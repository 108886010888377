<template>
    <div class="row">
        <div class="col-12">
            <div class="container-centered-s">
                <form @submit.prevent="onSubmit" class="mt-2">
                    <form-errors :errors="formErrors" />

                    <p>Please review the suggested changes to your resident address.</p>
                    <h5>Original Address:</h5>
                    <p>
                        {{ employeeUpdate.address_1 }}
                        {{ employeeUpdate.address_2 }}
                        <br/>
                        <span>{{ employeeUpdate.city }}, </span>
                        <span>{{ employeeUpdate.state }} </span>
                        <span>{{ employeeUpdate.postal_code }}</span>
                    </p>
                    <h5>Suggested Address:</h5>
                    <p>
                        {{ employeeUpdate.address_1 }}
                        {{ employeeUpdate.address_2 }}
                        <br/>
                        <span :class="{ 'text-bold': employeeUpdate.geocode_data.geocoder_modified_city }">
                            {{ employeeUpdate.geocode_data.city }},
                        </span>
                        <span :class="{ 'text-bold': employeeUpdate.geocode_data.geocoder_modified_state }">
                            {{ employeeUpdate.geocode_data.state }}
                        </span>
                        <span :class="{ 'text-bold': employeeUpdate.geocode_data.geocoder_modified_zip_code }">
                            {{ employeeUpdate.geocode_data.zip_code }}
                        </span>
                    </p>

                    <portal to="address-edit-modal-footer">
                        <button-row stacked>
                            <button type="button" @click.prevent="onPrev()" class="btn btn-outline-primary">Back</button>
                            <button type="button" @click.prevent="onSubmit()" class="btn btn-primary">Accept Changes</button>
                        </button-row>
                    </portal>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ButtonRow from '@/components/ButtonRow'
import api from '@/api'

export default {
    mixins: [FormMixin],
    components: {ButtonRow, },
    props: ['employee', 'employeeUpdate', 'prev'],
    watch: {
        employee() {
            this.reset()
        },
        employeeUpdate() {
            this.reset()
        }
    },
    computed: {},
    data() {
        const data = {
            formData: this.makeFormData(),
            formRules: {
                address_1: {presence: {allowEmpty: false},},
                city: {presence: {allowEmpty: false},},
                state: {presence: {allowEmpty: false},},
                postal_code: {presence: {allowEmpty: false},},
            },
            formLabels: {
                postal_code: 'Zip Code',
            },
        }
        return data
    },
    methods: {
        makeFormData() {
            let formData = {
                action: 'correct-address',
                address_1: this.employeeUpdate.address_1,
                address_2: this.employeeUpdate.address_2,
                city: this.employeeUpdate.geocode_data.city,
                state: this.employeeUpdate.geocode_data.state,
                postal_code: this.employeeUpdate.geocode_data.zip_code,
            }
            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            this.$store.dispatch('START_LOADING')
            api.patch(`/me/ess/companies/${this.employee.company_id}/updates/${this.employeeUpdate.id}`, this.getFormData()).then((resp) => {
                this.$emit('patch', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onPrev() {
            this.$emit('prev')
        },
    }
}
</script>