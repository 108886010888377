<template>
    <page>
        <fast-table
            v-if="dataLoaded"
            ref="table"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :tableClasses="tableClasses"
            @click="onClick"
        >

            <template v-slot:table-actions>
                <a href @click.prevent="onExport(currentYear)" class="btn btn-primary d-print-none ml-hf mr-1">
                    <i class="far fa-fw fa-file-excel"></i>
                    <span class="btn-text">Download {{ currentYear }} YTD Report</span>
                </a>

                <div class="dropdown">
                    <a href="" class="btn btn-icon-outline text-700 dropdown-toggle" role="button" id="menuYears" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="far fa-fw fa-calendar"></i> <span class="btn-text">{{ currentYear }}</span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="menuYears">
                        <a class="dropdown-item" @click.prevent="selectYear(year)" v-for="year in years">
                            {{ year }}
                        </a>
                    </div>
                </div>

            </template>

        </fast-table>

        <sidebar v-if="currentPayroll" :payroll="currentPayroll" :client="client" @close="currentPayroll = null" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Sidebar from './components/PayrollSidebar'
import Page from '@/components/Page'

export default {
    props: ['client', ],
    components: {FastTable, Sidebar, Page},
    computed: {
        tableData() {
            const tableData = [[]]

            this.payrolls.forEach((p, idx) => {
                const company = this.companies[p.company]
                const row = {
                    id: idx,
                    isActive: this.currentPayroll && p.id == this.currentPayroll.id,
                    object: p,
                    cells: [
                        company.company_code,
                        company.name,
                        company.client.client_code,
                        company.client.name,
                        p.check_date,
                        p.check_count,
                        p.period_begin_date,
                        p.period_end_date,
                        p.total_gross_wages,
                        p.total_gross_pay,
                        p.total_net_pay,
                        p.status_pretty,
                        p.run_number,
                    ],
                }

                tableData[0].push(row)
            })

            return tableData
        },
    },
    data() {
        return {
            currentYear: null, // check year, not the standard date range filter
            years: [],

            companies: [],
            payrolls: [],
            dataLoaded: false,
            currentPayroll: null,

            tableClasses: 'table-anchor--date',
            headers: [
                {label: 'Company Code', classes: '', isSearchable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true},
                {label: 'Client Code', classes: 'cell-company', isSearchable: true},
                {label: 'Client', classes: 'cell-company', isSearchable: true},
                {label: 'Check Date', classes: 'cell-medium', type: 'date', isSearchable: false, defaultSort: true, defaultSortIsDesc: true},
                {label: 'Check Count', classes: 'cell-medium', isSearchable: false},

                {label: 'Start Date', classes: 'cell-medium', type: 'date', isSearchable: false},
                {label: 'End Date', classes: 'cell-medium', type: 'date', isSearchable: false},

                {label: 'Gross Wages', classes: 'cell-medium', isSearchable: false},
                {label: 'Gross Pay', classes: 'cell-medium', isSearchable: false},
                {label: 'Net Pay', classes: 'cell-medium', isSearchable: false},

                {label: 'Status', classes: 'cell-medium', isSearchable: true},
                {label: 'Run Number', classes: 'cell-narrow', isSearchable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Payrolls')
        this.getCompanies().then(() => {
            this.getPayrolls()
        })
    },
    methods: {
        selectYear(year) {
            this.currentYear = year
            this.getPayrolls()
        },

        getCompanies() {
            return this.$api.get(`/admin/companies`).then(resp => {
                this.companies = {}
                resp.forEach(c => {
                    this.companies[c.id] = c
                })
            }).catch((errors) => {
                this.$bus.showError(errors.__all__[0])
            })
        },
        getPayrolls() {
            this.dataLoaded = false

            let url = `/admin/payrolls`
            if (this.currentYear) {
                url = `${url}?year=${encodeURIComponent(this.currentYear)}`
            }

            this.$store.dispatch('START_LOADING')
            this.$api.get(url).then(resp => {
                this.years = resp['years']
                this.currentYear = resp['year_filter']
                this.payrolls = resp['payrolls']
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onClick(payroll) {
            this.currentPayroll = payroll
        },
        onExport(year) {
            this.$api.redirect(`/admin/payrolls/export/${year}`)
        },

    },
}

</script>
