<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Ready for Test</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    On the next page you will be presented with a test about the E-Verify system, based on the content you just reviewed.
                                </p>

                                <p>
                                    You must achieve a score of <b>at least 70%</b> in order to use E-Verify, including via GetMyPayStub.
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page4'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-test'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
