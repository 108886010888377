<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template v-slot:title>
            <span v-if="taxExemptionsGroup">Update Tax Exempt Group: </span><span v-if="taxExemptionsGroup" class="text-thin text-nowrap">{{ taxExemptionsGroup.name }}</span>
            <span v-if="!taxExemptionsGroup">Add New Tax Exempt Group</span>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <form-errors :errors="formErrors"/>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" />
                    </div>
                </div>

            </form>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['taxExemptionsGroup', ],
    mixins: [FormMixin, ModalMixin, ],
    data() {
        return {
            formData: {
                name: this.taxExemptionsGroup ? this.taxExemptionsGroup.name : '',
            },
        }
    },
    methods: {
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise
            if (this.taxExemptionsGroup) {
                promise = this.$api.put(`/admin/tax-engine/tax-exemptions-groups/${this.taxExemptionsGroup.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/admin/tax-engine/tax-exemptions-groups`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>

