<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ $filters.date(version.starts_on, "MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ $filters.date(version.ends_on, "MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <div>
        <span class="text-semibold">Version: </span>
        <span>{{ version.version }}</span>
    </div>

    <div>
        <span class="text-semibold">Type: </span>
        <span>{{ version.type_pretty }}</span>
    </div>

    <div>
        <span class="text-semibold">Has Tip Credit Claimed:</span>
        <span> {{ version.has_tip_credit_claimed ? 'Yes' : 'No' }}
        </span>
    </div>

    <div>
        <span class="text-semibold">Is Tips: </span>
        <span>{{ version.is_tips ? 'Yes' : 'No' }}</span>
    </div>

    <div>
        <span class="text-semibold">Is Employer-Paid Tips: </span>
        <span>
            {{ version.is_employer_paid_tips ? 'Yes' : 'No' }}
        </span>
    </div>

    <div>
        <span class="text-semibold">Is Work Suffered: </span>
        <span>{{ version.is_work_suffered ? 'Yes' : 'No' }}</span>
    </div>

    <div>
        <span class="text-semibold">Is Virtual (e.g. PUCC): </span>
        <span> {{ version.is_virtual ? 'Yes' : 'No' }}</span>
    </div>

    <div>
        <span class="text-semibold">Created On: </span>
        <span> {{ $filters.date(version.created_on) }}</span>
    </div>
</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', ],
    data() {
        return {
            isExpanded: false,
        }
    },
}
</script>
