<template>
<div class="card mb-2">
    <div class="card-header pr-1 d-flex align-items-center">
        <span class="btnlike mr-auto"><h4 class="mb-0">Vaccination Status</h4></span>
    </div>

    <div class="card-body">
        <h5>Current Status</h5>
        <h2>{{ status ? status.vaccination_status_pretty : 'Not Yet Reported'}}</h2>
        <p>{{ status ? status.status_pretty : ''}}</p>

        <button class="btn btn-block mb-1 mt-4" :class="{'btn-primary': !status, 'btn-outline-primary': status}" @click.prevent="$emit('click')">{{ buttonTextPretty }}</button>
    </div>
</div>
</template>

<script>
export default {
    props: ['status'],
    computed: {
        buttonTextPretty() {
            const status = (this.status && this.status.vaccination_status) ? this.status.vaccination_status : ''
            return status ? 'Update Vaccination Status' : 'Report Vaccination Status'
        },
    },
}
</script>
