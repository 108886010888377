<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container my-4">
                        <div class="row">
                            <div class="col-6">
                                <h3>Test Address</h3>

                                <form @submit.prevent class="mt-4">
                                    <form-errors :errors="formErrors" :errorsHtml="formErrorsHtml" />

                                    <form-input label="Address 1" v-model="formData.address_1" :errors="errors.address_1" type="text" />
                                    <form-input label="Address 2 (Optional)" v-model="formData.address_2" :errors="errors.address_2" type="text" />
                                    <form-input label="City" v-model="formData.city" :errors="errors.city" type="text" />

                                    <div class="form-row">
                                        <div class="col-6">
                                            <form-input label="State" v-model="formData.state" :errors="errors.state" type="select" :options="stateOptions"/>
                                        </div>

                                        <div class="col-6">
                                            <form-input label="Zip Code" :maxlength="5" v-model="formData.postal_code" :errors="errors.postal_code" type="text" />
                                        </div>
                                    </div>

                                    <div class="row mb-2 d-flex justify-content-between">
                                        <div class="col text-center">
                                            <button class="btn btn-outline-primary" @click="testUSPS">USPS</button>
                                        </div>

                                        <div class="col text-center">
                                            <button class="btn btn-outline-primary" @click="testVertex">Vertex</button>
                                        </div>

                                        <div class="col text-center">
                                            <button class="btn btn-outline-primary" @click="testPayroll">Payroll</button>
                                        </div>

                                        <div class="col text-center">
                                            <button class="btn btn-outline-primary" @click="testPGStandardizeAddr">PG: Addr</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="col-6">
                                <div class="debug-output" v-text="debugOutput" v-if="debugOutput"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .debug-output {
        background-color: #EEEEEE;
        color: black;
        white-space: pre;
        padding: 1em;
        font-family: monospace;
        font-size: 0.8em;
    }
</style>

<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'

import states from '@/states'

export default {
    mixins: [FormMixin, ],
    computed: {
        stateOptions() {
            const result = []
            states.forEach((s) => {
                result.push({text: s.name.toUpperCase(), value: s.abbreviation})
            })
            return result
        },
    },
    data() {
        return {
            formRules: {
                address_1: {presence: {allowEmpty: false},},
                city: {presence: {allowEmpty: false},},
                state: {presence: {allowEmpty: false},},
                postal_code: {presence: {allowEmpty: false},},
            },
            formLabels: {
                postal_code: 'Zip Code',
            },
            formErrorsHtml: [],
            formData: this.makeFormData(),
            debugOutput: ''
        }
    },
    methods: {
        makeFormData() {
            return {
                address_1: '',
                address_2: '',
                city: '',
                state: '',
                postal_code: '',
            }
        },
        testUSPS() {
            if (!this.validate()) {
                return
            }
            this.$store.dispatch('START_LOADING')
            api.post(`/admin/geocoding/tester/usps`, this.getFormData()).then((resp) => {
                this.debugOutput = resp.debug_output
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrorsHtml = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        testVertex() {
            if (!this.validate()) {
                return
            }
            this.$store.dispatch('START_LOADING')
            api.post(`/admin/geocoding/tester/vertex`, this.getFormData()).then((resp) => {
                this.debugOutput = resp.debug_output
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrorsHtml = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        testPayroll() {
            if (!this.validate()) {
                return
            }
            this.$store.dispatch('START_LOADING')
            api.post(`/admin/geocoding/tester/aos`, this.getFormData()).then((resp) => {
                this.debugOutput = resp.debug_output
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrorsHtml = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        testPGStandardizeAddr() {
            if (!this.validate()) {
                return
            }
            this.$store.dispatch('START_LOADING')
            api.post(`/admin/geocoding/tester/pg-address`, this.getFormData()).then((resp) => {
                this.debugOutput = resp.debug_output
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrorsHtml = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrorsHtml = []
            this.formData = this.makeFormData()
        },
    },
}
</script>
