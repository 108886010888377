<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">

        <template v-slot:title>
            <span v-if="intertaxRule">Update Intertax Rule: </span><span v-if="intertaxRule" class="text-thin text-nowrap">{{ intertaxRule.name }}</span>
            <span v-if="!intertaxRule">Add New Intertax Rule</span>
        </template>

        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div v-show="mode == 'form'">
                    <div class="form-row mb-2">
                        <div class="col-6">
                            <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                        </div>
                        <div class="col-6">
                            <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col">
                            <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" :readonly="!!intertaxRule" />
                        </div>
                    </div>

                    <div class="form-row mb-2">
                        <div class="col-6">
                            <form-input v-model="formData.type" :errors="errors.type" type="select" label="Type" :disabled="!!intertaxRule" :options="typeOptions" />
                        </div>
                    </div>

                    <hr />

                    <div class="form-row mb-2">
                        <div class="col-3">
                            <form-input v-model="formData.jurisdiction_type" :errors="errors.jurisdiction_type" type="select" label="Jurisdiction Type" :options="jurisdictionTypeOptions" />
                        </div>

                        <div class="col-6" v-if="parentJurisdictionLabel">
                            <form-input v-model="formData.parent_jurisdiction" :errors="errors.parent_jurisdiction" type="select" :label="parentJurisdictionLabel" :options="parentJurisdictionOptions" />
                        </div>

                        <div class="col-3" v-if="formData.jurisdiction_type">
                            <form-input v-model="formData.jurisdiction_selection" :errors="errors.jurisdiction_selection" type="select" label="Selection" :options="manyToManyOptions" />
                        </div>
                    </div>

                    <div class="card mb-2" v-show="formData.jurisdiction_type && formData.jurisdiction_selection == 'specific'">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Jurisdictions</h4>
                            </span>
                        </div>

                        <div class="card-body py-1">
                            <table-selector
                                :sections="[{title: 'All', id: 'all'}]"
                                :headers="[{label: 'Name', classes: 'cell-wide'}, {label: 'Type', classes: ''}]"
                                :items="jurisdictionSelectionData"
                                v-model="formData.jurisdictions"
                            />
                        </div>
                    </div>

                    <div class="form-row mb-2">
                        <div class="col-3">
                            <form-input v-model="formData.tax_type" :errors="errors.tax_type" type="select" label="Tax Type" :options="taxTypeOptions" />
                        </div>

                        <div class="col-3" v-if="formData.tax_type">
                            <form-input v-model="formData.tax_selection" :errors="errors.tax_selection" type="select" label="Selection" :options="manyToManyOptions" />
                        </div>
                    </div>

                    <div class="card mb-2" v-show="formData.tax_type && formData.tax_selection == 'specific'">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Taxes</h4>
                            </span>
                        </div>

                        <div class="card-body py-1">
                            <table-selector
                                :sections="[{title: 'All', id: 'all'}]"
                                :headers="[{label: 'Name', classes: 'cell-wide'}, {label: 'Jurisdiction', classes: ''}]"
                                :items="taxSelectionData"
                                v-model="formData.remote_taxes"
                            />
                        </div>
                    </div>

                    <div class="form-row mb-2">
                        <div class="col-6">
                            <form-input v-model="formData.tax_tag_selection" :errors="errors.tax_tag_selection" type="radio" label="Use tax tags" :options="taxTagManyToManyOptions" />
                        </div>
                    </div>

                    <div class="card mb-2" v-show="formData.tax_tag_selection == 'specific'">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Tax Tags</h4>
                            </span>
                        </div>

                        <div class="card-body py-1">
                            <table-selector
                                :sections="[{title: 'All', id: 'all'}]"
                                :headers="[{label: 'Tag', classes: 'cell-wide'}]"
                                :items="taxTagSelectionData"
                                v-model="formData.tax_tags"
                            />
                        </div>
                    </div>


                    <hr />

                    <div class="form-row mb-2">
                        <div class="col-6">
                            <form-input v-model="formData.calculation_algorithm" :errors="errors.calculation_algorithm" type="select" label="Calculation Algorithm" :options="calculationAlgorithmOptions" />
                        </div>

                        <div class="col-6" v-if="formData.calculation_algorithm == 'credit'">
                            <form-input v-model="formData.credit_proportion" :errors="errors.credit_proportion" type="integer" label="Credit Percentage" :min="1" :max="100" :maxlength="3" />
                        </div>
                    </div>
                </div>

                <div v-if="mode == 'confirm'">
                    <p>When the employee is subject to <b>{{ tax.name }}</b> as a <b>{{ formData.type == 'resident' ? 'Resident' : 'Non-Resident' }}</b></p>

                    <div v-if="formData.jurisdiction_type">
                        <p class="text-center"><b>AND</b></p>
                        <p>the employee <b>{{ formData.type == 'resident' ? 'works' : 'lives' }}</b> in
                            <b v-if="formData.jurisdiction_selection == 'all'">any {{ formData.jurisdiction_type }} jurisdiction</b> <span v-if="formData.parent_jurisdiction"> in <b>{{ selectedparentJurisdiction.canonical_name }}</b></span>

                            <b v-if="formData.jurisdiction_selection == 'specific'">a {{ formData.jurisdiction_type }} jurisdiction specified below:</b>
                        </p>
                        <ul v-if="formData.jurisdiction_selection == 'specific'">
                            <li v-for="j in selectedJurisdictions">{{ j.canonical_name }}</li>
                            <li v-if="formData.jurisdictions.length < 1"><em>None specified</em></li>
                        </ul>
                    </div>

                    <div v-if="formData.tax_type">
                        <p class="text-center"><b>AND</b></p>
                        <p>the employee earns income that is subject to
                            <b v-if="formData.tax_selection == 'all'">any {{ selectedTaxTypePretty }} in the above jurisdictions</b>
                            <b v-if="formData.tax_selection == 'specific'">a {{ selectedTaxTypePretty }} in the above jurisdictions as specified below:</b>
                        </p>
                        <ul v-if="formData.tax_selection == 'specific'">
                            <li v-for="t in selectedTaxes">{{ t.name }}</li>
                            <li v-if="selectedTaxes.length < 1"><em>None specified</em></li>
                        </ul>
                    </div>

                    <div v-if="formData.tax_tag_selection == 'specific'">
                        <p class="text-center"><b>AND</b></p>
                        <p>the employee earns income that is tagged as:</p>
                        <ul>
                            <li v-for="t in selectedTaxTags">{{ t.name }}</li>
                            <li v-if="formData.tax_tags.length < 1"><em>None specified</em></li>
                        </ul>
                    </div>

                    <p class="text-center"><b>THEN</b></p>

                    <p v-if="formData.calculation_algorithm == 'exempt'">the employee will be <b>exempt</b> from paying <b>{{ tax.name }}</b>.</p>
                    <p v-if="formData.calculation_algorithm == 'withhold'">the employee will have <b>{{ tax.name }} withheld</b>.</p>
                    <p v-if="formData.calculation_algorithm == 'credit'">the employee <b>will get credit</b> from <b>{{ tax.name }}</b> for any of the taxes that match the selection above at the rate of <b>{{ formData.credit_proportion }}%</b>.</p>
                </div>

            </form>
        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="mode == 'form' ? close() : (mode = 'form')">{{ mode == 'form' ? 'Cancel' : 'Back' }}</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">{{ mode == 'form' ? 'Continue' : 'Save' }}</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import TableSelector from '@/components/TableSelector'

export default {
    props: ['tax', 'intertaxRuleId', 'jurisdictions', 'taxes' ],
    mixins: [FormMixin, ModalMixin, ],
    components: {TableSelector},
    computed: {
        taxTagManyToManyOptions() {
            return [
                {text: 'No', value: 'all'},
                {text: 'Yes', value: 'specific'},
            ]
        },
        manyToManyOptions() {
            return [
                {text: 'Any', value: 'all'},
                {text: 'Specific', value: 'specific'},
            ]
        },
        jurisdictionTypeOptions() {
            return [
                {text: 'Federal', value: 'fed'},
                {text: 'State', value: 'state'},
                {text: 'City', value: 'city'},
                {text: 'County', value: 'county'},
                {text: 'School District', value: 'school-district'},
            ]
        },
        taxTypeOptions() {
            return [
                {text: 'Income Tax', value: 'income'},
                {text: 'Unemployment Insurance', value: 'unemp-ins'},
                {text: 'Service Fee', value: 'service-fee'},
            ]
        },
        parentJurisdictionOptions() {
            const TYPES = {
                'city': 'state',
                'county': 'state',
                'school-district': 'city',
            }

            const result = []
            this.jurisdictions.filter(j => j.type == TYPES[this.formData.jurisdiction_type]).forEach(j => {
                result.push({value: j.id, text: j.canonical_name})
            })
            result.sort((a, b) => {
                return a.text > b.text ? 1 : -1
            })
            return result
        },
        parentJurisdictionLabel() {
            const LABELS = {
                'city': 'State',
                'county': 'State',
                'school-district': 'City',
            }

            return LABELS[this.formData.jurisdiction_type] || ''
        },
        typeOptions() {
            return [
                {text: 'Resident', value: 'resident'},
                {text: 'Non-resident', value: 'non-resident'},
            ]
        },
        calculationAlgorithmOptions() {
            return [
                {text: 'Credit', value: 'credit'},
                {text: 'Exempt', value: 'exempt'},
                {text: 'Withhold', value: 'withhold'},
            ]
        },
        jurisdictionSelectionData() {
            // This returns data so we can select it via the table selector
            const result = []
            this.jurisdictions.filter(j => j.type == this.formData.jurisdiction_type).forEach(item => {
                result.push({
                    id: item.id,
                    cells: [
                        item.canonical_name,
                        item.type_pretty,
                    ],
                })
            })

            result.sort((a, b) => {
                if (a.cells[1] == b.cells[1]) {
                    return a.cells[0] > b.cells[0] ? 1 : -1
                }
                const PRIORITY = {
                    'Federal': 1,
                    'State': 2,
                    'City': 3,
                }

                return PRIORITY[a.cells[1]] > PRIORITY[b.cells[1]] ? 1 : -1
            })

            return [result]
        },
        taxTagSelectionData() {
            // This returns data so we can select it via the table selector
            const result = []

            this.taxTags.forEach(tag => {
                result.push({
                    id: tag.id,
                    cells: [
                        tag.name
                    ],
                })
            })

            return [result]
        },
        taxSelectionData() {
            // This returns data so we can select it via the table selector
            const result = []
            this.taxes
                .filter(t => t.id != this.tax.id)
                .filter(t => t.type == this.formData.tax_type)
                .filter(t => this.formData.jurisdiction_type ? t.jurisdiction.type == this.formData.jurisdiction_type : true)
                .forEach(item => {
                result.push({
                    id: item.id,
                    cells: [
                        item.name,
                        item.jurisdiction.canonical_name,
                    ],
                })
            })

            result.sort((a, b) => {
                return a.cells[0] > b.cells[0] ? 1 : -1
            })

            return [result]
        },
        selectedparentJurisdiction() {
            return this.jurisdictions.find(j => j.id == this.formData.parent_jurisdiction)
        },
        selectedJurisdictions() {
            const result = []
            this.formData.jurisdictions.forEach(jId => {
                result.push(this.jurisdictions.find(j => j.id == jId))
            })

            result.sort((a, b) => {
                return a.name > b.name ? 1 : -1
            })

            return result
        },
        selectedTaxes() {
            const result = []
            this.formData.remote_taxes.forEach(tId => {
                result.push(this.taxes.find(t => t.id == tId))
            })

            result.sort((a, b) => {
                return a.name > b.name ? 1 : -1
            })

            return result
        },
        selectedTaxTags() {
            const result = []
            this.formData.tax_tags.forEach(tId => {
                result.push(this.taxTags.find(t => t.id == tId))
            })

            result.sort((a, b) => {
                return a.name > b.name ? 1 : -1
            })

            return result
        },
        selectedTaxTypePretty() {
            return this.taxTypeOptions.find(o => o.value == this.formData.tax_type).text
        },
    },
    mounted() {
        this.getData()
    },
    data() {
        return {
            dataLoaded: false,
            intertaxRule: null,
            taxTags: [],
            formData: {},
            mode: 'form',
            formRules: {
                starts_on: {presence: {allowEmpty: false}, date: true},
                name: {presence: {allowEmpty: false}},
                type: {presence: {allowEmpty: false}},
                calculation_algorithm: {presence: {allowEmpty: false}},
            },
        }
    },
    watch: {
        'formData.jurisdiction_type'(a, b) {
            if (!this.dataLoaded) {return}
            if (a != b) {
                this.formData.jurisdiction_selection = 'all'
                this.formData.jurisdictions = []
                this.formData.parent_jurisdiction = null
            }
        },
        'formData.jurisdiction_selection'() {
            if (!this.dataLoaded) {return}
            this.formData.jurisdictions = []
        },
        'formData.tax_type'(a, b) {
            if (!this.dataLoaded) {return}
            if (a != b) {
                this.formData.tax_selection = 'all'
                this.formData.remote_taxes = []
            }
        },
        'formData.tax_selection'() {
            if (!this.dataLoaded) {return}
            this.formData.remote_taxes = []
        },
        'formData.tax_tag_selection'(a, b) {
            if (!this.dataLoaded) {return}
            if (this.formData.tax_tag_selection == 'all') {
                this.formData.tags = []
            }
        },
    },
    methods: {
        getData() {
            this.$api.get(`/admin/tax-engine/tax-tags`).then(resp => {
                resp.sort((a, b) => {
                    return a.name > b.name ? 1 : -1
                })
                this.taxTags = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })

            if (!this.intertaxRuleId) {
                this.formData = this.makeFormData()
                this.$nextTick(() => {
                    this.dataLoaded = true
                })
                return
            }
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            this.$api.get(`/admin/tax-engine/taxes/${this.tax.id}/intertax-rules/${this.intertaxRuleId}`).then(resp => {
                this.intertaxRule = resp
                this.formData = this.makeFormData()
                this.$nextTick(() => {
                    this.dataLoaded = true
                })
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })

        },
        makeFormData() {
            if (this.intertaxRule) {
                return {
                    starts_on: this.intertaxRule.current_version.starts_on,
                    inactive_on: this.intertaxRule.current_version.inactive_on,
                    name: this.intertaxRule.name,
                    type: this.intertaxRule.type,

                    jurisdiction_type: this.intertaxRule.current_version.jurisdiction_type,
                    parent_jurisdiction: this.intertaxRule.current_version.parent_jurisdiction ? this.intertaxRule.current_version.parent_jurisdiction.id : null,
                    jurisdiction_selection: this.intertaxRule.current_version.jurisdiction_selection,
                    jurisdictions: this.intertaxRule.current_version.jurisdictions.map(x => x.id),

                    tax_type: this.intertaxRule.current_version.tax_type,
                    tax_selection: this.intertaxRule.current_version.tax_selection,
                    remote_taxes: this.intertaxRule.current_version.remote_taxes.map(x => x.id),

                    tax_tag_selection: this.intertaxRule.current_version.tax_tag_selection || 'all',
                    tax_tags: this.intertaxRule.current_version.tax_tags.map(x => x.id),

                    calculation_algorithm: this.intertaxRule.current_version.calculation_algorithm,
                    credit_proportion: this.intertaxRule.current_version.credit_proportion,
                }
            } else {
                return {
                    starts_on: '',
                    inactive_on: '',
                    name: '',
                    type: '',
                    jurisdictions: [],
                    remote_taxes: [],
                    tax_tags: [],
                    jurisdiction_type: '',
                    tax_type: '',
                    parent_jurisdiction: null,
                    jurisdiction_selection: 'all',
                    tax_selection: 'all',
                    calculation_algorithm: '',
                    credit_proportion: 100,
                    tax_tag_selection: 'all',
                }
            }
        },
        onSubmit() {
            this.formErrors = []
            this.errors = {}
            if (!this.validate()) {
                return
            }

            if (!this.formData.jurisdiction_type && !this.formData.tax_type && this.formData.tax_tag_selection == 'all') {
                this.formErrors.push('Please specify at least one criteria for selecting taxes.')
                return
            }

            if (this.mode == 'form') {
                this.mode = 'confirm'
                return
            }

            let promise
            if (this.intertaxRule) {
                promise = this.$api.put(`/admin/tax-engine/taxes/${this.tax.id}/intertax-rules/${this.intertaxRule.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/admin/tax-engine/taxes/${this.tax.id}/intertax-rules`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
