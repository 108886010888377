<template>
    <div>
        <page>
            <div class="p-4">
                <p>
                    This is some sample page content.
                </p>
                <p>
                    <button class="btn btn-primary" @click.prevent="openTestModal">Edit Role (or whatever)</button>
                </p>
            </div>
        </page>
        <modal-with-child-overlay ref="testModal" />
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import moment from 'moment'

import Page from '@/components/Page'
import ModalWithChildOverlay from '@/components/ModalWithChildOverlay'

export default {
    props: ['data'],
    components: { Page, ModalWithChildOverlay, },
    computed: {
    },
    data() {
        return {
            testModalOpened: false,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Modal Child Overlay Test')
        this.testFunction()
    },
    methods: {
        testFunction() {
            console.log(this.testModalOpened)
        },
        openTestModal() {
            this.testModalOpened = true
            this.$nextTick(() => {
                this.$refs.testModal.open()
            })
        },
    }
}
</script>
