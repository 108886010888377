<script>

import api from '@/api'
import bus from '@/bus'
import jQuery from 'jquery'
import ArrayBufferToString from 'arraybuffer-to-string'

import { saveAs } from 'file-saver'

export default {
    data() {
        return {
            'printableItemType': null,
            'printableItemId': null,
            'printableStaticPDF': null,
        }
    },
    methods: {
        printToPDF() {
            let h = this.createPrintableHTML(this.$refs.printable.innerHTML)

            let css_includes = []
            let css_rules = []
            for (let style of document.styleSheets) {
                if (style.href) {
                    // NB: non-GetMyPayStub items will be filtered out and need to be
                    // included on the server (i.e. Google Fonts, FontAwesome, etc.)
                    css_includes.push(style.href)
                } else {
                    for (let css_rule of style.cssRules) {
                        css_rules.push(css_rule.cssText)
                    }
                }
            }

            this.$store.dispatch('START_LOADING')
            if (this.printableStaticPDF) {
                let url = `/pdf/${this.printableStaticPDF}`
                api.get(url).then(this.handlePDFSuccessResponse).catch(this.handlePDFErrorResponse)
            } else {
                api.post(`/print-pdf`, {
                    html: h,
                    css_includes: css_includes,
                    css_rules: css_rules,
                    currentURL: window.location.href,
                    printableItemType: this.printableItemType,
                    printableItemId: this.printableItemId
                }).then(this.handlePDFSuccessResponse).catch(this.handlePDFErrorResponse)
            }
        },

        handlePDFSuccessResponse(resp) {
            this.$store.dispatch('STOP_LOADING')
            if (this.$store.state.isInApp) {
                if (typeof global !== 'undefined' && global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
                    let ab_str = Uint8ToString(new Uint8Array(resp))
                    global.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
                        command: 'print',
                        content: btoa(ab_str)
                    }))
                }
            } else {
                // TODO: make filename customizable
                saveAs(new Blob([resp], {type: "application/pdf"}), `download.pdf`)
            }
        },

        handlePDFErrorResponse(errors) {
            this.$store.dispatch('STOP_LOADING')
            bus.showError(errors.__all__[0])
        },

        createPrintableHTML(html) {
          return `
                <div class="container-fluid">
                    <div class="printable-item">
                        ${html}
                    </div>
                </div>
            `
        }
    },
}

// workaround for RangeError if you do ArrayBufferToString(resp, 'binary') of a large file
function Uint8ToString(u8_array){
    let chunk_length = 32768;
    let c = [];
    for (var i = 0; i < u8_array.length; i += chunk_length) {
        c.push(String.fromCharCode.apply(null, u8_array.subarray(i, i + chunk_length)));
    }
    return c.join('');
}

</script>
