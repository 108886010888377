<template>
    <modal size="xl" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">

        <template v-slot:title>Add Entitlement</template>

        <slot>
            <form class="mui-form px-1" @submit.prevent="onSubmit()" style="min-height: 20em;">
                <form-errors :errors="formErrors"/>

                <div class="form-row">
                    <div class="col">
                        <form-input label="Form" v-model="formData.yaml_form" :errors="errors.yaml_form" type="select" :options="hrFormSelectOptions"/>
                    </div>
                </div>
            </form>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['client', 'clientEntitlement'],
    mixins: [FormMixin, ModalMixin],
    watch: {
        client() {
            this.reset()
        },
    },
    computed: {
        hrFormSelectOptions() {
            const options = []
            for (let form of (this.hrFormOptions || [])) {
                options.push({text: form.name, value: form.id})
            }
            return options
        },
    },
    mounted() {
        this.getHrFormOptions()
    },
    data() {
        return {
            hrFormOptions: [],
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            return {
                client: this.client.id,
                yaml_form: this.clientEntitlement ? this.clientEntitlement.yaml_form.id : null,
            }
        },
        getHrFormOptions() {
            this.$api.get(`/admin/pdf-forms/forms/hr-forms`).then(resp => {
                this.hrFormOptions = resp
                this.mode = this.formData.type
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let url = `/admin/clients/${this.$hasher.decode(this.$route.params.hashedClientId)}/form-entitlements`

            this.$store.dispatch('START_LOADING')
            return this.$api.post(url, this.getFormData()).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>