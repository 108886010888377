<template>
    <div class="card mb-1">
        <div class="card-body">
            <form-errors :errors="formErrors"/>

            <div class="form-row">
                <div class="col">
                    <form-input label="Location" v-model="formData.location" :errors="errors.location" type="select" :options="locationOptions" @update:model-value="onInput" :readonly="readOnlyPosition" :disabled="readOnlyPosition" />
                </div>
            </div>

            <div class="form-row" v-if="!client.location_is_terminal">
                <div class="col">
                    <form-input :label="client.position_without_location_label_pretty" v-model="formData.position" :errors="errors.position" type="select" :options="positionOptions" :disabled="readOnlyPosition || !formData.location" @update:model-value="onInput" :readonly="readOnlyPosition" />
                </div>
            </div>

            <div class="form-row">
                <div class="col-6 col-sm-4" v-if="formData.pay_type == 'H'">
                    <form-input label="Per Hour" v-model="formData.hourly_rate" :errors="errors.hourly_rate" type="currency" prefix="$" @update:model-value="onInput" />
                </div>

                <div class="col-6 col-sm-4" v-if="formData.pay_type == 'S'">
                    <form-input label="Per Pay Period" v-model="formData.salary_rate" :errors="errors.salary_rate" type="currency" prefix="$" @update:model-value="onInput" />
                </div>

                <div class="col-6 col-sm-4" v-if="formData.pay_type == 'S'">
                    <form-input label="Default Hours" v-model="formData.default_hours" :errors="errors.default_hours" type="decimal" @update:model-value="onInput" />
                </div>

                <div class="col-12">
                    <p class="field-helper-text" v-if="selectedPosition">Paid {{ selectedPosition.paygroup.pay_frequency_verbose }}.</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'

export default {
    mixins: [FormMixin],
    props: ['client', 'company', 'instance', 'allJobs', 'pay_type', 'readOnlyPosition'],
    computed: {
        payFrequencyMessage() {
            return 'This employee will be paid every other week.'
        },
        selectedJobs() {
            const result = {}
            this.allJobs.forEach((job) => {
                if (!job.location) {
                    return
                }

                result[job.location] = result[job.location] || {}

                if (job.position) {
                    result[job.location][job.position] = true
                }
            })
            return result
        },
        locationOptions() {
            const options = []
            for (let location of this.company.work_locations) {
                if (!location.is_active) {
                    continue
                }

                const workLocationPositionIds = Array.from(new Set(location.positions.map(p => p.id)))
                let hasAccessToLocation = false

                for (let positionId of workLocationPositionIds) {
                    hasAccessToLocation = hasAccessToLocation || this.$permissions.hasAccess(
                        this.client.id,
                        this.company.id,
                        [location.id],
                        [positionId],
                    )
                }

                if (!hasAccessToLocation) {
                    continue
                }

                if (location.id != this.formData.location) {
                    if (this.selectedJobs[location.id]) {
                        if (location.positions.length <= Object.keys(this.selectedJobs[location.id]).length) {
                            continue
                        }
                    }
                }
                options.push({text: location.name, value: location.id})
            }

            return options
        },
        positionOptions() {
            const options = []
            const location = this.selectedLocation
            if (!location) {
                return []
            }
            for (let position of location.positions) {
                if (position.id != this.formData.position) {
                    if (this.selectedJobs[location.id] && this.selectedJobs[location.id][position.id]) {
                        continue
                    }
                }

                if (!position.is_active) {
                    continue
                }

                const hasAccess = this.$permissions.hasAccess(
                    this.client.id,
                    this.company.id,
                    [location.id],
                    [position.id],
                )
                if (!hasAccess) {continue}

                options.push({text: position.terminal_value_no_location, value: position.id})
            }

            return options
        },
        selectedLocation() {
            return this.company.work_locations.find((l) => {return l.id == this.formData.location})
        },
        selectedPosition() {
            if (!this.selectedLocation) {
                return null
            }
            return this.selectedLocation.positions.find((p) => {return p.id == this.formData.position})
        },
    },
    data() {
        let defaultHoursRule = {}
        if (this.instance && this.instance.pay_type == 'S') {
            defaultHoursRule = {presence: {allowEmpty: false}, decimal: true}
        }

        return {
            payTypeOptions: [
                {text: 'Hourly', value: 'H'},
                {text: 'Salary', value: 'S'},
            ],
            formRules: {
                location: {presence: {allowEmpty: false},},
                position: {presence: {allowEmpty: false},},
                pay_type: {presence: {allowEmpty: false},},
                hourly_rate: {presence: {allowEmpty: true}, currency: true},
                salary_rate: {presence: {allowEmpty: true}, currency: true},
                default_hours: defaultHoursRule,
            },
            formData: this.makeFormData()
        }
    },
    watch: {
        instance() {
            this.reset()
        },
        pay_type() {
            this.formData.pay_type = this.pay_type
        },
        'formData.pay_type'() {
            if (this.formData.pay_type == 'S') {
                this.formRules.default_hours = {presence: {allowEmpty: false}, decimal: true}
                if (this.formData.default_hours == '') {
                    if (this.selectedPosition) {
                        this.formData.default_hours = this.selectedPosition.paygroup.default_hours
                    }
                }
            }
            else {
                if (this.formRules.default_hours) {
                    delete this.formRules.default_hours
                }
                this.formData.default_hours = ''
                this.onInput()
            }
            this.formData.hourly_rate = ''
            this.formData.salary_rate = ''
        },
        'formData.position'() {
            if (this.formData.pay_type == 'S') {
                if (this.selectedPosition) {
                    this.formData.default_hours = this.selectedPosition.paygroup.default_hours
                }
            }
        },
    },
    methods: {
        getFormRules(fields) {
            const rules = FormMixin.methods.getFormRules.call(this, fields)
            if (this.formData.pay_type == 'S') {
                rules.salary_rate = {presence: {allowEmpty: false}, currency: true}
                rules.hourly_rate = {presence: {allowEmpty: true}}
            }
            else {
                rules.salary_rate = {presence: {allowEmpty: true}}
                rules.hourly_rate = {presence: {allowEmpty: false}, currency: true}
            }
            return rules
        },
        makeFormData() {
            if (this.instance) {
                return {
                    id: this.instance.id,
                    location: this.instance.location,
                    position: this.instance.position,
                    pay_type: this.pay_type || this.instance.pay_type,
                    hourly_rate: this.instance.pay_type == 'H' ? this.instance.hourly_rate : '',
                    salary_rate: this.instance.pay_type == 'S' ? this.instance.salary_rate : '',
                    default_hours: this.instance.pay_type == 'S' ? this.instance.default_hours : '',
                }
            }
            else {
                return {
                    location: '',
                    position: '',
                    pay_type: this.pay_type || '',
                    hourly_rate: '',
                    salary_rate: '',
                    default_hours: '',
                }
            }
        },
        getFormData() {
            if (this.client.location_is_terminal) {
                this.formData.position = this.selectedLocation.positions[0]
            }

            return this.formData
        },
        onInput() {
            this.$nextTick(() => {
                this.$emit('update', this.getFormData())
            })
        },
    },
}
</script>
