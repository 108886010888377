<template>
    <div class="progress-container">
        <div class="progress">
            <div class="progress-bar" role="progressbar" :style="'width: ' + progress + '%'" :aria-valuenow="roundedProgress" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="progress-label">{{ roundedProgress }}%</div>
    </div>
</template>

<script>
export default {
    props: ['progress'],
    computed: {
        roundedProgress() {
            return Math.round(this.progress)
        },
    },
}
</script>

<style scoped>
.progress-container {
    max-width: 6em;
    width: 100%;
}
</style>
