<template>
    <page>
        <history-log-table :client="client" :changelogs="changelogs" @date_filter_change="onDateRangeChange" />
    </page>
</template>

<script>
import HistoryLogTable from '@/components/HistoryLogTable'
import Page from '@/components/Page'

export default {
    props: ['client', ],
    components: {HistoryLogTable, Page, },
    data() {
        return {
            changelogs: [],
            dateRangeStart: null,
            dateRangeEnd: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Change History')
        this.getChangelogs()
    },
    methods: {
        getChangelogs() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/history-logs`
            if (this.dateRangeStart && this.dateRangeEnd) {
                url = `${url}?date-filter.history_logs.start=${encodeURIComponent(this.dateRangeStart)}&date-filter.history_logs.end=${encodeURIComponent(this.dateRangeEnd)}`
            }

            this.$api.get(url).then(resp => {
                this.changelogs= resp
                this.changelogs= resp.filter(c =>
                    (!c.company_security_profile_id &&
                    !c.security_profile_id &&
                    !c.hr_document_id &&
                    !c.hr_template_id)
                )
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onDateRangeChange(range) {
            this.dateRangeStart = range.start
            this.dateRangeEnd = range.end
            this.getChangelogs()
        },
    },
}

</script>

