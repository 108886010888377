<template>
    <span class="flex-grow-1">
        <form-input type="search" placeholder="Search" v-model="search" @update:model-value="$emit('update:modelValue', $event)" />
    </span>
</template>

<script>
export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            search: this.modelValue
        }
    },
}
</script>
