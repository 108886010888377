<template>
    <modal size="xl" @close="$emit('close')" ref="modal">
        <template v-slot:title>
          Mark Resolved
          <span v-if="w2detail"> - {{ w2detail.w2.tax_year }}</span>
     </template>

        <template v-slot:subheading>
            <div v-if="w2detail">
               {{ w2detail.w2.company.name }} /
               {{ w2detail.w2.employee.full_name }}
            </div>
        </template>

        <slot>
            <div v-if="w2detail">
                <br>
                <form-errors :errors="formErrors"/>

                <div class="form-row pb-2">
                    <div class="col">
                        <form-input v-model="formData.action" :errors="errors.action" type="radio" label="Which data to use?" :options="actionOptions"/>
                    </div>
                </div>

                <div class="form-row pb-2">
                    <div class="col">
                        <form-input type="textarea" v-model="formData.note" :errors="errors.note" label="Resolution note" />
                    </div>
                </div>
            </div>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['w2reviewdetail'],
    data() {
        const actionOptions = [
            {text: 'Calculated', value: 'calculated'},
            {text: 'Filed', value: 'filed'},
        ]

        const rules = {
            action: {presence: {allowEmpty: false}, },
            note: {presence: {allowEmpty: false}, },
        }

        return {
            w2detail: null,
            dataLoaded: false,
            formRules: rules,
            formData: this.makeFormData(),
            actionOptions: actionOptions,
        }
    },
    mounted() {
        this.w2detail = this.w2reviewdetail
    },
    watch: {
        w2reviewdetail() {
            this.w2detail = this.w2reviewdetail
        }
    },
    methods: {
        makeFormData() {
            let formData = {}
            formData = {
                note: '',
                action: '',
            }

            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let url = `/admin/w2-review/detail/${this.w2detail.w2.id}/mark-resolved`

            this.$store.dispatch('START_LOADING')
            api.post(url, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo(`Marked resolved`)
                this.close()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
