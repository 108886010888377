<template>
    <div ref="printable">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered py-4">

                        <!-- Header Messaging -->
                        <div class="d-flex mb-1">
                            <p class="flex-grow-1 pr-2 btnlike">
                                Before you proceed, please acknowledge the <strong>{{ warnings.length }} critical warning{{ warnings.length == 1 ? '' : 's' }}</strong> in this account:
                            </p>
                            <p class="flex-0">
                                <button class="btn btn-outline-primary btn-collapse-rs ml-1" @click.prevent="printToPDF()">
                                    <i class="far fa-fw fa-file-pdf"></i>
                                    <span class="btn-text ml-hf">PDF</span>
                                </button>
                            </p>
                        </div>

                        <div v-for="company in companiesWithWarnings">

                            <h3 class="h4 text-700 mb-2" v-if="client.companies.length > 1">{{ company.name }} <span class="text-thin">({{ warningsByCompany[company.id].length }})</span></h3>

                            <!-- Warning Categories List -->
                            <div class="card card-body p-0 mb-2">

                                <!-- Warning Category object -->
                                <template v-for="warningType in warningTypes">
                                <div class="p-2 boxed-li-bottom" v-if="(warningType in warningsByCompanyAndType[company.id]) && warningCountsByCompanyAndType[company.id][warningType]">
                                    <div class="d-flex">
                                        <div class="flex-0 mr-2 mr-rs-3">
                                            <div class="h4 counter counter-warnings">
                                                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings: "></i>
                                                <span class="counter-label">{{ warningCountsByCompanyAndType[company.id][warningType] }}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <h4 class="pt-hf unpad-t text-danger-dark">
                                                {{ warningDescriptionsByType[warningType].warning_type_pretty }}
                                                <more-info>
                                                    <vue-markdown v-if="warningDescriptionsByType[warningType].warning_info" :source="warningDescriptionsByType[warningType].warning_info"/>
                                                </more-info>
                                            </h4>
                                            <ul class="list-unstyled mb-0">
                                                <li v-for="w in warningsByCompanyAndType[company.id][warningType]" class="text-small mb-qt">
                                                    <div v-if="w.warning_type == 'sensitive-info' || w.warning_type == 'doc-collection-settings-changed'">
                                                        <b>{{ w.csp_name }}</b>
                                                        <div class="my-1" v-for="w_item in w.items">
                                                            <i>Employee:</i> {{ w_item.employee_full_name }}
                                                            <br>
                                                            <i>Document:</i> {{ w_item.document_description }}
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div v-if="w.warning_detail_description">{{ w.warning_detail_description }}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                </template>

                            </div>
                        </div>

                        <button-row stacked class="mb-2 d-print-none">
                            <router-link class="btn btn-outline-primary" :to="{name: 'index'}">
                                Back to Accounts
                            </router-link>
                            <button class="btn btn-primary" @click.prevent="acknowledgeWarnings">
                                Acknowledge {{ warnings.length }} Warning{{ warnings.length == 1 ? '' : 's' }}
                            </button>
                        </button-row>

                        <!-- Footer Messaging -->
                        <p>
                            To view all current and past warnings, go to <strong>Warnings</strong> from your account homepage.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .form-moreinfo-trigger {
        margin-top: 0 !important;
        display: inline !important;
    }
</style>

<script>
import PrintableMixin from '@/mixins/Printable'
import ButtonRow from '@/components/ButtonRow'
import VueMarkdown from 'vue-markdown-render'

export default {
    props: ['client', ],
    mixins: [PrintableMixin],
    components: {ButtonRow, VueMarkdown},
    data() {
        return {
            warnings: [],
            'printableItemType': 'critical-warnings',
            'printableItemId': this.client.id,
        }
    },
    computed: {
        clientId() {
            return this.$hasher.decode(this.$route.params.hashedClientId)
        },
        companiesWithWarnings() {
            return this.client.companies.filter(c => this.warningsByCompany[c.id] && this.warningsByCompany[c.id].length)
        },
        warningsByCompany() {
            const byCompany = {}
            this.warnings.forEach(w => {
                if (!byCompany[w.company.id]) {
                    byCompany[w.company.id] = []
                }

                byCompany[w.company.id].push(w)
            })

            return byCompany
        },
        warningTypes() {
            const types = new Set()
            this.warnings.forEach(w => {
                types.add(w.warning_type)
            })

            return Array.from(types)
        },
        warningDescriptionsByType() {
            const result = {}
            this.warnings.forEach(w => {
                if (!result[w.warning_type]) {
                    result[w.warning_type] = {
                        'warning_type_pretty': w.warning_type_pretty,
                        'warning_text': w.warning_text,
                        'warning_info': w.warning_info,
                        'module_pretty': w.module_pretty,
                    }
                }
            })

            return result
        },
        warningCountsByCompanyAndType() {
            const result = {}
            Object.keys(this.warningsByCompany).forEach(cId => {
                result[cId] = {}
                this.warningsByCompany[cId].forEach(w => {
                    if (!result[cId][w.warning_type]) {
                        result[cId][w.warning_type] = 0
                    }

                    result[cId][w.warning_type] += 1
                })
            })

            return result
        },
        warningsByCompanyAndType() {
            const result = {}
            Object.keys(this.warningsByCompany).forEach(cId => {
                result[cId] = {}
                this.warningsByCompany[cId].forEach(w => {
                    // 'sensitive-info' and 'doc-collection-settings-changed' warnings are further grouped
                    if (w.warning_type == 'sensitive-info' || w.warning_type == 'doc-collection-settings-changed') {
                        if (!result[cId][w.warning_type]) {
                            result[cId][w.warning_type] = {}
                        }

                        let csp_key = w.company_security_profile.security_profile.full_name
                        if (!result[cId][w.warning_type][csp_key]) {
                            result[cId][w.warning_type][csp_key] = {
                                'warning_type': w.warning_type,
                                'csp_name': csp_key,
                                'items': [],
                            }
                        }
                        result[cId][w.warning_type][csp_key]['items'].push({
                            'employee_full_name': w.warning_detail_data.employee_full_name,
                            'document_description': w.warning_detail_data.document_description,
                        })
                    } else {
                        if (!result[cId][w.warning_type]) {
                            result[cId][w.warning_type] = []
                        }

                        result[cId][w.warning_type].push(w)
                    }
                })
            })

            return result
        },
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Critical Warnings')
        this.getWarnings()
    },
    methods: {
        getWarnings() {
            const url = `/clients/${this.clientId}/warnings/active`

            this.$store.dispatch('START_LOADING')
            return this.$api.get(url).then(resp => {
                this.warnings = resp.filter(w => w.is_critical)
                if (this.warnings.length < 1) {
                    this.$router.push({name: 'employer-home', params: {hashedClientId: this.$hasher.encode(this.clientId)}})
                    return
                }

                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            })
        },
        acknowledgeWarnings() {
            const url = `/clients/${this.clientId}/warnings/active`
            this.$store.dispatch('START_LOADING')
            return this.$api.post(url, {warnings: this.warnings.map(w => w.id)}).then(resp => {
                this.warnings = resp.filter(w => w.is_critical)
                if (this.warnings.length < 1) {
                    if (this.$route.query.next) {
                        return this.$router.push(this.$route.query.next)
                    }
                    return this.$router.push({name: 'employer-home', params: {hashedClientId: this.$hasher.encode(this.clientId)}})
                }

                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
    }
}
</script>

