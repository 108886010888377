
import states from '@/states'
import countries from '@/countries'

const data = {
    yearsThisNext: [
        {slug: 'this_year', text: '' + (new Date()).getFullYear()},
        {slug: 'next_year', text: '' + ((new Date()).getFullYear() + 1)},
    ],
    states: [],
    statesAbbr: [],
    statesCanadaMexico: [],
    statesCanadaMexicoAbbr: [],

    countries: [],
    countriesAbbr: [],
}


states.forEach(s => {
    data.states.push({slug: s.abbreviation, text: s.name.toUpperCase()})
    data.statesAbbr.push({slug: s.abbreviation, text: s.abbreviation.toUpperCase()})
    data.statesCanadaMexico.push({slug: s.abbreviation, text: s.name.toUpperCase()})
    data.statesCanadaMexicoAbbr.push({slug: s.abbreviation, text: s.abbreviation.toUpperCase()})
})

data.statesCanadaMexico.push({slug: 'CAN', text: 'Canada'.toUpperCase()})
data.statesCanadaMexico.push({slug: 'MEX', text: 'Mexico'.toUpperCase()})

data.statesCanadaMexicoAbbr.push({slug: 'CAN', text: 'CAN'})
data.statesCanadaMexicoAbbr.push({slug: 'MEX', text: 'MEX'})

countries.forEach(c => {
    data.countries.push({slug: c.abbreviation, text: c.name.toUpperCase()})
    data.countriesAbbr.push({slug: c.abbreviation, text: c.abbreviation.toUpperCase()})
})

export default data
