<template>
    <modal @close="$emit('close')" ref="modal" size="lg">
        <template v-slot:title>Privacy Policy</template>

        <template v-slot:headerbuttons>
            <button class="btn btn-outline-primary btn-collapse-xl ml-1" @click.prevent="$refs.privacyPolicy.printToPDF()">
                <i class="far fa-fw fa-file-pdf"></i>
                <span class="sr-only">Print</span>
            </button>
        </template>

        <slot>
            <div>
                <privacy-policy ref="privacyPolicy" />
            </div>
        </slot>

        <template v-slot:footer>
            <button-row center>
                <button type="button" class="btn btn-primary" @click.prevent="close()">Close</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import PrivacyPolicy from '@/components/Policies/PrivacyPolicy'

export default {
    mixins: [ModalMixin, ],
    data() {
        return {
        }
    },
    components: {PrivacyPolicy, },
}
</script>
