<template>
<div v-if="dataLoaded">
    <div class="flex-body body-scroll">
        <div class="container-fluid">
            <div class="container-centered-l py-4">

                <direct-deposit-form
                    :direct-deposit-accounts="directDepositAccounts"
                    :url="url"
                    :has-invalid-direct-deposit="hasInvalidDirectDeposit"
                    @updated="getData()"
                    :readonly="!company.has_enhanced_ess"
                />

            </div>
        </div>
    </div>
</div>
</template>


<script>
import DirectDepositForm from '@/components/DirectDepositForm'
import moment from 'moment'

export default {
    props: ['company'],
    components: {DirectDepositForm, },
    computed: {
        url() {
            return `/me/ess/companies/${this.company.id}/direct-deposit-accounts`
        },
        hasInvalidDirectDeposit() {
            if (this.directDepositAccounts && this.directDepositAccounts.length) {
                return this.directDepositAccounts[0].employee.has_invalid_direct_deposit
            }
            return false
        }
    },
    data() {
        return {
            directDepositAccounts: [],
            dataLoaded: false,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/me/ess/companies/${this.company.id}/direct-deposit-accounts`).then(resp => {
                this.directDepositAccounts = resp
                this.dataLoadedTimestamp = moment().format('X')
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        editDirectDeposit() {},
    },
}

</script>
