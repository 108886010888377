<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Case Creation</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    E-Verify cases are created as part of GetMyPayStub Onboarding, or can be created manually in the E-Verify tile if the employee did not complete onboarding.
                                </p>

                                <p>
                                    The case creation screen will pull information from the employee's I-9 (if Onboarding was done in GetMyPayStub).
                                </p>

                                <p>
                                    The employment eligibility verification process begins with a completed Form I-9. E-Verify confirms employment eligibility by comparing the employee's Form I-9 information entered in E-Verify by the employer with the information in records available to the Social Security Administration (SSA) and/or Department of Homeland Security (DHS).
                                </p>

                                <p>
                                    An E-Verify case must be created within three business days after the employee's first day of employment. 
                                </p>

                                <h5>Employee's First Day of Employment</h5>

                                <p>
                                    The employee's first day of employment is the date the employee begins working in exchange for wages or other remuneration. This is recorded in Section 2 of Form I-9. If Form I-9 is completed after the employee accepts the offer of employment, but before the actual start of work for pay or other remuneration, it is possible that the first day of employment recorded on Form I-9 could change after the employer created the case in E-Verify. If this happens, no additional action is required in E Verify as the first day of employment cannot be changed once the case has been submitted. If the employee's first day of employment changes, employers should correct the date originally indicated in the certification block in Section 2 on the employee's Form I-9.
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page1c'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page2a'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
