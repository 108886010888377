<template>
    <div>
        <div v-if="customForm" class="custom-form-survey">
            <h3 v-if="formName">{{ formName }}</h3>
            <div class="card">
                <div class="card-body py-1 mt-1">
                    <div class="custom-form-questions">
                        <div class="custom-form-question mb-1" v-for="(question, idx) in formData.questions" :key="idx">
                            <div v-if="question.question_type == 'select-one'">
                                <p class="question-prompt mb-hf">{{ question.prompt || 'Untitled Question' }}<span v-if="question.is_required" class="text-primary"> *</span></p>
                                <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                                <form-input
                                    :options="makeInputOptionsFromQuestionOptions(question.options)"
                                    :type="getInputTypeFromQuestionType(question.question_type)"
                                    v-model="question.response"
                                    :errors="question.errors"
                                />
                            </div>

                            <div v-else-if="question.question_type == 'dropdown'">
                                <p class="question-prompt mb-0">{{ question.prompt || 'Untitled Question' }}<span v-if="question.is_required" class="text-primary"> *</span></p>
                                <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                                <form-input
                                    :options="makeInputOptionsFromQuestionOptions(question.options)"
                                    :type="getInputTypeFromQuestionType(question.question_type)"
                                    v-model="question.response"
                                    :errors="question.errors"
                                />
                            </div>

                            <div v-else-if="question.question_type == 'select-multiple'" class="mb-3">
                                <p class="question-prompt mb-hf">{{ question.prompt || 'Untitled Question' }}<span v-if="question.is_required" class="text-primary"> *</span></p>
                                <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                                <p class="field-helper-text mt-0 mb-1 text-danger" v-for="e in question.errors" :key="e">
                                    <i aria-hidden="true" title="Error" class="fas fa-fw fa-exclamation-circle"></i>
                                    <span class="sr-only">Error:</span><span>{{ e }}</span>
                                </p>
                                <form-input
                                    v-for="(option, idx) in question.options"
                                    :key="idx"
                                    :label="option"
                                    :type="getInputTypeFromQuestionType(question.question_type)"
                                    class="mb-1"
                                    :model-value="isOptionChecked(question, option)"
                                    @update:model-value="setOptionChecked($event, question, option)"
                                />
                            </div>

                            <div v-else-if="question.question_type == 'address'">
                                <p class="question-prompt mb-1">{{ question.prompt || 'Untitled Question' }}<span v-if="question.is_required" class="text-primary"> *</span></p>
                                <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                                <form-input label="Address 1" type="text" :maxlength="50" v-model="question.response.address_1" :errors="question.errors.address_1"/>
                                <form-input label="Address 2 (Optional)" type="text" :maxlength="50" v-model="question.response.address_2" :errors="question.errors.address_2"/>
                                <form-input label="City" type="text" :maxlength="50" v-model="question.response.city" :errors="question.errors.city"/>

                                <div class="form-row">
                                    <div class="col-6">
                                        <form-input label="State" type="select" :options="stateOptions" v-model="question.response.state" :errors="question.errors.state"/>
                                    </div>

                                    <div class="col-6">
                                        <form-input label="Zip Code" :maxlength="5" type="text" inputmode="tel" v-model="question.response.postal_code" :errors="question.errors.postal_code"/>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="question.question_type == 'upload'" class="mb-4">
                                <p class="question-prompt mb-hf">{{ question.prompt || 'Untitled Question' }}<span v-if="question.is_required" class="text-primary"> *</span></p>
                                <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                                <input class="d-none" type="file" @change="onUpload($event, question)" accept=".pdf,.jpg,.jpeg,.png,.heic,.heif;capture=camera" :ref="`fileInput-${idx}`" />
                                <button v-if="!question.response" class="btn btn-outline-primary btn-boxy" @click.prevent="$refs[`fileInput-${idx}`][0].click()">
                                    <i class="far fa-camera mr-hf"></i>
                                    <span class="mr-8">Take Photo</span>
                                    <i class="far fa-upload mr-hf"></i>
                                    <span>Upload File</span>
                                </button>
                                <div v-if="question.response">
                                    <div class="docphoto">
                                        <img :src="getPreviewURL(question.response)" alt="Uploaded Photo">
                                    </div>

                                    <div class="text-small pt-1">
                                        Not quite right? <button class="btn btn-link-inline" @click.prevent="question.response = null">Add a different photo instead</button>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="question.question_type == 'phone-number'">
                                <p class="question-prompt mb-0">{{ question.prompt || 'Untitled Question' }}<span v-if="question.is_required" class="text-primary"> *</span></p>
                                <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                                <form-input
                                    v-model="question.response"
                                    :errors="question.errors"
                                    :type="getInputTypeFromQuestionType(question.question_type)"
                                    placeholder="XXX-XXX-XXXX"
                                />
                            </div>

                            <div v-else>
                                <p class="question-prompt mb-0">{{ question.prompt || 'Untitled Question' }}<span v-if="question.is_required" class="text-primary"> *</span></p>
                                <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                                <form-input
                                    v-model="question.response"
                                    :errors="question.errors"
                                    :type="getInputTypeFromQuestionType(question.question_type)"
                                    :nocaps="true"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import states from '@/states'
import moment from 'moment'
import FormErrors from './FormErrors.vue'
import ImageConverter from '@/utils/image-converter'

const VALIDATORS = {
    'email': function(value) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(value)) {
            return 'Please enter a valid email address.';
        }

        return null;
    },
    'us_phone': function(value) {
        var re = /^[0-9]{10}$/;

        if (!re.test(value)) {
            return 'Please enter a valid 10 digit phone number.';
        }

        return null;
    },
    'us_can_mex_postcode': function(value) {
        if (!value || !value.trim() || value.trim().length < 5 || value.trim().length > 6) {
            return 'Please enter a valid post code.'
        }

        value = value.trim()

        if ((value.length == 6) && (!/^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/.test(value))) {
            return 'Please enter a valid post code.'
        }

        if ((value.length == 5) && (!/^\d\d\d\d\d$/.test(value))) {
            return 'Please enter a valid post code.'
        }

    },
    // TODO KB add date validator
}

export default {
    props: ['customForm', 'isPreview'], // customForm should be an EmployeeHRCustomForm or OnboardingApplicationHRCustomForm (or in-progress form data approximating its structure in the case of a preview)
    components: {FormErrors},
    data() {
        let data = {
            formData: this.makeFormData()
        }
        return data
    },
    mounted() {},
    computed: {
        stateOptions() {
            const result = []
            states.forEach((s) => {
                result.push({text: s.name.toUpperCase(), value: s.abbreviation})
            })
            return result
        },
        formName() {
            if (this.customForm.hr_custom_form) {
                return this.customForm.hr_custom_form.name
            } else {
                return ""
            }
        }
    },
    methods: {
        makeFormData() {
            // if there is already a response to this form, pre-fill it
            if (this.customForm.filled_on) {
                return {
                    'questions': JSON.parse(JSON.stringify(this.customForm.response_data))
                }
            }
            // else create blank questions from form
            let formData = {
                'questions': []
            }
            let questions = JSON.parse(JSON.stringify(this.customForm.hr_custom_form_version.questions))
            questions.forEach((q) => {
                let qFormData = {
                    'hr_custom_form_version_id': q.hr_custom_form_version_id,
                    'hr_custom_form_question_id': q.id,
                    'idx': q.idx,
                    'prompt': q.prompt,
                    'instructions': q.instructions,
                    'is_required': q.is_required,
                    'options': q.options,
                    'question_type': q.question_type,
                    'use_as_expiration': q.use_as_expiration,
                    'response': '',
                    'errors': [],
                }
                if (qFormData.question_type == 'address') {
                    qFormData.response = {
                        'address_1': '',
                        'address_2': '',
                        'city': '',
                        'state': '',
                        'postal_code': '',
                    }
                    qFormData.errors = {
                        'address_1': [],
                        'address_2': [],
                        'city': [],
                        'state': [],
                        'postal_code': [],
                    }
                } else if (qFormData.question_type == 'select-multiple') {
                    qFormData.response = []
                }
                formData.questions.push(qFormData)
            })
            return formData
        },
        makeInputOptionsFromQuestionOptions(options) {
            return options.filter(o => o).map(option => {
                return { text: option, value: option };
            });
        },
        getInputTypeFromQuestionType(type) {
            let typeMap = {
                'short-answer': 'text',
                'paragraph': 'textarea',
                'select-one': 'radio',
                'select-multiple': 'checkbox',
                'dropdown': 'select',
                'date': 'date', // TODO KB consider using datepicker
                'address': 'text',
                'phone-number': 'tel',
                'email': 'email',
            }
            if (type in typeMap) {
                return typeMap[type]
            }
            return 'input'
        },
        setOptionChecked(checked, question, option) {
            if (checked && !question.response.includes(option)) {
                question.response.push(option)
            } else if (!checked) {
                question.response = question.response.filter(o => o != option)
            }
        },
        isOptionChecked(question, option) {
            return question.response.includes(option)
        },
        getFormData() {
            return {
                'date': moment().format('MM/DD/YYYY'),
                'response_data': this.formData.questions
            }
        },
        validate() {
            let validated = true
            for (let q of this.formData.questions) {
                // reset errors
                q.errors = []
                if (q.question_type == 'address') {
                    q.errors = {
                        'address_1': [],
                        'address_2': [],
                        'city': [],
                        'state': [],
                        'postal_code': [],
                    }
                }
                // check for errors
                if (q.is_required && !q.response) {
                    q.errors = ['This field is required']
                    validated = false
                }
                if (q.question_type == 'select-multiple') {
                    if (q.is_required && !(q.response && q.response.length)) {
                        q.errors = ['You must select at least one.']
                        validated = false
                    }
                }
                if (q.question_type == 'address') {
                    ['address_1', 'state', 'city', 'postal_code'].forEach(f => {
                        if (q.is_required && !q.response[f]) {
                            q.errors[f] = ['This field is required']
                            validated = false
                        }
                        if (f == 'postal_code') {
                            let e = VALIDATORS['us_can_mex_postcode'](q.response[f])
                            if (e) {
                                q.errors[f] = [e]
                                validated = false
                            }
                        }
                    })
                }
                if (q.question_type == 'email') {
                    let e = VALIDATORS['email'](q.response)
                    if (e) {
                        q.errors = [e]
                    }
                }
                if (q.question_type == 'phone-number') {
                    let e = VALIDATORS['us_phone'](q.response)
                    if (e) {
                        q.errors = [e]
                    }
                }
            }
            return validated
        },
        onUpload(evt, question) {
            this.formErrors = []
            const files = evt.target.files || evt.dataTransfer.files
            if (!files.length) {
                return
            }

            const reader = new FileReader()
            reader.onload = () => {
                const photo = {
                    filename: files[0].name,
                    size: files[0].size,
                    file_type: files[0].type,
                    data: reader.result,
                }

                this.$store.dispatch('START_LOADING')
                ImageConverter.convert(files[0]).then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    photo.data = resp.data  // Base64 encoded
                    photo.file_type = resp.mime
                    question.response = photo
                    evt.target.value = '' // reset input
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    Object.keys(errors).forEach(field => {
                        if (field.substring(0, 2) == '__' && field != '__all__') {return}
                        this.formErrors = this.formErrors.concat(errors[field])
                    })

                    this.$bus.showError(this.formErrors)
                })
            }
            reader.readAsDataURL(files[0])
        },
        getPreviewURL(response) {
            if (response) {
                if (response.photo_id) {
                    if (this.$route.meta.mode == 'ess') {
                        if (this.customForm.employee_id) {
                            return `${this.$api.API_BASE}/me/ess/companies/${this.customForm.hr_custom_form.company_id}/hr-custom-forms/${this.customForm.id}/photos/${response.photo_id}`
                        } else if (this.customForm.onboarding_application_id) {
                            return `${this.$api.API_BASE}/me/onboarding/${this.customForm.onboarding_application_id}/onboarding-application/hr-custom-forms/${this.customForm.id}/photos/${response.photo_id}`
                        }
                    } else if (this.$route.meta.mode == 'client') {
                        if (this.customForm.employee_id) {
                            return `${this.API_BASE}/clients/${this.customForm.hr_custom_form.client_id}/employees/${this.customForm.employee_id}/hr-custom-forms/${this.customForm.id}/photos/${response.photo_id}`
                        } else if (this.customForm.onboarding_application_id) {
                            return `${this.API_BASE}/clients/${this.customForm.hr_custom_form.client_id}/onboarding-applications/${this.customForm.onboarding_application_id}/hr-custom-forms/${this.customForm.id}/photos/${response.photo_id}`
                        }
                    }
                } else if (response.data) {
                    return `data:${response.file_type};base64,${response.data}`
                }
            }
            return ''
        },
    },
}
</script>

<style lang="scss">
    .custom-form-survey {
        .card {
            background-color: white;
        }
        .form-input-radio {
            .list-unstyled {
                padding-left: 0 !important;
            }
            .form-check-set {
                padding-left: 0;
                margin-left: 0;
                border-left: 0;
                .form-check {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
</style>
