<template>
    <div>
        <div class="flex-body pb-2">
            <br><br><br><br>
            <div class="text-center">
                <div class="mx-auto d-flex flex-column justify-content-center">
                    <div class="spinner mx-auto position-relative mb-2">
                        <div class="lds-grid">
                            <div></div><div></div><div></div>
                            <div></div><div></div><div></div>
                            <div></div><div></div><div></div>
                        </div>
                    </div>
                    <div class="position-relative mx-auto">
                        <h4>Importing</h4>
                        <ul class="list-unstyled process-spinner-messages">
                            <li class="d-block">
                                Importing to Payroll
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '@/api'

import moment from 'moment'

export default {
    props: ['client'],
    computed: {
        taskId() {
            return this.$route.params.taskId
        },
    },
    data() {
        return {
            pollInterval: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tips Allocator')
        this.pollInterval = setInterval(() => {this.pollTask(this.$route.params.taskId)}, 2000)
    },
    methods: {
        pollTask(taskId) {
            let url = `/clients/${this.client.id}/tips-allocator/${taskId}/poll`
            api.get(url).then((resp) => {
                if (resp['task']['status_display'].toUpperCase() == 'COMPLETE') {
                    clearInterval(this.pollInterval)
                    this.$router.push({name: 'tips-allocator-complete', params: {hashedClientId: this.$route.params.hashedClientId, taskId: this.taskId}});
                } else if (resp['task']['status_display'].toUpperCase() == 'ERROR') {
                    clearInterval(this.pollInterval)
                    this.$router.push({name: 'tips-allocator-error', params: {hashedClientId: this.$route.params.hashedClientId, taskId: this.taskId}});
                } else {
                    // do nothing, keep polling
                }
            })
        },
    },
}
</script>
