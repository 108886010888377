<template>
    <div class="text-small">
        <div class="mb-2">
            <p class="mb-hf text-bold">Uploaded by Manager:</p>
            <div class="mb-1 text-muted" v-if="!employerDocuments.length">
                No document photos
            </div>

            <ul class="list-unstyled mb-1 ml-1" v-if="employerDocuments.length">
                <li class="py-hf" v-for="doc in employerDocuments">
                    <div v-if="!doc.photos.length && !mostRecentI9">
                        None
                    </div>
                    <div v-else>
                        <button class="btn btn-reset btn-link d-flex justify-content-center align-items-center" @click.prevent="onManagerDocClick(doc)">
                            <div v-if="doc.photos.length" class="flex-grow-0 docphoto-thumb mr-1" :style="{ backgroundImage: 'url(' + $api.API_BASE + '/clients/' + client.id + '/onboarding-applications/' + onboardingApplicationId + '/forms/' + form.id + '/photos/' + doc.photos[0].id + '/preview)' }"></div>
                            <div v-if="!doc.photos.length && mostRecentI9" class="flex-grow-0 docphoto-thumb docphoto-thumb-button mr-1">
                                <i class="far fa-plus" aria-hidden="true" title="Add"></i>
                                <span class="sr-only">Add</span>
                            </div>

                            <div class="flex-grow-1">{{ doc.document_title }}</div>
                        </button>
                    </div>
                </li>
            </ul>
        </div>

        <div>
            <p class="mb-hf text-bold">Uploaded by Employee:</p>
            <div class="mb-1 text-muted" v-if="!employeeDocuments.length">
                No document photos
            </div>

            <ul class="list-unstyled mb-1 ml-1" v-if="employeeDocuments.length">
                <li class="py-hf" v-for="doc in employeeDocuments">
                    <button class="btn btn-reset btn-link d-flex justify-content-center align-items-center" @click.prevent="currentDocument = doc; documentPhotosModalOpen = true">
                        <div class="flex-grow-0 docphoto-thumb mr-1" :style="{ backgroundImage: 'url(' + $api.API_BASE + '/clients/' + client.id + '/onboarding-applications/' + onboardingApplicationId + '/forms/' + form.id + '/photos/' + doc.photos[0].id + '/preview)' }">
                        </div>
                        <div class="flex-grow-1">{{ doc.document_title }}</div>
                    </button>
                </li>
            </ul>
        </div>

        <document-photos-modal
            v-if="documentPhotosModalOpen"
            :client="client"
            :company="company"
            :onboarding-application-id="onboardingApplicationId"
            :doc="currentDocument"
            :form="form"
            :most-recent-i9="mostRecentI9"
            @close="documentPhotosModalOpen = false; currentDocument = null"
            @updated="$emit('updated')"
        />

    </div>
</template>

<style scoped lang="scss">
  // Import Sass globals
  @import "@/assets/scss/globals";

  // 
  // Component styles
  //

  // Tiny thumbnail image of document
  .docphoto-thumb {
    background-color: gray("150");
    // background-image set inline above
    background-position: center;
    background-size: cover;
    border: solid 1px $border-color;
    border-radius: 3px;
    height: 24px;
    width: 24px;
    line-height: 23px; // 24px results in slightly off-center text
    text-align: center;
  }

  // "Add Photo" (+) button
  .docphoto-thumb-button {
    background-color: $link-color;
    border-color: $link-color;
    color: $white;

    // Hover behavior
    *:hover > &, 
    *:active > &, 
    *:focus > & {
      background-color: $link-hover-color;
      border-color: $link-hover-color;
      text-decoration: none;
    }
  }
</style>

<script>
import DocumentPhotosModal from './DocumentPhotosModal'
import i9Documents from '@/utils/i9'

export default {
    props: ['client', 'company', 'onboardingApplicationId', 'form', 'mostRecentI9', ],
    components: {DocumentPhotosModal, },
    data() {
        return {
            documentPhotosModalOpen: false,
            currentDocument: null,
        }
    },
    computed: {
        employeeDocuments() {
            const result = []
            this.form.photos.filter(p => !p.is_manager_uploaded).forEach(p => {
                const doc = result.find(x => x.document_slug == p.document_slug)
                if (!doc) {
                    result.push({
                        document_title: p.document_title,
                        document_slug: p.document_slug,
                        document_list: p.document_list,
                        is_manager_uploaded: false,
                        photos: [p],
                        docDetails: doc,
                    })
                }
                else {
                    doc.photos.push(p)
                    doc.photos.sort(a => {
                        return (a.side == 'front') ? -1 : 1
                    })
                }
            })
            return result
        },
        employerDocuments() {
            const result = []
            const addDoc = (doc, list) => {
                const x = {
                    document_title: doc.shortName ? doc.shortName : doc.name,
                    document_slug: doc.slug,
                    document_list: list,
                    requiresBackPhoto: doc.requiresBackPhoto,
                    is_manager_uploaded: true,
                    photos: [],
                    docDetails: doc,
                }

                const front = this.form.photos.find(p => p.document_slug == doc.slug && p.is_manager_uploaded && p.side == 'front')
                const back = this.form.photos.find(p => p.document_slug == doc.slug && p.is_manager_uploaded && p.side == 'back')
                if (front) {
                    x.photos.push(front)
                    if (back) {
                        x.photos.push(back)
                    }
                }

                result.push(x)
            }

            if (this.form.employer_form_data.doc_a_slug) {
                const doc = this.findI9Document(this.form.employer_form_data.doc_a_slug)
                addDoc(doc, 'list-a')

                if (this.form.employer_form_data.doc_a_secondary_slug) {
                    addDoc(this.findI9Document(doc.slug, this.form.employer_form_data.doc_a_secondary_slug), 'list-a-1')
                }
                if (this.form.employer_form_data.doc_a_tertiary_slug) {
                    addDoc(this.findI9Document(doc.slug, '', this.form.employer_form_data.doc_a_tertiary_slug), 'list-a-2')
                }
            }

            if (this.form.employer_form_data.doc_b_slug) {
                addDoc(this.findI9Document(this.form.employer_form_data.doc_b_slug), 'list-b')
            }

            if (this.form.employer_form_data.doc_c_slug) {
                addDoc(this.findI9Document(this.form.employer_form_data.doc_c_slug), 'list-c')
            }

            return result
        },
    },
    methods: {
        findI9Document(slug, secondarySlug, tertiarySlug) {
            let doc = i9Documents.listA.find(d => d.slug == slug)
            if (!doc) {
                doc = i9Documents.listB.find(d => d.slug == slug)
            }
            if (!doc) {
                doc = i9Documents.listC.find(d => d.slug == slug)
            }
            if (!doc) {return null}

            if (secondarySlug) {
                return doc.secondary_documents.find(d => d.slug == secondarySlug)
            }

            if (tertiarySlug) {
                return doc.tertiary_documents.find(d => d.slug == tertiarySlug)
            }

            return doc
        },
        onManagerDocClick(doc) {
            this.currentDocument = doc
            this.documentPhotosModalOpen = true
        },
    },
}
</script>
