<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">
      
                        <h5>{{ onboardingApplication.company.name }}</h5>
                        <h3>Equal Employment Opportunity Self-Identification</h3>

                        <p>
                            Your employer is subject to certain governmental recordkeeping and reporting requirements for the administration of civil rights laws and regulations. To comply with these laws, your employer invites employees to voluntarily identify their race/ethnicity and gender. Submission of this information is voluntary and refusal to provide it will not subject you to any adverse treatment. The information obtained will be kept confidential and may only be used in accordance with the provision of applicable federal laws, executive orders, and regulations, including those which require the information to be summarized and reported to the Federal Government for civil rights enforcement purposes. When reported, data will not identify any specific individual.
                        </p>

                        <p>
                            If you choose not to identify your race/ethnicity or gender at this time, the federal government requires your employer to determine this information by visual observation and/or other available information.
                        </p>

                        <p>
                            For civil rights monitoring and enforcement purposes only, all race/ethnicity and gender information will be collected and reported in the categories identified below. The definitions for each category have been established by the federal government. If you choose to voluntarily self-identify, you may mark only one of the boxes in each section presented below.
                        </p>

                        <form @submit.prevent="onSubmit">
                            <form-errors :errors="formErrors"/>

                            <div class="form-row">
                                <div class="col-12">
                                    <form-input label="Legal Gender (Select only one):" v-model="formData.gender" :errors="errors.gender" type="radio" :options="genderOptions" extraclasses="label--bold"/>
                                    <div class="ml-3" v-if="formData.gender == 'custom'">
                                        <form-input placeholder="Your Gender" v-model="formData.gender_custom" :errors="errors.gender_custom" type="text" />
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-12">
                                    <form-input label="Racial/Ethnic Identification (Select only one):" v-model="formData.ethnic_origin" :errors="errors.ethnic_origin" type="radio" :options="ethnicOriginOptions" extraclasses="label--bold"/>
                                </div>
                            </div>

                            <p class="mb-4 p-2 border">
                                <b>Anti-Discrimination Notice.</b> It is an unlawful employment practice for an employer to fail or refuse to hire or discharge any individual, or otherwise to discriminate against any individual with respect to that individual's terms and conditions of employment, because of such individual's race, color, religion, sex, or national origin. 
                            </p>
                            
                            <button-row inline>
                                <router-link v-if="previousPage" :to="previousPage" class="btn btn-outline-primary">Back</router-link>
                                <button class="btn btn-primary">Continue</button>
                            </button-row>
                        </form>

                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import api from '@/api'
import WizardMixin from './WizardMixin'
import ButtonRow from '@/components/ButtonRow'

const genderOptions = [
    {text: 'Female', value: 'Female'},
    {text: 'Male', value: 'Male'},
    {text: 'Prefer not to say', value: 'Chose not to answer'},
    //{text: 'Custom...', value: 'custom'},
]

const ethnicOriginOptions = [
    {text: 'Hispanic or Latino', value: 'Hispanic or Latino', popover_helper_text: "A person of Cuban, Mexican, Chicano, Puerto Rican, South or Central American, or other Spanish culture or origin, regardless of race."},
    {text: 'White', value: 'White (Not Hispanic or Latino)', popover_helper_text: "A person having origins in any of the original peoples of Europe, the Middle East, or North Africa. "},
    {text: 'Black or African American', value: 'Black or African American (Not Hispanic or Latino)', popover_helper_text: "A person having origins in any of the black racial groups of Africa."},
    {text: 'Asian', value: 'Asian (Not Hispanic or Latino)', popover_helper_text: "A person having origins in any of the original peoples of the Far East, Southeast Asia, or the Indian subcontinent including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand, and Vietnam."},
    {text: 'Native Hawaiian or Other Pacific Islander', value: 'Native Hawaiian or Other Pacific Islander', popover_helper_text: "A person having origins in any of the original peoples of Hawaii, Guam, Samoa, or other Pacific Islands."},
    {text: 'Native American or Alaska Native (Not Hispanic)', value: 'Native American or Alaska Native (Not Hispanic)', popover_helper_text: "A person having origins in any of the original peoples of North and South America (including Central America), and who maintains tribal affiliation or community attachment."},
    {text: 'Two or More Races', value: 'Two or More Races (Not Hispanic or Latino)', popover_helper_text: "A person who primarily identifies with two or more of the above race/ethnicity categories."},
    {text: 'Prefer not to say', value: 'Chose not to answer'},
]

export default {
    mixins: [FormMixin, WizardMixin],
    components: {
        FormInput,
        FormErrors,
        ButtonRow, 
    },
    props: ['onboardingApplication'],
    computed: {
        genderOptions() {return genderOptions},
        ethnicOriginOptions() {return ethnicOriginOptions},
    },
    data() {
        return {
            formRules: {
                gender: {presence: {allowEmpty: false}},
                ethnic_origin: {presence: {allowEmpty: false}},
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            let gender

            const listedGenders = []
            genderOptions.forEach((g) => {
                if (g.value != 'custom') {
                    listedGenders.push(g.value)
                }
            })

            if (this.onboardingApplication.gender) {
                gender = (listedGenders.indexOf(this.onboardingApplication.gender) >= 0) ? this.onboardingApplication.gender : 'custom'
            }
            return {
                action: 'give-eeo-info',

                gender: gender,
                ethnic_origin: this.onboardingApplication.ethnic_origin,
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            const formData = Object.assign({}, this.formData)  // Make a copy
            if (formData.gender == 'custom') {
                formData.gender = formData.gender_custom
            }
            delete formData.gender_custom

            this.$store.dispatch('START_LOADING')
            api.patch(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application`, this.getFormData()).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrors = []
            this.formData = this.makeFormData()
        },
    },
}
</script>
