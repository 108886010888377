t<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid" v-show="dataLoaded">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Knowledge Test</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    Please answer the following questions TRUE or FALSE.
                                </p>

                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th colspan="2">
                                                Question
                                            </th>
                                            <th class="text-center">TRUE</th>
                                            <th class="text-center">FALSE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="question in questions">
                                            <td>
                                                {{ question.number }}.
                                            </td>
                                            <td>
                                                {{ question.question_text }}
                                            </td>
                                            <td class="text-center">
                                                <input type="radio" :name="'form-question-' + question.number" v-model="formData.questions[question.number]" :value="true">
                                            </td>
                                            <td class="text-center">
                                                <input type="radio" :name="'form-question-' + question.number" v-model="formData.questions[question.number]" :value="false">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <a class="btn btn-primary" @click="scoreTest">Submit Answers</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['client', ],
    components: {},
    data() {
        return {
            dataLoaded: false,
            formData: {
                questions: []
            },
            questions: []
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    mounted() {
        this.getInitialData()
    },
    computed: {
    },
    methods: {
        getInitialData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            const url = `/clients/${this.client.id}/e-verify-test`

            this.$api.get(url).then(resp => {
                this.questions = resp['questions']

                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },

        scoreTest() {
            const url = `/clients/${this.client.id}/e-verify-test`

            let submissionData = {'answers': {}}
            let num_answered = 0
            this.questions.forEach((question, idx) => {
                submissionData['answers'][question.number] = this.formData.questions[question.number]
                if (submissionData['answers'][question.number] !== undefined) {
                    num_answered += 1
                }
            })

            if (this.questions.length > num_answered) {
                alert("Please answer all questions");
                return;
            }

            return this.$api.post(url, submissionData).then(resp => {
                let urlName = 'training-e-verify-test-fail'
                if (resp['passed']) {
                     urlName = 'training-e-verify-test-pass'
                }

                this.$router.push({name: urlName, params: {hashedClientId: this.$route.params.hashedClientId, testId: resp['new_test_id']}});
            }).catch(errors => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.$bus.showError(errors.__all__)
            })

        }
    }
}

</script>
