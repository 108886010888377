<script>
import TablePage from '@/components/TablePage'
import moment from 'moment'

export default {
    components: {TablePage,  },
    props: ['client'],
    data() {
        return {
            mainWarnings: [],
            mainChangelogs: [],
            dateRanges: {},

            warnings: [],
            changelogs: [],
            changelogDateRangeStart: null,
            changelogDateRangeEnd: null,

            warningsDateRangeStart: null,
            warningsDateRangeEnd: null,
        }
    },
    methods: {
        filterRelevantWarnings(w) {
            throw "Must be implemented by the page component."
        },
        filterRelevantChangelog(c) {
            throw "Must be implemented by the page component."
        },
        getMaxMainDateRange() {
            let start, end
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (!range) {return}
                if (start) {
                    start = moment(start) > moment(range.start) ? range.start : start
                }
                else {
                    start = range.start
                }

                if (end) {
                    end = moment(end) > moment(range.end) ? range.end : end
                }
                else {
                    end = range.end
                }
            })

            return {'start': start, 'end': end}
        },
        getWarningsByDateRange(start, end) {
            let url = `/clients/${this.client.id}/warnings`
            if (start && end) {
                url = `${url}?date-filter.resolved.start=${encodeURIComponent(start)}&date-filter.resolved.end=${encodeURIComponent(end)}`
            }

            return this.$api.get(url).then(resp => {
                return resp.warnings.filter(this.filterRelevantWarnings)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getMainWarnings() {
            // This method will fetch warnings for the main tab, based on date ranges specified for canceled/completed sections
            const range = this.getMaxMainDateRange()

            return this.getWarningsByDateRange(range.start, range.end).then(warnings => {
                this.mainWarnings = warnings
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getWarnings() {
            // This method will fetch warnings for the warnings tab, based on date ranges specified there
            return this.getWarningsByDateRange(this.warningsDateRangeStart, this.warningsDateRangeEnd).then(warnings => {
                this.warnings = warnings
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getChangelogsByDateRange(start, end) {
            let url = `/clients/${this.client.id}/history-logs`
            if (start && end) {
                url = `${url}?date-filter.history_logs.start=${encodeURIComponent(start)}&date-filter.history_logs.end=${encodeURIComponent(end)}`
            }

            return this.$api.get(url).then(resp => {
                return resp.filter(this.filterRelevantChangelog)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getMainChangelogs() {
            // This method will fetch changelogs for the main tab, based on date ranges specified for canceled/completed sections.
            // This can be used, for example, to show the sidebar with the changelogs prefetched
            if (!this.$permissions.hasRoles(this.client.id, this.$store.state.security_roles.CHANGE_HISTORY_ROLES)) {
                return
            }

            return this.getChangelogsByDateRange(this.changelogDateRangeStart, this.changelogDateRangeEnd).then(changelogs => {
                this.mainChangelogs = changelogs
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })

        },
        getChangelogs() {
            // This method will fetch changelogs for the changelogs tab, based on date ranges specified there
            if (!this.$permissions.hasRoles(this.client.id, this.$store.state.security_roles.CHANGE_HISTORY_ROLES)) {
                return
            }

            return this.getChangelogsByDateRange(this.changelogDateRangeStart, this.changelogDateRangeEnd).then(changelogs => {
                this.changelogs = changelogs
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        onChangelogDateRangeChange(range) {
            this.changelogDateRangeStart = range.start
            this.changelogDateRangeEnd = range.end
            this.getChangelogs()
        },
        onWarningsDateRangeChange(range) {
            this.warningsDateRangeStart = range.start
            this.warningsDateRangeEnd = range.end
            this.getWarnings()
        },
    },
}
</script>
