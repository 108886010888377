<template>
    <modal size="lg" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">
        <template v-slot:title>
            <h3 class="modal-title">Edit Positions Required</h3>
        </template>

        <slot>
            <div class="mb-2">
                <div class="p-1 unpad-x bg-150 rounded">
                    <div><span class="text-muted">Company: </span>{{ document.company.name }}</div>
                    <div><span class="text-muted">Document Name: </span>{{ document.name }}</div>
                </div>
            </div>

            <form-errors :errors="formErrors"/>

            <p class="mb-0" v-if="!editingIncludeInOnboarding">Select the positions that are required to have this document completed.</p>

            <alert-box type="info" v-if="documentHasDraft" class="mt-1 mb-0">
                Any changes to positions will apply to both your active and draft versions of this document.
            </alert-box>

            <position-selector v-if="!editingIncludeInOnboarding" :all-positions="allCompanyPositions" v-model="formData.positions"/>

            <div v-if="editingIncludeInOnboarding">
                <p>
                    This document will now be required for new employees in the selected positions. How should GetMyPaystub collect this document for these new employees?
                </p>
                <div class="form-check">
                    <label>
                        <input name="include_in_onboarding" type="radio" @click="formData.include_in_onboarding = true" :checked="formData.include_in_onboarding == true" class="form-check-input">
                        <strong>Employees</strong> will be prompted to complete this document online when they onboard or first log in.
                    </label>
                    <p class="mt-0 small">
                        New employees will be blocked from accessing paystubs, W-2s, etc. until they have completed this document.
                    </p>
                </div>
                <div class="form-check pb-1 mt-1">
                    <label>
                        <input name="include_in_onboarding" type="radio" @click="formData.include_in_onboarding = false" :checked="formData.include_in_onboarding == false" class="form-check-input">
                        <span><strong>Managers</strong> will be given a task to complete or upload this document on behalf of new employees.</span>
                    </label>
                    <p class="mt-0 small">
                        New employees will be allowed to access paystubs, W-2s, etc. before this document is completed.
                    </p>
                </div>
                <alert-box type="info">
                    In either case, managers can always upload {{ documentType == 'hr-doc'? "" : " or complete " }} a document on an employee’s behalf.
                </alert-box>
            </div>

        </slot>

        <template v-slot:footer>
            <button-row stacked>
                <button v-if="editingIncludeInOnboarding" type="button" class="btn btn-outline-primary" @click.prevent="editingIncludeInOnboarding = false">
                    Back
                </button>
                <button v-else type="button" class="btn btn-outline-primary" @click.prevent="close()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">
                    Save
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import PositionSelector from './PositionSelector'

export default {
    props: ['client', 'document', 'documentType', 'documentHasDraft'],
    mixins: [FormMixin, ModalMixin],
    components: {PositionSelector, },
    data() {
        return {
            step: 'positions',
            formData: this.makeFormData(),
            formRules: {
                name: {presence: {allowEmpty: false}, },
            },
            allPositions: [],
            positionsLoaded: false,
            editingIncludeInOnboarding: false,
        }
    },
    watch: {
        client() {
            this.reset()
        }
    },
    mounted() {
        this.getPositions()
    },
    computed: {
        allCompanyPositions() {
            return this.allPositions.filter(p => p.company_id == this.document.company_id)
        },
    },
    methods: {
        getPositions() {
            this.$api.get(`/clients/${this.client.id}/positions`).then(resp => {
                this.allPositions = resp
                this.allPositions.sort((a, b) => {return a.terminal_value.toLowerCase() > b.terminal_value.toLowerCase() ? 1 : -1})
                this.positionsLoaded = true
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        makeFormData() {
            const data = {
                positions: []
            }
            this.document.positions.forEach(p => {
                data.positions.push(p.id)
            })

            return data
        },
        onSubmit() {
            if (!this.editingIncludeInOnboarding && this.formData.positions.length && this.document.include_in_onboarding === null) {
                // if they didn't set include_in_onboarding in document creation, they need to do it now
                this.formData.include_in_onboarding = null
                this.editingIncludeInOnboarding = true
                return
            }

            if (this.editingIncludeInOnboarding && this.formData.include_in_onboarding === null) {
                this.formErrors = ['Collection settings for new employees are required.']
                return
            }

            this.$store.dispatch('START_LOADING')
            let promise

            if (this.documentType == 'hr-doc') {
                promise = this.$api.put(`/clients/${this.client.id}/hr-documents/${this.document.id}/positions`, this.getFormData())
            } else if (this.documentType == 'uniform') {
                promise = this.$api.put(`/clients/${this.client.id}/uniforms/${this.document.id}/positions`, this.getFormData())
            } else if (this.documentType == 'hr-custom-form') {
                promise = this.$api.put(`/clients/${this.client.id}/hr-custom-forms/${this.document.id}/positions`, this.getFormData())
            } else if (this.documentType == 'hr-form-doc') {
                promise = this.$api.put(`/clients/${this.client.id}/hr-form-documents/${this.document.id}/positions`, this.getFormData())
            }
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    }
}
</script>
