<template>
    <modal @close="$emit('close')" ref="modal">
        <template v-slot:title>ESS Invitation Migration Email</template>

        <slot>
            <form-errors :errors="formErrors"/>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input label="Email Template:" v-model="formData.migration_template" :errors="errors.migration_template" type="radio" :options="emailTemplateOptions" extraclasses="label--bold"/>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.migration_template == 'ess-invitation-migration'">
                <div class="col">
                    <form-input type="date" v-model="formData.migration_date" :errors="errors.migration_date"  label="Migration Date" />
                </div>
            </div>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Send</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['company'],
    data() {
        const emailTemplateOptions = [
            {text: 'Turning off old ESS', value: 'ess-invitation-migration', popover_helper_text: "Gives a date when existing ESS access will be turned off."},
            {text: 'NOT turning off old ESS', value: 'ess-invitation-migration-2'},
        ]

        const rules = {
            migration_template: {presence: {allowEmpty: false}},
            migration_date: {presence: {allowEmpty: true}, date: true},
        }

        return {
            dataLoaded: false,
            emailTemplateOptions: emailTemplateOptions,
            formRules: rules,
            formData: this.makeFormData(),
        }
    },
    mounted() {

    },
    methods: {
        makeFormData() {
            let formData = {}
            formData = {
                migration_template: '',
                migration_date: '',
            }

            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let url = `/admin/clients/${this.company.client.id}/companies/${this.company.id}/send-ess-migration-emails`

            this.$store.dispatch('START_LOADING')
            api.post(url, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
