<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Photo Matching</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    Photo matching is an additional step that prompts employers and employer agents to compare an employee's photo ID with a photo displayed in E-Verify. Photo matching only occurs when the employee presents:
                                </p>

                                <ul>
                                    <li> A Permanent Resident Card (Form I-551)</li>
                                    <li> Employment Authorization Document (Form I-766)</li>
                                    <li> A valid U.S. passport or passport card</li>
                                </ul>

                                <p>
                                    If the employee presents one of these documents, the employer or employer agent must copy the front and back of the document (or in the case of a U.S. passport, copy the Passport ID page and the Passport Barcode page) and retain the copies with Form I-9.
                                </p>

                                <p>
                                    NOTE: Do not ask employees to present any specific document from the list above to enable use of the photo matching.
                                </p>

                                <p>
                                    Do not compare the photo displayed by E-Verify Web services to the actual employee. Direct comparison between the photo on the document and the individual should have occurred during Form I-9 completion, prior to using E-Verify Web services access.
                                </p>

                                <p>
                                    Only select No photo displayed if E-Verify displays nothing at all or it displays something other than a photo of a person, such as a photo of a document.
                                </p>

                                <p>
                                    Users are required to scan and upload the front and back of the photo match document if the case receives a photo mismatch Tentative Nonconfirmation. If the employee provided a U.S. passport or passport card, users are required to upload an image of the passport ID page and the passport barcode page.
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page2a'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page3'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
