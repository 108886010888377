<template>
    <div v-if="chartData" class="pie-chart-container">
        <table class="charts-css pie hide-data">
            <caption v-if="caption">{{ caption }}</caption>
            <tbody>
                <tr v-for="slice in formattedChartData">
                    <td :style="`--start: ${slice.start}; --end: ${slice.end}; --color: ${slice.color};`">
                        <span class="data"> {{ slice.label }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="doughnut-hole" v-if="doughnut"></div>
    </div>
</template>

<script>
import '@/assets/css/charts.css'

export default {
    components: {},
    props: {
        chartData: {
            type: Array,
            default: null,
        },
        // expects chartData in the format:
        // [
        //     {
        //         label: "Slice 1",
        //         value: 10,
        //         color: '#368F99',
        //     },
        //     ...
        // ],
        doughnut: {
            type: Boolean,
            default: false
        },
        gap: {
            type: Boolean,
            default: false
        },
        caption: {
            type: String,
            default: ''
        }
    },
    computed: {
        formattedChartData() {
            let data = []
            if (!this.chartData) {
                return data
            }
            let numSlices = this.chartData.filter(s => s.value > 0).length
            if (!numSlices) {
                return data
            }
            let totalValue = this.chartData.reduce((sum, slice) => sum + slice.value, 0)
            let filled = 0
            for (const slice of this.chartData) {
                let start = filled
                let end = start + (slice.value/totalValue)
                let gap = 0
                if (this.gap && numSlices > 1) {
                    gap = 0.01
                }
                filled = end
                data.push({
                    label: slice.label,
                    start: start,
                    end: end - gap,
                    color: slice.color
                })
            }

            return data
        }
    }
}
</script>

<style lang="scss" scoped>
    .pie-chart-container {
        position: relative;
        .doughnut-hole {
            height: 72%;
            width: 72%;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -36%;
            margin-top: -36%;
            border-radius: 50%;
            background-color: #fff;
        }
        .charts-css {
            // colors can be overriden in chartData
            --color-1: #368F99;
            --color-2: #2A7EFF;
            --color-3: #FFA200;
            --chart-bg-color: #fff
        }
    }
</style>
