<template>
    <modal @close="$emit('close')" ref="modal" :autoshow="true">
        <template v-slot:title>POS Sync Status</template>

        <slot>
            <p>
                Set or clear the POS sync flag for this employee/companies.
            </p>

            <h5>Companies</h5>

            <div class="py-hf" v-for="ec in employeeCompanies" :key="ec.id">
                <label class="my-0">
                    {{ ec.company.name }} - EE #{{ ec.employee_number }}
                </label>

                <div v-if="ec.synced_to_pos_on">
                    <small>Synced to POS on {{ $filters.date(ec.synced_to_pos_on, "MM/DD/YYYY") }}</small>
                    <button type="submit" class="btn btn-primary btn-sm mx-2" @click.prevent="setPOSSyncStatus(ec.id, false)">Clear</button>
                </div>
                <div v-else>
                    <small>Not synced to POS</small>
                    <button type="submit" class="btn btn-primary btn-sm mx-2" @click.prevent="setPOSSyncStatus(ec.id, true)">Mark as Synced</button>
                </div>
            </div>

            <br>
            <button-row>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Close</button>
            </button-row>
        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin, ],
    props: ['client', 'employees', 'fullName'],
    data() {
        return {}
    },
    computed: {
        companyMap() {
            const companyMap = {};
            (this.client.companies || []).forEach(c => {
                companyMap[c.id] = c
            })
            return companyMap
        },
        employeeCompanies() {
            const result = []
            const seenEmp = new Set()
            this.employees.forEach(e => {
                if (!seenEmp.has(e.company)) {
                    result.push({
                        'company': this.companyMap[e.company_id] || {},
                        'employee_number': e.employee_number,
                        'id': e.id,
                        'is_synced_to_pos': e.is_synced_to_pos,
                        'synced_to_pos_on': e.synced_to_pos_on,
                    })
                    seenEmp.add(e.company_id)
                }
            })

            return result
        },
    },
    methods: {
        setPOSSyncStatus(ee_id, syncStatus) {
            this.$store.dispatch('START_LOADING')

            this.$api.post(`/admin/employees/${ee_id}/set-pos-sync-status`, {'syncStatus': syncStatus}).then(() => {
                this.$bus.showInfo('Success!')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$bus.showError("Error saving")
                this.$store.dispatch('STOP_LOADING')
            })
        },

    }
}
</script>
