<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">

                        <h1 class="text-center mb-4">Verify Your Email Address</h1>
        
                        <form @submit.prevent="onSubmit" novalidate class="mui-form">
                            <form-errors :errors="formErrors"/>
        
                            <p>
                            We've sent you an email at the address you provided, containing a
                            verification code.
                            </p>
        
                            <p>
                            Please enter the code here:
                            </p>
        
                            <div class="form-row">
                                <div class="col-6">
                                    <form-input placeholder="Verification Code" v-model="formData.verification_code" :errors="errors.verification_code" type="text" inputmode="numeric" maxlength="6"/>
                                </div>
                            </div>
        
                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col">
                                    <router-link :to="{name: 'signup'}" class="btn btn-link">Back</router-link>
                                </div>
                                <div class="col text-right">
                                    <button type="submit" class="btn btn-primary">Continue</button>
                                </div>
                            </div>
                            <hr>
        
                            <div class="mt-2 text-center">
                                <router-link :to="{name: 'signup-no-email'}" class="btn btn-link">Don't have access to this email?</router-link>
                            </div>
                        </form>
                    
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import api from '@/api'

export default {
    mixins: [FormMixin],
    components: {
        FormInput,
        FormErrors,
    },
    data() {
        return {
            formRules: {
                verification_code: {
                    presence: {allowEmpty: false},
                },
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            return {
                verification_code: '',
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/signup/validate-email`, this.formData).then(() => {
                this.$router.push({name: 'signup-digital-consent'})
                this.$store.dispatch('STOP_LOADING')
                this.$bus.trackEvent('User Registration Email Validated')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })

        },
    },
}
</script>


