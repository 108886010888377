<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-l py-4">

                        <h2>Account Deletion</h2>

                        <p>
                            <router-link :to="{name: 'login'}" class="btn btn-link">Back</router-link>
                        </p>

                        <p>
                            Delta HCM LLC ("us", "we", or "our") operates the www.getmypaystub.com website and the GetMyPayStub App mobile application (hereinafter referred to as the "Service").
                        </p>

                        <p>
                            To delete your account, send an email to <a href="mailto:privacy@hcmdelta.com">privacy@hcmdelta.com</a> requesting that your GetMyPayStub account be deleted.
                            Be sure to send it from the email address associated with the account you wish to delete.
                        </p>

                        <p>
                            Upon receiving such request, we will delete your login information and history; however, your pay information and any associated documents or tax forms will remain in our system as part of your payroll records.
                        </p>

                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

}

</script>