<template>
<div>
<info-widget :collapsible="true" :is-expanded="widgetExpanded" @toggle_expand="widgetExpanded = !widgetExpanded">
    <template v-slot:title>
        <slot name="title"></slot>
    </template>

    <template v-slot:headerbutton>
        <button class="btn btn-outline-primary" type="button" @click.prevent.stop="$emit('add')">
            <i class="far fa-plus" aria-hidden="true" title="Add"></i>&nbsp;
            <slot name="add-button">Add</slot>
        </button>
    </template>

    <slot>
        <div class="p-0">
            <section class="table-section" v-for="section in sections">
                <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': expandedSections[section.title]}" @click.prevent="expandedSections[section.title] = !expandedSections[section.title]">

                    <span class="widget-toggle-wrapper">
                        <collapse-toggle-button />
                    </span>
                    <h5 class="table-section-title">{{ section.title }} <span class="text-regular">({{ section.count }})</span></h5>
                </a>
                <div class="row-empty" v-if="!section.count" v-show="expandedSections[section.title]">
                    This section is empty.
                </div>
                <table class="table css-table widget-table table-fixed" v-if="section.count" v-show="expandedSections[section.title]">
                    <thead>
                        <tr>
                            <th class="cw-toggle"></th>
                            <th v-for="header in headers">{{ header }}</th>
                            <th class="cw-date">Dates</th>
                        </tr>
                    </thead>
                    <tbody v-for="row in section.rows" :key="row.id" :class="{'active-item': false}">
                        <tr>
                            <td class="cw-toggle position-relative">
                                <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': expandedItems[row.id]}" @click.prevent="expandedItems[row.id] = !expandedItems[row.id]">
                                    <collapse-toggle-button />
                                </a>
                            </td>
                            <td class="truncate-wrapper" v-for="(header, idx) in headers">{{ row.cells[idx] }}</td>
                            <td class="cw-date">
                                <span>{{ $filters.date(row.current_version.starts_on, "MM/DD/YYYY") }}</span>
                                <span v-if="row.current_version.ends_on"> - {{ $filters.date(row.current_version.ends_on, "MM/DD/YYYY") }}</span>
                                <span v-else>+</span>
                            </td>
                        </tr>
                        <tr class="child no-transition" v-show="expandedItems[row.id]">
                            <td :colspan="headers.length + 2">
                                <div class="card card-body item-details">
                                    <div>
                                        <div class="form-row mb-2">
                                            <div class="col-6">
                                                Version: {{ row.current_version.version }}
                                            </div>
                                            <div class="col-6 text-right">
                                                <a href @click.prevent="$emit('versions', row.id)">
                                                    <i class="fa fa-arrow-up-right-from-square"></i>
                                                    Previous Versions
                                                </a>
                                            </div>
                                        </div>
                                        <section class="item-details-section">
                                            <div>
                                                <slot name="row-details" :row="row"></slot>
                                            </div>

                                            <div class="mt-2">
                                                <button class="btn btn-outline-primary mr-1" @click.prevent="$emit('edit', row.id)">
                                                    <i class="fa fa-pencil"></i>
                                                    Edit
                                                </button>
                                                <button v-if="!row.has_been_used" class="btn btn-outline-primary mr-1" @click.prevent="$emit('delete', row.id)">
                                                    <i class="fa fa-trash"></i>
                                                    Delete
                                                </button>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>

    </slot>
</info-widget>

</div>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'

export default {
    props: ['rows', 'headers'],
    components: {InfoWidget, },
    computed: {
        sections() {
            return [
                {
                    title: 'Future',
                    count: this.futureRows.length,
                    rows: this.futureRows,
                },
                {
                    title: 'Current',
                    count: this.currentRows.length,
                    rows: this.currentRows,
                },
                {
                    title: 'Past',
                    count: this.pastRows.length,
                    rows: this.pastRows,
                },
            ]
        },
        futureRows() {
            const rows = JSON.parse(JSON.stringify(this.rows.filter(w => w.current_version.is_future)))
            rows.sort((a, b) => {
                if (a.created_on != b.created_on) {
                    return (a.created_on > b.created_on) ? -1 : 1
                }
                return (a.name > b.name) ? 1 : -1
            })
            return rows
        },
        currentRows() {
            const rows = JSON.parse(JSON.stringify(this.rows.filter(w => w.current_version.is_active)))
            rows.sort((a, b) => {
                return (a.cells[0] > b.cells[0]) ? 1 : -1
            })
            return rows
        },
        pastRows() {
            const rows = JSON.parse(JSON.stringify(this.rows.filter(w => w.current_version.is_past)))
            rows.sort((a, b) => {
                if (a.created_on != b.created_on) {
                    return (a.created_on > b.created_on) ? -1 : 1
                }
                return (a.name > b.name) ? 1 : -1
            })
            return rows
        },
    },
    data() {
        return {
            widgetExpanded: false,
            expandedSections: {},
            expandedItems: {},
        }
    },
}
</script>
