<template>
    <modal @close="$emit('close')" ref="modal" :fade="false" :v-center="true" modal-additional-classes="modal-pageerror modal-noclose" :modal-container="'specialModal'" :hide-x-button="true">
        <template v-slot:title>
            <div class="text-center">Page Unavailable</div>
        </template>

        <slot>
            <div>
                <p class="text-center mb-hf">
                  You do not have access to this page. If you think this is an error, please contact your Account Administrator.
                </p>
            </div>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col-12 col-rs-6 offset-rs-3 col-sm-4 offset-sm-4">
                    <router-link :to="logoLinkRoute" class="btn btn-primary btn-block mb-1">Go to Home</router-link>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin, ],
    data() {
        return {
        }
    },
    computed: {
      logoLinkRoute() { // Borrowed from MainNavbar
            switch (this.$route.meta.mode) {
                case 'ess':
                    return {name: 'employee-home'}
                case 'client':
                    return {name: 'employer-home', params: { hashedClientId: this.$route.params.hashedClientId }}
                case 'admin':
                    return {name: 'admin-home'}
            }
            return '/'
        },
    }
}
</script>
