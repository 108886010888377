<template>
    <page>

        <div>
            <div class="flex-page-content">
                <div class="flex-body body-scroll">
                    <div class="container mx-auto">
                        <div class="py-4">

                            <form class="mui-form">
                                <p>
                                    Use this tool to match check numbers from a "OBC Checks By Run Date" report to a check file.
                                </p>

                                <form-errors :errors="formErrors"/>

                                <div class="form-row mb-4">
                                    <div class="col-6">
                                        <form-input v-model="formData.check_file" :errors="errors.check_file" type="file" label="Check File (.txt)" helper-text="File extension must be .txt" accept=".txt" @upload="formData.check_file = $event[0]" />
                                    </div>
                                </div>

                                <div class="form-row mb-4">
                                    <div class="col-6">
                                        <form-input v-model="formData.report_file" :errors="errors.report_file" type="file" label="Report File (.xlsx)" helper-text="File extension must be .xlsx" accept=".xlsx" @upload="formData.report_file = $event[0]" />
                                    </div>
                                </div>

                                <div class="form-row mb-4">
                                    <div class="col-2">
                                        <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </page>
</template>

<script>
import Page from '@/components/Page'
import FormMixin from '@/mixins/Form'
import api from '@/api'

export default {
    props: ['client', ],
    mixins: [FormMixin, ],
    components: {Page},
    data() {
        return {
            formRules: {
                check_file: {presence: {allowEmpty: false}},
                report_file: {presence: {allowEmpty: false}},
            },
            formData: {
                check_file: null,
                report_file: null,
            }
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'OBC Check Match Tool')
    },
    methods: {
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            let url = `/admin/utils/obc-check-match`

            api.uploadMultiple(url, {
                'check_file': this.formData.check_file,
                'report_file': this.formData.report_file
            }, {}).then((resp) => {
                saveAs(new Blob([resp], {type: "application/zip"}), `result.zip`)
                this.$store.dispatch('STOP_LOADING')
                
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        }
    },
}

</script>
