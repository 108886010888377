<template>
    <modal :autoshow="true" size="xl" :height-max="showTable" ref="modal" @close="$emit('close')" :is-closeable="isCloseable">
        <template v-slot:title>Update Employee</template>

        <template v-slot:subheading>
            <div class="p-1 unpad-x bg-150 rounded">
                <div><span class="text-muted">Employee:</span> {{ fullName }}</div>

                <div v-if="mode == 'companies'"><span class="text-muted">Old Email:</span> {{ email }}</div>
                <div v-if="mode == 'companies'"><span class="text-muted">New Email:</span> {{ formData.email }}</div>
            </div>
        </template>

        <slot>
            <form v-if="mode == 'email'" @submit.prevent="onSubmit">
                <alert-box type="info" class="mb-4">
                    Changing this employee's email will require them to register a GetMyPayStub account at their new email address, and re-link each company from that account.
                </alert-box>

                <div class="form-row">
                    <div class="col-12">
                        <form-input label="Email Address" v-model="formData.email" :errors="errors.email" type="email" />
                    </div>
                </div>
            </form>

            <div v-if="mode == 'companies'">
                <p>
                    <template v-if="numRecords > 1">
                        The email address <strong>{{ email }}</strong> is associated with employee records at
                        <strong>{{ numRecords }} companies</strong>.
                        <template v-if="numMatchingRecords > 0">
                            Which of the following do you want to change?
                        </template>
                    </template>
                    <template v-else-if="(numMatchingRecords == 1) && (numConflictingRecords == 0)">
                        Are you sure you want to update this employee's email address at
                        <template v-if="matchingEmployees[0]">
                            <strong>{{ matchingEmployees[0].company.name }}</strong>?
                        </template>
                        <template v-else-if="matchingOnboardingApplications[0]">
                            <strong>{{ matchingOnboardingApplications[0].company.name }}</strong>?
                        </template>
                    </template>
                </p>

                <!-- If some updates aren't possible, use a yellow alert. -->
                <alert-box type="caution" v-if="(numMatchingRecords > 0) && (numConflictingRecords > 0)">
                    Some records cannot be updated because the new email address is already in use within that company.
                </alert-box>

                <!-- If no updates are possible, use a red alert. -->
                <alert-box type="error" v-if="numMatchingRecords < 1">
                    {{ (numRecords) == 1 ? 'The record cannot' : 'No records can' }}
                    be updated because the new email address is already in use within
                    {{ (numRecords) == 1 ? 'this company' : 'those companies' }}.
                </alert-box>

                <fast-table v-if="showTable"
                    :sections="sections"
                    :headers="headers"
                    :data="tableData"
                    :use-date-filters="false"
                    :show-filter="false"
                    :show-search="false"
                    :show-more-actions="false"
                    :has-row-select="true"

                    @click="onRowClick"
                    @select="onRowSelect"
                />
            </div>

        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">{{ mode == 'companies' ? 'Update Employee' : 'Continue' }}</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'
import FastTable from '@/components/FastTable'

export default {
    props: ['client', 'email', 'fullName', 'employeeId', 'onboardingApplicationId', ],
    mixins: [ModalMixin, FormMixin, ],
    components: {FastTable, },
    computed: {
        numMatchingRecords() {
            return this.matchingEmployees.length + this.matchingOnboardingApplications.length
        },
        numConflictingRecords() {
            return this.conflictingEmployees.length + this.conflictingOnboardingApplications.length
        },
        numRecords() {
            return this.numMatchingRecords + this.numConflictingRecords
        },
        showTable() {
            return (this.numRecords > 1) || (this.numConflictingRecords > 0)
        },
        tableData() {
            const tableData = []

            const addRow = (x, isConflict) => {
                const eeName = {value: x.full_name_sortable, _meta: {}}
                if (isConflict) {
                    eeName._meta.additionalCSSClasses = "text-danger has-danger-icon"
                    eeName._meta.hasWarningTriangle = true
                }

                let isSelected = false
                if (!isConflict) {
                    if (x.is_employee) {
                        isSelected = this.formData.employees.includes(x.id)
                    }
                    else {
                        isSelected = this.formData.onboarding_applications.includes(x.id)
                    }
                }
                const prefix = x.is_employee ? 'e-' : 'o-'
                const row = {
                    id: prefix + x.id,
                    object: x,
                    isSelected: isSelected,
                    selectDisabled: isConflict,
                    cells: [
                        eeName,
                        x.company.name,
                        x.is_employee ? 'Employee' : 'Onboarding',
                    ],
                }

                tableData.push(row)
            }

            this.matchingEmployees.forEach(e => {
                addRow(e, false)
            })

            this.conflictingEmployees.forEach(e => {
                addRow(e, true)
            })

            this.matchingOnboardingApplications.forEach(o => {
                addRow(o, false)
            })

            this.conflictingOnboardingApplications.forEach(o => {
                addRow(o, true)
            })

            return [tableData]
        },
    },
    data() {
        return {
            mode: 'email', // Can be email or companies
            formData: {
                email: this.email,
                employees: [],
                onboarding_applications: [],
            },
            formRules: {
                email: {
                    email: true,
                    presence: {allowEmpty: false},
                },
            },
            matchingEmployees: [],
            conflictingEmployees: [],
            matchingOnboardingApplications: [],
            conflictingOnboardingApplications: [],

            headers: [
                {label: 'Person', classes: '', isSearchable: false, defaultSort: true},
                {label: 'Company', classes: 'cell-company', isSearchable: false, isFilterable: false},
                {label: 'Status', classes: 'cell-medium', isSearchable: false, isFilterable: false},
            ],
            sections: [
                {title: 'People', defaultIsClosed: false, id: 'active', hasDateFilter: false},
            ],

        }
    },
    methods: {
        getFormData() {
            const result = {email: this.formData.email}
            if (this.mode == 'companies') {
                result.employee_ids = this.formData.employees
                result.onboarding_application_ids = this.formData.onboarding_applications
            }
            return result
        },
        validate() {
            const basicFormValidation = FormMixin.methods.validate.call(this)

            if (this.mode == 'email' && (!this.formData.email || this.formData.email == this.email)) {
                this.formErrors.push('New email address cannot be the same as the existing email address.')
                return false
            }

            if (this.mode == 'companies' && (this.formData.employees.length + this.formData.onboarding_applications.length) < 1) {
                this.formErrors.push('Select at least one employee to update.')
                return false
            }

            return basicFormValidation
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let url = `/clients/${this.client.id}/employee-email?new_email=${encodeURIComponent(this.formData.email)}`
            if (!this.email && this.employeeId) {
                url += `&employee_id=${encodeURIComponent(this.employeeId)}`
            }
            else {
                url += `&old_email=${encodeURIComponent(this.email || '')}`
            }

            if (this.mode == 'email') {
                this.$store.dispatch('START_LOADING')
                this.errors = {}
                this.formErrors = []
                this.$api.get(url, this.getFormData()).then(resp => {
                    this.mode = 'companies'
                    this.matchingEmployees = resp.matching_employees
                    this.conflictingEmployees = resp.conflicting_employees
                    this.matchingOnboardingApplications = resp.matching_onboarding_applications
                    this.conflictingOnboardingApplications = resp.conflicting_onboarding_applications

                    if (this.employeeId) {
                        if (!this.conflictingEmployees.find(e => e.id == this.employeeId)) {
                            this.formData.employees.push(this.employeeId)
                        }
                    }

                    if (this.onboardingApplicationId) {
                        if (!this.conflictingOnboardingApplications.find(o => o.id == this.onboardingApplicationId)) {
                            this.formData.onboarding_applications.push(this.onboardingApplicationId)
                        }
                    }

                    this.$store.dispatch('STOP_LOADING')
                }).catch(errors => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.$store.dispatch('STOP_LOADING')
                })
            }
            else if (this.mode == 'companies') {
                this.$store.dispatch('START_LOADING')
                this.errors = {}
                this.formErrors = []
                this.$api.post(url, this.getFormData()).then(() => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', {email: this.formData.email})
                    this.$nextTick(() => {
                        this.close()
                    })
                }).catch(errors => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.$store.dispatch('STOP_LOADING')
                })
            }
        },
        onRowSelect(rows) {
            const employees = new Set()
            const onboardingApplications = new Set()
            rows.forEach(row => {
                if (row.object.is_employee) {
                    employees.add(row.object.id)
                }
                else {
                    onboardingApplications.add(row.object.id)
                }
            })

            this.formData.employees = Array.from(employees)
            this.formData.onboarding_applications = Array.from(onboardingApplications)
        },
        onRowClick(x) {
            if (x.selectDisabled) {
                return
            }
            if (x.is_employee) {
                if (this.conflictingEmployees.find(e => e.id == x.id)) {return}

                if (this.formData.employees.includes(x.id)) {
                    this.formData.employees = this.formData.employees.filter(eId => eId != x.id)
                }
                else {
                    this.formData.employees.push(x.id)
                }
            }
            else {
                if (this.conflictingOnboardingApplications.find(o => o.id == x.id)) {return}
                if (this.formData.onboarding_applications.includes(x.id)) {
                    this.formData.onboarding_applications = this.formData.onboarding_applications.filter(eId => eId != x.id)
                }
                else {
                    this.formData.onboarding_applications.push(x.id)
                }
            }
        },
    },
}
</script>
