<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">
                      
                        <h5>{{ onboardingApplication.company.name }}</h5>
                        <h3>Review Address</h3>
        
                        <form @submit.prevent="onSubmit" class="mt-2">
                            <form-errors :errors="formErrors" :errorsHtml="formErrorsHtml" />

                            <p>Please review the suggested changes to your address.</p>
                            <h5>Original Address:</h5>
                            <p>
                                {{ onboardingApplication.address_1 }}
                                {{ onboardingApplication.address_2 }}
                                <br/>
                                <span>{{ onboardingApplication.city }}, </span>
                                <span>{{ onboardingApplication.state }} </span>
                                <span>{{ onboardingApplication.postal_code }}</span>
                            </p>
                            <h5>Suggested Address:</h5>
                            <p>
                                {{ onboardingApplication.address_1 }}
                                {{ onboardingApplication.address_2 }}
                                <br/>
                                <span :class="{ 'text-bold': onboardingApplication.geocode_data.geocoder_modified_city }">
                                    {{ onboardingApplication.geocode_data.city }},
                                </span>
                                <span :class="{ 'text-bold': onboardingApplication.geocode_data.geocoder_modified_state }">
                                    {{ onboardingApplication.geocode_data.state }}
                                </span>
                                <span :class="{ 'text-bold': onboardingApplication.geocode_data.geocoder_modified_zip_code }">
                                    {{ onboardingApplication.geocode_data.zip_code }}
                                </span>
                            </p>

                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col">
                                    <router-link v-if="previousPage" :to="previousPage">Back</router-link>
                                </div>
        
                                <div class="col text-right">
                                    <button class="btn btn-primary">Accept Changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'
import WizardMixin from './WizardMixin'

export default {
    mixins: [FormMixin, WizardMixin],
    props: ['onboardingApplication', ],
    computed: {},
    data() {
        return {
            formRules: {},
            formLabels: {},
            formErrorsHtml: [],
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            // NB: use geodode_data for city, state, postal_code to submit the form
            // with the geocoder-modified values for these fields
            return {
                action: 'give-address',
                address_1: this.onboardingApplication.address_1,
                address_2: this.onboardingApplication.address_2,
                city: this.onboardingApplication.geocode_data.city,
                state: this.onboardingApplication.geocode_data.state,
                postal_code: this.onboardingApplication.geocode_data.zip_code,
                phone: this.onboardingApplication.phone,
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            this.$store.dispatch('START_LOADING')
            api.patch(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application`, this.getFormData()).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrorsHtml = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrorsHtml = []
            this.formData = this.makeFormData()
        },
    },
}
</script>
