<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Privacy and Data Protections</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    Employers, E-Verify employer agents, and users must protect the privacy of employees who submit information to be processed through E-Verify and ensure that all personal information collected is safeguarded and used only for the purposes outlined in the MOU.
                                </p>

                                <p>
                                    Failure to properly protect employee information can result in identity theft or fraud and can cause considerable inconvenience, harm or embarrassment to the employees or employer affected. At a minimum, follow the steps below to protect personal information and comply with the appropriate requirements:
                                </p>

                                <ul>
                                    <li> Allow only authorized users to use E-Verify. Ensure that only the appropriate users handle information and create E-Verify cases.
                                    </li>
                                    <li> Secure access to E-Verify. Protect passwords used to access E-Verify and ensure that unauthorized users do not gain access to the system.
                                    </li>
                                    <li> Protect and store employee information properly. Ensure that employee information is stored in a safe and secure location and that only authorized users have access to this information.
                                    </li>
                                    <li>
                                        Discuss E-Verify results in private. Ensure that all case results, including Tentative Nonconfirmations (Mismatches), Dual Tentative Noncomfirmations (Mismatches) and Final Nonconfirmations, are discussed privately with the employee.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page1a'}">Continue</router-link>
                            </button-row>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
