<template>
<div class="mb-3">
    <h4 class="tasks-header box-shadow-tile">
        Tasks <span class="text-regular">({{ numTasks }})</span>
    </h4>
    <div v-if="numTasks > 0" class="scoreboard scoreboard--tasks box-shadow-tile accordion" id="scoreboard_tasks">
        <div v-for="taskType in tasksTableData" :key="taskType.type" class="scoreboard-item">
            <div class="scoreboard-item-main" data-toggle="collapse" :data-target="'#tasks-table-' + taskType.type" role="button" aria-expanded="false" :aria-controls="'tasks-table-' + taskType.type">
                <span class="scoreboard-item-icon">
                    <span class="scoreboard-item-icon">
                        <span class="counter counter-tasks">
                            <i v-if="taskType.type == 'doc_needs_manager_response'" class="counter-icon far fa-fw fa-file pr-hf" aria-label="tasks"></i>
                            <i v-if="taskType.type == 'doc_needs_manager_approval'" class="counter-icon far fa-fw fa-file-check pr-hf" aria-label="tasks"></i>
                            <i v-if="taskType.type == 'onboarding_app_approval'" class="counter-icon far fa-fw fa-user-check" aria-label="tasks"></i>
                            <i v-if="taskType.type == 'everify_action_required'" class="counter-icon far fa-fw fa-id-badge pr-hf" aria-label="tasks"></i>
                            <i v-if="taskType.type == 'everify_cases_to_create'" class="counter-icon far fa-fw fa-id-badge pr-hf" aria-label="tasks"></i>
                            <span class="counter-label">{{ taskType.numTasks }}</span>
                        </span>
                    </span>
                </span>
                <span class="scoreboard-item-type">
                    {{ taskType.label }}
                </span>
            </div>
            <!-- Expanded UI -->
            <div class="scoreboard-item-detail collapse" :id="'tasks-table-' + taskType.type" data-parent="#scoreboard_tasks">
                <!-- Company section -->
                <section template v-if="taskType.companies.length">
                    <div v-for="company in taskType.companies" :key="company.id" class="table-section pb-0">
                        <a href v-if="company.id" class="table-section-titlebar collapse-trigger" role="button" :class="{'collapsed': !isSectionExpanded(taskType, company)}" @click.prevent="toggleIsSectionExpanded(taskType, company)">
                            <collapse-toggle-button />
                            <h5 class="table-section-title">{{ company.name }} <span class="text-regular">({{ company.numTasks }})</span></h5>
                        </a>
                        <section v-show="isSectionExpanded(taskType, company)" class="pb-1">
                            <!-- Document section -->
                            <section v-if="company.documents.length">
                                <div v-for="doc in company.documents" :key="doc.key" class="scoreboard-document-section">
                                    <a href v-if="company.id" class="table-section-titlebar collapse-trigger" role="button" :class="{'collapsed': !isSectionExpanded(taskType, company, doc)}" @click.prevent="toggleIsSectionExpanded(taskType, company, doc)">
                                        <collapse-toggle-button />
                                        <h5 class="table-section-title">{{ doc.name }} <span class="text-regular">({{ doc.numTasks }})</span></h5>
                                    </a>
                                    <task-type-table v-show="isSectionExpanded(taskType, company, doc)" :tasks="doc.tasks" :task-type="taskType.type"/>
                                </div>
                            </section>
                            <!-- Tasks not associated with a document -->
                            <section v-else>
                                <task-type-table :tasks="company.tasks" :task-type="taskType.type"/>
                            </section>
                        </section>
                    </div>
                </section>
                <!-- Tasks not associated with a company -->
                <section v-else>
                    <task-type-table :tasks="taskType.tasks" :task-type="taskType.type"/>
                </section>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TaskTypeTable from './EmployerDashboardTaskTypeTable'

export default {
    props: ['client', 'tasksByType'],
    components: {TaskTypeTable, },
    data() {
        return {
            expandedSections: [],
        }
    },
    mounted() {
        this.expandSingleSections()
    },
    computed: {
        user() {return this.$store.state.user},
        numTasks() {
            let numTasks = 0
            Object.keys(this.tasksByType).forEach(key => {
                numTasks += this.tasksByType[key].length
            })
            return numTasks
        },
        tasksTableData() {
            let tableData = []
            let taskTypeLabels = {
                'onboarding_app_approval': 'Onboarding Ready for Approval',
                'doc_needs_manager_approval': 'Document Ready for Approval',
                'doc_needs_manager_response': 'Documents to Complete',
                'everify_action_required': 'E-Verify: Action Required',
                'everify_cases_to_create': 'E-Verify: Cases to Create',
            }
            if (!this.tasksByType) {
                return tableData
            }
            Object.keys(this.tasksByType).forEach(key => {
                if (this.tasksByType[key].length > 0) {

                    let tasksList = this.tasksByType[key]

                    let taskTypeData = {
                        type: key,
                        label: taskTypeLabels[key],
                        tasks: [],
                        numTasks: tasksList.length,
                        companies: [],
                    }

                    tasksList.forEach(t => {
                        if (t.company_id) {
                            let existingCompanyIdx = taskTypeData.companies.findIndex(company => company.id == t.company_id)

                            if (existingCompanyIdx == -1) {
                                let newCompany = {
                                    id: t.company_id,
                                    name: t.company.name,
                                    documents: [],
                                    numTasks: 1,
                                    tasks: [],
                                }
                                if (t.doc_key) {
                                    let newDoc = {
                                        key: t.doc_key,
                                        name: t.document_name,
                                        tasks: [t],
                                        numTasks: 1,
                                    }
                                    newCompany.documents.push(newDoc)
                                } else {
                                    newCompany.tasks.push(t)
                                }
                                taskTypeData.companies.push(newCompany);
                            } else {
                                if (t.doc_key) {
                                    let existingDocIdx = taskTypeData.companies[existingCompanyIdx].documents.findIndex(doc => doc.key == t.doc_key)

                                    if (existingDocIdx == -1) {
                                        let newDoc = {
                                            key: t.doc_key,
                                            name: t.document_name,
                                            tasks: [t],
                                            numTasks: 1,
                                        }
                                        taskTypeData.companies[existingCompanyIdx].documents.push(newDoc)
                                    } else {
                                        taskTypeData.companies[existingCompanyIdx].documents[existingDocIdx].tasks.push(t)
                                        taskTypeData.companies[existingCompanyIdx].documents[existingDocIdx].numTasks += 1
                                    }
                                } else {
                                    taskTypeData.companies[existingCompanyIdx].tasks.push(t)
                                }
                                taskTypeData.companies[existingCompanyIdx].numTasks += 1
                            }
                        } else {
                            taskTypeData.tasks.push(t)
                        }
                    })

                    tableData.push(taskTypeData)
                }
            })
            return tableData
        },
    },
    methods: {
        getSectionKey(taskType, company, doc) {
            let sectionKey = `taskType-${taskType.type}`
            if (company) {
                sectionKey += `--company-${company.id}`
            }
            if (doc) {
                sectionKey += `--${doc.key}`
            }
            return sectionKey
        },
        isSectionExpanded(taskType, company, doc) {
            let sectionKey = this.getSectionKey(taskType, company, doc)
            return this.expandedSections.includes(sectionKey)
        },
        toggleIsSectionExpanded(taskType, company, doc) {
            let sectionKey = this.getSectionKey(taskType, company, doc)
            if (this.expandedSections.includes(sectionKey)) {
                this.collapseSection(sectionKey)
            } else {
                this.expandSection(sectionKey)
            }

        },
        collapseSection(sectionKey) {
            this.expandedSections = this.expandedSections.filter(s => s !== sectionKey)
        },
        expandSection(sectionKey) {
            this.expandedSections.push(sectionKey)
        },
        expandSingleSections() {
            this.tasksTableData.forEach(taskType => {
                if (taskType.companies.length == 1) {
                    this.expandSection(this.getSectionKey(taskType, taskType.companies[0]))
                }
                taskType.companies.forEach(c => {
                    if (c.documents && c.documents.length == 1) {
                        this.expandSection(this.getSectionKey(taskType, c, c.documents[0]))
                    }
                })
            })
        },
    },
}

</script>

<style scoped>
    .tasks-header {
        background-color: #D1ECF1;
        margin-bottom: 0;
        padding: 10px;
    }
    .scoreboard--tasks {
        border-left: none;
    }
</style>