<template>
<div>
    <div class="nav-tabs-container pt-1">
        <div class="nav-tabs-scroll-helper">
            <ul class="nav nav-tabs">

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'all'}" href id="tabLinkLocations1" role="tab" aria-controls="tabContentLocations1" :aria-selected="mode == 'all'" @click.prevent="mode = 'all'; $bus.$emit('resize')">
                        All Locations ({{ allLocationCount }})
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'selected'}" href id="tabLinkLocations2" role="tab" aria-controls="tabContentLocations1" :aria-selected="mode == 'selected'" @click.prevent="mode = 'selected'; $bus.$emit('resize')">
                        Selected ({{ selectedLocationCount }})
                    </a>
                </li>

            </ul>
        </div>
    </div>

    <div class="flex-body flex-container tab-content">
        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'all'}" id="tabContentLocations1" role="tabpanel" aria-labelledby="tabLinkLocations1">
            <fast-table
                v-if="mode == 'all'"
                :sections="workLocationSections"
                :headers="workLocationHeaders"
                :data="workLocationTableData"
                @click="onLocationClick"
                @select="onLocationSelect"
                @filter-edit-open="$emit('filter-edit-open')"
                @filter-edit-close="$emit('filter-edit-close')"
                :use-overlay-filter-editor="true"
                :has-row-select="true"
                :show-search="true"
                :show-filter="true"
                :show-more-actions="false"
            />
        </div>

        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'selected'}" id="tabContentLocations2" role="tabpanel" aria-labelledby="tabLinkLocations2">
            <fast-table
                v-if="mode == 'selected'"
                :sections="workLocationSections"
                :headers="workLocationHeaders"
                :data="selectedWorkLocationTableData"
                @click="onLocationClick"
                @select="onLocationSelect"
                @filter-edit-open="$emit('filter-edit-open')"
                @filter-edit-close="$emit('filter-edit-close')"
                :use-overlay-filter-editor="true"
                :has-row-select="true"
                :show-search="true"
                :show-filter="true"
                :show-more-actions="false"
            />
        </div>

    </div>
</div>
</template>

<script>
import FastTable from '@/components/FastTable'

export default {
    props: ['client', 'clientWorkLocations', 'modelValue', 'hideCompanyFilter', ],
    emits: ['update:modelValue', 'filter-edit-open', 'filter-edit-close'],
    components: {FastTable, },
    computed: {
        workLocationTableData() {
            const result = []

            this.clientWorkLocations.forEach(w => {
                if (!this.canEditRole(w.company_id)) {
                    return  // This user can't edit this company role at all
                }

                const company = this.client.companies.find(c => c.id == w.company_id)
                result.push({
                    id: w.id,
                    isSelected: this.formData.work_locations.includes(w.id),
                    object: w,
                    cells: [
                        company.name,
                        w.name,
                    ],
                })
            })

            return [result]
        },
        selectedWorkLocationTableData() {
            let result = this.workLocationTableData[0].filter(row => {
                return this.formData.work_locations.includes(row.object.id)
            })

            result = JSON.parse(JSON.stringify(result))
            result.forEach(row => {
                row.isSelected = true
            })

            return [result]
        },
        allLocationCount() {
            return this.workLocationTableData[0].length
        },
        selectedLocationCount() {
            return this.selectedWorkLocationTableData[0].length
        },
    },
    data() {
        return {
            mode: 'all',
            formData: {
                work_locations: Array.from(this.modelValue),
            },
            workLocationSections: [
                {title: 'All', id: 'all'},
            ],
            workLocationHeaders: [
                {label: 'Company', classes: 'cell-company table-anchor--longtext', defaultSort: true, isFilterable: !this.hideCompanyFilter},
                {label: 'Location', classes: 'cell-org-struct', isSearchable: true, isFilterable: true},
            ],
        }
    },
    methods: {
        canEditRole(companyId) {
            return this.$permissions.hasRolesInCompany(this.client.id, companyId, this.$store.state.security_roles.SETTINGS_ROLES)
        },
        onLocationSelect(rows) {
            const locations = new Set()
            rows.forEach(row => {
                locations.add(row.object.id)
            })

            this.formData.work_locations = Array.from(locations)

            this.$emit('update:modelValue', this.formData.work_locations)
        },
        onLocationClick(location) {
            if (this.formData.work_locations.includes(location.id)) {
                this.formData.work_locations = this.formData.work_locations.filter(pId => pId != location.id)
            }
            else {
                this.formData.work_locations.push(location.id)
            }

            this.$emit('update:modelValue', this.formData.work_locations)
        },
    },
}

</script>
