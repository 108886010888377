<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Rules and Responsibilities Overview</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <h5>Employers and employer agents participating in E-Verify MUST:</h5>

                                <ul>
                                    <li> Follow E-Verify procedures for each employee for whom an E-Verify case is created.</li>
                                    <li> Notify each job applicant of E-Verify participation by clearly displaying the Notice of E-Verify Participation and the Right to Work posters in English and in Spanish. You may also display the posters in other languages provided by DHS.</li>
                                    <li> Complete Form I-9 for each newly hired employee before creating a case in E Verify.</li>
                                    <li> Obtain a Social Security number (SSN) from each newly hired employee on Form I-9.</li>
                                    <li> Create a case for each newly hired employee no later than the third business day after he or she starts work for pay.</li>
                                    <li> Enter the employee’s email address in E-Verify if it was provided on Form I-9.</li>
                                    <li> Complete Form I-9 for each newly hired employee before creating a case in E Verify.</li>
                                    <li> Provide each employee with notice of and the opportunity to take action on a Tentative Nonconfirmation (Mismatch) or a Dual Tentative Noncomfirmation (Mismatch).</li>
                                    <li> Provide each employee who receives a Mismatch eight federal government work days to take action (i.e., contact the appropriate federal agency to begin to resolve the mismatch).</li>
                                    <li> Allow each newly hired employee to start and continue working during the E-Verify verification process, even if he or she receives and takes action to resolve the mismatch, and even if it takes longer than ten days to resolve the mismatch.</li>
                                </ul>

                                <h5>Federal contractors participating in E-Verify MUST also:</h5>

                                <ul>
                                    <li> Only use E-Verify for an existing employee under limited circumstances.</li>
                                    <li> Use special considerations when you decide how to, and whether to verify an existing employee.</li>
                                    <li> Create a case only within a specified period of time. If a case is not created within these timeframes, you must note the reason for delay and attach it to Form I-9.</li>
                                    <li> Follow E-Verify procedures for each newly hired employee and an existing employee assigned to a covered federal contract while participating in E-Verify.</li>
                                </ul>

                                <p>
                                    <small>For more information about rules specific to Federal contractors, review the <a target="_blank" href="https://www.e-verify.gov/supplemental-guide-for-federal-contractors">Supplemental Guide for Federal Contractors.</a></small>
                                </p>

                                <h5>Employers and E-Verify Federal contractors participating in E-Verify MUST NOT:</h5>

                                <ul>
                                    <li> Use E-Verify to pre-screen an applicant for employment. Prescreening is creating an E-Verify case before a job offer is made or before an employee has accepted a job offer.</li>
                                    <li> Take adverse action against or terminate an employee because he or she received a mismatch result, unless E Verify issues a case result of Final Nonconfirmation.</li>
                                    <li> Specify or request which Form I-9 documentation an employee must use, except to specify that any Form I-9 List B document the employee chooses to present must contain a photo.</li>
                                    <li> Use E-Verify to discriminate against any job applicant or employee on the basis of his or her national origin, citizenship, or immigration status.</li>
                                    <li> Share any user ID and/or password.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page1a'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page1c'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
