<template>
<info-widget :collapsible="true" :is-expanded="widgetExpanded" @toggle_expand="widgetExpanded = !widgetExpanded">
    <template v-slot:title>Tax Statuses</template>

    <template v-slot:headerbutton>
        <button class="btn btn-outline-primary" type="button" @click.prevent.stop="modalOpen = true">
            <i class="far fa-plus" aria-hidden="true" title="Add"></i>&nbsp;
            <slot name="add-button">Add</slot>
        </button>
    </template>

    <slot>
        <table class="table css-table widget-table table-fixed" v-if="taxStatuses && taxStatuses.length">
            <thead>
                <tr>
                    <th>Tax</th>
                    <th>Calculation</th>
                </tr>
            </thead>
            <tbody v-for="taxStatus in taxStatuses" :key="taxStatus.id" :class="{'active-item': false}">
                <tr>
                    <td class="truncate-wrapper">{{ taxStatus.tax.name }} <span v-if="taxStatus.is_resident_tax" class="color-red">*</span></td>
                    <td class="truncate-wrapper">{{ taxStatus.calculation_pretty }}</td>
                </tr>
            </tbody>
        </table>

        <p class="p-1 ml-1" v-else><em>This section is empty.</em></p>

        <tax-status-modal
            v-if="modalOpen"
            :employee="employee"
            @close="modalOpen = false"
            @updated="onUpdate"
        />
    </slot>

</info-widget>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
import TaxStatusModal from './TaxStatusModal'

export default {
    props: ['employee', 'taxStatuses', ],
    components: {InfoWidget, TaxStatusModal},
    data() {
        return {
            widgetExpanded: false,
            expandedSections: {},
            expandedItems: {},

            modalOpen: false,
        }
    },
    methods: {
        onUpdate($event) {
            this.$emit('updated', $event)
        },
    },
}
</script>
