<template>
    <sidebar @close="$emit('close')">
        <template v-slot:title v-if="template">
            {{ template.name }}
        </template>

        <template v-slot:header v-if="template">
            <div class="row mb-0">
                <div class="col">
                    <span class="text-muted">
                        {{ template.company.name }}
                    </span>
                </div>
            </div>

            <div class="mb-0">
                <div class="d-flex flex-wrap">
                    <div class="py-qt text-nowrap">
                        <!-- Type of object -->
                        <span class="text-muted">
                            HR Template &#8226;
                        </span>
                        <!-- Status -->
                        <span class="text-muted" v-if="template.is_active">
                            Active
                        </span>
                        <span class="text-danger-dark text-bold text-italic" v-if="!template.is_active">
                            Inactive
                        </span>
                    </div>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col">
                    <span class="text-muted">
                        Last modified: {{ $filters.date(template.updated_on) }}
                    </span>
                </div>
            </div>


            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href id="tabLinkMain1" data-toggle="tab" role="tab" aria-controls="tabContentMain1" :aria-selected="activeTab == 'main'" @click.prevent="activeTab = 'main'">Main</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href id="tabLinkHistory1" data-toggle="tab" role="tab" aria-controls="tabContentHistory" :aria-selected="activeTab == 'changelogs'" @click.prevent="activeTab = 'changelogs'">History</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot>
            <!-- Tabs Content Container -->
            <div class="tab-content" v-if="template">
                <!-- Tab Pane 1 -->
                <div class="tab-pane" :class="{'show active': activeTab == 'main'}" id="tabContentMain1" role="tabpanel" aria-labelledby="tabLinkMain1">

                    <!-- Info Widget -->
                    <info-widget :collapsible="false">
                        <template v-slot:title>Template Information</template>

                        <template v-slot:headerbutton>
                            <button v-if="template.is_active" type="button" class="btn btn-outline-primary" @click.prevent="editModalOpen = true">
                                <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                Edit
                            </button>
                        </template>

                        <slot>
                            <div class="card-body py-1">
                            <!-- Documents -->
                            <div class="p-1">
                                <div class="info-widget-empty" v-if="!template.documents">
                                    This template has no HR Documents.
                                </div>
                                <div v-if="template.documents">

                                    <ul class="list-unstyled mb-0">
                                        <!-- Header row -->
                                        <li>
                                            <div class="form-row align-items-baseline py-1">
                                                <div class="col">
                                                    <h5 class="m-0">Documents ({{ template.documents.length }})</h5>
                                                </div>
                                            </div>
                                        </li>

                                        <li :class="{'border-top': $index > 0}" v-for="(document, $index) in template.documents" :key="document.doc_key" v-if="document.is_active">
                                            <div class="form-row align-items-baseline py-3q">
                                                <div class="col">
                                                    <a href @click.prevent="showDocument(document)" class="truncate-content">
                                                        <i class="far fa-fw fa-file-pdf mr-hf"></i>
                                                        <span>{{ document.name }}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- Assignment -->
                            <div class="p-1">
                                <div class="info-widget-empty" v-if="!visiblePositionCompanies.length">
                                    This template is not assigned to anything.
                                </div>
                                <div v-if="visiblePositionCompanies.length">

                                    <ul class="list-unstyled mb-0" v-if="visiblePositionCompanies.length">
                                        <!-- Header row -->
                                        <li>
                                            <div class="form-row align-items-baseline py-1">
                                                <div class="col">
                                                    <h5 class="m-0">Positions ({{ template.positions.length }})</h5>
                                                </div>
                                            </div>
                                        </li>

                                        <li v-for="cp in visiblePositionCompanies" :key="cp.company.id">
                                            <!-- Company heading -->
                                            <div class="text-small text-muted pt-1 pb-qt border-bottom mb-hf">
                                                <span>{{ cp.company.name }}</span>
                                            </div>

                                            <!-- Position rows -->
                                            <div class="form-row align-items-baseline py-hf" v-for="position in cp.positions" :key="position.id">
                                                <div class="col">
                                                    <span>{{ position.terminal_value }}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                    <div class="mt-1">
                                        <button v-if="!showAllPositions && !allPositionsVisible" type="button" class="btn btn-link btn-block" @click.prevent="showAllPositions = true">See all</button>
                                    </div>
                                </div>
                            </div>
                            <button-row stacked class="pt-0">
                                <button class="btn btn-outline-primary btn-block my-2" @click.prevent="inactivate()" v-if="template.is_active">Deactivate Template</button>
                                <button class="btn btn-outline-primary btn-block my-2" @click.prevent="reactivate()" v-if="!template.is_active">Reactivate Template</button>
                            </button-row>
                            </div>
                        </slot>
                    </info-widget>
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'changelogs'}" id="tabContentHistory1" role="tabpanel" aria-labelledby="tabLinkHistory1">
                    <change-history :client="client" :changelogs="template.history_logs" :active-changelog-id="activeChangelogId"/>
                </div>
            </div>
        </slot>

        <edit-modal
            :template="template"
            :client="client"
            @updated="onUpdate"
            @close="editModalOpen = false"
            v-if="template && editModalOpen"
        />

        <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="showDocument(null)" ref="pdfModal" />
    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'
import ChangeHistory from '@/components/ChangeHistory'
import EditModal from './HRTemplateModal'
import PdfModal from '@/components/PdfModal'
import ButtonRow from '@/components/ButtonRow'

export default {
    props: ['templateId', 'client', 'tab', 'section', 'itemId', ],
    components: {Sidebar, InfoWidget, ChangeHistory, EditModal, PdfModal, ButtonRow},
    computed: {
        positionCompanies() {
            const result = []
            const companies = {}
            this.template.positions.forEach(p => {
                if (!companies[p.company.id]) {
                    companies[p.company.id] = {
                        company: p.company,
                        positions: [],
                    }
                }

                companies[p.company.id].positions.push(p)
            })

            Object.keys(companies).forEach(key => {
                result.push(companies[key])
            })

            return result
        },
        visiblePositionCompanies() {
            if (this.showAllPositions) {
                return this.positionCompanies
            }

            const MAX_POSITIONS = 2
            const result = []
            let count = 0
            for (const cp of this.positionCompanies) {
                result.push({
                    company: cp.company,
                    positions: [],
                })

                for (const p of cp.positions) {
                    result[result.length - 1].positions.push(p)
                    count += 1
                    if (count >= MAX_POSITIONS) {
                        break
                    }
                }

                if (count >= MAX_POSITIONS) {
                    break
                }
            }

            return result
        },
        allPositionsVisible() {
            let count = 0
            for (const cp of this.visiblePositionCompanies) {
                count += cp.positions.length
            }

            return (count == this.template.positions.length)
        },
    },
    data() {
        return {
            template: null,
            showAllPositions: false,
            activeTab: this.tab,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,
            editModalOpen: false,
            currentPDF: null,
        }
    },
    watch: {
        'templateId'() {
            this.getTemplate()
        },
    },
    mounted() {
        this.getTemplate()
    },
    methods: {
        close() {
            this.$emit('paymasters_close_sidebar')
        },
        getTemplate() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/hr-templates/${this.templateId}`).then(resp => {
                this.template = resp
                let allDocs = this.template.hr_documents.concat(this.template.hr_form_documents).filter(d => d.is_active)
                this.template.documents = allDocs
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(template) {
            Object.assign(this.template, template)
            let allDocs = this.template.hr_documents.concat(this.template.hr_form_documents).filter(d => d.is_active)
            this.template.documents = allDocs
            this.$emit('updated', template)
        },
        reactivate() {
            this.editModalOpen = true
        },
        inactivate() {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/hr-templates/${this.template.id}/status`, {status: 'inactive'}).then(resp => {
                this.onUpdate(resp)
                this.$store.dispatch('STOP_LOADING')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        showDocument(document) {
            if (!document) {
                this.currentPDF = null
                return
            }

            let title
            let fileUrl

            if (document.doc_type == 'hr-form-doc') {
                title = document.yaml_form.name
                fileUrl = `${this.$api.API_BASE}/clients/${this.client.id}/hr-form-documents/${document.id}/file/pdf`
            } else {
                title = document.name
                fileUrl = `${this.$api.API_BASE}/clients/${this.client.id}/hr-documents/${document.id}/file/pdf?file_id=${document.current_version.id}`
            }

            this.currentPDF = {
                title: title,
                url: {
                    url: fileUrl,
                    withCredentials: true,
                }
            }
        },
    },
}
</script>

