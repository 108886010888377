<template>
    <sidebar @close="$emit('close')">
        <template v-slot:title v-if="tax">
            {{ tax.name }}
        </template>

        <template v-slot:header v-if="tax">
            <div>
                <div>
                    <span class="text-semibold">Jurisdiction: </span>
                    <span>{{ tax.jurisdiction.canonical_name }}</span>
                </div>

                <div v-if="tax.subtax_of_id">
                    <span class="text-semibold">Subtax Of: </span>
                    <span>{{ tax.subtax_of.name }}</span>
                </div>


                <div>
                    <span class="text-semibold">Status: </span>
                    <span v-if="tax.current_version.is_past">Past</span>
                    <span v-if="tax.current_version.is_active">Current</span>
                    <span v-if="tax.current_version.is_future">Future</span>
                </div>

                <div>
                    <span class="text-semibold">Has been used: </span>
                    <span v-if="tax.has_been_used">Yes</span>
                    <span v-if="!tax.has_been_used">No</span>
                </div>

            </div>
            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a href class="nav-link" :class="{active: activeTab == 'main'}" data-toggle="tab" role="tab" aria-controls="tabContentMain" :aria-selected="activeTab == 'main'" @click.prevent="activeTab='main'">Main</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'versions'}" href="#tabContentVersions" id="tabVersions" data-toggle="tab" role="tab" aria-controls="tabContentVersions" :aria-selected="activeTab == 'versions'" @click.prevent="activeTab='versions'">Versions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot v-if="tax">
            <div class="tab-content flex-body flex-container">

                <div class="tab-pane flex-body" :class="{'show active': activeTab == 'main'}" id="tabContentMain" role="tabpanel" aria-labelledby="tabLinkMain">
                    <div class="card mb-2">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Current Version</h4>
                            </span>

                            <button class="btn btn-primary" type="button" @click.prevent="editIsOpen = true">Edit</button>
                            <button class="btn btn-outline-primary ml-1" type="button" @click.prevent="onDelete" v-if="!tax.has_been_used">Delete</button>
                        </div>

                        <div class="card-body py-1">
                            <tax-version :version="tax.current_version" />
                        </div>
                    </div>

                    <tax-table-sidebar-widget
                        :tax="tax"
                        :jurisdictions="jurisdictions"
                        @updated="onUpdate()"
                    />

                    <tax-standard-deduction-sidebar-widget
                        :tax="tax"
                        :jurisdictions="jurisdictions"
                        :standard-deduction-formulas="standardDeductionFormulas"
                        @updated="onUpdate()"
                    />

                    <tax-exemption-sidebar-widget
                        :tax="tax"
                        :jurisdictions="jurisdictions"
                        :exemption-formulas="exemptionFormulas"
                        @updated="onUpdate()"
                    />

                    <tax-low-income-exemption-sidebar-widget
                        :tax="tax"
                        :jurisdictions="jurisdictions"
                        :low-income-exemption-formulas="lowIncomeExemptionFormulas"
                        @updated="onUpdate()"
                    />

                    <intertax-rules-sidebar-widget
                        :tax="tax"
                        :taxes="taxes"
                        :jurisdictions="jurisdictions"
                        @updated="onUpdate()"
                    />

                    <percent-personal-tax-credit-table-sidebar-widget
                        :tax="tax"
                        :jurisdictions="jurisdictions"
                        @updated="onUpdate()"
                    />

                    <tax-recapture-table-sidebar-widget
                        :tax="tax"
                        :jurisdictions="jurisdictions"
                        @updated="onUpdate()"
                    />
                </div>

                <div class="tab-pane flex-body" :class="{'show active': activeTab == 'versions'}" id="tabContentVersions" role="tabpanel" aria-labelledby="tabLinkVersions">

                    <version-widget :version="version" v-for="version in versions" v-if="versions && versions.length">
                        <tax-version :version="version" />
                    </version-widget>

                    <p v-if="!versions || !versions.length">
                        Loading...
                    </p>
                </div>
            </div>

            <edit-modal
                v-if="editIsOpen"
                :tax="tax"
                :taxes="taxes"
                :jurisdictions="jurisdictions"
                :tax-exemptions-groups="taxExemptionsGroups"
                @updated="onUpdate($event)"
                @close="editIsOpen = false"
            />
        </slot>
    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'
import TaxTableSidebarWidget from './TaxTableSidebarWidget'
import TaxStandardDeductionSidebarWidget from './TaxStandardDeductionSidebarWidget'
import TaxExemptionSidebarWidget from './TaxExemptionSidebarWidget'
import TaxLowIncomeExemptionSidebarWidget from './TaxLowIncomeExemptionSidebarWidget'
import IntertaxRulesSidebarWidget from './IntertaxRulesSidebarWidget'
import PercentPersonalTaxCreditTableSidebarWidget from './PercentPersonalTaxCreditTableSidebarWidget'
import TaxRecaptureTableSidebarWidget from './TaxRecaptureTableSidebarWidget'
import EditModal from './TaxModal'
import TaxVersion from './TaxVersion'
import VersionWidget from './VersionWidget'

export default {
    components: {Sidebar, InfoWidget, TaxTableSidebarWidget, EditModal, TaxStandardDeductionSidebarWidget, TaxExemptionSidebarWidget, TaxLowIncomeExemptionSidebarWidget, TaxVersion, VersionWidget, IntertaxRulesSidebarWidget, PercentPersonalTaxCreditTableSidebarWidget, TaxRecaptureTableSidebarWidget, },
    props: ['taxId', 'jurisdictions', 'involuntaryWithholdingTypes', 'deductionTypes', 'earningTypes', 'taxes', 'taxExemptionsGroups', 'standardDeductionFormulas', 'exemptionFormulas', 'lowIncomeExemptionFormulas'],
    computed: {
        versions() {
            if (!this.tax) {
                return []
            }
            return this.tax.versions.sort((a, b) => a.created_on < b.created_on ? 1 : -1)
        },
    },
    data() {
        return {
            editIsOpen: false,
            activeTab: 'main',
            tax: null,
            expandedVersions: {},
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/taxes/${this.taxId}`).then(resp => {
                this.tax = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onDelete() {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(`/admin/tax-engine/taxes/${this.tax.id}`).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('deleted')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate($evt) {
            if ($evt) {
                this.$emit('updated', $evt)
            }
            else {
                this.$emit('updated')
            }

            this.getData()
        },
    },
}
</script>
