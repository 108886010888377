<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body">
                <div class="container-fluid">

                    <div class="py-2 border-bottom">
                        <div class="row row-alt align-items-center">
                            <div class="col-auto">
                                <div class="d-flex flex-wrap py-1">
                                    <div class="mr-4">
                                        <div class="theading">
                                            Pay Group
                                        </div>
                                        <div class="text-large text-semibold mr-2">
                                            {{ displayPaygroup }}
                                        </div>
                                    </div>
                                    <div class="mr-4">
                                        <div class="theading">
                                            Store
                                        </div>
                                        <div class="text-large text-semibold mr-2">
                                            {{ displayStore }}
                                        </div>
                                    </div>
                                    <div class="mr-0">
                                        <div class="theading">
                                            Pay Period
                                        </div>
                                        <div class="text-large text-semibold mr-2">
                                            <span class="text-thin">
                                                {{ displayDateStartDOW }}
                                            </span>
                                            {{ displayDateStart }}
                                            <span class="text-thin">
                                                 &ndash; {{ displayDateEndDOW }}
                                            </span>
                                            {{ displayDateEnd }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col text-md-right">
                                <div class="py-1">
                                    <div class="step-items">
                                        <div class="step-item" :class="{'step-item-active': activeStep == 1}">
                                            <div class="step-item-number">
                                                1
                                            </div>
                                            <div class="step-item-label">
                                                Start<br>
                                                Import
                                            </div>
                                        </div>
                                        <div class="step-item" :class="{'step-item-active': activeStep == 2}">
                                            <div class="step-item-number">
                                                2
                                            </div>
                                            <div class="step-item-label">
                                                Review<br>
                                                Issues
                                            </div>
                                        </div>
                                        <div class="step-item" :class="{'step-item-active': activeStep == 3}">
                                            <div class="step-item-number">
                                                3
                                            </div>
                                            <div class="step-item-label">
                                                Final<br>
                                                Review
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-body pb-2">
                        <router-view class="view" ref="stepComponent" v-if="dataLoaded"
                            :client="client"
                            :task-detail="taskDetail"
                        />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'

import moment from 'moment'

export default {
    props: ['client'],
    data() {
        return {
            dataLoaded: false,
            taskDetail: null,
            pos_do_new_hires: false,
            statusDetail: "",
            posData: {
                'integration': '',
                'store': '*',
                'paygroup': '*',
                'date_start': '',
                'date_end': '',
            },
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Point-of-Sale Integration')
        this.getInitialData()
    },
    computed: {
        taskId() {
            return this.$route.params.taskId
        },
        activeStep() {
            return this.$route.meta.wizardStep || 1
        },
        displayPaygroup() {
            if (this.posData.paygroup == '*') {
                return 'ALL'
            }

            return this.posData.paygroup
        },
        displayStore() {
            if (this.posData.store == '*') {
                return 'ALL'
            }

            return this.posData.store
        },
        displayDateStart() {
            let value = this.posData.date_start
            let date_format = 'MM/DD/YYYY' ? value.includes('/') : 'YYYY-MM-DD'
            let date_moment = value ? moment(value, date_format) : null
            if (date_moment) {
                return date_moment.format('MM/DD/YYYY')
            }
            return ''
        },
        displayDateEnd() {
            let value = this.posData.date_end
            let date_format = 'MM/DD/YYYY' ? value.includes('/') : 'YYYY-MM-DD'
            let date_moment = value ? moment(value, date_format) : null
            if (date_moment) {
                return date_moment.format('MM/DD/YYYY')
            }
            return ''
        },
        displayDateStartDOW() {
            let value = this.posData.date_start
            let date_format = 'MM/DD/YYYY' ? value.includes('/') : 'YYYY-MM-DD'
            let date_moment = value ? moment(value, date_format) : null
            if (date_moment) {
                return date_moment.format('ddd')
            }
            return ''
        },
        displayDateEndDOW() {
            let value = this.posData.date_end
            let date_format = 'MM/DD/YYYY' ? value.includes('/') : 'YYYY-MM-DD'
            let date_moment = value ? moment(value, date_format) : null
            if (date_moment) {
                return date_moment.format('ddd')
            }
            return ''
        },
    },
    methods: {
        getInitialData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            const url = `/clients/${this.client.id}/pos-integration/${this.taskId}/detail`

            this.$api.get(url).then(resp => {
                this.posData.store = resp['store']
                this.posData.paygroup = resp['paygroup']
                this.posData.date_start = resp['date_start']
                this.posData.date_end = resp['date_end']
                this.posData.store = resp['store']

                this.pos_do_new_hires = resp['pos_do_new_hires']
                this.taskDetail = resp

                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
    },
}
</script>
