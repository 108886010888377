<script>

import api from '@/api'
import bus from '@/bus'
import ArrayBufferToString from 'arraybuffer-to-string'

import { saveAs } from 'file-saver'

export default {
    methods: {
        downloadFile(url) {
            this.$store.dispatch('START_LOADING')
            api.get(url, true, false).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                if (this.$store.state.isInApp) {
                    try {
                        if (typeof global !== 'undefined' && global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
                            let ab_str = Uint8ToString(new Uint8Array(resp))
                            global.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
                                command: 'print',
                                content: btoa(ab_str)
                            }))
                        } else {
                            console.error("In app, but global.webkit.messageHandlers.cordova_iab not found!");
                            throw {'__all__': ["Error saving file"]}
                        }
                    } catch (error) {
                        console.error(error);
                        throw {'__all__': ["Error saving file"]}
                    }
                } else {
                    // TODO: make filename customizable
                    saveAs(new Blob([resp], {type: "application/pdf"}), `download.pdf`)
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
    },
}

// workaround for RangeError if you do ArrayBufferToString(resp, 'binary') of a large file
function Uint8ToString(u8_array){
    let chunk_length = 32768;
    let c = [];
    for (var i = 0; i < u8_array.length; i += chunk_length) {
        c.push(String.fromCharCode.apply(null, u8_array.subarray(i, i + chunk_length)));
    }
    return c.join('');
}

</script>
