<template>
    <div>
        <nav-secondary :navOptions="navOptions"></nav-secondary>
        <router-view class="flex-page-content flex-container view"/>
    </div>
</template>

<script>
import NavSecondary from '@/components/NavSecondary'

export default {
    components: { NavSecondary, },
    props: ['client', ],
    data() {
        return {
            navOptions: [
                {'text': 'Tax Forms & Config', 'value': 'admin-tax-engine-states-cities'},
                {'text': 'Jurisdictions', 'value': 'admin-tax-engine-jurisdictions'},
                {'text': 'Earning Types', 'value': 'admin-tax-engine-earning-types'},
                {'text': 'Deduction Types', 'value': 'admin-tax-engine-deduction-types'},
                {'text': 'Reimbursement Types', 'value': 'admin-tax-engine-reimbursement-types'},
                {'text': 'Tax Exemptions Groups', 'value': 'admin-tax-engine-tax-exempt-groups'},
                {'text': 'Taxes', 'value': 'admin-tax-engine-taxes'},
            ]
        }
    },
    mounted() {
        const routes = this.navOptions.map(x => x.value)
        if (this.$route.name == 'admin-tax-engine') {
            this.$router.replace({name: routes[0]})
        }
    },
}
</script>
