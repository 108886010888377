<template>
    <modal size="xl" ref="modal" @close="$emit('close')" :is-closeable="isCloseable" :autoshow="true">
        <template v-slot:title>
            <span v-if="tax">Update Tax</span>
            <span v-if="!tax">Add Tax</span>
        </template>
        <slot>

            <form class="mui-form" @submit.prevent="onSubmit">
                <div class="form-row mb-4">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.type" :errors="errors.type" type="select" label="Type" :readonly="!!tax" :options="taxTypeOptions" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.jurisdiction_type" :errors="errors.jurisdiction_type" type="select" label="Jurisdiction Type" :readonly="!!tax" :options="jurisdictionTypeOptions" />
                    </div>
                    <div class="col-6" v-show="formData.jurisdiction_type && formData.jurisdiction_type != 'fed'">
                        <form-input v-model="formData.jurisdiction" :errors="errors.jurisdiction" type="select" label="Jurisdiction" :readonly="!!tax" :options="jurisdictionOptions" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" :readonly="!!tax" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.tax_exemptions_group" :errors="errors.tax_exemptions_group" type="select" label="Tax Exemptions Group" :options="taxExemptionsGroupOptions" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.exemption_amount" :errors="errors.exemption_amount" type="currency" label="Per Exemption Amount" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.exemption_credit_amount" :errors="errors.exemption_credit_amount" type="currency" label="Per Exemption Credit" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col">
                        <form-input v-model="formData.mastertax_tax_codes" :errors="errors.mastertax_tax_codes" type="textarea" label="Mastertax Tax Codes" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col">
                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">Tax Calculation Is Affected by Other Taxes</h4>
                                </span>
                            </div>

                            <div class="card-body py-1">
                                <table-selector
                                    :sections="[{title: 'All', id: 'all'}]"
                                    :headers="[{label: 'Name', classes: 'cell-wide'}, {label: 'Jurisdiction', classes: ''}]"
                                    :items="taxSelectionData"
                                    v-model="formData.affected_by_taxes"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col">
                        <form-input v-model="formData.tags" :errors="errors.tags" type="textarea" label="Tags" />
                    </div>
                </div>

            </form>
        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import TableSelector from '@/components/TableSelector'

export default {
    props: ['tax', 'jurisdictions', 'taxExemptionsGroups', 'taxes', ],
    mixins: [FormMixin, ModalMixin, ],
    components: {TableSelector, },
    computed: {
        jurisdictionTypeOptions() {
            return [
                {text: 'Federal', value: 'fed'},
                {text: 'State', value: 'state'},
                {text: 'City', value: 'city'},
                {text: 'County', value: 'county'},
                {text: 'School District', value: 'school-district'},
            ]
        },
        taxTypeOptions() {
            return [
                {text: 'Income Tax', value: 'income'},
                {text: 'Unemployment Insurance', value: 'unemp-ins'},
                {text: 'Service Fee', value: 'service-fee'},
            ]
        },
        jurisdictionOptions() {
            return this.jurisdictions.filter(j => j.type == this.formData.jurisdiction_type).map(j => {
                return {text: j.canonical_name, value: j.id}
            }).sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
        },
        taxExemptionsGroupOptions() {
            return this.taxExemptionsGroups.map(teg => {
                return {text: teg.name, value: teg.id}
            }).sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
        },
        taxSelectionData() {
            // This returns data so we can select it via the table selector
            const result = []
            this.taxes
                .filter(t => t.id != (this.tax ? this.tax.id : null))
                .filter(t => ['state', 'fed'].includes(t.jurisdiction.type))
                .forEach(item => {
                result.push({
                    id: item.id,
                    cells: [
                        item.name,
                        item.jurisdiction.canonical_name,
                    ],
                })
            })

            result.sort((a, b) => {
                return a.cells[0] > b.cells[0] ? 1 : -1
            })

            return [result]
        },
    },
    watch: {
        'formData.jurisdiction_type'(n, o) {
            if (n == o) {return}
            if (this.formData.jurisdiction_type == 'fed') {
                this.formData.jurisdiction = this.jurisdictions.find(j => j.type == 'fed').id
            }
            else {
                this.formData.jurisdiction = ''
            }
        },
    },
    data() {
        return {
            formRules: {
                starts_on: {presence: {allowEmpty: false}, date: true},
                name: {presence: {allowEmpty: false}},
                jurisdiction_type: {presence: {allowEmpty: false}},
                type: {presence: {allowEmpty: false}},
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.tax) {
                return {
                    starts_on: this.tax.current_version.starts_on,
                    inactive_on: this.tax.current_version.inactive_on,
                    name: this.tax.name,
                    mastertax_tax_codes: this.tax.current_version.mastertax_tax_codes.join('\n'),
                    tags: this.tax.current_version.tags.map(t => t.name).join('\n'),
                    affected_by_taxes: this.tax.current_version.affected_by_taxes.map(x => x.id),
                    type: this.tax.type,
                    jurisdiction_type: this.tax.jurisdiction.type,
                    jurisdiction: this.tax.jurisdiction.id,
                    tax_exemptions_group: this.tax.current_version.tax_exemptions_group_id,
                    exemption_amount: this.tax.current_version.exemption_amount,
                    exemption_credit_amount: this.tax.current_version.exemption_credit_amount,
                }
            } else {
                return {
                    starts_on: '',
                    inactive_on: '',
                    name: '',
                    type: '',
                    mastertax_tax_codes: '',
                    tags: '',
                    jurisdiction_type: '',
                    jurisdiction: '',
                    tax_exemptions_group: '',
                    exemption_amount: '',
                    exemption_credit_amount: '',
                    affected_by_taxes: [],
                }
            }
        },
        getFormData() {
            const data = JSON.parse(JSON.stringify(this.formData))
            data.mastertax_tax_codes = data.mastertax_tax_codes.split('\n').map(x => x.trim()).filter(x => x)
            data.tags = data.tags.split('\n').map(x => x.trim().toUpperCase()).filter(x => x)
            return data
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise, url;
            if (this.tax) {
                url = `/admin/tax-engine/taxes/${this.tax.id}`
                promise = this.$api.put(url, this.getFormData())
            }
            else {
                url = `/admin/tax-engine/taxes`
                promise = this.$api.post(url, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>


