<script>
import SearchWorker from '../search.worker.js?worker'


export default {
    data() {
        return {
            searchIndexes: [],
            foundDataIds: null,
            searchWorker: null,
        }
    },
    watch: {
        search() {
            this.performSearch()
        },
        data() {
            this.createSearchIndexes()
        },
    },
    created() {
        this.searchWorker = new SearchWorker()
        this.searchWorker.addEventListener('message', (m) => {
            if (m.data.action == 'search') {
                this.foundDataIds = m.data.result
            }
        })
    },
    mounted() {
        this.createSearchIndexes()
    },
    unmounted() {
        if (this.searchWorker) {
            this.searchWorker.terminate()
            this.searchWorker = null
        }
    },
    computed: {
        searchedData() {
            if (this.search.trim() && this.foundDataIds) {
                const result = []
                this.foundDataIds.forEach((sectionIds, sectionIdx) => {
                    sectionIds = new Set(sectionIds)
                    result[sectionIdx] = this.data[sectionIdx].filter(r => sectionIds.has(r.id))
                })
                return result
            }
            return this.data
        },
    },
    methods: {
        createSearchIndexes() {
            // turn Vue 3 Proxy object into raw object before sending to worker via postMessage
            const headers = JSON.parse(JSON.stringify(this.headers))  // May contain methods
            const sections = JSON.parse(JSON.stringify(this.sections))
            const data = JSON.parse(JSON.stringify(this.data))
            this.searchWorker.postMessage({
                action: 'create',
                headers: headers,
                sections: sections,
                data: data,
            })
        },
        performSearch() {
            if (this.search.trim()) {
                this.searchWorker.postMessage({
                    action: 'search',
                    search: this.search,
                })
            }
            else {
                this.foundDataIds = null
            }
        },
    },
}
</script>
