<template>
    <page>
        <fast-table
            v-if="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentPosition = $event"
        >

            <template v-slot:table-actions>
                <button type="button" class="btn btn-primary btn-collapse mr-2" @click.prevent="validatePositions()">
                    <i class="far fa-fw fa-question-circle"></i> <span class="btn-text">Validate</span>
                </button>

                <a class="btn btn-primary btn-collapse mr-2" :href="exportFileURL">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Export</span>
                </a>

                <button type="button" class="btn btn-primary btn-collapse mr-2" @click.prevent="$refs.importFileModal.open()">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Import</span>
                </button>
            </template>
        </fast-table>

        <import-file-modal :client="client" :import-file-type="'positions'" @updated="getData()" ref="importFileModal" />

        <positions-validation-result-modal :client="client" :results="positionsValidationResultsData" ref="positionsValidationResultModal" v-if="positionsValidationResultsData" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import ImportFileModal from './components/ImportFileModal'
import PositionsValidationResultModal from './components/PositionsValidationResultModal'

export default {
    components: {Page, FastTable, ImportFileModal, PositionsValidationResultModal, },
    props: ['client', ],
    computed: {
        tableData() {
            const tableData = []

            // 'CompanyCode', 'WorkLocation', 'PayGroup', 'AlternatePayRate'
            this.positions.forEach((o, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentPosition && o.id == this.currentPosition.id,
                    object: o,
                    cells: [
                        o.CompanyCode,
                        o.OrgLevel1,
                        o.OrgLevel2,
                        o.OrgLevel3,
                        o.OrgLevel4,
                        o.OrgLevel5,
                        o.WorkLocation,
                        o.PayGroup,
                        o.AlternatePayRate,
                        o.POSValue,
                        o.SOCCode,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
        exportFileURL() {
            return `${this.$api.API_BASE}/admin/clients/${this.client.id}/config/positions/export`
        }
    },
    data() {
        return {
            positions: [],
            dataLoaded: false,
            currentPosition: null,
            positionsValidationResultsData: null,

            headers: [
                {label: 'Company', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Org Level 1', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Org Level 2', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Org Level 3', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Org Level 4', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Org Level 5', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Work Location', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Paygroup', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Alternate Pay Rate', classes: '', isSearchable: true},
                {label: 'POS Value', classes: '', isSearchable: true},
                {label: 'SOC Code', classes: '', isSearchable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', `Client ${this.client.client_code} - ${this.client.name} / Terminal Values`)
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/config/positions`).then((resp) => {
                this.positions = resp['data']
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        validatePositions() {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/admin/clients/${this.client.id}/config/positions/validate`, {}).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.positionsValidationResultsData = resp;
                this.$nextTick(() => {
                    this.$refs.positionsValidationResultModal.open();
                })
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        }
    },
}

</script>
