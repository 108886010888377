<template>
    <modal @close="$emit('close')" ref="modal" size="lg" :autoshow="true" :is-closeable="isCloseable" class="modal--filters">
        <template v-slot:title>Filters</template>

        <slot>
            <filter-editor
                :possible-filters="possibleFilters"
                :filter-values="filterValues"
                :quick-filter-on="quickFilterOn"

                :filteredData="filteredData"
                :data="data"
                :headers="headers"
                :sections="sections"
                :managers="managers"

                @close="close"
                @filter="onFilters"
            />
        </slot>

    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import FilterEditor from './FilterEditor'

export default {
    props: ['possibleFilters', 'filterValues', 'quickFilterOn', 'sections', 'headers', 'data', 'filteredData', 'managers', ],
    mixins: [ModalMixin],
    components: {FilterEditor, },
    methods: {
        onFilters(filters) {
            this.$emit('filter', filters);
        },
    },
}
</script>
