<template>
<div class="card mb-2 dd-account-card">
    <div class="card-body p-0">
        <div class="d-flex align-items-center">
            <div class="flex-grow-1 overflow-hidden px-2 py-1">
                <i v-if="invalidDirectDeposit" class="fa fa-fw fa-exclamation-triangle text-warning mr-hf "></i>
                <span class="dd-account-name" :title="account.bank_name || 'Bank'">
                    {{ account.bank_name || 'Bank' }}
                </span>
                <div class="dd-account-details">
                    <span class="my-0 text-nowrap">
                        <span>Routing</span>
                        {{ account.routing_number }}
                    </span>
                    <span class="detail-divider mx-hf">-</span>
                    <span class="my-0 text-nowrap">
                        <span>Account</span>
                        <template v-if="revealedAccount">
                            {{ revealedAccount.account_number }}
                            <a href @click.prevent="hideAccount()"><i class="far fa-fw fa-eye-slash"></i></a>
                        </template>
                        <template v-else-if="account.id && account.account_number_redacted">
                            {{ account.account_number_redacted }}
                            <a v-if="approvalView && account.id" href @click.prevent="revealAccount()"><i class="far fa-fw fa-eye"></i></a>
                        </template>
                        <template v-else>
                            {{ account.account_number }}
                        </template>
                    </span>
                    <span class="detail-divider mx-hf">-</span>
                    <span class="dd-account-type text-nowrap">{{ getDisplayAccountType(account.account_type) }}</span>
                </div>
            </div>
            <div
                v-if="!(numAccounts == 1 && account.split_type == 'remainder')"
                class="align-self-stretch text-center d-flex flex-column justify-content-center align-items-center px-1 bg-150 border-left rounded-right"
                :class="[numAccounts > 1 ? 'dd-account-split--double' : 'dd-account-split--single', 'text-right']"
            >
                <span v-if="account.split_type == 'remainder'" class="text-muted d-inline-block mr-1 split-indicator">
                    Remainder
                </span>
                <span v-if="account.split_type == 'percentage'" class="text-muted d-inline-block mr-1 split-indicator">
                    {{ account.percentage }}% per pay
                </span>
                <span v-if="account.split_type == 'fixed'" class="text-muted d-inline-block mr-1 split-indicator">
                    ${{ account.amount }} per pay
                </span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        props: ['account', 'numAccounts', 'approvalView', 'invalidDirectDeposit', 'url', ],
        data() {
            return {
                revealedAccount: null
            }
        },
        methods: {
            revealAccount() {
                // only by manager for now - re-fetch the account in order to get the *full* #
                let url = this.url + '/' + this.account.id
                this.$api.post(url, {unmasked: true}).then((resp) => {
                    this.revealedAccount = resp
                }).catch((errors) => {
                    this.$bus.showError(errors.__all__)
                })
            },
            hideAccount() {
                this.revealedAccount = null
            },
            getDisplayAccountType(accountType) {
                if (accountType == 'CHECKING' || accountType == 'checking') {
                    return 'Checking'
                } else if (accountType == 'SAVINGS' || accountType == 'savings') {
                    return 'Savings'
                } else {
                    return accountType
                }
            }
        },
    }
</script>