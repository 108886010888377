<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                    
                    <div class="container-centered-s py-4">

                        <h1 class="mb-4 text-center">Contact your Employer</h1>
                        <p>
                            Your employer will need to update your email address in Payroll.
                        </p>

                        <p class="text-center">
                            <router-link class="btn btn-link" :to="{name: 'login'}">Back to Sign In</router-link>
                        </p>
                
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
