<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container my-4">
                        <div class="row mb-8">
                            <div class="col-6">
                                <h4>Generate Address File</h4>
                                <p>
                                    Generate and download a .csv file for upload to the <a href="https://thefinder.tax.ohio.gov/StreamlineSalesTaxWeb/Upload/FileUpload.aspx?taxType=SchoolDist">OH Finder site</a>.
                                    Includes all employees that are active or were last paid within the past year.
                                </p>


                                <form @submit.prevent class="mt-2">
                                    <form-errors :errors="addressFileFormErrors"/>

                                    <div class="row">
                                        <div class="col">
                                            <button class="btn btn-outline-primary" @click="generateAddressFile">Generate Address File</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <h4>Check School District Matches</h4>
                                <p>
                                    Upload a return .csv file from the <a href="https://thefinder.tax.ohio.gov/StreamlineSalesTaxWeb/Upload/FileUploadStatus.aspx?taxType=SchoolDist">OH Finder site</a>
                                    and find any mismatches between the school district in the OH Finder file and the school district we have on record for each employee.
                                </p>


                                <form @submit.prevent class="mt-2">
                                    <form-errors :errors="checkMatchesFormErrors"/>
                                    
                                    <form-input @upload="formData.oh_return_file = $event[0]" :errors="errors.file" label="Upload file:" helper-text="File extension must be .csv" type="file" accept=".csv"/>

                                    <div class="row mt-2">
                                        <div class="col">
                                            <button class="btn btn-outline-primary" @click="checkMatches">Check Matches</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'
import moment from 'moment'
import { saveAs } from 'file-saver'


export default {
    mixins: [FormMixin, ],
    computed: {},
    data() {
        return {
            formRules: {
                oh_return_file: {presence: {allowEmpty: false},},
            },
            formLabels: {},
            formData: this.makeFormData(),
            addressFileFormErrors: [],
            checkMatchesFormErrors: [],
        }
    },
    methods: {
        makeFormData() {
            return {
                oh_return_file: null,
            }
        },
        generateAddressFile() {
            let url = `/admin/geocoding/oh-finder-address-file`
            api.get(url).then((resp) => {
                saveAs(new Blob([resp], {type: "text/csv"}), `oh-addresses-${moment().format('MM_DD_YYYY')}.csv`)
                this.$store.dispatch('STOP_LOADING')   
            }).catch((errors) => {
                this.errors = errors
                this.addressFileFormErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        checkMatches() {
            if (!this.validate(['oh_return_file'])) {
                return
            }
            let url = `/admin/geocoding/oh-finder-check-matches`
            api.upload(url,this.formData.oh_return_file, {fieldName: 'oh_return_file'}).then((resp) => {
                saveAs(new Blob([resp], {type: "text/csv"}), `oh-mismatches-${moment().format('MM_DD_YYYY')}.csv`)
                this.$store.dispatch('STOP_LOADING')   
            }).catch((errors) => {
                this.errors = errors
                this.checkMatchesFormErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>
