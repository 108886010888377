<template>
    <modal size="xl" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">

    <template v-slot:title>
        <h3 class="modal-title">
            {{ form ? "Update Form:" : "Add New Form:"}}
            <span class="text-thin text-nowrap" v-if="mode == 'form-info'">Form Information</span>
            <span class="text-thin text-nowrap" v-if="mode == 'form-version'">Form Version</span>
        </h3>
    </template>

    <template v-slot:subheading>
        <div v-if="mode != 'form-info'">
            <div>
                <div class="p-1 unpad-x bg-150 rounded">
                    <div><span class="text-muted">Name:</span> {{ formData.name }}</div>
                    <div><span class="text-muted">Type:</span> {{ typeAsText }}</div>
                    <div><span class="text-muted">Is Live:</span> {{ formData.is_live ? 'YES' : 'NO' }}</div>
                </div>
            </div>
        </div>
    </template>

    <slot>
    <div v-if="mode == 'form-version' && form" class="nav-tabs-container mb-2">
        <div class="nav-tabs-scroll-helper">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" :class="{active: versionTab == 'active'}" href="#tabActiveVersion" id="tabLinkActiveVersion" data-toggle="tab" role="tab" aria-controls="tabSUContentMain" :aria-selected="versionTab == 'active'" @click="versionTab = 'active'">Active Version</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" :class="{active: versionTab == 'draft'}" href="#tabDraftVersion" id="tabLinkDraftVersion" data-toggle="tab" role="tab" aria-controls="tabSUContentWarnings" :aria-selected="versionTab == 'draft'" @click="versionTab = 'draft'">Draft</a>
                </li>
            </ul>
        </div>
    </div>
    <form class="mui-form">
        <form-errors :errors="formErrors"/>

            <template v-if="mode == 'form-info'">
                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.slug" :readonly="form" :nocaps="true" :errors="errors.slug" type="text" label="Slug" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-8 col-md-4">
                        <form-input v-model="formData.form_type" :errors="errors.form_type" type="select" label="Type" :options="typeOptions"/>
                    </div>

                    <div class="col-8 col-md-4" v-if="formData.form_type == 'city-w4'">
                        <form-input v-model="formData.city" :errors="errors.city" type="select" label="City" :options="cityOptions" />
                    </div>

                    <div class="col-8 col-md-4" v-if="(formData.form_type == 'state-w4') || (formData.form_type == 'non-resident') || (formData.form_type == 'exempt-cert')">
                        <form-input v-model="formData.state" :errors="errors.state" type="select" label="State" :options="stateOptions" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-8 col-md-4">
                        <form-input v-model="formData.pdf_theme" :errors="errors.pdf_theme" type="select" label="PDF Theme" :options="pdfThemeOptions"/>
                    </div>
                </div>

                <div class="form-row mb-1">
                    <div class="col-8">
                        <form-input v-model="formData.is_live" :errors="errors.is_live" type="checkbox" label="Is live" />
                    </div>
                </div>
            </template>

            <template v-if="mode == 'form-version'">
                <div class="form-row mt-1 mb-1">
                    <div class="col">
                        <form-input v-model="formData.versions[versionTab].version_number" :readonly="versionTab == 'active'" :nocaps="true" :errors="errors.version_number" type="number" label="Version Number" />
                    </div>
                </div>

                <div class="form-row mb-4">
                    <div class="col-8">
                        <form-input v-model="formData.versions[versionTab].pdf_file" :errors="errors.pdf_file" type="file" label="PDF File" @upload="onUpload($event, versionTab, 'pdf_file')" />
                        <small v-if="form && form.versions[versionTab] && form.versions[versionTab].pdf_file">Currently: <a class="textfield" :href="form.versions[versionTab].pdf_file_url" target="_blank">{{ form.versions[versionTab].pdf_file_real_name }}</a></small>
                    </div>
                </div>

                <div class="form-row mb-4">
                    <div class="col-8">
                        <form-input v-model="formData.versions[versionTab].instructions_pdf_file" :errors="errors.instructions_pdf_file" type="file" label="Instructions PDF File" @upload="onUpload($event, versionTab, 'instructions_pdf_file')" helper-text="Optional. If not provided, the PDF form file will be used for instructions."/>
                        <small v-if="form && form.versions[versionTab] && form.versions[versionTab].instructions_pdf_file">Currently: <a class="textfield" :href="form.versions[versionTab].instructions_pdf_file_url" target="_blank">{{ form.versions[versionTab].instructions_pdf_file_real_name }}</a></small>
                    </div>
                </div>

                <div class="form-row mb-3">
                    <div class="col">
                        <form-input v-model="formData.versions[versionTab].yaml" :errors="errors.yaml" type="textarea" rows="12" label="YAML" extraclasses="yaml-input" @update:model-value='validateYAML()' />
                    </div>
                </div>
            </template>

        </form>
    </slot>

    <template v-slot:footer>
        <div class="form-row">

            <template v-if="mode == 'form-info'">
                <div class="col">
                    <button-row>
                        <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmitFormInfo()">
                            {{ form ? "Save" : "Continue" }}
                        </button>
                    </button-row>
                </div>
            </template>

            <template v-if="mode == 'form-version'">
                <div class="col">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="form ? close() : back()">
                            {{ form ? "Cancel" : "Back" }}
                        </button>
                    </button-row>
                </div>
                <div class="col">
                    <button-row v-if="!form">
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmitFormAdd()">
                            Save Form
                        </button>
                    </button-row>

                    <button-row v-if="form && versionTab == 'active'">
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmitFormVersion({version: 'active', saveAsActiveVersion: true})" key="save-primary">
                            Save Active Version
                        </button>
                    </button-row>

                    <button-row v-if="form && versionTab == 'draft'">
                        <button type="button" class="btn btn-outline-primary" @click.prevent="onSubmitFormVersion({version: 'draft', saveAsActiveVersion: false})">
                            Save Draft
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmitFormVersion({version: 'draft', saveAsActiveVersion: true})" key="save-primary">
                            Save as Active Version
                        </button>
                    </button-row>
                </div>
            </template>
        </div>
    </template>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import jsyaml from 'js-yaml'
import ButtonRow from '@/components/ButtonRow'

export default {
    props: ['form'],
    emits: ['updated', 'close'],
    mixins: [FormMixin, ModalMixin, ],
    components: {ButtonRow, },
    watch: {
        form() {
            this.reset()
        },
        'formData.form_type'(n, o) {
            if (o != n) {
                this.formData.city = ''
                this.formData.state = ''
            }
        },
    },
    computed: {
        cityOptions() {
            const opts = []
            this.cities.forEach(c => {
                opts.push({text: `${c.name}, ${c.state.name}`, value: c.id})
            })
            return opts
        },
        stateOptions() {
            const opts = []
            this.states.forEach(s => {
                opts.push({text: s.name, value: s.id})
            })
            return opts
        },
        typeOptions() {
            const opts = []
            this.formTypes.forEach(x => {
                opts.push({text: x[1], value: x[0]})
            })
            return opts
        },
        typeAsText() {
            let formType = (this.typeOptions.find(type => type.value == this.formData.form_type))
            if (formType) {
                return formType.text || ''
            }
            return ''
        }
    },
    data() {
        return {
            mode: 'form-info',
            versionTab: 'active',
            uploads: {
                active: {},
                draft: {},
            },
            formData: this.makeFormData(),
            cities: [],
            states: [],
            formTypes: [],
            pdfThemeOptions: [
                {'text': 'DEFAULT', 'value': 'default'},
                {'text': 'LARGE FONT', 'value': 'large-font'},
            ],
        }
    },
    mounted() {
        this.getCities()
        this.getStates()
        this.getFormTypes()
    },
    methods: {
        reset() {
            this.mode = 'form-info'
            this.versionTab = 'active'
            FormMixin.methods.reset.call(this)
            this.uploads = {
                active: {},
                draft: {},
            }
        },
        setMode(m) {
            this.mode = m
        },
        makeFormData() {
            if (this.form) {
                const data = {
                    name: this.form.name,
                    slug: this.form.slug,
                    form_type: this.form.form_type,
                    city: this.form.city ? this.form.city.id : null,
                    state: this.form.state ? this.form.state.id : null,
                    is_live: this.form.is_live,
                    pdf_theme: this.form.pdf_theme,
                    versions: {
                        active: {},
                        draft: {},
                    },
                }
                if (this.form.form_versions) {
                    this.form.versions = {
                        active: this.form.form_versions.find(version => version.is_active_version),
                        draft: this.form.form_versions.find(version => version.is_draft)
                    }
                    data.versions.active = {
                        yaml_form: this.form.id,
                        version_number: this.form.versions.active.version_number,
                        yaml: this.form.versions.active.yaml,
                        pdf_file: null,
                        instructions_pdf_file: null,
                        is_draft: false,
                    }
                    data.versions.draft = {
                        yaml_form: this.form.id,
                        version_number: this.form.versions.draft ? this.form.versions.draft.version_number : (this.form.versions.active.version_number + 1),
                        yaml: this.form.versions.draft ? this.form.versions.draft.yaml : this.form.versions.active.yaml,
                        pdf_file: null,
                        instructions_pdf_file: null,
                        is_draft: true,
                    }
                }
                return data
            } else {
                const data = {
                    versions: {
                        active: {
                            version_number: 1,
                            yaml: "",
                            pdf_file: null,
                            instructions_pdf_file: null,
                            is_draft: false
                        },
                    },
                }
                return data
            }
        },
        onUpload(f, version, field) {
            if (!f[0]) {
                return
            }

            this.uploads[version][field] = f[0]
            return
        },
        submitUploads(version) {
            const uploadPromises = []
            Object.keys(this.uploads[version]).forEach(field => {
                uploadPromises.push(api.upload(`/admin/pdf-forms/file-upload`, this.uploads[version][field], {fieldName: 'file'}).then(resp => {
                    this.formData.versions[version][field] = {
                        url: resp.url,
                        filename: resp.filename,
                    }

                    return resp
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    bus.showError(errors.__all__)
                }))
            })
            return Promise.all(uploadPromises)
        },
        onSubmitFormInfo() {
            this.errors = []
            this.formErrors = []

            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')

            let promise

            if (this.form) {
                promise = api.put(`/admin/pdf-forms/forms/${this.form.id}`, this.getFormData())
            } else {
                promise = api.post(`/admin/pdf-forms/forms/validate-form`, this.getFormData())
            }

            promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                if (this.form) {
                    this.$emit('updated', resp)
                    this.close()
                } else {
                    this.mode = 'form-version'
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })

            return
        },
        onSubmitFormAdd() {
            this.errors = []
            this.formErrors = []
            const version = 'active'

            if (!this.validate()) {
                return
            }

            this.validateYAML()
            if (this.errors.yaml) {
                return false
            }

            this.$store.dispatch('START_LOADING')

            this.submitUploads(version).then(() => {
                api.post(`/admin/pdf-forms/forms`, this.getFormData()).then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', resp)
                    this.close()
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })
            })
        },
        onSubmitFormVersion({version, saveAsActiveVersion}) {
            let confirmMessage = ""
            if (version == 'draft' && saveAsActiveVersion) {
                confirmMessage = "Are you sure you want to save this draft as the active version of this form?"
            } else if (version == 'active') {
                confirmMessage = "Are you sure you want to modify the currently active version of this form?"
            }
            if (confirmMessage) {
                if (!confirm(confirmMessage)) { return }
            }

            this.errors = []
            this.formErrors = []

            if (!this.validate()) {
                return
            }

            this.validateYAML()
            if (this.errors.yaml) {
                return false
            }

            this.$store.dispatch('START_LOADING')

            this.submitUploads(version).then(() => {
                this.formData.versions[version]['save_as_active_version'] = saveAsActiveVersion

                let promise

                if (this.form && this.form.versions[version]) {
                    promise = api.put(`/admin/pdf-forms/forms/${this.form.id}/versions/${this.form.versions[version].id}`, this.getFormData().versions[version])
                } else {
                    promise = api.post(`/admin/pdf-forms/forms/${this.form.id}/versions`, this.getFormData().versions[version])
                }

                promise.then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', resp)
                    this.close()
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })
            })
        },
        back() {
            this.errors = []
            this.formErrors = []
            if (this.mode == 'form-info') {
                this.close()
            } else if (this.mode == 'form-version') {
                this.mode = 'form-info'
            }

        },
        getCities() {
            api.get(`/admin/tax-engine/cities`).then(resp => {
                this.cities = resp
            }).catch((errors) => {
                bus.showError(errors.__all__[0])
            })
        },
        getFormTypes() {
            api.get(`/admin/pdf-forms/forms/types`).then(resp => {
                this.formTypes = resp
            }).catch((errors) => {
                bus.showError(errors.__all__[0])
            })
        },
        getStates() {
            api.get(`/admin/tax-engine/states`).then(resp => {
                this.states = resp
            }).catch((errors) => {
                bus.showError(errors.__all__[0])
            })
        },
        validateYAML() {
            const findQuestions = (tree) => {
                let questions = []
                tree.forEach(q => {
                    questions.push(q)

                    // Add dependent questions
                    questions = questions.concat(findQuestions(q.questions || []))

                    ;(q.options || []).forEach(o => {
                        questions = questions.concat(findQuestions(o.questions || []))
                    })
                })

                return questions
            }

            const KNOWN_TYPES = ['select-one', 'select-many', 'date', 'signature', 'text', 'ssn', 'checkbox', 'currency', 'positive-currency', 'integer', 'positive-integer', 'math', 'calc', 'constant', 'info', 'email']

            delete this.errors.yaml
            try {
                const survey = jsyaml.safeLoad(this.formData.versions[this.versionTab].yaml)

                if (!survey) {
                    this.errors.yaml = ['YAML seems to be blank.']
                    return
                }

                if (!survey.title) {
                    this.errors.yaml = ['There is no title!']
                    return
                }

                if (!survey.instructions) {
                    this.errors.yaml = ['There is no instructions text.']
                    return
                }

                if (!(survey.questions instanceof Array) || survey.questions.length < 1) {
                    this.errors.yaml = ['The questions list is missing, empty, or malformed.']
                    return
                }

                const questions = findQuestions(survey.questions)
                const slugs = []

                for (let i = 0; i < questions.length; i++) {
                    let q = questions[i]
                    let plainQ = Object.assign({}, q)

                    delete plainQ.questions
                    delete plainQ.options

                    if (!(q.slug || '').trim()) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` is missing the `slug` attribute.']
                        return
                    }

                    if (!(q.slug + '').match(/^[a-zA-Z][a-zA-Z0-9_]*$/)) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a slug of invalid format. Slugs can only contain characters a-z, A-Z, 0-9, and underscore. They cannot start with a number or an underscore.']
                        return
                    }

                    if (q.slug.indexOf('__') >= 0) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a slug of invalid format. Slugs must not contain more than one underscore in a row.']
                        return
                    }

                    if (slugs.indexOf(q.slug) >= 0) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a slug that is already used by another question.']
                        return
                    }

                    if (!q.type) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` is missing the `type` attribute.']
                        return
                    }

                    if (!q.text && !(q.template || q.source || q.type == 'constant' || q.type == 'signature' || q.type == 'math' || q.type == 'calc')) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` is missing the `text` attribute.']
                        return
                    }

                    if (KNOWN_TYPES.indexOf(q.type) < 0) {
                        this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a type that is not supported. Supported types are: ' + KNOWN_TYPES.join(', ')]
                        return
                    }

                    if ((q.type == 'select-one') || (q.type == 'select-many')) {
                        if (!(q.options || q.preset_options)) {
                            this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` is missing the `options` or `preset_options` attribute.']
                            return
                        }

                        if (q.options && ((!(q.options instanceof Array)) || q.options.length < 1)) {
                            this.errors.yaml = ['Question `' + JSON.stringify(plainQ) + '` has a malformed `options` attribute.']
                            return
                        }

                        if (q.options) {
                            const optionSlugs = []
                            for (let j = 0; j < q.options.length; j++) {
                                const o = q.options[j]
                                const plainO = Object.assign({}, o)
                                delete plainO.questions

                                if (!(o.slug || '').trim()) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` is missing the `slug` attribute.']
                                    return
                                }

                                if (!(o.slug + '').match(/^[a-zA-Z][a-zA-Z0-9_]*$/)) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` has a slug of invalid format. Slugs can only contain characters a-z, A-Z, 0-9, and underscore. They cannot start with a number or an underscore.']
                                    return
                                }

                                if (o.slug.indexOf('__') >= 0) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` has a slug of invalid format. Slugs must not contain more than one underscore in a row.']
                                    return
                                }

                                if (optionSlugs.indexOf(o.slug) >= 0) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` has a slug that is already used by another option in the same question.']
                                    return
                                }

                                if (!o.text) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` is missing the `text` attribute.']
                                    return
                                }

                                if (o.hasOwnProperty('questions') && (!(o.questions instanceof Array) || o.questions.length < 1)) {
                                    this.errors.yaml = ['Option `' + JSON.stringify(plainO) + '` of question `' + JSON.stringify(plainQ) + '` has a malformed or empty `questions` attribute.']
                                    return
                                }

                                optionSlugs.push(o.slug)
                            }
                        }
                    }

                    slugs.push(q.slug)
                }
            }
            catch (ex) {
                this.errors.yaml = ['YAML error: ' + ex.message]
            }

        }
    }
}
</script>

<style lang="scss">
.mui-textfield textarea.yaml-input {
    font-family: monospace;
    padding: 0.5rem;
    border: 1px solid #eee;
}
</style>
