import Hashids from 'hashids'

const hashids = new Hashids(undefined, 6)

export default {
    encode(id) {
        return hashids.encode(id)
    },
    decode(hashedId) {
        return hashids.decode(hashedId)[0]
    }
}