<template>
<div class="card mb-2" v-if="loaded">
    <div class="card-header pr-1 d-flex align-items-center">
        <span class="btnlike mr-auto"><h4 class="mb-0">Login History</h4></span>
    </div>

    <div class="card-body py-1">

        <div v-if="!loginSessions.length > 0">
            <p class="py-1 m-0 text-muted">
                <em>No logins recorded.</em>
            </p>
        </div>
        <div v-if="loginSessions.length > 0">
            <ul class="list-unstyled m-0">
                <li class="d-none d-sm-block">
                    <div class="form-row align-items-baseline py-1">
                        <div class="col-5">
                            <h5 class="theading m-0">Date & Time</h5>
                        </div>
                        <div class="col-3">
                            <h5 class="theading m-0">Duration</h5>
                        </div>
                        <div class="col-4">
                            <h5 class="theading m-0">IP Address</h5>
                        </div>
                    </div>
                </li>

                <li class="boxed-li-bottom" v-for="loginSession in recentLoginSessions" :key="loginSession.id">
                    <div class="form-row align-items-baseline py-3q">
                        <div class="col-12 col-sm-5 pr-0">
                            <span>
                                {{ $filters.datetimeshort(loginSession.dt_start) }}
                            </span>
                        </div>
                        <div class="col-12 col-sm-3">
                            <span v-if="loginSession.dt_end">{{ $filters.durationmins(loginSession.dt_start, loginSession.dt_end) }}</span>
                            <span v-if="!loginSession.dt_end">Ongoing</span>
                        </div>
                        <div class="col-12 col-sm-4">
                            <span>{{ loginSession.ip_address }} </span>
                        </div>
                    </div>
                </li>
            </ul>

            <div v-if="!showAll && loginSessions.length > 5">
                <button type="button" class="btn btn-link btn-block" @click.prevent="showAll = true">See all</button>
            </div>
            <div v-if="showAll && loginSessions.length > 5">
                <button type="button" class="btn btn-link btn-block" @click.prevent="showAll = false">See fewer</button>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    props: ['loginSessions'],
    computed: {
        sortedLoginSessions() {
            if (!this.loaded) {return []}

            const result = this.loginSessions.filter(x => x.dt_start)
            result.sort((a, b) => {
                return b.dt_start > a.dt_start ? 1 : -1
            })

            return result
        },
        recentLoginSessions() {
            return this.loginSessions

            if (this.showAll) {
                return this.sortedLoginSessions
            }

            return this.sortedLoginSessions.slice(0, 5)
        },
        loaded() {
            return Array.isArray(this.loginSessions)
        },
    },
    data() {
        return {
            showAll: false,
        }
    },
}
</script>
