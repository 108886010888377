<template>
    <div>
        <table-page
            :client="client"
            :warnings="ourWarnings"
            :changelogs="ourChangelogs"
            @changelog_date_filter_change="onChangelogDateRangeChange"
            @warnings_date_filter_change="onWarningsDateRangeChange"
            @warnings_updated="getWarnings"
            @changelog_click="onChangelogClick"
            @warning_click="onWarningClick"
            :disable-changelog-sidebar="true"
            :disable-warning-sidebar="true">

            <template v-slot:alert>
                <div class="alert-banner"
                    v-if="client.has_enhanced_ess && companiesMissingEESS.length"
                >
                    {{ companiesWithEESS.length }} of {{ client.companies.length }} companies have Enhanced ESS enabled. <a href class="btn-link" @click.prevent="showEnhancedESSInfoModal">Show Details</a>
                </div>
            </template>

            <template v-if="enhancedESSUpgradeAvailable">
                <portal to="upgrade-link">
                    <router-link target="_blank" :to="enhancedESSUpgradeRoute" @click="trackUpgradeClick">
                        <i class="fas fa-fw fa-sparkles mr-0"></i>
                        Upgrade
                    </router-link>
                </portal>
            </template>

            <fast-table
                :recordLabel="recordLabel"
                :sections="sections"
                :headers="headers"
                :data="tableData"
                :managers="client.company_managers"
                @click="onClick"
                @click_cell="onClick($event.object, $event.columnIdx)"
                :use-date-filters="true"
                @date_filter_change="onDateFilter"
                :useFiltersFromUrl="dataLoaded"
            />

        </table-page>

        <sidebar
            v-if="currentUser"
            :user="currentUser"
            :client="client"
            @close="currentUserKey = null"
            @updated="onUpdate"
            :key="currentUserKey"
            :tab="tab"
            :itemId="{'main': null, 'warnings': activeWarningId, 'changelogs': activeChangelogId}[this.tab]"
        />

        <EnhancedESSInfoModal v-if="showingEnhancedESSInfoModal" @close="showingEnhancedESSInfoModal = false" :client="client"></EnhancedESSInfoModal>
    </div>
</template>

<script>
import Sidebar from './components/Sidebar'
import FastTable from '@/components/FastTable'
import TablePageMixin from '@/mixins/TablePageMixin'
import EnhancedESSInfoModal from './components/EnhancedESSInfoModal.vue'

export default {
    props: ['client'],
    components: {FastTable, Sidebar, EnhancedESSInfoModal, },
    mixins: [TablePageMixin],
    computed: {
        activeUsers() {
            return this.users.filter(u => u.is_active)
        },
        inactiveUsers() {
            return this.users.filter(u => !u.is_active)
        },
        currentUser() {
            return this.users.find(u => u.key == this.currentUserKey)
        },
        ourWarnings() {
            // This is a filtered list of warnings. It is meant to ensure that the Warnings table on this page
            // only shows warnings about the users in the Main tab

            let result = []
            this.users.forEach(u => {
                let item = this.findItemsForUser(u, this.warnings)
                result = result.concat(item)
            })
            return result
        },
        ourChangelogs() {
            // This is a filtered list of changelogs. It is meant to ensure that the History table on this page
            // only shows changelogs about the users in the Main tab

            let result = []
            this.users.forEach(u => {
                let item = this.findItemsForUser(u, this.changelogs)
                result = result.concat(item)
            })
            return result
        },
        tableData() {
            const tableData = []

            const subsets = [
                this.activeUsers,
                this.inactiveUsers,
            ]

            subsets.forEach(users => {
                const sectionData = []

                users.forEach(u => {
                    const companyIds = new Set()

                    u.employees.forEach(e => {companyIds.add(e.company_id)})
                    u.onboarding_applications.forEach(o => {companyIds.add(o.company_id)})
                    const companyCount = companyIds.size

                    let ssnLast4 = ''
                    if (u.employees.length) {
                        ssnLast4 = u.employees[0].ssn_last_4 ? u.employees[0].ssn_last_4 : ''
                    }
                    if (u.onboarding_applications.length) {
                        ssnLast4 = u.onboarding_applications[0].ssn_last_4 ? u.onboarding_applications[0].ssn_last_4 : ssnLast4
                    }

                    let fullName = u.full_name_sortable
                    if (!fullName) {
                        if (u.employees.length) {
                            fullName = u.employees[0].full_name_sortable ? u.employees[0].full_name_sortable : ''
                        }
                        if (u.onboarding_applications.length) {
                            fullName = u.onboarding_applications[0].full_name_sortable ? u.onboarding_applications[0].full_name_sortable : fullName
                        }
                    }

                    const lockedCompanyIds = new Set()
                    const unlinkedCompanyIds = new Set()
                    let last_pay_date = ''
                    let subrows = []

                    u.employees.forEach(e => {
                        if (e.is_locked) {
                            lockedCompanyIds.add(e.company_id)
                        }
                        if (! e.user_id) {
                            unlinkedCompanyIds.add(e.company_id)
                        }
                        if (e.last_pay_date && e.last_pay_date > last_pay_date) {
                            last_pay_date = e.last_pay_date
                        }
                    })

                    u.onboarding_applications.forEach(o => {
                        if (o.is_locked) {
                            lockedCompanyIds.add(o.company_id)
                        }
                        // NB: this is OK - if someone just hasn't started Onboarding, we don't consider that a problem
                        // if (! o.user) {
                        //     unlinkedCompanyIds.add(o.company_id)
                        // }
                    })

                    // Locked > Unlinked > OK (if Locked, takes precedence over Unlinked)
                    let status = 'OK'
                    if (! u.has_user) {
                        status = 'Not Registered'
                    } else {
                        if (unlinkedCompanyIds.size > 0) {
                            if (unlinkedCompanyIds.size > 1) {
                                status = unlinkedCompanyIds.size + ' Unlinked Companies'
                            } else {
                                status = unlinkedCompanyIds.size + ' Unlinked Company'
                            }
                        }
                        if (lockedCompanyIds.size > 0) {
                            if (lockedCompanyIds.size > 1) {
                                status = lockedCompanyIds.size + ' Locked Companies'
                            } else {
                                status = lockedCompanyIds.size + ' Locked Company'
                            }
                        }

                        if (u.digital_consent_revoked_on) {
                            status = 'Withdrew Consent'
                        }
                    }

                    // figure out the subrows; only if they have > 1 company, EE or OA
                    let companyRows = []

                    u.employees.forEach(e => {
                        let companyStatus = 'Not Linked'
                        if (e.user_id) {
                            if (e.is_locked) {
                                companyStatus = 'Locked'
                            } else {
                                companyStatus = 'OK'
                            }
                        }

                        // this overrides everything
                        if (u.digital_consent_revoked_on) {
                            companyStatus = 'Withdrew Consent'
                        }
                        if (! u.has_user) {
                            companyStatus = 'Not Registered'
                        }

                        companyRows.push([
                            '',
                            '',
                            e.company.name,
                            companyStatus,
                            '',
                            '',
                            '',
                            ''
                        ])
                    })

                    u.onboarding_applications.forEach(o => {
                        let companyStatus = 'Not Registered'
                        if (o.user_id) {
                            if (o.is_locked) {
                                companyStatus = 'Locked'
                            } else {
                                companyStatus = 'OK'
                            }
                        }

                        // this overrides everything
                        if (u.digital_consent_revoked_on) {
                            companyStatus = 'Withdrew Consent'
                        }
                        if (! u.has_user) {
                            companyStatus = 'Not Registered'
                        }

                        companyRows.push([
                            '',
                            '',
                            o.company.name,
                            companyStatus,
                            '',
                            '',
                            '',
                            ''
                        ])
                    })

                    subrows = companyRows.slice(1)
                    let firstCompanyName = companyRows[0][2]
                    let firstCompanyStatus = companyRows[0][3]


                    const row = {
                        id: u.key,
                        isActive: u.key == this.currentUserKey, // use this rather than activeRowId because it's key, not id
                        object: u,
                        cells: [
                            fullName,
                            this.getWarningCount(u),
                            firstCompanyName,
                            firstCompanyStatus,
                            u.last_login ? u.last_login : '',
                            u.email,
                            ssnLast4 ? 'XXX-XX-' + ssnLast4 : '',
                            last_pay_date
                        ],
                        subrows: subrows
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
        enhancedESSUpgradeAvailable() {
            if (!(this.$store.state.system_flags && this.$store.state.system_flags['enhanced_ess_released'])) {
                return false
            }
            if (!this.client.has_enhanced_ess) {
                return true
            }
            return false
        },
        enhancedESSUpgradeRoute() {
            return {name: 'client-features-enhanced-ess', params: {client: this.client.id}}
        },
        companiesWithEESS() {
            return this.client.companies.filter(c => c.has_enhanced_ess)
        },
        companiesMissingEESS() {
            return this.client.companies.filter(c => !c.has_enhanced_ess)
        },
    },
    data() {
        return {
            users: [],
            dataLoaded: false,
            currentUserKey: null,

            recordLabel: "employees",
            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true, id: 'employee-name'},
                {label: 'Warnings', classes: 'cw-warning', isFilterable: true, isSearchable: false, type: 'warning', isClickable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Account Status', classes: 'cw-9', isFilterable: true, isSearchable: true, filterValueEditor: 'unlinked-companies'},
                {label: 'Last Login', classes: 'cw-date', isSearchable: true, type: 'date'},
                {label: 'Email', classes: 'cw-email', isSearchable: true},
                {label: 'SSN', classes: 'cw-ssn', isSearchable: true},
                {label: 'Last Paid', classes: 'cw-date', isSearchable: false, type: 'date'},
            ],
            sections: [
                {title: 'Active Employees', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Employees', defaultIsClosed: true, id: 'inactive', hasDateFilter: true},
            ],

            tab: 'main',
            activeWarningId: null,
            activeChangelogId: null,
            showingEnhancedESSInfoModal: false,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Self-Service Access')
        this.getUsers()
        this.getWarnings()
        this.getChangelogs()
        this.getMainWarnings()
        this.getMainChangelogs()

        this.$bus.$on('client_data_updated', updates => {
            if ((updates.indexOf('onboarding_applications') >= 0) || (updates.indexOf('employees') >= 0)) {
                this.getUsers(true)
                this.getWarnings()
                this.getChangelogs()
            }
        })
    },
    methods: {
        filterRelevantWarnings(w) {
            if (w.employee_id || w.onboarding_application_id) {
                if (['bounced-email', 'consent-withdrawn', 'pin-reset'].indexOf(w.warning_type) >= 0) {
                    return true
                }
            }
            return false
        },
        filterRelevantChangelog(c) {
            if (!(c.onboarding_application_id || c.employee_id)) {return false}
            if ([
                'email',
                'status'
            ].includes(c.tracked_object_slug)) {return true}

            return false
        },
        getUsers(silent) {
            if (!silent) {
                this.$store.dispatch('START_LOADING')
            }

            this.getMainWarnings().then(() => {
                this.updateUserWarningsAndChangelogs()
            })
            this.getMainChangelogs().then(() => {
                this.updateUserWarningsAndChangelogs()
            })

            let url = `/clients/${this.client.id}/non-sec-users`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                this.users = resp
                this.updateUserWarningsAndChangelogs()

                if (!silent) {
                    this.dataLoaded = true
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(user) {
            const userToUpdate = this.users.find(u => u.key == user.key)
            this.currentUserKey = user.key
            if (userToUpdate) {
                Object.assign(userToUpdate, user)
            }
            this.getUsers(false)
        },
        findItemsForUser(u, items) {
            const employeeIds = new Set()
            const onboardingApplicationIds = new Set()

            u.employees.forEach(e => {
                employeeIds.add(e.id)
            })

            u.onboarding_applications.forEach(o => {
                onboardingApplicationIds.add(o.id)
            })

            let result = []
            if (items) {
                result = result.concat(items.filter(x => {
                    if (x.employee_id) {
                        return employeeIds.has(x.employee_id)
                    }
                    else if (x.onboarding_application_id) {
                        return onboardingApplicationIds.has(x.onboarding_application_id)
                    }
                }))
            }

            return result
        },
        findChangelogsForUser(u) {
            return this.findItemsForUser(u, this.mainChangelogs)
        },
        findWarningsForUser(u) {
            return this.findItemsForUser(u, this.mainWarnings)
        },
        getWarningCount(u) {
            let warnings = this.findWarningsForUser(u)
            warnings = warnings.filter(w => !(w.is_resolved))
            return warnings.length
        },
        updateUserWarningsAndChangelogs() {
            // This method combines this.users with this.warnings and this.changelogs so that all
            // those values are accessible from each user object
            if (this.users && this.warnings) {
                this.users.forEach(u => {
                    u.warnings = this.findWarningsForUser(u)
                })
            }

            if (this.users && this.mainChangelogs.length) {
                this.users.forEach(u => {
                    u.changelogs = this.findChangelogsForUser(u)
                })
            }
        },
        onClick(u, columnIdx) {
            this.activeWarningId = null
            this.activeChangelogId = null
            this.tab = 'main'

            if (columnIdx == 1 && this.getWarningCount(u)) {
                this.tab = 'warnings'
            }

            this.currentUserKey = u.key
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getUsers()
        },
        onChangelogClick(changelog) {
            this.activeWarningId = null
            this.activeChangelogId = null
            this.currentUserKey = null
            this.tab = 'main'

            const u = this.users.find(u => {
                if (changelog.employee) {
                    if (u.employees.find(e => e.id == changelog.employee.id)) {
                        return true
                    }
                }
                else if (changelog.onboarding_application) {
                    if (u.onboarding_applications.find(e => e.id == changelog.onboarding_application.id)) {
                        return true
                    }
                }
                return false
            })

            if (u) {
                this.tab = 'changelogs'
                this.activeChangelogId = changelog.id
                this.currentUserKey = u.key
            }
        },
        onWarningClick(warning) {
            this.activeWarningId = null
            this.activeChangelogId = null
            this.currentUserKey = null
            this.tab = 'main'

            const u = this.users.find(u => {
                if (warning.employee) {
                    if (u.employees.find(e => e.id == warning.employee.id)) {
                        return true
                    }
                }
                else if (warning.onboarding_application) {
                    if (u.onboarding_applications.find(e => e.id == warning.onboarding_application.id)) {
                        return true
                    }
                }
                return false
            })

            if (u) {
                this.tab = 'warnings'
                this.activeWarningId = warning.id
                this.currentUserKey = u.key
            }
        },
        showEnhancedESSInfoModal() {
            this.showingEnhancedESSInfoModal = true
        },
        trackUpgradeClick() {
            this.$bus.trackEvent('Enhanced ESS: Self-Service Access Upgrade Click', {
                'client_id': this.client.id,
                'client_business_type_rollup_primary': this.client.business_type_rollup_primary,
                'client_business_type_rollup_secondary': this.client.business_type_rollup_secondary,
                'client_business_type_rollup_brand': this.client.business_type_rollup_brand,
            })
        }
    },
}
</script>

