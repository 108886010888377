<template>
    <modal @close="$emit('close')" ref="modal" :is-closeable="isCloseable">
        <template v-slot:title>Bulk Add Employees</template>

        <slot>
            <p>
                <!-- Some notes about file requirements etc... -->
            </p>

            <form-errors :errorsHtml="formErrors"/>

            <div class="form-row">
                <div class="col">
                    <form-input label="Company" v-model="formData.company" :errors="errors.company" type="select" :options="companyOptions"/>
                </div>
            </div>

            <div v-if="formData.company" class="form-row pb-3">
                <div class="col">
                    <!-- <a type="button" class="btn btn-block btn-outline-primary" :href="downloadTemplateUrl()">Download Template</a> -->
                    <a type="button" :href="downloadTemplateUrl()">
                        <i class="fas fa-file-excel mr-hf"></i>
                        Download Template
                    </a>
                </div>
            </div>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input type="file" v-model="formData.pdf_file" :errors="errors.file" label="Upload spreadsheet:" helper-text="File extension must be .xlsx" accept=".xlsx" @upload="onUpload($event)" />
                </div>
            </div>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close(true)">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit">Add Employees</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['client'],
    computed: {
        companies() {
            return this.client.companies || []
        },
        companyOptions() {
            const options = []
            for (let company of this.companies) {
                if (company.has_paperless_onboarding) {
                    options.push({text: company.name, value: company.id})
                }
            }
            return options
        },
        selectedCompany() {
            return this.companies.find((c) => {return c.id == this.formData.company})
        },
    },
    data() {
        return {
            formData: {
                file: null,
                company: '',
            },
            formRules: {
                file: {presence: {allowEmpty: false}, },
            },
        }
    },
    methods: {
        downloadTemplateUrl() {
            return `${this.$api.API_BASE}/clients/${this.client.id}/companies/${this.formData.company}/onboarding-applications/bulk-upload/template`
        },
        onUpload(f) {
            this.formData.file = f[0]
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            this.errors = {}
            this.formErrors = []

            this.$store.dispatch('START_LOADING')
            let url = `/clients/${this.client.id}/onboarding-applications/bulk-upload`
            api.upload(url, this.formData.file, {fieldName: 'file', formData: this.formData}).then((resp) => {
                // poll every 2 seconds to see the task status
                this.pollInterval = setInterval(() => {this.pollTask(resp.task_id)}, 2000)
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        pollTask(taskId) {
            let url = `/clients/${this.client.id}/onboarding-applications/bulk-upload/progress?task_id=${encodeURIComponent(taskId)}`
            api.get(url).then((resp) => {
                if (resp['status'] == 'SUCCESS') {
                    clearInterval(this.pollInterval)
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showInfo(`Successfully added ${resp.num_added} ${resp.num_added > 1 ? 'employees': 'employee'}`)
                    this.$emit('payroll_onboarding_applications_updated')
                    this.close()
                } else if (resp['status'] == 'FAILURE') {
                    clearInterval(this.pollInterval)
                    this.formErrors = resp.errors
                    this.$store.dispatch('STOP_LOADING')
                } else {
                    // do nothing, keep polling
                }
            })
        },
    }
}
</script>
