<template>
    <modal @close="$emit('close')" ref="modal">
        <template v-slot:title>Change Password</template>

        <slot>
            <form @submit.prevent="onSubmit" novalidate class="mui-form">
                <form-errors :errors="formErrors"/>

                <p>Set a new password for your account.</p>

                <div class="form-row">
                    <div class="col">
                        <form-input placeholder="Enter current password" v-model="formData.old_password" :errors="errors.old_password" type="password" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input placeholder="Enter new password" v-model="formData.new_password" :errors="errors.new_password" type="password" />
                    </div>
                </div>

                <button-row>
                    <button type="button" class="btn btn-outline-primary" @click.prevent="close">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">
                        Continue
                    </button>
                </button-row>

            </form>
        </slot>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'

export default {
    mixins: [ModalMixin, FormMixin],
    props: ['employee', 'user'],
    data() {
        return {
            formRules: {
                old_password: {
                    presence: {allowEmpty: false},
                },
                new_password: {
                    presence: {allowEmpty: false},
                    length: {
                        minimum: 8,
                        maximum: 128,
                    }
                },
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            return {
                old_password: '',
                new_password: '',
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post('/me/change-password', this.formData).then((data) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showInfo("Password has been updated.")
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
    },
}
</script>
