<template>
    <div>
        <div class="block block-body">
            <section class="mb-2 border-bottom">
                <w2-detail :tax_form="w2" v-if="w2" />
            </section>
        </div>
    </div>
</template>

<script>
import W2Detail from '@/components/W2Detail'

export default {
    components: {W2Detail, },
    props: ['w2', ],
}
</script>
