<template>
    <div class="row">
        <div class="col-12">
            <div :class="[{'container-centered-l': currentPDF}, {'container-centered-m': !currentPDF}]" class="py-2">

                <div v-show="!activeForm && !currentPDF">
                    <p class="mt-2">Complete the document{{forms.length > 1 ? "s": ""}} below to finish editing your taxes.</p>

                    <div class="card doc-item mb-2" v-for="form in forms" :key="form.id">
                        <div class="card-body">
                            <div class="todo-item" :class="{'todo-item-completed': form.filled_on}">
                                <div class="todo-item-content">
                                    <h6 class="doc-title mb-2">{{ yamlTitle(form.yaml) }}</h6>

                                    <div class="doc-action-primary mb-1">
                                        <a href class="btn btn-outline-primary" target="_blank" @click.prevent="setActiveForm(form)" v-if="!form.filled_on">
                                            <i class="far fa-fw fa-pencil mr-1"></i>Fill Out Document
                                        </a>

                                        <a href v-if="form.filled_on" class="btn btn-outline-success" @click.prevent="previewPDFForm(form)">
                                            <i class="far fa-fw fa-check mr-1"></i>View Signed Document
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="activeForm && !currentPDF" class="mb-4">
                    <survey
                        :yaml="activeForm.yaml"
                        :knowndata="knownData"
                        @error="onFormSurveyError"
                        ref="activeFormSurvey"
                        :hidesubmit="true"
                        :pdf-url="activeForm.instructions_pdf_file_url || activeForm.pdf_file_url"
                    />
                </div>

                <div v-if="currentPDF">
                    <pdf-inline
                        :url="currentPDF.url"
                        :title="currentPDF.title"
                        :key="currentPDF.url.url"
                        ref="currentPDF"
                    />
                </div>

                <form-errors :errors="formErrors" />

                <portal to="tax-withholding-edit-modal-footer">
                    <button-row stacked>
                        <button type="button" @click.prevent="onPrev()" class="btn btn-outline-primary">{{ prevButtonLabel }}</button>
                        <button type="button" @click.prevent="onSubmit()" class="btn btn-primary">{{ submitButtonLabel }}</button>
                    </button-row>
                </portal>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import Survey from '@/components/PDFForm/Survey'
import PdfInline from '@/components/PdfInline'
import ButtonRow from '@/components/ButtonRow'
import api from '@/api'
import YAML from 'js-yaml'
import moment from 'moment'

export default {
    mixins: [FormMixin],
    components: {Survey, PdfInline, ButtonRow},
    props: ['employee', 'employeeUpdate', 'taxWithholding', 'prev'],
    watch: {
        employee() {
            this.reset()
        },
        employeeUpdate() {
            this.reset()
        }
    },
    computed: {
        prevButtonLabel() {
            if (this.currentPDF && !this.viewingSignedDocument) {
                return 'Edit Form'
            } else {
                return 'Back'
            }
        },
        submitButtonLabel() {
            if (this.currentPDF) {
                return 'Sign'
            } else if (this.activeForm) {
                return 'Continue'
            } else {
                return 'Submit'
            }
        },
        forms() {
            const forms = this.employeeUpdate.forms
            forms.sort((a, b) => {
                if (a.priority_order == b.priority_order) {
                    return a.id > b.id ? 1 : -1
                }
                return (a.priority_order > b.priority_order) ? 1 : -1
            })
            return forms
        },
        viewingSignedDocument() {
            return this.currentPDF && this.currentPDF.form.filled_on
        },
        knownData() {
            const eeu = this.employeeUpdate
            const ee = this.employeeUpdate.employee
            return {
                'system': {
                    'date': moment().format('MM/DD/YYYY'),
                    'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
                },
                'employee': {
                    'first_name': ee.first_name,
                    'middle_initial': ee.middle_initial,
                    'last_name': ee.last_name,
                    'full_name': ee.full_name,
                    'email': ee.email,
                    'suffix': ee.suffix,
                    'address_line_1': ee.address_1,
                    'address_line_2': ee.address_2,
                    'city': ee.city,
                    'city_normalized': ee.city_normalized,
                    'state': ee.state,
                    'state_long': ee.state_long,
                    'postcode': ee.postal_code,
                    'phone_number': ee.phone_number,
                    'dob': moment(ee.dob).format('MM/DD/YYYY'),
                    'date_hired': moment(ee.date_hired).format('MM/DD/YYYY'),
                     // TODO do we actually use these 2 fields in form fill? If so, it needs to be on cached on Employee
                    'school_district_id': eeu.school_district_id,
                    'school_district_name': eeu.school_district_name,
                },
                'employer': {
                    'name': eeu.company.name,
                    'fein': eeu.company.EIN,
                    'address_line_1': eeu.company.address_1,
                    'address_line_2': eeu.company.address_2,
                    'city': eeu.company.city,
                    'state': eeu.company.state,
                    'state_long': eeu.company.state_long,
                    'postcode': eeu.company.postal_code,
                    'phone_number': eeu.company.phone_number,
                    // TODO this seems error-prone; risk that we select a different job than the back-end logic?
                    'default_job_city': ee.jobs && ee.jobs.length ? ee.jobs[0].position.work_location.city_normalized : '',
                    'default_job_state': ee.jobs && ee.jobs.length ? ee.jobs[0].position.work_location.state : '',
                },
            }
        },
        numDocuments() {
            return this.employeeUpdate.forms.length
        }
    },
    data() {
        const data = {
            formData: this.makeFormData(),
            activeForm: null,
            currentPDF: null,
        }
        return data
    },
    methods: {
        makeFormData() {
            let formData = {
                action: 'complete-forms',
            }
            return formData
        },
        validate() {
            const basicFormValidation = FormMixin.methods.validate.call(this)

            if (this.forms.filter((f) => !f.filled_on).length) {
                this.formErrors.push("Complete all documents to continue.")
                return false
            }

            return basicFormValidation
        },
        setActiveForm(form) {
            this.activeForm = null
            this.formErrors = []
            this.$nextTick(() => {
                this.activeForm = form
            })
        },
        onFormSurveyError(errors) {
            this.formErrors = []
            if (errors.message) {
                this.formErrors = errors.message
            }
            else if (errors.__all__) {
                this.formErrors = errors.__all__
            }
            else {
                this.formErrors = [errors]
            }
            this.$bus.showError(this.formErrors)
        },
        onFormSurveySubmit() {
            let data
            try {
                data = this.$refs.activeFormSurvey.getFormValues()
                delete data['signature']
                data.__yaml_form_id__ = this.activeForm.yaml_form_id
                data.__form_designation__ = this.activeForm.form_designation
                data.__employee_update_pdf_form_id__ = this.activeForm.id
            }
            catch (ex) {
                this.onFormSurveyError(ex)
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/me/ess/updates/${this.employeeUpdate.id}/forms/${this.activeForm.id}`, {'form_data': data}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.previewPDFForm(this.activeForm, data)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        previewPDFForm(form, formData) {
            this.currentPDF = {
                title: this.parseYAML(form.yaml).title,
                url: {
                    url: `${this.$api.API_BASE}/me/ess/updates/${this.employeeUpdate.id}/forms/${form.id}/pdf`,
                    withCredentials: true,
                },
                'form': form,
                'formData': formData,
            }
        },
        onFormSign() {
            if (this.currentPDF.form) {
                const formData = {
                    form_data: Object.assign({}, this.currentPDF.formData),
                    signatures: {
                        'signature': {
                            'first_name': this.knownData.employee.first_name,
                            'last_name': this.knownData.employee.last_name,
                            'full_name': this.knownData.employee.full_name,
                            'date': this.knownData.system.date
                        },
                    },
                }

                this.$store.dispatch('START_LOADING')
                this.$api.post(`/me/ess/updates/${this.employeeUpdate.id}/forms/${this.currentPDF.form.id}?sign=1`, formData).then(() => {
                    this.$store.dispatch('STOP_LOADING')
                    this.currentPDF = null
                    this.activeForm = null
                    this.$emit('patch')
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.__all__)
                })
            }
        },
        onPrev() {
            this.formErrors = []
            if (this.currentPDF) {
                this.currentPDF = null
                return
            }
            if (this.activeForm) {
                this.activeForm = null
                return
            }
            this.$emit('prev')
        },
        onSubmit() {
            if (this.currentPDF) {
                this.onFormSign()
                return
            }
            if (this.activeForm) {
                this.onFormSurveySubmit()
                return
            }
            if (!this.validate()) {
                return
            }

            // Forms complete and signed, patching EmployeeUpdate
            this.$store.dispatch('START_LOADING')
            api.patch(`/me/ess/companies/${this.employee.company_id}/updates/${this.employeeUpdate.id}/tax-withholdings/${this.taxWithholding.id}`, this.getFormData()).then((resp) => {
                // poll every 2 seconds to see the task status
                this.pollInterval = setInterval(() => {this.pollEmployeeUpdate()}, 2000)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        pollEmployeeUpdate() {
            api.get(`/me/ess/updates/${this.employeeUpdate.id}/progress`).then((resp) => {
                if (resp['status'] == 'SUCCESS') {
                    clearInterval(this.pollInterval)
                    this.$emit('updated')
                    this.$store.dispatch('STOP_LOADING')
                } else if (resp['status'] == 'FAILURE') {
                    clearInterval(this.pollInterval)
                    this.$bus.showError(resp['error'])
                    this.$store.dispatch('STOP_LOADING')
                } else {
                    // do nothing, keep polling
                }
            })
        },
        parseYAML(yaml) {
            return YAML.safeLoad(yaml)
        },
        yamlTitle(yaml) {
            return this.parseYAML(yaml).title
        }
    },
}
</script>