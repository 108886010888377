<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">
                        <h2>Diagnostics</h2>
                        <br>

                        <div v-if="apiData">
                            <dl>
                                <dt>User-Agent</dt>
                                <dd class="mb-2">
                                    {{ userAgent }}
                                </dd>

                                <dt>Cookies</dt>
                                <dd class="mb-2">
                                    cookieEnabled={{ cookieEnabled}}
                                    <br>
                                    cookie_keys={{ apiData.cookie_keys }}
                                </dd>

                                <dt>Viewport</dt>
                                <dd>
                                    {{ viewPort.width }} x {{ viewPort.height }}
                                </dd>

                                <dt>IP</dt>
                                <dd>
                                    {{ apiData.REMOTE_ADDR }} / {{ apiData.client_ip }}
                                </dd>

                                <dt>Time</dt>
                                <dd>
                                    <span :class="{'text-danger': timeMismatch}">{{ currentComputerTime }}</span> (Local)
                                    <br>
                                    {{ apiData.time }} (Server)
                                </dd>
                            </dl>
                        </div>

                        <div v-if="errors.length">
                            {{ errors }}
                        </div>

                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import api from '@/api'
import moment from 'moment'

export default {
    components: {},
    data() {
        return {
            apiData: null,
            errors: []
        }
    },
    computed: {
        userAgent() {
            return window.navigator.userAgent
        },
        cookieEnabled() {
            return window.navigator.cookieEnabled
        },
        viewPort() {
            return {
                'width': window.innerWidth,
                'height': window.innerHeight,
            }
        },
        currentComputerTime() {
            let m = moment.utc().toISOString()
            return m.split(".")[0] + "Z"
        },
        timeMismatch() {
            if (! this.apiData) {
                return false
            }

            return this.apiData.time != this.currentComputerTime
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.$store.dispatch('START_LOADING')
            return api.get(`/debug/diagnostics/set-cookie`).then((outer_resp) => {
                return api.get(`/debug/diagnostics`).then((resp) => {
                    this.apiData = resp
                    return this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.errors = errors
                    this.$store.dispatch('STOP_LOADING')
                })
            }).catch((errors) => {
                this.errors = errors
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>

