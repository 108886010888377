<template>
    <div>
        <fast-table
            v-if="warnings !== null"
            :activeRowId="currentWarning ? currentWarning.id : null"
            :recordLabel="recordLabel"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :search="search"
            @click="onClick"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        />

        <employee-sidebar
            v-if="!disableSidebar && currentWarning && (currentWarning.employee || currentWarning.onboarding_application)"
            :employee-id="currentEmployeeId"
            :onboarding-application-id="currentOnboardingApplicationId"
            tab='warnings' :item-id="currentWarning.id"
            :client="client"
            @close="currentWarning = null"
            :key="currentWarning ? `e-${currentWarning.id}` : ''"
            @updated="onUpdate"
        />

        <security-profile-sidebar
            v-if="!disableSidebar && currentWarning && currentWarning.company_security_profile_id"
            :security-profile-id="currentWarning.company_security_profile.security_profile_id"
            tab="warnings" :item-id="currentWarning.id"
            :client="client"
            @close="currentWarning = null"
            :key="currentWarning ? `sp-${currentWarning.id}` : ''"
            @updated="onUpdate"
        />
    </div>

</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import SecurityProfileSidebar from '@/components/SecurityProfileSidebar'

export default {
    components: {FastTable, EmployeeSidebar, SecurityProfileSidebar, },
    props: ['client', 'warnings', 'showEmployeeColumns', 'disableSidebar', ],
    computed: {
        currentEmployeeId() {
            if (!this.currentWarning) {
                return null
            }

            return this.currentWarning.employee_id ? this.currentWarning.employee_id : null
        },
        currentOnboardingApplicationId() {
            if (!this.currentWarning) {
                return null
            }

            if (this.currentWarning.employee_id) {
                // If it has an employee, perfer that even if it also has an onboarding application
                return null
            }

            return this.currentWarning.onboarding_application_id ? this.currentWarning.onboarding_application_id : null
        },
        unresolved() {
            return this.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && !w.is_resolved)
            })
        },
        resolved() {
            return this.warnings.filter(w => {
                //const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return w.is_resolved
            })
        },
        dismissed() {
            return this.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return isDismissed && !w.is_resolved
            })
        },
        tableData() {
            const tableData = []
            const companyLookup = {}

            const subsets = [
                this.dismissed,
                this.unresolved,
                this.resolved,
            ]

            this.client.companies.forEach(c => {
                companyLookup[c.id] = c
            })

            subsets.forEach(warnings => {
                const sectionData = []
                warnings.forEach((w, idx) => {
                    let fullName, ssnLast4, email, lastPayDate;

                    if (!fullName) {
                        if (w.company_security_profile) {
                            email = w.company_security_profile.security_profile.email
                            fullName = w.company_security_profile.security_profile.full_name_sortable
                            ssnLast4 = ''
                            lastPayDate = ''
                        }
                        if (w.employee) {
                            email = w.employee.email
                            fullName = w.employee.full_name_sortable
                            ssnLast4 = w.employee.ssn_last_4
                            lastPayDate = w.employee.last_pay_date
                        }
                        if (w.onboarding_application) {
                            email = w.onboarding_application.email
                            fullName = w.onboarding_application.full_name_sortable
                            ssnLast4 = w.onboarding_application.ssn_last_4
                            lastPayDate = ''
                        }
                    }

                    const company = w.company ? companyLookup[w.company] : null;

                    const row = {
                        id: w.id,
                        object: w,
                        cells: [
                            fullName,
                            company ? company.name : '',
                            w.warning_type_pretty,
                            w.module_pretty,
                            w.created_on,
                            //w.acknowledgement_count,
                            //w.dismiss_count,
                            //w.manager_count,
                            email,
                            ssnLast4 ? 'XXX-XX-' + ssnLast4 : '',
                            lastPayDate,
                        ],
                    }
                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        const data = {
            search: '',
            currentWarning: null,
            recordLabel: "warnings",
            headers: [
                {label: 'User', classes: '', isSearchable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Warning', classes: 'cw-13', isSearchable: false, isFilterable: true},
                {label: 'Module', classes: 'cw-9', isSearchable: true, isFilterable: true},
                {label: 'Date', classes: 'cw-date', isSearchable: false, type: 'date', defaultSort: true},
                //{label: 'Acknowledged By', classes: 'cell-medium', isSearchable: false},
                //{label: 'Dismissed By', classes: 'cell-medium', isSearchable: false},
                //{label: 'Managers', classes: 'cell-narrow', isSearchable: false},
                {label: 'Email Address', classes: 'cw-email', isSearchable: true},
            ],
            sections: [
                {title: 'Dismissed', defaultIsClosed: true, id: 'dismissed', hasDateFilter: false},
                {title: 'Unresolved', defaultIsClosed: false, id: 'unresolved', hasDateFilter: false},
                {title: 'Resolved', defaultIsClosed: true, id: 'resolved', hasDateFilter: true},
            ],
        }

        if (this.showEmployeeColumns) {
            data.headers.push({label: 'SSN', classes: 'cw-ssn', isSearchable: true})
            data.headers.push({label: 'Last Paid', classes: 'cw-date', isSearchable: false, type: 'date'})
        }

        return data
    },
    methods: {
        onDateFilter(dateRanges) {
            const dateRange = dateRanges.resolved
            this.$emit('date_filter_change', dateRange)
        },
        onUpdate($evt) {
            this.$emit('updated', $evt)
        },
        onClick($event) {
            this.currentWarning = $event
            this.$emit('warning_click', $event)
        },
    },
}
</script>


