<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">
                      
                        <h5>{{ onboardingApplication.company.name }}</h5>
                        <h3>Resident Address</h3>
        
                        <form @submit.prevent="onSubmit" class="mt-4">
                            <form-errors :errors="formErrors" :errorsHtml="formErrorsHtml" />
        
                            <form-input label="Address 1" v-model="formData.address_1" :errors="errors.address_1" type="text" :maxlength="50" />
                            <form-input label="Address 2 (Optional)" v-model="formData.address_2" :errors="errors.address_2" type="text" :maxlength="50" />
                            <form-input label="City" v-model="formData.city" :errors="errors.city" type="text" :maxlength="50" />
        
                            <div class="form-row">
                                <div class="col-6">
                                    <form-input label="State" v-model="formData.state" :errors="errors.state" type="select" :options="stateOptions"/>
                                </div>
        
                                <div class="col-6">
                                    <form-input label="Zip Code" :maxlength="5" v-model="formData.postal_code" :errors="errors.postal_code" type="text" inputmode="tel" />
                                </div>
                            </div>
        
                            <div class="form-row">
                                <div class="col-6">
                                    <form-input label="Phone Number" v-model="formData.phone" :errors="errors.phone" type="phone" inputmode="tel"/>
                                </div>
                            </div>

                            <form-input label="Use a different mailing address?" type="checkbox" v-model="formData.use_mailing_address" class="mb-2"/>

                            <!-- Optional Mailing address -->
                            <template v-if="formData.use_mailing_address">
                                <h3 class="mt-4 mb-4">Mailing Address</h3>
                                <form-input label="Address 1" v-model="formData.mailing_address_1" :errors="errors.mailing_address_1" type="text" :maxlength="50" />
                                <form-input label="Address 2 (Optional)" v-model="formData.mailing_address_2" :errors="errors.mailing_address_2" type="text" :maxlength="50" />
                                <form-input label="City" v-model="formData.mailing_city" :errors="errors.mailing_city" type="text" :maxlength="50" />
                                <div class="form-row">
                                    <div class="col-6">
                                        <form-input label="State" v-model="formData.mailing_state" :errors="errors.mailing_state" type="select" :options="stateOptions"/>
                                    </div>
            
                                    <div class="col-6">
                                        <form-input label="Zip Code" :maxlength="5" v-model="formData.mailing_postal_code" :errors="errors.mailing_postal_code" type="text" />
                                    </div>
                                </div>
                            </template>
        
                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col">
                                    <router-link v-if="previousPage" :to="previousPage">Back</router-link>
                                </div>
        
                                <div class="col text-right">
                                    <button class="btn btn-primary">Continue</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'
import WizardMixin from './WizardMixin'

import states from '@/states'

export default {
    mixins: [FormMixin, WizardMixin],
    props: ['onboardingApplication', ],
    computed: {
        stateOptions() {
            const result = []
            states.forEach((s) => {
                result.push({text: s.name.toUpperCase(), value: s.abbreviation})
            })
            return result
        },
    },
    data() {
        return {
            formRules: {
                address_1: {presence: {allowEmpty: false},},
                city: {presence: {allowEmpty: false},},
                state: {presence: {allowEmpty: false},},
                postal_code: {presence: {allowEmpty: false},},
                phone: {presence: {allowEmpty: false}, ten_digit_phone: true},
                mailing_address_1: {presence: {allowEmpty: false},},
                mailing_city: {presence: {allowEmpty: false},},
                mailing_state: {presence: {allowEmpty: false},},
                mailing_postal_code: {presence: {allowEmpty: false},},
            },
            formLabels: {
                postal_code: 'Zip Code',
                phone: 'Phone Number',
            },
            formErrorsHtml: [],
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            return {
                action: 'give-address',
                address_1: this.onboardingApplication.address_1,
                address_2: this.onboardingApplication.address_2,
                city: this.onboardingApplication.city,
                state: this.onboardingApplication.state,
                postal_code: this.onboardingApplication.postal_code,
                phone: this.onboardingApplication.phone,
                use_mailing_address: this.onboardingApplication.use_mailing_address,
                mailing_address_1: this.onboardingApplication.mailing_address_1,
                mailing_address_2: this.onboardingApplication.mailing_address_2,
                mailing_city: this.onboardingApplication.mailing_city,
                mailing_state: this.onboardingApplication.mailing_state,
                mailing_postal_code: this.onboardingApplication.mailing_postal_code,
            }
        },
        onSubmit() {
            let validateFields = ['address_1', 'city', 'state', 'postal_code', 'phone']
            if (this.formData.use_mailing_address) {
                validateFields = validateFields.concat(['mailing_address_1', 'mailing_city', 'mailing_state', 'mailing_postal_code'])
            }
            if (!this.validate(validateFields)) {
                return
            }
            this.$store.dispatch('START_LOADING')
            api.patch(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application`, this.getFormData()).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrorsHtml = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrorsHtml = []
            this.formData = this.makeFormData()
        },
    },
}
</script>
