<template>
<sidebar @close="$emit('close')">
    <template v-slot:title>
        {{ city.name }}, {{ city.state.name }}
    </template>
    <slot>
        <city-details-sidebar-widget
            :city="city"
            :all-cities="allCities"
            @updated="$emit('updated', $event)"
        />

        <tax-withholding-config-sidebar-widget
            jurisdiction="city"
            :city="city"
            @updated="$emit('updated')"
        />
    </slot>

    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import CityDetailsSidebarWidget from './CityDetailsSidebarWidget'
import TaxWithholdingConfigSidebarWidget from './TaxWithholdingConfigSidebarWidget'

export default {
    components: {Sidebar, TaxWithholdingConfigSidebarWidget, CityDetailsSidebarWidget, },
    props: ['city', 'allCities', 'involuntaryWithholdingTypes', 'deductionTypes', 'earningTypes', ],
    computed: {
        enabledTaxWithholdingFields() {
            if (this.city.tax_withholding_configs.length) {
                return this.city.tax_withholding_configs[0].field_configs.filter(fc => (fc.is_enabled)).slice().sort((a,b) => (
                    (a.tax_field < b.tax_field ? -1 : a.tax_field > b.tax_field ? 1 : 0)
                ))
            } else {
                return []
            }
        }
    }
}
</script>

