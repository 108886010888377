<template>
<div>
    <h3>I-9 Documents: Add Photos</h3>

    <div class="mt-4">
        <div class="mb-2">
            <p v-if="!doc.skipBackPhoto">
                <span>Take</span> or upload a photo of the <strong>{{ sidePretty }}</strong> of your <strong>{{ doc.shortName ? doc.shortName : doc.name }}</strong>:
            </p>

            <p v-if="doc.skipBackPhoto">
                <span>Take</span> or upload a photo of your <strong>{{ doc.shortName ? doc.shortName : doc.name }}</strong>:
            </p>

            <div class="card card-body p-1">
                <div class="d-flex mb-1">
                    <span class="flex-grow-1 text-bold" v-if="!doc.skipBackPhoto">
                        {{ sidePretty }} photo {{ (side == 'back' && !doc.requiresBackPhoto) ? '(Optional)' : '' }}
                    </span>

                    <span class="flex-grow-0 text-right position-relative" v-if="doc.slug == 'us_passport' && side == 'front'">
                        <div class="docphoto-info">
                            <more-info>
                                <template v-slot:label>Example</template>
                                <template>
                                    <p>
                                        The <strong>Passport ID</strong> page is the page of your passport that contains your name and photo.
                                    </p>

                                    <div class="docphoto mb-0">
                                        <img src="@/assets/img/i9-doc-photos/i9doc-example-passportid.png" alt="Passport ID page example illustration">
                                    </div>
                                </template>
                            </more-info>
                        </div>
                    </span>

                    <span class="flex-grow-0 text-right position-relative" v-if="doc.slug == 'us_passport' && side == 'back'">
                        <div class="docphoto-info">
                            <more-info>
                                <template v-slot:label>Example</template>
                                <template>
                                    <p>
                                        The <strong>Passport Barcode</strong> page is the inside back cover of your passport, which contains a barcode and number along one edge.
                                    </p>

                                    <div class="docphoto mb-0">
                                        <img src="@/assets/img/i9-doc-photos/i9doc-example-passportbarcode.png" alt="Passport Barcode page example illustration">
                                    </div>
                                </template>
                            </more-info>
                        </div>
                    </span>

                    <span class="flex-grow-0 text-right position-relative" v-if="(doc.slug != 'us_passport') && (list == 'list-a' || list == 'list-a-1' || list == 'list-a-2')">
                        <div class="docphoto-info">
                            <more-info>
                                <template v-slot:label>Example</template>
                                <template>
                                    <p>
                                        Examples for most List A documents can be found
                                        on the U.S. Citizenship and Immigration Services
                                        website:
                                    </p>
                                    <p>
                                        <a href="https://www.uscis.gov/i-9-central/form-i-9-resources/handbook-for-employers-m-274/120-acceptable-documents-for-verifying-employment-authorization-and-identity/121-list-a-documents-that-establish-identity-and-employment-authorization" target="_blank">
                                            List A Document Examples (USCIS)
                                        </a>
                                    </p>
                                </template>
                            </more-info>
                        </div>
                    </span>

                    <span class="flex-grow-0 text-right position-relative" v-if="list == 'list-b'">
                        <div class="docphoto-info">
                            <more-info>
                                <template v-slot:label>Example</template>
                                <template>
                                    <p>
                                        Examples for most List B documents can be found
                                        on the U.S. Citizenship and Immigration Services
                                        website:
                                    </p>
                                    <p>
                                        <a href="https://www.uscis.gov/i-9-central/form-i-9-resources/handbook-for-employers-m-274/120-acceptable-documents-for-verifying-employment-authorization-and-identity/122-list-b-documents-that-establish-identity" target="_blank">
                                            List B Document Examples (USCIS)
                                        </a>
                                    </p>
                                </template>
                            </more-info>
                        </div>
                    </span>

                    <span class="flex-grow-0 text-right position-relative" v-if="list == 'list-c'">
                        <div class="docphoto-info">
                            <more-info>
                                <template v-slot:label>Example</template>
                                <template>
                                    <p>
                                        Examples for most List C documents can be found
                                        on the U.S. Citizenship and Immigration Services
                                        website:
                                    </p>
                                    <p>
                                        <a href="https://www.uscis.gov/i-9-central/form-i-9-resources/handbook-for-employers-m-274/handbook-for-employers-m-274/120-acceptable-documents-for-verifying-employment-authorization-and-identity/123-list-c-documents-that-establish-employment-authorization" target="_blank">
                                            List C Document Examples (USCIS)
                                        </a>
                                    </p>
                                </template>
                            </more-info>
                        </div>
                    </span>

                </div>
                <div v-if="!uploads[side]">
                    <button class="btn btn-outline-primary btn-boxy" @click.prevent="$refs.fileInput.click()">
                        <i class="far fa-camera mr-hf"></i>
                        Add Photo
                    </button>

                    <input class="d-none" type="file" @change="onUpload($event)" accept=".pdf,.jpg,.jpeg,.png,.heic,.heif;capture=camera" ref="fileInput" />
                </div>

                <div v-if="uploads[side]">
                    <div class="docphoto">
                        <img :src="previewURLs[side]" alt="Document Photo">
                    </div>

                    <div class="text-small pt-1">
                        Not quite right? <button class="btn btn-link-inline" @click.prevent="uploads[side] = null">Add a different photo instead</button>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="side == 'back' && !doc.requiresBackPhoto">
            <button type="button" class="btn btn-link btn-block" @click.prevent="next(true)">
                This document does not have a back
            </button>
        </div>

        <hr v-if="formErrors.length" />
        <form-errors :errors="formErrors"/>

        <button-row inline>
            <a href @click.prevent="back">Back</a>
            <button class="btn btn-primary" @click.prevent="next(false)">Continue</button>
        </button-row>
    </div>

</div>
</template>

<script>
import ButtonRow from '@/components/ButtonRow'
import ImageConverter from '@/utils/image-converter'

export default {
    props: ['doc', 'list', 'form', ],
    components: {ButtonRow, },
    computed: {
        sidePretty() {
            let label = (this.doc.sideLabels && this.doc.sideLabels[this.side]) ? this.doc.sideLabels[this.side] : this.side
            label = label[0].toUpperCase() + label.substring(1)
            return label
        },
        previewURLs() {
            return {
                front: this.uploads.front ? `${this.$api.API_BASE}/me/onboarding/${this.form.onboarding_application_id}/onboarding-application/forms/${this.form.id}/photos/${this.uploads.front.id}/preview` : '',
                back: this.uploads.back ? `${this.$api.API_BASE}/me/onboarding/${this.form.onboarding_application_id}/onboarding-application/forms/${this.form.id}/photos/${this.uploads.back.id}/preview` : '',
            }
        },
    },
    data() {
        return {
            side: 'front',
            formErrors: [],
            uploads: {
                front: null,
                back: null,
            },
        }
    },
    methods: {
        onUpload(evt) {
            this.formErrors = []
            const files = evt.target.files || evt.dataTransfer.files
            if (!files.length) {
                return
            }

            const reader = new FileReader()
            reader.onload = () => {
                const photo = {
                    filename: files[0].name,
                    size: files[0].size,
                    file_type: files[0].type,
                    data: btoa(reader.result),

                    document_title: this.doc.shortName ? this.doc.shortName : this.doc.name,
                    document_slug: this.doc.slug,
                    document_list: this.list,
                    side: this.side,
                    side_pretty: this.sidePretty,
                }


                this.$store.dispatch('START_LOADING')
                ImageConverter.convert(files[0]).then(resp => {
                    photo.data = resp.data  // Base64 encoded
                    photo.file_type = resp.mime

                    // Send the converted image to the server to be attached to the form
                    this.$api.post(`/me/onboarding/${this.form.onboarding_application_id}/onboarding-application/forms/${this.form.id}/photos`, photo).then(resp => {
                        this.$store.dispatch('STOP_LOADING')
                        this.uploads[this.side] = resp
                    }).catch(errors => {
                        this.$store.dispatch('STOP_LOADING')
                        Object.keys(errors).forEach(field => {
                            if (field.substring(0, 2) == '__' && field != '__all__') {return}
                            this.formErrors = this.formErrors.concat(errors[field])
                        })

                        this.$bus.showError(this.formErrors)
                    })
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.__all__)
                })

                return
                // Get a pre-signed URL so we can upload the file directly to S3
                this.$api.post(`/image-converter-upload`, {ext: photo.filename.split('.').pop()}).then(resp => {
                    const filename = resp.delta.filename
                    // Upload the file to S3
                    this.$api.upload(resp.url, files[0], {formData: resp.fields}).then(resp => {
                        // Get the converted file from our image converter
                        this.$api.get(`/image-converter/${filename}`).then(resp => {
                            photo.data = resp.data  // Base64 encoded
                            photo.file_type = resp.mime

                            // Send the converted image to the server to be attached to the form
                            this.$api.post(`/me/onboarding/${this.form.onboarding_application_id}/onboarding-application/forms/${this.form.id}/photos`, photo).then(resp => {
                                this.$store.dispatch('STOP_LOADING')
                                this.uploads[this.side] = resp

                            }).catch(errors => {
                                this.$store.dispatch('STOP_LOADING')
                                Object.keys(errors).forEach(field => {
                                    if (field.substring(0, 2) == '__' && field != '__all__') {return}
                                    this.formErrors = this.formErrors.concat(errors[field])
                                })

                                this.$bus.showError(this.formErrors)
                            })

                        }).catch(errors => {
                            this.$store.dispatch('STOP_LOADING')
                            this.$bus.showError(errors.__all__)
                        })
                    }).catch(errors => {
                        this.$store.dispatch('STOP_LOADING')
                        this.$bus.showError(errors.__all__)
                    })
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.__all__)
                })

            }
            reader.readAsBinaryString(files[0])
        },
        next(allowSkipBack) {
            this.formErrors = []
            if (this.side == 'front') {
                if (this.uploads.front) {
                    if (this.doc.skipBackPhoto) {
                        this.$emit('uploads', this.uploads)
                    }
                    else {
                        this.side = 'back'
                    }
                }
                else {
                    this.formErrors.push('Please upload a photo.')
                }
            }
            else if (this.side == 'back') {
                if (!this.doc.requiresBackPhoto && allowSkipBack) {
                    this.uploads.back = null
                    this.$emit('uploads', this.uploads)
                }
                else if (this.uploads.back) {
                    this.$emit('uploads', this.uploads)
                }
                else {
                    this.formErrors.push('Please upload a photo!')
                }
            }
        },
        back() {
            this.formErrors = []
            if (this.side == 'front') {
                this.$emit('close')
            }
            else if (this.side == 'back') {
                this.side = 'front'
            }
        },
    },
}
</script>
