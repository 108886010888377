<template>
    <page>
        <fast-table
            v-if="dataLoaded"
            tableClasses="table-anchor--shorttext"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="onClick"
        >

            <template v-slot:table-actions>
            </template>

        </fast-table>

        <ats-log-sidebar :ats-log-id="currentATSLogId" v-if="currentATSLogId" @close="currentATSLogId = null" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import ATSLogSidebar from './components/ATSLogSidebar'

export default {
    components: {FastTable, AtsLogSidebar: ATSLogSidebar, Page, },
    computed: {
        ats_logs_errors() {
            return this.ats_logs.filter(c => !c.ats_inbound_success)
        },
        ats_logs_success() {
            return this.ats_logs.filter(c => c.ats_inbound_success)
        },
        tableData() {
            const tableData = []

            const subsets = [
                this.ats_logs_errors,
                this.ats_logs_success,
            ]

            subsets.forEach(ats_logs_section => {
                const sectionData = []

                ats_logs_section.forEach((c, idx) => {
                    const row = {
                        id: idx,
                        object: c,
                        cells: [
                            c.ats_employee_full_name_sortable,
                            c.ats_employee_email,
                            c.client ? c.client.client_code : '',
                            c.client ? c.client.name : '',
                            c.dt,
                            // c.status_code,
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            ats_logs: [],
            dataLoaded: false,
            currentATSLogId: null,
            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'Client Code', classes: 'cw-8', isSearchable: true},
                {label: 'Client', classes: 'cell-company', isSearchable: true},
                {label: 'Date', classes: 'cell-date', isSearchable: true, type: 'date'},
                // {label: 'Status', classes: '', isSearchable: true},
            ],
            sections: [
                {title: 'Errors', defaultIsClosed: false, id: 1},
                {title: 'Successful', defaultIsClosed: true, id: 2},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Inbound ATS Logs')
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/ats-logs').then((resp) => {
                this.ats_logs = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(ats_log) {
            const existing = this.ats_logs.find(s => {return s.id == ats_log.id})
            if (existing) {
                Object.assign(existing, ats_log)
            }
        },
        onClick(ats_log) {
            this.currentATSLogId = ats_log.id
        },
    },
}

</script>
