<template>
    <div>
        <nav-secondary :navOptions="navOptions"></nav-secondary>
        <router-view class="flex-page-content flex-container view" :client="client"/>
    </div>
</template>

<script>
import NavSecondary from '@/components/NavSecondary'

export default {
    components: { NavSecondary, },
    props: ['client', ],
    data() {
        let navOptions = [
            {'text': 'Security Users', 'value': 'settings-users', 'params': '{hashedClientId: $route.params.hashedClientId}}'},
            {'text': 'HR Document Library', 'value': 'settings-hr-documents', 'params': '{hashedClientId: $route.params.hashedClientId}}'},
            {'text': 'Change History', 'value': 'settings-change-history', 'params': '{hashedClientId: $route.params.hashedClientId}}'},
        ]

        if (this.client.has_everify) {
            navOptions.splice(1, 0, {'text': 'E-Verify Users', 'value': 'settings-everify-users', 'params': '{hashedClientId: $route.params.hashedClientId}}'})
        }

        return {
            navOptions: navOptions
        }
    },
    mounted() {
        const routes = this.navOptions.map(x => x.value)
        if (routes.indexOf(this.$route.name) < 0) {
            this.$router.replace({name: routes[0], params: {hashedClientId: this.$route.params.hashedClientId}})
        }
    },
}
</script>
