<template>
    <aside class="drawer drawer-rowdetail drawer-right collapse no-transition show" v-show="dataLoaded">

        <div class="drawer-header">
            <div class="drawer-close">
                <button type="button" class="close" aria-label="Close" role="button" aria-expanded="true" @click.prevent="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row">
                <div class="col">
                    <h2 class="drawer-title">
                        <span class="drawer-title-text">{{ company.name }} </span>
                    </h2>
                </div>
            </div>
        </div>

        <div class="drawer-body">

            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Properties</h4>
                    </span>
                </div>

                <div class="card-body p-1">
                    <div class="mui-textfield">
                        <span class="textfield">{{ details.company_code }}</span>
                        <label>Company Code</label>
                    </div>

                    <div class="mui-textfield">
                        <span class="textfield">{{ details.EIN }}</span>
                        <label>EIN</label>
                    </div>

                    <div class="mui-textfield">
                        <span class="textfield">{{ details.name }}</span>
                        <label>Name</label>
                    </div>

                    <div class="mui-textfield">
                        <span class="textfield">{{ details.known_as }}</span>
                        <label>Known As</label>
                    </div>

                    <div class="mui-textfield">
                        <span class="textfield" v-if="details.city || details.postal_code">
                            {{ details.address_1 }}{{ details.address_2 ? ' , ' + details.address_2 : '' }}
                            <br>
                            {{ details.city }}, {{ details.state }} {{ details.postal_code }}
                        </span>
                        <label>Address</label>
                    </div>
                </div>
            </div>

            <div class="card mb-2" v-if="dataLoaded">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Work Locations</h4>
                    </span>
                </div>

                <div class="card-body py-1">
                    <p v-if="!details.work_locations.length">This company has no work locations.</p>

                    <ul class="list-unstyled m-0" v-if="details.work_locations.length">
                        <li v-for="(w, i) in details.work_locations" :key="w.id" :class="{'border-bottom': i < details.work_locations.length - 1}" class="py-1">
                            <strong>{{ w.name }}</strong><br/>
                            {{w.id }} {{ w.address_1 }}{{ w.address_2 ? ` ${w.address_2}, ` : '' }}{{ w.city }}, {{ w.state }} {{ w.postal_code }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="card mb-2" v-if="dataLoaded">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Voluntary Tax Locations</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.prevent="$refs.editTaxLocationsModal.open()">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </div>

                <div class="card-body py-1">
                    <p v-if="!withholdingLocations.length">This company has no voluntary withholding locations.</p>

                    <ul class="list-unstyled m-0" v-if="withholdingLocations.length">
                        <li v-for="(w, i) in withholdingLocations" :key="w.id" :class="{'border-bottom': i < withholdingLocations.length - 1}">
                            <strong v-if="w.city">{{ w.city.name }}, {{ w.city.state.name }}</strong>
                            <strong v-if="w.state">{{ w.state.long_name }}</strong>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="card mb-2" v-if="dataLoaded">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">WOTC</h4>
                    </span>
                </div>

                <div class="card-body py-1">
                    <div class="mui-textfield">
                        <span class="textfield" v-if="details.wotc_api_enabled">Yes</span><span v-else>No</span>
                        <label>WOTC API Enabled</label>
                    </div>

                    <div class="mui-textfield" v-if="details.wotc_api_enabled">
                        <span class="textfield">{{ details.client.client_code }}</span>
                        <label>WOTC API Company Code</label>
                    </div>
                </div>
            </div>

            <div class="card mb-2" v-if="dataLoaded">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">SOC Code</h4>
                    </span>
                </div>

                <div class="card-body py-1">
                    <div class="mui-textfield">
                        <span class="textfield" v-if="details.soc_code">{{ details.soc_code }}</span>
                        <span class="textfield" v-if="!details.soc_code">Not Set</span>
                        <label>Default SOC Code</label>
                    </div>
                </div>
            </div>


            <div class="card mb-2" v-if="dataLoaded">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Implementation</h4>
                    </span>
                </div>

                <div class="card-body py-1">
                    <a class="btn btn-outline-primary btn-sm" :href="exportEmailsURL">
                        <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Export Emails</span>
                    </a>
                </div>

                <div class="card-body py-1">
                    <div class="mui-textfield">
                        <span class="textfield" v-if="details.ess_migration_emails_sent_on">Sent {{ $filters.datetime(details.ess_migration_emails_sent_on) }}</span><span v-else>Not sent</span>
                        <span class="ml-1">
                            <button class="btn btn-outline-primary btn-sm" @click.prevent="$refs.massMigrationEmailModal.open()">Send</button>
                        </span>
                        <label>ESS Invitation Mass Email</label>
                    </div>
                </div>
            </div>

            <div class="card mb-2" v-if="dataLoaded">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Data Sync</h4>
                    </span>
                </div>

                <div class="card-body py-1">
                    <a class="btn btn-outline-primary btn-sm" :href="syncFromPayrollURL">
                        <i class="far fa-fw fa-sync"></i> <span class="btn-text">Sync From Payroll</span>
                    </a>
                </div>
            </div>
        </div>

        <edit-tax-locations-modal ref="editTaxLocationsModal" :company="details" @updated="onVoluntaryWithholdingLocationsUpdate" />
        <mass-migration-email-modal ref="massMigrationEmailModal" :company="details" @updated="onMassMigrationEmailSend" />
    </aside>
</template>

<script>
import EditTaxLocationsModal from './EditTaxLocationsModal'
import MassMigrationEmailModal from './MassMigrationEmailModal'
import api from '@/api'
import bus from '@/bus'

export default {
    components: {EditTaxLocationsModal, MassMigrationEmailModal,},
    props: ['company', ],
    computed: {
        exportEmailsURL() {
            return `${this.$api.API_BASE}/admin/clients/${this.company.client.id}/companies/${this.company.id}/export-emails`
        },
        syncFromPayrollURL() {
            return `${this.$delta.POS_APP_URL}/employer/company/${this.company.id}/employee-sync`
        },
        withholdingLocations() {
            const locations = this.details.voluntary_withholding_locations
            locations.sort((a, b) => {
                if (a.city && !b.city) {return 1}
                if (!a.city && b.city) {return -1}

                if (a.city && b.city) {
                    return a.city.name > b.city.name ? 1 : -1
                }

                if (a.state && !b.state) {return 1}
                if (!a.state && b.state) {return -1}

                if (a.state && b.state) {
                    return a.state.long_name > b.state.long_name ? 1 : -1
                }


                return a.id > b.id ? 1 : -1
            })

            return locations
        },
    },
    mounted() {
        this.getCompany()
    },
    watch: {
        'company.id'() {
            this.getCompany()
        },
    },
    data() {
        return {
            dataLoaded: false,
            details: {},
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        getCompany() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            api.get(`/admin/clients/${this.$hasher.decode(this.$route.params.hashedClientId)}/companies/${this.company.id}/details`).then((resp) => {
                this.details = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
        onVoluntaryWithholdingLocationsUpdate(locations) {
            this.details.voluntary_withholding_locations = locations
            this.$emit('updated', this.details)
        },
        onMassMigrationEmailSend(resp) {
            bus.showInfo(resp['emails_sent'] + ' emails sent!')
            this.details.ess_migration_emails_sent_on = resp['ess_migration_emails_sent_on']
            this.$emit('updated', this.details)
        },
    },
}
</script>

