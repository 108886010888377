<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="onSubmit">
                        <form-errors :errors="formErrors" />

                        <h4 class="mb-2">Resident Address</h4>
                        <form-input label="Address 1" v-model="formData.address_1" :errors="errors.address_1" type="text" :maxlength="50"/>
                        <form-input label="Address 2 (Optional)" v-model="formData.address_2" :errors="errors.address_2" type="text" :maxlength="50"/>
                        <form-input label="City" v-model="formData.city" :errors="errors.city" type="text" :maxlength="50"/>

                        <div class="form-row">
                            <div class="col-6">
                                <form-input label="State" v-model="formData.state" :errors="errors.state" type="select" :options="stateOptions"/>
                            </div>

                            <div class="col-6">
                                <form-input label="Zip Code" :maxlength="5" v-model="formData.postal_code" :errors="errors.postal_code" type="text" inputmode="tel" />
                            </div>
                        </div>

                        <form-input label="Use a different mailing address?" type="checkbox" v-model="formData.use_mailing_address" class="mb-2"/>

                        <!-- Optional Mailing address -->
                        <template v-if="formData.use_mailing_address">
                            <h4 class="mt-4 mb-2">Mailing Address</h4>
                            <form-input label="Address 1" v-model="formData.mailing_address_1" :errors="errors.mailing_address_1" type="text" :maxlength="50"/>
                            <form-input label="Address 2 (Optional)" v-model="formData.mailing_address_2" :errors="errors.mailing_address_2" type="text" :maxlength="50"/>
                            <form-input label="City" v-model="formData.mailing_city" :errors="errors.mailing_city" type="text" :maxlength="50"/>
                            <div class="form-row">
                                <div class="col-6">
                                    <form-input label="State" v-model="formData.mailing_state" :errors="errors.mailing_state" type="select" :options="stateOptions"/>
                                </div>

                                <div class="col-6">
                                    <form-input label="Zip Code" :maxlength="5" v-model="formData.mailing_postal_code" :errors="errors.mailing_postal_code" type="text" />
                                </div>
                            </div>
                        </template>

                        <portal to="address-edit-modal-footer">
                            <button-row stacked>
                                <button type="button" @click.prevent="onPrev()" class="btn btn-outline-primary">Cancel</button>
                                <button type="button" @click.prevent="onSubmit()" class="btn btn-primary">Continue</button>
                            </button-row>
                        </portal>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ButtonRow from '@/components/ButtonRow'
import api from '@/api'

import states from '@/states'

export default {
    mixins: [FormMixin],
    components: {ButtonRow, },
    props: ['employee', 'employeeUpdate', 'prev'],
    watch: {
        employee() {
            this.reset()
        },
        employeeUpdate() {
            this.reset()
        }
    },
    computed: {
        stateOptions() {
            const result = []
            states.forEach((s) => {
                result.push({text: s.name.toUpperCase(), value: s.abbreviation})
            })
            return result
        },
    },
    data() {
        const data = {
            formData: this.makeFormData(),
            formRules: {
                address_1: {presence: {allowEmpty: false},},
                city: {presence: {allowEmpty: false},},
                state: {presence: {allowEmpty: false},},
                postal_code: {presence: {allowEmpty: false},},
                mailing_address_1: {presence: {allowEmpty: false},},
                mailing_city: {presence: {allowEmpty: false},},
                mailing_state: {presence: {allowEmpty: false},},
                mailing_postal_code: {presence: {allowEmpty: false},},
            },
            formLabels: {
                postal_code: 'Zip Code',
            },
        }
        return data
    },
    methods: {
        makeFormData() {
            let source = this.employeeUpdate ? this.employeeUpdate : this.employee
            let formData = {
                action: 'edit-address',
                address_1: source.address_1,
                address_2: source.address_2,
                city: source.city,
                state: source.state,
                postal_code: source.postal_code,
                use_mailing_address: source.use_mailing_address,
                mailing_address_1: source.mailing_address_1,
                mailing_address_2: source.mailing_address_2,
                mailing_city: source.mailing_city,
                mailing_state: source.mailing_state,
                mailing_postal_code: source.mailing_postal_code,
            }
            return formData
        },
        onSubmit() {
            let validateFields = ['address_1', 'city', 'state', 'postal_code', 'phone']
            if (this.formData.use_mailing_address) {
                validateFields = validateFields.concat(['mailing_address_1', 'mailing_city', 'mailing_state', 'mailing_postal_code'])
            }
            if (!this.validate(validateFields)) {
                return
            }

            this.$store.dispatch('START_LOADING')
            if (this.employeeUpdate) {
                this.patchEmployeeUpdate(this.employeeUpdate.id)
            } else {
                // If employeeUpdate wasn't passed in, that means we need to create one first before we patch it with form data
                api.post(`/me/ess/companies/${this.employee.company_id}/updates`, {}).then((resp) => {
                    this.patchEmployeeUpdate(resp['id'])
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }
        },
        onPrev() {
            this.$emit('prev')
        },
        patchEmployeeUpdate(employeeUpdateId) {
            this.$store.dispatch('START_LOADING')
            api.patch(`/me/ess/companies/${this.employee.company_id}/updates/${employeeUpdateId}`, this.getFormData()).then((resp) => {
                this.$emit('patch', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    }
}
</script>