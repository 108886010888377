<template>
    <employer-dashboard v-if="client.preview_dashboard" :client="client"/>
    <employer-home v-else :client="client"/>
</template>

<script>
// temporary component to switch between new and old dashboard until it's enabled for all
import EmployerHome from '@/pages/client/EmployerHome'
import EmployerDashboard from '@/pages/client/EmployerDashboard'

export default {
    props: ['client'],
    components: {EmployerHome, EmployerDashboard},
}
</script>
