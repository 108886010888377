<template>
<div>

    <fast-table
        v-if="dataLoaded"
        :sections="sections"
        :headers="headers"
        :data="tableData"
        @click="currentCityId = $event.id"
    >
        <template v-slot:table-actions>
            <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="currentCityId = null; addModalOpen = true">
                <i class="far fa-fw fa-plus"></i>
                <span class="btn-text">Add City</span>
            </button>
        </template>
    </fast-table>

    <sidebar
        v-if="currentCityId"
        :key="currentCityId"
        :city="currentCity"
        :all-cities="cities"
        :earning-types="earningTypes"
        :deduction-types="deductionTypes"
        :involuntary-withholding-types="involuntaryWithholdingTypes"
        @updated="onUpdate($event)"
        @close="currentCityId = null"
    />
    <add-modal v-if="addModalOpen" :all-cities="cities" @updated="getCities()" @close="addModalOpen = false"/>

</div>
</template>

<script>
import FastTable from '@/components/FastTable'
import AddModal from './components/CityModal'
import Sidebar from './components/CitySidebar'

export default {
    components: {FastTable, Sidebar, AddModal, },
    computed: {
        currentCity() {
            return this.cities.find(c => c.id == this.currentCityId)
        },
        tableData() {
            const tableData = []

            this.cities.forEach((c, idx) => {
                let numTaxFieldsEnabled = 0
                if (c.tax_withholding_configs.length) {
                    c.tax_withholding_configs.forEach((config, idx) => {
                        numTaxFieldsEnabled += config.num_fields_enabled
                    })
                }
                const row = {
                    id: idx,
                    isActive: this.currentCityId && c.id == this.currentCityId,
                    object: c,
                    cells: [
                        c.name,
                        c.state.name,
                        numTaxFieldsEnabled,
                        c.reciprocal_agreement_cities.length,
                        c.alternative_names.length,
                        c.updated_on,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            cities: [],
            dataLoaded: false,
            addModalOpen: false,
            currentCityId: null,

            involuntaryWithholdingTypes: [],
            earningTypes: [],
            deductionTypes: [],

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'State', classes: 'cw-5', isSearchable: true},
                {label: 'Tax Withholding Fields', classes: 'cw-11', isSearchable: false},
                {label: 'Reciprocal Cities', classes: 'cw-11', isSearchable: false},
                {label: 'Alternative Names', classes: 'cw-11', isSearchable: false},
                {label: 'Last Update', classes: 'cell-date', type: 'date', isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / Cities')
        this.getCities()
        this.getInvoluntaryWithholdingTypes()
        this.getEarningTypes()
        this.getDeductionTypes()
    },
    methods: {
        getCities() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/cities`).then(resp => {
                this.cities = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        getInvoluntaryWithholdingTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/involuntary-withholding-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.involuntaryWithholdingTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getEarningTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/earning-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.earningTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getDeductionTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/deduction-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.deductionTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(city) {
            if (!city) {
                return this.getCities()
            }
            const existing = this.cities.find(x => {return x.id == city.id})
            if (existing) {
                Object.assign(existing, city)
            }
        },
    },
}

</script>
