<template>
<div>
<info-widget :collapsible="true" :is-expanded="widgetExpanded" @toggle_expand="widgetExpanded = !widgetExpanded">
    <template v-slot:title>Form Selection Rules</template>

    <template v-slot:headerbutton>
        <button class="btn btn-outline-primary" type="button" @click.prevent="modalOpen = true">
            <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
            Edit
        </button>
    </template>

    <slot>
        <div class="p-2">
            <h4>1. Work &amp; Live in {{ state.name }}</h4>
            <div v-if="selfRule && selfRule.question" class="mb-2">
                <strong>Question:</strong> {{ selfRule.question.name }}
            </div>

            <div v-if="selfRule && selfRule.yaml_form" class="mb-2">
                <strong>PDF Form:</strong> {{ selfRule.yaml_form.name }}
            </div>

            <div v-if="!selfRule" class="mb-2">
                <em><i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i> Not defined</em>
            </div>

            <h4>2. Work in {{ state.name }} &amp; Live Elsewhere</h4>
            <p v-if="state.work_state_rules.length < 1">
                No special case rules are defined for {{ state.name }}.
            </p>

            <div class="mb-2" v-for="(rule, $index) in otherStates" :key="rule.id">
                <strong>State:</strong> {{ otherStateNames[$index] }}
                <div v-if="rule.question">
                    <strong>Question:</strong> {{ rule.question.name }}
                </div>

                <div v-if="rule.yaml_form">
                    <strong>PDF Form:</strong> {{ rule.yaml_form.name }}
                </div>
            </div>

            <h4>3. Default out of State Behavior</h4>
            <div v-if="defaultRule && defaultRule.question" class="mb-2">
                <strong>Question:</strong> {{ defaultRule.question.name }}
            </div>

            <div v-if="defaultRule && defaultRule.yaml_form" class="mb-2">
                <strong>PDF Form:</strong> {{ defaultRule.yaml_form.name }}
            </div>

            <div v-if="!defaultRule" class="mb-2">
                <em><i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i> Not defined</em>
            </div>
        </div>
    </slot>

</info-widget>
<edit-modal v-if="modalOpen" :state="state" :all-states="allStates" @updated="$emit('updated', $event)"  @close="modalOpen = false"/>

</div>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
import EditModal from './StateFormRulesModal'

export default {
    props: ['state', 'allStates'],
    components: {EditModal, InfoWidget, },
    computed: {
        selfRule() {
            return this.state.work_state_rules.find(r => r.resident_state == this.state.id)
        },
        defaultRule() {
            return this.state.work_state_rules.find(r => !r.resident_state)
        },
        otherStates() {
            return this.state.work_state_rules.filter(r => (r.resident_state && r.resident_state != this.state.id))
        },
        otherStateNames() {
            const result = []
            this.otherStates.forEach(rule => {
                result.push(this.allStates.find(s => s.id == rule.resident_state).name)
            })
            return result
        },
    },
    data() {
        return {
            modalOpen: false,
            widgetExpanded: false,
        }
    },
}
</script>



