export default [
  {name: 'Afghanistan', abbreviation: 'AF', alpha3: 'AFG'},
  {name: 'Åland Islands', abbreviation: 'AX', alpha3: 'ALA'},
  {name: 'Albania', abbreviation: 'AL', alpha3: 'ALB'},
  {name: 'Algeria', abbreviation: 'DZ', alpha3: 'DZA'},
  {name: 'American Samoa', abbreviation: 'AS', alpha3: 'ASM'},
  {name: 'Andorra', abbreviation: 'AD', alpha3: 'AND'},
  {name: 'Angola', abbreviation: 'AO', alpha3: 'AGO'},
  {name: 'Anguilla', abbreviation: 'AI', alpha3: 'AIA'},
  {name: 'Antarctica', abbreviation: 'AQ', alpha3: 'ATA'},
  {name: 'Antigua and Barbuda', abbreviation: 'AG', alpha3: 'ATG'},
  {name: 'Argentina', abbreviation: 'AR', alpha3: 'ARG'},
  {name: 'Armenia', abbreviation: 'AM', alpha3: 'ARM'},
  {name: 'Aruba', abbreviation: 'AW', alpha3: 'ABW'},
  {name: 'Australia', abbreviation: 'AU', alpha3: 'AUS'},
  {name: 'Austria', abbreviation: 'AT', alpha3: 'AUT'},
  {name: 'Azerbaijan', abbreviation: 'AZ', alpha3: 'AZE'},
  {name: 'Bahamas', abbreviation: 'BS', alpha3: 'BHS'},
  {name: 'Bahrain', abbreviation: 'BH', alpha3: 'BHR'},
  {name: 'Bangladesh', abbreviation: 'BD', alpha3: 'BGD'},
  {name: 'Barbados', abbreviation: 'BB', alpha3: 'BRB'},
  {name: 'Belarus', abbreviation: 'BY', alpha3: 'BLR'},
  {name: 'Belgium', abbreviation: 'BE', alpha3: 'BEL'},
  {name: 'Belize', abbreviation: 'BZ', alpha3: 'BLZ'},
  {name: 'Benin', abbreviation: 'BJ', alpha3: 'BEN'},
  {name: 'Bermuda', abbreviation: 'BM', alpha3: 'BMU'},
  {name: 'Bhutan', abbreviation: 'BT', alpha3: 'BTN'},
  {name: 'Bolivia', abbreviation: 'BO', alpha3: 'BOL'},
  {name: 'Bonaire, Sint Eustatius and Saba', abbreviation: 'BQ', alpha3: 'BES'},
  {name: 'Bosnia and Herzegovina', abbreviation: 'BA', alpha3: 'BIH'},
  {name: 'Botswana', abbreviation: 'BW', alpha3: 'BWA'},
  {name: 'Bouvet Island', abbreviation: 'BV', alpha3: 'BVT'},
  {name: 'Brazil', abbreviation: 'BR', alpha3: 'BRA'},
  {name: 'British Indian Ocean Territory', abbreviation: 'IO', alpha3: 'IOT'},
  {name: 'Brunei', abbreviation: 'BN', alpha3: 'BRN'},
  {name: 'Bulgaria', abbreviation: 'BG', alpha3: 'BGR'},
  {name: 'Burkina Faso', abbreviation: 'BF', alpha3: 'BFA'},
  {name: 'Burundi', abbreviation: 'BI', alpha3: 'BDI'},
  {name: 'Cambodia', abbreviation: 'KH', alpha3: 'KHM'},
  {name: 'Cameroon', abbreviation: 'CM', alpha3: 'CMR'},
  {name: 'Canada', abbreviation: 'CA', alpha3: 'CAN'},
  {name: 'Cape Verde', abbreviation: 'CV', alpha3: 'CPV'},
  {name: 'Cayman Islands', abbreviation: 'KY', alpha3: 'CYM'},
  {name: 'Central African Republic', abbreviation: 'CF', alpha3: 'CAF'},
  {name: 'Chad', abbreviation: 'TD', alpha3: 'TCD'},
  {name: 'Chile', abbreviation: 'CL', alpha3: 'CHL'},
  {name: 'China', abbreviation: 'CN', alpha3: 'CHN'},
  {name: 'Christmas Island', abbreviation: 'CX', alpha3: 'CXR'},
  {name: 'Cocos (Keeling) Islands', abbreviation: 'CC', alpha3: 'CCK'},
  {name: 'Colombia', abbreviation: 'CO', alpha3: 'COL'},
  {name: 'Comoros', abbreviation: 'KM', alpha3: 'COM'},
  {name: 'Congo', abbreviation: 'CG', alpha3: 'COG'},
  {name: 'Congo, Democratic Republic of the', abbreviation: 'CD', alpha3: 'COD'},
  {name: 'Cook Islands', abbreviation: 'CK', alpha3: 'COK'},
  {name: 'Costa Rica', abbreviation: 'CR', alpha3: 'CRI'},
  {name: 'Cote d\'Ivoire', abbreviation: 'CI', alpha3: 'CIV'},
  {name: 'Croatia', abbreviation: 'HR', alpha3: 'HRV'},
  {name: 'Cuba', abbreviation: 'CU', alpha3: 'CUB'},
  {name: 'Curaçao', abbreviation: 'CW', alpha3: 'CUW'},
  {name: 'Cyprus', abbreviation: 'CY', alpha3: 'CYP'},
  {name: 'Czech Republic', abbreviation: 'CZ', alpha3: 'CZE'},
  {name: 'Denmark', abbreviation: 'DK', alpha3: 'DNK'},
  {name: 'Djibouti', abbreviation: 'DJ', alpha3: 'DJI'},
  {name: 'Dominica', abbreviation: 'DM', alpha3: 'DMA'},
  {name: 'Dominican Republic', abbreviation: 'DO', alpha3: 'DOM'},
  {name: 'Ecuador', abbreviation: 'EC', alpha3: 'ECU'},
  {name: 'Egypt', abbreviation: 'EG', alpha3: 'EGY'},
  {name: 'El Salvador', abbreviation: 'SV', alpha3: 'SLV'},
  {name: 'Equatorial Guinea', abbreviation: 'GQ', alpha3: 'GNQ'},
  {name: 'Eritrea', abbreviation: 'ER', alpha3: 'ERI'},
  {name: 'Estonia', abbreviation: 'EE', alpha3: 'EST'},
  {name: 'Ethiopia', abbreviation: 'ET', alpha3: 'ETH'},
  {name: 'Falkland Islands (Malvinas)', abbreviation: 'FK', alpha3: 'FLK'},
  {name: 'Faroe Islands', abbreviation: 'FO', alpha3: 'FRO'},
  {name: 'Fiji', abbreviation: 'FJ', alpha3: 'FJI'},
  {name: 'Finland', abbreviation: 'FI', alpha3: 'FIN'},
  {name: 'France', abbreviation: 'FR', alpha3: 'FRA'},
  {name: 'French Guiana', abbreviation: 'GF', alpha3: 'GUF'},
  {name: 'French Polynesia', abbreviation: 'PF', alpha3: 'PYF'},
  {name: 'French Southern Territories', abbreviation: 'TF', alpha3: 'ATF'},
  {name: 'Gabon', abbreviation: 'GA', alpha3: 'GAB'},
  {name: 'Gambia', abbreviation: 'GM', alpha3: 'GMB'},
  {name: 'Georgia', abbreviation: 'GE', alpha3: 'GEO'},
  {name: 'Germany', abbreviation: 'DE', alpha3: 'DEU'},
  {name: 'Ghana', abbreviation: 'GH', alpha3: 'GHA'},
  {name: 'Gibraltar', abbreviation: 'GI', alpha3: 'GIB'},
  {name: 'Greece', abbreviation: 'GR', alpha3: 'GRC'},
  {name: 'Greenland', abbreviation: 'GL', alpha3: 'GRL'},
  {name: 'Grenada', abbreviation: 'GD', alpha3: 'GRD'},
  {name: 'Guadeloupe', abbreviation: 'GP', alpha3: 'GLP'},
  {name: 'Guam', abbreviation: 'GU', alpha3: 'GUM'},
  {name: 'Guatemala', abbreviation: 'GT', alpha3: 'GTM'},
  {name: 'Guernsey', abbreviation: 'GG', alpha3: 'GGY'},
  {name: 'Guinea', abbreviation: 'GN', alpha3: 'GIN'},
  {name: 'Guinea-Bissau', abbreviation: 'GW', alpha3: 'GNB'},
  {name: 'Guyana', abbreviation: 'GY', alpha3: 'GUY'},
  {name: 'Haiti', abbreviation: 'HT', alpha3: 'HTI'},
  {name: 'Heard Island and McDonald Islands', abbreviation: 'HM', alpha3: 'HMD'},
  {name: 'Holy See', abbreviation: 'VA', alpha3: 'VAT'},
  {name: 'Honduras', abbreviation: 'HN', alpha3: 'HND'},
  {name: 'Hong Kong', abbreviation: 'HK', alpha3: 'HKG'},
  {name: 'Hungary', abbreviation: 'HU', alpha3: 'HUN'},
  {name: 'Iceland', abbreviation: 'IS', alpha3: 'ISL'},
  {name: 'India', abbreviation: 'IN', alpha3: 'IND'},
  {name: 'Indonesia', abbreviation: 'ID', alpha3: 'IDN'},
  {name: 'Iran', abbreviation: 'IR', alpha3: 'IRN'},
  {name: 'Iraq', abbreviation: 'IQ', alpha3: 'IRQ'},
  {name: 'Ireland', abbreviation: 'IE', alpha3: 'IRL'},
  {name: 'Isle of Man', abbreviation: 'IM', alpha3: 'IMN'},
  {name: 'Israel', abbreviation: 'IL', alpha3: 'ISR'},
  {name: 'Italy', abbreviation: 'IT', alpha3: 'ITA'},
  {name: 'Jamaica', abbreviation: 'JM', alpha3: 'JAM'},
  {name: 'Japan', abbreviation: 'JP', alpha3: 'JPN'},
  {name: 'Jersey', abbreviation: 'JE', alpha3: 'JEY'},
  {name: 'Jordan', abbreviation: 'JO', alpha3: 'JOR'},
  {name: 'Kazakhstan', abbreviation: 'KZ', alpha3: 'KAZ'},
  {name: 'Kenya', abbreviation: 'KE', alpha3: 'KEN'},
  {name: 'Kiribati', abbreviation: 'KI', alpha3: 'KIR'},
  {name: 'Korea, Democratic Republic of (North Korea)', abbreviation: 'KP', alpha3: 'PRK'},
  {name: 'Korea, Republic of (South Korea)', abbreviation: 'KR', alpha3: 'KOR'},
  {name: 'Kosovo', abbreviation: 'XK', alpha3: 'XKX'},
  {name: 'Kuwait', abbreviation: 'KW', alpha3: 'KWT'},
  {name: 'Kyrgyzstan', abbreviation: 'KG', alpha3: 'KGZ'},
  {name: 'Laos', abbreviation: 'LA', alpha3: 'LAO'},
  {name: 'Latvia', abbreviation: 'LV', alpha3: 'LVA'},
  {name: 'Lebanon', abbreviation: 'LB', alpha3: 'LBN'},
  {name: 'Lesotho', abbreviation: 'LS', alpha3: 'LSO'},
  {name: 'Liberia', abbreviation: 'LR', alpha3: 'LBR'},
  {name: 'Libya', abbreviation: 'LY', alpha3: 'LBY'},
  {name: 'Liechtenstein', abbreviation: 'LI', alpha3: 'LIE'},
  {name: 'Lithuania', abbreviation: 'LT', alpha3: 'LTU'},
  {name: 'Luxembourg', abbreviation: 'LU', alpha3: 'LUX'},
  {name: 'Macao', abbreviation: 'MO', alpha3: 'MAC'},
  {name: 'North Macedonia', abbreviation: 'MK', alpha3: 'MKD'},
  {name: 'Madagascar', abbreviation: 'MG', alpha3: 'MDG'},
  {name: 'Malawi', abbreviation: 'MW', alpha3: 'MWI'},
  {name: 'Malaysia', abbreviation: 'MY', alpha3: 'MYS'},
  {name: 'Maldives', abbreviation: 'MV', alpha3: 'MDV'},
  {name: 'Mali', abbreviation: 'ML', alpha3: 'MLI'},
  {name: 'Malta', abbreviation: 'MT', alpha3: 'MLT'},
  {name: 'Marshall Islands, Republic of the', abbreviation: 'MH', alpha3: 'MHL'},
  {name: 'Martinique', abbreviation: 'MQ', alpha3: 'MTQ'},
  {name: 'Mauritania', abbreviation: 'MR', alpha3: 'MRT'},
  {name: 'Mauritius', abbreviation: 'MU', alpha3: 'MUS'},
  {name: 'Mayotte', abbreviation: 'YT', alpha3: 'MYT'},
  {name: 'Mexico', abbreviation: 'MX', alpha3: 'MEX'},
  {name: 'Micronesia, Federated States of', abbreviation: 'FM', alpha3: 'FSM'},
  {name: 'Moldova, Republic of', abbreviation: 'MD', alpha3: 'MDA'},
  {name: 'Monaco', abbreviation: 'MC', alpha3: 'MCO'},
  {name: 'Mongolia', abbreviation: 'MN', alpha3: 'MNG'},
  {name: 'Montenegro', abbreviation: 'ME', alpha3: 'MNE'},
  {name: 'Montserrat', abbreviation: 'MS', alpha3: 'MSR'},
  {name: 'Morocco', abbreviation: 'MA', alpha3: 'MAR'},
  {name: 'Mozambique', abbreviation: 'MZ', alpha3: 'MOZ'},
  {name: 'Myanmar', abbreviation: 'MM', alpha3: 'MMR'},
  {name: 'Namibia', abbreviation: 'NA', alpha3: 'NAM'},
  {name: 'Nauru', abbreviation: 'NR', alpha3: 'NRU'},
  {name: 'Nepal', abbreviation: 'NP', alpha3: 'NPL'},
  {name: 'Netherlands', abbreviation: 'NL', alpha3: 'NLD'},
  {name: 'New Caledonia', abbreviation: 'NC', alpha3: 'NCL'},
  {name: 'New Zealand', abbreviation: 'NZ', alpha3: 'NZL'},
  {name: 'Nicaragua', abbreviation: 'NI', alpha3: 'NIC'},
  {name: 'Niger', abbreviation: 'NE', alpha3: 'NER'},
  {name: 'Nigeria', abbreviation: 'NG', alpha3: 'NGA'},
  {name: 'Niue', abbreviation: 'NU', alpha3: 'NIU'},
  {name: 'Norfolk Island', abbreviation: 'NF', alpha3: 'NFK'},
  {name: 'Northern Mariana Islands', abbreviation: 'MP', alpha3: 'MNP'},
  {name: 'Norway', abbreviation: 'NO', alpha3: 'NOR'},
  {name: 'Oman', abbreviation: 'OM', alpha3: 'OMN'},
  {name: 'Pakistan', abbreviation: 'PK', alpha3: 'PAK'},
  {name: 'Palau', abbreviation: 'PW', alpha3: 'PLW'},
  {name: 'Palestine, State of', abbreviation: 'PS', alpha3: 'PSE'},
  {name: 'Panama', abbreviation: 'PA', alpha3: 'PAN'},
  {name: 'Papua New Guinea', abbreviation: 'PG', alpha3: 'PNG'},
  {name: 'Paraguay', abbreviation: 'PY', alpha3: 'PRY'},
  {name: 'Peru', abbreviation: 'PE', alpha3: 'PER'},
  {name: 'Philippines', abbreviation: 'PH', alpha3: 'PHL'},
  {name: 'Pitcairn', abbreviation: 'PN', alpha3: 'PCN'},
  {name: 'Poland', abbreviation: 'PL', alpha3: 'POL'},
  {name: 'Portugal', abbreviation: 'PT', alpha3: 'PRT'},
  {name: 'Puerto Rico', abbreviation: 'PR', alpha3: 'PRI'},
  {name: 'Qatar', abbreviation: 'QA', alpha3: 'QAT'},
  {name: 'Réunion', abbreviation: 'RE', alpha3: 'REU'},
  {name: 'Romania', abbreviation: 'RO', alpha3: 'ROU'},
  {name: 'Russian Federation', abbreviation: 'RU', alpha3: 'RUS'},
  {name: 'Rwanda', abbreviation: 'RW', alpha3: 'RWA'},
  {name: 'Saint Barthélemy', abbreviation: 'BL', alpha3: 'BLM'},
  {name: 'Saint Helena, Ascension and Tristan da Cunha', abbreviation: 'SH', alpha3: 'SHN'},
  {name: 'Saint Kitts and Nevis', abbreviation: 'KN', alpha3: 'KNA'},
  {name: 'Saint Lucia', abbreviation: 'LC', alpha3: 'LCA'},
  {name: 'Saint Martin (French part)', abbreviation: 'MF', alpha3: 'MAF'},
  {name: 'Saint Pierre and Miquelon', abbreviation: 'PM', alpha3: 'SPM'},
  {name: 'Saint Vincent and the Grenadines', abbreviation: 'VC', alpha3: 'VCT'},
  {name: 'Samoa', abbreviation: 'WS', alpha3: 'WSM'},
  {name: 'San Marino', abbreviation: 'SM', alpha3: 'SMR'},
  {name: 'Sao Tome and Principe', abbreviation: 'ST', alpha3: 'STP'},
  {name: 'Saudi Arabia', abbreviation: 'SA', alpha3: 'SAU'},
  {name: 'Senegal', abbreviation: 'SN', alpha3: 'SEN'},
  {name: 'Serbia', abbreviation: 'RS', alpha3: 'SRB'},
  {name: 'Seychelles', abbreviation: 'SC', alpha3: 'SYC'},
  {name: 'Sierra Leone', abbreviation: 'SL', alpha3: 'SLE'},
  {name: 'Singapore', abbreviation: 'SG', alpha3: 'SGP'},
  {name: 'Sint Maarten (Dutch part)', abbreviation: 'SX', alpha3: 'SXM'},
  {name: 'Slovakia', abbreviation: 'SK', alpha3: 'SVK'},
  {name: 'Slovenia', abbreviation: 'SI', alpha3: 'SVN'},
  {name: 'Solomon Islands', abbreviation: 'SB', alpha3: 'SLB'},
  {name: 'Somalia', abbreviation: 'SO', alpha3: 'SOM'},
  {name: 'South Africa', abbreviation: 'ZA', alpha3: 'ZAF'},
  {name: 'South Georgia and the South Sandwich Islands', abbreviation: 'GS', alpha3: 'SGS'},
  {name: 'South Sudan', abbreviation: 'SS', alpha3: 'SSD'},
  {name: 'Spain', abbreviation: 'ES', alpha3: 'ESP'},
  {name: 'Sri Lanka', abbreviation: 'LK', alpha3: 'LKA'},
  {name: 'Sudan', abbreviation: 'SD', alpha3: 'SDN'},
  {name: 'Suriname', abbreviation: 'SR', alpha3: 'SUR'},
  {name: 'Svalbard and Jan Mayen', abbreviation: 'SJ', alpha3: 'SJM'},
  {name: 'Swaziland', abbreviation: 'SZ', alpha3: 'SWZ'},
  {name: 'Sweden', abbreviation: 'SE', alpha3: 'SWE'},
  {name: 'Switzerland', abbreviation: 'CH', alpha3: 'CHE'},
  {name: 'Syria', abbreviation: 'SY', alpha3: 'SYR'},
  {name: 'Taiwan', abbreviation: 'TW', alpha3: 'TWN'},
  {name: 'Tajikistan', abbreviation: 'TJ', alpha3: 'TJK'},
  {name: 'Tanzania, United Republic of', abbreviation: 'TZ', alpha3: 'TZA'},
  {name: 'Thailand', abbreviation: 'TH', alpha3: 'THA'},
  {name: 'Timor-Leste', abbreviation: 'TL', alpha3: 'TLS'},
  {name: 'Togo', abbreviation: 'TG', alpha3: 'TGO'},
  {name: 'Tokelau', abbreviation: 'TK', alpha3: 'TKL'},
  {name: 'Tonga', abbreviation: 'TO', alpha3: 'TON'},
  {name: 'Trinidad and Tobago', abbreviation: 'TT', alpha3: 'TTO'},
  {name: 'Tunisia', abbreviation: 'TN', alpha3: 'TUN'},
  {name: 'Turkey', abbreviation: 'TR', alpha3: 'TUR'},
  {name: 'Turkmenistan', abbreviation: 'TM', alpha3: 'TKM'},
  {name: 'Turks and Caicos Islands', abbreviation: 'TC', alpha3: 'TCA'},
  {name: 'Tuvalu', abbreviation: 'TV', alpha3: 'TUV'},
  {name: 'Uganda', abbreviation: 'UG', alpha3: 'UGA'},
  {name: 'Ukraine', abbreviation: 'UA', alpha3: 'UKR'},
  {name: 'United Arab Emirates', abbreviation: 'AE', alpha3: 'ARE'},
  {name: 'United Kingdom of Great Britain and Northern Ireland', abbreviation: 'GB', alpha3: 'GBR'},
  {name: 'United States of America (USA)', abbreviation: 'US', alpha3: 'USA'},
  {name: 'United States Minor Outlying Islands', abbreviation: 'UM', alpha3: 'UMI'},
  {name: 'Uruguay', abbreviation: 'UY', alpha3: 'URY'},
  {name: 'Uzbekistan', abbreviation: 'UZ', alpha3: 'UZB'},
  {name: 'Vanuatu', abbreviation: 'VU', alpha3: 'VUT'},
  {name: 'Venezuela, Bolivarian Republic of', abbreviation: 'VE', alpha3: 'VEN'},
  {name: 'Vietnam', abbreviation: 'VN', alpha3: 'VNM'},
  {name: 'Virgin Islands, British', abbreviation: 'VG', alpha3: 'VGB'},
  {name: 'Virgin Islands, U.S.', abbreviation: 'VI', alpha3: 'VIR'},
  {name: 'Wallis and Futuna', abbreviation: 'WF', alpha3: 'WLF'},
  {name: 'Western Sahara', abbreviation: 'EH', alpha3: 'ESH'},
  {name: 'Yemen', abbreviation: 'YE', alpha3: 'YEM'},
  {name: 'Zambia', abbreviation: 'ZM', alpha3: 'ZMB'},
  {name: 'Zimbabwe', abbreviation: 'ZW', alpha3: 'ZWE'},
]
