<template>
    <modal @close="$emit('close')" size="xl" ref="modal" :fade="false" :v-center="true" :autoshow="true" :is-closeable="isCloseable">
        <template v-slot:title>Employee COVID Vaccination Status</template>

        <slot>
            <p v-if="status.status == 'pending' && status.is_active">This COVID vaccination status is pending your review. Please carefully review the employee's submission and either accept or reject it.</p>

            <p>Submitted by employee on {{ $filters.date(status.reported_on) }}</p>

            <div v-if="status.status == 'accepted'">
                <p v-if="status.accepted_by_id">Accepted by: <span>{{ status.accepted_by.email }}</span> on <span>{{ $filters.date(status.accepted_on) }}</span>.</p>
            </div>

            <div v-if="status.status == 'rejected'">
                <p v-if="status.rejected_by_id">Rejected by: <span>{{ status.rejected_by.email }}</span> on <span>{{ $filters.date(status.rejected_on) }}</span>.</p>
                <p v-if="!status.accepted_by_id">Submitted by the employee on {{ $filters.date(status.reported_on) }}.</p>
            </div>

            <div v-if="!status.is_active">
                <p>This COVID vaccination status has been overridden by a newer submission from the employee.</p>
            </div>

            <h5>Reported Status</h5>
            <h2>{{ status.vaccination_status_pretty }}</h2>

            <div v-if="status.vaccination_status == 'fully-vaccinated' || status.vaccination_status == 'partially-vaccinated'" class="mb-2">
                <table class="table mb-2 mt-2">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Location Administered</th>
                            <th>Type</th>
                        </tr>
                    </thead>

                    <tr v-for="shot in status.shots">
                        <td>{{ $filters.date(shot.administered_on) }}</td>
                        <td>{{ shot.location }}</td>
                        <td>{{ shot.vaccine_name }}</td>
                    </tr>
                </table>

                <div v-if="status.is_attestation">
                    <p>This employee's vaccination card was lost or stolen. They attest that they are {{ status.vaccination_status_pretty }}.</p>

                    <pdf-inline title="" :url="{url: `${$api.API_BASE}/clients/${status.client_id}/employees/${status.employee_id}/covid-vaccination-statuses/${status.id}/card`, withCredentials: true}" />
                </div>

                <div v-if="!status.is_attestation">
                    <img :src="`${$api.API_BASE}/clients/${status.client_id}/employees/${status.employee_id}/covid-vaccination-statuses/${status.id}/card`" alt="COVID vaccination card" style="max-width: 100%"/>
                </div>
            </div>

        </slot>

        <template v-slot:footer>
            <button-row stacked center>
                <button v-if="status.status == 'pending' && status.is_active" type="button" class="btn btn-outline-primary" @click.prevent="reject">Reject</button>
                <button v-if="status.status == 'pending' && status.is_active" type="button" class="btn btn-primary" @click.prevent="accept">Accept</button>
                <button v-if="status.status != 'pending' || !status.is_active" type="button" class="btn btn-primary" @click.prevent="close">Close</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import PdfInline from '@/components/PdfInline'

export default {
    props: ['clientId', 'employeeId', 'status'],
    mixins: [ModalMixin],
    components: {PdfInline, },
    computed: {
        updateURL() {
            return `/clients/${this.status.client_id}/employees/${this.status.employee_id}/covid-vaccination-statuses/${this.status.id}`
        }
    },
    data() {
        return {
        }
    },
    methods: {
        accept() {
            this.$store.dispatch('START_LOADING')
            this.$api.put(this.updateURL, {status: 'accepted'}).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        reject() {
            this.$store.dispatch('START_LOADING')
            this.$api.put(this.updateURL, {status: 'rejected'}).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>
