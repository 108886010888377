<template>
<!--
    Shell component that handles edit document draft, add document version, & edit document version draft
    by showing the correct component with props pulled from route
    We could split these 3 actions into 3 similar components if that's easier to reason about
-->
    <div>
        <page :fullScroll="true">
                    <div class="container-centered-m py-4">
                        <router-link class="btn btn-link" :to="{name: 'settings-hr-documents'}"><i class="far fa-fw fa-chevron-left mr-hf"></i>Back to HR Document Library</router-link>

                        <uniform-wizard
                            v-if="documentType=='uniform'"
                            :client="client"
                            :uniformId="documentId"
                            :uniformVersionNumber="documentVersionNumber"
                            :mode="this.$route.meta.documentMode"
                            @prev="exitToDocuments"
                        />

                        <company-document-wizard
                            v-if="documentType=='hr-doc'"
                            :client="client"
                            :documentId="documentId"
                            :documentVersionNumber="documentVersionNumber"
                            :mode="this.$route.meta.documentMode"
                            @prev="exitToDocuments"
                        />

                        <custom-form-wizard
                            v-if="documentType=='hr-custom-form'"
                            :client="client"
                            :documentId="documentId"
                            :documentVersionNumber="documentVersionNumber"
                            :mode="this.$route.meta.documentMode"
                            @prev="exitToDocuments"
                        />

                    </div>
        </page>
    </div>
</template>

<script>
import UniformWizard from './components/UniformWizard.vue'
import CustomFormWizard from './components/CustomFormWizard.vue'
import CompanyDocumentWizard from './components/CompanyDocumentWizard.vue'
import Page from '@/components/Page.vue'

export default {
    components: {Page, UniformWizard, CustomFormWizard, CompanyDocumentWizard, },
    props: ['client', ],
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Settings / HR Document Library')
    },
    computed: {
        documentType() {
            return this.$route.params.documentType
        },
        documentId() {
            return this.$route.params.documentId
        },
        documentVersionNumber() {
            return this.$route.params.documentVersionNumber || null
        },
    },
    methods: {
        exitToDocuments() {
            this.$router.push({name: 'settings-hr-documents'})
        },
    }
}

</script>
