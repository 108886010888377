<template>
    <table-page
        :client="client"
        :warnings="warnings"
        :changelogs="changelogs"
        @changelog_date_filter_change="onChangelogDateRangeChange"
        @warnings_date_filter_change="onWarningsDateRangeChange"
        @warnings_updated="getWarnings"
        @updated="refresh"
    >
        <div class="heads-up-banner-container" v-if="eVerifyAccess == 'blocked'">
            <transition name="fade">
                <div class="alert alert-info mt-2">
                    <div>
                        <i class="fas fa-fw fa-info-circle mr-1"></i>
                        <strong>E-Verify Training Required</strong>
                    </div>
                    <div class="ml-3 my-2">
                        The Department of Homeland Security requires all E-Verify users to complete training and complete a knowledge test with a score
                        of at least 70% before using E-Verify.
                    </div>
                    <div class="ml-3">
                        <router-link class="btn btn-link py-0" :to="{name: 'training-e-verify', params: {client: client.id} }">
                            <i class="far mr-hf fa-book-open"></i> Review E-Verify training and complete your Knowledge Test
                        </router-link>
                    </div>
                </div>
            </transition>
        </div>

        <fast-table
            v-if="eVerifyAccess != 'blocked'"
            :recordLabel="recordLabel"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :managers="client.company_managers"
            @click="currentCase = $event"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        />

        <employee-sidebar
            :client="client"
            :employee-id="currentCase.employee ? currentCase.employee.id : null"
            :onboarding-application-id="currentCase.employee ? null : (currentCase.onboarding_application ? currentCase.onboarding_application.id : null)"
            v-if="currentCase"
            @close="currentCase = null"
            tab="misc" section="e-verify" :item-id="currentCase.id"
            @updated="refresh"
            :key="currentCase.id"/>
    </table-page>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import TablePageMixin from '@/mixins/TablePageMixin'

export default {
    props: ['client'],
    components: {FastTable, EmployeeSidebar, },
    mixins: [TablePageMixin, ],
    computed: {
        eVerifyAccess() {
            const userSP = this.$permissions.getSecurityProfile(this.client.id)
            if (userSP) {
                return userSP.everify_access
            }

            if (this.$permissions.isSuperuser()) {
                return 'enabled'
            }

            return ''
        },
        cases_to_create() {
            return this.cases.filter(c => c.case_status_category == 'cases-to-create')
        },
        cases_pending_employer() {
            return this.cases.filter(c => c.case_status_category == 'pending-employer')
        },
        cases_pending_everify() {
            return this.cases.filter(c => c.case_status_category == 'pending-everify')
        },
        cases_closed() {
            return this.cases.filter(c => c.case_status_category == 'closed')
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.cases_to_create,
                this.cases_pending_employer,
                this.cases_pending_everify,
                this.cases_closed,
            ]

            subsets.forEach(cases_section => {
                const sectionData = []

                cases_section.forEach((c, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentCase && c.id == this.currentCase.id,
                        object: c,
                        cells: [
                            c.emp_full_name_sortable,
                            c.emp_start_date,
                            c.case_status_pretty,
                            c.company.name,
                            c.emp_email,
                            `XXX-XX-${c.emp_ssn_last_4}`,
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            cases: [],
            dataLoaded: false,
            refreshing: false,
            currentCase: null,
            dateRanges: {},

            recordLabel: "E-Verify cases",
            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Start Date', classes: 'cw-date', isSearchable: false, type: 'date'},
                {label: 'Status', classes: 'cw-5h cell-center', isFilterable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'SSN', classes: 'cw-ssn', isSearchable: true},
            ],
            sections: [
                {title: 'Cases to Create', defaultIsClosed: false, id: 'cases-to-create', hasDateFilter: false},
                {title: 'Action Required', defaultIsClosed: false, id: 'pending-employer', hasDateFilter: false},
                {title: 'Pending', defaultIsClosed: true, id: 'pending-everify', hasDateFilter: false},
                {title: 'Closed Cases', defaultIsClosed: true, id: 'closed', hasDateFilter: true},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify')

        if (this.eVerifyAccess == 'blocked') {
            this.$router.push({name: 'training-e-verify', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
            return
        }

        this.getData()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        filterRelevantWarnings(w) {
            // N/A for this tile
            return false
        },
        getData(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.refreshing = true

            let url = `/clients/${this.client.id}/e-verify-cases`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                this.cases = resp
                this.dataLoaded = true
                this.refreshing = false
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.refreshing = false
                this.$bus.showError(errors.__all__)
            })
        },
        refresh() {
            if (this.refreshing) {return}
            this.getData(true)
            this.getWarnings()
            this.getChangelogs()
        },
        filterRelevantChangelog(c) {
            return c.everify_case_id
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getData()
        },
    },
}

</script>
