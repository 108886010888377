<template>
<div>
<sidebar-widget
    @add="modalOpen = true"
    @delete="onDelete($event)"
    @edit="currentRowId = $event; modalOpen = true"
    @versions="currentRowId = $event; versionsModalIsOpen = true"
    :rows="rows"
    :headers="['Min Wage', 'Tip Credit']"
>
    <template v-slot:title>Minimum Wages</template>

    <template v-slot:row-details scope="props">
        <minimum-wage-version :version="props.row.current_version" />
    </template>
</sidebar-widget>


<minimum-wage-modal
    v-if="modalOpen"
    :jurisdiction="jurisdiction"
    :minimum-wage="currentRow"
    @updated="$emit('updated')"
    @close="modalOpen = false; currentRowId = null"

/>

<versions-modal
    v-if="versionsModalIsOpen"
    :url="getURL(currentRowId)"
    @close="currentRowId = null; versionsModalIsOpen = false"
>
    <template v-slot:version scope="props">
        <minimum-wage-version :version="props.version" />
    </template>
</versions-modal>

</div>
</template>

<script>
import MinimumWageModal from './MinimumWageModal'
import SidebarWidget from './SidebarWidget'
import MinimumWageVersion from './MinimumWageVersion'
import VersionsModal from './VersionsModal'

export default {
    props: ['jurisdiction', ],
    components: {MinimumWageModal, SidebarWidget, MinimumWageVersion, VersionsModal},
    computed: {
        currentRow() {
            return this.allMinimumWage.find(x => x.id == this.currentRowId)
        },
        rows() {
            const result = []
            this.allMinimumWage.forEach(row => {
                row = JSON.parse(JSON.stringify(row))
                row.cells = [
                    `$${this.$filters.formatCurrency(row.current_version.minimum_wage)}`,
                    `$${this.$filters.formatCurrency(row.current_version.max_tip_credit_claimed)}`,
                ]
                result.push(row)
            })
            return result
        },
        allMinimumWage() {
            return this.jurisdiction.minimum_wages.sort((a, b) => a.current_version.starts_on > b.current_version.starts_on ? -1 : 1)
        },
    },
    data() {
        return {
            modalOpen: false,
            versionsModalIsOpen: false,
            currentRowId: null,
        }
    },
    methods: {
        getURL(rowId) {
            if (!rowId) {return}
            return `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/minimum-wage/${rowId}`
        },
        onDelete(rowId) {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(this.getURL(rowId)).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>

