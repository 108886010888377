
const EDITORS = {
    'unlinked-companies': (val) => {
        if (val.toLowerCase().includes('unlinked comp')) {
            return 'Has Unlinked Companies'
        }
        return val
    },
}

const rowMatchesFilters = (row, filters, managers) => {
    let totalFilterMatches = 0
    filters.forEach(filter => {
        let matchesFilter = false
        const rowValues = new Set()
        const filterValues = new Set(filter.filterValues.map(fv => fv.value))

        if (filter.type == 'managers') {
            const rowCSPIds = Array.from(new Set(row.object.company_security_profile_ids))  // We might want to provide row.managerIds explicitly vs by convention in row.object.company_security_profile_ids
            const rowSPIds = new Set()

            rowCSPIds.forEach(cspId => {
                const spId = managers[cspId] ? managers[cspId].security_profile_id : null
                if (spId) {
                    rowSPIds.add(spId)
                }
            })

            rowSPIds.forEach(managerId => {
                if (filterValues.has(managerId)) {
                    matchesFilter = true
                }
            })
        }
        else if (filter.type == 'warning') {
            const warnings = (row.object.warnings || [])
            if (warnings.length > 0) {
                warnings.forEach(w => {  // We might want to provide row.warnings explicitly vs by convention in row.object.warnings
                    const val = w.warning_type
                    if (filterValues.has(val)) {
                        matchesFilter = true
                    }
                })
            }
        }
        else if (filter.type == 'checkmark') {
            const val = row.cells[filter.idx]
            if (filterValues.has(val)) {
                matchesFilter = true
            }
        }
        else {
            let val = (row.cells[filter.idx] && row.cells[filter.idx].value) ? row.cells[filter.idx].value : row.cells[filter.idx]
            if (filter.filterValueEditor) {
                const editor = EDITORS[filter.filterValueEditor]
                val = editor(val)
            }
            rowValues.add(val)

            if (row.subrows) {
                row.subrows.forEach(subrow => {
                    rowValues.add(subrow[filter.idx])
                })
            }

            rowValues.forEach(rv => {
                if (filterValues.has(rv)) {
                    matchesFilter = true
                }
            })
        }

        if (matchesFilter) {
            totalFilterMatches += 1
        }
    })

    return totalFilterMatches == filters.length  // True if we match every filter provided
}

/**
 * Can encode nested object as flat query string which can be decoded by queryStringToObject
 * e.g. {a: 1, b: {x: 2, y: 3}, c: [4, 5]} becomes a=1&b.x=2&b.y=3&c.0=4&c.1=5
 */
const objectToQueryString = (obj, parentKey = '') => {
    const keyValuePairs = []

    for (const [key, value] of Object.entries(obj)) {
        const fullKey = parentKey ? `${parentKey}.${key}` : key

        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                if (typeof value[i] === 'object' && value[i] !== null) {
                    keyValuePairs.push(objectToQueryString(value[i], `${fullKey}.${i}`))
                } else {
                    keyValuePairs.push(encodeURIComponent(`${fullKey}.${i}`) + '=' + encodeURIComponent(value[i]))
                }
            }
        } else if (typeof value === 'object' && value !== null) {
            keyValuePairs.push(objectToQueryString(value, fullKey))
        } else {
            keyValuePairs.push(encodeURIComponent(fullKey) + '=' + encodeURIComponent(value))
        }
    }

    return keyValuePairs.join('&')
}

/**
 * Can decode nested object from flat query string created by objectToQueryString
 */
const queryStringToObject = (queryString) => {
    const params = new URLSearchParams(queryString);
    const obj = {}

    params.forEach((value, key) => {
        const keys = key.split('.')
        let currentObj = obj;

        for (let i = 0; i < keys.length; i++) {
            const keyPart = keys[i]

            if (!currentObj[keyPart]) {
                if (i == keys.length - 1) {
                    currentObj[keyPart] = decodeURIComponent(value)
                } else {
                    let nextKey = keys[i+1]
                    currentObj[keyPart] = isNaN(nextKey) ? {} : []
                }
            }

            currentObj = currentObj[keyPart]
        }
    })

    return obj
}

/**
 * Can encode nested object as flat query params object which can be decoded by queryParamsToObject
 * e.g. {a: 1, b: {x: 2, y: 3}, c: [4, 5]} becomes {a: 1, b.x: 2, b.y: 3, c.0: 4, c.1: 5}
 */
const objectToQueryParams = (obj, parentKey = '') => {
    let queryParams = {}
    for (const [key, value] of Object.entries(obj)) {
        const fullKey = parentKey ? `${parentKey}.${key}` : key

        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                if (typeof value[i] === 'object' && value[i] !== null) {
                    const nested = objectToQueryParams(value[i], `${fullKey}.${i}`)
                    queryParams = { ...queryParams, ...nested }
                } else {
                    queryParams[`${fullKey}.${i}`] = value[i]
                }
            }
        } else if (typeof value === 'object' && value !== null) {
            const nested = objectToQueryParams(value, fullKey)
            queryParams = { ...queryParams, ...nested }
        } else {
            queryParams[fullKey] = value
        }
    }
    return queryParams
}


/**
 * Can decode nested object from flat query params object created by objectToQueryParams
 */
const queryParamsToObject = (queryParams) => {
    const obj = {}

    for (const [key, value] of Object.entries(queryParams)) {
        const keys = key.split('.')
        let currentObj = obj;

        for (let i = 0; i < keys.length; i++) {
            const keyPart = keys[i]

            if (!currentObj[keyPart]) {
                if (i == keys.length - 1) {
                    currentObj[keyPart] = value
                } else {
                    let nextKey = keys[i+1]
                    currentObj[keyPart] = isNaN(nextKey) ? {} : []
                }
            }

            currentObj = currentObj[keyPart]
        }
    }

    return obj
}

export default rowMatchesFilters

export {rowMatchesFilters, EDITORS, objectToQueryString, queryStringToObject, objectToQueryParams, queryParamsToObject}
