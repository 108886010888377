<template>
    <div>
        <div class="flex-body pb-2">
            <div>
                <div class="container-centered my-4">
                    <div class="row">
                        <div class="col-12 col-md-10 offset-md-1">
                            <router-link class="btn btn-link mb-2" :to="{name: 'employer-home', params: {hashedClientId: $hasher.encode(client.id)}}">&laquo; Back to Dashboard</router-link>

                            <h3>Allocate Tips: <span class="text-thin">Total Tips</span></h3>

                            <p class="">
                                Enter the amount of total tips from the pay period for each location below.
                            </p>

                            <form @submit.prevent="saveStoreTips" novalidate class="mt-2" v-if="dataLoaded">
                                <div class="alert alert-warning" v-for="w in taskDetail.warnings">
                                    {{ w }}
                                </div>

                                <form-errors :errors="formErrors" :errorsHtml="formErrorsHtml" />

                                <table class="table css-table">
                                    <thead>
                                        <tr>
                                            <th>Location Name</th>
                                            <th>Total Tips For Pay Period</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(store_detail, store_name) in taskDetail.store_data">
                                            <td class="text-left">
                                                {{ store_name }}
                                            </td>
                                            <td>
                                                <div class="bordered-prefixed-input-container">
                                                    <form-input v-model="formData.tips_by_store[store_name]" type="currency" extraclasses="bordered-prefixed-input mb-0" containercoldivclasses="mui-textfield mui-textfield-flipped mb-1" />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="row my-2 d-flex justify-content-between">
                                    <div class="col">
                                        <button type="button" class="btn btn-block btn-outline-primary" @click="goBack()">Back</button>
                                    </div>
                                    <div class="col">
                                        <button type="submit" class="btn btn-block btn-primary">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import FastTable from '@/components/FastTable'

import moment from 'moment'

export default {
    props: ['client', ],
    components: {FastTable, },
    computed: {
        taskId() {
            return this.$route.params.taskId
        },
    },
    data() {
        return {
            dataLoaded: false,
            taskDetail: null,
            formErrors: [],
            formErrorsHtml: [],
            formData: this.makeFormData(),
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tips Allocator')
        this.getInitialData()
    },
    methods: {
        makeFormData() {
            return {
                tips_by_store: {},
            }
        },
        saveStoreTips() {
            const url = `/clients/${this.client.id}/tips-allocator/${this.$route.params.taskId}/store-tips`

            this.$store.dispatch('START_LOADING')
            return this.$api.post(url, this.formData).then(resp => {
                let taskId = resp['task']['id']
                this.$router.push({name: 'tips-allocator-employees', params: {hashedClientId: this.$route.params.hashedClientId, taskId: taskId}});
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        getInitialData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            const url = `/clients/${this.client.id}/tips-allocator/${this.taskId}/detail`

            this.$api.get(url).then(resp => {
                this.taskDetail = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')

                // if there were errors, to go end
                if (this.taskDetail.errors.length) {
                    this.$router.push({name: 'tips-allocator-error', params: {hashedClientId: this.$route.params.hashedClientId, taskId: this.taskId}});
                }

                for (let [store_key, store_obj] of Object.entries(this.taskDetail.store_data)) {
                    this.formData.tips_by_store[store_key] = store_obj['total_tips']
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrorsHtml = []
            this.formData = this.makeFormData()
        },
        goBack: function() {
            this.$router.push({name: 'tips-allocator', params: {hashedClientId: this.$route.params.hashedClientId}});
        }
    },
}
</script>
