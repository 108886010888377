<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">E-Verify: Training Required</h2>

                        <div class="my-2">
                            <router-link :to="{name: 'e-verify', params: {hashedClientId: $hasher.encode(client.id)}}">&laquo; Back to E-Verify</router-link>
                        </div>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    This training, required by the Department of Homeland Security, covers important information about how to use E-Verify and remain compliant with E-Verify procedures
                                    as well as federal law.
                                </p>

                                <p>
                                    E-Verify is an Internet-based system that compares information entered by an employer from an employee’s Form I-9, Employment Eligibility Verification, to records available to the U.S. Department of Homeland Security and the Social Security Administration to confirm employment eligibility.
                                </p>

                                <p>
                                    GetMyPayStub has an integration with E-Verify that allows you to create and manage cases without having a separate login
                                    to E-Verify.
                                </p>

                                <p>
                                    <b>There will be a knowledge test at the end.</b> Users must pass the knowledge test with a score of <b>at least 70%</b> before using E-Verify.
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <router-link class="btn btn-primary" :to="{name: 'training-e-verify-page1'}">Continue to Training</router-link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['client', ],
    components: {},
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
