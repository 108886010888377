<template>
    <div>
        <div class="flex-page-content flex-container body-gray">
            <div class="flex-body body-scroll">

                <div class="container mx-auto">

                    <div class="py-4">
                        <h1 class="mb-2">{{ client.name }}</h1>

                        <heads-up-banner :client="client" headsup="enhanced-ess"/>

                        <employer-scoreboard :client="client" />

                        <div class="row">
                            <div class="col-12 col-lg-9">
                                <!-- Account Features -->
                                <div>
                                    <h4 class="mb-2">
                                        Account Features
                                    </h4>
                                    <div class="form-row pr-lg-3 pr-xl-2">

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <router-link
                                                :to="{name: 'onboarding', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--upgrade': !client.has_paperless_onboarding, 'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.ONBOARDING_ROLES)}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="tile-badge" v-if="client.num_onboarding_pending_approval">
                                                          {{ client.num_onboarding_pending_approval }}
                                                        </span>
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-user-check"></i></span>
                                                        <div class="module-tile-label">Onboarding Employees</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <router-link
                                                :to="{name: 'self-service-access', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{
                                                    'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.SELF_SERVICE_ACCESS_ROLES),
                                                    'module-tile--upgrade': enhancedESSUpgradeAvailable,
                                                }"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-users"></i></span>
                                                        <div class="module-tile-label">Self-Service Access</div>
                                                        <div v-if="enhancedESSUpgradeAvailable" class="module-tile-meta">
                                                            <span>Upgrade Available!</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <router-link
                                                :to="{name: 'warnings', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.WARNINGS_ROLES)}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-exclamation-triangle"></i></span>
                                                        <div class="module-tile-label">Warnings</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <router-link
                                                :to="{name: 'hr-documents', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.HR_DOCUMENTS_ROLES)}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-copy"></i></span>
                                                        <div class="module-tile-label">HR Documents</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <router-link
                                                :to="{name: 'paychecks', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.PAYCHECKS_ROLES)}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-money-check-alt"></i></span>
                                                        <div class="module-tile-label">Pay Stubs</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <router-link
                                                :to="{name: 'employee-w2s', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.EMPLOYEE_TAX_DOCUMENTS_ROLES)}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-file-invoice-dollar"></i></span>
                                                        <div class="module-tile-label">Employee Tax Documents</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <router-link
                                                :to="{name: 'tax-forms', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.COMPANY_TAX_DOCUMENTS_ROLES)}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-folder"></i></span>
                                                        <div class="module-tile-label">Company Tax Documents</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <router-link
                                                :to="{name: 'changelogs', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.CHANGE_HISTORY_ROLES)}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-history"></i></span>
                                                        <div class="module-tile-label">Change History</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, $store.state.security_roles.POS_ROLES)">
                                            <a href="#" @click.prevent="goToPOS()" class="module-tile" :class="{'module-tile--inactive module-tile--upgrade': !client.has_pos_integration || !$permissions.hasRoles(client.id, $store.state.security_roles.POS_ROLES)}">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-cogs"></i>
                                                        </span>

                                                        <div class="module-tile-label">Point of Sale Integration</div>
                                                        <div class="module-tile-meta" v-if="!client.has_pos_integration">
                                                            <span>Upgrade</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <!-- For now, only show the E-Verify tile if they actually have it -->
                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="hasEVerify && $permissions.hasRoles(client.id, $store.state.security_roles.EVERIFY_ROLES)">
                                            <router-link
                                                :to="{name: 'e-verify', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--upgrade': !hasEVerify}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="tile-badge" v-if="client.num_everify_pending_employer">
                                                          {{ client.num_everify_pending_employer }}
                                                        </span>

                                                        <span class="tile-badge alert-badge" v-if="eVerifyAccess == 'blocked'">
                                                          <i class="fas fa-exclamation"></i>
                                                        </span>

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-id-badge"></i>
                                                        </span>

                                                        <div class="module-tile-label">E-Verify</div>
                                                        <div class="module-tile-meta" v-if="!hasEVerify">
                                                            <span>Upgrade</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="hasCovidTile">
                                            <router-link
                                                :to="{name: 'covid', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.COVID_ROLES)}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">
                                                        <span class="module-tile-icon"><i class="fal fa-fw fa-virus"></i></span>
                                                        <div class="module-tile-label">COVID</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>


                                        <!--
                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3">
                                            <div class="module-tile module-tile--inactive module-tile--comingsoon">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-flag"></i>
                                                        </span>

                                                        <div class="module-tile-label">WOTC</div>
                                                        <div class="module-tile-meta">
                                                            <span>Coming Soon</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        -->

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="client.has_tips_allocator && $permissions.hasRoles(client.id, $store.state.security_roles.POS_ROLES)">
                                            <router-link
                                                :to="{name: 'tips-allocator', params: {hashedClientId: $hasher.encode(client.id)}}" class="module-tile">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-money-bill-wave"></i>
                                                        </span>

                                                        <div class="module-tile-label">Tips Allocator</div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>

                                        <div class="col-12 col-ss-6 col-sm-4 col-xl-3" v-if="$permissions.hasRoles(client.id, $store.state.security_roles.SETTINGS_ROLES)">
                                            <router-link
                                                :to="{name: 'settings', params: {hashedClientId: $hasher.encode(client.id)}}"
                                                class="module-tile"
                                                :class="{'module-tile--inactive module-tile--comingsoon': !$permissions.hasRoles(client.id, $store.state.security_roles.SETTINGS_ROLES)}"
                                            >
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-tools"></i>
                                                        </span>

                                                        <div class="module-tile-label">Settings</div>

                                                    </div>
                                                </div>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3">
                                <div class="pt-2 pt-lg-0 pl-xl-2">
                                    <!-- Companies List -->
                                    <companies-list :companies="client.companies" :is-client="true" />
                                    <!-- Promo Tile -->
                                    <promo-tile class="promo-tile-tall-lg"></promo-tile>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import EmployerScoreboard from '@/components/EmployerScoreboard/EmployerScoreboard'
import CompaniesList from '@/components/CompaniesList'
import PromoTile from '@/components/PromoTile'
import HeadsUpBanner from '@/components/HeadsUpBanner'

export default {
    props: ['client'],
    components: {EmployerScoreboard, CompaniesList, PromoTile, HeadsUpBanner, },
    computed: {
        hasCovidTile() {
            return this.client.companies.filter(c => c.feature_covid_vaccination_status_reporting).length > 0
        },
        hasEVerify() {
            return this.client.companies.filter(c => c.everify_enabled).length > 0
        },
        eVerifyAccess() {
            const userSP = this.$permissions.getSecurityProfile(this.client.id)
            if (userSP) {
                return userSP.everify_access
            }

            return ''
        },
        enhancedESSUpgradeAvailable() {
            if (!(this.$store.state.system_flags && this.$store.state.system_flags['enhanced_ess_released'])) {
                return false
            }
            if (!this.client.has_enhanced_ess) {
                return true
            } else {
                for (let c of this.client.companies) {
                    if (!c.has_enhanced_ess) {
                        return true
                    }
                }
            }
            return false
        },
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Home')
        this.$store.dispatch('GET_USER')

        this.$api.post('/me/last-viewed', {client_id: this.client.id})
    },
    methods: {
        goToPOS() {
            if (! this.client.has_pos_integration) {
                return
            }

            if (this.client.pos_use_new_ui) {
                this.$router.push({name: 'pos-integration', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                return
            }

            if (this.client.demo_custom_pos_integration_url) {
                window.location.href = this.client.demo_custom_pos_integration_url
                return
            }

            let pos_app_url = `${this.$delta.POS_APP_URL}/employer/client/${this.client.id}/hours-import/`
            window.location.href = pos_app_url
        }
    }
}
</script>

<style scoped lang="scss">
.card {
    /* width: 15rem; Removing for now, I think this is unneeded. */
}

.module-tile--inactive {
    pointer-events: none;
}
</style>
