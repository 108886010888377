<template>
    <div>
        <div class="flex-body pb-2">
            <div>
                <div class="mx-auto d-flex flex-column justify-content-center">
                    <!-- Summary container -->
                    <div class="py-2">
                        <p class="lead text-semibold">
                            The following data will be imported into payroll:
                        </p>
                        <!-- Big metrics -->
                        <div class="pb-4">
                            <div class="d-flex flex-wrap">
                                <div class="mr-8">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_employees_for_payroll }}
                                    </div>
                                    <div class="theading">
                                        Employees
                                    </div>
                                </div>
                                <div class="mr-8">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_hours_reg }}
                                    </div>
                                    <div class="theading">
                                        Regular Hours
                                    </div>
                                </div>
                                <div class="mr-8">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_hours_ot }}
                                    </div>
                                    <div class="theading">
                                        Overtime Hours
                                    </div>
                                </div>
                                <div class="mr-8" v-if="this.taskDetail.payroll_summary_stats.total_hours_other">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_hours_other }}
                                    </div>
                                    <div class="theading">
                                        Other Hours
                                    </div>
                                </div>
                                <div class="mr-8" v-if="this.taskDetail.payroll_summary_stats.total_other_earnings">
                                    <div class="text-display1">
                                        ${{ this.taskDetail.payroll_summary_stats.total_other_earnings }}
                                    </div>
                                    <div class="theading">
                                        Other Earnings
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Smaller summary statements -->
                        <p class="lead mb-hf" v-if="this.taskDetail.skipped_data_summary.num_employees">
                            <i aria-hidden="true" class="fas fa-fw fa-exclamation-triangle text-warning mr-1"></i> 
                            <strong class="text-bold text-warning-dark">
                                {{ this.taskDetail.skipped_data_summary.num_employees }}
                            </strong> employees will be skipped. 
                            <strong class="text-semibold">
                                {{ this.taskDetail.skipped_data_summary.total_hours_reg }}
                            </strong> regular and
                            <strong class="text-semibold">
                                {{ this.taskDetail.skipped_data_summary.total_hours_ot }}
                            </strong> OT
                            hours will be skipped.
                        </p>
                    </div>
                    <!-- END Summary container -->

                    <!-- Company/Location breakdown -->
                    <div class="py-1">
                        <div class="table-section tr-hasissue">
                            <div class="py-1 border-top">
                                <div class="d-flex pb-1">
                                    <div class="flex-grow-1 position-relative">
                                        <!-- Company Table -->
                                        <div class="pt-1">
                                            <div class="table css-table table-no-borders table-anchor table-anchor--shorttext">
                                                <!-- Table Headings Row -->
                                                <div class="tr-wrapper">
                                                    <div class="tr th-row">
                                                        <div class="th anchor-col">
                                                            <span class="cell-truncate">
                                                                Company
                                                            </span>
                                                        </div>
                                                        <div class="th cell-warnings">
                                                            <span class="cell-truncate">
                                                                Issues
                                                            </span>
                                                        </div>
                                                        <div class="th cell-narrow cell-right flex-grow-1">
                                                            <span class="cell-truncate">
                                                                Employees
                                                            </span>
                                                        </div>
                                                        <div class="th cell-narrow cell-right flex-grow-1">
                                                            <span class="cell-truncate">
                                                                Regular Hours
                                                            </span>
                                                        </div>
                                                        <div class="th cell-narrow cell-right flex-grow-1">
                                                            <span class="cell-truncate">
                                                                Overtime Hours
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="tr-wrapper" v-for="company_detail in this.taskDetail.payroll_summary_by_company_location">
                                                    <div class="tr td-row display3-row">
                                                        <div class="td anchor-col">
                                                            <span class="text-large text-semibold cell-truncate">
                                                                <a href class="collapse-trigger" role="button" :class="{'is-expanded': companiesExpanded[company_detail.CompanyCode]}" @click.prevent="companiesExpanded[company_detail.CompanyCode] = !companiesExpanded[company_detail.CompanyCode]">
                                                                        <span class="widget-toggle-wrapper">
                                                                            <collapse-toggle-button />
                                                                        </span>
                                                                </a>

                                                                {{ company_detail.CompanyName }}
                                                            </span>
                                                        </div>
                                                        <div class="td cell-warnings">
                                                            <span class="counter counter-warnings" v-if="company_detail.num_issues">
                                                                <i aria-hidden="true" class="counter-icon fas fa-fw fa-exclamation-triangle"></i>
                                                                <span class="counter-label">
                                                                    {{ company_detail.num_issues }}
                                                                </span>
                                                                <span class="sr-only">warnings</span>
                                                            </span>
                                                        </div>
                                                        <div class="td cell-narrow cell-right flex-grow-1">
                                                            <span class="text-display3 cell-truncate">
                                                                {{ company_detail.num_employees }}
                                                            </span>
                                                        </div>
                                                        <div class="td cell-narrow cell-right flex-grow-1">
                                                            <span class="text-display3 cell-truncate">
                                                                {{ company_detail.total_hours_reg }}
                                                            </span>
                                                        </div>
                                                        <div class="td cell-narrow cell-right flex-grow-1">
                                                            <span class="text-display3 cell-truncate">
                                                                {{ company_detail.total_hours_ot }}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <!-- Locations Table -->
                                                    <div class="collapse" v-for="location_detail in company_detail.locations" :class="{'show': companiesExpanded[company_detail.CompanyCode]}">
                                                        <div class="table css-table table-thin table-no-borders table-anchor table-anchor--shorttext">
                                                            <div class="tr-wrapper">
                                                                <div class="tr td-row">
                                                                    <div class="td anchor-col">
                                                                        <span class="cell-truncate ml-4">
                                                                            {{ location_detail.Store }}
                                                                            ({{ location_detail.WorkLocationName }})
                                                                        </span>
                                                                    </div>
                                                                    <div class="td cell-warnings">
                                                                        <span class="counter counter-warnings" v-if="location_detail.num_issues">
                                                                            <i aria-hidden="true" class="counter-icon fas fa-fw fa-exclamation-triangle"></i>
                                                                            <span class="counter-label">
                                                                                {{ location_detail.num_issues }}
                                                                            </span>
                                                                            <span class="sr-only">warnings</span>
                                                                        </span>
                                                                    </div>
                                                                    <div class="td cell-narrow cell-right flex-grow-1">
                                                                        <span class="cell-truncate">
                                                                            {{ location_detail.num_employees }}
                                                                        </span>
                                                                    </div>
                                                                    <div class="td cell-narrow cell-right flex-grow-1">
                                                                        <span class="cell-truncate">
                                                                            {{ location_detail.total_hours_reg }}
                                                                        </span>
                                                                    </div>
                                                                    <div class="td cell-narrow cell-right flex-grow-1">
                                                                        <span class="cell-truncate">
                                                                            {{ location_detail.total_hours_ot }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- /Locations Table -->

                                                </div>
                                            </div>
                                        </div>
                                        <!-- /Company Table -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END Company/Location breakdown -->

                    <div class="py-2 border-top">
                        <div class="row row-alt">
                            <div class="col-6">
                                <router-link class="btn btn-outline-primary" :to="{name: 'pos-integration-review', params: {hashedClientId: this.$route.params.hashedClientId, taskId: this.$route.params.taskId}}">Back</router-link>
                            </div>
                            <div class="col-6 text-right">
                                <p>
                                    <a class="btn btn-primary" href @click.prevent="finishPOSImport">
                                        Continue
                                    </a>
                                </p>
                            </div>
                        </div>

                        <p class="mb-0 text-right" v-if="this.taskDetail.skipped_data_summary.num_employees">
                            <i aria-hidden="true" class="fas fa-exclamation-triangle text-warning mr-hf"></i>
                            <strong class="text-bold text-warning-dark">
                                {{ this.taskDetail.skipped_data_summary.num_employees }}
                            </strong> employees will be skipped.
                            <strong class="text-bold text-warning-dark">
                                {{ this.taskDetail.skipped_data_summary.total_hours_reg }}
                            </strong> regular and
                            <strong class="text-bold text-warning-dark">
                                {{ this.taskDetail.skipped_data_summary.total_hours_ot }}
                            </strong> OT hours will be skipped.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import FastTable from '@/components/FastTable'

import moment from 'moment'

export default {
    props: ['client', 'taskDetail'],
    components: {FastTable, },
    computed: {
    },
    data() {
        const companiesExpanded = {}

        for (const [key, value] of Object.entries(this.taskDetail.payroll_summary_by_company_location)) {
            companiesExpanded[value['CompanyCode']] = false
        }

        return {
            companiesExpanded: companiesExpanded,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Point-of-Sale Integration')
    },
    methods: {
        finishPOSImport() {
            const url = `/clients/${this.client.id}/pos-integration/${this.$route.params.taskId}/finish`

            return this.$api.post(url, {}).then(resp => {
                let taskId = resp['task']['id']
                this.$router.push({name: 'pos-integration-status', params: {hashedClientId: this.$route.params.hashedClientId, taskId: taskId}});
            }).catch(errors => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.mode = 'setup'
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>
