<template>
<div>
<sidebar-widget
    @add="modalOpen = true"
    @delete="onDelete($event)"
    @edit="currentRowId = $event; modalOpen = true"
    @versions="currentRowId = $event; versionsModalIsOpen = true"
    :rows="rows"
    :headers="['Filing Status', 'Formula']"
>
    <template v-slot:title>Exemptions</template>

    <template v-slot:row-details scope="props">
        <tax-exemption-version :version="props.row.current_version" />
    </template>
</sidebar-widget>

<edit-modal
    v-if="modalOpen"
    :tax="tax"
    :tax-exemption="currentRow"
    :exemption-formulas="exemptionFormulas"
    @updated="$emit('updated')"
    @close="modalOpen = false; currentRowId = null"

/>

<versions-modal
    v-if="versionsModalIsOpen"
    :url="`/admin/tax-engine/taxes/${tax.id}/tax-exemptions/${currentRowId}`"
    @close="currentRowId = null; versionsModalIsOpen = false"
>
    <template v-slot:version scope="props">
        <tax-exemption-version :version="props.version" />
    </template>
</versions-modal>

</div>
</template>

<script>
import EditModal from './TaxExemptionModal'
import SidebarWidget from './SidebarWidget'
import TaxExemptionVersion from './TaxExemptionVersion'
import VersionsModal from './VersionsModal'

export default {
    props: ['tax', 'exemptionFormulas', ],
    components: {EditModal, SidebarWidget, TaxExemptionVersion, VersionsModal, },
    computed: {
        currentRow() {
            return this.tax.exemptions.find(x => x.id == this.currentRowId)
        },
        rows() {
            const result = []
            this.tax.exemptions.forEach(row => {
                row = JSON.parse(JSON.stringify(row))
                const formula = this.exemptionFormulas.find(x => x.id == row.current_version.formula_id)
                row.cells = [
                    row.filing_status ? row.filing_status.name : 'N/A',
                    formula ? formula.name : '',
                ]
                result.push(row)
            })
            return result
        },
    },
    data() {
        return {
            modalOpen: false,
            versionsModalIsOpen: false,
            currentRowId: null,
        }
    },
    methods: {
        getURL(rowId) {
            if (!rowId) {return}
            return `/admin/tax-engine/taxes/${this.tax.id}/tax-exemptions/${rowId}`
        },
        onDelete(rowId) {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(this.getURL(rowId)).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>


