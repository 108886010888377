<template>
    <modal @close="$refs.formComponent.reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">
        <template v-slot:title>Add Employee</template>

        <slot>
            <onboarding-app-form
                :client="client"
                ref="formComponent"
                @payroll_onboarding_applications_updated="onApplicationsUpdated($event)"
                @scrollbottom="scrollBottom()"
                @pristine_status="isCloseable = $event"
                @form_errors="formErrors = $event"
                :hide-form-errors="true"
            />
        </slot>
        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close(true)">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit">Add Employee</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import OnboardingAppForm from '@/components/EmployeeSidebar/OnboardingAppForm'

export default {
    mixins: [ModalMixin],
    components: {OnboardingAppForm, },
    props: ['client'],
    data() {
        return {
            formErrors: [],
        }
    },
    watch: {
        client() {
            this.$refs.formComponent.reset()
        }
    },
    methods: {
        onSubmit(evt) {
            this.$refs.formComponent.onSubmit(evt)
        },
        onApplicationsUpdated(app) {
            this.$emit('payroll_onboarding_applications_updated', app)
        },
    }
}
</script>
