<template>
    <page>
        <slot name="alert"></slot>
        <div class="nav-tabs-container pt-1" id="tabsPageNav">
            <div class="nav-tabs-scroll-helper">
                <ul class="nav nav-tabs">

                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': mode == 'main'}" href id="tabLink1" role="tab" aria-controls="tabContent1" :aria-selected="mode == 'main'" @click.prevent="setMode('main')">
                            Main
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': mode == 'warnings'}" href id="tabLink2" role="tab" aria-controls="tabContent2" :aria-selected="mode == 'warnings'" @click.prevent="setMode('warnings')">
                            Warnings
                        </a>
                    </li>

                    <li class="nav-item" v-if="$permissions.hasRoles(client.id, $store.state.security_roles.CHANGE_HISTORY_ROLES)">
                        <a class="nav-link" :class="{'active': mode == 'changelogs'}" href id="tabLink3" role="tab" aria-controls="tabContent3" :aria-selected="mode == 'changelogs'" @click.prevent="setMode('changelogs')">
                            Change History
                        </a>
                    </li>

                </ul>
            </div>
        </div>

        <div class="flex-body flex-container tab-content">
            <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'main'}" id="tabContent1" role="tabpanel" aria-labelledby="tabLink1">
                <slot></slot>
            </div>

            <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'warnings'}" id="tabContent2" role="tabpanel" aria-labelledby="tabLink2">
                <warnings-table v-if="mode == 'warnings'" :client="client" :warnings="warnings" @updated="$emit('warnings_updated', $event)" @date_filter_change="$emit('warnings_date_filter_change', $event)" @warning_click="$emit('warning_click', $event)"  :disable-sidebar="disableWarningSidebar" :show-employee-columns="showEmployeeColumnsInWarnings"/>
            </div>

            <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'changelogs'}" id="tabContent3" role="tabpanel" aria-labelledby="tabLink3" v-if="$permissions.hasRoles(client.id, $store.state.security_roles.CHANGE_HISTORY_ROLES)">
                <history-log-table
                    v-if="mode == 'changelogs'"
                    :client="client"
                    :changelogs="changelogs"
                    :disable-sidebar="disableChangelogSidebar"
                    :email-column-label="emailColumnLabel"
                    :hide-record-type-column="true"
                    :hide-employee-ssn-column="hideEmployeeSsnColumn"
                    :hide-email-column="hideEmailColumn"
                    :hide-tracked-object-name-column="hideTrackedObjectNameColumn"
                    @date_filter_change="$emit('changelog_date_filter_change', $event)"
                    @changelog_click="$emit('changelog_click', $event)"
                    @updated="$emit('updated', $event)"
                />
            </div>
        </div>

    </page>
</template>

<script>
import Page from './Page'
import WarningsTable from '@/components/WarningsTable'
import HistoryLogTable from '@/components/HistoryLogTable'

export default {
    components: {Page, WarningsTable, HistoryLogTable, },
    props: {
        'client': {required: true},
        'warnings': {required: true},
        'changelogs': {required: true},
        'disableChangelogSidebar': {type: Boolean, default: false},
        'disableWarningSidebar': {type: Boolean, default: false},
        'showEmployeeColumnsInWarnings': {type: Boolean, default: true},
        'hideEmployeeSsnColumn': {type: Boolean, default: false},
        'hideEmailColumn': {type: Boolean, default: false},
        'hideTrackedObjectNameColumn': {type: Boolean, default: false},
        'emailColumnLabel': {type: String, default: 'Employee Email'},
    },
    data() {
        return {
            mode: 'main',
        }
    },
    methods: {
        setMode(mode) {
            this.mode = mode
            this.$bus.$emit('resize')
            this.$bus.$emit('close_sidebar')
        },
    }
}
</script>
