<template>
    <div class="card mb-1">
        <div class="card-body">
            <form-errors :errors="formErrors"/>

            <div class="form-row">
                <div class="col">
                    <form-input label="Type" v-model="formData.vaccine_name" :errors="errors.vaccine_name" type="select" @update:model-value="onInput" :options="vaccineOptions"/>
                </div>
            </div>

            <div class="form-row" v-if="formData.vaccine_name == 'Other'">
                <div class="col">
                    <form-input label="Type (other)" v-model="formData.vaccine_name_other" :errors="errors.vaccine_name_other" type="text" @update:model-value="onInput" />
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <form-input label="Date Administered" v-model="formData.administered_on" :errors="errors.administered_on" type="date" @update:model-value="onInput"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <form-input label="Location Administered" v-model="formData.location" :errors="errors.location" type="text" @update:model-value="onInput" placeholder=""/>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import moment from 'moment'

export default {
    mixins: [FormMixin],
    props: ['instance', ],
    computed: {
        vaccineOptions() {
            return [
                {text: "Pfizer", value: "Pfizer"},
                {text: "Moderna", value: "Moderna"},
                {text: "Johnson & Johnson", value: "Johnson & Johnson"},
                {text: "Other", value: "Other"},
            ]
        },
    },
    data() {
        return {
            formLabels: {
                administered_on: 'Date Administered',
            },
            formRules: {
                location: {presence: {allowEmpty: false},},
                vaccine_name: {presence: {allowEmpty: false},},
                vaccine_name_other: {presence: {allowEmpty: false},},
                administered_on: {presence: {allowEmpty: false}, date: {latest: moment(), tooLate: "can't be in the future."}},
            },
            formData: this.makeFormData()
        }
    },
    watch: {
        instance() {
            this.reset()
        },
    },
    methods: {
        makeFormData() {
            if (this.instance) {
                return {
                    id: this.instance.id,
                    location: this.instance.location,
                    administered_on: this.instance.administered_on,
                    vaccine_name: this.instance.vaccine_name,
                    vaccine_name_other: this.instance.vaccine_name_other,
                }
            }
            else {
                return {
                    location: '',
                    administered_on: '',
                    vaccine_name: '',
                    vaccine_name_other: '',
                }
            }
        },
        getFormRules() {
            const rules = Object.assign({}, this.formRules)
            if (this.formData.vaccine_name != 'Other') {
                delete rules.vaccine_name_other
            }

            return rules
        },
        onInput() {
            this.$nextTick(() => {
                this.$emit('update', this.getFormData())
            })
        },
    },
}
</script>
