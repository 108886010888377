<template>
    <page>
        <fast-table
            v-if="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentEarningTypeSlug = $event.slug"
        >
            <template v-slot:table-actions>
                <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="currentEarningTypeSlug = null; addModalOpen = true">
                    <i class="far fa-fw fa-plus"></i>
                    <span class="btn-text">Add Earning Type</span>
                </button>
            </template>
        </fast-table>

        <sidebar
            :earning-type="currentEarningType"
            v-if="currentEarningTypeSlug"
            @updated="onUpdate($event)"
            @deleted="currentEarningTypeSlug = null; getData()"
            @close="currentEarningTypeSlug = null"
        />
        <add-modal v-if="addModalOpen" @updated="getData()" @close="addModalOpen = false" />

    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import AddModal from './components/EarningTypeModal'
import Sidebar from './components/EarningTypeSidebar'

export default {
    components: {FastTable, Page, Sidebar, AddModal, },
    computed: {
        currentEarningType() {
            return this.earningTypes.find(e => e.slug == this.currentEarningTypeSlug)
        },
        future() {
            return this.earningTypes.filter(e => e.current_version.is_future)
        },
        active() {
            return this.earningTypes.filter(e => e.current_version.is_active)
        },
        inactive() {
            return this.earningTypes.filter(e => e.current_version.is_past)
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.future,
                this.active,
                this.inactive,
            ]

            subsets.forEach(earningTypes => {
                const sectionData = []

                earningTypes.forEach((e, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentEarningTypeSlug && e.id == this.currentEarningTypeSlug,
                        object: e,
                        cells: [
                            e.name,
                            e.current_version.type_pretty,
                            e.has_been_used ? 'Yes' : 'No',
                            e.current_version.version,
                            e.current_version.has_tip_credit_claimed,
                            e.current_version.is_tips,
                            e.current_version.is_work_suffered,
                            e.current_version.is_virtual,
                            e.current_version.starts_on,
                            e.current_version.ends_on,
                        ],
                    }

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            earningTypes: [],
            dataLoaded: false,
            currentEarningTypeSlug: null,
            addModalOpen: false,

            headers: [
                {label: 'Earning Type', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Type', classes: 'cw-5', isFilterable: true},
                {label: 'Has Been Used', classes: 'cw-5', isFilterable: true},
                {label: 'Version', classes: 'cw-5', isFilterable: false},
                {label: 'Has Tip Credit', classes: 'cw-5', type: 'checkmark', isFilterable: true},
                {label: 'Tips', classes: 'cw-11', type: 'checkmark', isFilterable: true},
                {label: 'Is Work Suffered', classes: 'cw-5', type: 'checkmark', isFilterable: true},
                {label: 'Is Virtual', classes: 'cw-5', type: 'checkmark', isFilterable: true},
                {label: 'Starts On', classes: 'cw-5', type: 'date'},
                {label: 'Ends On', classes: 'cw-5', type: 'date'},
            ],
            sections: [
                {title: 'Future', defaultIsClosed: false, id: 1},
                {title: 'Current', defaultIsClosed: false, id: 2},
                {title: 'Past', defaultIsClosed: true, id: 3},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / Earning Types')
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/earning-types`).then(resp => {
                this.earningTypes = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(earningType) {
            if (!earningType) {
                return this.getData()
            }
            const existing = this.earningTypes.find(x => x.slug == earningType.slug)
            if (existing) {
                Object.assign(existing, earningType)
            }
            else {this.getData()}
        },
    },
}

</script>
