<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template v-slot:title>
            <span v-if="city">Update City: </span><span v-if="city" class="text-thin text-nowrap">{{ city.name }}</span>
            <span v-if="!city">Add New City</span>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <form-errors :errors="formErrors"/>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.state" :errors="errors.state" type="select" label="State" :options="stateOptions" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.alternative_names" :errors="errors.alternative_names" type="textarea" label="Alternative Names" />
                    </div>
                </div>

                <h4>Reciprocal Cities</h4>
                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.reciprocal_agreement_cities[s.id]" v-for="s in allCities" type="checkbox" :label="s.name + ', ' + s.state.name" :key="s.id" />
                    </div>
                </div>
            </form>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['city', 'allCities', ],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        cityOptions() {
            const opts = []
            this.allCities.forEach(c => {
                if (!this.city || (c.id != this.city.id)) {
                    opts.push({text: c.name, value: c.id})
                }
            })
            return opts
        },
        stateOptions() {
            const opts = []
            this.states.forEach(s => {
                opts.push({text: s.name, value: s.id})
            })

            opts.sort((a, b) => {
                return a.text > b.text ? 1 : -1
            })
            return opts
        },
    },
    data() {
        return {
            states: [],
            formData: this.makeFormData(),
        }
    },
    mounted() {
        this.getStates()
    },
    methods: {
        makeFormData() {
            if (this.city) {
                let ras = {}
                this.city.reciprocal_agreement_cities.forEach(s => {
                    ras[s.id] = true
                })

                return {
                    name: this.city.name,
                    state: this.city.state.id,
                    reciprocal_agreement_cities: ras,
                    alternative_names: this.city.alternative_names.join('\n'),
                }
            } else {
                return {
                    name: '',
                    state: '',
                    reciprocal_agreement_cities: [],
                    alternative_names: '',
                }
            }
        },
        getStates() {
            this.$api.get(`/admin/tax-engine/states`).then(resp => {
                this.states = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__[0])
            })
        },
        getFormData() {
            return {
                'reciprocal_agreement_cities': Object.keys(this.formData.reciprocal_agreement_cities).filter(key => this.formData.reciprocal_agreement_cities[key]),
                name: this.formData.name,
                state: this.formData.state,
                alternative_names: this.formData.alternative_names,
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise
            if (this.city) {
                promise = this.$api.put(`/admin/tax-engine/cities/${this.city.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/admin/tax-engine/cities`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
