<template>
    <div>
        <nav-secondary :navOptions="navOptions"></nav-secondary>
        <router-view class="flex-page-content flex-container view" :client="client"/>
    </div>
</template>

<script>
import NavSecondary from '@/components/NavSecondary'

export default {
    components: { NavSecondary, },
    props: ['client', ],
    data() {
        const navOptions = [
            {'text': 'Vaccination Statuses', 'value': 'covid-vaccination-statuses', 'params': '{hashedClientId: $route.params.hashedClientId}}'},
            //{'text': 'COVID Testing', 'value': 'covid-vaccination-testing', 'params': '{hashedClientId: $route.params.hashedClientId}}'},
            //{'text': 'Vaccination Report', 'value': 'covid-vaccination-report', 'params': '{hashedClientId: $route.params.hashedClientId}}'},
        ]

        return {
            navOptions: navOptions
        }
    },
    mounted() {
        if (!this.client.companies.filter(c => c.feature_covid_vaccination_status_reporting).length) {
            this.$router.replace({name: 'employer-home', params: {hashedClientId: this.$route.params.hashedClientId}})
            return
        }

        const routes = this.navOptions.map(x => x.value)
        if (routes.indexOf(this.$route.name) < 0) {
            this.$router.replace({name: routes[0], params: {hashedClientId: this.$route.params.hashedClientId}})
        }
    },
}
</script>
