<template>
    <div v-show="company.id">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-m py-4">

                        <h5>Account Linking: {{ company.name }}</h5>
        
                        <div v-if="mode == 'invalid'">
                            <h3>Cannot Find Your Record</h3>
        
                            <p>We could not find a record to link you acocunt. Please start over.</p>
        
                            <p class="mt-2">
                                <router-link class="btn btn-link" :to="{name: 'index'}">Go Back</router-link>
                            </p>
        
                        </div>
        
                        <div v-if="mode == 'ssn'">
                            <h3>Validate SSN</h3>
        
                            <p>For security purposes, please enter your Social Security Number.</p>
                            <form @submit.prevent="onSSNSubmit" novalidate class="mui-form">
                                <form-errors :errors="formErrors"/>
        
                                <div class="form-row">
                                    <div class="col-12">
        
                                        <form-input placeholder="Social Security Number" v-model="ssnFormData.ssn" :errors="errors.ssn" type="ssn" />
                                    </div>
                                </div>
        
                                <p>
                                    <button type="submit" class="btn btn-primary">Continue</button>
                                </p>
                            </form>
                        </div>
        
                        <div v-if="mode == 'complete'">
                            <div class="pt-8 text-center">
                                <div class="icon-huge">
                                    <i class="fas fa-check"></i>
                                </div>

                                <h1>Company Linked</h1>
        
                                <p class="mt-4">
                                    <router-link class="btn btn-primary" :to="{name: 'index'}">Continue to Dashboard</router-link>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'

import api from '@/api'
import bus from '@/bus'

export default {
    components: {
        FormInput,
        FormErrors,
    },
    mixins: [FormMixin],
    mounted() {
        this.$store.dispatch('GET_USER')
        this.getCompany()
    },
    computed: {
        companyId() {
            return this.$hasher.decode(this.$route.params.hashedCompanyId)
        },
    },
    data() {
        return {
            company: {},
            mode: '',
            formLabels: {
                'ssn': 'Social Security Number',
            },
            ssnFormData: {
                ssn: '',
            },
            ssnFormRules: {
                ssn: {presence: {allowEmpty: false}, ssn: true},
            },
        }
    },
    methods: {
        getFormRules() {
            return this.ssnFormRules
        },
        getFormData() {
            return this.ssnFormData
        },
        onSSNSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/me/companies/${this.companyId}/link/ssn`, this.ssnFormData).then((resp) => {
                this.mode = 'complete'
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        getCompany() {
            this.$store.dispatch('START_LOADING')
            api.get(`/me/companies/${this.companyId}/link`).then((resp) => {
                this.company = resp.company
                this.mode = 'ssn'
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 404) {
                    this.mode = 'invalid'
                }
                else {
                    bus.showError(errors['__all__'][0])
                }
                this.$store.dispatch('STOP_LOADING')
            })
        }
    },
}
</script>
