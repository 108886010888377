<template>
    <div>
        <div class="modal-child-overlay">
            <div class="modal-child-overlay-inner">
                <div>
                    <span class="px-1" @click.prevent="$emit('close')">close</span>
                </div>

                <slot></slot>
            </div>
        </div>
    </div>
</template>

