<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                    <div class="container-centered py-4" v-show="companies">
                        <h1 class="mb-2 text-center">
                            Select a Company
                        </h1>
                        <div class="row">
                            <div class="col-12">
                                <div v-if="companies && !companies.length">
                                    <p>You do not have access to any companies. Contact your payroll administrator if you believe this is incorrect.</p>
                                </div>
                                <router-link v-for="company in companies" :to="{name: 'employee-company-home', params: {hashedCompanyId: $hasher.encode(company.id)}}" class="card mb-hf no-decoration" :key="company.id">
                                    <div class="card-body">
                                        <div class="form-row">
                                            <div class="col">
                                                <h4>{{ company.name }}</h4>
                                                <div class="text-muted text-small pt-qt">
                                                    <span>Last paid: </span>
                                                    <span v-if="lastPayDates && lastPayDates[company.id]">
                                                        {{ $filters.date(lastPayDates[company.id]) }}
                                                    </span>
                                                    <span v-else>Never</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="pendingLinkCompanyIds.includes(company.id)" class="card-footer border-0 bg-info-light d-flex">
                                        <div class="flex-grow-1 text-dark text-small">
                                            <i aria-hidden="true" class="fa-link fas fa-fw text-info"></i>
                                            <span class="text-semibold ml-hf">
                                                Set up your access to this company
                                            </span>
                                        </div>
                                        <div class="flex-grow-0 text-primary text-small">
                                            <i class="fas fa-fw fa-arrow-right"></i>
                                        </div>
                                    </div>

                                </router-link>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Tooltip from '@/components/Tooltip'

export default {
    props: [],
    components: {Tooltip, },
    computed: {
        state() {return this.$store.state},
        pendingLinkCompanyIds() {
            let result = []
            this.$store.state.user.employee_pending_link_companies.forEach((c) => {
                result.push(c.id)
            })

            return result
        },
        companies() {
            let result = []
            if (!this.$store.state.user) {
                return result
            }
            result = result.concat(this.$store.state.user.employee_companies)
            result = result.concat(this.$store.state.user.employee_onboarding_companies)
            result = result.concat(this.$store.state.user.employee_pending_approval_companies)
            result = result.concat(this.$store.state.user.employee_pending_link_companies)

            let seen = new Set()
            result = result.filter((c) => {
                if (seen.has(c.id)) {
                    return false
                }
                seen.add(c.id)
                return true
            })
            result.sort((a, b) => a.name > b.name ? 1 : -1)
            return result
        },
        inactiveCompanyIds() {
            const inactive = {}
            if (!this.$store.state.user) {return inactive}

            this.$store.state.user.employees.forEach(ee => {
                if (ee.employment_status != "Active") {
                    inactive[ee.company] = ee.company
                }
            })
            return inactive
        },
        lastPayDates() {
            const lastPayDates = {}
            if (!this.$store.state.user) {return lastPayDates}

            this.$store.state.user.employees.forEach(ee => {
                if (ee.last_pay_date) {
                    lastPayDates[ee.company] = ee.last_pay_date
                }
            })
            return lastPayDates
        },
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Home')
        this.$store.dispatch('GET_USER').then(() => {
            this.redirectIfNecessary()
            this.$api.post('/me/last-viewed', {ess: true})
            this.$api.get('/me/ess') // this is basically just so that we record in LoginSession
        }).catch((errors) => {
            this.$bus.showError(errors.__all__)
        })
    },
    methods: {
        redirectIfNecessary() {
            // no longer do this - always show them the list of companies
        },
    },
}
</script>
