<template>
<div>
    <p>Select a security role for this user:</p>

    <div class="row">
        <div class="col-12 col-md-6">
            <form-input
                v-model="formData.value"
                type="select"
                label="Role"
                :options="myRoleOptions"
                @update:model-value="onInput"
                :errors="errors"
            />
        </div>
    </div>

    <div class="mt-2">
        <p class="mb-1">
            <i class="far fa-fw fa-info-circle text-muted mr-hf"></i>
            <strong>Reference Guide:</strong> 
            Click to view detailed permissions for each role.
        </p>

        <!-- Small-screen version -->

        <div class="accordion mt-2 d-block d-lg-none" id="rolesAccordionSm">
            <div class="card" v-for="role in roleOptions" :key="role.value">
                <div class="card-header p-0" :id="'heading_' + role.value" :class="[{ 'role-item--selected': role.value == formData.value }, { 'role-item--disabled': !roleIsSelectable(role.value) }]">
                    <a href class="card-header-inner pr-2 collapse-trigger collapsed" role="button" data-toggle="collapse" :data-target="'#pane_' + role.value" aria-expanded="false" :aria-controls="'pane_' + role.value">
                        <span class="widget-toggle-wrapper">
                            <collapse-toggle-button />
                        </span>
                        <span class="btnlike mr-auto">
                            <h6 class="text-dark mb-0">
                                {{ role.text }}
                            </h6>
                        </span>
                        <span>
                            <em v-if="role.value == formData.value" class="badge badge-primary ml-hf">
                                Selected
                            </em>
                        </span>
                    </a>
                </div>
                <div :id="'pane_' + role.value" class="collapse" :aria-labelledby="'heading_' + role.value" data-parent="#rolesAccordionSm">
                    <div class="card-body">
                        <alert-box type="info" v-if="role.value == 'onboarding-manager'">
                            This role can only manage <strong>onboarding employees</strong>.
                        </alert-box>
                        <div class="row mb-2">
                            <div class="col">
                                Assigned to: 
                            </div>
                            <div class="col">
                                {{ role.assignedTo }}
                            </div>
                        </div>
                        <role-permission-details :role="role" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Large-screen version -->

        <div class="card card-body roles-info-box d-none d-lg-flex">
            <div class="roles-info-box__list">
                <div class="role-item role-item--header">
                    <div class="role-item__label">
                        <span class="theading">
                            Role
                        </span>
                    </div>
                    <div class="role-item__rating">
                        <span class="theading">
                            Responsibility
                        </span>
                    </div>
                    <div class="role-item__assigned">
                        <span class="theading">
                            Assigned To
                        </span>
                    </div>
                </div>
                <ul>
                    <li class="role-item" v-for="role in roleOptions" :key="role.value" @click.prevent="showRole(role.value)" @keydown="showRole(role.value)" :class="[{ 'role-item--active': role.value == roleShown }, { 'role-item--selected': role.value == formData.value }, { 'role-item--disabled': !roleIsSelectable(role.value) }]">
                        <div class="role-item__label">
                            <span class="text-semibold">
                                {{ role.text }}
                            </span>
                            <em v-if="role.value == formData.value" class="badge badge-primary ml-hf">
                                Selected
                            </em>
                        </div>
                        <div class="role-item__rating">
                            <span v-if="role.rating" class="text-muted mr-qt">
                                <i v-for="n in (Math.floor(role.rating))" :key="'filled-' + n" class="fas fa-circle"></i>
                                <i v-if="role.rating % 1" class="fas fa-adjust"></i>
                                <i v-for="n in (4 - Math.ceil(role.rating))" :key="'empty-' + n" class="far fa-circle"></i>
                                <span class="sr-only">{{ role.rating }} out of 4</span>
                            </span>
                        </div>
                        <div class="role-item__assigned">
                            <span>
                                {{ role.assignedTo }}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="roles-info-box__details">
                <div class="roles-info-box__scroll">
                    
                    <div v-for="role in roleOptions" :key="role.value">
                        <div v-if="role.value == roleShown">
                            <h5>
                                <span class="sr-only">Detailed Permissions for </span>
                                {{ role.text }}
                            </h5>
                            <alert-box type="info" v-if="role.value == 'onboarding-manager'">
                                This role can only manage <strong>onboarding employees</strong>.
                            </alert-box>

                            <role-permission-details :role="role" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import RolePermissionDetails from './RolePermissionDetails'

export default {
    props: ['roleOptions', 'myRoleOptions', 'modelValue', 'errors', ],
    emits: ['update:modelValue'],
    components: {RolePermissionDetails, },
    data() {
        return {
            formData: {
                "value": this.modelValue || '',
            },
            roleShown: this.modelValue || this.roleOptions[0].value, // The role in the table shown initially by default
        }
    },
    methods: {
        // Change the role that is 'highlighted' in the table
        showRole(roleValue) {
            this.roleShown = roleValue;
        },
        onInput($event) {
            this.showRole($event);
            this.$emit('update:modelValue', $event)
        },
        roleIsSelectable(roleValue) {
            return this.myRoleOptions.some(r => r.value == roleValue)
        }
    },
}
</script>

<style scoped lang="scss">
  // Import Sass globals
  @import "@/assets/scss/globals";

  // 
  // Component styles
  //

  .roles-info-box {
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
  }

  .roles-info-box, 
  .accordion {

    .badge {
        font-weight: $font-weight-normal;
    }
  }

  .roles-info-box__list {
    flex: 1 0 auto;

    ul {
        margin: 0;
        padding: 0;
        list-style: none outside;
    }
  }

  .role-item {
    background-color: $white;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    border-bottom: solid 1px $border-color;
    padding: 0.75rem spacer(2);

    &:last-child {
      border-bottom: none;
    }

    &:hover, 
    &:active, 
    &:focus {
      background-color: gray("100");
    }
  }

  .role-item--header {
    cursor: default;
    padding: 0.25rem spacer(2);
    border-top-left-radius: $border-radius;

    &, 
    &:hover, 
    &:active, 
    &focus {
        background-color: $white;
    }
  }

  .role-item--active {

    &, 
    &:hover, 
    &:active, 
    &:focus {
      background-color: gray("150");
    }

    &::after {
        color: $text-muted;
        content: "\f054"; // chevron-right
        @include font-awesome-display(400);
    }
  }

  // Don't use the chevron-right for small screens
  .accordion .role-item--active::after {
    content: none;
  }

  .role-item--selected {

    &, 
    &:hover, 
    &:active, 
    &:focus {
      background-color: theme-color("hilite");
    }
  }

  .role-item--disabled {
    color: gray("550");

    .text-dark { // Accordion titles
        color: gray("550") !important;
    }
  }

  .role-item__label, 
  .role-item__rating, 
  .role-item__assigned {
    flex: 1 0 auto;
  }

  .role-item__label {
    flex-basis: 50%;

    > * {
        margin: 0;
    }
  }

  .role-item__rating {
    flex-basis: 25%;
  }

  .role-item__assigned {
    flex-basis: 25%;
  }

  .roles-info-box__details {
    flex: 0 1 328px;
    position: relative;
    border-left: solid 1px $border-color;
  }

  .roles-info-box__scroll {
    @include scroll-container-shadow();
    padding: spacer(2);
    overflow-y: auto;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
</style>
