<template>
    <modal :autoshow="true" size="xl" ref="modal" @close="$emit('close')" :is-closeable="isCloseable">
        <template v-slot:title>
            Emergency Contacts
        </template>

        <template v-slot:subheading>
            <div class="">
                <div class="p-1 unpad-x bg-150 rounded">
                    <div><span class="text-muted">Employee:</span> {{ employee.full_name }}</div>
                </div>
            </div>
        </template>

        <form @submit.prevent="onSubmit">
            <form-errors :errors="formErrors"/>

            <div>
                <div class="row mb-1">
                    <div v-for="(id, $index) in Object.keys(emergencyContactForms)" :key="id" class="col-12 col-lg-6">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="mb-1">Contact {{ $index + 1 }}</h6>
                            </div>
                            <div class="col text-right">
                                <a href class="btn btn-link" @click.prevent="removeEmergencyContact(id, $index)" v-if="Object.keys(emergencyContactForms).length > 1">Remove</a>
                            </div>
                        </div>

                        <emergency-contact-form
                            :instance="formData.emergency_contacts[$index]"
                            ref="emergencyContactForms"
                            @update="onEmergencyContactUpdate($index, $event)"
                        />
                    </div>
                </div>
            </div>
        </form>

        <p class="mb-2">
            <a href class="btn btn-link" @click.prevent="addEmergencyContact()"><i class="far fa-plus mr-hf"></i>Add another</a>
        </p>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit">Save</button>
                </div>
            </div>
        </template>

    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'
import api from '@/api'
import bus from '@/bus'

import EmergencyContactForm from '@/components/EmergencyContactForm'

export default {
    mixins: [ModalMixin, FormMixin],
    components: {EmergencyContactForm},
    props: ['client', 'employee', 'contacts' ],
    watch: {
        client() {
            this.$refs.form.reset()
        }
    },
    data() {
        const data = {
            formData: this.makeFormData(),
            emergencyContactForms: {},
        }

        // make sure there's at least 1 form
        // (anyone onboarded should have one automatically, but not necessarily a 'legacy' EE)
        data.emergencyContactForms = {0: 0}

        for (let i = 0; i < this.contacts.length; i++) {
            data.emergencyContactForms[i] = i
        }

        return data
    },
    methods: {
        makeFormData() {
            const formData = {
                emergency_contacts: [],
            }

            this.contacts.forEach((contact) => {
                formData.emergency_contacts.push({
                    id: contact.id,
                    first_name: contact.first_name,
                    last_name: contact.last_name,
                    relationship: contact.relationship,
                    phone: contact.phone,
                    email: contact.email,
                })
            })

            return formData
        },
        addEmergencyContact() {
            const id = Math.max(1, ...Object.keys(this.emergencyContactForms)) + 1
            this.emergencyContactForms[id] = Object.keys(this.emergencyContactForms.length - 1)
            this.$emit('scrollbottom')
        },
        removeEmergencyContact(id, idx) {
            const emergency_contacts = []
            this.formData.emergency_contacts = [];
            (this.$refs.emergencyContactForms || []).forEach((form, i) => {
                if (i != idx) {
                    emergency_contacts.push(form.getFormData())
                }
            })

            this.formData.emergency_contacts = emergency_contacts
            delete this.emergencyContactForms[id]
        },
        onEmergencyContactUpdate(idx, data) {
            this.formData.emergency_contacts[idx] = data
        },
        onSubmit() {
            let errorCount = 0;
            (this.$refs.emergencyContactForms || []).forEach((form) => {
                if (!form.validate()) {
                    errorCount += 1
                }
            })

            if (!this.validate() || errorCount) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/clients/${this.client.id}/employees/${this.employee.id}/emergency-contacts`, this.getFormData()).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            (this.$refs.emergencyContactForms || []).forEach((form) => {
                form.reset()
            })
            if (! this.emergencyContactForms) {
                this.emergencyContactForms = {1: 1}
            }
            this.formErrors = []
            this.formData = this.makeFormData()

            FormMixin.methods.reset.call(this)
        },
    }
}
</script>
