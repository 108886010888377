<template>
    <modal :autoshow="true" ref="modal" @close="$emit('close')" :is-closeable="true">
        <template v-slot:title>{{ title }}</template>

        <template v-slot:subheading>
            <slot name="subheading"></slot>
        </template>

        <slot>
        </slot>

        <template v-slot:footer>
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="$emit('close')">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="$emit('submit', $event)">{{ confirmButtonText }}</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/Modal'
import ButtonRow from '@/components/ButtonRow'

export default {
    props: ['title', 'confirmButtonText', ],
    emits: ['close', 'submit'],
    components: {Modal, ButtonRow, },
}
</script>
