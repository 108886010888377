<template>
    <div>
        <nav-secondary :navOptions="navOptions"></nav-secondary>
        <router-view class="flex-page-content flex-container view"/>
    </div>
</template>

<script>
import NavSecondary from '@/components/NavSecondary'

export default {
    components: { NavSecondary, },
    props: ['client', ],
    data() {
        return {
            navOptions: [
                {'text': 'Test Address', 'value': 'admin-geocoding-tester'},
                {'text': 'Find School District', 'value': 'admin-geocoding-school-district'},
                {'text': 'OH Finder Check Matches', 'value': 'admin-geocoding-oh-finder-check-matches'},
            ]
        }
    },
    mounted() {
        const routes = this.navOptions.map(x => x.value)
        if (routes.indexOf(this.$route.name) < 0) {
            this.$router.replace({name: routes[0]})
        }
    },
}
</script>
