<template>
    <modal ref="modal" :fade="false" :v-center="true" modal-additional-classes="modal-pageerror modal-noclose" :options="modalOptions" :modal-container="'specialModal'" :hide-x-button="true">
        <template v-slot:title>
            <div>Signed Out</div>
        </template>

        <slot>
            <div>
                <p>
                  You've been signed out due to inactivity. Please sign in again.
                </p>
            </div>
        </slot>

        <template v-slot:footer>
            <button-row center>
                <a href @click.prevent="onClick" class="btn btn-primary">Go to Sign In</a>
            </button-row>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin, ],
    data() {
        return {
            modalOptions: {
                backdrop: 'static',
                keyboard: false,
            },
        }
    },
    methods: {
        onClick() {
            this.$store.dispatch('LOGOUT', true)
            if (this.$route.name != 'login') {
                this.$router.push({name: 'login'})
            }
        },
        close(forceClose = false) {
            if (forceClose) {
                ModalMixin.methods.close.call(this)
            }
        },
    },
}
</script>
