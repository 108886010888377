<template>
    <div class="heads-up-banner-container">
        <transition name="fade">
        <div v-if="headsup=='enhanced-ess' && enhancedESSCompanies.length > 0 && !isDismissed" class="heads-up-banner mb-3">
            <div class="heads-up-status">running now</div>
            <div class="heads-up-details pl-1 pr-2">
                <div>
                    <strong>Enhanced ESS is now in use in {{ enhancedESSCompanies[0].name }} {{ enhancedESSCompanies.length > 1 ? `and ${enhancedESSCompanies.length - 1} others` : ''}}!</strong>
                </div>
                <div class="heads-up-links">
                    <router-link target="_blank" class="btn btn-link py-0" :to="{name: 'client-features-enhanced-ess', params: {client: client.id} }" @click="trackLearnMore">
                        <i class="far mr-hf fa-book-open"></i>Learn More
                    </router-link>
                    <a href class="btn btn-link py-0" @click.prevent="dismiss()">Dismiss</a>
                </div>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: ['client', 'headsup'],
    components: {},
    data() {
        return {
            'dismissed': false
        }
    },
    computed: {
        dismissalSlug() {
            return `headsup-${this.headsup}`
        },
        dismissalsRecord() {
            let rec = {}
            if (this.$store.state.user) {
                if (this.$store.state.user.dismissals_record['clients']) {
                    if (this.client.id in this.$store.state.user.dismissals_record['clients']) {
                        rec = this.$store.state.user.dismissals_record['clients'][this.client.id]
                    }
                }
            }
            return rec
        },
        isDismissed() {
            if (this.dismissed) {
                return true
            } else {
                let timestamp = this.dismissalsRecord[this.dismissalSlug]
                if (timestamp) {
                    let inputformat = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
                    let dismissedMoment = moment(timestamp, inputformat)
                    if (dismissedMoment.isSameOrBefore()) {
                        return true
                    }
                }
            }
            return false
        },
        enhancedESSCompanies() {
            if (this.client.has_enhanced_ess) {
                return this.client.companies.filter(c => c.has_enhanced_ess)
            } else {
                return []
            }
        },
    },
    methods: {
        dismiss() {
            this.dismissed = true
            this.$api.post(`/me/dismiss`, {'client_id': this.client.id, 'slug': this.dismissalSlug}).then(resp => {
                this.$store.dispatch('GET_USER')
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
            this.$bus.trackEvent('Enhanced ESS Heads Up Banner Dismiss', {
                'client_id': this.client.id,
                'client_business_type_rollup_primary': this.client.business_type_rollup_primary,
                'client_business_type_rollup_secondary': this.client.business_type_rollup_secondary,
                'client_business_type_rollup_brand': this.client.business_type_rollup_brand,
            })
        },
        dismissIn30Days() {
            let dismissTimestamp = moment().add(30, 'days');
            this.$api.post(`/me/dismiss`, {'client_id': this.client.id, 'slug': this.dismissalSlug, 'timestamp': dismissTimestamp})
        },
        trackLearnMore() {
            this.$bus.trackEvent('Enhanced ESS Heads Up Banner Learn More', {
                'client_id': this.client.id,
                'client_business_type_rollup_primary': this.client.business_type_rollup_primary,
                'client_business_type_rollup_secondary': this.client.business_type_rollup_secondary,
                'client_business_type_rollup_brand': this.client.business_type_rollup_brand,
            })
        },
    },
    mounted() {
        // dismiss 30 days from the first time the viewer sees this heads up
        if (!this.isDismissed) {
            this.dismissIn30Days()
        }
    },
}

</script>
