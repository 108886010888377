<template>
    <div>
        <div class="flex-body pb-2">
            <div>
                <div class="mx-auto d-flex flex-column justify-content-center">
                    <!-- Summary container -->
                    <div class="py-2">
                        <p class="lead text-semibold">
                            The following data was imported into payroll:
                        </p>
                        <!-- Big metrics -->
                        <div class="pb-4">
                            <div class="d-flex flex-wrap">
                                <div class="mr-8">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_employees_for_payroll }}
                                    </div>
                                    <div class="theading">
                                        Employees
                                    </div>
                                </div>
                                <div class="mr-8">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_hours_reg }}
                                    </div>
                                    <div class="theading">
                                        Regular Hours
                                    </div>
                                </div>
                                <div class="mr-8">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_hours_ot }}
                                    </div>
                                    <div class="theading">
                                        Overtime Hours
                                    </div>
                                </div>
                                <div class="mr-8" v-if="this.taskDetail.payroll_summary_stats.total_hours_other">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_hours_other }}
                                    </div>
                                    <div class="theading">
                                        Other Hours
                                    </div>
                                </div>
                                <div class="mr-8" v-if="this.taskDetail.payroll_summary_stats.total_other_earnings">
                                    <div class="text-display1">
                                        ${{ this.taskDetail.payroll_summary_stats.total_other_earnings }}
                                    </div>
                                    <div class="theading">
                                        Other Earnings
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END Summary container -->

                    <div class="mx-auto d-flex flex-column justify-content-center w-75 mb-4" v-if="this.taskDetail.warnings_json">
                        <div v-for="warning in this.taskDetail.warnings_json">
                            <div class="alert alert-warning">
                                <h4>
                                    <i class="fas fa-fw fa-exclamation-triangle"></i> Warning
                                </h4>

                                {{ warning.description }}

                                <div v-if="warning.referenced_data">
<br>
<pre>
<b>{{ warning.referenced_data_header }}</b>{{ warning.referenced_data }}
</pre>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mx-auto d-flex flex-column justify-content-center w-75 mb-4" v-if="this.taskDetail.errors_json">
                        <div v-for="warning in this.taskDetail.errors_json">
                            <div class="alert alert-danger">
                                <h4>
                                    <i class="fas fa-fw fa-exclamation-triangle"></i> Error
                                </h4>

                                {{ error.description }}

                                <div v-if="error.referenced_data">
<br>
<pre>
<b>{{ error.referenced_data_header }}</b>{{ error.referenced_data }}
</pre>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="text-center">
                        <div class="icon-huge">
                            <i class="fas fa-check"></i>
                        </div>
                        <h1 class="mb-1">Import was successful.</h1>
                        <p class="mb-4">
                            Employee hours from POS are now in payroll.
                        </p>
                        <p>
                            <router-link class="btn btn-primary" :to="{name: 'employer-home', params: {hashedClientId: $hasher.encode(client.id)}}">Home</router-link>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import FastTable from '@/components/FastTable'

import moment from 'moment'

export default {
    props: ['client', 'taskDetail'],
    components: {FastTable, },
    computed: {
    },
    data() {
        const companiesExpanded = {}

        for (const [key, value] of Object.entries(this.taskDetail.payroll_summary_by_company_location)) {
            companiesExpanded[value['CompanyCode']] = false
        }

        return {
            companiesExpanded: companiesExpanded,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Point-of-Sale Integration')
    },
    methods: {
    },
}
</script>
