<template>
    <div class="px-2 pb-1 pt-0 pt-md-1">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th class="cell-name col-sortable">
                        Employee
                        <span class="sort-icon"></span>
                    </th>
                    <th class="cw-date text-right">
                        {{ dateLabel }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="task in tasks" :key="task.id">
                    <td class="cell-name">
                        <span class="btnlike-sm-alt text-semibold" v-if="task.case_status">
                            <router-link class="btnlike-sm-alt text-semibold" :to="{name: 'e-verify'}">{{ task.emp_full_name }}</router-link>
                        </span>
                        <span class="btnlike-sm-alt text-semibold" v-else-if="taskType == 'doc_needs_manager_approval' || taskType == 'doc_needs_manager_response'">
                            <router-link class="btnlike-sm-alt text-semibold" :to="{name: 'hr-documents-incomplete', query: {doc: task.id}}">{{ task.employee.full_name }}</router-link>
                        </span>
                        <span class="btnlike-sm-alt text-semibold" v-else-if="taskType == 'onboarding_app_approval'">
                            <router-link class="btnlike-sm-alt text-semibold" :to="{name: 'onboarding', query: {id: task.id}}">{{ task.full_name }}</router-link>
                        </span>
                    </td>
                    <td class="cw-date text-right">
                        <span class="btnlike-sm-alt">
                            <span v-if="taskType == 'doc_needs_manager_approval'">{{ $filters.dateshort(task.responded_on) }}</span>
                            <span v-else-if="taskType == 'doc_needs_manager_response'">{{ $filters.dateshort(task.assigned_on) }}</span>
                            <span v-else-if="taskType == 'onboarding_app_approval'">{{ $filters.fromNow(task.date_hired)}}</span>
                            <span v-else-if="taskType == 'everify_action_required'">{{ $filters.dateshort(task.next_action_due_on) }}</span>
                            <span v-else-if="taskType == 'everify_cases_to_create'">{{ $filters.dateshort(task.next_action_due_on) }}</span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['tasks', 'taskType'],
        computed: {
            dateLabel() {
                if (this.taskType) {
                    return {
                        'onboarding_app_approval': 'Expected Start Date',
                        'doc_needs_manager_approval': 'Submitted On',
                        'doc_needs_manager_response': 'Sent On',
                        'everify_action_required': 'Due On',
                        'everify_cases_to_create': 'Due On',
                    }[this.taskType]
                }
                return ""
            },
        }
    }
</script>