<template>
<div>
<info-widget :collapsible="true" :is-expanded="widgetExpanded" @toggle_expand="widgetExpanded = !widgetExpanded">
    <template v-slot:title>City Details</template>

    <template v-slot:headerbutton>
        <button class="btn btn-outline-primary" type="button" @click.prevent.stop="modalOpen = true">
            <i class="far fa-pencil" aria-hidden="true" title="Edit"></i>&nbsp;
            <slot name="add-button">Edit</slot>
        </button>
    </template>

    <slot>
        <div class="card my-0">

            <div class="card-header py-1">
                <h5>Reciprocals</h5>
            </div>
            <div class="card-body py-1">
                <p v-if="!city.reciprocal_agreement_cities.length">This city has no reciprocal agreements.</p>

                <ul class="list-unstyled m-0" v-if="city.reciprocal_agreement_cities.length">
                    <li v-for="(s, i) in city.reciprocal_agreement_cities" :key="s.id" :class="{'border-bottom': i < city.reciprocal_agreement_cities.length - 1}">
                        <span>{{ s.name }}</span>
                    </li>
                </ul>
            </div>

            <div class="card-header py-1">
                <h5>Alternative Names</h5>
            </div>
            <div class="card-body py-1">
                <p v-if="!city.alternative_names.length">This city has no alternative names.</p>

                <ul class="list-unstyled m-0" v-if="city.alternative_names.length">
                    <li v-for="(n, i) in city.alternative_names" :key="n" :class="{'border-bottom': i < city.alternative_names.length - 1}">
                        <span>{{ n }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </slot>

</info-widget>

<edit-modal v-if="modalOpen" :city="city" :all-cities="allCities" @updated="$emit('updated', $event)" @close="modalOpen = false"/>

</div>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
import EditModal from './CityModal'

export default {
    props: ['city', 'allCities'],
    components: {EditModal, InfoWidget, },
    data() {
        return {
            modalOpen: false,
            widgetExpanded: false,
        }
    },
}
</script>
