<template>
    <div>
        <nav-secondary :navOptions="navOptions"></nav-secondary>
        <router-view class="flex-page-content flex-container view" :client="client" v-if="client" />
    </div>
</template>

<script>
import NavSecondary from '@/components/NavSecondary'

export default {
    components: { NavSecondary, },
    data() {
        return {
            client: null,
            navOptions: [
                {'text': 'Companies', 'value': 'admin-client-companies'},
                {'text': 'POS Integrations', 'value': 'admin-client-pos-integrations'},
                {'text': 'Pay Items', 'value': 'admin-client-pay-items'},
                {'text': 'Orgs', 'value': 'admin-client-config-orgs'},
                {'text': 'Paygroups', 'value': 'admin-client-config-paygroups'},
                {'text': 'Locations', 'value': 'admin-client-config-locations'},
                {'text': 'Terminal Values', 'value': 'admin-client-config-positions'},
                {'text': 'Form Entitlements', 'value': 'admin-client-form-entitlements'},
            ]
        }
    },
    mounted() {
        this.getClient()
        const routes = this.navOptions.map(x => x.value)
        if (routes.indexOf(this.$route.name) < 0) {
            this.$router.replace({name: routes[0]})
        }
    },
    methods: {
        getClient() {
            this.dataLoaded = false
            const clientId = this.$hasher.decode(this.$route.params.hashedClientId)
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${clientId}`).then((resp) => {
                this.client = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    }
}
</script>
