<template>
<div>
    <form class="mui-form">
        <h3 class="py-2 mb-0">Add Fillable Form: <span class="text-thin text-nowrap">{{ stepAsText }}</span></h3>
        <div v-if="step != 'doc-companies'" class="mb-2">
            <div class="p-1 unpad-x bg-150 rounded">
                <div><span class="text-muted">Company: </span>{{ companyAsText }}</div>
                <div v-if="step == 'doc-positions'"><span class="text-muted">Document Name: </span>{{ formData.name }}</div>
            </div>
        </div>

        <form-errors class="unpad-x" :errors="formErrors"/>

        <div v-if="step == 'doc-companies'">
            <p class="mb-0">Select the company or companies this document will be available for.</p>
            <company-selector :client="client" :companies="companiesWithFeature" v-model="formData.companies"/>
        </div>

        <div v-if="step == 'doc-name-form'">
            <div class="form-row">
                <div class="col">
                    <form-input label="Form" v-model="formData.yaml_form" :errors="errors.yaml_form" type="select" :options="hrFormSelectOptions"/>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <form-input v-model="formData.name" :errors="errors.name" label="Document Name" type="text" />
                </div>
            </div>
        </div>

        <div v-if="step == 'doc-positions'">
            <position-selector :all-positions="allCompanyPositions" :companies="formData.companies" v-model="formData.positions" />
        </div>

    </form>

    <div class="row mb-2 mt-4 d-flex align-items-center">
        <div class="col">
            <button class="btn btn-outline-primary" @click.prevent="onPrevStep">Back</button>
        </div>

        <div v-if="step == 'doc-positions'" class="col text-right">
            <button class="btn btn-primary" @click.prevent="saveDocument">Save</button>
        </div>

        <div v-else class="col text-right">
            <button class="btn btn-primary" @click.prevent="onNextStep">Continue</button>
        </div>
    </div>
</div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import CompanySelector from './CompanySelector'
import PositionSelector from './PositionSelector'
import InfoWidget from '@/components/InfoWidget'
import PdfInline from '@/components/PdfInline'

// Steps used when adding a new doc (i.e. 'add' and 'edit-draft' modes)
const STEPS = [
    'doc-companies',
    'doc-name-form',
    'doc-positions'
]

export default {
    components: {CompanySelector, PositionSelector, InfoWidget, PdfInline, },
    props: ['client'], // mode can be add, edit-draft, add-version, edit-draft-version
    mixins: [FormMixin, ],
    data() {
        let data = {
            step: 'doc-companies',
            formData: this.makeFormData(),
            hrFormOptions: [],
            allPositions: [],
        }
        return data
    },
    computed: {
        companyAsText() {
            let companyText = ''
            if (this.formData.companies && this.formData.companies.length > 0) {
                let company = (this.client.companies.find(company => company.id == this.formData.companies[0]))
                if (company) {
                    companyText = company.name
                }
                if (this.formData.companies && this.formData.companies.length > 1) {
                    companyText += ` + ${this.formData.companies.length - 1} More`
                }
            }
            return companyText
        },
        stepAsText() {
            return {
                'doc-companies': 'Company Selection',
                'doc-name-form': 'Form Selection',
                'doc-positions': 'Assign to Positions',
            }[this.step]
        },
        allCompanyPositions() {
            // positions filtered to currently selected companies
            return this.allPositions.filter(p => this.formData.companies && this.formData.companies.includes(p.company_id))
        },
        companiesWithFeature() {
            // fillable forms feature is granted on a client basis
            return this.client.companies
        },
        hrFormSelectOptions() {
            const options = []
            for (let form of (this.hrFormOptions || [])) {
                options.push({text: form.name, value: form.id})
            }
            return options
        },
    },
    created() {
        this.getPositions()
    },
    methods: {
        getPositions() {
            // positions filtered by security profile on backend
            this.$api.get(`/clients/${this.client.id}/positions`).then(resp => {
                this.allPositions = resp
                this.allPositions.sort((a, b) => {return a.terminal_value.toLowerCase() > b.terminal_value.toLowerCase() ? 1 : -1})
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        makeFormData() {
            return {
                companies: [],
                name: '',
                yaml_form: null,
                positions: []
            }
        },
        async validateFormData() {
            // validate form data on each next step
            this.formErrors = []
            this.errors = {}
            if (this.step == 'doc-companies') {
                if (!this.formData.companies.length) {
                    this.formErrors = ['At least one company is required.']
                    return false
                }
            }
            if (this.step == 'doc-name-form') {
                if (!this.formData.name) {
                    this.errors['name'] = ['This field is required.']
                    return false
                }
                if (!this.formData.yaml_form) {
                    this.errors['yaml_form'] = ['This field is required.']
                    return false
                }
                try {
                    let resp = await this.$api.post(`/clients/${this.client.id}/hr-form-documents?action=validate-name`, this.getFormData())
                    return true
                } catch(errors) {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    return false
                }
            }
            return true
        },
        getNextStep(step) {
            let stepIdx = STEPS.indexOf(step)
            if (stepIdx == -1 || stepIdx + 1 >= STEPS.length) {
                return null
            } else {
                return STEPS[stepIdx + 1]
            }
        },
        getPrevStep(step) {
            let stepIdx = STEPS.indexOf(step)
            if (stepIdx <= 0) {
                return null
            } else {
                return STEPS[stepIdx - 1]
            }
        },
        goToNextStep() {
            this.step = this.getNextStep(this.step)
        },
        goToPrevStep() {
            this.step = this.getPrevStep(this.step)
        },
        onNextStep() {
            this.validateFormData().then(validated => {
                if (!validated) {
                    return false
                } else {
                    if (this.step == 'doc-companies') {
                        this.$api.get(`/clients/${this.client.id}/companies/all/hr-form-options`).then(resp => {
                            this.hrFormOptions = resp
                            this.goToNextStep()
                        }).catch(errors => {
                            this.$store.dispatch('STOP_LOADING')
                            this.errors = errors
                            this.formErrors = errors.__all__
                            this.onError()
                        })
                    } else {
                        this.goToNextStep()
                    }
                }
            })
        },
        onPrevStep() {
            // first check if we should exit out of this component
            if (this.step == STEPS[0]) {
                this.$emit('prev') // return to doc type selector in parent component
                return
            }
            this.goToPrevStep()
        },
        exitToDocuments() {
            this.$router.push({name: 'settings-hr-documents'})
        },
        getFormData() {
            return this.formData
        },
        saveDocument() {
            this.validateFormData().then(validated => {
                if (!validated) {
                    return false
                } else {
                    this.$store.dispatch('START_LOADING')
                    this.$api.post(`/clients/${this.client.id}/hr-form-documents`, this.getFormData()).then(resp => {
                        this.$store.dispatch('STOP_LOADING')
                        this.exitToDocuments()
                    }).catch(errors => {
                        this.$store.dispatch('STOP_LOADING')
                        this.errors = errors
                        this.formErrors = errors.__all__
                        this.onError()
                    })
                }
            })
        },
    }
}
</script>
