<template>
<page>
<div class="nav-tabs-container pt-1">
    <div class="nav-tabs-scroll-helper">
        <ul class="nav nav-tabs">

            <li class="nav-item" v-for="navOpt in navOptions">
                <router-link class="nav-link" :class="{'active': $route.name == navOpt.value}" :to="{'name': navOpt.value}">{{ navOpt.text }}</router-link>
            </li>
        </ul>
    </div>
</div>

<div class="flex-body flex-container" id="qqq">
    <div class="flex-body flex-container tab-pane" role="tabpanel">
        <router-view class="view" />
    </div>
</div>
</page>
</template>

<script>
import Page from '@/components/Page'

export default {
    components: {Page, },
    data() {
        return {
            navOptions: [
                {'text': 'States', 'value': 'admin-tax-engine-states'},
                {'text': 'Cities', 'value': 'admin-tax-engine-cities'},
            ]
        }
    },
    mounted() {
        const routes = this.navOptions.map(x => x.value)
        if (routes.indexOf(this.$route.name) < 0) {
            this.$router.replace({name: routes[0]})
        }
    },
}
</script>

