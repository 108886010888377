<template>
    <div class="row">
        <div class="col-12">
            <div class="container-centered-s">
                <form @submit.prevent="onSubmit" class="mt-2">
                    <form-errors :errors="formErrors"/>

                    <div class="form-row">
                        <div class="col">
                            <form-input v-model="formData.geocode_choice_idx" :errors="errors.geocode_choice_idx" type="radio" :options="geocodeChoiceOptions" label="Please select the town/city in which you live."/>
                        </div>
                    </div>

                    <alert-box type="caution">
                    {{ geocodeDisclaimer }}
                    </alert-box>

                    <portal to="address-edit-modal-footer">
                        <button-row stacked>
                            <button type="button" @click.prevent="onPrev()" class="btn btn-outline-primary">Back</button>
                            <button type="button" @click.prevent="onSubmit()" class="btn btn-primary">Continue</button>
                        </button-row>
                    </portal>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ButtonRow from '@/components/ButtonRow'
import api from '@/api'

export default {
    mixins: [FormMixin],
    components: {ButtonRow, },
    props: ['employee', 'employeeUpdate', 'prev'],
    watch: {
        employee() {
            this.reset()
        },
        employeeUpdate() {
            this.reset()
        }
    },
    computed: {
        geocodeChoiceOptions() {
            const result = []
            this.employeeUpdate.geocode_data['geocode_choices']['geocode_choices'].forEach((c, idx) => {
                result.push({text: c['JURNAME'].toUpperCase(), value: idx})
            })
            return result
        },
        geocodeDisclaimer() {
            let state = this.employeeUpdate.geocode_data['state']
            return this.geocodeDisclaimers[state] || this.geocodeDisclaimers['default']
        }
    },
    data() {
        const data = {
            formData: this.makeFormData(),
            formRules: {
                'geocode_choice_idx': {
                    presence: {
                        allowEmpty: false,
                        message: "is required"
                    },
                }
            },
            formLabels: {
                'geocode_choice_idx': 'Town/City Selection',
            },
            formErrorsHtml: [],
            geocodeDisclaimers: {
                'default': 'This selection may affect your taxes. If you have questions regarding your jurisdiction, please verify with a trusted resource, such as a state or local government office.',
                'OH': 'This selection may affect your taxes. If you have questions regarding your jurisdiction, please verify with your County Auditor or contact the Department of Taxation.'
            }
        }
        return data
    },
    methods: {
        makeFormData() {
            let formData = {
                action: 'choose-geocode',
                geocode_choice_idx: this.employeeUpdate.geocode_choice_idx,
            }
            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.patch(`/me/ess/companies/${this.employee.company_id}/updates/${this.employeeUpdate.id}`, this.getFormData()).then((resp) => {
                this.$emit('patch', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onPrev() {
            this.$emit('prev')
        },
    }
}
</script>