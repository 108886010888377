<template>
    <div class="card mb-2">
        <div class="card-header pr-1 d-flex align-items-center">
            <span class="btnlike mr-auto">
                <h4 class="mb-0">Employee Information</h4>
            </span>

            <button v-if="canEdit" class="btn btn-outline-primary" type="button" @click.prevent="$emit('edit')">
                <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                Edit
            </button>
        </div>

        <div class="card-body">
            <alert-box type="caution" v-if="hasBouncedEmail">
                <strong>Bounced Email:</strong> Onboarding cannot proceed because email cannot be delivered.
                <a v-if="canEditEmail" href @click.prevent="editEmail">Update this user's email address to re-try.</a>
            </alert-box>

            <div class="mui-textfield">
                <span class="textfield">{{ employee.full_name }}</span>
                <label>Employee Name</label>
            </div>

            <!-- Textfield with icon buttons to the right of it -->
            <div class="d-flex align-items-start">
                <div class="flex-fill overflow-auto">
                    <div class="mui-textfield">
                        <span class="textfield">{{ employee.email }}</span>
                        <label>Email Address</label>
                    </div>
                </div>
                <div class="flex-0">
                    <a class="btn btn-outline-primary btn-collapse-xl" :href="'mailto:' + encodeURI(employee.email)">
                        <i class="far fa-envelope" aria-hidden="true" title="Send Email"></i>
                        <span class="btn-text">Send Email</span>
                    </a>
                    <button v-if="canEditEmail" class="btn btn-outline-primary btn-collapse-xl ml-hf" @click.prevent="editEmail">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        <span class="btn-text">Edit</span>
                    </button>
                </div>
            </div>

            <!-- NB: phone_home is for Employee; phone is for OnboardingApplication -->
            <div class="mui-textfield" v-if="employee.phone_home_formatted">
                <span class="textfield">{{ employee.phone_home_formatted }}</span>
                <label>Phone</label>
            </div>

            <div class="mui-textfield" v-if="employee.phone_formatted">
                <span class="textfield">{{ employee.phone_formatted }}</span>
                <label>Phone</label>
            </div>

            <div>
                <div class="mui-textfield">
                    <span class="textfield">{{ $filters.date(employee.date_hired) }}</span>
                    <label>
                        <span v-if="employee.is_onboarding_application">Expected</span>
                        Start Date
                    </label>
                </div>

                <div class="mui-textfield">
                    <span class="textfield">{{ employee.aca_category }}</span>
                    <label>ACA Employment Category</label>
                </div>

                <div class="mui-textfield" v-if="employee.address_1">
                    <span class="textfield">
                        {{ employee.address_1 }}
                        <br />
                        {{ employee.address_2 }}
                        <br v-if="employee.address_2" />
                        {{ employee.city }}, {{ employee.state }} {{ employee.postal_code }}
                    </span>
                    <label>Resident Address</label>
                </div>

                <div class="mui-textfield" v-if="employee.address_1">
                    <span class="textfield">
                        <template v-if="employee.use_mailing_address">
                            {{ employee.mailing_address_1 }}
                            <br />
                            {{ employee.mailing_address_2 }}
                            <br v-if="employee.mailing_address_2" />
                            {{ employee.mailing_city }}, {{ employee.mailing_state }} {{ employee.mailing_postal_code }}
                        </template>
                        <template v-else>
                            Same as above
                        </template>
                    </span>
                    <label>Mailing Address</label>
                </div>

                <div class="mui-textfield" v-if="employee.ssn_last_4">
                    <span class="textfield">XXX-XX-{{ employee.ssn_last_4 }}</span>
                    <label>Social Security Number</label>
                </div>

                <div v-if="employee.is_onboarding_application || $permissions.hasRoles(client.id, $store.state.security_roles.EMPLOYEE_ROLES)">
                    <div class="unpad-x">
                      <hr class="unpad-x" />
                    </div>

                    <h4 class="mt-2 mb-2">{{ client.position_label_pretty }} Information</h4>

                    <!-- Position -->
                    <div class="mb-1" v-for="job in employee.jobs" :key="job.id">
                        <div class="row align-items-center mb-1">
                            <div class="col-12">
                                <h6 class="mb-0"><!-- {{ job.position.work_location.name }} - -->{{ job.position.terminal_value }}</h6>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-12 col-sm-4">
                                <div class="mui-textfield">
                                    <span class="textfield" v-if="job.pay_type == 'S'">Salary</span>
                                    <span class="textfield" v-if="job.pay_type == 'H'">Hourly</span>
                                    <label>Pay Type</label>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4">
                                <div class="mui-textfield" v-if="job.pay_type == 'H'">
                                    <span class="textfield">${{ job.hourly_rate }}</span>
                                    <label>Per Hour</label>
                                </div>
                                <div class="mui-textfield" v-if="job.pay_type == 'S'">
                                    <span class="textfield">${{ job.salary_rate }}</span>
                                    <label>Per Pay Period</label>
                                </div>
                            </div>
                            <div class="col-6 col-sm-4" v-if="job.pay_type == 'S'">
                                <div class="mui-textfield">
                                    <span class="textfield">{{ job.default_hours }}</span>
                                    <label>Default Hours</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <p class="field-helper-text mb-1">Paid {{ job.position.paygroup.pay_frequency_verbose }}.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Position -->
    </div>
</template>

<script>

export default {
    props: ['employee', 'client', 'canEdit'],
    computed: {
        hasBouncedEmail() {
            if (!this.employee) {return false}
            return this.employee.warnings.filter(w => !w.resolved_on && w.warning_type == 'bounced-email').length > 0
        },
        canEditEmail() {
            if (this.employee.is_employee && !this.$permissions.hasRolesInCompany(this.client.id, this.employee.company_id, this.$store.state.security_roles.EMPLOYEE_ROLES)) {
                return false  // Onboarding-only managers can't edit existing employees' email addresses
            }
            return this.employee.is_employee || (this.employee.is_onboarding_application && this.employee.status != 'canceled')
        },
    },
    methods: {
        editEmail() {
            this.$emit('edit-email')
        },
    },
}
</script>
