<template>
<div>
    <div class="flex-header">
        <div class="page-actions" v-if="showSearch || showFilter || showMoreActions">
            <div class="page-actions-search" v-if="showSearch">
                <a v-if="sections.length > 1" href class="collapse-trigger" :class="{'is-expanded': !allSectionsCollapsed}" @click.prevent="toggleCollapse()">
                    <collapse-toggle-button />
                </a>
                <page-search v-model="search" />
                <!--
                <button type="button" class="ml-1 btn btn-outline-primary" :disabled="refreshing" @click="refresh()">
                    <i class="fas fa-sync" :class="{'fa-spin': refreshing}"></i>
                </button>
                -->
            </div>

            <table-filter
                v-if="showFilter"
                :sections="sections"
                :headers="headersWithIds"
                :data="searchedData"
                :filteredData="filteredData"
                @filter="onFilter"
                :managers="managers"
                @filter-edit-open="$emit('filter-edit-open')"
                @filter-edit-close="$emit('filter-edit-close')"
                :use-overlay-filter-editor="useOverlayFilterEditor"
                :encode-filters-to-url="encodeFiltersToUrl"
                ref="tablefilter"
            />

            <div class="page-actions-main">
                <slot name="table-actions"></slot>
                <date-filter-trigger v-if="useDateFilters && sections.length == 1" :date-filter="defaultDateFilter" @click="onDateFilterClick('section-' + sections[0].id)" />
                <more-actions-menu ref="moreActionsMenu" v-if="showMoreActions" />
            </div>
        </div>
    </div>

    <div class="flex-body content-main">
        <div class="table-wrapper table-responsive" v-if="rows.length" ref="table">
            <div class="table css-table" tabindex="1" :class="tableClasses" style="height: 100%">

                <!-- <virtual-list ref="tableBody"
                    :data-key="'id'"
                    :data-sources="rows"
                    :data-component="rowComponent"

                    :extra-props="extraProps"
                    :keeps="50"
                    :estimate-size="ROW_HEIGHT"

                    style="overflow: auto; height: 100%;"
                    :page-mode="false"

                    class="virtual-table-wrapper"
                    wrap-class="virtual-table"
                    item-class="virtual-table-item"
                /> -->

                <VirtualScroller
                    :items="rows"
                    :itemSize="ROW_HEIGHT"
                    class="virtual-table-wrapper"
                    style="width: 100%; height: 100%"
                    ref="tableBody"
                >
                    <template v-slot:item="{ item, options }">
                        <row
                            :source="item"
                            :activeRowId="`row-${activeRowId}`"
                            :headers="sortedHeaders"
                            :onclick="onClick"
                            :onselect="onSelect"
                            :onRadioSelect="onRadioSelect"
                            :dateFilterClickHandler="onDateFilterClick"
                            :onSort="onSort"
                            :onToggleSection="onToggleSection"
                            :onToggleSelectAll="onToggleSelectAll"
                            :onColumnSort="onColumnSort"
                            :sortColumnId="sortColumnId"
                            :sortIsAsc="sortIsAsc"
                            :stickyWidth="stickyWidth"
                            :hasRowSelect="hasRowSelect"
                            :hasRowRadioSelect="hasRowRadioSelect"
                            :searchIsActive="Boolean(search)"
                            :filterIsActive="Boolean(filters.length)"
                            class="virtual-table-item"
                        >
                        </row>
                    </template>
                </VirtualScroller>
            </div>
        </div>
    </div>

    <date-filter-modal
        v-if="dateFilterSection"
        :recordLabel="recordLabel"
        :sectionId="dateFilterSection.id"
        :mode="sectionDateFilters['section-' + dateFilterSection.id].mode"
        :start="sectionDateFilters['section-' + dateFilterSection.id].start"
        :end="sectionDateFilters['section-' + dateFilterSection.id].end"
        @change="onDateFilter"
        @close="dateFilterSection = null"
    />
</div>
</template>

<script>
import $ from 'jquery'
import Row from './Row'
import SortableMixin from './SortableMixin'
import SearchableMixin from './SearchableMixin'
import FilterableMixin from './FilterableMixin.vue'
import {queryParamsToObject} from './filter-utils'
import TableFilter from './Filter'
import MoreActionsMenu from '@/components/MoreActionsMenu'
import DateFilterModal from '@/components/DateFilterModal'
import DateUtils from './date-utils'
import DateFilterTrigger from './DateFilterTrigger'
import VirtualScroller from 'primevue/virtualscroller';

export default {
    props: {
        'recordLabel': String,
        'sections': Array,
        'tableClasses': String,
        'headers': Array,
        'defaultSortStack': Array,
        'data': Array,
        'activeRowId': {
            default: null,
        },
        'managers': Object,
        'fixedTableHeight': Number,
        'showFilter': {
            type: Boolean, default: true,
        },
        'showSearch': {
            type: Boolean, default: true,
        },
        'showMoreActions': {
            type: Boolean, default: true,
        },
        'useDateFilters': {
            type: Boolean, default: false,
        },
        'hasRowSelect': {
            type: Boolean, default: false,
        },
        'hasRowRadioSelect': {
            type: Boolean, default: false,
        },
        'useOverlayFilterEditor': {
            type: Boolean, default: false,
        },
        'encodeFiltersToUrl': {
            type: Boolean, default: false
        },
        'useFiltersFromUrl': {
            type: Boolean, default: false
        },
    },
    emits: ['click', 'click_cell'],
    mixins: [SortableMixin, SearchableMixin, FilterableMixin, ],
    components: {TableFilter, MoreActionsMenu, DateFilterModal, DateFilterTrigger, Row, VirtualScroller},
    computed: {
        defaultDateFilter() {
            // This is only used if we have two filters
            if (!this.useDateFilters) {return null}
            if (this.sections.length != 1) {return null}

            return this.sectionDateFilters[Object.keys(this.sectionDateFilters)[0]]
        },
        sortedHeaders() {
            const headers = []
            this.headerOrderIds.forEach(id => {
                headers.push(this.headersWithIds.find(h => h.id == id))
            })
            return headers
        },
        headerIdxMapping() {
            const result = {}
            this.headersWithIds.forEach((h, originalIdx) => {
                result[originalIdx] = this.headerOrderIds.indexOf(h.id)
            })

            return result
        },
        extraProps() {
            return {
                activeRowId: `row-${this.activeRowId}`,
                headers: this.sortedHeaders,
                onclick: this.onClick,
                onselect: this.onSelect,
                onRadioSelect: this.onRadioSelect,
                dateFilterClickHandler: this.onDateFilterClick,
                onSort: this.onSort,
                onToggleSection: this.onToggleSection,
                onToggleSelectAll: this.onToggleSelectAll,
                onColumnSort: this.onColumnSort,
                sortColumnId: this.sortColumnId,
                sortIsAsc: this.sortIsAsc,
                stickyWidth: this.stickyWidth,
                hasRowSelect: this.hasRowSelect,
                hasRowRadioSelect: this.hasRowRadioSelect,
                searchIsActive: Boolean(this.search),
                filterIsActive: Boolean(this.filters.length),
            }
        },
        rows() {
            if (!this.data) {
                return []
            }

            let rows = []
            this.sections.forEach((section, idx) => {
                let isClosed = false
                const sectionId = 'section-' + section.id

                if (this.sections.length > 1) {
                    isClosed = this.closedSections[sectionId]

                    const hasDateFilter = section.hasDateFilter === false ? false : this.useDateFilters
                    rows.push({
                        isHeading: true,
                        title: section.title,
                        count: this.displayData[idx].length,
                        isClosed: isClosed,
                        id: sectionId,
                        dateFilter: this.sectionDateFilters[sectionId],
                        useDateFilters: hasDateFilter,
                    })
                }

                if (!isClosed) {
                    const sectionRows = []
                    this.displayData[idx].forEach(row => {
                        const r = Object.assign({}, row)
                        r.id = 'row-' + row.id
                        r.sectionId = sectionId
                        r.isRow = true

                        const cells = Array.from(r.cells)  // Make a copy mostly to just initialize an array
                        Object.keys(this.headerIdxMapping).forEach(originalIdx => {
                            // This will re-arrange the cells for this row according to our current draggable column order
                            const newIdx = this.headerIdxMapping[originalIdx]
                            cells[newIdx] = r.cells[originalIdx]
                        })
                        r.cells = cells

                        sectionRows.push(r)
                    })

                    if (sectionRows.length) {
                        rows.push({
                            isHeaders: true,
                            id: 'section-headers-' + idx,
                        })
                        rows = rows.concat(this.sortRows(sectionRows))
                    }
                    else {
                        rows.push({
                            isEmpty: true,
                            id: `empty-${idx}`,
                        })
                    }
                }
            })

            return rows
        },
        allDataIds() {
            const result = []
            this.sections.forEach((section, sectionIdx) => {
                const rows = this.data[sectionIdx]
                result[sectionIdx] = rows.map(r => r.id)
            })
            return result
        },
        displayData() {
            const haveFilters = Boolean(this.filters && this.filters.length)
            const haveSearch = Boolean(this.search.trim())

            if (!haveFilters && !haveSearch) {
                // quick path
                return this.data
            }

            const result = []
            this.sections.forEach((section, sectionIdx) => {
                const foundIds = (this.foundDataIds === null) ? new Set(this.allDataIds[sectionIdx]) : new Set(this.foundDataIds[sectionIdx])
                const filteredIds = (this.filteredDataIds === null) ? new Set(this.allDataIds[sectionIdx]) : new Set(this.filteredDataIds[sectionIdx])

                result[sectionIdx] = this.data[sectionIdx].filter(r => filteredIds.has(r.id) && foundIds.has(r.id))
            })

            return result
        },
        allSectionsCollapsed() {
            let sectionsOpen = 0
            Object.keys(this.closedSections).forEach(sId => {
                if (!this.closedSections[sId]) {
                    sectionsOpen++
                }
            })

            return sectionsOpen < 1
        },
    },
    data() {
        const closedSections = {}
        const sectionDateFilters = {}
        this.sections.forEach(section => {
            closedSections['section-' + section.id] = section.defaultIsClosed
            const hasDateFilter = section.hasDateFilter === false ? false : this.useDateFilters
            if (hasDateFilter) {
                sectionDateFilters['section-' + section.id] = DateUtils.makeDateRange('last30', null, null)
            }
        })

        const headers = this.makeHeadersWithIds()
        return {
            ROW_HEIGHT: 40,
            CONTAINER_PADDING: 16,
            rowComponent: Row,
            tableHeight: 0,
            closedSections: closedSections,
            stickyWidth: $(this.$refs.table).width() || 0,

            filters: [],
            search: '',
            dateFilterSection: null,
            sectionDateFilters: sectionDateFilters,
            headersWithIds: headers,
            headerOrderIds: headers.map(h => h.id),
            selectedRows: [],
            selectedRow: null,
        }
    },
    mounted() {
        $(window).on('resize', this.onResize)
        this.$bus.$on('resize', this.onResize)
        window.dispatchEvent(new Event('resize'));
    },
    watch: {
        data: {
            handler() {
                const selectedRows = []
                this.rows.forEach(row => {
                    if (row.isRow && row.isSelected) {
                        selectedRows.push(row)
                    }
                })
                this.selectedRows = selectedRows
                this.onResize()
                this.performSearch()
            },
            immediate: true,
        },
        useFiltersFromUrl(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.$nextTick(() => { // nextTick so table data can be populated before we apply a filter
                    this.addFiltersFromUrl()
                })
            }
        }
    },
    methods: {
        addFiltersFromUrl() {
            if (this.$route.query.numFilters) {
                let qsFilters = queryParamsToObject(this.$route.query)
                if (qsFilters.numFilters && qsFilters.filters) {
                    this.$refs.tablefilter.addFilters(qsFilters.filters)
                }
            }
        },
        makeHeadersWithIds() {
            const result = []
            this.headers.forEach(h => {
                const slug = h.label.trim().toLowerCase().replace(/\s/g, '-')
                let id = slug
                for (let i = 1; result.indexOf(id) >= 0; i++) {
                    id = slug + '-' + i
                }

                // if the ID is explicitly specified, use that
                if (h.hasOwnProperty('id')) {
                    id = h.id
                }

                result.push(Object.assign({}, h, {'id': id}))
            })
            return result
        },
        getDefaultSortStack() {
            return this.defaultSortStack || []
        },
        getFixedTableHeight() {
            return this.fixedTableHeight
        },
        onResize() {
            this.$nextTick(() => {
                if (!this.$refs.tableBody) {return}

                // make sure this is before the height adjustment
                this.stickyWidth = $(this.$refs.table).width() - 17 // Magic number to account for vert scrollbar
                if (this.getFixedTableHeight()) {
                    $(this.$refs.table).height(this.getFixedTableHeight())
                    return
                }

                // const tableScrollOffset = this.$refs.tableBody.getOffset()

                const $t = $(this.$refs.table)
                const offset = $t.offset()
                if (!offset) {
                    return
                }

                if ($t.parents('.modal-body').length == 0) {
                    this.tableHeight = Math.max(0, $(window).height() - offset.top - this.CONTAINER_PADDING)
                }
                else {
                    this.tableHeight = $t.parents('.modal-body:first').height() - (
                        $t.offset().top -
                        $t.parents('.modal-body:first').offset().top
                    )
                }

                $t.height(this.tableHeight)

                // this.resetToOffset(tableScrollOffset)
            })
        },
        onClick(msg) {
            if (msg.columnIdx !== null) {
                if (this.headersWithIds[msg.columnIdx].isClickable) {
                    this.$emit('click_cell', {
                        object: msg.row.object,
                        columnIdx: msg.columnIdx,
                    })

                    return
                }
            }

            // if this table has radio buttons, consider a click as a radio select
            if (this.hasRowRadioSelect) {
                this.onRadioSelect(msg.row)
            }

            this.$emit('click', msg.row.object)
        },
        onSelect(row) {
            if (this.selectedRows.find(r => r.id == row.id)) {
                this.selectedRows = this.selectedRows.filter(r => r.id != row.id)
            }
            else {
                this.selectedRows.push(row)
            }

            this.$emit('select', this.selectedRows)
        },
        onRadioSelect(row) {
            if (row.selectDisabled) {
                return
            }

            this.selectedRow = row
            this.$emit('select', this.selectedRow)
        },
        onToggleSelectAll(sectionId) {
            const section = this.sections[sectionId.replace(/^section-headers-/, '')]
            const rows = this.rows.filter(r => (r.sectionId == 'section-' + section.id) && (!r.selectDisabled))
            const rowIds = rows.map(r => r.id)

            // Either [true, false], [true], or [false]. If [true], then all rows are selected, otherwise not all are selected
            const allSelected = !(new Set(rows.map(r => r.isSelected))).has(false)

            if (allSelected) {
                let selectedRows = Array.from(this.selectedRows)
                selectedRows = selectedRows.filter(r => !rowIds.includes(r.id))
                this.selectedRows = selectedRows
            }
            else {
                let selectedRows = Array.from(this.selectedRows)
                rows.forEach(row => {
                    if (!selectedRows.find(r => r.id == row.id)) {
                        selectedRows.push(row)
                    }
                })
                this.selectedRows = selectedRows
            }

            this.$emit('select', this.selectedRows)
        },
        onToggleSection(sectionId) {
            // const offset = this.$refs.tableBody ? this.$refs.tableBody.getOffset() : 0
            this.closedSections[sectionId] = !this.closedSections[sectionId]
            // this.resetToOffset(offset)
        },
        toggleCollapse() {
            // const offset = this.$refs.tableBody ? this.$refs.tableBody.getOffset() : 0
            let sectionsOpen = 0
            Object.keys(this.closedSections).forEach(sId => {
                if (!this.closedSections[sId]) {
                    sectionsOpen++
                }
            })

            Object.keys(this.closedSections).forEach(sId => {
                this.closedSections[sId] = sectionsOpen ? true : false
            })

            // this.resetToOffset(offset)
        },
        resetToOffset(offset) {
            this.$nextTick(() => {
                this.$refs.tableBody.reset()
                this.$nextTick(() => {
                    this.$refs.tableBody.scrollToOffset(offset)
                })
            })
        },
        onFilter(filters) {
            this.filters = filters
        },
        onDateFilterClick(sectionId) {
            this.dateFilterSection = this.sections.find(s => 'section-' + s.id == sectionId)
        },
        onDateFilter(dateFilter) {
            const sectionId = 'section-' + this.dateFilterSection.id
            this.sectionDateFilters[sectionId] = Object.assign({}, dateFilter)

            const eventData = {}
            this.sections.forEach(s => {
                const sectionId = 'section-' + s.id
                eventData[s.id] = this.sectionDateFilters[sectionId]
            })

            this.$emit('date_filter_change', eventData)
        },
        onColumnSort(ids) {
            this.headerOrderIds = ids
        },
    },
}
</script>
