<template>
<modal @close="$emit('close')" ref="modal" :is-closeable="isCloseable" size="xl">
    <template v-slot:title>Approve Employee</template>

    <template v-slot:subheading>
        <span class="text-muted">Company: {{ employee.company.company_code }} -  {{ employee.company.name }}</span>
    </template>

    <slot v-if="approvalBlockers">

        <!-- Show any errors. -->
        <div v-if="approvalBlockers.errors.length">
            <alert-box type="error" v-if="approvalBlockers.errors.length && !approvalBlockers.conflicting_employees.length">
                <p class="mb-1"><strong>Multiple duplicate records.</strong></p>
                <p class="mb-1" v-for="error in approvalBlockers.errors" :key="error">{{ error }}</p>
            </alert-box>
        </div>

        <!-- We don't have an employee to update. Note that a terminated employee that matches by SSN, email, and full name will show up as approvalBlockers.employee_to_update === null, aka this case -->
        <div v-if="!approvalBlockers.employee_to_update && !approvalBlockers.conflicting_employees.length && !approvalBlockers.errors.length">
            <div class="border p-2 mb-2">
                <h5 class="theading">Onboarding Employee</h5>
                <div>{{ employee.full_name }}</div>
                <div>{{ employee.email }}</div>
                <div>SSN: XXX-XX-{{ employee.ssn_last_4 }}</div>
            </div>

            <p>
                <strong>Confirm approval?</strong> This employee's data will be transferred into the payroll system.
            </p>
            <p>
                This process may take a few minutes.
            </p>
        </div>

        <!-- There is an employee to update, you are a payroll admin -->
        <!-- NB: we do not worry about the case where there is an employee_to_update but you are not the admin as the API in that case returns employee_to_update as null. -->
        <div v-if="approvalBlockers.employee_to_update && approverCanResolveConflicts">

            <alert-box type="caution" class="">
                <p class="mb-1"><strong>Possible duplicate record.</strong></p>
                <p class="mb-0">An employee in the payroll system has the same Social Security Number.</p>
            </alert-box>

            <p>Proceeding with approval will <strong>modify the existing record</strong> of the employee in payroll. Are you sure?</p>

            <div class="border p-2 mb-2">
                <div class="row">
                    <div class="col-12 col-md-8 mb-2">

                        <div class="row ee-data-item ee-data-item-heading">
                            <div class="col-12 col-sm-6">
                                <h5>Employee in Payroll</h5>
                            </div>
                            <div class="col-12 col-sm-6">
                                <h5 class="d-flex">
                                    <approval-field-icon :matches="true" />
                                    <span class="flex-1">Onboarding Employee</span>
                                </h5>
                            </div>
                        </div>

                        <div class="row ee-data-item" :class="{'ee-data-item-mismatch': approvalBlockers.employee_to_update.full_name != employee.full_name}">
                            <div class="col-12 col-sm-6">
                                <div>{{ approvalBlockers.employee_to_update.full_name }} <span class="text-muted">({{ approvalBlockers.employee_to_update.employee_number }})</span></div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <approval-field-icon :matches="approvalBlockers.employee_to_update.full_name == employee.full_name" />
                                <div>{{ employee.full_name }}</div>
                            </div>
                        </div>

                        <div class="row ee-data-item" :class="{'ee-data-item-mismatch': approvalBlockers.employee_to_update.email != employee.email}">
                            <div class="col-12 col-sm-6">
                                <div class="text-break" v-if="approvalBlockers.employee_to_update.email">{{ approvalBlockers.employee_to_update.email }}</div>
                                <div class="text-break" v-if="!approvalBlockers.employee_to_update.email">---</div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <approval-field-icon :matches="approvalBlockers.employee_to_update.email == employee.email" />
                                <div class="text-break">{{ employee.email }}</div>
                            </div>
                        </div>

                        <div class="row ee-data-item">
                            <div class="col-12 col-sm-6">
                                <div>SSN: XXX-XX-{{ approvalBlockers.employee_to_update.ssn_last_4 }}</div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <approval-field-icon :matches="true" />
                                <div>SSN: XXX-XX-{{ employee.ssn_last_4 }}</div>
                            </div>
                        </div>

                        <div class="row ee-data-item" :class="{'ee-data-item-mismatch': approvalBlockers.employee_to_update.status_pretty != 'Active'}">
                            <div class="col-12 col-sm-6">
                                <div>Status: {{ approvalBlockers.employee_to_update.employment_status }}</div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <approval-field-icon :matches="approvalBlockers.employee_to_update.status_pretty == 'Active'" />
                                <div>Status: Active</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-4">
                      <h5>What Will Change</h5>
                      <div>
                          <!--- This cannot happen as our system does not allow for this case.
                          <p class="mb-1" v-if="!approvalBlockers.employee_to_update.ssn_matches_onboarding">
                              The new SSN will <strong>replace</strong>
                              existing data.
                          </p>
                          -->

                          <p class="mb-1">New HR info and role details will <strong>replace</strong> existing data.</p>
                      </div>
                    </div>
                </div>
            </div>

            <p>
                <strong>Confirm approval?</strong> This employee's data will be transferred into the payroll system.
            </p>
            <p>
                This process may take a few minutes.
            </p>
        </div>

        <!-- If there's a conflict and you're an admin. -->
        <!-- NB: we do not worry about the case where there you are not an admin and there are conflicts because the API will not return us the conflicts if that's the case. -->
        <div v-if="approvalBlockers.conflicting_employees.length && approverCanResolveConflicts">
            <alert-box type="error" v-for="error in approvalBlockers.errors" :key="error">
                <p class="mb-1"><strong>Conflicting employee records</strong></p>
                <p class="mb-0">{{ error }}</p>
            </alert-box>

            <div class="border p-2 mb-2">
                <div class="row py-qt ee-data-item-multi">
                    <div class="col-12 col-sm-5 col-md-6">
                        <h5 class="theading">Onboarding Employee</h5>
                    </div>
                    <div class="col-12 col-sm-7 col-md-6">
                        <h5 class="theading d-none d-sm-block">Email Address</h5>
                    </div>
                </div>
                <div class="row py-hf ee-data-item-multi">
                    <div class="col-12 col-sm-5 col-md-6">
                        <div><span class="text-semibold">{{ employee.full_name }}</span></div>
                    </div>
                    <div class="col-12 col-sm-7 col-md-6">
                        <div class="text-break">{{ employee.email }}</div>
                    </div>
                </div>

                <div class="row py-qt mt-2 ee-data-item-multi">
                    <div class="col-12 col-sm-5 col-md-6">
                        <h5 class="theading">Employees in Payroll</h5>
                    </div>
                    <div class="col-12 col-sm-7 col-md-6">
                        <h5 class="theading d-none d-sm-block">Email Address</h5>
                    </div>
                </div>

                <div class="row py-hf ee-data-item-multi" v-for="conflict in approvalBlockers.conflicting_employees">
                    <div class="col-12 col-sm-5 col-md-6">
                        <div class="ee-data-item-icon">
                            <i aria-hidden="true" class="fas fa-exclamation-circle text-danger" title="Data conflict with:"></i>
                            <span class="sr-only">Data conflict with:</span>
                        </div>
                        <div>
                            <span class="text-semibold">{{ conflict.full_name }}</span>&nbsp;
                            <span class="text-muted">({{ conflict.employee_number }})</span>
                        </div>
                    </div>
                    <div class="col-12 col-sm-7 col-md-6">
                        <div class="text-break" v-if="conflict.email">{{ conflict.email }}</div>
                        <div class="text-break" v-if="!conflict.email">---</div>
                    </div>
                </div>
            </div>

            <form v-if="approverCanResolveConflicts && (approvalBlockers.employee_to_update || approvalBlockers.conflicting_employees.length)">
                <p>
                    <strong>Please resolve all duplicates in payroll before
                    proceeding.</strong> If you have already done this, you can
                    retry approval:
                </p>
                <p>
                    <form-input label="I have resolved all duplicates in the payroll system and I'm ready to retry." type="checkbox" v-model="formData.agreedToProceed" />
                </p>
            </form>
        </div>

    </slot>

    <template v-slot:footer v-if="approvalBlockers">
        <form-errors :errors="formErrors"/>
        <template v-if="approvalBlockers.errors.length && !approverCanResolveConflicts">
            <button-row centered>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close(true)">Close</button>
            </button-row>
        </template>
        <template v-else>
            <button-row stacked>
                <button type="button" class="btn btn-block btn-outline-primary mb-1" @click.prevent="close(true)">Cancel</button>
                <button type="submit" class="btn btn-block btn-primary mb-1" @click.prevent="onSubmit" :disabled="approveDisabled">Approve Employee</button>
            </button-row>
        </template>
    </template>
</modal>
</template>

<style scoped lang="scss">
  // Import Sass globals
  @import "@/assets/scss/globals";

  //
  // Component styles
  //

  // Data Match/Mismatch appearance

  .ee-data-item {
    margin-bottom: spacer(1);

    @include media-breakpoint-up(sm) {
      margin-bottom: spacer("qt");
    }

    [class*="col-"] {
      position: relative;
    }

    [class*="col-"] + [class*="col-"] {
      display: flex;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .ee-data-item-icon {
      width: 24px; // 1.5x column gutter


      @include media-breakpoint-up(sm) {
        text-align: center;
        position: absolute;
        left: -24px; // More breathing room when space allows
      }

      @include media-breakpoint-only(md) {
        left: -16px; // 1x column gutter, prevents overlap with leftcol text
      }
    }
  }

  .ee-data-item-heading {

    h5 {

      @include media-breakpoint-down(rs) {
        margin-bottom: spacer("hf");
      }
    }

    @include media-breakpoint-up(sm) {

      .ee-data-item-icon {
        visibility: hidden;
      }
    }
  }

  .ee-data-item-match {

    .ee-data-item-icon {
      color: theme-color("success");
    }
  }

  .ee-data-item-mismatch {
    font-weight: bold;
    color: theme-color("danger-dark");
  }

  .ee-data-item-multi {
    padding-left: 0;

    @include media-breakpoint-up(sm) {
      padding-left: 24px; // 1.5x column gutter
    }

    [class*="col-"]:first-child {
      position: relative;
      display: flex;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .ee-data-item-icon {
      width: 24px;

      @include media-breakpoint-up(sm) {
        position: absolute;
        left: -8px; // 0.5x column gutter
      }
    }
  }
</style>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import ApprovalFieldIcon from './ApprovalFieldIcon'
import ButtonRow from '@/components/ButtonRow'

export default {
    components: {ApprovalFieldIcon, ButtonRow, },
    mixins: [ModalMixin],
    props: {employee: {required: true}, client: {required: true}},
    computed: {
        approveDisabled() {
            // disable just until we get a response from checkOnboardingApplication()
            return !this.approvalBlockers
        },
        approverCanResolveConflicts() {
            return this.$permissions.hasRolesInCompany(this.client.id, this.employee.company.id, this.$store.state.security_roles.COMPANY_CONFLICT_RESOLVE_ROLES)
        },
    },
    data() {
        return {
            approvalBlockers: null,
            formData: {
                agreedToProceed: false,
            },
            formErrors: [],
        }
    },
    methods: {
        open() {
            this.checkOnboardingApplication().then(() => {
                ModalMixin.methods.open.call(this)
            }).catch(err => {
                this.$bus.showError(err.__all__)
            })
        },
        checkOnboardingApplication() {
            this.$store.dispatch('START_LOADING')
            return this.$api.get(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}/approval-check`).then(resp => {
                this.approvalBlockers = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        validate() {
            if (this.approvalBlockers.errors.length && !this.approverCanResolveConflicts) {
                // If you are not a client-level admin and there are blockers, you can't proceed
                this.formErrors.push("You do not have permission to approve with these conflicts.")
                return false
            }

            if (this.approverCanResolveConflicts && this.approvalBlockers.conflicting_employees.length && !this.formData.agreedToProceed) {
                this.formErrors.push("Check the box indicating your agreement to continue.")
                return false
            }

            return true
        },
        onSubmit() {
            this.formErrors = []

            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.patch(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}`, {status: 'hired'}).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.close()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        reset() {
            this.formData.agreedToProceed = false
        },
    },
}
</script>
