<template>
    <div>
        <div class="flex-body body-scroll">
            <div class="container-fluid">

                <div class="my-4">
                    <form class="mui-form" @submit.prevent="onSubmit()" v-if="mode == 'address'">
                        <form-errors :errors="formErrors"/>
            
                        <div class="form-row">
                            <div class="col-12 col-md-6">
                                <form-input v-model="addressFormData.work_city" :errors="errors.work_city" type="text" label="Work City" />
                                <form-input v-model="addressFormData.work_state" :errors="errors.work_state" type="select" label="Work State" :options="stateOptions"/>
                            </div>
            
                            <div class="col-12 col-md-6">
                                <form-input v-model="addressFormData.resident_city" :errors="errors.resident_city" type="text" label="Resident City" />
                                <form-input v-model="addressFormData.resident_state" :errors="errors.resident_state" type="select" label="Resident State" :options="stateOptions"/>
            
                                <form-input v-model="addressFormData.has_city_nexus" :errors="errors.has_city_nexus" type="checkbox" label="Has city nexus" />
                                <form-input v-model="addressFormData.has_state_nexus" :errors="errors.has_state_nexus" type="checkbox" label="Has state nexus" />
                            </div>
                        </div>
                        
                        <hr/>
            
                        <div class="form-row">
                            <div class="col-12 col-md-6">
                                <form-input v-model="addressFormData.work_city2" :errors="errors.work_city2" type="text" label="Second Work City" />
                                <form-input v-model="addressFormData.work_state2" :errors="errors.work_state2" type="select" label="Second Work State" :options="stateOptions"/>
            
                                <hr/>
            
                                <form-input v-model="addressFormData.work_city3" :errors="errors.work_city3" type="text" label="Third Work City" />
                                <form-input v-model="addressFormData.work_state3" :errors="errors.work_state3" type="select" label="Third Work State" :options="stateOptions"/>
                            </div>
                        </div>
            
                        <p class="my-4">
                            <button type="submit" class="btn btn-primary">Continue</button>
                        </p>
                    </form>

                    <form class="mui-form" @submit.prevent="onSubmit()" v-if="mode == 'questions'">
                        <h4>Questions</h4>
                        <form-errors :errors="formErrors"/>
            
                        <div v-for="question in questions" :key="question.id">
                            <p v-html="question.html"></p>
                            <div class="row" v-for="option in question.options" :key="option.id">
                                <div class="col-1 text-right">
                                    <input :name="'form-selection-question-' + question.id" :id="'form-selection-option-' + option.id" type="radio" :value="option.option_id" @click="questionFormData[question.id].option_id = option.id" :checked="questionFormData[question.id].option_id == option.id" />
                                </div>
            
                                <div class="col-11">
                                    <label :for="'form-selection-option-' + option.id" v-html="option.html"></label>
                                </div>
                            </div>
                        </div>

                        <p class="my-4">
                            <button type="submit" class="btn btn-primary">Continue</button>&nbsp; &nbsp;
                            <button type="button" class="btn btn-secondary" @click.prevent="reset()">Start Over</button>
                        </p>
                    </form>

                    <div v-if="mode == 'forms'">
                        <div class="border-bottom pb-2 mb-2" v-if="forms.federal_tax_forms.length">
                            <h4>Federal Forms</h4>
                            <div class="card doc-item mb-2" v-for="form in forms.federal_tax_forms">
                                <div class="card-body">
                                    <div class="todo-item">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ form.name }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="border-bottom pb-2 mb-2" v-if="forms.i9.length">
                            <h4>I-9</h4>
                            <div class="card doc-item mb-2" v-for="form in forms.i9">
                                <div class="card-body">
                                    <div class="todo-item">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ form.name }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="border-bottom pb-2 mb-2" v-if="forms.work_state_tax_forms.length">
                            <h4>Work State Forms</h4>
                            <div class="card doc-item mb-2" v-for="form in forms.work_state_tax_forms">
                                <div class="card-body">
                                    <div class="todo-item">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ form.name }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="border-bottom pb-2 mb-2" v-if="forms.work_city_tax_forms.length">
                            <h4>Work City Forms</h4>
                            <div class="card doc-item mb-2" v-for="form in forms.work_city_tax_forms">
                                <div class="card-body">
                                    <div class="todo-item">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ form.name }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="border-bottom pb-2 mb-2" v-if="forms.resident_state_tax_forms.length">
                            <h4>Resident State Forms</h4>
                            <div class="card doc-item mb-2" v-for="form in forms.resident_state_tax_forms">
                                <div class="card-body">
                                    <div class="todo-item">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ form.name }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="border-bottom pb-2 mb-2" v-if="forms.resident_city_tax_forms.length">
                            <h4>Resident City Forms</h4>
                            <div class="card doc-item mb-2" v-for="form in forms.resident_city_tax_forms">
                                <div class="card-body">
                                    <div class="todo-item">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ form.name }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="my-4">
                            <button type="button" class="btn btn-primary" @click.prevent="reset()">Start Over</button>
                        </p>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import FormMixin from '@/mixins/Form'

export default {
    mixins: [FormMixin, ],
    computed: {
        stateOptions() {
            const opts = []
            this.states.forEach(s => {
                opts.push({text: s.name, value: s.name})
            })
            return opts
        },
    },
    data() {
        return {
            mode: 'address',  // Can be 'address', 'questions', and 'forms'
            states: [],

            addressFormRules: {
                work_state: {presence: {allowEmpty: false},},
                resident_state: {presence: {allowEmpty: false},},
            },
            addressFormData: {},

            questionFormRules: {},
            questionFormData: {},

            questions: [],
            forms: [],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'PDF Forms Tester')
        this.getStates()
    },
    methods: {
        getStates() {
            api.get(`/admin/tax-engine/states`).then(resp => {
                this.states = resp
            }).catch((errors) => {
                bus.showError(errors.__all__[0])
            })
        },
        getFormRules(fields) {
            let formRules;

            if (this.mode == 'address') {
                formRules = this.addressFormRules
            }
            if (this.mode == 'quetions') {
                formRules = this.questionFormRules
            }

            fields = (fields && fields.length) ? fields : Object.keys(formRules || {})
            const rules = {}
            fields.forEach((field) => {
                if (field in (formRules || {})) {
                    rules[field] = formRules[field]
                }
            })

            return rules
        },
        getFormData() {
            if (this.mode == 'address') {
                return this.addressFormData
            }
            if (this.mode == 'questions') {
                return Object.assign({questions: this.questionFormData}, this.addressFormData)
            }
        },
        onSubmit() {
            this.errors = {}
            this.fromErrors = []

            if (!this.validate()) {
                return
            }

            if (this.mode == 'address') {
                this.$store.dispatch('START_LOADING')
                api.post(`/admin/pdf-forms/selection-tester/questions`, this.getFormData()).then(resp => {
                    this.questions = resp
                    this.questions.forEach((q) => {
                        this.questionFormData[q.id] = {option_id: null}
                    })

                    this.mode = 'questions'

                    if (!this.questions.length) {
                        this.onSubmit()
                    }

                    this.$store.dispatch('STOP_LOADING')
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })
            }
            else if (this.mode == 'questions') {
                api.post(`/admin/pdf-forms/selection-tester/forms`, this.getFormData()).then(resp => {
                    this.forms = resp
                    this.mode = 'forms'
                    this.$store.dispatch('STOP_LOADING')
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })
            }

        },
        reset() {
            this.errors = {}
            this.formErrors = []
            this.mode = 'address'
            this.addressFormData = {}

            this.questionFormData = {}
            this.questions = []
            this.forms = []
            this.getStates()
        },
    },
}

</script>
