<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ $filters.date(version.starts_on, "MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ $filters.date(version.ends_on, "MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <div>
        <span class="text-semibold">Minimum Wage: </span>
        <span>${{ $filters.formatCurrency(version.minimum_wage) }}</span>
    </div>

    <div>
        <span class="text-semibold">Max Tip Credit: </span>
        <span>${{ $filters.formatCurrency(version.max_tip_credit_claimed) }}</span>
    </div>

    <div>
        <span class="text-semibold">Minor Minimum Wage: </span>
        <span v-if="version.minor_minimum_wage">${{ $filters.formatCurrency(version.minor_minimum_wage) }}</span>
        <span v-else>N/A</span>
    </div>

    <div>
        <span class="text-semibold">Minor Max Tip Credit: </span>
        <span v-if="version.minor_max_tip_credit_claimed">${{ $filters.formatCurrency(version.minor_max_tip_credit_claimed) }}</span>
        <span v-else>N/A</span>
    </div>

    <div>
        <span class="text-semibold">Minor Age: </span>
        <span v-if="version.minor_age_years">{{ version.minor_age_years }} years, {{ version.minor_age_months }} months</span>
        <span v-else>N/A</span>
    </div>

</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', ],
    data() {
        return {
            isExpanded: false,
        }
    },
}
</script>
