<template>
    <modal size="lg" @close="$emit('close')" ref="modal" :is-closeable="true" :autoshow="true" :height-max="this.step != 'add-type'">
        <template v-slot:title>
            <h3 class="modal-title">Add Document<span v-if="stepAsText" class="text-thin text-nowrap">: {{ stepAsText }}</span></h3>
        </template>

        <template v-slot:subheading>
            <div>
                <div class="p-1 unpad-x bg-150 rounded">
                    <div><span class="text-muted">Employee: </span>{{ employee.full_name }}</div>
                    <div><span class="text-muted">Company: </span>{{ employee.company.name }}</div>
                    <div v-if="selectedDocument" >
                        <span class="text-muted">Document: </span>{{ selectedDocument.name }}
                    </div>
                    <div v-if="this.mode == 'upload' && selectedDocument && formData.selected_doc_version_number">
                        <span class="text-muted">Version: </span>{{ formData.selected_doc_version_number }}
                    </div>
                </div>

                <div v-if="step == 'upload-match-doc'" class="pt-1">
                    <div v-if="uploadLibraryMatchDoc">
                        <p class="text-semibold mb-1">
                            Which document did you upload? Our Document Match tool suggests: <a href @click.prevent="onSelectTaggableDoc(uploadLibraryMatchDoc)"> {{ uploadLibraryMatchDoc.name }}</a>
                            <more-info class="moreinfo-inline">
                                Categorizing your uploaded document helps keep your records organized and accurate.
                                Our Document Match tool compared your uploaded file to the documents in your Library to suggest a match.
                            </more-info>
                        </p>
                        <p class="mb-0 small">
                            Not the right match? Select a document from your Library below to preview and compare it to your upload, or select <span class="text-nowrap">"I uploaded a document not on the above list".</span>
                        </p>
                    </div>
                    <div v-else>
                        <p class="text-semibold mb-1">
                            Which document did you upload?
                            <more-info class="moreinfo-inline">
                                Categorizing your uploaded document helps keep your records organized and accurate.
                                <span v-if="client.preview_hr_docs_match_tool">Our Document Match tool compared your uploaded file to the documents in your Library but did not find a suggested match.</span>
                            </more-info>
                        </p>
                        <p class="mb-0 small">
                            Select a document from your Library below to preview and compare it to your upload, or select <span class="text-nowrap">"I uploaded a document not on the above list".</span>
                        </p>
                    </div>
                </div>

                <div v-if="step == 'upload-match-version'">
                    <p v-if="selectedDocument && selectedDocument.versions.length > 1" class="pt-1 mb-0 text-semibold">
                        Which version of this document did you upload?
                        <more-info class="moreinfo-inline">
                            Categorizing this document helps keep your records organized and accurate.
                            Select a version to preview and compare to your upload.
                        </more-info>
                    </p>
                    <p v-else class="pt-1 mb-0 text-semibold">
                        Does this match the document you are uploading?
                    </p>
                </div>
            </div>
        </template>

        <!-- Modal body -->
        <slot>
            <input class="d-none" ref="fileInput" type="file" @change="onFileUpload($event)" accept=".pdf" />

            <div v-if="step == 'add-type'">
                <p>How would you like to add a document to this employee?</p>
                <div class="row justify-content-around">
                    <div class="col-6 col-md-5 pr-1 text-center justify-content-center">
                        <div @click.prevent="$refs.fileInput.click()" class="add-method-tile pt-4 pb-2">
                            <i class="fas fa-file-plus pt-2"></i>
                            <p class="py-2 mb-0"><strong>Upload</strong></p>
                        </div>
                         <p class="pt-1 mb-0">Upload a document from your device.</p>
                    </div>
                    <!-- TODO KB disable this option and prompt upgrade if client does not have an HR Docs Suite module -->
                    <div class="col-6 col-md-5 pl-1 text-center justify-content-center">
                        <div @click="mode = 'require'; step = 'require-select-doc'" class="add-method-tile pt-4 pb-2">
                            <i class="fas fa-books pt-2"></i>
                            <p class="py-2 mb-0"><strong>Choose from Library</strong></p>
                        </div>
                        <p class="pt-1 mb-0">Choose a document from GetMyPayStub to send for completion.</p>
                    </div>
                </div>
            </div>

            <div v-if="step == 'upload-file'">
                <form class="mui-form">
                    <div class="text-small mb-1">
                        Not quite right? <button class="btn btn-link-inline" @click.prevent="$refs.fileInput.click()">Upload a different document instead</button>
                    </div>
                    <pdf-inline
                        v-if="uploadedPdfPreviewUrl"
                        :url="uploadedPdfPreviewUrl"
                        :title="formData.file.name"
                        :hide-controls="true"
                    />
                </form>
            </div>

            <div v-if="step == 'upload-match-doc'">
                <fast-table
                    v-if="taggableDocs.length"
                    :sections="taggableDocsTableSections"
                    :headers="taggableDocsTableHeaders"
                    :data="taggableDocsTableData"
                    @click="onSelectTaggableDoc"
                    @filter-edit-open="$emit('filter-edit-open')"
                    @filter-edit-close="$emit('filter-edit-close')"
                    :use-overlay-filter-editor="true"
                    :show-search="true"
                    :show-filter="false"
                    :show-more-actions="false"
                />
            </div>

            <div v-if="step == 'upload-match-version' && selectedDocument">
                <div class="version-selector-tabs row">
                    <a href @click.prevent="versionSelectTab = 'library'" class="version-selector-tab pb-hf pr-2" :class="{'text-muted': (versionSelectTab == 'upload'), 'selected': (versionSelectTab == 'library')}">
                        {{ selectedDocument.name }}
                    </a>
                    <a href @click.prevent="versionSelectTab = 'upload'"  class="version-selector-tab pb-hf" :class="{'text-muted': (versionSelectTab == 'library'), 'selected': (versionSelectTab == 'upload')}">
                        Uploaded Document
                    </a>
                </div>
                <div class="row pt-1">
                    <div class="col col-version-pdf pl-2 pr-2 pt-0 ml-1 h-100">
                        <div v-show="versionSelectTab == 'library'">
                            <pdf-inline
                                v-if="docVersionPreviewPDF && docVersionPreviewPDF.url"
                                :title="docVersionPreviewPDF.title"
                                ref="docVersionPreviewPDF"
                                :url="docVersionPreviewPDF.url"
                                :hide-controls="true"
                            />
                        </div>
                        <div v-show="versionSelectTab == 'upload'">
                            <pdf-inline
                                v-if="uploadedPdfPreviewUrl"
                                :url="uploadedPdfPreviewUrl"
                                :hide-controls="true"
                            />
                        </div>
                    </div>
                    <div class="col-version-selector mr-1 bg-150" :class="{'disabled': versionSelectTab == 'upload'}">
                        <h4 class="pl-2 pt-1 pb-1 mb-0">Versions</h4>
                        <div v-for="v in selectedDocument.versions" class="doc-version" :class="{'selected': v.version_number == formData.selected_doc_version_number}" @click.prevent="formData.selected_doc_version_number = v.version_number; setDocVersionPreviewPDF(v)">
                            <a class="todo-item" :class="{'todo-item-completed': v.version_number == formData.selected_doc_version_number}">
                                <span class="todo-item-content" :class="{'text-bold': v.version_number == formData.selected_doc_version_number}">Version {{ v.version_number }}</span>
                            </a>
                            <em class="text-small" v-if="v.version_number == selectedDocument.version_number">Latest Version<br/></em>
                            <span class="text-small">{{ $filters.datetime(v.published_on) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="step == 'upload-name'">
                <div class="form-row pb-2">
                    <div class="col">
                        <form-input label="Document Name" v-model="formData.name" :errors="errors.name" type="text"/>
                    </div>
                </div>
            </div>

            <div v-if="step == 'upload-request-signature'">
                <p class="text-semibold">Do you need to request a digital employee signature on this document?</p>
                <div class="form-check">
                    <label class="mb-0">
                        <input name="ad_hoc_employee_signature_required" type="radio" @click="formData.ad_hoc_employee_signature_required = true" :checked="formData.ad_hoc_employee_signature_required == true" class="form-check-input">
                        <strong>Yes</strong>, request employee signature.
                    </label>
                    <p class="text-small mt-1">
                        Send this uploaded document to the employee and prompt them to review and sign.
                    </p>
                    <div class="pl-2 pr-1 pt-2 pb-1 mb-2 bg-100 rounded" v-show="formData.ad_hoc_employee_signature_required">
                        <p class="text-semibold">Is a manager signature required to approve this document after the employee has signed?</p>
                        <div class="form-check">
                            <label>
                                <input name="ad_hoc_manager_signature_required" type="radio" @click="formData.ad_hoc_manager_signature_required = true" :checked="formData.ad_hoc_manager_signature_required == true" class="form-check-input">
                                <strong>Yes</strong>, manager signature is required.
                            </label>
                        </div>
                        <div class="form-check">
                            <label>
                                <input name="ad_hoc_manager_signature_required" type="radio" @click="formData.ad_hoc_manager_signature_required = false" :checked="formData.ad_hoc_manager_signature_required == false" class="form-check-input">
                                <strong>No</strong>, manager signature is not required.
                            </label>
                        </div>
                        <p class="text-semibold mt-2 mb-2">Should employee be blocked from accessing paystubs, W-2s, etc until they complete this document?</p>
                        <div class="form-check">
                            <label>
                                <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = true" :checked="formData.block_ee_until_completion == true" class="form-check-input">
                                <strong>Block</strong> access to paystubs, W-2s, etc. until employee has completed this document
                            </label>
                        </div>
                        <div class="form-check">
                            <label>
                                <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = false" :checked="formData.block_ee_until_completion == false" class="form-check-input">
                                <strong>Allow</strong> employee to access paystubs, W-2s, etc. before completing this document
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-check">
                    <label class="mb-0">
                        <input name="is_ad_hoc" type="radio" @click="formData.ad_hoc_employee_signature_required = false" :checked="formData.ad_hoc_employee_signature_required == false" class="form-check-input">
                        <strong>No</strong>, save this document file as-is into this employee’s record.
                    </label>
                </div>
            </div>

            <div v-if="step == 'upload-settings'">
                <info-widget>
                    <template v-slot:title>Document Details</template>
                    <div class="card-body pt-2 pb-1">
                        <form-input v-if="!selectedDocument" label="Document Name" v-model="formData.name" :errors="errors.name" type="text"/>
                        <form-input label="Date of Document Completion (Optional)" v-model="formData.completed_on" :errors="errors.completed_on" type="date" />
                    </div>
                </info-widget>
                <info-widget v-if="!selectedDocument">
                    <template v-slot:title>Document Visibility</template>
                    <div class="card-body pt-2 pb-1">
                        <div class="form-check">
                            <label class="mb-0">
                                <input name="hidden_from_employee" type="radio" @click="formData.hidden_from_employee = false" :checked="formData.hidden_from_employee == false" class="form-check-input">
                                <strong>Visible to Employee</strong>
                            </label>
                            <p class="mt-0">
                                This employee can view this document in their HR Documents.
                            </p>
                        </div>
                        <div class="form-check">
                            <label class="mb-0">
                                <input name="hidden_from_employee" type="radio" @click="formData.hidden_from_employee = true" :checked="formData.hidden_from_employee == true" class="form-check-input">
                                <strong>Hidden from Employee</strong>
                            </label>
                            <p class="mt-0">
                                This employee cannot see this document. Only security users can see this document in the employee’s record.
                            </p>
                        </div>
                    </div>
                </info-widget>
                <info-widget v-if="this.formData.selected_doc_type == 'hr-custom-form' && this.selectedDocument.is_expiring_doc">
                    <template v-slot:title>Expiration Date</template>
                    <div class="card-body pt-2 pb-1">
                            <form-input label="Document Expiration Date" v-model="formData.expires_on" :errors="errors.expires_on" type="date" />
                    </div>
                </info-widget>
            </div>

            <div v-if="step == 'require-select-doc' && !docVersionPreviewPDF && !previewCustomForm">
                <p class="text-semibold mb-hf">Which document do you want to send for completion?</p>
                <fast-table
                    v-if="requirableDocsLoaded"
                    :sections="requirableDocsTableSections"
                    :headers="requirableDocsTableHeaders"
                    :data="requirableDocsTableData"
                    @click="onSelectRequirableDoc"
                    @filter-edit-open="$emit('filter-edit-open')"
                    @filter-edit-close="$emit('filter-edit-close')"
                    :use-overlay-filter-editor="true"
                    :show-search="true"
                    :show-filter="false"
                    :show-more-actions="false"
                />
            </div>

            <div v-if="step == 'require-select-doc' && docVersionPreviewPDF">
                <p class="text-semibold">Confirm that this is the document you want to send.</p>
                <pdf-inline
                    :url="docVersionPreviewPDF.url"
                    :title="docVersionPreviewPDF.title"
                    :key="docVersionPreviewPDF.url.url"
                    ref="docVersionPreviewPDF"
                />
            </div>

            <div v-if="step == 'require-select-doc' && previewCustomForm">
                <p class="text-semibold">Confirm that this is the document you want to send.</p>
                <custom-form-survey
                    :custom-form="previewCustomForm"
                    :is-preview="true"
                />
            </div>

            <div v-if="step == 'require-settings'">
                <div v-if="selectedDocument">
                <p>These are the settings that will be used to send and collect this document.</p>
                <info-widget>
                    <template v-slot:title>Collection Settings</template>
                    <div class="card-body pt-2 pb-1">
                        <div v-if="selectedDocument.collection_method == 'employee'">
                            <p class="mb-hf"><strong>Employee</strong> will receive an email and will be prompted to complete this document online.</p>
                            <p v-if="selectedDocument.block_ee_until_completion" class="text-small">Employee will be blocked from accessesing paystubs, W-2s, etc. until they have completed this document.</p>
                            <p v-else class="text-small">Employee will be allowed to access paystubs, W-2s, etc. before this document is completed.</p>
                        </div>
                        <div v-else-if="selectedDocument.collection_method == 'manager'">
                            <p class="mb-hf"> <strong>Managers</strong> will be given a task to {{ selectedDocument.doc_type != 'hr-doc' ? 'complete or' : '' }} upload this document on behalf of the employee.</p>
                            <p class="text-small">Employee will be allowed to access paystubs, W-2s, etc. before this document is completed.</p>
                            <alert-box type="info">Only managers may complete this document. The document <strong>will</strong> be visible to the employee after completion.</alert-box>
                        </div>
                        <div v-else-if="selectedDocument.collection_method == 'do-not-collect'">
                            <!-- If a manager is requiring a doc that was set to 'do-not-collect' in the library, then set collection to 'employee' for this individual task instead and let the manager choose whether to block access until completion -->
                            <p class="mb-hf"><strong>Employee</strong> will receive an email and will be prompted to complete this document online.</p>
                            <p class="text-semibold mt-2 mb-2">Should employee be blocked from accessing paystubs, W-2s, etc until they complete this document?</p>
                            <div class="form-check">
                                <label>
                                    <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = true" :checked="formData.block_ee_until_completion == true" class="form-check-input">
                                    <strong>Block</strong> access to paystubs, W-2s, etc. until employee has completed this document
                                </label>
                            </div>
                            <div class="form-check">
                                <label>
                                    <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = false" :checked="formData.block_ee_until_completion == false" class="form-check-input">
                                    <strong>Allow</strong> employee to access paystubs, W-2s, etc. before completing this document
                                </label>
                            </div>
                        </div>
                    </div>
                </info-widget>
                <info-widget v-if="selectedDocument.doc_type != 'uniform'">
                    <template v-slot:title>Signature Settings</template>
                    <div class="card-body pt-2 pb-1">
                        <div v-if="selectedDocument.current_version.manager_signature_required">
                            <p class="mb-hf"><strong>Manager signature is required to approve this document.</strong></p>
                            <p>A manager must review and sign this document after {{ selectedDocument.collection_method == 'employee' ? 'submission' : 'completion' }}.</p>
                        </div>
                        <div v-else>
                            <p class="mb-hf"><strong>Manager signature is not required.</strong></p>
                            <p v-if="selectedDocument.collection_method == 'employee'">This document will become active immediately after submission.</p>
                        </div>
                    </div>
                </info-widget>
                </div>
            </div>
        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <div v-if="step == 'upload-match-doc'" class="pt-1 border-top">
                <button type="button" class="btn btn-link btn-block" @click.prevent="onSelectSkipTagging()">
                    I uploaded a document not on the above list
                </button>
                <button type="button" class="btn btn-outline-primary btn-block" @click.prevent="onPrevStep()">Back</button>
            </div>

            <button-row v-else>
                <!-- Prev/Cancel-->
                <button v-if="step == 'add-type'" type="button" class="btn btn-outline-primary" @click.prevent="close()">
                    Cancel
                </button>
                <button v-else type="button" class="btn btn-outline-primary" @click.prevent="onPrevStep()">
                    Back
                </button>
                <!-- Next/Submit -->
                <button v-if="step == 'upload-match-version' || (this.step == 'require-select-doc') && (this.previewCustomForm || this.docVersionPreviewPDF)" class="btn btn-primary" @click.prevent="onNextStep()">
                    Select this Document
                </button>
                <button v-else-if="step == 'upload-settings'" class="btn btn-primary" @click.prevent="onSubmitUpload()">
                    Save Document
                </button>
                <button v-else-if="step == 'require-settings'" class="btn btn-primary" @click.prevent="onSubmitRequire()">
                    Send Document
                </button>
                <button v-else-if="isAdHocDoc && step == 'upload-name'" class="btn btn-primary" @click.prevent="onSubmitAdHoc()">
                    Send Document
                </button>
                <button v-else-if="step != 'add-type' && step != 'upload-match-doc' && step != 'require-select-doc'" class="btn btn-primary" @click.prevent="onNextStep()">
                    Continue
                </button>
            </button-row>

        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import moment from 'moment'
import FastTable from '@/components/FastTable'
import InfoWidget from '@/components/InfoWidget'
import PdfInline from '@/components/PdfInline'
import PdfModal from '@/components/PdfModal'
import CustomFormSurvey from '@/components/CustomFormSurvey'

const STEP_METADATA = {
    'add-type': {'title': ''},
    'upload-file': {'title': 'Upload'},
    'upload-request-signature': {'title': 'Signature'},
    'upload-name': {'title': 'Name'},
    'upload-match-doc': {'title': 'Match'},
    'upload-match-version': {'title': 'Match'},
    'upload-settings': {'title': 'Settings'},
    'require-select-doc': {'title': 'Send a Document'},
    'require-settings': {'title': 'Settings'},
}

const UPLOAD_AD_HOC_STEPS = [
    'add-type',
    'upload-file',
    'upload-request-signature',
    'upload-name'
]

const UPLOAD_ARCHIVE_STEPS = [
    'add-type',
    'upload-file',
    'upload-request-signature',
    'upload-match-doc',
    'upload-match-version',
    'upload-settings'
]

const REQUIRE_STEPS = [
    'add-type',
    'require-select-doc',
    'require-settings'
]

export default {
    components: {FastTable, InfoWidget, PdfInline, PdfModal, CustomFormSurvey},
    mixins: [ModalMixin, FormMixin, ],
    props: ['employee', 'client'],
    data() {
        return {
            formData: {
                name: '',
                file: null,
                selected_doc_type: '',
                selected_doc_id: '',
                selected_doc_version_number: undefined,
                ad_hoc_employee_signature_required: undefined,
                ad_hoc_manager_signature_required: undefined,
                block_ee_until_completion: undefined,
                hidden_from_employee: false,
                completed_on: moment().format('MM/DD/YYYY'), // default to today
                expires_on: null,
            },
            mode: null, // 'require' for Choose from Library or 'upload' for Upload
            step: 'add-type',
            uploadedPdfPreviewUrl: null,
            uploadLibraryMatchDoc: null,
            previewCustomForm: null,
            selectedDocument: null,
            docVersionPreviewPDF: null,
            versionSelectTab: 'library',
            taggableDocs: [],
            requirableDocs: [],
            requiredIncompleteDocs: [],
            requirableHRDocsLoaded: false,
            requirableUniformsLoaded: false,
            requirableCustomFormsLoaded: false,
            taggableDocsTableHeaders: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Type', classes: 'cw-6', isSearchable: false},
            ],
            taggableDocsTableSections: [
                {title: 'Inactive Documents', defaultIsClosed: true, id: 'inactive', hasDateFilter: false}, // TODO KB make this sticky
                {title: 'Active Documents', defaultIsClosed: false, id: 'active', hasDateFilter: false},
            ],
            requirableDocsTableHeaders: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                // TODO KB add status: not_required, required_incomplete, completed, (completed_prev_version?)
                {label: 'Type', classes: 'cw-6', isSearchable: false},
            ],
            requirableDocsTableSections: [
                {title: 'Requirable Documents', defaultIsClosed: false, id: 'requirable', hasDateFilter: false},
            ],
        }
    },
    mounted() {
        this.getRequirableDocuments()
        this.getTaggableDocuments()
    },
    computed: {
        stepAsText() {
            if (this.step) {
                return STEP_METADATA[this.step]['title']
            } else {
                return ''
            }
        },
        isDocTagged() {
            return this.mode == 'upload' && !!this.formData.selected_doc_id
        },
        isAdHocDoc() {
            return this.mode == 'upload' && this.formData.ad_hoc_employee_signature_required
        },
        hasAdHocDocsModule() {
            return this.client.has_ad_hoc_docs_module && this.employee.company.has_ad_hoc_docs_module
        },
        activeTaggableDocs() {
            return this.taggableDocs.filter((doc) => {return doc.is_active && !doc.is_draft})
        },
        inactiveTaggableDocs() {
            return this.taggableDocs.filter((doc) => {return !doc.is_active && !doc.is_draft})
        },
        taggableDocsTableData() {
            const tableData = []
            const subsets = [
                this.inactiveTaggableDocs,
                this.activeTaggableDocs,
            ]
            subsets.forEach(subset => {
                const sectionData = []
                subset.forEach((d, idx) => {
                    sectionData.push({
                        id: idx,
                        object: d,
                        cells: this.makeTaggableDocTableData(d),
                        isActive: (this.uploadLibraryMatchDoc ? (d.doc_key == this.uploadLibraryMatchDoc.doc_key) : false)
                    })
                })
                tableData.push(sectionData)
            })
            return tableData
        },
        requirableDocsTableData() {
            this.requirableDocs.forEach(d => d['requirable'] == true)
            this.requiredIncompleteDocs.forEach(d => d['requirable'] == false)
            const tableData = []
            const sectionData = []
            let allDocs = this.requirableDocs.concat(this.requiredIncompleteDocs)
            allDocs.forEach((doc, docIdx) => {
                sectionData.push({
                    id: docIdx,
                    object: doc,
                    cells: this.makeRequirableDocTableData(doc),
                    selectDisabled: !doc['requirable'],
                })
            })
            tableData.push(sectionData)

            return tableData
        },
        requirableDocsLoaded() {
            return this.requirableHRDocsLoaded && this.requirableUniformsLoaded && this.requirableCustomFormsLoaded
        }
    },
    methods: {
        getRequirableDocuments() {
            this.requirableDocs = []
            this.requiredIncompleteDocs = []
            this.requirableHRDocsLoaded = false
            this.requirableUniformsLoaded = false
            this.requirableCustomFormsLoaded = false
            this.getRequirableHRDocuments()
            this.getRequirableUniforms()
            this.getRequirableCustomForms()
        },
        getRequirableHRDocuments() {
            this.$api.get(`/clients/${this.client.id}/employees/${this.employee.id}/hr-documents/requirable`).then(resp => {
                this.requirableDocs = this.requirableDocs.concat(resp['requirable'])
                this.requiredIncompleteDocs = this.requiredIncompleteDocs.concat(resp['required_incomplete'])
                this.requirableHRDocsLoaded = true
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getRequirableUniforms() {
            this.$api.get(`/clients/${this.client.id}/employees/${this.employee.id}/uniforms/requirable`).then(resp => {
                this.requirableDocs = this.requirableDocs.concat(resp['requirable'])
                this.requiredIncompleteDocs = this.requiredIncompleteDocs.concat(resp['required_incomplete'])
                this.requirableUniformsLoaded = true
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getRequirableCustomForms() {
            this.$api.get(`/clients/${this.client.id}/employees/${this.employee.id}/hr-custom-forms/requirable`).then(resp => {
                this.requirableDocs = this.requirableDocs.concat(resp['requirable'])
                this.requiredIncompleteDocs = this.requiredIncompleteDocs.concat(resp['required_incomplete'])
                this.requirableCustomFormsLoaded = true
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getTaggableDocuments() {
            this.$api.get(`/clients/${this.client.id}/employees/${this.employee.id}/hr-documents/taggable`).then(resp => {
                this.taggableDocs = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getSelectedDocument() {
            // Get the full serialization for the selected library document
            this.$store.dispatch('START_LOADING')
            let docUrl = {
                'hr-doc': `/clients/${this.client.id}/hr-documents/${this.formData.selected_doc_id}`,
                'uniform': `/clients/${this.client.id}/uniforms/${this.formData.selected_doc_id}`,
                'hr-custom-form': `/clients/${this.client.id}/hr-custom-forms/${this.formData.selected_doc_id}`
            }[this.formData.selected_doc_type]
            this.$api.get(docUrl).then(doc => {
                this.$store.dispatch('STOP_LOADING')
                this.selectedDocument = doc
                let versions = []
                if (this.selectedDocument.doc_type == 'hr-doc') {
                    versions = this.selectedDocument.files
                } else {
                    versions = this.selectedDocument.versions
                }
                this.selectedDocument.versions = versions.sort((a,b) => b.version_number - a.version_number)
                this.selected_doc_version_number = doc.version_number
                if (this.selectedDocument.doc_type == 'hr-doc') {
                    this.setDocVersionPreviewPDF(doc.current_version)
                } else if (this.selectedDocument.doc_type == 'hr-custom-form') {
                    this.setPreviewCustomForm(doc)
                }
                return true
            }).catch((errors) => {
                this.selectedDocument = null
                this.$bus.showError(errors.__all__)
            })
        },
        makeTaggableDocTableData(doc) {
            let docTypePretty = this.getDocTypePretty(doc.doc_type)
            return [
                doc.name,
                docTypePretty,
            ]
        },
        makeRequirableDocTableData(doc) {
            let docTypePretty = this.getDocTypePretty(doc.doc_type)
            // let docStatusPretty = 'Status' // TODO KB
            return [
                doc.name,
                // docStatusPretty,
                docTypePretty,
            ]
        },
        getDocTypePretty(docType) {
            return {'hr-doc': 'Company Document', 'uniform': 'Uniform', 'hr-custom-form': 'Custom Form'}[docType]
        },
        onFileUpload(evt) {
            const files = evt.target.files || evt.dataTransfer.files
            if (!files.length) {
                return true
            }

            this.formErrors = []
            this.formData.file = files[0]
            this.formData.name = this.formData.file.name.toUpperCase()
            this.uploadLibraryMatchDoc = null

            // Attempt to match this upload to files in the library if there are taggable docs
            // NB: we do this on file upload so there's some time for the match to process before we get to the matching step in the wizard
            if (this.client.preview_hr_docs_match_tool && this.taggableDocs.length) {
                this.$api.upload(`/clients/${this.client.id}/employees/${this.employee.id}/documents/archive/match`, this.formData.file, {fieldName: 'file'}).then(resp => {
                    if (resp && resp.match) {
                        this.uploadLibraryMatchDoc = resp.match
                    }
                }).catch(errors => {
                    this.$bus.showError(errors.__all__)
                })
            }

            const reader = new FileReader()
            reader.onload = (e) => {
                this.uploadedPdfPreviewUrl = e.target.result
            }
            reader.readAsDataURL(files[0])

            this.mode = 'upload'
            this.step = 'upload-file'
        },
        getUploadFormData() {
            let data = {
                date: moment().format('MM/DD/YYYY'),
            }
            if (this.selectedDocument) { // document is "tagged"
                data.name = this.selectedDocument.name
                data.doc_id = this.selectedDocument.id
                data.doc_type = this.selectedDocument.doc_type
                data.version = this.formData.selected_doc_version_number
            } else {
                data.name = this.formData.name
                data.version = 1
                // NB: only "untagged" docs can be hidden
                if (this.formData.hidden_from_employee) {
                    data.hidden_from_employee = true
                }
            }
            if (this.formData.completed_on) {
                data.completed_on = this.formData.completed_on
            }
            if (this.formData.expires_on) {
                data.expires_on = this.formData.expires_on
            }
            return data
        },
        getAdHocFormData() {
            let data = {
                name: this.formData.name,
            }
            if (this.formData.ad_hoc_manager_signature_required) {
                data['manager_signature_required'] = true
            }
            return data
        },
        getRequireFormData() {
            return {
                doc_type: this.formData.selected_doc_type,
                doc_id: this.formData.selected_doc_id,
                block_ee_until_completion: this.formData.block_ee_until_completion,
            }
        },
        getNextStep(step) {
            let steps = []
            if (this.mode == 'require') {
                steps = REQUIRE_STEPS
            } else if (this.mode == 'upload') {
                steps = this.isAdHocDoc ? UPLOAD_AD_HOC_STEPS : UPLOAD_ARCHIVE_STEPS
            }
            let stepIdx = steps.indexOf(step)
            if (stepIdx == -1 || stepIdx + 1 >= steps.length) {
                return null
            } else {
                let nextStep = steps[stepIdx + 1]
                // logic for skipping steps
                if (nextStep == 'upload-request-signature') {
                    if (!this.hasAdHocDocsModule) {
                        nextStep = this.getNextStep(nextStep)
                    }
                }
                if (nextStep == 'upload-match-doc') {
                    if (!this.taggableDocs.length) {
                        // if we don't have any taggable docs, skip the tagging step
                        nextStep = this.getNextStep(nextStep)
                    }
                }
                if (nextStep == 'upload-match-version') {
                    if (!this.formData.selected_doc_id) {
                        // if we did not select a doc for tagging, skip the version selection
                        nextStep = this.getNextStep(nextStep)
                    }
                }
                return nextStep
            }
        },
        getPrevStep(step) {
            let steps = []
            if (this.mode == 'require') {
                steps = REQUIRE_STEPS
            } else if (this.mode == 'upload') {
                steps = this.isAdHocDoc ? UPLOAD_AD_HOC_STEPS : UPLOAD_ARCHIVE_STEPS
            }
            let stepIdx = steps.indexOf(step)
            if (stepIdx <= 0) {
                return null
            } else {
                let prevStep = steps[stepIdx - 1]
                // logic for skipping steps
                if (prevStep == 'upload-request-signature') {
                    if (!this.hasAdHocDocsModule) {
                        prevStep = this.getPrevStep(prevStep)
                    }
                }
                if (prevStep == 'upload-match-doc') {
                    if (!this.taggableDocs.length) {
                        // if we don't have any taggable docs, skip the tagging step
                        prevStep = this.getPrevStep(prevStep)
                    }
                }
                if (prevStep == 'upload-match-version') {
                    if (!this.formData.selected_doc_id) {
                        // if we did not select a doc for tagging, skip the version selection
                        prevStep = this.getPrevStep(prevStep)
                    }
                }
                return prevStep
            }
        },
        validateFormData() {
            this.formErrors = []
            this.errors = {}
            if (this.step == 'require-select-doc') {
                if (!this.formData.selected_doc_id) {
                    this.formErrors = ["Please select the document you are requiring."]
                    return false
                }
            }
            if (this.step == 'upload-match-version') {
                if (!this.formData.selected_doc_version_number) {
                    this.formErrors = ["Please select the version of this document you are uploading."]
                    return false
                }
            }
            if (this.step == 'upload-file') {
                if (!this.formData.file) {
                    this.formErrors = ["Document upload is required."]
                    return false
                }
            }
            if (this.step == 'upload-request-signature') {
                if (this.formData.ad_hoc_employee_signature_required === undefined || (this.formData.ad_hoc_employee_signature_required && (this.formData.ad_hoc_manager_signature_required === undefined || this.formData.block_ee_until_completion === undefined))) {
                    this.formErrors = ["Please select document signature settings."]
                    return false
                }
            }
            if (this.step == 'upload-name') {
                if (!this.formData.name) {
                    this.errors.name = ['Document name is required.']
                    return false
                }
            }
            if (this.step == 'upload-settings') {
                if (!this.selectedDocument && !this.formData.name) {
                    this.errors.name = ['Document name is required.']
                    return false
                }
            }
            if (this.step == 'require-settings') {
                if (this.selectedDocument.collection_method == 'do-not-collect') {
                    if (this.formData.block_ee_until_completion === undefined) {
                        this.formErrors = ['Select collection settings for this employee to continue.']
                        return false
                    }
                }
            }
            return true
        },
        onNextStep() {
            if (!this.validateFormData()) {
                return false
            }

            this.step = this.getNextStep(this.step)
        },
        onPrevStep() {
            this.formErrors = []
            this.errors = {}

            if ((this.step == 'require-select-doc') && (this.previewCustomForm || this.docVersionPreviewPDF)) {
                this.previewCustomForm = null
                this.docVersionPreviewPDF = null
                this.clearSelectedDocument()
                return
            }
            if (this.step == 'upload-match-version') {
                this.previewCustomForm = null
                this.docVersionPreviewPDF = null
                this.clearSelectedDocument()
            }

            this.step = this.getPrevStep(this.step)

            if (this.step == 'add-type') {
                this.clearSelectedDocument()
                this.clearUploadedDocument()
            }
        },
        onSubmitUpload() {
            if (!this.validateFormData()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.upload(`/clients/${this.client.id}/employees/${this.employee.id}/documents/archive`, this.formData.file, {fieldName: 'file', formData: this.getUploadFormData()}).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo('Document uploaded successfully.')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        onSubmitRequire() {
            if (!this.validateFormData()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/employees/${this.employee.id}/individual-doc-reqs`,  this.getRequireFormData()).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo('Document successfully sent for completion.')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        onSubmitAdHoc() {
            if (!this.validateFormData()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.upload(`/clients/${this.client.id}/employees/${this.employee.id}/documents/ad-hoc`, this.formData.file, {fieldName: 'file', formData: this.getAdHocFormData()}).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo('Document uploaded and sent successfully.')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        onSelectTaggableDoc(d) {
            this.onSelectDoc(d)
            this.onNextStep()
        },
        onSelectRequirableDoc(d) {
            this.onSelectDoc(d)
        },
        onSelectDoc(d) {
            this.formData.selected_doc_type = d.doc_type
            this.formData.selected_doc_id = d.id
            this.formData.selected_doc_version_number = d.version_number
            this.selectedDocument = null
            this.getSelectedDocument()
        },
        onSelectSkipTagging() {
            this.clearSelectedDocument()
            this.onNextStep()
       },
       clearSelectedDocument() {
            this.formData.selected_doc_type = null
            this.formData.selected_doc_id = null
            this.formData.selected_doc_version_number = null
            this.selectedDocument = null
        },
        onDocVersionSelect(v) {
            this.formData.selected_doc_version_number = v.version_number
        },
        setDocVersionPreviewPDF(v) {
            this.formErrors = []
            if (!v) {
                this.docVersionPreviewPDF = null
                return
            }
            this.docVersionPreviewPDF = {
                title: '',
                url: {
                    url: `${this.$api.API_BASE}/clients/${this.client.id}/hr-documents/${v.hr_document_id}/file/pdf?file_id=${v.id}`,
                    withCredentials: true,
                },
                version_number: v.version_number
            }
        },
        setPreviewCustomForm(cf) {
            this.formErrors = []
            if (!cf) {
                this.previewCustomForm = null
                return
            }
            this.previewCustomForm = {
                'hr_custom_form': {
                    'name': cf.name
                },
                'hr_custom_form_version': {
                    'questions': cf.current_version.questions
                },
                'response_data': {}
            }
        },
        clearUploadedDocument() {
            this.uploadedPdfPreviewUrl = null
            this.$refs.fileInput.value = null
            this.formData.file = null
        }
    },
}
</script>

<style lang="scss">
.add-method-tile {
    border: 2px solid #FE4E00;
    border-radius: 8px;
    color: #FE4E00;
    i {
        color: #FE4E00;
        font-size: 64px;
        transition: color 0.2s;
    }
    transition: background-color 0.2s;
    &:hover {
        cursor: pointer;
        background-color: #FE4E00;
        color: white;
        i {
            color: white;
        }
    }
}
.col-version-pdf {
    min-height: 600px;
    background-color: #f9f9f9;
}
.col-version-selector {
    flex: 0 0 200px;

    .todo-item::before {
        height: 24px;
        width: 24px;
        margin-right: 8px;
        font-size: 0.8rem;
        padding-top: 3px;
        padding-left: 6px;
    }

    .todo-item-content {
        padding-top: 2px;
    }

    .todo-item:hover, .todo-item.active {
        .todo-item-content {
        color: #FE4E00 !important;
        text-decoration: none;
      }
    }

    &.disabled  {
        pointer-events: none;
        color: #666;

        .todo-item-content, .todo-item-completed::before {
            opacity: 0.7;
        }
    }

    .doc-version {
        width: 100%;
        padding-bottom: 10px;
        padding-top: 6px;
        padding-left: 16px;
        &:hover {
            background-color: #e0e0e0;
            cursor: pointer;
        }
    }
}
.row.version-selector-tabs {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
    border-bottom: 1px solid #DDD;
    margin-left: 0px;
    margin-right: -8px;
}
.version-selector-tab {
    color: #333;
    &.selected {
        border-bottom: 2px solid #FE4E00;
    }
    &:hover {
        text-decoration: none;
        border-bottom: 2px solid #FE4E00;
        color: initial;
    }
}
</style>
