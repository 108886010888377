<template>
    <modal size="xl" ref="modal" @close="$emit('close')" :is-closeable="isCloseable" :autoshow="true">
        <template v-slot:title>
            <span v-if="filingStatus">Update Filing Status</span>
            <span v-if="!filingStatus">Add Filing Status</span>
        </template>
        <slot>

            <form class="mui-form" @submit.prevent="onSubmit">
                <div class="form-row mb-4">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>
                <div class="form-row mb-2">
                    <div class="col-10">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" :readonly="filingStatus" />
                    </div>
                    <div class="col-2">
                        <form-input v-model="formData.code" :errors="errors.code" type="text" label="Code" :readonly="filingStatus" />
                    </div>
                </div>
            </form>
        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['filingStatus', 'jurisdiction', ],
    mixins: [FormMixin, ModalMixin, ],
    data() {
        return {
            formRules: {
                starts_on: {presence: {allowEmpty: false}, date: true},
                name: {presence: {allowEmpty: false}},
                code: {presence: {allowEmpty: false}},
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.filingStatus) {
                return {
                    starts_on: this.filingStatus.current_version.starts_on,
                    inactive_on: this.filingStatus.current_version.inactive_on,
                    name: this.filingStatus.name,
                    code: this.filingStatus.code,
                    jurisdiction: this.jurisdiction.id,
                }
            } else {
                return {
                    starts_on: '',
                    inactive_on: '',
                    name: '',
                    code: '',
                    jurisdiction: this.jurisdiction.id,
                }
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise, url;
            if (this.filingStatus) {
                url = `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/filing-statuses/${this.filingStatus.id}`
                promise = this.$api.put(url, this.getFormData())
            }
            else {
                url = `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/filing-statuses`
                promise = this.$api.post(url, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>


