<!--
    List of detailed role permissions
-->
<template>
  <div>
      <p>
          Within this user's assigned <strong class="text-lowercase">{{ role.assignedTo }}</strong>, they can:
      </p>
      <h6>
          <span v-if="role.value == 'onboarding-manager'">Onboarding </span>Employees
      </h6>
      <ul class="list-unstyled">
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['enterEeInfo']" 
              />
              <span>
                  Enter initial employee info
              </span>
          </li>
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['editEeDetails']" 
              />
              <span>
                  View and edit employee details
              </span>
          </li>
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['setInitialPayRate']" 
              />
              <span>
                  Set initial rate of pay
              </span>
          </li>
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['useReporting']" 
              />
              <span>
                  Use reporting features
              </span>
          </li>
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['submitPayRate']" 
              />
              <span>
                  Submit pay rate change for approval
              </span>
          </li>
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['submitPayroll']" 
              />
              <span>
                  Submit payroll for approval
              </span>
          </li>
      </ul>
      <h6>
          Approvals
      </h6>
      <ul class="list-unstyled">
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['approvePayroll']" 
              />
              <span>
                  Approve submitted payroll
              </span>
          </li>
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['approvePayRate']" 
              />
              <span>
                  Approve pay rate changes
              </span>
          </li>
      </ul>
      <h6>
          Settings
      </h6>
      <ul class="list-unstyled">
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['editSettings']" 
              />
              <span>
                  Change company settings
              </span>
          </li>
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['editServices']" 
              />
              <span>
                  Add/remove services
              </span>
          </li>
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['addUsers']" 
              />
              <span>
                  Add security users (except Payroll Administrators)
              </span>
          </li>
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['addPayrollAdmins']" 
              />
              <span>
                  Add Payroll Administrators
              </span>
          </li>
      </ul>
      <ul class="list-unstyled mt-1">
          <li>
              <role-permission-indicator 
                  :allowed="role.permissions['contactInfoShown']" 
              />
              <span>
                  Contact info is shown to employees
              </span>
          </li>
      </ul>
  </div>
</template>

<script>
import RolePermissionIndicator from './RolePermissionIndicator'

export default {
    props: ['role'],
    components: {RolePermissionIndicator, },
}
</script>