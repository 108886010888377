<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Case Creation (Part 2)</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <h5>Confirm Data</h5>
                                <p>
                                    If the information entered does not immediately match records available to SSA and/or DHS, the case creation form will ask you to confirm that the information entered matches the Form I-9. Users may either confirm that the information matches Form I-9 or change the information in the prompted fields if they entered the information incorrectly.
                                </p>

                                <h5>Duplicate Cases</h5>
                                <p>
                                    You may not have multiple active cases for the same employee. If you submit a case for the same employee while a prior case is open, it will error. If you submit a case for the same employee that has a CLOSED case from the last 12 calendar months, you will be able to submit the case, but will be asked to explain why you are submitting a duplicate case.
                                </p>

                                <h5>Late Submissions</h5>
                                <p>
                                    If you are creating the E-Verify more than 3 days after the employee's start date, you will be asked to explain why.
                                </p>

                                <h5>Case Submission</h5>
                                <p>
                                    During Onboarding approval, you can proceed with Onboarding after you've created the case. In many cases, the case will immediately come back with Employment Authorized and will be auto-closed - in this situation, there is nothing further to do.

                                    In some cases, however, further action will be required. These actions will appear in the E-Verify tile, accessible from your Dashboard.
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page2'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page2b'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
