
<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import RoleSelector from './SecurityUserModalRole'
import CompanySelector from './SecurityUserModalCompanies'
import PositionSelector from './SecurityUserModalPositions'
import LocationSelector from './SecurityUserModalLocations'
import SecurityUserModalSummary from './SecurityUserModalSummary'

export default {
    props: ['client', ],
    mixins: [FormMixin, ModalMixin],
    components: {SecurityUserModalSummary, RoleSelector, CompanySelector, PositionSelector, LocationSelector, },
    computed: {
        myRoleOptions() {
            const roles = new Set(this.$store.state.security_roles.PAYROLL_ADMIN_MANAGED_ROLES)

            this.client.companies.forEach(c => {
                if (this.$permissions.hasRolesInCompany(this.client.id, c.id, this.$store.state.security_roles.OFFICER_ROLES)) {
                    roles.add(this.$store.state.security_roles.ROLE_PAYROLL_ADMIN)
                }
            })

            if (this.$store.state.user.is_superuser) {
                roles.add(this.$store.state.security_roles.ROLE_PAYROLL_ADMIN)
                roles.add(this.$store.state.security_roles.ROLE_OFFICER)
                roles.add(this.$store.state.security_roles.ROLE_OFFICER_PA)
            }

            return this.roleOptions.filter(r => roles.has(r.value))
        },
        currentRolePretty() {
            let role;
            if (this.securityProfile && this.currentCompanyId) {
                role = this.formData.roles[this.currentCompanyId]
            }
            else {
                role = this.formData.role
            }

            const ro = this.roleOptions.find(r => r.value == role)
            if (ro) {
                return ro.text
            }
            return ''
        },
        clientWorkLocations() {
            const result = []
            this.clientPositions.forEach(p => {
                if (!result.find(w => w.id == p.work_location.id)) {
                    result.push(p.work_location)
                }
            })
            return result
        },
        summaryCompanyRoles() {
            const result = []

            const data = this.getFormData()
            Object.keys(data.roles).forEach(companyId => {
                const role = data.roles[companyId]
                const company = this.findCompany(companyId)

                const companyRole = {
                    company: company,
                    role: '',
                    role_pretty: '',
                    work_locations: [],
                    positions: [],
                }

                const roleObj = this.roleOptions.find(r => r.value == role)
                if (roleObj) {
                    companyRole.role = roleObj.value
                    companyRole.role_pretty = roleObj.text
                }

                if (this.$store.state.security_roles.POSITION_LEVEL_ROLES.includes(role)) {
                    data.positions[company.id].forEach(pId => {
                        const pos = this.clientPositions.find(p => p.id == pId)
                        companyRole.positions.push(pos)
                    })
                }
                else if (this.$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(role)) {
                    data.work_locations[company.id].forEach(wId => {
                        const w = this.clientWorkLocations.find(w => w.id == wId)
                        companyRole.work_locations.push(w)
                    })
                }

                result.push(companyRole)
            })

            return result
        },
    },
    data() {
        const roleOptions = []

        this.$store.state.security_roles.NUMERICALLY_SORTED_ROLES.forEach(roleId => {
            if (!this.$store.state.security_roles.ALL_ROLES.includes(roleId)) {return}  // Skip these

            roleOptions.push({
                value: roleId,
                text: this.$store.state.security_roles.ROLE_LABELS[roleId],
                desc: this.$store.state.security_roles.ROLE_DESCRIPTIONS[roleId],
                rating: this.$store.state.security_roles.ROLE_RESPONSIBILITY_RATINGS[roleId],
                assignedTo: this.getAssignedTo(roleId),
                permissions: {
                    'enterEeInfo': this.$store.state.security_roles.ONBOARDING_ROLES.includes(roleId),
                    'editEeDetails': this.$store.state.security_roles.EMPLOYEE_ROLES.includes(roleId),
                    'setInitialPayRate': this.$store.state.security_roles.ONBOARDING_ROLES.includes(roleId),
                    'useReporting': this.$store.state.security_roles.ALL_ROLES.includes(roleId),
                    'submitPayRate': this.$store.state.security_roles.SUBMIT_PAYRATE_ROLES.includes(roleId),
                    'submitPayroll': this.$store.state.security_roles.SUBMIT_PAYROLL_ROLES.includes(roleId),
                    'approvePayroll': this.$store.state.security_roles.APPROVE_PAYROLL_ROLES.includes(roleId),
                    'approvePayRate': this.$store.state.security_roles.APPROVE_PAYRATE_ROLES.includes(roleId),
                    'editSettings': this.$store.state.security_roles.SETTINGS_ROLES.includes(roleId),
                    'editServices': this.$store.state.security_roles.SETTINGS_ROLES.includes(roleId),
                    'addUsers': this.$store.state.security_roles.SETTINGS_ROLES.includes(roleId),
                    'addPayrollAdmins': this.$store.state.security_roles.OFFICER_ROLES.includes(roleId),
                    'contactInfoShown': this.$store.state.security_roles.CONTACT_INFO_SHOWN_ROLES.includes(roleId),
                }
            })
        })

        return {
            mode: '',
            clientPositions: [],
            roleOptions: roleOptions,
        }
    },
    mounted() {
        this.getClientPositions()
    },
    methods: {
        getClientPositions() {
            this.clientPositions = []
            this.$api.get(`/clients/${this.client.id}/positions`).then(resp => {
                this.clientPositions = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        canEditRole(companyId) {
            if (this.$store.state.user.is_superuser) {return true}

            if (!this.$permissions.hasRolesInCompany(this.client.id, companyId, this.$store.state.security_roles.SETTINGS_ROLES)) {
                return false  // We just don't have any access to this company at all, so don't bother with anything else
            }

            const csp = this.securityProfile.company_security_profiles.find(csp => (csp.company_id == companyId && csp.is_active))
            if (csp) {
                // This user has a CSP in this company. We need to check if our level of access is higher than thiers.
                if (this.$store.state.security_roles.OFFICER_ROLES.includes(csp.role)) {
                    return false  // They are an officer. We can't touch them.
                }
                else if (csp.role == this.$store.state.security_roles.ROLE_PAYROLL_ADMIN) {
                    return this.$permissions.hasRolesInCompany(this.client.id, companyId, this.$store.state.security_roles.OFFICER_ROLES)  // They are a payroll admin. We can only touch them if we are an officer.
                }
                else {
                    return this.$permissions.hasRolesInCompany(this.client.id, companyId, this.$store.state.security_roles.SETTINGS_ROLES)  // We can edit them as long as we are a payroll admin or an officer
                }
            }
            else {
                return true  // They don't have an active CSP, but we do (see second if statement in this method).
            }

            return false  // Unreachable failsafe
        },
        findCompany(cId) {
            return this.client.companies.find(c => c.id == cId)
        },
        getAssignedTo(roleId) {
            if (this.$store.state.security_roles.POSITION_LEVEL_ROLES.includes(roleId)) {
                return 'Department'
            } else if (this.$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(roleId)) {
                return 'Location'
            } else if (this.$store.state.security_roles.COMPANY_LEVEL_ROLES.includes(roleId)) {
                return 'Company'
            } else {
                return ''
            }
        },
    },
}
</script>
