<template>
    <div>
        <form ref="form" @submit.prevent="formSubmit($event)" novalidate autocomplete="off" v-if="survey">
            <div class="mb-4">
                <h3>{{ survey.title }}</h3>
                <p class="mt-2">
                    <button type="button" v-if="pdfUrl" @click="openPdf(pdfUrl)" class="btn btn-outline-primary"><i class="far fa-fw fa-file-pdf"></i>View Instructions (PDF)</button>
                </p>

                <vue-markdown class="mt-4" v-if="survey.instructions" :source="survey.instructions"/>

                <hr />

                <question ref="questions" v-for="q in survey.questions" :question="q" :key="q.slug" :initial="initialValues[q.slug]" />
            </div>

            <p v-if="!hidesubmit">
                <button class="btn btn-primary" type="submit">Submit</button>
            </p>
        </form>
    </div>
</template>

<script>
/* NB: this module is written using ES5 syntax on purpose. It is used in `pdf_forms` Django app
outside of the single page Vue application, and loaded using http-vue-loader. It is still
usable in single page Vue apps, but in order to make it compatible with more direct browser
consumption without setting up a compilation step, we use more plain syntax and allow for
pre-loaded globals like window.YAML, etc.
*/

import Handlebars from 'handlebars'
import * as YAML from 'js-yaml'
import w4lang from './w4lang.mjs'
import VueMarkdown from 'vue-markdown-render'
import Question from './Question.vue'


Handlebars.registerHelper('substr', function(passedString, startstring, endstring) {
   var theString = passedString.substring( startstring, endstring );
   return new Handlebars.SafeString(theString)
});

Handlebars.registerHelper('and', function(...args) {
    const options = args.pop(); // The last argument is the Handlebars options object, so we ignore it
    return args.every(arg => Boolean(arg));
});

Handlebars.registerHelper('eq', function(a, b, options) {
    return a === b
});

var findOption = function(question, slug) {
    for (let i = 0; i < question.options.length; i++) {
        var o = question.options[i];
        if (o.slug == slug) {
            return o;
        }
    }

    return null;
};

export default {
    name: 'Survey',
    'components': { Question, VueMarkdown },
    props: ['yaml', 'knowndata', 'hidesubmit', 'pdfUrl'],
    emits: ['submit', 'error'],
    computed: {
        initialValues: function() {
            const result = {}
            this.allQuestionDefs.forEach(q => {
                if (q.initial) {
                    result[q.slug] = ('' + Handlebars.compile(q.initial)(Object.assign({}, this.knowndata))).trim();
                }
            })

            return result
        },
    },
    data: function() {
        return {
            survey: null,
            allQuestionDefs: []
        };
    },
    mounted: function() {
        this.initSurvey(this.yaml);
    },
    methods: {
        getSurvey: function(url) {
            var self = this;

            return fetch(url, {
                credentials: 'include'
            }).then((resp) => {return resp.text()}).then(function(text) {
                self.initSurvey(text);
            });
        },
        initSurvey(yaml) {
            this.survey = YAML.safeLoad(yaml);

            var findQuestions = function(tree) {
                var questions = [];
                for (let i = 0; i < tree.length; i++) {
                    var q = tree[i];
                    questions.push(q);

                    // Add dependent questions
                    questions = questions.concat(findQuestions(q.questions || []));

                    for (var j = 0; j < (q.options || []).length; j++) {
                        var o = q.options[j];
                        questions = questions.concat(findQuestions(o.questions || []));
                    }
                }

                return questions;
            };

            this.allQuestionDefs = findQuestions(this.survey.questions);
        },
        compileTemplate: function(tmpl, formData) {
            return Handlebars.compile(tmpl)(Object.assign({}, this.knowndata, formData));
        },
        getFromSource: function(src, formData) {
            return this.compileTemplate('{{ ' + src + ' }}', formData);
        },
        generateSignature: function(formData) {
            return this.compileTemplate('{{employee.full_name}}, digitally signed on {{ system.date }}', formData);
        },

        findQuestion: function(slug) {
            for (let i = 0; i < this.allQuestionDefs.length; i++) {
                if (this.allQuestionDefs[i].slug == slug) {
                    return this.allQuestionDefs[i];
                }
            }

            return null;
        },
        getFormValues() {
            var self = this;

            // Collect answers to the given questions
            var errorCount = 0;
            var values = {};
            var taxValues = {};
            const localTaxValues = {};

            const updateLocalTaxValues = (qTaxValues) => {
                Object.keys(qTaxValues).forEach(cityName => {
                    if (!localTaxValues[cityName])  {
                        localTaxValues[cityName] =  {}
                    }

                    Object.assign(localTaxValues[cityName], qTaxValues[cityName])
                })
            }

            for (let i = 0; i < (this.$refs.questions || []).length; i++) {
                const q = this.$refs.questions[i];
                if (q.validate()) {
                    const qValues = q.getValues();

                    if (qValues.__tax_values) {
                        Object.assign(taxValues, qValues.__tax_values)
                        delete qValues.__tax_values;
                    }

                    if (qValues.__local_tax_values) {
                        updateLocalTaxValues(qValues.__local_tax_values);
                        delete qValues.__local_tax_values;
                    }

                    Object.assign(values, qValues);
                }
                else {
                    errorCount++;
                }
            }

            if (errorCount) {
                throw 'Some form responses are missing or invalid. Please find and fix any problems.'
            }

            // Collect answers to "hidden" questions
            for (let i = 0; i < this.allQuestionDefs.length; i++) {
                const q = this.allQuestionDefs[i];

                if (q.type == 'signature') {
                    values[q.slug] = this.generateSignature(values);
                }
                else if (q.template) {
                    values[q.slug] = this.compileTemplate(q.template, values);

                    if (q.tax_field) {
                        taxValues[q.tax_field] = '' + values[q.slug]
                    }

                    if (q.local_tax_field && q.local_tax_city_name) {
                        if (!localTaxValues.hasOwnProperty(q.local_tax_city_name)) {
                            localTaxValues[q.local_tax_city_name] = {}
                        }

                        localTaxValues[q.local_tax_city_name][q.local_tax_field] = '' + values[q.slug]
                    }

                }
                else if (q.source) {
                    values[q.slug] = this.getFromSource(q.source, values);
                }
            }

            // Do the math questions
            (function() {
                var getMathValues = function(variables) {
                    var result = [];
                    for (let i = 0; i < variables.length; i++) {
                        result.push(1 * values[variables[i]]);
                    }
                    return result;
                };


                var sum = function(vals) {return vals.reduce(function(acc, val) {return acc + val})};

                for (let i = 0; i < self.allQuestionDefs.length; i++) {
                    var q = self.allQuestionDefs[i];
                    if (q.type != 'math') {
                        continue;
                    }

                    if (q.add) {
                        values[q.slug] = getMathValues(q.add).reduce(function(acc, val) {return acc + val});
                    }
                    else if (q.subtract) {
                        var vals = getMathValues(q.subtract);
                        values[q.slug] = vals[0] - sum(vals.slice(1));
                    }
                }
            })();

            // Do the calc questions
            (function() {
                w4lang.lookupValue = function(id) {
                    var slug, oSlug;
                    var parts = id.split('__');
                    if (parts.length > 1) {
                        slug = parts[0];
                        oSlug = parts[1]
                    }
                    else {
                        slug = parts[0];
                    }

                    var q = self.findQuestion(slug);
                    var o;
                    if (!q) {
                        throw 'Could not find the question with the slug "' + id + '".';
                    }

                    if (oSlug) {
                        // We are looking for a question_slug__option_slug

                        o = findOption(q, oSlug);
                        if (!o) {
                            throw 'Could not find the option with the slug "' + id + '".';
                        }

                        if (o.calc_value) {
                            return 1 * (values[id] ? o.calc_value : 0);
                        }

                        // Return 1 for checked, 0 for not
                        return values[id] ? 1 : 0;
                    }

                    // Checkboxes act like select-one or select-many
                    if (q.type == 'checkbox') {
                        if (q.calc_value) {
                            return 1 * (values[id] ? q.calc_value : 0);
                        }

                        return values[id] ? 1 : 0;
                    }
                    else if (q.type == 'select-one') {
                        for (let i = 0; i < q.options.length; i++) {
                            o = q.options[i];
                            if (values[q.slug + '__' + o.slug]) {
                                return o.calc_value ? o.calc_value : o.text;
                            }
                        }
                        return 0;
                    }
                    else if (['currency', 'integer', 'positive-currency', 'positive-integer'].indexOf(q.type) >= 0) {
                        return 1 * values[id];
                    }

                    // All other questions
                    else if (q.calc_value) {
                        return 1 * (values[id] ? q.calc_value : 0);
                    }
                    else {
                        return values[id] ? values[id] : '';
                    }
                };
                if (w4lang.parser) {
                    w4lang.parser.lookupValue = w4lang.lookupValue;
                }

                for (let i = 0; i < self.allQuestionDefs.length; i++) {
                    var q = self.allQuestionDefs[i];
                    if (q.type != 'calc') {
                        continue;
                    }

                    try {
                        var val = w4lang.parse(q.calc).toString();
                    }
                    catch (ex) {
                        throw ex.message ? ex.message : ex;
                    }

                    if (q.calc_output_type === 'currency') {
                        val = Number(val).toFixed(2);
                    }
                    else if (q.calc_output_type === 'integer') {
                        val = Number(val).toFixed(0);
                    }
                    else if (q.calc_output_type === 'checkbox') {
                        val = Number(Number(val).toFixed(0)) ? 'X' : '';
                    }

                    val = String(val)
                    values[q.slug] = val.toString();

                    if (q.tax_field) {
                        taxValues[q.tax_field] = val;
                    }

                    // TODO: test this with NY or another form that has local tax fields
                    //
                    // if (q.local_tax_field) {
                    //     if (!localTaxValues.hasOwnProperty(q.local_tax_city_name)) {
                    //         localTaxValues[q.local_tax_city_name] = {}
                    //     }
                    //     localTaxValues[q.local_tax_city_name][q.local_tax_field] = val;
                    // }
                }
            })();

            // Make sure all values are strings.
            Object.keys(values).forEach(function(key) {
                values[key] = '' + values[key];
            });

            // Use the default values for all blank questions
            for (let i = 0; i < self.allQuestionDefs.length; i++) {
                let q = self.allQuestionDefs[i];
                if (q.default_value && !values[q.slug]) {
                    values[q.slug] = q.default_value;
                }
            }

            if (this.survey.i9_name_rules) {
                if (values.first_name.toLowerCase() == 'unknown') {
                    if (values.last_name.toLowerCase() == 'unknown') {
                        throw "You cannot enter 'Unknown' in both the First Name and Last Name fields"
                    }
                }

                if (values.state == 'CAN') {
                    if (values.postcode.length < 6) {
                        throw "Please enter a valid Canadian postcode."
                    }
                }
                else {
                    if (values.postcode.length > 5) {
                        throw "Please enter a valid postcode."
                    }
                }
            }

            values.__local_tax_values = localTaxValues
            values.__tax_values = taxValues
            return values;
        },
        getDefaultTaxValues() {
            var self = this;
            var values = {};

            if (! self.survey.default_tax_values) {
                return values;
            }

            Object.assign(values, self.survey.default_tax_values);
            return values;
        },
        formSubmit: function() {
            try {
                var values = this.getFormValues()
                this.$emit('submit', values);
                return true;
            }
            catch (ex) {
                this.$emit('error', ex)
            }
        },

        openPdf(url) {
            if (! this.$store.state.isInApp) {
                window.open(url)
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.get(url, false, false).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                // use this rather than ArrayBufferToString because ArrayBufferToString
                // sometimes results in: 'RangeError: Maximum call stack size exceeded'
                function _arrayBufferToBase64( buffer ) {
                    var binary = '';
                    var bytes = new Uint8Array( buffer );
                    var len = bytes.byteLength;
                    for (let i = 0; i < len; i++) {
                        binary += String.fromCharCode( bytes[ i ] );
                    }
                    return window.btoa( binary );
                }

                if (typeof global !== 'undefined' && global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
                    global.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
                        command: 'print',
                        content: btoa(_arrayBufferToBase64(resp))
                    }))
                } else {
                    console.error("In app, but global.webkit.messageHandlers not found");
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        }

    }
}
</script>
