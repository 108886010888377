<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4" v-show="dataLoaded">
    
                        <h1 class="mb-4 text-center">PIN Reset</h1>
        
                        <div v-if="!done">
                            <div v-if="badSecret">
                                <p>The PIN reset link is incorrect or has expired. Please start the process over.</p>
                            </div>
        
                            <form @submit.prevent="onSubmit" novalidate class="mui-form" v-if="!badSecret">
                                <form-errors :errors="formErrors"/>
        
                                <div class="form-row">
                                    <div class="col-6 col-md-4">
                                        <form-input placeholder="Enter a PIN" v-model="formData.pin" :errors="errors.pin" type="text" inputmode="numeric" maxlength="6" />
                                    </div>
                                    <div class="col-12 field-helper-text">
                                        <span class="password-strength-message">PIN must be a number with 4 to 6 digits.</span>
                                    </div>
                                </div>
        
                                <p>
                                    <button type="submit" class="btn btn-primary btn-block">Continue</button>
                                </p>
                            </form>
        
                            <p>
                                <router-link class="btn btn-link btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
                            </p>
                        </div>
        
                        <div v-if="done">
                            <p>Your new PIN has been successfully saved.</p>
        
                            <p>
                                <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
                            </p>
                        </div>
                
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import FormMixin from '@/mixins/Form'

export default {
    mixins: [FormMixin],
    data() {
        return {
            done: false,
            badSecret: false,
            dataLoaded: false,
        }
    },
    mounted() {
        this.validateSecret()
    },
    methods: {
        validateSecret() {
            this.$store.dispatch('START_LOADING')
            return api.get(`/forgot-pin/secret/${this.$route.params.secret}`).then((resp) => {
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.dataLoaded = true
                if (errors.__status__ == 403) {
                    this.badSecret = true
                }
                else {
                    bus.showError(errors.__all__)
                }

                this.$store.dispatch('STOP_LOADING')
            })
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/forgot-pin/secret/${this.$route.params.secret}`, this.formData).then(() => {
                this.done = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>
