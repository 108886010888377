<template>
    <modal size="lg" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template v-slot:title>
            <h3 class="modal-title">Edit Collection Settings</h3>
        </template>

        <slot>
            <div class="mb-2">
                <div class="p-1 unpad-x bg-150 rounded">
                    <div><span class="text-muted">Company: </span>{{ document.company.name }}</div>
                    <div><span class="text-muted">Document Name: </span>{{ document.name }}</div>
                </div>
            </div>

            <alert-box type="info" v-if="documentHasDraft" class="mt-1 mb-2">
                Any changes to collection settings will apply to both your active and draft versions of this document.
            </alert-box>

            <p class="text-semibold">
                How should GetMyPaystub collect this document for required employees?
            </p>

            <info-widget>
                <template v-slot:title>New Employees</template>
                <div class="card-body pt-2 pb-1">
                    <div class="form-check">
                        <label>
                            <input name="include_in_onboarding" type="radio" @click="formData.include_in_onboarding = true" :checked="formData.include_in_onboarding == true" class="form-check-input">
                            <strong>Employees</strong> will be prompted to complete this document online when they onboard or first log in.
                        </label>
                        <p class="mt-0 small">
                            New employees will be blocked from accessing paystubs, W-2s, etc. until they have completed this document.
                        </p>
                    </div>
                    <div class="form-check pb-1 mt-1">
                        <label>
                            <input name="include_in_onboarding" type="radio" @click="formData.include_in_onboarding = false" :checked="formData.include_in_onboarding == false" class="form-check-input">
                            <span><strong>Managers</strong> will be given a task to complete or upload this document on behalf of new employees.</span>
                        </label>
                        <p class="mt-0 small">
                            New employees will be allowed to access paystubs, W-2s, etc. before this document is completed.
                        </p>
                    </div>
                    <alert-box type="info">
                        In either case, managers can always upload {{ documentType == 'hr-doc'? "" : " or complete " }} a document on an employee’s behalf.
                    </alert-box>
                </div>
            </info-widget>

            <info-widget>
                <template v-slot:title>Active Employees</template>
                <div class="card-body pt-2 pb-1">
                    <div class="form-check">
                        <label>
                            <input name="collection_method" type="radio" @click="formData.collection_method = 'employee'" :checked="formData.collection_method == 'employee'" class="form-check-input">
                            <strong>Employees</strong> will receive an email and will be prompted to complete this document online.
                        </label>
                        <div class="pl-2 pr-1 pt-2 pb-1 mb-1 bg-100 rounded" v-show="formData.collection_method == 'employee'">
                            <div class="form-check">
                                <label>
                                    <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = true" :checked="formData.block_ee_until_completion == true" class="form-check-input">
                                    Block access to paystubs, W-2s, etc. until employee has completed this document
                                </label>
                            </div>
                            <div class="form-check">
                                <label>
                                    <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = false" :checked="formData.block_ee_until_completion == false" class="form-check-input">
                                    Allow employee to access paystubs, W-2s, etc. before completing this document
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-check mt-1">
                        <label>
                            <input name="collection_method" type="radio" @click="formData.collection_method = 'manager'; formData.block_ee_until_completion = false" :checked="formData.collection_method == 'manager'" class="form-check-input">
                            <span><strong>Managers</strong> will be given a task to complete or upload this document on behalf of active employees.</span>
                        </label>
                        <p class="mt-0 small">
                            Employees will be allowed to access paystubs, W-2s, etc. before this document is completed.
                        </p>
                    </div>
                    <div class="form-check pb-1">
                        <label>
                            <input name="collection_method" type="radio" @click="formData.collection_method = 'do-not-collect'" :checked="formData.collection_method == 'do-not-collect'" class="form-check-input">
                            <strong>Do not collect</strong> this document for active employees.
                        </label>
                    </div>
                    <alert-box type="info">
                        In any case, managers can always upload {{ documentType == 'hr-doc'? "" : " or complete " }} a document on an employee’s behalf.
                    </alert-box>
                </div>
            </info-widget>

        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">
                    Save
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import InfoWidget from '@/components/InfoWidget'

export default {
    props: ['client', 'document', 'documentType', 'documentHasDraft'],
    mixins: [FormMixin, ModalMixin],
    components: {InfoWidget, },
    data() {
        return {
            step: 'positions',
            formData: this.makeFormData(),
            allPositions: [],
            positionsLoaded: false,
        }
    },
    watch: {
        client() {
            this.reset()
        }
    },
    methods: {
        makeFormData() {
            const data = {
                include_in_onboarding: this.document.include_in_onboarding,
                block_ee_until_completion: this.document.block_ee_until_completion,
                collection_method: this.document.collection_method
            }
            // handle case where collection settings for active ees are not configured yet
            // (usually because this doc was created with the onboarding module, and not with the company docs module)
            if (this.document.major_version_number == 0) { // HRDocuments created without the company docs module have major version 0
                data.collection_method = undefined
                data.block_ee_until_completion = false
            }

            return data
        },
        onSubmit() {
            if (this.formData.collection_method === undefined) {
                this.formErrors = ['Select collection settings for active employees to continue']
                return
            }
            this.$store.dispatch('START_LOADING')
            let promise

            if (this.documentType == 'hr-doc') {
                promise = this.$api.patch(`/clients/${this.client.id}/hr-documents/${this.document.id}/collection-settings`, this.getFormData())
            } else if (this.documentType == 'uniform') {
                promise = this.$api.patch(`/clients/${this.client.id}/uniforms/${this.document.id}/collection-settings`, this.getFormData())
            } else if (this.documentType == 'hr-custom-form') {
                promise = this.$api.patch(`/clients/${this.client.id}/hr-custom-forms/${this.document.id}/collection-settings`, this.getFormData())
            }
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    }
}
</script>
