<template>
<sidebar @close="$emit('close')">
    <template v-slot:title>
        ATS Log #{{ ats_log.id }}
    </template>

    <template v-slot:header>
        <div class="row mb-1">
            <div class="col">
                <div class="text-muted" v-if="ats_log">
                    {{ ats_log }}
                </div>
            </div>
        </div>
    </template>

    <slot v-if="dataLoaded">
        <p class="mb-2 py-2 border-top border-bottom">
            {{ ats_log }}
        </p>
    </slot>

</sidebar>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import Sidebar from '@/components/Sidebar'

export default {
    components: {Sidebar, },
    props: ['atsLogId', ],
    data() {
        return {
            dataLoaded: false,
            ats_log: null,
            client: null,
        }
    },
    mounted() {
        this.getData()
    },
    watch: {
        atsLogId() {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            const url = `/admin/ats-logs/${this.atsLogId}`
            api.get(url).then(resp => {
                this.ats_log = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
    },
}
</script>
