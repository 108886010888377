<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">
                
                        <h1 class="mb-4 text-center">Account Closed</h1>
        
                        <p>
                            Your GetMyPayStub account has been closed.
                        </p>
        
                        <p>
                            Your employers still retain all of your GetMyPayStub data, including pay stubs and tax documents.
                        </p>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
