<template>
    <div></div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'

export default {
    data() {
        return {
        }
    },
    mounted() {
        const challenge_key = this.$route.params.challengeKey
        const secure_token = this.$route.params.secureToken

        let new_device_id = this.$route.query.new_device_id || ''

        this.$store.dispatch('SET_PAGE_TITLE', 'Logging in...')
        this.$store.dispatch('START_LOADING')
        this.$store.dispatch('SET_IS_IN_APP', true)
        api.post(`/login/complete/get-session/${challenge_key}/${secure_token}`, {
            'new_device_id': new_device_id,
        }).then(resp => {
            this.$store.dispatch('SET_SESSION', resp)
            this.$store.dispatch('GET_USER').then(user_resp => {
                this.$store.dispatch('STOP_LOADING')

                if (resp['prompt_review_trusted_devices']) {
                    if (new_device_id) {
                        this.$router.push({name: 'review-trusted-devices', query: {ndid: new_device_id}})
                    } else {
                        this.$router.push({name: 'review-trusted-devices'})
                    }
                } else {
                    this.$router.push({name: 'index'})
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
                this.$router.push({name: 'login'})
            })
        }).catch(errors => {
            this.$store.dispatch('STOP_LOADING')
            bus.showError(errors.__all__)
            this.$router.push({name: 'login'})
        })
    },
}
</script>
