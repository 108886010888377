<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable">

    <template v-slot:title>
        <h3 class="modal-title">Test Form</h3>
    </template>

    <template v-slot:subheading>
        <div class="p-1 unpad-x bg-150 rounded">
            <div><span class="text-muted">Form: </span> {{ form.name }}</div>
            <div><span class="text-muted">Version: </span> {{ version.version_number }}</div>
        </div>
    </template>

    <slot>
    <survey ref="survey" :yaml="version.yaml" :knowndata="sampleKnownData" @error="onPDFFormError" :hidesubmit="true"  :pdf-url="version.instructions_pdf_file_url || version.pdf_file_url" />

    <div class="mb-2">
        <button type="button" class="btn btn-primary" @click.prevent="onPDFFormSubmit()">Test PDF</button>
        <button v-if="form.form_type != 'employer'" type="button" class="btn btn-outline-primary ml-1" @click.prevent="onTestTaxValues()">Test Tax Values</button>
    </div>

    <div class="py-2 mt-2 border-top" v-if="taxData || localTaxData">
        <dl class="row" v-for="t in taxData">
            <dt class="col-6">{{ t.field }}</dt>
            <dd class="col">{{ t.value }}</dd>
        </dl>

        <div v-for="cityName in Object.keys(localTaxData)" v-if="localTaxData" class="border p-1">
            <p>These values will not be transmitted to the payroll system during this test, but will be used during real onboardings.</p>
            <h5>Local tax values for: {{ cityName }}</h5>
            <dl class="row" v-for="t in localTaxData[cityName]">
                <dt class="col-6">{{ t.field }}</dt>
                <dd class="col">{{ t.value }}</dd>
            </dl>
        </div>

        <p v-if="(localTaxData.length + taxData.length) < 1">
            <em>No tax values found.</em>
        </p>

        <div>
            <hr>
            <h4>Push to Payroll</h4>
            <p>
                To push these values to a test employee in Payroll, enter an address below and click the button - the address must fall in the jurisdiction of this form.
            </p>
            <p>
                <b>Resident Address</b>
                <br><br>
                <form-input label="Address" v-model="testAddressFormData.test_address_address1" type="text" />
                <form-input label="City" v-model="testAddressFormData.test_address_city" type="text" />
                <form-input label="State" v-model="testAddressFormData.test_address_state" type="text" maxlength="2" />
                <form-input label="Zip" v-model="testAddressFormData.test_address_zip_code" type="text" />
            </p>

            <form-input label="Resident or Work (applies to taxes, not address)" v-model="testFormTaxScope" type="select" :options="taxScopeOptions"/>

            <form-errors :errors="testFormErrors"/>
            <div>
                <alert-box type="success" v-if="testFormSuccessMsg">
                    {{ testFormSuccessMsg }}
                </alert-box>
            </div>

            <button type="button" class="btn btn-primary ml-0" @click.prevent="onPushAddressToPayroll()">Push Address</button>
            <button type="button" class="btn btn-primary ml-1" @click.prevent="onPushTestValuesToPayroll()">Push Taxes</button>
            <button type="button" class="btn btn-outline-primary ml-1" @click.prevent="onReadValuesFromPayroll()">Read from Payroll</button>
            <br><br>
            <p>
                <small>
                    Test employee: <b>AND778</b> in company <b>090</b>
                </small>
            </p>

            <div id="payroll-data-comparison" v-if="read_payroll_data.length">
                <small>
                    <table class="table table-condensed">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Field</th>
                                <th>Us</th>
                                <th>Payroll</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="payroll_data_row in read_payroll_data">
                                <td>
                                    <span v-if="! payroll_data_row.match">
                                        <i class="fas fa-fw fa-exclamation-triangle"></i>
                                    </span>
                                </td>
                                <td>{{ payroll_data_row.field }}</td>
                                <td>{{ payroll_data_row.expected_value }}</td>
                                <td>{{ payroll_data_row.payroll_value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </small>
            </div>

        </div>
    </div>

    <form method="post" target="_blank" ref="pdfPreviewForm" :action="previewActionURL">
        <input type="hidden" name="csrfmiddlewaretoken" :value="pdfPreviewDataCSRFToken" />
        <input type="hidden" name="data" ref="pdfPreviewData" />
    </form>

    </slot>

    </modal>
</template>

<script>
import bus from '@/bus'
import api from '@/api'
import Survey from '@/components/PDFForm/Survey'
import ModalMixin from '@/mixins/ModalMixin'
import $ from 'jquery'
import moment from 'moment'

export default {
    components: {Survey, },
    mixins: [ModalMixin, ],
    props: ['form', 'version'],
    emits: ['close'],
    computed: {
        pdfPreviewDataCSRFToken() {
            return this.$store.state.session ? this.$store.state.session.csrf_token : ''
        },
        previewActionURL() {
            return `${api.API_BASE}/admin/pdf-forms/forms/${this.form.id}/versions/${this.version.id}`
        },
        taxData() {
            if (!this.formValues) {
                return null
            }

            const defaultTaxValues = this.$refs.survey.getDefaultTaxValues()

            const data = {}
            const formTaxValues = this.formValues.__tax_values || {};
            Object.assign(data, defaultTaxValues, formTaxValues)

            const result = []
            Object.keys(data).forEach(taxFieldName => {
                result.push({
                    field: taxFieldName,
                    value: data[taxFieldName],
                })
            })

            return result
        },
        localTaxData() {
            if (!this.formValues) {
                return null
            }

            const result = {}
            const formTaxValues = this.formValues.__local_tax_values || {};
            Object.keys(formTaxValues).forEach(cityName => {
                if (!result[cityName]) {
                    result[cityName] = []
                }
                Object.keys(formTaxValues[cityName]).forEach(field => {
                    result[cityName].push({
                        field: field,
                        value: formTaxValues[cityName][field],
                    })
                })
            })

            return result
        },
        taxScopeOptions() {
            return [
                {text: 'Resident', value: 'resident'},
                {text: 'Work', value: 'work'},
            ]
        },
    },
    watch: {
        form: {
            handler() {
                this.formValues = null
            },
            deep: true,
        },
        version: {
            handler() {
                this.formValues = null
            },
            deep: true,
        }
    },
    data() {
        return {
            formValues: null,
            testFormErrors: null,
            testFormSuccessMsg: '',
            testAddressFormData: {
                test_address_address1: '89 E 42nd St',
                test_address_city: 'New York',
                test_address_state: 'NY',
                test_address_zip_code: '10017',
            },
            testFormTaxScope: '',
            read_payroll_data: [],
            sampleKnownData:  {
                'system': {
                    'date': moment().format('MM/DD/YYYY'),
                    'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
                },
                'employee': {
                    'first_name': 'Johnny',
                    'middle_initial': 'R',
                    'last_name': 'Cash',
                    'full_name': 'Johnny R. Cash',
                    'address_line_1': '123 Main St',
                    'address_line_2': 'Apartment 777',
                    'city': 'Kingsland',
                    'city_normalized': 'Kingsland',
                    'state': 'AR',
                    'state_long': 'Arkansas',
                    'postcode': '71849',
                    'phone_number': '(530) 555-1234',
                    'ssn': '111-22-3345',
                    'dob': moment(new Date(1932, 1, 25)).format('MM/DD/YYYY'),
                    'date_hired': moment(new Date(2019, 11, 25)).format('MM/DD/YYYY'),
                    'school_district_id': '123',
                    'school_district_name': 'Test Central School District',
                },
                'employer': {
                    'name': 'McDonalds Corp',
                    'fein': '11-2233444',
                    'address_line_1': '675 Queen St',
                    'address_line_2': 'Suite #301',
                    'city': 'Southington',
                    'state': 'CT',
                    'state_long': 'Connecticut',
                    'postcode': '06489',
                    'phone_number': '(530) 555-1234',
                },
            },
        }
    },
    methods: {
        onPDFFormError(error) {
            if (error.message) {
                bus.showError(error.message)
            }
            else {
                bus.showError(error)
            }
        },
        onPDFFormSubmit() {
            let data;
            try {
                data = this.$refs.survey.getFormValues()
            } catch (ex) {
                this.onPDFFormError(ex)
                return
            }

            // We are specifically using jQuery here because if we do it via the normal
            // Vue.js DOM update, we'd have to submit the form in a this.$nextTick
            // callback which would trigger browsers' popup blockers.
            // jQuery updates the value synchronously, so we can update it and submit
            // directly after the button was pressed, which doesn't trigger the blockers.
            $(this.$refs.pdfPreviewData).val(JSON.stringify(data))
            this.$refs.pdfPreviewForm.submit()
        },
        onTestTaxValues() {
            let data;
            try {
                data = this.$refs.survey.getFormValues()
            } catch (ex) {
                this.onPDFFormError(ex)
                return
            }

            this.formValues = data

            this.$nextTick(() => {
                const $body = $(this.$refs.drawerBody)
                $body.scrollTop($body.height())
            })
        },
        onPushAddressToPayroll() {
            this.testFormErrors = null
            this.testFormSuccessMsg = ''

            let testData
            testData = {
                'tax_scope': this.testFormTaxScope,
                'test_address': {
                    'address_1': this.testAddressFormData.test_address_address1,
                    'city': this.testAddressFormData.test_address_city,
                    'state': this.testAddressFormData.test_address_state,
                    'zip_code': this.testAddressFormData.test_address_zip_code,
                },
            }

            let promise
            promise = api.put(`/admin/pdf-forms/forms/${this.form.id}/test-tax-values/push-address`, testData)

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormSuccessMsg = resp['msg']
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormErrors = errors.__all__
            })
        },
        onPushTestValuesToPayroll() {
            this.testFormErrors = null
            this.testFormSuccessMsg = ''

            const testData = {
                'tax_scope': this.testFormTaxScope,
                'tax_data': this.taxData,
                'local_tax_data': this.localTaxData,
            }

            this.$store.dispatch('START_LOADING')
            return this.$api.put(`/admin/pdf-forms/forms/${this.form.id}/test-tax-values/push-taxes`, testData).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormSuccessMsg = resp['msg']
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormErrors = errors.__all__
            })
        },
        onReadValuesFromPayroll() {
            this.testFormErrors = null
            this.read_payroll_data = []

            const testData = {
                'tax_data': this.taxData,
                'tax_scope': this.testFormTaxScope,
                'test_address': {
                    'address_1': this.testAddressFormData.test_address_address1,
                    'city': this.testAddressFormData.test_address_city,
                    'state': this.testAddressFormData.test_address_state,
                    'zip_code': this.testAddressFormData.test_address_zip_code,
                },
            }

            this.$store.dispatch('START_LOADING')
            return this.$api.put(`/admin/pdf-forms/forms/${this.form.id}/test-tax-values/read`, testData).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.read_payroll_data = resp['payroll_data']
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.testFormErrors = errors.__all__
            })
        },
    },
}
</script>