<template>
    <div>
        <a href class="dropdown-item"
            v-if="$permissions.hasRoles(client.id, $store.state.security_roles.POS_ROLES)"
            @click.prevent="goToPOS()"
            :class="{'menu-link-disabled': !client.has_pos_integration || !$permissions.hasRoles(client.id, $store.state.security_roles.POS_ROLES)}"
        >
            <i class="far fa-fw fa-cogs mr-1"></i>
            POS Integration
        </a>
        <!-- TODO KB show upgrade link if necessary -->

        <router-link class="dropdown-item" :to="{name: 'employees', params: { hashedClientId: this.$route.params.hashedClientId }}">
            <i class="far fa-fw fa-file-invoice-dollar mr-1"></i>
            Employees
        </router-link>

        <router-link
            :to="{name: 'onboarding', params: { hashedClientId: this.$route.params.hashedClientId }}"
            class="dropdown-item"
            :class="{'menu-link-disabled': !client.has_paperless_onboarding || !$permissions.hasRoles(client.id, $store.state.security_roles.ONBOARDING_ROLES)}"
        >
            <i class="far fa-fw fa-user-check mr-1"></i>
            Onboarding Employees
        </router-link>
        <!-- TODO KB show upgrade link if necessary -->

        <router-link
            :to="{name: 'self-service-access', params: { hashedClientId: this.$route.params.hashedClientId }}"
            class="dropdown-item"
            :class="{'menu-link-disabled': !client.has_enhanced_ess || !$permissions.hasRoles(client.id, $store.state.security_roles.SELF_SERVICE_ACCESS_ROLES)}"
        >
            <i class="far fa-fw fa-users mr-1"></i>
            Self-Service Access
        </router-link>
        <!-- TODO KB show upgrade link if necessary -->

        <hr class="ml-2 mr-2 mt-2 mb-2"/>

        <router-link
            :to="{name: 'paychecks', params: { hashedClientId: this.$route.params.hashedClientId }}"
            class="dropdown-item"
            :class="{'menu-link-disabled': !$permissions.hasRoles(client.id, $store.state.security_roles.PAYCHECKS_ROLES)}"
        >
            <i class="far fa-fw fa-money-check-alt mr-1"></i>
            Pay Stubs
        </router-link>

        <router-link
            :to="{name: 'employee-w2s', params: { hashedClientId: this.$route.params.hashedClientId }}"
            class="dropdown-item"
            :class="{'menu-link-disabled': !$permissions.hasRoles(client.id, $store.state.security_roles.EMPLOYEE_TAX_DOCUMENTS_ROLES)}"
        >
            <i class="far fa-fw fa-file-invoice-dollar mr-1"></i>
            Employee Tax Documents
        </router-link>

        <router-link
            :to="{name: 'tax-forms', params: { hashedClientId: this.$route.params.hashedClientId }}"
            class="dropdown-item"
            :class="{'menu-link-disabled': !$permissions.hasRoles(client.id, $store.state.security_roles.COMPANY_TAX_DOCUMENTS_ROLES)}"
        >
            <i class="far fa-fw fa-folder mr-1"></i>
            Company Tax Documents
        </router-link>

        <router-link
            :to="{name: 'hr-documents', params: { hashedClientId: this.$route.params.hashedClientId }}"
            class="dropdown-item"
            :class="{'menu-link-disabled': !$permissions.hasRoles(client.id, $store.state.security_roles.HR_DOCUMENTS_ROLES)}"
        >
            <i class="far fa-fw fa-copy mr-1"></i>
            HR Documents
        </router-link>

        <!-- Only show the Tips Allocator if they actually have it -->
        <template v-if="client.has_tips_allocator && $permissions.hasRoles(client.id, $store.state.security_roles.POS_ROLES)">
            <router-link
                :to="{name: 'tips-allocator', params: { hashedClientId: this.$route.params.hashedClientId }}"
                class="dropdown-item"
                :class="{'menu-link-disabled': !$permissions.hasRoles(client.id, $store.state.security_roles.POS_ROLES)}"
            >
                <i class="fal fa-fw fa-money-bill-wave"></i>
                Tips Allocator
            </router-link>
            <!-- TODO KB show upgrade link if necessary -->
        </template>

        <hr class="ml-2 mr-2 mt-2 mb-2"/>

        <!-- For now, only show the E-Verify link if they actually have it -->
        <template v-if="hasEVerify && $permissions.hasRoles(client.id, $store.state.security_roles.EVERIFY_ROLES)">
            <router-link
                :to="{name: 'e-verify', params: { hashedClientId: this.$route.params.hashedClientId }}"
                class="dropdown-item"
                :class="{'menu-link-disabled': !$permissions.hasRoles(client.id, $store.state.security_roles.EVERIFY_ROLES)}"
            >
                <i class="far fa-fw fa-id-badge mr-1"></i>
                E-Verify
            </router-link>
            <!-- TODO KB show upgrade link if necessary -->
            <hr class="ml-2 mr-2 mt-2 mb-2"/>
        </template>

        <router-link
            :to="{name: 'warnings', params: { hashedClientId: this.$route.params.hashedClientId }}"
            class="dropdown-item"
            :class="{'menu-link-disabled': !$permissions.hasRoles(client.id, $store.state.security_roles.WARNINGS_ROLES)}"
        >
            <i class="far fa-fw fa-exclamation-triangle mr-1"></i>
            Warnings
        </router-link>

        <router-link
            :to="{name: 'changelogs', params: { hashedClientId: this.$route.params.hashedClientId }}"
            class="dropdown-item"
            :class="{'menu-link-disabled': !$permissions.hasRoles(client.id, $store.state.security_roles.CHANGE_HISTORY_ROLES)}"
        >
            <i class="far fa-fw fa-history mr-1"></i>
            Change History
        </router-link>

        <router-link
            v-if="$permissions.hasRoles(client.id, $store.state.security_roles.SETTINGS_ROLES)"
            :to="{name: 'settings', params: { hashedClientId: this.$route.params.hashedClientId }}"
            class="dropdown-item"
        >
            <i class="far fa-fw fa-tools mr-1"></i>
            Settings
        </router-link>
    </div>
</template>

<script>
export default {
    props: ['client'],
    computed: {
        hasEVerify() {
            return this.client.companies.filter(c => c.everify_enabled).length > 0
        },
        eVerifyAccess() {
            const userSP = this.$permissions.getSecurityProfile(this.client.id)
            if (userSP) {
                return userSP.everify_access
            }

            return ''
        },
        enhancedESSUpgradeAvailable() {
            if (!this.client.has_enhanced_ess) {
                return true
            } else {
                for (let c of this.client.companies) {
                    if (!c.has_enhanced_ess) {
                        return true
                    }
                }
            }
            return false
        },
    },
    methods: {
        goToPOS() {
            if (! this.client.has_pos_integration) {
                return
            }

            if (this.client.pos_use_new_ui) {
                this.$router.push({name: 'pos-integration', params: {hashedClientId: this.$hasher.encode(this.client.id)}})
                return
            }

            if (this.client.demo_custom_pos_integration_url) {
                window.location.href = this.client.demo_custom_pos_integration_url
                return
            }

            let pos_app_url = `${this.$delta.POS_APP_URL}/employer/client/${this.client.id}/hours-import/`
            window.location.href = pos_app_url
        }
    }
}
</script>

<style scoped>
    .menu-link-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
</style>