<template>
<table class="table css-table">
    <tbody v-for="(section, sectionIdx)  in sections" :key="section.id">
        <tr v-if="sections.length > 1">
            <th :colspan="headers.length + 1">
                {{ section.title }}
            </th>
        </tr>
        <tr v-if="items[sectionIdx].length">
            <th></th>
            <th v-for="header in headers" :class="header.classes" :key="header.id">{{ header.label }}</th>
        </tr>
        <tr @click.prevent.stop="onClick(item)" v-for="item in items[sectionIdx]" :key="item.id">
            <td class="cw-checkbox">
                <input type="checkbox" :checked="item.isSelected" :key="'item-checkbox-' + item.id + '-' + item.isSelected" :disabled="readonly || item.readonly" />
            </td>
            <td v-for="(cell, cellIdx) in item.cells">
                <cell :cell="cell" :header="headers[cellIdx]" />
            </td>
        </tr>

        <tbody v-if="items[sectionIdx].length < 1">
            <tr :colspan="headers.length + 1">
                <td class="my-2">
                    <em class="text-muted">This section is empty.</em>
                </td>
            </tr>
        </tbody>
    </tbody>
</table>
</template>

<script>
import Cell from '@/components/FastTable/Cell'

export default {
    props: ['sections', 'headers', 'items', 'modelValue', 'readonly', ],
    emits: ['update:modelValue'],
    components: {Cell, },
    methods: {
        onClick(item) {
            let result = Array.from(this.modelValue)
            if (result.includes(item.id)) {
                result = result.filter(id => id != item.id)
            }
            else {
                result.push(item.id)
            }

            this.$emit('update:modelValue', result)
        },
    },
}
</script>

<style lang="scss" scoped>
tr {
    cursor: default;
}
</style>
