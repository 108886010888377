<template>
<modal ref="modal" size="lg" :autoshow="true">
    <template v-slot:title>New Employee Onboarding</template>
    <slot>
        <p>
            You have Onboarding tasks to complete for {{ onboardingApplications.length == 1 ? 'this company' : `${onboardingApplications.length} companies` }}:
        </p>
        <ul class="list-unstyled border-bottom">
            <li class="py-1 border-top" v-for="app in onboardingApplications" :key="app.id">
                <div class="form-row">
                    <div class="col-12 col-rs-8 d-flex">
                        <span class="btnlike align-self-center">{{ app.company.name }}</span>
                    </div>
                    <div class="col-12 col-rs-4 d-flex justify-content-end">
                        <router-link :to="{name: 'employee-onboarding', params: {appId: app.id}}"  class="btn btn-block btn-primary align-self-center mb-1 mb-rs-0">
                            {{ app.digital_consent_given_on ? 'Continue' : 'Start' }}
                        </router-link>
                    </div>
                </div>
            </li>
        </ul>
    </slot>

    <template v-slot:footer>
        <button-row center>
            <button type="button" class="btn btn-outline-primary" @click.prevent="close">I'll do this later</button>
        </button-row>
    </template>
</modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin],
    props: ['onboardingApplications'],
}
</script>
