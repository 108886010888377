<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-l py-4">

                        <h2>Privacy Policy</h2>
                
                        <p>
                            <router-link :to="{name: 'login'}" class="btn btn-link">Back</router-link>
                        </p>
                
                        <privacy-policy />
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PrivacyPolicy from '@/components/Policies/PrivacyPolicy'

export default {
    components: {PrivacyPolicy, },
}

</script>