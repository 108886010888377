<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template v-slot:title>
            <span v-if="exemptDeductionType">Update Exempt Deduction Type: </span><span v-if="exemptDeductionType" class="text-thin text-nowrap">{{ exemptDeductionType.deduction_type.name }}</span>
            <span v-if="!exemptDeductionType">Add New Exempt Deduction Type</span>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.deduction_type" :errors="errors.deduction_type" type="select" label="Deduction Type" :readonly="!!exemptDeductionType" :options="deductionTypeOptions" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-6">
                        <form-input v-model="formData.exemption_limit" :errors="errors.exemption_limit" type="currency" label="Annual Exempt Amount" />
                    </div>

                    <div class="col-6">
                        <form-input v-model="formData.monthly_exemption_limit" :errors="errors.monthly_exemption_limit" type="currency" label="Monthly Exempt Amount" />
                    </div>
                </div>
            </form>
        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['taxExemptionsGroup', 'exemptDeductionType', 'deductionTypes', ],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        deductionTypeOptions() {
            const result = []
            this.deductionTypes.forEach(x => {
                result.push({text: x.name, value: x.id})
            })

            result.sort((a, b) => a.name > b.name ? 1 : -1)
            return result
        },
    },
    data() {
        return {
            states: [],
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.exemptDeductionType) {
                return {
                    starts_on: this.exemptDeductionType.current_version.starts_on,
                    inactive_on: this.exemptDeductionType.current_version.inactive_on,
                    deduction_type: this.exemptDeductionType.deduction_type_id,
                    exemption_limit: this.exemptDeductionType.current_version.exemption_limit,
                    monthly_exemption_limit: this.exemptDeductionType.current_version.monthly_exemption_limit,
                }
            } else {
                return {
                    starts_on: '',
                    inactive_on: '',
                    deduction_type: '',
                    exemption_limit: '',
                    monthly_exemption_limit: '',
                }
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise
            if (this.exemptDeductionType) {

                promise = this.$api.put(`/admin/tax-engine/tax-exemptions-groups/${this.taxExemptionsGroup.id}/exempt-deduction-types/${this.exemptDeductionType.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/admin/tax-engine/tax-exemptions-groups/${this.taxExemptionsGroup.id}/exempt-deduction-types`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
