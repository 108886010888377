<template>
<div>
<sidebar-widget
    @add="modalOpen = true"
    @delete="onDelete($event)"
    @edit="currentRowId = $event; modalOpen = true"
    @versions="currentRowId = $event; versionsModalIsOpen = true"
    :rows="rows"
    :headers="['Name', 'Code']"
>
    <template v-slot:title>Filing Statuses</template>

    <template v-slot:row-details scope="props">
        <div>
            <span class="text-semibold">Date range: </span>
            <span>{{ $filters.date(props.row.current_version.starts_on, "MM/DD/YYYY") }}</span>
            <span v-if="props.row.current_version.ends_on"> - {{ $filters.date(props.row.current_version.ends_on, "MM/DD/YYYY") }}</span>
            <span v-else>+</span>
        </div>
    </template>
</sidebar-widget>

<filing-status-modal
    v-if="modalOpen"
    :jurisdiction="jurisdiction"
    :filing-status="currentRow"
    @updated="$emit('updated')"
    @close="modalOpen = false; currentRowId = null"
/>

<versions-modal
    v-if="versionsModalIsOpen"
    :url="getURL(currentRowId)"
    @close="currentRowId = null; versionsModalIsOpen = false"
>
    <template v-slot:version scope="props">
        <div>
            <span class="text-semibold">Date range: </span>
            <span>{{ $filters.date(props.version.starts_on, "MM/DD/YYYY") }}</span>
            <span v-if="props.version.ends_on"> - {{ $filters.date(props.version.ends_on, "MM/DD/YYYY") }}</span>
            <span v-else>+</span>
        </div>
    </template>
</versions-modal>


</div>
</template>

<script>
import FilingStatusModal from './FilingStatusModal'
import SidebarWidget from './SidebarWidget'
import VersionsModal from './VersionsModal'

export default {
    props: ['jurisdiction', ],
    components: {FilingStatusModal, SidebarWidget, VersionsModal, },
    computed: {
        currentRow() {
            return this.allFilingStatuses.find(x => x.id == this.currentRowId)
        },
        rows() {
            const result = []
            this.allFilingStatuses.forEach(row => {
                row = JSON.parse(JSON.stringify(row))
                row.cells = [
                    row.name,
                    row.code,
                ]
                result.push(row)
            })
            return result
        },
        allFilingStatuses() {
            return this.jurisdiction.filing_statuses.sort((a, b) => a.name > b.name ? 1 : -1)
        },
    },
    data() {
        return {
            modalOpen: false,
            versionsModalIsOpen: false,
            currentRowId: null,
        }
    },
    methods: {
        getURL(rowId) {
            if (!rowId) {return}
            return `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/filing-statuses/${rowId}`
        },
        onDelete(rowId) {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(this.getURL(rowId)).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>


