<template>
    <div class="ee-data-item-icon">
        <i aria-hidden="true" class="fas fa-arrow-right" title="will be replaced by" v-if="!matches"></i>
        <span class="sr-only" v-if="!matches">will be replaced by</span>

        <i aria-hidden="true" class="far fa-check" title="matches" v-if="matches"></i>
        <span class="sr-only" v-if="matches">matches</span>

    </div>
</template>

<script>
export default {
    props: ['matches']
}
</script>
