<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid" v-show="dataLoaded">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Knowledge Test Not Passed</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    Your score of {{ score }}% was below the threshold of <b>{{ passing_score }}%</b>.
                                </p>

                                <p>
                                    You must achieve a score of at least {{ passing_score }}% in order to use E-Verify, including via GetMyPayStub.
                                </p>

                                <p>
                                    Please review the training materials and try again.
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <router-link class="btn btn-primary" :to="{name: 'training-e-verify'}">Restart Training</router-link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['client', ],
    components: {},
    data() {
        return {
            dataLoaded: false,
            score: null,
            passing_score: null,
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    mounted() {
        this.getInitialData()
    },
    computed: {
    },
    methods: {
        getInitialData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            const url = `/clients/${this.client.id}/e-verify-test-detail/${this.$route.params.testId}`

            this.$api.get(url).then(resp => {
                this.score = resp['score']
                this.passing_score = resp['passing_score']

                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
    }
}

</script>
