<template>
    <div class="row" v-if="dataLoaded">
        <div class="col-12">
            <div class="container-centered-s">
                <form @submit.prevent="onSubmit" class="mt-2">
                    <alert-box type="info" v-show="currentQuestion == 0">
                    Please answer the following questions to determine the tax forms you need to complete for your new address.
                    </alert-box>

                    <div v-for="(question, $index) in questions" :key="question.id">
                        <div v-show="$index == currentQuestion">
                            <h4 v-html="question.html"></h4>

                            <div class="row" v-for="option in question.options" :key="option.id">
                                <div class="col-1 text-right">
                                    <input :id="'form-selection-option-' + option.id" :name="'form-selection-question-' + question.id" type="radio" :value="option.option_id" @click="onClick(question.id, option.id)" :checked="formData.questions[question.id].option_id == option.id" />
                                </div>

                                <div class="col-11">
                                    <label :for="'form-selection-option-' + option.id" v-html="option.html"></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form-errors :errors="formErrors"/>

                    <portal to="address-edit-modal-footer">
                        <button-row stacked>
                            <button type="button" @click.prevent="onPrev()" class="btn btn-outline-primary">Back</button>
                            <button type="button" @click.prevent="onSubmit()" class="btn btn-primary">Continue</button>
                        </button-row>
                    </portal>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ButtonRow from '@/components/ButtonRow'
import api from '@/api'

export default {
    mixins: [FormMixin],
    components: {ButtonRow, },
    props: ['employee', 'employeeUpdate', 'prev'],
    watch: {
        employee() {
            this.reset()
        },
        employeeUpdate() {
            this.reset()
        }
    },
    mounted() {
        this.getQuestions()
    },
    computed: {},
    data() {
        const data = {
            formData: this.makeFormData(),
            formRules: {},
            dataLoaded: false,
            questions: null,
            currentQuestion: 0,
        }
        return data
    },
    methods: {
        makeFormData() {
            let formData = {
                action: 'form-selection',
                questions: {}
            }
            return formData
        },
        getQuestions() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            api.get(`/me/ess/updates/${this.employeeUpdate.id}/form-selection-questions`).then((resp) => {
                this.questions = resp
                if (this.questions.length < 1) {
                    if (this.prev) {
                        this.$store.dispatch('STOP_LOADING')
                        this.onPrev()
                    } else {
                        this.onSubmit()
                    }
                    return
                }

                this.currentQuestion = 0
                this.reset()
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onClick(questionId, optionId) {
            this.formData.questions[questionId] = {option_id: optionId}
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            if (this.currentQuestion < (this.questions.length - 1)) {
                this.nextQuestion()
                return
            }

            let missingQuestionCount = 0
            Object.keys(this.formData.questions).forEach((q_id) => {
                if (!this.formData.questions[q_id].option_id) {
                    missingQuestionCount++
                }
            })

            if (missingQuestionCount) {
                this.formErrors = ['Please answer all questions.']
                return
            }

            this.$store.dispatch('START_LOADING')
            api.patch(`/me/ess/companies/${this.employee.company_id}/updates/${this.employeeUpdate.id}`, this.getFormData()).then((resp) => {
                this.$emit('patch', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onPrev() {
            if (this.currentQuestion < 1) {
                this.$emit('prev')
            } else {
                this.previousQuestion()
            }
        },
        goToQuestion(idx) {
            this.formErrors = ''
            if (idx >= this.questions.length) {
                this.onSubmit()
                return
            }

            if (idx < 0) {
                return
            }

            if (idx > this.currentQuestion) {
                const questionId = this.questions[this.currentQuestion].id
                if (!this.formData.questions[questionId].option_id) {
                    this.formErrors = ['Please provide an answer.']
                    return
                }
            }

            this.currentQuestion = idx
        },
        nextQuestion() {
            this.goToQuestion(this.currentQuestion + 1)
        },
        previousQuestion() {
            this.goToQuestion(this.currentQuestion - 1)
        },
        reset: function() {
            this.errors = {}
            this.formErrors = []
            this.formData.questions = {}
            this.questions.forEach((q) => {
                this.formData.questions[q.id] = {option_id: null}
            })
        },
    }
}
</script>