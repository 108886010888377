<template>
    <modal @close="$emit('close')" ref="modal" size="xl" :fade="false" :v-center="true" :autoshow="true">
        <template v-slot:title>
            Test Modal with a Child Overlay
        </template>
        <template v-slot:subheading>
            <div class="p-1 unpad-x bg-150 rounded mb-1">
                <div><span class="text-muted">User:</span> BOB ROBERTSON</div>
            </div>
        </template>

        <slot>
            <div>
                <p>
                    Select the locations that this user should have access to as a <strong>General Manager</strong>.
                </p>
            </div>
            <div class="p-1 border" style="height: 400px;">
                <div class="d-flex pt-1 pb-2 px-hf mb-1 border-bottom">
                    <div class="mr-1" style="max-width: 250px;">
                        <page-search />
                    </div>
                    <div>
                        <button type="button" class="btn btn-icon btn-icon-outline btn-toggle btn-toggle--filters" @click.prevent="openChildOverlay">
                            <span title="Filters">
                                <svg aria-hidden="true" class="custom-icon" width="20" height="20" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs></defs>
                                    <g id="icon-filters" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Group" transform="translate(2.000000, 4.000000)" fill="#000000" class="svg-fill">
                                    <rect id="icon-filters-bar1" x="0" y="0" width="16" height="2" rx="1"></rect>
                                    <rect id="icon-filters-bar2" x="3" y="5" width="10" height="2" rx="1"></rect>
                                    <rect id="icon-filters-bar3" x="5" y="10" width="6" height="2" rx="1"></rect>
                                    </g>
                                    </g>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="px-2 py-1 border-bottom">
                    Pretend Location row
                </div>
                <div class="px-2 py-1 border-bottom">
                    Pretend Location row
                </div>
                <div class="px-2 py-1 border-bottom">
                    Pretend Location row
                </div>
                <div class="px-2 py-1 border-bottom">
                    Pretend Location row
                </div>
                <div class="px-2 py-1 border-bottom">
                    Pretend Location row
                </div>
            </div>
        </slot>

        <template v-slot:footer>
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" @click.prevent="close()">
                    Done
                </button>
            </button-row>
        </template>

        <template v-slot:childOverlay>
            <transition name="slide-up">
                <div class="modal-child-overlay" ref="childOverlay" v-if="childOverlayOpen">
                    <div class="modal-child-overlay-inner">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="d-flex w-100 flex-wrap">
                                <div class="modal-header-titlebar py-hf">
                                    <h3 class="modal-title btnheight-min">
                                        Filters
                                    </h3>
                                </div>
                                <button type="button" class="close" aria-label="Close" @click.prevent="closeChildOverlay">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="modal-header-buttonbar py-hf">
                                    <!-- <slot name="headerbuttons"></slot> -->
                                </div>
                            </div>
                        </div>
                        <div class="modal-body d-flex flex-column flex-grow-1">
                            <!--Slot-->

                            <div class="flex-grow-1 modal-scroll-area">
                                <div>
                                    <div class="modal-active-filters mb-1">
                                        <span class="btnlike text-muted"> Select 1 or more filters in these categories: </span>
                                        <div class="activefilters-list-wrapper" style="display: none;"><div data-v-3f1c62a7="" class="activefilters-list activefilters-list--h">
                                                hello world

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--/Slot-->
                        </div>
                        <div class="modal-footer">
                            <button-row stacked>
                                <button type="button" class="btn btn-outline-primary" @click.prevent="closeChildOverlay">
                                    Cancel
                                </button>
                                <button type="submit" class="btn btn-primary" @click.prevent="closeChildOverlay">
                                    Apply Filters
                                </button>
                            </button-row>
                        </div>
                    </div>
                    </div>
                </div>
            </transition>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/Modal'
import ModalMixin from '@/mixins/ModalMixin'
import ModalChildOverlay from '@/components/ModalChildOverlay'
import ButtonRow from '@/components/ButtonRow'

export default {
    props: [],
    mixins: [ModalMixin],
    components: {Modal, ModalChildOverlay, ButtonRow},
    computed: {
    },
    data() {
        return {
            childOverlayOpen: false,
        }
    },
    mounted() {
    },
    methods: {
        close() {
            this.$emit('close')
        },
        openChildOverlay() {
            this.childOverlayOpen = true
        },
        closeChildOverlay() {
            this.childOverlayOpen = false
        },
    },
}
</script>

<style scoped lang="scss">
  // Import Sass globals
  @import "@/assets/scss/globals";

  //
  // Component styles
  //

  // Overlay pane that appears
  // on top of the modal itself,
  // e.g. to display filters when needed

  .modal-child-overlay {
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: $border-radius;
    padding-top: 3.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9000;
  }

  .modal-child-overlay-inner {
    background-color: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5); // Replace with real box-shadow variable?
    padding: 0;
    height: 100%;

    @include media-breakpoint-up(md) {
        padding-top: spacer(1);
    }

    .modal-content {
        border: none !important;
        box-shadow: none !important;
        height: 100%;
    }
  }

  // Animated transition styles
  // for child overlay pane

  .slide-up-enter-active {
    transition: all .2s ease;
  }

  .slide-up-enter {
    transform: translateY(3.5rem);
    opacity: 0;
  }

  .slide-up-leave-to {
    transform: translateY(-1.5rem);
    opacity: 0;
  }

  .slide-up-leave-active {
    transition: all .15s ease;
  }
</style>
