import store from '@/store'
import api from '@/api'
import bus from '@/bus'

export default {
    convert(file) {
        // Get a pre-signed URL so we can upload the file directly to S3
        return api.post(`/image-converter-upload`, {ext: file.name.split('.').pop()}).then(resp => {
            const filename = resp.delta.filename
            // Upload the file to S3
            return api.upload(resp.url, file, {formData: resp.fields, noCors: true}).then(resp => {
                // Get the converted file from our image converter
                return api.get(`/image-converter/${filename}`).then(resp => {
                    return resp
                }).catch(errors => {
                    store.dispatch('STOP_LOADING')
                    bus.showError(errors.__all__)
                })
            }).catch(errors => {
                store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        }).catch(errors => {
            store.dispatch('STOP_LOADING')
            bus.showError(errors.__all__)
        })
    }
}
