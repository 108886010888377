<template>
    <div>
        <fast-table
            v-if="changelogs !== null"
            :activeRowId="currentHistoryLog ? currentHistoryLog.id : null"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="onClick"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        />

        <employee-sidebar
            v-if="!disableSidebar && currentHistoryLog && (currentHistoryLog.employee_id || currentHistoryLog.onboarding_application_id)"
            :employee-id="currentEmployeeId"
            :onboarding-application-id="currentOnboardingApplicationId"
            tab="changelogs" :item-id="currentHistoryLog.id"
            :client="client"
            @close="currentHistoryLog = null"
            :key="currentHistoryLog ? `e-${currentHistoryLog.id}` : ''"
            @updated="$emit('updated', $event)"
        />

        <security-profile-sidebar
            v-if="!disableSidebar && currentHistoryLog && (currentHistoryLog.company_security_profile_id || currentHistoryLog.security_profile_id)"
            :active-changelog-id="currentHistoryLog.id"
            :security-profile-id="(currentHistoryLog.company_security_profile_id ? currentHistoryLog.company_security_profile.security_profile_id : currentHistoryLog.security_profile_id)"
            tab="changelogs" :item-id="currentHistoryLog.id"
            :client="client"
            @close="currentHistoryLog = null"
            :key="currentHistoryLog ? `sp-${currentHistoryLog.id}` : ''"
            @updated="$emit('updated', $event)"
        />

        <hr-document-sidebar
            v-if="!disableSidebar && currentHistoryLog && currentDocument"
            :active-changelog-id="currentHistoryLog.id"
            :document-id="currentDocument.id"
            :document-type="currentDocument.doc_type"
            tab="changelogs" :item-id="currentHistoryLog.id"
            :client="client"
            @close="currentHistoryLog = null"
            :key="currentHistoryLog ? `hr-doc-${currentHistoryLog.id}` : ''"
            @updated="$emit('updated', $event)"
        />
        <hr-template-sidebar
            v-if="!disableSidebar && currentHistoryLog && currentHistoryLog.hr_template_id"
            :active-changelog-id="currentHistoryLog.id"
            :template-id="currentHistoryLog.hr_template_id"
            tab="changelogs" :item-id="currentHistoryLog.id"
            :client="client"
            @close="currentHistoryLog = null"
            :key="currentHistoryLog ? `hr-template-${currentHistoryLog.id}` : ''"
            @updated="$emit('updated', $event)"
        />
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import SecurityProfileSidebar from '@/components/SecurityProfileSidebar'
import HrDocumentSidebar from '@/pages/client/Settings/components/DocumentSidebar'
import HrTemplateSidebar from '@/pages/client/Settings/components/TemplateSidebar'

export default {
    props: ['client', 'changelogs', 'disableSidebar', 'hideRecordTypeColumn', 'hideTrackedObjectNameColumn', 'hideEmployeeSsnColumn', 'emailColumnLabel', 'hideEmailColumn'],
    components: {FastTable, EmployeeSidebar, SecurityProfileSidebar, HrDocumentSidebar, HrTemplateSidebar, },
    computed: {
        currentEmployeeId() {
            if (!this.currentHistoryLog) {
                return null
            }

            return this.currentHistoryLog.employee_id ? this.currentHistoryLog.employee_id : null
        },
        currentOnboardingApplicationId() {
            if (!this.currentHistoryLog) {
                return null
            }

            if (this.currentHistoryLog.employee) {
                // If it has an employee, perfer that even if it also has an onboarding application
                return null
            }

            return this.currentHistoryLog.onboarding_application_id ? this.currentHistoryLog.onboarding_application_id : null
        },
        currentDocument() {
            if (!this.currentHistoryLog) {
                return null
            }
            if (this.currentHistoryLog.hr_document_id) {
                return this.currentHistoryLog.hr_document
            }
            if (this.currentHistoryLog.hr_form_document_id) {
                return this.currentHistoryLog.hr_form_document
            }
            return null
        },
        tableData() {
            const tableData = []

            this.changelogs.forEach((c, idx) => {
                const cells = [
                    c.change_target_name,
                    c.parent_company ? c.parent_company.name : '--',
                ]

                if (!this.hideRecordTypeColumn) {
                    cells.push(c.change_target)
                }

                cells.push(c.tracked_object_type == 'Status' ? '' : c.tracked_object_type)
                if (!this.hideTrackedObjectNameColumn) {
                    cells.push(c.tracked_object_name)
                }
                cells.push(c.action_pretty)
                cells.push(c.created_on)

                let created_by_label = c.created_by ? c.created_by.email : ''
                if (c.created_by_type == 'SYSTEM') {
                    created_by_label = 'SYSTEM'
                }
                if (c.created_by_type == 'PARTNER-API') {
                    created_by_label = 'PARTNER API'
                }
                cells.push(created_by_label)

                if (!this.hideEmailColumn) {
                    cells.push(c.employee_email)
                }
                if (!this.hideEmployeeSsnColumn) {
                    cells.push(c.employee_ssn_last_4 ? `XXX-XX-${c.employee_ssn_last_4}` : '')
                }
                cells.push(c.created_from_ip)

                const row = {
                    id: idx,
                    object: c,
                    cells: cells,
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        const headers = [
            {label: 'Record Changed', classes: '', isSearchable: true},
            {label: 'Company', classes: 'cell-company', isSearchable: false, isFilterable: true},
        ]
        if (!this.hideRecordTypeColumn) {
            headers.push({label: 'Record Type', classes: 'cw-9', isSearchable: false, isFilterable: true})
        }

        headers.push({label: 'Change Type', classes: 'cw-9', isSearchable: false, isFilterable: true})
        if (!this.hideTrackedObjectNameColumn) {
            headers.push({label: 'Item', classes: 'cw-18', isSearchable: true, isFilterable: false})
        }
        headers.push({label: 'Action', classes: 'cw-9', isSearchable: false, isFilterable: true})
        headers.push({label: 'Date', classes: 'cw-date', isSearchable: true, defaultSort: true, defaultSortIsDesc: true, type: 'date'})
        headers.push({label: 'Changed By', classes: 'cell-email', isSearchable: false, isFilterable: true})

        if (!this.hideEmailColumn) {
            headers.push({label: this.emailColumnLabel || 'Employee Email', classes: 'cell-email', isSearchable: true})
        }

        if (!this.hideEmployeeSsnColumn) {
            headers.push({label: 'Employee SSN', classes: 'cw-ssn', isSearchable: true})
        }
        headers.push({label: 'IP Address', classes: 'cell-ip-address', isSearchable: true})

        return {
            currentHistoryLog: null,
            headers: headers,
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    methods: {
        onDateFilter(dateRanges) {
            const dateRange = dateRanges[Object.keys(dateRanges)[0]]
            this.$emit('date_filter_change', dateRange)
        },
        onClick($event) {
            this.currentHistoryLog = $event
            this.$emit('changelog_click', $event)
        },
    },
}

</script>
