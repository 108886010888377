<template>
    <div class="flex-page-sidebar">
        <nav class="nav-secondary">
            <div class="dropdown">
                <a class="btn dropdown-toggle" href role="button" id="navSecondaryMenuBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                    Show: {{ $store.state.pageTitle }}
                </a>
                <div class="dropdown-menu" aria-labelledby="navSecondaryMenuBtn">
                    <div v-for="o in navOptions">
                        <router-link :to="{name: o.value}" class="dropdown-item" :class="{'active': activeRoute == o.value}">{{ o.text }}</router-link>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    computed: {
        activeRoute() {
            let active = ''
            this.navOptions.forEach(opt => {
                if (this.$route.matched.find(r => r.name == opt.value)) {
                    active = opt.value
                    return false
                }
            })

            return active
        },
    },
    props: ['navOptions', ],
}
</script>
