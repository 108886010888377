<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">

                        <h1 class="text-center mb-4">No Email Access</h1>
        
                        <p>
                        To use GetMyPayStub, you need to have access to the email
                        account that you provided to your employer.
                        </p>
        
                        <p>
                        If you do not have access to this email account, please
                        contact your employer to update the email address
                        on file.
                        </p>
        
                        <p class="text-center">
                            <router-link class="btn btn-link" :to="{name: 'login'}">Back to Sign In</router-link>
                        </p>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
