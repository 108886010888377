<template>
    <div>
        
        <div class="py-4">
            <div class="loader text-center">
                <div class="spinner">
                    <div class="lds-grid">
                        <div></div><div></div><div></div>
                        <div></div><div></div><div></div>
                        <div></div><div></div><div></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'

export default {
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Logout')

        if (!global.webkit.messageHandlers.cordova_iab) {
            if (console && console.error) {
                console.error("Cordova IAB postMessage API not found!")
            }
        }
        global.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({"command": "logout"}))
    },
}
</script>

