<template>
    <div>
        <form ref="form" @submit.prevent="saveUniform($event)" novalidate autocomplete="off" v-if="uniform">
            <div class="mb-2">
                <h3>{{ uniform.uniform.name }}</h3>

                <div class="card mt-2">
                    <div class="card-body py-1 mt-1">
                        <div v-for="item in itemData" :key="item.name + '-size'">
                            <h5>{{ item.name }}</h5>
                            <div class="mui-textfield" v-if="item.categories.length == 1 && item.categories[0].sizes.length == 1">
                                <span class="textfield">{{ item.selected_category_size }}</span>
                                <label>{{ item.name + ' Size' }}</label>
                            </div>
                            <form-input
                                v-else
                                type="select"
                                :label="item.name + ' Size'"
                                v-model="item.selected_category_size"
                                @update:model-value="onItemSizeSelect(item, $event)"
                                :opt-groups="makeItemSizeOptGroups(item)"
                                :errors="item.errors.categorySize"
                            />

                            <div v-if="item.selected_category && colorOptionsByItemCategory[item.name][item.selected_category].length">
                                <form-input
                                    v-if="colorOptionsByItemCategory[item.name][item.selected_category].length > 1"
                                    type="select"
                                    :readonly="false"
                                    :label="item.name + ' Color'"
                                    :options="colorOptionsByItemCategory[item.name][item.selected_category]"
                                    v-model="item.selected_color"
                                    @update:model-value="onItemColorSelect(item, $event)"
                                    :errors="item.errors.color"
                                />
                                <div
                                    class="mui-textfield"
                                    v-if="colorOptionsByItemCategory[item.name][item.selected_category].length == 1"
                                >
                                    <span class="textfield">{{ item.selected_color }}</span>
                                    <label>{{ item.name + ' Color' }}</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <p v-if="!hidesubmit">
                <button class="btn btn-primary" type="submit">Submit</button>
            </p>
        </form>
    </div>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
import moment from 'moment'

export default {
    props: ['uniform', 'hidesubmit', ],
    components: { InfoWidget, },
    data() {
        let data = {
            itemData: this.makeItemData(),
            formData: {},
            colorOptionsByItemCategory: this.makeColorOptionsByItemCategory()
        }
        return data
    },
    mounted() {
        for (let item of this.itemData) {
            if (item.categories.length ==  1 && item.categories[0].sizes.length == 1) {
                let selected_category = item.categories[0].name
                let selected_size = item.categories[0].sizes[0].name
                item.selected_category_size = `${selected_category}-${selected_size}`
                this.onItemSizeSelect(item, item.selected_category_size)
            }
        }
    },
    methods: {
        makeItemData() {
            let itemData = JSON.parse(JSON.stringify(this.uniform.uniform_version.item_data))
            itemData.forEach(i => i.errors = {})
            if (Object.keys(this.uniform.response_data).length) {
                for (let selected_item of this.uniform.response_data) {
                    let item = itemData.find(i => i.name == selected_item.name)
                    if (item) {
                        item.selected_category = selected_item.category
                        item.selected_size = selected_item.size
                        item.selected_category_size = `${item.selected_category}-${item.selected_size}`
                        item.selected_color = selected_item.color
                    }
                }
            }
            return itemData
        },
        getFormData() {
            let formData = {
                selected_item_data: [],
            }
            for (let i of this.itemData) {
                formData.selected_item_data.push({
                    'name': i.name,
                    'category': i.selected_category,
                    'size': i.selected_size,
                    'color': i.selected_color
                })
            }
            return formData
        },
        makeItemSizeOptGroups(item) {
            let optGroups = []
            for (let category of item.categories) {
                let optGroup = {
                    label: category.name,
                    options: []
                }
                for (let size of category.sizes) {
                    optGroup.options.push({text: `${category.name} ${size.name}`, value: `${category.name}-${size.name}`})
                }
                optGroups.push(optGroup)
            }
            return optGroups
        },
        makeColorOptionsByItemCategory() {
            let colorOptionsByItemCategory = {}
            for (let item of this.uniform.uniform_version.item_data) {
                colorOptionsByItemCategory[item.name] = {}
                for (let category of item.categories) {
                    colorOptionsByItemCategory[item.name][category.name] = []
                    for (let color of category.colors) {
                        colorOptionsByItemCategory[item.name][category.name].push({text: color.name, value: color.name})
                    }
                }
            }
            return colorOptionsByItemCategory
        },
        onItemSizeSelect(item, event) {
            if (event) {
                let [category, size] = event.split('-')
                if (item.selected_category != category) {
                    item.selected_color = null
                }
                item.selected_category = category
                item.selected_size = size
                item.errors.categorySize = null
                let colorOptions = this.colorOptionsByItemCategory[item.name][item.selected_category]
                if (colorOptions.length == 1) {
                    item.selected_color = colorOptions[0].value
                }
            } else {
                item.selected_category = null
                item.selected_size = null
                item.selected_color = null
            }
            this.formData = this.getFormData()
        },
        onItemColorSelect(item, event) {
            if (event) {
                item.errors.color = null
            }
            this.formData = this.getFormData()
        },
        validate() {
            for (let item of this.itemData) {
                if (!(item.selected_category && item.selected_size)) {
                    item.errors.categorySize = ['This field is required']
                    return false
                }
                if (this.colorOptionsByItemCategory[item.name][item.selected_category].length && !item.selected_color) {
                    item.errors.color = ['This field is required']
                    return false
                }
            }
            return true
        },
        saveUniform() {},
    },
}
</script>
