import countries from '@/countries'
import states from '@/states'

const canadianProvinces = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'Newfoundland and Labrador',
    'Nova Scotia',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Northwest Territories',
    'Nunavut',
    'Yukon',
]

const allStates = []
states.forEach(s => {
    allStates.push(s.name)
})

const allCountries = []
countries.forEach(c => {
    allCountries.push(c.name)
})

const rules = {
    passport: {
        rules: {
            presence: {allowEmpty: false},
            format: {
                pattern: "^[A-Za-z0-9]*$",
                message: "can only contain letters and numbers"
            }
        },
        inputOptions: {min_length: 6, maxlength: 9,},
    },
    i551: {
        rules: {
            presence: {allowEmpty: false},
            format: {
                pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                message: "must start with three letters followed by 10 digits. E.g.: QLC3856718663"
            }
        },
        inputOptions: {min_length: 13, maxlength: 13,}
    },
    foreignPassport: {
        rule: {
            presence: {allowEmpty: false},
            format: {
                pattern: "^[A-Za-z0-9]*$",
                message: "must contain only letters and numbers"
            }
        },
        inputOptions: {min_length: 6, maxlength: 12,},
    },
    i94: {
        rules: {
            presence: {allowEmpty: false},
            format: {
                pattern: "^[0-9A-Za-z]*$",
                message: "can only contain letters and numbers."
            }
        },
        inputOptions: {min_length: 11, maxlength: 11,},
    },
    i766: {
        rules: {
            presence: {allowEmpty: false},
            format: {
                pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                message: "must start with three letters followed by 10 digits. E.g.: MSC0902850001"
            }
        },
        inputOptions: {min_length: 13, maxlength: 13,},
    },
    sevis_number: {
        rules: {
            presence: {allowEmpty: false},
            format: {
                pattern: "^N[0-9]{10}$",
                message: "must be 'N' followed by 10 digits"
            },
        },
        inputOptions: {min_length: 13, maxlength: 13,},
    },
    stateId: {
        rules: {
            presence: {allowEmpty: false},
            format: {
                pattern: "^[a-zA-Z0-9*-]*$",
                message: "can only contain letters, numbers, *, and -."
            }
        },
        inputOptions: {min_length: 1, maxlength: 17,},
    },
    ssn: {
        rules: {
            presence: {allowEmpty: false}, ssn: {specialAllowedValues: ['n/a', 'N/A', 'N/a', 'n/A'], }
        },
        inputOptions: {maxlength: 11, },
    },
}

export default {
    listA: [
        {
            slug: 'list_a_na',
            name: 'N/A',
            issuers: ['N/A'],
            number_is_na: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'us_passport',
            name: 'U.S. Passport',
            immigration_statuses: ['us_citizen', 'us_national'],
            issuers: [
                'U.S. Department of State',
            ],
            documentNumberRules: rules.passport.rules,
            documentNumberInputOptions: rules.passport.inputOptions,
            sideLabels: {
                front: 'Passport ID page',
                back: 'Passport barcode page',
            },
            examples: {
                front: 'i9doc-example-vert.jpg',
                back: 'i9doc-example-vert.jpg',
            },
            requiresBackPhoto: true,
        },
        {
            slug: 'us_passport_card',
            name: 'U.S. Passport Card',
            immigration_statuses: ['us_citizen', 'us_national'],
            issuers: [
                'U.S. Department of State',
            ],
            documentNumberRules: rules.passport.rules,
            documentNumberInputOptions: rules.passport.inputOptions,
            requiresBackPhoto: false,
        },
        {
            slug: 'permanent_resident_card',
            name: 'Perm. Resident Card (Form I-551)',
            immigration_statuses: ['us_permanent_resident'],
            issuers: [
                'U.S. Citizenship and Immigration Services',
                'DOJ Immigration and Naturalization Service',
            ],
            documentNumberRules: rules.i551.rules,
            documentNumberInputOptions: rules.i551.inputOptions,
            requiresBackPhoto: false,
        },
        {
            slug: 'alien_reg_receipt_card',
            name: 'Alien Reg. Receipt Card (Form I-551)',
            immigration_statuses: ['us_permanent_resident'],
            issuers: [
                'DOJ Immigration and Naturalization Service',
            ],
            documentNumberRules: rules.i551.rules,
            documentNumberInputOptions: rules.i551.inputOptions,
        },
        {
            slug: 'foreign_passport_with_temp_i551_stamp',
            name: 'Foreign Passport with Temp. I-551 Stamp',
            shortName: 'Foreign Passport',
            immigration_statuses: ['us_permanent_resident'],
            issuers: allCountries,
            documentNumberRules: rules.foreignPassport.rules,
            documentNumberInputOptions: rules.foreignPassport.inputOptions,
            secondary_documents: [
                {
                    slug: 'temp_i551_stamp',
                    name: 'Temporary I-551 Stamp',
                    number_is_na: true,
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'DOJ Immigration and Naturalization Service',
                    ],
                    skipBackPhoto: true,
                }
            ],
        },
        {
            slug: 'foreign_passport_with_temp_i551_mriv',
            name: 'Foreign Passport with Temp. I-551 MRIV',
            shortName: 'Foreign Passport',
            immigration_statuses: ['us_permanent_resident'],
            issuers: allCountries,
            documentNumberRules: rules.foreignPassport.rules,
            documentNumberInputOptions: rules.foreignPassport.inputOptions,
            secondary_documents: [
                {
                    slug: 'temp_i551_mriv',
                    name: 'Machine-readable immigrant visa (MRIV)',
                    issuers: [
                        'U.S. Department of State',
                    ],
                    documentNumberRules: rules.i551.rules,
                    documentNumberInputOptions: rules.i551.inputOptions,
                    skipBackPhoto: true,
                }
            ],
        },
        {
            slug: 'receipt_form_i94_i551_stamp_photo',
            name: 'Receipt Form I-94/I-94A w/I-551 stamp, photo',
            issuers: ['U.S. Department of Homeland Security'],
            immigration_statuses: ['us_permanent_resident'],
            documentNumberRules: rules.i94.rules,
            documentNumberInputOptions: rules.i94.inputOptions,
            skipBackPhoto: true,
        },
        {
            slug: 'receipt_replacement_perm_res_card_i551',
            name: 'Receipt Replacement Perm. Res. Card (Form I-551)',
            issuers: ['U.S. Citizenship and Immigration Services'],
            immigration_statuses: ['us_permanent_resident'],
            documentNumberRules: rules.i551.rules,
            documentNumberInputOptions: rules.i551.inputOptions,
        },

        {
            slug: 'employment_auth_doc_i766',
            name: 'Employment Auth. Document (Form I-766)',
            issuers: ['U.S. Citizenship and Immigration Services'],
            immigration_statuses: ['authorized_alien'],
            documentNumberRules: rules.i766.rules,
            documentNumberInputOptions: rules.i766.inputOptions,
            requiresBackPhoto: true,
        },

        {
            slug: 'foreign_passport_work_auth_nonimmigrant',
            name: 'Foreign Passport, work-authorized nonimmigrant',
            shortName: 'Foreign Passport',
            issuers: allCountries,
            immigration_statuses: ['authorized_alien'],
            documentNumberRules: rules.foreignPassport.rules,
            documentNumberInputOptions: rules.foreignPassport.inputOptions,
            secondary_documents: [
                {
                    slug: 'foreign_passport_work_auth_nonimmigrant_i94',
                    name: 'Form I-94/I-94A',
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    skipBackPhoto: true,
                },
                {
                    slug: 'foreign_passport_work_auth_nonimmigrant_receipt_i94',
                    name: 'Receipt Form I-94/I-94A',
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    skipBackPhoto: true,
                },
            ],
            tertiary_documents: [
                {
                    slug: 'foreign_passport_work_auth_nonimmigrant_tertiary_na',
                    name: 'N/A',
                    issuers: ['N/A'],
                    number_is_na: true,
                },
                {
                    slug: 'foreign_passport_work_auth_nonimmigrant_form_i20',
                    name: 'Form I-20',
                    issuers: [
                        'U.S. Immigration and Customs Enforcement',
                        'US DOJ INS',
                    ],
                    documentNumberRules: rules.sevis_number.rules,
                    documentNumberInputOptions: rules.sevis_number.inputOptions,
                },
                {
                    slug: 'foreign_passport_work_auth_nonimmigrant_form_ds2019',
                    name: 'Form DS-2019',
                    issuers: ['U.S. Department of State'],
                    documentNumberRules: rules.sevis_number.rules,
                    documentNumberInputOptions: rules.sevis_number.inputOptions,
                },
            ],
        },

        {
            slug: 'fsm_passport_with_form_i94',
            name: 'FSM Passport with Form I-94',
            shortName: 'FSM Passport',
            issuers: ['Federated States of Micronesia'],
            immigration_statuses: ['authorized_alien'],
            documentNumberRules: {presence: {allowEmpty: false}, format: {
                pattern: "^[A-Za-z0-9]*$",
                message: "must contain only letters and numbers"
            }},
            documentNumberInputOptions: {min_length: 6, maxlength: 12,},
            secondary_documents: [
                {
                    slug: 'fsm_passport_with_form_i94_i94',
                    name: 'Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
                {
                    slug: 'fsm_passport_with_form_i94_receipt_i94',
                    name: 'Receipt Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
            ],
        },

        {
            slug: 'rmi_passport_with_form_i94',
            name: 'RMI Passport with Form I-94',
            shortName: 'RMI Passport',
            issuers: ['Republic of the Marshall Islands'],
            immigration_statuses: ['authorized_alien'],
            documentNumberRules: rules.foreignPassport.rules,
            documentNumberInputOptions: rules.foreignPassport.inputOptions,
            secondary_documents: [
                {
                    slug: 'rmi_passport_with_form_i94_i94',
                    name: 'Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
                {
                    slug: 'rmi_passport_with_form_i94_receipt_i94',
                    name: 'Receipt Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
            ],
        },
        {
            slug: 'receipt_replacement_ead_form_i766',
            name: 'Receipt Replacement EAD (Form I-766)',
            issuers: ['U.S. Citizenship and Immigration Services'],
            immigration_statuses: ['authorized_alien'],
            documentNumberRules: rules.i766.rules,
            documentNumberInputOptions: rules.i766.inputOptions,
        },

        {
            slug: 'receipt_replacement_form_i94_with_refugee_stamp',
            name: 'Receipt Form I-94/I-94A w/refugee stamp (or RE class of admission)',
            issuers: ['U.S. Department of Homeland Security'],
            immigration_statuses: ['authorized_alien'],
            documentNumberRules: rules.i766.rules,
            documentNumberInputOptions: rules.i766.inputOptions,
        },

        {
            slug: 'receipt_foreign_passport_work_auth_nonimmigrant',
            name: 'Receipt Foreign Passport, work-authorized nonimmigrant',
            shortName: 'Receipt Foreign Passport',
            issuers: allCountries,
            immigration_statuses: ['authorized_alien'],
            documentNumberRules: rules.foreignPassport.rules,
            documentNumberInputOptions: rules.foreignPassport.inputOptions,
            secondary_documents: [
                {
                    slug: 'r_foreign_passport_work_auth_nonimmigrant_i94',
                    name: 'Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
                {
                    slug: 'r_foreign_passport_work_auth_nonimmigrant_receipt_i94',
                    name: 'Receipt Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
            ],
            tertiary_documents: [
                {
                    slug: 'r_foreign_passport_work_auth_nonimmigrant_tertiary_na',
                    name: 'N/A',
                    issuers: ['N/A'],
                    number_is_na: true,
                },
                {
                    slug: 'r_foreign_passport_work_auth_nonimmigrant_form_i20',
                    name: 'Form I-20',
                    issuers: [
                        'U.S. Immigration and Customs Enforcement',
                        'US DOJ INS',
                    ],
                    documentNumberRules: rules.sevis_number.rules,
                    documentNumberInputOptions: rules.sevis_number.inputOptions,
                },
                {
                    slug: 'r_foreign_passport_work_auth_nonimmigrant_form_ds2019',
                    name: 'Form DS-2019',
                    issuers: ['U.S. Department of State'],
                    documentNumberRules: rules.sevis_number.rules,
                    documentNumberInputOptions: rules.sevis_number.inputOptions,
                },
            ],
        },

        {
            slug: 'receipt_fsm_passport_with_form_i94',
            name: 'Receipt FSM Passport with Form I-94',
            issuers: ['Federated States of Micronesia'],
            immigration_statuses: ['authorized_alien'],
            documentNumberRules: rules.foreignPassport.rules,
            documentNumberInputOptions: rules.foreignPassport.inputOptions,
            secondary_documents: [
                {
                    slug: 'receipt_fsm_passport_with_form_i94_i94',
                    name: 'Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
                {
                    slug: 'receipt_fsm_passport_with_form_i94_receipt_i94',
                    name: 'Receipt Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
            ],
            tertiary_documents: [
                {
                    slug: 'receipt_fsm_passport_with_form_i94_na',
                    name: 'N/A',
                    issuers: ['N/A'],
                    number_is_na: true,
                },
                {
                    slug: 'receipt_fsm_passport_with_form_i94_i20',
                    name: 'Form I-20',
                    issuers: [
                        'U.S. Immigration and Customs Enforcement',
                        'US DOJ INS',
                    ],
                    documentNumberRules: rules.sevis_number.rules,
                    documentNumberInputOptions: rules.sevis_number.inputOptions,
                },
                {
                    slug: 'receipt_fsm_passport_with_form_i94_ds2019',
                    name: 'Form DS-2019',
                    issuers: ['U.S. Department of State'],
                    documentNumberRules: rules.sevis_number.rules,
                    documentNumberInputOptions: rules.sevis_number.inputOptions,
                },
            ],
        },

        {
            slug: 'receipt_rmi_passport_with_form_i94',
            name: 'Receipt RMI Passport with Form I-94',
            issuers: ['Republic of the Marshall Islands'],
            immigration_statuses: ['authorized_alien'],
            documentNumberRules: rules.foreignPassport.rules,
            documentNumberInputOptions: rules.foreignPassport.inputOptions,
            secondary_documents: [
                {
                    slug: 'receipt_rmi_passport_with_form_i94_i94',
                    name: 'Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
                {
                    slug: 'receipt_rmi_passport_with_form_i94_receipt_i94',
                    name: 'Receipt Form I-94/I-94A',
                    issuers: [
                        'U.S. Citizenship and Immigration Services',
                        'U.S. Customs and Border Protection',
                    ],
                    documentNumberRules: rules.i94.rules,
                    documentNumberInputOptions: rules.i94.inputOptions,
                    skipBackPhoto: true,
                },
            ],
            tertiary_documents: [
                {
                    slug: 'receipt_rmi_passport_with_form_i94_na',
                    name: 'N/A',
                    issuers: ['N/A'],
                    number_is_na: true,
                },
                {
                    slug: 'receipt_rmi_passport_with_form_i94_form_i20',
                    name: 'Form I-20',
                    issuers: [
                        'U.S. Immigration and Customs Enforcement',
                        'US DOJ INS',
                    ],
                    documentNumberRules: rules.sevis_number.rules,
                    documentNumberInputOptions: rules.sevis_number.inputOptions,
                },
                {
                    slug: 'receipt_rmi_passport_with_form_i94_form_ds2019',
                    name: 'Form DS-2019',
                    issuers: ['U.S. Department of State'],
                    documentNumberRules: rules.sevis_number.rules,
                    documentNumberInputOptions: rules.sevis_number.inputOptions,
                },
            ],
        },
    ],
    listB: [
        {
            slug: 'list_b_na',
            name: 'N/A',
            issuers: ['N/A'],
            number_is_na: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: "drivers_license_issued_by_stateterritory",
            name: "Driver's license issued by state/territory",
            issuers: allStates,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            documentNumberRules: rules.stateId.rules,
            documentNumberInputOptions: rules.stateId.inputOptions,
            requiresBackPhoto: true,
        },

        {
            slug: 'id_card_issued_by_stategterritory',
            name: 'ID card issued by state/territory',
            issuers: allStates,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            documentNumberRules: rules.stateId.rules,
            documentNumberInputOptions: rules.stateId.inputOptions,
            requiresBackPhoto: true,
        },
        {
            slug: 'government_id',
            name: 'Government ID',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'school_id',
            name: 'School ID',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'voter_registration_card',
            name: 'Voter registration card',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'us_military_card',
            name: 'U.S. Military card',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'us_military_draft_record',
            name: 'U.S. Military draft record',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `military_dependents_id_card`,
            name: `Military dependent's ID card`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `uscg_merchant_mariner_card`,
            name: `USCG Merchant Mariner card`,
            issuers: ['U.S. Coast Guard'],
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `native_american_tribal_document_id`,
            name: `Native American tribal document`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `canadian_drivers_license`,
            name: `Canadian driver's license`,
            issuers: canadianProvinces,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `school_record`,
            name: `School record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `report_card`,
            name: `Report card (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `clinic_record`,
            name: `Clinic record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `doctor_record`,
            name: `Doctor record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `hospital_record`,
            name: `Hospital record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },

        {
            slug: `day_care_record`,
            name: `Day-care record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `nursery_school_record`,
            name: `Nursery School record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `special_placement`,
            name: `Special Placement`,
            number_is_na: true,
            issuers: ['N/A'],
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `individual_under_age_18`,
            name: `Individual under Age 18`,
            number_is_na: true,
            issuers: ['N/A'],
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },

        {
            slug: "receipt_replacement_driver_s_license",
            name: "Receipt Replacement Driver's license",
            issuers: allStates,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'receipt_replacement_id_card',
            name: 'Receipt Replacement ID card',
            issuers: allStates,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'receipt_replacement_government_id',
            name: 'Receipt Replacement Government ID',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },

        {
            slug: 'receipt_replacement_school_id',
            name: 'Receipt Replacement School ID',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'receipt_replacement_voter_reg_card',
            name: 'Receipt Replacement Voter Reg. card',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'receipt_replacement_us_military_card',
            name: 'Receipt Replacement U.S. Military card',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: 'receipt_replacement_us_military_draft_record',
            name: 'Receipt Replacement U.S. Military Draft record',
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `receipt_replacement_military_dep_card`,
            name: `Receipt Replacement Military Dep. card`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `receipt_replacement_merchant_mariner_card`,
            name: `Receipt Replacement Merchant Mariner Card`,
            issuers: ['U.S. Coast Guard'],
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `receipt_replacement_native_american_tribal_doc`,
            name: `Receipt Replacement Native American Tribal Doc`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `receipt_replacement_canadian_dl`,
            name: `Receipt Replacement Canadian DL`,
            issuers: canadianProvinces,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `receipt_replacement_school_record`,
            name: `Receipt Replacement School record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `receipt_replacement_report_card`,
            name: `Receipt Replacement Report card (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `receipt_replacement_clinic_record`,
            name: `Receipt Replacement Clinic record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `receipt_replacement_doctor_record`,
            name: `Receipt Replacement Doctor record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `receipt_replacement_hospital_record`,
            name: `Receipt Replacement Hospital record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `receipt_replacement_day_care_record`,
            name: `Receipt Replacement Day-care record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
        {
            slug: `receipt_replacement_nursery_school_record`,
            name: `Receipt Replacement Nursery School record (under 18)`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            is_under_18: true,
        },
    ],
    listC: [
        {
            slug: 'list_c_na',
            name: 'N/A',
            issuers: ['N/A'],
            number_is_na: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `social_security_card`,
            name: `Social Security Card (Unrestricted)`,
            issuers: [
                'Social Security Administration',
                'U.S. Department of Health and Human Services',
                'Social Security Board',
                'Department of Health, Education and Welfare',
            ],
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
            documentNumberRules: rules.ssn.rules,
            documentNumberInputOptions: rules.ssn.inputOptions,
            requiresBackPhoto: true,
        },
        {
            slug: `form_fs_545`,
            name: `Form FS-545`,
            issuers: ['U.S. Department of State',],
            immigration_statuses: ['us_citizen', 'us_national'],
        },
        {
            slug: `form_ds_1350`,
            name: `Form DS-1350`,
            issuers: ['U.S. Department of State',],
            immigration_statuses: ['us_citizen', 'us_national'],
        },
        {
            slug: `form_fs_240`,
            name: `Form FS-240`,
            issuers: ['U.S. Department of State',],
            immigration_statuses: ['us_citizen', 'us_national'],
        },
        {
            slug: `us_birth_certificate`,
            name: `U.S. Birth Certificate`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national'],
        },
        {
            slug: `native_american_tribal_document_auth`,
            name: `Native American tribal document`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `form_i_197`,
            name: `Form I-197`,
            issuers: ['DOJ Immigration and Naturalization Service'],
            immigration_statuses: ['us_citizen', 'us_national'],
        },
        {
            slug: `form_i_179`,
            name: `Form I-179`,
            issuers: ['DOJ Immigration and Naturalization Service'],
            immigration_statuses: ['us_citizen', 'us_national'],
        },
        {
            slug: `employment_auth_document_dhs`,
            name: `Employment auth. document (DHS) List C #7`,
            issuers: ['DHS'],
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },

        {
            slug: `receipt_replace_unrestricted_ss_card`,
            name: `Receipt Replace. Unrestricted SS Card`,
            issuers: ['Social Security Administration'],
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },

        {
            slug: `receipt_replacement_birth_certificate`,
            name: `Receipt Replacement Birth Certificate`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national'],
        },
        {
            slug: `receipt_replace_native_american_tribal_doc`,
            name: `Receipt Replace. Native American Tribal Doc`,
            enter_issuer: true,
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
        {
            slug: `receipt_replace_employment_auth_doc`,
            name: `Receipt Replace. Employment Auth. Doc. (DHS) List C #7 Receipt`,
            issuers: ['DHS'],
            immigration_statuses: ['us_citizen', 'us_national', 'us_permanent_resident', 'authorized_alien'],
        },
    ],
}
