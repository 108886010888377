<template>
<div v-if="user.id && isLocked" class="alert alert-locked mb-2">
    <div class="d-flex mb-2">
        <div class="flex-grow-0 flex-shrink-0 mr-1">
            <i aria-hidden="true" class="alert-icon fas fa-fw fa-lock"></i>
        </div>
        <div>
            <strong>This user is locked out</strong> because their PIN was reset at another organization.
            <more-info class="moreinfo-inline">
                <p class="mb-1">
                    This user has access to <strong>multiple corporate accounts</strong> in GetMyPayStub. One of them reset this user's PIN.
                </p>
                <p class="mb-1">
                    Resetting the PIN automatically locked access to all other corporate accounts (including this one) to ensure that all accounts stay secure.
                </p>
                <p class="mb-0">
                    Clicking "Unlock Account" will allow this user to regain access to your organization.
                </p>
            </more-info>
        </div>
    </div>

    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="unlock()">Unlock &amp; Restore Access</button>

    <unlock-modal :user="user" :client="client" v-if="user.id" ref="unlockModal" @unlocked="$emit('unlocked')"/>
</div>
</template>

<script>
import UnlockModal from '@/components/UnlockModal'

export default {
    props: ['user', 'client', 'isLocked', ],
    components: {UnlockModal, },
    methods: {
        unlock() {
            this.$refs.unlockModal.open()
        },
    },
}
</script>
