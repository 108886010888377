<template>
    <div class="row mb-1">
        <div class="col-12">
            <div class="card mb-1">
                <div class="card-body">
                    <form @submit.prevent="onSubmit">
                        <form-errors :errors="formErrors"/>
                        <form-input label="First Name" type="text" v-model="formData.first_name" :errors="errors.first_name" maxlength="25" />
                        <form-input label="Middle Name" type="text" v-model="formData.middle_name" :errors="errors.middle_name" />
                        <form-input label="Last Name" type="text" v-model="formData.last_name" :errors="errors.last_name" maxlength="25" />
                        <div class="form-row">
                            <div class="col-12 col-sm-6">
                                <form-input label="Suffix" v-model="formData.suffix" :errors="errors.suffix" type="select" :options="suffixOptions"/>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 col-sm-6">
                                <form-input label="Date of Birth" v-model="formData.dob" :errors="errors.dob" type="date" placeholder="MM/DD/YYYY" />
                            </div>
                            <div class="col-12 col-sm-6">
                                <form-input label="Phone Number" type="phone" v-model="formData.phone_home" :errors="errors.phone_home" />
                            </div>
                        </div>
                        <portal to="identity-edit-modal-footer">
                            <button-row stacked>
                                <button type="button" @click.prevent="onPrev()" class="btn btn-outline-primary">Cancel</button>
                                <button type="button" @click.prevent="onSubmit()" class="btn btn-primary">Save</button>
                            </button-row>
                        </portal>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ButtonRow from '@/components/ButtonRow'
import api from '@/api'
import moment from 'moment'

export default {
    mixins: [FormMixin],
    components: {ButtonRow, },
    props: ['employee', 'employeeUpdate', 'prev'],
    watch: {
        employee() {
            this.reset()
        },
        employeeUpdate() {
            this.reset()
        }
    },
    computed: {
        suffixOptions() {return [
            {text: 'JR', value: 'JR'},
            {text: 'SR', value: 'SR'},
            {text: 'III', value: 'III'},
            {text: 'IV', value: 'IV'},
        ]},
    },
    data() {
        const data = {
            formData: this.makeFormData(),
            formRules: {
                first_name: {presence: {allowEmpty: false}, },
                last_name: {presence: {allowEmpty: false}, },
                dob: {presence: {allowEmpty: false}, date: {latest: moment().format()}},
                phone_home: {presence: {allowEmpty: false}, ten_digit_phone: true},
            },
            pollInterval: null,
            formLabels: {
                dob: 'Date of Birth',
                phone_home: 'Phone Number',
            },
        }
        return data
    },
    unmounted() {
        if (this.pollInterval) {
            clearInterval(this.pollInterval)
        }
    },
    methods: {
        makeFormData() {
            let source = this.employeeUpdate ? this.employeeUpdate : this.employee
            let formData = {
                action: 'edit-identity',
                first_name: source.first_name,
                middle_name: source.middle_name,
                last_name: source.last_name,
                suffix: source.suffix,
                dob: moment(source.dob).format('MM/DD/YYYY'),
                phone_home: source.phone_home,
            }
            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            this.$store.dispatch('START_LOADING')
            if (this.employeeUpdate) {
                this.patchEmployeeUpdate(this.employeeUpdate.id)
            } else {
                // If employeeUpdate wasn't passed in, that means we need to create one first before we patch it with form data
                api.post(`/me/ess/companies/${this.employee.company_id}/updates`, {}).then((resp) => {
                    this.patchEmployeeUpdate(resp['id'])
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }
        },
        onPrev() {
            this.$emit('prev')
        },
        patchEmployeeUpdate(employeeUpdateId) {
            this.$store.dispatch('START_LOADING')
            api.patch(`/me/ess/companies/${this.employee.company_id}/updates/${employeeUpdateId}`, this.getFormData()).then((resp) => {
                // poll every 2 seconds to see the task status
                this.pollInterval = setInterval(() => {this.pollEmployeeUpdate(employeeUpdateId)}, 2000)
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        pollEmployeeUpdate(employeeUpdateId) {
            api.get(`/me/ess/updates/${employeeUpdateId}/progress`).then((resp) => {
                if (resp['status'] == 'SUCCESS') {
                    clearInterval(this.pollInterval)
                    this.$emit('updated')
                    this.$store.dispatch('STOP_LOADING')
                } else if (resp['status'] == 'FAILURE') {
                    clearInterval(this.pollInterval)
                    this.$bus.showError(resp['error'])
                    this.$store.dispatch('STOP_LOADING')
                } else {
                    // do nothing, keep polling
                }
            })
        },
    }
}
</script>