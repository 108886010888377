<template>
    <sidebar @close="$emit('close')">
        <template v-slot:title>
            {{ deductionType.name }}
        </template>

        <template v-slot:header>
            <div>
                <div>
                    <span class="text-semibold">Status: </span>
                    <span v-if="deductionType.current_version.is_past">Past</span>
                    <span v-if="deductionType.current_version.is_active">Current</span>
                    <span v-if="deductionType.current_version.is_future">Future</span>
                </div>

                <div>
                    <span class="text-semibold">Has been used: </span>
                    <span v-if="deductionType.has_been_used">Yes</span>
                    <span v-if="!deductionType.has_been_used">No</span>
                </div>

            </div>
            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a href class="nav-link" :class="{active: activeTab == 'main'}" data-toggle="tab" role="tab" aria-controls="tabContentMain" :aria-selected="activeTab == 'main'" @click.prevent="activeTab='main'">Main</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'versions'}" href="#tabContentVersions" id="tabVersions" data-toggle="tab" role="tab" aria-controls="tabContentVersions" :aria-selected="activeTab == 'versions'" @click.prevent="activeTab='versions'">Versions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot>
            <div class="tab-content flex-body flex-container">
                <div class="tab-pane flex-body" :class="{'show active': activeTab == 'main'}" id="tabContentMain" role="tabpanel" aria-labelledby="tabLinkMain">
                    <div class="card mb-2">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Current Version</h4>
                            </span>

                            <button class="btn btn-primary" type="button" @click.prevent="editIsOpen = true">Edit</button>
                            <button class="btn btn-outline-primary ml-1" type="button" @click.prevent="onDelete" v-if="!deductionType.has_been_used">Delete</button>
                        </div>

                        <div class="card-body py-1">
                            <deduction-type-version :version="deductionType.current_version" />
                        </div>
                    </div>
                </div>

                <div class="tab-pane flex-body" :class="{'show active': activeTab == 'versions'}" id="tabContentVersions" role="tabpanel" aria-labelledby="tabLinkVersions">

                    <version-widget :version="version" v-for="version in versions" v-if="versions && versions.length">
                        <deduction-type-version :version="version" />
                    </version-widget>

                    <p v-if="!versions || !versions.length">
                        Loading...
                    </p>
                </div>

            </div>

            <edit-modal
                v-if="editIsOpen"
                :deductionType="deductionType"
                @updated="onUpdate"
                @close="editIsOpen = false"
            />

        </slot>
    </sidebar>
</template>

<script>
import EditModal from './DeductionTypeModal'
import Sidebar from '@/components/Sidebar'
import VersionWidget from './VersionWidget'
import DeductionTypeVersion from './DeductionTypeVersion'

export default {
    components: {EditModal, Sidebar, DeductionTypeVersion, VersionWidget, },
    props: ['deductionType', ],
    data() {
        return {
            editIsOpen: false,
            activeTab: 'main',
            versions: null,
            expandedVersions: {},
        }
    },
    mounted() {
        this.getVersions()
    },
    methods: {
        getVersions() {
            this.$api.get(`/admin/tax-engine/deduction-types/${this.deductionType.id}`).then(resp => {
                this.versions = resp.versions.sort((a, b) => a.created_on < b.created_on ? 1 : -1)
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
        onDelete() {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(`/admin/tax-engine/deduction-types/${this.deductionType.id}`).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('deleted')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate($event) {
            this.getVersions()
            this.$emit('updated', $event)
        },
    },
}
</script>

