<template>
<div class="form-row">
    <div class="col-4">
        <form-input label="Child Support Amount" v-model="formData.current_child_support_amount" :errors="errors.current_child_support_amount" type="currency" @update:model-value="$emit('update:modelValue', formData)" />
    </div>

    <div class="col-4">
        <form-input label="Medical Amount" v-model="formData.current_medical_amount" :errors="errors.current_medical_amount" type="currency" @update:model-value="$emit('update:modelValue', formData)" />
    </div>

    <div class="col-4">
        <form-input label="Spousal Support Amount" v-model="formData.current_spousal_amount" :errors="errors.current_spousal_amount" type="currency" @update:model-value="$emit('update:modelValue', formData)" />
    </div>

    <div class="col-4">
        <form-input label="Past Child Support Amount" v-model="formData.past_child_support_amount" :errors="errors.past_child_support_amount" type="currency" @update:model-value="$emit('update:modelValue', formData)" />
    </div>

    <div class="col-4">
        <form-input label="Past Medical Amount" v-model="formData.past_medical_amount" :errors="errors.past_medical_amount" type="currency" @update:model-value="$emit('update:modelValue', formData)" />
    </div>

    <div class="col-4">
        <form-input label="Past Spousal Support Amount" v-model="formData.past_spousal_amount" :errors="errors.past_spousal_amount" type="currency" @update:model-value="$emit('update:modelValue', formData)" />
    </div>

    <div class="col-4">
        <form-input label="Other Amount" v-model="formData.other_amount" :errors="errors.other_amount" type="currency" @update:model-value="$emit('update:modelValue', formData)" />
    </div>

    <div class="col-4">
        <form-input label="Arrears greater than 12 weeks?" v-model="formData.in_arrears" :errors="errors.in_arrears" type="checkbox" @update:model-value="$emit('update:modelValue', formData)" />
    </div>

    <div class="col-4">
        <form-input label="Not supporting spouse or child?" v-model="formData.use_max_rate" :errors="errors.use_max_rate" type="checkbox" @update:model-value="$emit('update:modelValue', formData)" />
    </div>

</div>
</template>

<script>
export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            formData: {
                current_child_support_amount: (this.modelValue && this.modelValue.current_child_support_amount) ? this.modelValue.current_child_support_amount : '0.00',
                current_medical_amount: (this.modelValue && this.modelValue.current_medical_amount) ? this.modelValue.current_medical_amount : '0.00',
                current_spousal_amount: (this.modelValue && this.modelValue.current_spousal_amount) ? this.modelValue.current_spousal_amount : '0.00',
                past_child_support_amount: (this.modelValue && this.modelValue.past_child_support_amount) ? this.modelValue.past_child_support_amount : '0.00',
                past_medical_amount: (this.modelValue && this.modelValue.past_medical_amount) ? this.modelValue.past_medical_amount : '0.00',
                past_spousal_amount: (this.modelValue && this.modelValue.past_spousal_amount) ? this.modelValue.past_spousal_amount : '0.00',
                other_amount: (this.modelValue && this.modelValue.other_amount) ? this.modelValue.other_amount : '0.00',
            },
            errors: {},
        }
    },
}
</script>
