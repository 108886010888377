<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Tentative Nonconfirmation</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    A mismatch case result means that information entered into E-Verify does not match records available to DHS and/or SSA. It is possible for an employee to receive a dual mismatch, which means the case received a mismatch result from both agencies at the same time because information entered into E-Verify does not match records available to both DHS and SSA. E-Verify identifies the agency or agencies associated with the mismatch in the Further Action Notice. This does not mean the employee is not authorized to work. While resolving mismatches, employees must be allowed to work without any change in working conditions, regardless of how long the resolution process takes.
                                </p>

                                <p>
                                    A case can result in a mismatch with DHS because the employee's:
                                </p>

                                <ul>
                                    <li> Name, Alien number, I-94 number and/or foreign passport number are incorrect in DHS records.</li>
                                    <li> U.S. passport, passport card, driver's license, foreign passport or state ID card information could not be verified.</li>
                                    <li> Information was not updated in the employee's DHS records.</li>
                                    <li> Citizenship or immigration status changed.</li>
                                    <li> Record contains another type of error.</li>
                                    <li> Employer entered information incorrectly in E-Verify.</li>
                                    <li> Photo did not match.</li>
                                </ul>

                                <p>
                                    If E-Verify returns a Tentative Nonconfirmation, the system will guide the user through the process of addressing it. This includes sharing the Further Action Notice with the employee in a timely manner and giving them the option for how to address the mismatch.
                                </p>

                                <p>
                                    While resolving mismatches, employees must be allowed to work without any change in working conditions, regardless of how long the resolution process takes.
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page3'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page4'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
