<template>
<modal @close="$emit('close')" ref="modal" :fade="false" :v-center="true" :autoshow="true" :is-closeable="isCloseable">
    <template v-slot:title>Update Employee Vaccination Status</template>

    <slot>
        <form @submit.prevent="onSubmit">

            <div v-show="step == 'intro'">
                <p class="mb-1">You will need the employee's vaccination card or attestation document.</p>
            </div>

            <div v-show="step == 'status'">
                <p>Please select the statement that best describes the employee's vaccination status:</p>

                <form-input type="radio" v-model="formData.vaccination_status" :errors="errors.vaccination_status" :options="statusOptions" />

            </div>

            <div v-show="step == 'details'">

                <p>
                    <form-input type="radio" v-model="formData.lost_card" :errors="errors.lost_card" :options="lostCardOptions" label="" @click="onLostCard"/>
                </p>


                <div class="mb-2">
                    <div v-if="!formData.vaccination_card">
                        <button class="btn btn-outline-primary btn-boxy" @click.prevent="$refs.fileInput.click()">
                            <i class="far fa-camera mr-hf"></i>
                            <span v-if="formData.lost_card == 'yes'">
                                Upload Attestation Document
                            </span>
                            <span v-else>
                                Add Photo
                            </span>
                        </button>

                        <input class="d-none" type="file" @change="onUpload($event)" accept=".pdf,.jpg,.jpeg,.png,.heic,.heif" ref="fileInput" />
                    </div>

                    <div v-if="formData.vaccination_card">
                        <div class="docphoto">
                            <img :src="previewURL" alt="Vaccination Document or Photo">
                        </div>

                        <div class="text-small pt-1">
                            Not quite right? <button class="btn btn-link-inline" @click.prevent="formData.vaccination_card = null">Add a different photo instead</button>
                        </div>
                    </div>
                </div>

                <p>
                    For each shot, please enter the type of vaccine received, the date(s) received and the location.
                </p>

                <p>
                    <b>After entering information for the first shot, click on "+ Add another" to enter information for the second shot (and any additional shots you may want to include).</b>
                </p>

                <div v-for="(id, $index) in Object.keys(shotForms)" :key="id" class="card mb-2">
                    <div class="card-header">
                        <div class="row my-1">
                            <div class="col">
                                <h6 class="mb-0">Shot {{ $index + 1 }}</h6>
                            </div>
                            <div class="col-6 text-right">
                                <a v-if="Object.keys(shotForms).length > 1" href @click.prevent="removeShot(id, $index)">Remove</a>
                            </div>
                        </div>
                    </div>

                        <shot-form :instance="formData.shots[$index]" ref="shotForms" @update="onShotUpdate($index, $event)" />
                </div>
                <p class="mb-2">
                    <a href class="btn btn-link" @click.prevent="addShot()"><i class="far fa-plus mr-hf"></i>Add another</a>
                </p>
            </div>

            <div v-show="step == 'summary'">
                <p>Please review the information below and ensure that it is correct. </p>

                <h5>Current Status</h5>
                <h2>{{ statusPretty }}</h2>

                <div v-if="formData.vaccination_status == 'fully-vaccinated' || formData.vaccination_status == 'partially-vaccinated'">
                    <table class="table mb-2">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Location</th>
                                <th>Type</th>
                            </tr>
                        </thead>

                        <tr v-for="shot in formData.shots">
                            <td>{{ $filters.date(shot.administered_on) }}</td>
                            <td>{{ shot.location }}</td>
                            <td>{{ shot.vaccine_name == 'Other' ? shot.vaccine_name_other : shot.vaccine_name }}</td>
                        </tr>
                    </table>

                </div>
            </div>
        </form>
    </slot>

    <template v-slot:footer>
        <form-errors :errors="formErrors"/>

        <button-row>
            <button type="button" class="btn btn-outline-primary" @click.prevent="onBack">{{ backButtonText }}</button>
            <button type="button" class="btn btn-primary" @click.prevent="onSubmit">{{ buttonText }}</button>
        </button-row>
    </template>
</modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'
import ShotForm from './ShotForm'

export default {
    props: ['client', 'company', 'employee', ],
    mixins: [ModalMixin, FormMixin],
    components: {ShotForm, },
    computed: {
        lostCardOptions() {
            return [
                {
                    text: 'Employee has vaccination card',
                    value: 'no',
                },
                {
                    text: "Employee does NOT have vaccination card",
                    value: 'yes',
                    helper_text: 'Employee attests that COVID Vaccination Card has been lost or stolen and is unable to get a replacement card from vaccination provider. SIGNED ATTESTATION DOCUMENT IS REQUIRED.'
                },
            ]
        },
        buttonText() {
            if (this.step == 'intro') {return 'Begin'}
            if (this.step == 'summary') {return 'Submit'}
            return 'Next'
        },
        backButtonText() {
            if (this.step == 'intro') {return 'Cancel'}
            return 'Back'
        },
        statusOptions() {
            return [
                {
                    text: 'Fully vaccinated',
                    value: 'fully-vaccinated',
                    helper_text: "At least EITHER 2 shots of the Moderna or Pfizer vaccines OR 1 shot of the Johnson & Johnson vaccine, administered 14 days or more before today's date."
                },
                {
                    text: 'Partially vaccinated',
                    value: 'partially-vaccinated',
                    helper_text: "Only 1 shot of the Moderna or Pfizer vaccines OR second shot of these vaccines or the 1 shot of the Johnson & Johnson vaccine was administered less than 14 days before today's date."
                },
                {
                    text: 'Not vaccinated',
                    value: 'not-vaccinated',
                },
                {
                    text: 'Not vaccinated and claiming an accommodation for medical or religious reasons',
                    value: 'not-vaccinated-exemption',
                },
            ]
        },
        statusPretty() {
            const STATUSES = {
                'fully-vaccinated': 'Fully Vaccinated',
                'partially-vaccinated': 'Partially Vaccinated',
                'not-vaccinated': 'Not Vaccinated',
                'not-vaccinated-exemption': 'Not Vaccinated-Exempt',
                '': 'Not Yet Reported',
            }

            return STATUSES[this.formData.vaccination_status || '']
        },
        previewURL() {
            return this.formData.vaccination_card ? `data:${this.formData.vaccination_card.file_type};base64,${this.formData.vaccination_card.data}` : ''
        },
    },
    data() {
        const data = {
            step: 'intro',  // May be intro, status, details, summary
            formLabels: {
            },
            formRules: {
            },
            formData: this.makeFormData(),
        }

        if (this.vaccination_status) {
            //for (let i = 0; i < this.employee.jobs.length; i++) {
            //    data.shotForms[i] = i
            //}
        }
        else {
            data.shotForms = {1: 1}
        }

        return data
    },
    methods: {
        makeFormData() {
            return {
                status: '',
                shots: [],
                vaccination_card: null,
                lost_card: '',
            }
        },
        validate() {
            let result = FormMixin.methods.validate.call(this)

            // no attestation validation, since either way they need to upload *something*
            // (vaccine card or attestation document)

            return result
        },
        onSubmit() {
            this.formErrors = []
            this.errors = {}

            if (this.step == 'intro') {
                return this.step = 'status'
            }
            else if (this.step == 'status') {
                if (!this.formData.vaccination_status) {
                    return this.formErrors = ['Please select vaccination status.']
                }

                if (this.formData.vaccination_status == 'fully-vaccinated' || this.formData.vaccination_status == 'partially-vaccinated') {
                    return this.step = 'details'
                }
                else {
                    this.shotForms = {}
                    this.$nextTick(() => {
                        // This will clear out formData correctly rather than doing this in the same tick
                        this.shotForms = {1: 1}
                        this.formData.shots = []
                    })

                    return this.step = 'summary'
                }
            }
            else if (this.step == 'details') {
                let errorCount = 0;

                if (!this.formData.lost_card) {
                    this.errors.lost_card = ['Please indicate if you have your vaccination card.']
                }
                else if (this.formData.lost_card == 'no') {
                    if (!this.formData.vaccination_card) {
                        this.formErrors.push('Please upload vaccination card.')
                        errorCount += 1
                    }
                }
                else if (this.formData.lost_card == 'yes') {
                    if (!this.formData.vaccination_card) {
                        this.formErrors.push('Please upload signed attestation document.')
                        errorCount += 1
                    }
                }

                (this.$refs.shotForms || []).forEach((form) => {
                    if (!form.validate()) {
                        errorCount += 1
                    }
                })

                if (errorCount) {
                    return
                }

                this.step = 'summary'
            }
            else if (this.step == 'summary') {
                if (!this.validate()) {return}

                this.$store.dispatch('START_LOADING')
                this.$api.post(`/clients/${this.client.id}/employees/${this.employee.id}/covid-vaccination-statuses/record`, {data: this.getFormData()}).then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('updated', {})
                    this.$emit('close')
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })
            }
        },
        getFormData() {
            const data = JSON.parse(JSON.stringify(this.formData))

            data.shots.forEach(s => {
                if (s.vaccine_name == 'Other') {
                    s.vaccine_name = s.vaccine_name_other
                    delete s.vaccine_name_other
                }
            })

            return data
        },
        onBack() {
            this.formErrors = []
            this.errors = {}

            if (this.step == 'intro') {return this.close()}
            if (this.step == 'status') {return this.step = 'intro'}
            if (this.step == 'details') {return this.step = 'status'}
            if (this.step == 'summary') {
                if (this.formData.vaccination_status == 'fully-vaccinated' || this.formData.vaccination_status == 'partially-vaccinated') {
                    return this.step = 'details'
                }
                else {
                    return this.step = 'status'
                }
            }
        },
        addShot() {
            const id = Math.max(1, ...Object.keys(this.shotForms)) + 1
            this.shotForms[id] = Object.keys(this.shotForms.length - 1)
            this.$emit('scrollbottom')
        },
        removeShot(id, idx) {
            const shots = []
            this.formData.shots = [];
            (this.$refs.shotForms || []).forEach((form, i) => {
                if (i != idx) {
                    shots.push(form.getFormData())
                }
            })

            this.formData.shots = shots
            delete this.shotForms[id]
        },
        onShotUpdate(idx, data) {
            this.formData.shots[idx] = data
        },
        onUpload(evt) {
            this.formErrors = []
            const files = evt.target.files || evt.dataTransfer.files
            if (!files.length) {
                return
            }

            const reader = new FileReader()
            reader.onload = () => {
                const photo = {
                    filename: files[0].name,
                    size: files[0].size,
                    file_type: files[0].type,
                    data: btoa(reader.result),
                }

                this.$store.dispatch('START_LOADING')
                this.$api.post(`/image-converter`, {data: photo.data}).then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    photo.data = resp.data  // Base64 encoded
                    photo.file_type = resp.mime
                    this.formData.vaccination_card = photo

                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    Object.keys(errors).forEach(field => {
                        if (field.substring(0, 2) == '__' && field != '__all__') {return}
                        this.formErrors = this.formErrors.concat(errors[field])
                    })

                    this.$bus.showError(this.formErrors)
                })
            }
            reader.readAsBinaryString(files[0])
        },
        onLostCard() {
            this.$nextTick(() => {
                this.formData.i_certify = false
                this.formData.i_attest = false

                if (this.formData.lost_card == 'yes') {
                    this.formData.vaccination_card = null
                }
            })
        },
    },
}
</script>

<style scoped>
.form-input-checkbox .field-helper-text {
    margin-top: 5px;
}
</style>
