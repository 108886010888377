export default [
    {
        "name": "Mother",
    },
    {
        "name": "Father",
    },
    {
        "name": "Brother",
    },
    {
        "name": "Sister",
    },
    {
        "name": "Spouse",
    },
    {
        "name": "Ex-Spouse",
    },
    {
        "name": "Domestic Partner",
    },
    {
        "name": "Friend",
    },
    {
        "name": "Grandparent",
    },
    {
        "name": "Child",
    },
    {
        "name": "Step Child",
    },
    {
        "name": "Other",
    },
]
