<template>
    <modal ref="modal" size="lg">
        <template v-slot:title>Update Employee Info as Admin</template>
        <slot>
            <div v-if="repeatStep == 'wotc'" class="modal-page-container">
                <onboarding-wotc-questions :onboarding-application="employee" @updated="onUpdate"/>
            </div>

            <div v-if="repeatStep == 'hr-info'" class="modal-page-container">
                <onboarding-hr-info :onboarding-application="employee" @updated="onUpdate"/>
            </div>

            <div v-if="repeatStep == 'address'" class="modal-page-container">
                <onboarding-address :onboarding-application="employee" @updated="onUpdate"/>
            </div>

            <div v-if="repeatStep == 'eeo-info'" class="modal-page-container">
                <onboarding-eeo-info :onboarding-application="employee" @updated="onUpdate"/>
            </div>

            <div v-if="repeatStep == 'forms'" class="modal-page-container">

                <onboarding-form-selection ref="fromSelectionForm" v-if="formsStep == 'selection'" :onboarding-application="employee" @updated="onUpdate"/>

                <onboarding-forms v-if="formsStep == 'forms'" :onboarding-application="employee" @updated="onUpdate"/>

            </div>

            <div v-if="repeatStep == 'direct-deposit'" class="modal-page-container">
                <onboarding-direct-deposit :onboarding-application="employee" @updated="onUpdate" paymasters_onboarding_application_updated="onUpdate" />
            </div>

        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import OnboardingWotcQuestions from '@/pages/ess/onboarding/OnboardingWOTCQuestions'
import OnboardingAddress from '@/pages/ess/onboarding/OnboardingAddress'
import OnboardingEmergencyContact from '@/pages/ess/onboarding/OnboardingEmergencyContact'
import OnboardingHRInfo from '@/pages/ess/onboarding/OnboardingHRInfo'
import OnboardingEEOInfo from '@/pages/ess/onboarding/OnboardingEEOInfo'
import OnboardingForms from '@/pages/ess/onboarding/OnboardingForms'
import OnboardingFormSelection from '@/pages/ess/onboarding/OnboardingFormSelection'
import OnboardingDirectDeposit from '@/pages/ess/onboarding/OnboardingDirectDeposit'
import api from '@/api'
import bus from '@/bus'

export default {
    props: ['client', 'employee', 'repeatStep'],
    mixins: [ModalMixin],
    components: {
        OnboardingWotcQuestions,
        OnboardingAddress,
        OnboardingEmergencyContact,
        OnboardingHrInfo: OnboardingHRInfo,
        OnboardingEeoInfo: OnboardingEEOInfo,
        OnboardingForms,
        OnboardingFormSelection,
        OnboardingDirectDeposit,
    },
    data() {
        return {
            formsStep: 'selection',
        }
    },
    methods: {
        onUpdate(app) {
            if (app) {
                this.$emit('updated', app)
            }
            else {
                this.$store.dispatch('START_LOADING')
                api.get(`/me/onboarding/${this.employee.id}/onboarding-application`).then((resp) => {
                    this.$emit('updated', resp)
                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    bus.showError(errors.__all__)
                    this.$store.dispatch('STOP_LOADING')
                })
            }

            if (this.repeatStep == 'forms') {
                if (this.formsStep == 'selection') {
                    this.formsStep = 'forms'
                    this.$refs.fromSelectionForm.reset()
                }
                else {
                    if (app) {
                        this.close()
                    }
                }
            }
            else if (this.repeatStep == 'direct-deposit') {
                if (app) {
                    this.close()
                }
            }
            else {
                this.close()
            }
        },
        reset(evt) {
            this.formsStep = 'selection'
            if (this.$refs.fromSelectionForm) {
                this.$refs.fromSelectionForm.reset()
            }
        },
        open() {
            this.reset()
            ModalMixin.methods.open.call(this)
        }
    },
}

</script>
