<template>
<div>
<sidebar-widget
    @add="modalOpen = true"
    @delete="onDelete($event)"
    @edit="currentRowId = $event; modalOpen = true"
    @versions="currentRowId = $event; versionsModalIsOpen = true"
    :rows="rows"
    :headers="['Type']"
>
    <template v-slot:title>Involuntary Withholding Laws</template>

    <template v-slot:row-details scope="props">
        <involuntary-withholding-law-version
            :version="props.row.current_version"
            :involuntary-withholding-type="props.row.involuntary_withholding_type"
            :filing-statuses="filingStatuses"
        />
    </template>
</sidebar-widget>

<involuntary-withholding-law-modal
    v-if="modalOpen"
    :jurisdiction="jurisdiction"
    :earning-types="earningTypes"
    :deduction-types="deductionTypes"
    :filing-statuses="filingStatuses"
    :involuntary-withholding-types="involuntaryWithholdingTypes"
    :involuntary-withholding-law="currentRow"
    @updated="$emit('updated')"
    @close="modalOpen = false; currentRowId = null"
/>

<versions-modal
    v-if="versionsModalIsOpen"
    :url="getURL(currentRowId)"
    @close="currentRowId = null; versionsModalIsOpen = false"
>
    <template v-slot:version scope="props">
        <involuntary-withholding-law-version
            :version="props.version"
            :involuntary-withholding-type="currentRow.involuntary_withholding_type"
            :filing-statuses="filingStatuses"
        />
    </template>
</versions-modal>


</div>
</template>

<script>
import InvoluntaryWithholdingLawModal from './InvoluntaryWithholdingLawModal'
import SidebarWidget from './SidebarWidget'
import InvoluntaryWithholdingLawVersion from './InvoluntaryWithholdingLawVersion'
import VersionsModal from './VersionsModal'

export default {
    props: ['jurisdiction', 'involuntaryWithholdingTypes', 'deductionTypes', 'earningTypes', 'filingStatuses'],
    components: {InvoluntaryWithholdingLawModal, SidebarWidget, InvoluntaryWithholdingLawVersion, VersionsModal, },
    computed: {
        currentRow() {
            return this.allLaws.find(x => x.id == this.currentRowId)
        },
        rows() {
            const result = []
            this.allLaws.forEach(row => {
                row = JSON.parse(JSON.stringify(row))
                row.cells = [
                    row.involuntary_withholding_type.name
                ]
                result.push(row)
            })
            return result
        },
        allLaws() {
            return this.jurisdiction.involuntary_withholding_laws.sort((a, b) => a.involuntary_withholding_type.name > b.involuntary_withholding_type.name ? 1 : -1)
        },
    },
    data() {
        return {
            modalOpen: false,
            versionsModalIsOpen: false,
            currentRowId: null,
        }
    },
    methods: {
        getURL(rowId) {
            if (!rowId) {return}
            return `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/involuntary-withholding-laws/${rowId}`
        },
        onDelete(rowId) {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(this.getURL(rowId)).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>
