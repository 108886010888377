<template>
    <div class="field-helper-text">
        <span class="text-danger">
            <i aria-hidden="true" title="Error" class="fas fa-fw fa-exclamation-circle"></i>
            <span class="sr-only">Error:</span>
            <span>
                Verify that this matches the employee's I-9
            </span>
        </span>
    </div>
</template>

<script>

export default {
    
}
</script>
