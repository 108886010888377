<template>
    <div class="info-widget card mb-2">
        <div class="card-header pr-1 d-flex align-items-center" v-if="!collapsible">
            <span class="btnlike mr-auto">
                <h4 class="mb-0">
                    <slot name="title"></slot>
                </h4>
            </span>
            <slot name="headerbutton"></slot>
        </div>
        <div class="card-header p-0" v-if="collapsible">
            <a href class="card-header-inner collapse-trigger" role="button" :class="{'is-expanded': isExpanded}" @click.prevent="$emit('toggle_expand')">
                <span class="widget-toggle-wrapper">
                    <collapse-toggle-button />
                </span>
                <span class="btnlike mr-auto">
                    <h4 class="text-dark mb-0">
                        <slot name="title"></slot>
                    </h4>
                </span>
                <span class="btnlike ml-auto mr-1">
                    <slot name="title-detail"></slot>
                </span>
                <slot name="headerbutton"></slot>
            </a>
        </div>
        <div class="card-body p-0" v-show="!collapsible || isExpanded">
            <slot></slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
  .info-widget {
    background-color: #fff;
    .card-header {
        background-color: #F0F0F0;
    }
  }
</style>

<script>
export default {
    props: {
        collapsible: {
            type: Boolean,
            default: false,
        },
        isExpanded: {
            default: false,
        },
    },
}
</script>
