<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ $filters.date(version.starts_on, "MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ $filters.date(version.ends_on, "MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <table class="mt-1 ml-0 table">
        <thead>
            <tr>
                <th class="pl-0">Start</th>
                <th>Amount</th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="line in version.table_lines">
                <td class="pl-0">${{ $filters.formatCurrency(line.start) }}</td>
                <td>${{ $filters.formatCurrency(line.amount) }}</td>
            </tr>
        </tbody>
    </table>
</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', ],
    data() {
        return {
            isExpanded: false,
        }
    },
    filters: {
        formatPercentage: (p) => {
            p = '' + p
            p = p.replace(/[0]*$/, '')
            p = p.replace(/[.]*$/, '')  // Make "0." become "0"
            return p
        },
    },
}
</script>
