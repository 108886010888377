<!-- 
    The "X/check" indicator next to each permission row, 
    showing whether this role allows that action.
-->
<template>
    <span>
        <span v-if="this.allowed">
            <i class="fas fa-fw fa-check mr-hf text-primary" aria-hidden="true"></i>
            <span class="sr-only">
                (Yes)
            </span>
        </span>
        <span v-else>
            <i class="fas fa-fw fa-times mr-hf text-muted" aria-hidden="true"></i>
            <span class="sr-only">
                (No)
            </span>
        </span>
    </span>
</template>

<script>
export default {
    props: {
        allowed: {
            type: Boolean,
            default: false,
        }
    }
}
</script>