<template>
    <div class="card card-body bg-150 m-0 py-1 item-details" :data-id="changelog.id">
        <div class="text-small text-muted">

            <!-- Header with when/who changed/summary of the change -->
            <div class="item-details-section">
                <div class="form-row mb-1">
                    <div class="col">
                        <span>{{ $filters.datetime(changelog.created_on) }}</span>
                    </div>
                    <div class="col text-right">
                        <span>{{ changelog.created_from_ip }}</span>
                    </div>
                </div>

                <p v-if="changelog.created_by_type == 'USER'">
                    <span v-if="changelog.created_by_security_profile_id">By: {{ changelog.created_by_security_profile.full_name }} ({{ changelog.created_by_security_profile.email }})</span>
                    <span v-if="!changelog.created_by_security_profile_id && changelog.created_by_id">By: {{ changelog.created_by.email }}</span>
                </p>
                <p v-if="changelog.created_by_type == 'SYSTEM'">By: SYSTEM</p>
                <p v-if="changelog.created_by_type == 'PARTNER-API'">By: PARTNER API</p>

                <p v-if="aTrackedObject.is_root"><strong>{{ changelog.change_target }} was {{ changelog.action_pretty.toLowerCase() }}.</strong></p>
                <p v-else-if="aTrackedObject.slug == 'status'"><strong>{{ changelog.change_target }} was {{ changelog.action_pretty.toLowerCase() }}.</strong></p>
                <p v-else><strong>{{ changelog.tracked_object_type }} <span v-if="changelog.tracked_object_name">"{{ changelog.tracked_object_name }}"</span> {{ aTrackedObject.options.is_plural ? 'were' : 'was' }} {{ changelog.action_pretty.toLowerCase() }}.</strong></p>
            </div>

            <tracked-object
                :old-tracked-object="changelog.old_tracked_object"
                :new-tracked-object="changelog.new_tracked_object"
                :action="changelog.action"
                @preview_photos="photoPreviewValues = $event"
                @open_pdf="onOpenPDF($event)"
            />

        </div>

        <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="setCurrentPDF(null)" ref="pdfModal" />

        <modal
            v-if="photoPreviewValues"
            @close="photoPreviewValues = null"
            :fade="false"
            :v-center="true"
            :autoshow="true"
            :is-closeable="true"
        >
            <template v-slot:title>{{ photoPreviewValues.document_title }}</template>

            <slot>
                <div class="card card-body p-1 mb-1">
                    <div class="d-flex mb-1">
                        <span class="flex-grow-1 text-bold btnlike">Front:</span>
                        <span class="flex-grow-0 text-right position-relative">
                            <button class="btn btn-outline-primary btn-collapse-xl" :disabled="zoom >= 400" @click.prevent="zoom += 50">
                                <i class="far fa-fw fa-search-plus"></i>
                                <span class="sr-only">Zoom In</span>
                            </button>
                            <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="zoom <= 100" @click.prevent="zoom -= 50">
                                <i class="far fa-fw fa-search-minus"></i>
                                <span class="sr-only">Zoom Out</span>
                            </button>
                        </span>
                    </div>
                    <div>
                        <div class="docphoto" :class="{'zoom-active': zoom > 100}">
                            <img :src="photoPreviewValues.front" alt="Document Photo" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                        </div>
                    </div>
                </div>

                <div class="card card-body p-1 mb-1" v-if="photoPreviewValues.back">
                    <div class="d-flex mb-1">
                        <span class="flex-grow-1 text-bold btnlike">Back:</span>
                        <span class="flex-grow-0 text-right position-relative">
                            <button class="btn btn-outline-primary btn-collapse-xl" :disabled="zoom >= 400" @click.prevent="zoom += 50">
                                <i class="far fa-fw fa-search-plus"></i>
                                <span class="sr-only">Zoom In</span>
                            </button>
                            <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="zoom <= 100" @click.prevent="zoom -= 50">
                                <i class="far fa-fw fa-search-minus"></i>
                                <span class="sr-only">Zoom Out</span>
                            </button>
                        </span>
                    </div>
                    <div>
                        <div class="docphoto" :class="{'zoom-active': zoom > 100}">
                            <img :src="photoPreviewValues.back" alt="Document Photo" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                        </div>
                    </div>
                </div>
            </slot>
        </modal>
    </div>
</template>


<script>
import Modal from '@/components/Modal'
import ViewDocumentModal from '@/components/EmployeeSidebar/ViewDocumentModal'
import PdfModal from '@/components/PdfModal'
import TrackedObject from '@/components/TrackedObject'
import validate from 'validate.js'
import moment from 'moment'

export default {
    props: ['changelog', 'client', ],
    components: {ViewDocumentModal, PdfModal, Modal, TrackedObject, },
    computed: {
        aTrackedObject() {
            // Return just one of the tracked objects (old or new) to allow access to the TO metadata
            return (this.changelog.old_tracked_object && this.changelog.old_tracked_object.data) ? this.changelog.old_tracked_object : this.changelog.new_tracked_object
        },
    },
    data() {
        return {
            currentRestrictedDoc: null,
            currentPDF: null,
            photoPreviewValues: null,
            zoom: 100,
        }
    },
    methods: {
        setCurrentRestrictedDoc(doc) {
            this.currentRestrictedDoc = doc
        },
        onOpenPDF(evt) {
            let url = `${this.$api.API_BASE}/clients/${this.client.id}/history-logs/${this.changelog.id}/pdf?to=${evt.to}&field=${evt.field}`
            this.setCurrentPDF({
                'url': url,
                'title': evt.filename
            })
        },
        setCurrentPDF(doc) {
            if (!doc) {
                this.currentPDF = null
                return
            }

            this.currentRestrictedDoc = null
            this.currentPDF = {
                title: doc.title,
                url: {
                    url: doc.url,
                    withCredentials: true,
                },
            }
        },
        isURL(val) {
            let url;

            try {
                url = new URL(val);
            } catch (_) {
                return false;
            }

            return url.protocol === "http:" || url.protocol === "https:";
        },
        formatIfDate(val) {
            if ((typeof val == 'string') && (moment(val, 'YYYY-MM-DD').isValid())) {
                return moment(val, 'YYYY-MM-DD').format('M/D/YYYY')
            }

            else if ((typeof val == 'string') && (moment(val, 'YYYY-MM-DDTHH:mm:ss.SSSZ').isValid())) {
                return moment(val, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('M/D/YYYY')
            }

            return val
        },
        formatValue(val) {
            let newVal = this.formatIfDate(val)
            if (val != newVal) {
                return newVal
            }

            return val
        },
        makeLabel(label) {
            const KNOWN_LABELS = {
                aca_category: 'ACA Category',
                user_status_pretty: 'User Account',
                date_hired: 'Hire Date',
                pdf_file: 'PDF File',
                hr_documents: 'HR Documents',
                status_pretty: 'Status',
                updated_by_pretty: 'Updated By',
                url: 'Document',
            }

            if (KNOWN_LABELS[label]) {
                return KNOWN_LABELS[label]
            }
            else {
                return validate.capitalize(validate.prettify(label))
            }
        },
        makeSubset(key, obj) {
            const item = []
            if (key == 'jobs') {
                item.push({label: 'Company', value: obj.company})
                item.push({label: 'Location', value: obj.location})
                item.push({label: 'Position', value: obj.position})
                item.push({label: 'Hourly rate', value: '$' + obj.hourly_rate})
                item.push({label: 'Default hours', value: obj.default_hours || ''})
                item.push({label: 'Pay type', value: obj.pay_type_pretty})
            }
            else {
                Object.keys(obj).forEach(k => {
                    item.push({
                        label: this.makeLabel(k),
                        value: this.formatValue(obj[k]),
                        isURL: this.isURL(obj[k]),
                    })
                })
            }

            return item
        },
    },
}
</script>

<style scoped lang="scss">
  // Import Sass globals
  @import "@/assets/scss/globals";

  //
  // Component styles
  //

  // Tiny thumbnail image of document
  .docphoto-thumb {
    background-color: gray("150");
    // background-image set inline above
    background-position: center;
    background-size: cover;
    border: solid 1px $border-color;
    border-radius: 3px;
    height: 24px;
    width: 24px;
    line-height: 23px; // 24px results in slightly off-center text
    text-align: center;
  }

  // "Add Photo" (+) button
  .docphoto-thumb-button {
    background-color: $link-color;
    border-color: $link-color;
    color: $white;

    // Hover behavior
    *:hover > &,
    *:active > &,
    *:focus > & {
      background-color: $link-hover-color;
      border-color: $link-hover-color;
      text-decoration: none;
    }
  }

  .docphoto img {
      max-height: 300px;
      max-width: auto;
  }
  .docphoto.zoom-active img {
      max-height: none;
  }
  .docphoto.zoom-active {
      max-width: 100%;
      overflow: auto;
      max-height: 300px;
  }
</style>

