<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Closing Cases</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    E-Verify will automatically close cases that receive an initial result of Employment Authorized, and applies a standard closure reason. Employers will not have to manually close the case. Therefore, when a case receives an EMPLOYMENT_AUTHORIZED status, users will now see CLOSED. Employers should rely on the case eligibility statement to determine if the employee is employment authorized.
                                </p>

                                <p>
                                    Other than Employment Authorized, employers must manually close each case and select an appropriate reason from the drop-down provided.
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page3a'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page5'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
