<template>
<div class="flex-page-content flex-container">
    <div class="flex-body body-scroll">
        <div class="container-fluid">

            <div class="container-centered py-4" v-if="dataLoaded">

                <h1 class="mb-4 text-center">Paycheck Calculator</h1>

                <form @submit.prevent="onSubmit" novalidate class="mui-form">

                    <div class="form-row">
                        <div class="col-6">
                            <form-input label="Pay Frequency" v-model="formData.pay_frequency" :errors="errors.pay_frequency" type="select" :options="payFrequencyOptions" />
                        </div>
                        <div class="col-3">
                            <form-input label="Gross YTD" v-model="formData.gross_ytd" :errors="errors.gross_ytd" type="currency" />
                        </div>
                    </div>

                    <hr />

                    <div class="form-row">
                        <div class="col-6">
                            <h4 class="mb-4">Resident Address</h4>
                            <form-input label="State" v-model="formData.state" :errors="errors.state" type="select" :options="stateOptions" />

                            <form-input label="City" v-model="formData.city" :errors="errors.city" type="select" :options="residentCityOptions" />
                            <form-input label="County" v-model="formData.county" :errors="errors.county" type="select" :options="residentCountyOptions" />
                            <form-input label="School District" v-model="formData.school_district" :errors="errors.school_district" type="select" :options="residentSchoolDistrictOptions" />
                        </div>
                        <div class="col-6">
                            <h4 class="mb-4">Work Address</h4>
                            <form-input label="State" v-model="formData.work_state" :errors="errors.work_state" type="select" :options="stateOptions" />
                            <form-input label="City" v-model="formData.work_city" :errors="errors.work_city" type="select" :options="workCityOptions" />
                            <form-input label="County" v-model="formData.work_county" :errors="errors.county" type="select" :options="workCountyOptions" />
                            <form-input label="School District" v-model="formData.work_school_district" :errors="errors.school_district" type="select" :options="workSchoolDistrictOptions" />
                        </div>
                    </div>

                    <hr />

                    <h4 class="mb-4">Earnings</h4>

                    <div class="form-row mb-2 border p-1 calc-item" v-for="earning, $idx in formData.earnings">
                        <a href class="remove" @click.prevent="removeEarning($idx)">X</a>
                        <div class="col-6">
                            <form-input label="Earning Type" v-model="formData.earnings[$idx].id" type="select" :options="earningTypeOptions" />

                            <form-input
                                v-if="formData.earnings[$idx].id && earningTypes[formData.earnings[$idx].id].current_version.type == 'hourly'"
                                label="Overtime"
                                v-model="formData.earnings[$idx].is_overtime"
                                type="checkbox"
                            />
                        </div>

                        <div class="col-2" v-if="formData.earnings[$idx].id">
                            <form-input
                                v-if="earningTypes[formData.earnings[$idx].id].current_version.type == 'hourly' || earningTypes[formData.earnings[$idx].id].current_version.type == 'per-unit'"
                                label="Rate"
                                v-model="formData.earnings[$idx].rate"
                                type="currency"
                            />
                        </div>

                        <div class="col-2" v-if="formData.earnings[$idx].id">
                            <form-input
                                v-if="earningTypes[formData.earnings[$idx].id].current_version.has_tip_credit_claimed"
                                label="Tip Credit"
                                v-model="formData.earnings[$idx].tip_credit_claimed"
                                type="currency"
                            />
                        </div>

                        <div class="col-2" v-if="formData.earnings[$idx].id">
                            <form-input
                                v-if="earningTypes[formData.earnings[$idx].id].current_version.type == 'hourly'"
                                label="Hours"
                                v-model="formData.earnings[$idx].hours"
                                type="decimal"
                            />
                            <form-input
                                v-if="earningTypes[formData.earnings[$idx].id].current_version.type == 'per-unit'"
                                label="Units"
                                v-model="formData.earnings[$idx].units"
                                type="integer"
                            />
                            <form-input
                                v-if="earningTypes[formData.earnings[$idx].id].current_version.type == 'amount'"
                                label="Amount"
                                v-model="formData.earnings[$idx].amount"
                                type="currency"
                            />
                        </div>

                        <div class="col-8" v-if="formData.earnings[$idx].id"></div>

                        <div class="col-2" v-if="formData.earnings[$idx].id">
                            <form-input
                                label="MTD Amount"
                                v-model="formData.earnings[$idx].mtd_amount"
                                type="currency"
                            />
                        </div>
                        <div class="col-2" v-if="formData.earnings[$idx].id">
                            <form-input
                                label="YTD Amount"
                                v-model="formData.earnings[$idx].ytd_amount"
                                type="currency"
                            />
                        </div>
                    </div>

                    <p>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="addEarning">+</button>
                    </p>

                    <hr />
                    <h4 class="mb-4">Deductions</h4>

                    <div class="form-row mb-2 border p-1 calc-item" v-for="deduction, $idx in formData.deductions">
                        <a href class="remove" @click.prevent="removeDeduction($idx)">X</a>

                        <div class="col-6">
                            <form-input label="Deduction Type" v-model="formData.deductions[$idx].id" :errors="errors.deductions[$idx]" type="select" :options="deductionTypeOptions" />
                        </div>

                        <div class="col-2" v-if="formData.deductions[$idx].id">
                            <form-input
                                label="Amount"
                                v-model="formData.deductions[$idx].amount"
                                type="currency"
                            />
                        </div>

                        <div class="col-2" v-if="formData.deductions[$idx].id">
                            <form-input
                                label="MTD Amount"
                                v-model="formData.deductions[$idx].mtd_amount"
                                type="currency"
                            />
                        </div>
                        <div class="col-2" v-if="formData.deductions[$idx].id">
                            <form-input
                                label="YTD Amount"
                                v-model="formData.deductions[$idx].ytd_amount"
                                type="currency"
                            />
                        </div>
                    </div>

                    <p>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="addDeduction">+</button>
                    </p>

                    <hr />

                    <h4 class="mb-4">Jurisdictions and Taxes</h4>

                    <template v-for="jurisdiction in applicableJurisdictions">
                    <div class="border mb-2" v-if="showJurisdictions[jurisdiction.id]">
                        <div class="form-row m-1" v-if="jurisdictionFilingStatuses[jurisdiction.id].length">
                            <div class="col-4">
                                <form-input
                                    :label="jurisdiction.canonical_name + 'Filing Status'"
                                    v-model="formData.filing_statuses[jurisdiction.id]"
                                    :errors="errors.filing_statuses[jurisdiction.id]"
                                    type="select"
                                    :options="jurisdictionFilingStatuses[jurisdiction.id]"
                                />
                            </div>

                        </div>

                        <template v-for="t in jurisdictionTaxes[jurisdiction.id]">
                        <div v-if="!['MEDICARE', 'SOCIAL SECURITY'].includes(t.name)">
                            <h5 class="m-1">{{ t.name }}</h5>
                            <div class="form-row m-1" >
                                <div class="col-4" v-if="t.type == 'unemp-ins'">
                                    <form-input
                                        label="Rate"
                                        v-model="formData.tax_rates[t.id]"
                                        :errors="errors.tax_rates[t.id]"
                                        type="decimal"
                                    />
                                </div>

                                <div class="col-4" v-if="t.type == 'unemp-ins'">
                                    <form-input
                                        label="Employer Rate"
                                        v-model="formData.emp_tax_rates[t.id]"
                                        :errors="errors.emp_tax_rates[t.id]"
                                        type="decimal"
                                    />
                                </div>

                                <template v-if="t.type == 'income' && w4inputsByTax[t.id]">
                                    <div class="col-4" v-for="inp in w4inputsByTax[t.id]">
                                        <form-input
                                            :label="inp.label"
                                            v-model="formData.w4data[t.id][inp.id]"
                                            :errors="errors.w4data[t.id][inp.id]"
                                            :type="inp.type"
                                        />
                                    </div>
                                </template>

                                <div class="col-4">
                                    <form-input
                                        label="Additional Withholding"
                                        v-model="formData.voluntary_withholdings[t.id]"
                                        :errors="errors.voluntary_withholdings[t.id]"
                                        type="integer"
                                    />
                                </div>
                            </div>
                        </div>
                        </template>
                    </div>
                    </template>

                    <hr />
                    <h4 class="mb-4">Involuntary Withholdings</h4>

                    <div class="form-row mb-2 border p-1 calc-item" v-for="deduction, $idx in formData.involuntary_withholdings">
                        <a href class="remove" @click.prevent="removeInvoluntaryWithholding($idx)">X</a>

                        <div class="col-6">
                            <form-input label="Withholding Type" v-model="formData.involuntary_withholdings[$idx].type" :errors="errors.involuntary_withholdings[$idx]" type="select" :options="involuntaryWithholdingTypeOptions" />
                        </div>

                        <div class="col-12 pl-4 pr-4" v-if="formData.involuntary_withholdings[$idx].type">
                            <child-support-input
                                v-if="formData.involuntary_withholdings[$idx].type == 'child-support'"
                                :model-value="formData.involuntary_withholdings[$idx].params"
                                @update:model-value="formData.involuntary_withholdings[$idx].params = $event"
                            />
                            <div class="form-row" v-else>
                                <div class="col-4">
                                    <form-input
                                        label="Amount"
                                        v-model="formData.involuntary_withholdings[$idx].params.max_amount"
                                        :errors="errors.involuntary_withholdings[$idx]"
                                        type="currency"
                                    />
                                </div>

                                <div class="col-4" v-if="involuntaryWithholdingTypesByType[formData.involuntary_withholdings[$idx].type].has_exempt_amount">
                                    <form-input
                                        label="Number of Dependents"
                                        v-model="formData.involuntary_withholdings[$idx].params.num_dependents"
                                        :errors="errors.involuntary_withholdings[$idx]"
                                        type="integer"
                                    />
                                </div>

                                <div class="col-4" v-if="involuntaryWithholdingTypesByType[formData.involuntary_withholdings[$idx].type].has_exempt_amount">
                                    <form-input
                                        label="Additional Exemptions"
                                        v-model="formData.involuntary_withholdings[$idx].params.num_additional"
                                        :errors="errors.involuntary_withholdings[$idx]"
                                        type="integer"
                                    />
                                </div>

                            </div>
                        </div>
                    </div>

                    <p>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="addInvoluntaryWithholding">+</button>
                    </p>


                    <hr />
                    <p class="mb-4">
                        <form-errors :errors="formErrors"/>

                        <button type="submit" class="btn btn-primary btn-block">Calculate</button>
                    </p>

                    <p class="mb-4"><a class="btn btn-block btn-outline-primary" :href="shareURL">Share URL</a></p>

                </form>
            </div>
        </div>
    </div>

    <paycheck-modal :paycheck="paycheck" v-if="paycheck" @close="closeModal"/>
</div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import PaycheckModal from './PaycheckModal'
import ChildSupportInput from './ChildSupportInput'
import debounce from 'lodash.debounce'
import states from '@/states'
import compression from '@/utils/compression'

export default {
    components: {PaycheckModal, ChildSupportInput, },
    mixins: [FormMixin],
    computed: {
        shareURL() {
            return location.href.split('#')[0] + '#' + this.compressedFormData
        },
        compressedFormData() {
            const data = JSON.parse(JSON.stringify(this.formData))
            delete data.csrfmiddlewaretoken
            return compression.compressText(JSON.stringify(data))
        },
        payFrequencyOptions() {
            return [
                {text: 'Weekly', value: '52'},
                {text: 'Bi-weekly', value: '26'},
                {text: 'Monthly', value: '12'},
                {text: 'Semi-Monthly', value: '24'},
                {text: 'Quarterly', value: '4'},
                {text: 'Annually', value: '1'},
            ]
        },
        jurisdictionFilingStatuses() {
            const result = {}

            this.applicableJurisdictions.forEach(j => {
                result[j.id] = []

                j.filing_statuses.forEach(f => {
                    result[j.id].push({text: f.name, value: f.id})
                })
            })

            return result
        },
        jurisdictionTaxes() {
            const result = {}

            this.applicableJurisdictions.forEach(j => {
                result[j.id] = []
            })

            this.applicableTaxes.forEach(t => {
                if (!result[t.jurisdiction_id]) {
                    result[t.jurisdiction_id] = []
                }
                result[t.jurisdiction_id].push(t)
            })

            return result
        },
        residentCityOptions() {
            if (!this.formData.state) {return []}
            const result = []
            const state = this.jurisdictions.find(j => (j.type == 'state') && (j.short_name == this.formData.state))

            this.jurisdictions.filter(j => (j.type == 'city') && (j.parent_id == state.id)).forEach(j => {
                result.push({text: j.name, value: j.name})
            })

            result.sort((a, b) => {
                return (a.text > b.text) ? 1 : -1
            })
            return result
        },
        residentCountyOptions() {
            if (!this.formData.state) {return []}
            const result = []
            const state = this.jurisdictions.find(j => (j.type == 'state') && (j.short_name == this.formData.state))

            this.jurisdictions.filter(j => (j.type == 'county') && (j.parent_id == state.id)).forEach(j => {
                result.push({text: j.name, value: j.name})
            })

            result.sort((a, b) => {
                return (a.text > b.text) ? 1 : -1
            })
            return result
        },
        residentSchoolDistrictOptions() {
            if (!this.formData.state) {return []}
            const result = []
            const state = this.jurisdictions.find(j => (j.type == 'state') && (j.short_name == this.formData.state))

            this.jurisdictions.filter(j => (j.type == 'school-district') && (j.parent_id == state.id)).forEach(j => {
                result.push({text: j.name, value: j.name})
            })

            result.sort((a, b) => {
                return (a.text > b.text) ? 1 : -1
            })
            return result
        },
        workCityOptions() {
            if (!this.formData.work_state) {return []}
            const result = []
            const state = this.jurisdictions.find(j => (j.type == 'state') && (j.short_name == this.formData.work_state))

            this.jurisdictions.filter(j => (j.type == 'city') && (j.parent_id == state.id)).forEach(j => {
                result.push({text: j.name, value: j.name})
            })

            result.sort((a, b) => {
                return (a.text > b.text) ? 1 : -1
            })
            return result
        },
        workCountyOptions() {
            if (!this.formData.work_state) {return []}
            const result = []
            const state = this.jurisdictions.find(j => (j.type == 'state') && (j.short_name == this.formData.work_state))

            this.jurisdictions.filter(j => (j.type == 'county') && (j.parent_id == state.id)).forEach(j => {
                result.push({text: j.name, value: j.name})
            })

            result.sort((a, b) => {
                return (a.text > b.text) ? 1 : -1
            })
            return result
        },
        workSchoolDistrictOptions() {
            if (!this.formData.work_state) {return []}
            const result = []
            const state = this.jurisdictions.find(j => (j.type == 'state') && (j.short_name == this.formData.work_state))

            this.jurisdictions.filter(j => (j.type == 'school-district') && (j.parent_id == state.id)).forEach(j => {
                result.push({text: j.name, value: j.name})
            })

            result.sort((a, b) => {
                return (a.text > b.text) ? 1 : -1
            })
            return result
        },
        stateOptions() {
            const enabledStates = []
            enabledStates.push('AZ')
            enabledStates.push('MI')
            enabledStates.push('MN')
            enabledStates.push('MS')
            enabledStates.push('IL')

            enabledStates.push('LA')
            enabledStates.push('DC')
            enabledStates.push('GA')
            enabledStates.push('OK')
            enabledStates.push('ND')

            enabledStates.push('NM')
            enabledStates.push('NC')
            enabledStates.push('NE')
            enabledStates.push('VA')
            enabledStates.push('ID')

            enabledStates.push('IA')
            enabledStates.push('ME')
            enabledStates.push('MT')
            enabledStates.push('DE')
            enabledStates.push('MO')

            enabledStates.push('NJ')
            enabledStates.push('CA')
            enabledStates.push('CT')
            enabledStates.push('NY')
            enabledStates.push('PA')

            enabledStates.push('IN')
            enabledStates.push('AR')
            enabledStates.push('WI')
            enabledStates.push('AL')
            enabledStates.push('KS')

            enabledStates.push('KY')
            enabledStates.push('CO')
            enabledStates.push('RI')
            enabledStates.push('SC')

            enabledStates.push('UT')
            enabledStates.push('VT')
            enabledStates.push('WV')
            enabledStates.push('HI')
            enabledStates.push('OH')

            enabledStates.push('MA')
            enabledStates.push('MD')

            // Next
            // Needs city tax resolution similar to CO
            //enabledStates.push('OR')

            // These have no income tax
            enabledStates.push('AK')
            enabledStates.push('FL')
            enabledStates.push('NH')
            enabledStates.push('NV')
            enabledStates.push('SD')

            enabledStates.push('TN')
            enabledStates.push('TX')
            enabledStates.push('WA')
            enabledStates.push('WY')

            const result = []
            states.forEach((s) => {
                if (enabledStates.includes(s.abbreviation)) { // XXX
                    result.push({text: s.abbreviation, value: s.abbreviation})
                }
            })
            return result
        },
        earningTypeOptions() {
            const result = []
            Object.values(this.earningTypes).forEach(e => {
                result.push({text: e.name, value: e.id})
            })
            result.sort((a, b) => {
                if (a.text == 'REGULAR') {return -1}
                else if (b.text == 'REGULAR') {return 1}
                else if (a.text == 'REPORTED TIPS') {return -1}
                else if (b.text == 'REPORTED TIPS') {return 1}
                else if (a.text == 'DELIVERY RUN') {return -1}
                else if (b.text == 'DELIVERY RUN') {return 1}
                return a.text > b.text ? 1 : -1
            })
            return result
        },
        deductionTypeOptions() {
            const result = []
            Object.values(this.deductionTypes).forEach(e => {
                result.push({text: e.name, value: e.id})
            })
            result.sort((a, b) => {
                return a.text > b.text ? 1 : -1
            })
            return result
        },
        involuntaryWithholdingTypeOptions() {
            const result = []
            this.involuntaryWithholdingTypes.forEach(x => {
                result.push({text: x.name, value: x.type})
            })
            result.sort((a, b) => {
                return a.text > b.text ? 1 : -1
            })
            return result
        },
        involuntaryWithholdingTypesByType() {
            const result = {}
            this.involuntaryWithholdingTypes.forEach(x => {
                result[x.type] = x
            })

            return result
        },

        w4inputsByTax() {
            const result = {}
            this.applicableTaxes.forEach(t => {
                if (t.type != 'income') {return}

                const filingStatusId = this.formData.filing_statuses[t.jurisdiction_id] ? parseInt(this.formData.filing_statuses[t.jurisdiction_id]) : null;

                const stdDed = t.standard_deductions.find(x => x.filing_status_id == filingStatusId)
                if (stdDed) {
                    const stdDedFormulaId = stdDed.current_version.formula_id
                    const stdDedFormula = this.standardDeductionFormulas.find(f => f.id == stdDedFormulaId)

                    if (stdDedFormula) {
                        stdDedFormula.w4fields.forEach(field => {
                            const fieldId = field[0]
                            const fieldMeta = field[1]

                            if (!result.hasOwnProperty(t.id)) {
                                result[t.id] = {}
                            }

                            result[t.id][fieldId] = {
                                id: fieldId,
                                type: fieldMeta.type,
                                label: fieldMeta.label,
                            }
                        })
                    }
                }

                const exemption = t.exemptions.find(x => x.filing_status_id == filingStatusId)
                if (exemption) {
                    const exemptionFormulaId = exemption.current_version.formula_id
                    const exemptionFormula = this.exemptionFormulas.find(f => f.id == exemptionFormulaId)

                    if (exemptionFormula) {
                        exemptionFormula.w4fields.forEach(field => {
                            const fieldId = field[0]
                            const fieldMeta = field[1]

                            if (!result.hasOwnProperty(t.id)) {
                                result[t.id] = {}
                            }

                            result[t.id][fieldId] = {
                                id: fieldId,
                                type: fieldMeta.type,
                                label: fieldMeta.label,
                            }
                        })
                    }
                }

            })

            return result
        },
        showJurisdictions() {
            // Returns {jurisdictionId => Boolean} for when a jurisdiction should or should not be shown.
            const result = {}
            this.applicableTaxes.forEach(t => {
                const j = this.jurisdictions.find(x => x.id == t.jurisdiction_id)
                if (j.filing_statuses.length) {
                    result[j.id] = true
                    return
                }

                const w4Inputs = this.w4inputsByTax[t.id] || {}
                if (t.type == 'income' && Object.keys(w4Inputs).length) {
                    result[j.id] = true
                    return
                }

                if (t.type == 'unemp-ins') {
                    result[j.id] = true
                    return
                }

                result[j.id] = false
            })

            return result
        },
    },
    data() {
        return {
            compression: compression,

            formData: this.makeFormData(),
            errors: this.makeErrors(),
            jurisdictions: [],
            applicableJurisdictions: [],
            applicableTaxes: [],
            earningTypes: {},
            deductionTypes: {},
            involuntaryWithholdingTypes: [],
            standardDeductionFormulas: {},
            exemptionFormulas: {},

            paycheck: null,
            dataLoaded: false,
        }
    },
    watch: {
        /*
        "formData.involuntary_withholdings": {
            deep: true,
            handler(newVal, oldVal) {
                this.formData.involuntary_withholdings.forEach((x, idx) => {
                    const iw = this.formData.involuntary_withholdings[x.id]
                    if (!iw || !iw.type) {return}

                    if (iw.type == "child-support") {
                        delete this.formData.involuntary_withholdings[idx].max_amount
                    }
                    else {
                        delete this.formData.involuntary_withholdings[idx].current_child_support_amount
                        delete this.formData.involuntary_withholdings[idx].current_medical_amount
                        delete this.formData.involuntary_withholdings[idx].current_spousal_amount
                        delete this.formData.involuntary_withholdings[idx].past_child_support_amount
                        delete this.formData.involuntary_withholdings[idx].past_medical_amount
                        delete this.formData.involuntary_withholdings[idx].past_spousal_amount
                        delete this.formData.involuntary_withholdings[idx].other_amount
                    }
                })
            },
        },
        */
        "formData.earnings": {
            deep: true,
            handler(newVal, oldVal) {
                this.formData.earnings.forEach((e, idx) => {
                    const et = this.earningTypes[e.id]
                    if (!et) {return}

                    if (et.current_version.type == 'hourly') {
                        delete this.formData.earnings[idx].amount
                        delete this.formData.earnings[idx].units
                    }

                    if (et.current_version.type == 'amount') {
                        delete this.formData.earnings[idx].rate
                        delete this.formData.earnings[idx].units
                        delete this.formData.earnings[idx].hours
                        delete this.formData.earnings[idx].tip_credit_claimed
                    }

                    if (et.current_version.type == 'per-unit') {
                        delete this.formData.earnings[idx].amount
                        delete this.formData.earnings[idx].hours
                        delete this.formData.earnings[idx].tip_credit_claimed
                    }

                })
            },
        },
        'formData.city'() {
            this.getApplicableJurisdictions()
        },
        'formData.state'() {
            this.getApplicableJurisdictions()
        },
        'formData.county'() {
            this.getApplicableJurisdictions()
        },
        'formData.school_district'() {
            this.getApplicableJurisdictions()
        },
        'formData.work_city'() {
            this.getApplicableJurisdictions()
        },
        'formData.work_state'() {
            this.getApplicableJurisdictions()
        },
        'formData.work_county'() {
            this.getApplicableJurisdictions()
        },
        'formData.work_school_district'() {
            this.getApplicableJurisdictions()
        },
    },
    mounted() {
        this.getData()
    },
    methods: {
        makeFormData() {
            return {
                earnings: [{}],
                deductions: [],
                involuntary_withholdings: [],
                tax_rates: {},
                emp_tax_rates: {},
                exemption_counts: {},
                w4data: {},
                voluntary_withholdings: {},
                fed_tax_w4: {},
                //filing_statuses: {},
                filing_statuses: {'1': '6'},  // XXX

                pay_frequency: '52',  // XXX
                city: 'DETROIT',  // XXX
                state: 'MI',  // XXX
                county: '',
                school_district: '',

                work_city: 'ALBION',  // XXX
                work_state: 'MI',  // XXX
                work_county: '',
                work_school_district: '',

                gross_ytd: '0.00',
            }
        },
        makeErrors() {
            const w4data = {};

            (this.applicableTaxes || []).forEach(t => {
                w4data[t.id] = {}
            })

            return {
                earnings: [{}],
                deductions: [],
                tax_rates: {},
                emp_tax_rates: {},
                exemption_counts: {},
                fed_tax_w4: {},
                voluntary_withholdings: {},
                filing_statuses: {},
                involuntary_withholdings: [],
                w4data: w4data,
            }
        },
        _getApplicableJurisdictions() {
            const promise1 = this.$api.post('/admin/tax-engine/calculator?jurisdictions=1', this.getFormData()).then(resp => {
                this.applicableJurisdictions = resp
                this.applicableJurisdictions.sort((a, b) => {
                    return a.locality_priority > b.locality_priority ? -1 : 1
                })

                if (!this.workCityOptions.find(c => c.value == this.formData.work_city)) {
                    this.formData.work_city = null
                }

                if (!this.workCountyOptions.find(c => c.value == this.formData.work_county)) {
                    this.formData.work_county = null
                }

                if (!this.workSchoolDistrictOptions.find(c => c.value == this.formData.work_school_district)) {
                    this.formData.work_school_district = null
                }

                if (!this.residentCityOptions.find(c => c.value == this.formData.city)) {
                    this.formData.city = null
                }

                if (!this.residentCountyOptions.find(c => c.value == this.formData.county)) {
                    this.formData.county = null
                }

                if (!this.residentSchoolDistrictOptions.find(c => c.value == this.formData.school_district)) {
                    this.formData.school_district = null
                }

            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })


            const promise2 = this.$api.post('/admin/tax-engine/calculator?taxes=1', this.getFormData()).then(resp => {
                this.applicableTaxes = resp

                const w4Data = JSON.parse(JSON.stringify(this.formData.w4data))
                this.formData.w4data = {}
                this.errors.w4data = {}
                this.applicableTaxes.forEach(t => {
                    this.formData.w4data[t.id] = {}
                    if (w4Data.hasOwnProperty(t.id)) {
                        this.formData.w4data[t.id] = w4Data[t.id]
                    }

                    this.errors.w4data[t.id] = {}
                })
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })

            return Promise.all([promise1, promise2])
        },
        getApplicableJurisdictions: debounce(function() {
            this._getApplicableJurisdictions()
        }, 500),
        getData() {

            const promises = []
            promises.push(this._getApplicableJurisdictions())

            this.$store.dispatch('START_LOADING')
            promises.push(this.$api.get('/admin/tax-engine/jurisdictions').then(resp => {
                this.jurisdictions = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            }))

            promises.push(this.$api.get('/admin/tax-engine/earning-types').then(resp => {
                this.earningTypes = {}
                resp.forEach(e => {
                    this.earningTypes[e.id] = e
                })
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            }))

            promises.push(this.$api.get('/admin/tax-engine/deduction-types').then(resp => {
                this.deductionTypes = {}
                resp.forEach(d => {
                    this.deductionTypes[d.id] = d
                })
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            }))

            promises.push(this.$api.get('/admin/tax-engine/involuntary-withholding-types').then(resp => {
                this.involuntaryWithholdingTypes = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            }))

            promises.push(this.$api.get('/admin/tax-engine/standard-deduction-formulas').then(resp => {
                this.standardDeductionFormulas = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            }))

            promises.push(this.$api.get('/admin/tax-engine/exemption-formulas').then(resp => {
                this.exemptionFormulas = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            }))

            Promise.all(promises).then(() => {
                try {
                    const hash = location.hash.slice(1)
                    let hydrated = null
                    if (hash) {
                        const hydratedData = JSON.parse(compression.decompressText(hash))
                        this.formData = hydratedData
                    }
                } catch (ex) {
                    if (window.console) {
                        window.console.log(ex)
                    }
                    this.$bus.showError('Could not decode saved calculator parameters. They could be in an outdated fromat.')
                }

                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            })
        },
        addEarning() {
            this.formData.earnings.push({})
            this.errors.earnings.push({})
        },
        removeEarning(idx) {
            this.formData.earnings.splice(idx, 1)
            this.errors.earnings.splice(idx, 1)
        },
        addDeduction() {
            this.formData.deductions.push({})
            this.errors.deductions.push({})
        },
        removeDeduction(idx) {
            this.formData.deductions.splice(idx, 1)
            this.errors.deductions.splice(idx, 1)
        },
        addInvoluntaryWithholding() {
            this.formData.involuntary_withholdings.push({type: '', params: {}})
            this.errors.involuntary_withholdings.push({})
        },
        removeInvoluntaryWithholding(idx) {
            this.formData.involuntary_withholdings.splice(idx, 1)
            this.errors.involuntary_withholdings.splice(idx, 1)
        },
        onSubmit() {
            this.formErrors = []
            this.errors = this.makeErrors()
            this.$store.dispatch('START_LOADING')
            this.paycheck = null
            this.$api.post('/admin/tax-engine/calculator', this.getFormData()).then(resp => {
                this.paycheck = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.paycheck = null
                Object.keys(errors).forEach(k => {
                    this.errors[k] = errors[k]
                })
                this.formErrors = errors.__all__ || []
                if (!this.formErrors.length) {
                    this.formErrors = ['Some form fields were not filled out correctly. Please check them and try again.']
                }
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        closeModal() {
            this.paycheck = null
        },
    },
}
</script>

<style>
.calc-item {
    position: relative;
}

.calc-item .remove{
    position: absolute;
    top: 0.5em;
    right: 0.75em;
    z-index: 100;
}
</style>
