<template>
    <modal size="lg" @close="$emit('close')" ref="modal" :is-closeable="true" :autoshow="true">
        <template v-slot:title>
            <h3 class="modal-title">Complete Document for Employee<span v-if="stepAsText" class="text-thin text-nowrap">: {{ stepAsText }}</span></h3>
        </template>

        <slot>
            <div class="mb-2">
                <div class="p-1 unpad-x bg-150 rounded">
                    <div><span class="text-muted">Employee: </span>{{ employee.full_name }}</div>
                    <div><span class="text-muted">Company: </span>{{ employee.company.name }}</div>
                    <div><span class="text-muted">Document: </span>{{ docTask.name }}</div>
                </div>
            </div>

            <div v-if="step == 'custom-form-complete-method'">
            </div>

            <div v-if="step == 'doc-upload'">
                <form id="pm-employee-doc-upload-form" class="mui-form">
                    <div class="form-row pb-2">
                        <div class="col">
                            <form-input @upload="onFileUpload($event)" :errors="errors.file" label="Upload your document:" helper-text="File extension must be .pdf" type="file" accept=".pdf"/>
                        </div>
                    </div>

                    <pdf-inline
                        v-if="pdfPreviewUrl"
                        :url="pdfPreviewUrl"
                    />

                </form>
            </div>

            <div v-if="step == 'uniform-form'">
                <uniform-survey
                    @submit="onSubmitUniform(activeUniform)"
                    :uniform="activeUniform"
                    ref="activeUniformSurvey"
                    :hidesubmit="true"
                />
            </div>

            <div v-if="step == 'custom-form'">
                <custom-form-survey
                    @submit="onSubmitCustomForm(activeCustomForm)"
                    :custom-form="activeCustomForm"
                    ref="activeCustomFormSurvey"
                />
            </div>
        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <button-row>
                <button v-if="step == 'doc-upload' || step == 'uniform-form'" type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button v-else type="button" class="btn btn-outline-primary" @click.prevent="onPrevStep()">Back</button>
                <button v-if="step == 'doc-upload' || step == 'uniform-form' || step == 'custom-form'" class="btn btn-primary" @click.prevent="onSubmit()">Save</button>
                <button v-else class="btn btn-primary" @click.prevent="onNextStep()">Continue</button>
            </button-row>

        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import moment from 'moment'
import FastTable from '@/components/FastTable'
import InfoWidget from '@/components/InfoWidget'
import PdfInline from '@/components/PdfInline'
import PdfModal from '@/components/PdfModal'
import UniformSurvey from '@/components/UniformSurvey'
import CustomFormSurvey from '@/components/CustomFormSurvey'

const STEP_METADATA = {
    'doc-upload': {'title': ''},
    'uniform-form': {'title': ''},
    'custom-form': {'title': ''},
}

const COMPANY_DOC_STEPS = [
    'doc-upload',
]

const UNIFORM_STEPS = [
    'uniform-form',
]

const CUSTOM_FORM_STEPS = [
    'custom-form',
]

export default {
    components: {FastTable, InfoWidget, PdfInline, PdfModal, UniformSurvey, CustomFormSurvey},
    mixins: [ModalMixin, FormMixin, ],
    props: ['employee', 'client', 'docTask'],
    data() {
        return {
            formData: {
                name: this.docTask.name,
                file: null,
            },
            step: this.getStartingStep(),
            pdfPreviewUrl: null,
        }
    },
    mounted() {},
    computed: {
        mode() {
            return this.docTask.task_type
        },
        stepAsText() {
            if (this.step) {
                return STEP_METADATA[this.step]['title']
            } else {
                return ''
            }
        },
        activeUniform() {
            let u = {}
            if (this.docTask.uniform) {
                u = {
                    'uniform': this.docTask.uniform,
                    'uniform_version': this.docTask.uniform.current_version,
                    'response_data': {}
                }
            }
            return u
        },
        activeCustomForm() {
            let cf = {}
            if (this.docTask.hr_custom_form) {
                cf = {
                    'hr_custom_form': this.docTask.hr_custom_form,
                    'hr_custom_form_version': this.docTask.hr_custom_form.current_version,
                    'response_data': {}
                }
            }
            return cf
        }
    },
    methods: {
        getStartingStep() {
            let step = {
                'hr-doc-completion': COMPANY_DOC_STEPS[0],
                'uniform-completion': UNIFORM_STEPS[0],
                'custom-form-completion': CUSTOM_FORM_STEPS[0]
            }[this.docTask.task_type]
            return step
        },
        getNextStep(step) {
            let steps = {
                'hr-doc-completion': COMPANY_DOC_STEPS,
                'uniform-completion': UNIFORM_STEPS,
                'custom_form-completion': CUSTOM_FORM_STEPS,
            }[this.docTask.task_type]
            let stepIdx = steps.indexOf(step)
            if (stepIdx == -1 || stepIdx + 1 >= steps.length) {
                return null
            } else {
                let nextStep = steps[stepIdx + 1]
                return nextStep
            }
        },
        getPrevStep(step) {
            let steps = {
                'hr-doc-completion': COMPANY_DOC_STEPS,
                'uniform-completion': UNIFORM_STEPS,
                'custom_form-completion': CUSTOM_FORM_STEPS
            }[this.docTask.task_type]
            let stepIdx = steps.indexOf(step)
            if (stepIdx <= 0) {
                return null
            } else {
                let prevStep = steps[stepIdx - 1]
                return prevStep
            }
        },
        goToNextStep() {
            this.step = this.getNextStep(this.step)
        },
        goToPrevStep() {
            this.step = this.getPrevStep(this.step)
        },
        onNextStep() {
            this.validateFormData().then(validated => {
                if (!validated) {
                    return false
                } else {
                    this.goToNextStep()
                }
            })
        },
        onPrevStep() {
            this.formErrors = []
            // first check if we should exit out of this component
            if (!this.getPrevStep(this.step)) {
                this.$emit('prev') // close modal
                return
            }
            this.goToPrevStep()
        },
        async validateFormData() {
            this.formErrors = []
            if (this.step == 'doc-upload') {
                if (!this.formData.file) {
                    this.formErrors = ["Document upload is required."]
                    return false
                }
            }
            return true
        },
        onFileUpload(f) {
            this.formErrors = []
            this.formData.file = f[0]

            const reader = new FileReader()
            reader.onload = (e) => {
                this.pdfPreviewUrl = e.target.result
            }
            reader.readAsDataURL(f[0])
        },
        onSubmit() {
            let submitFn = {
                'hr-doc-completion': this.onSubmitCompanyDoc,
                'uniform-completion': this.onSubmitUniform,
                'custom-form-completion': this.onSubmitCustomForm
            }[this.docTask.task_type]
            submitFn()
        },
        onSubmitCompanyDoc() {
            if (!this.validateFormData()) {
                return
            }

            let data = {
                action: 'complete',
                name: this.formData.name,
                date: moment().format('MM/DD/YYYY'),
            }

            this.$store.dispatch('START_LOADING')
            this.$api.upload(`/clients/${this.client.id}/employees/${this.employee.id}/document-tasks/${this.docTask.id}`, this.formData.file, {fieldName: 'file', formData: data}).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo('Document uploaded successfully.')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        onSubmitUniform() {
            if (!this.$refs.activeUniformSurvey.validate()) {
                return
            }

            let data = {
                'action': 'complete'
            }
            Object.assign(data, this.$refs.activeUniformSurvey.getFormData())

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/employees/${this.employee.id}/document-tasks/${this.docTask.id}`, data).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo('Uniform completed successfully.')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        onSubmitCustomForm() {
            if (!this.$refs.activeCustomFormSurvey.validate()) {
                return
            }

            let data = {
                'action': 'complete'
            }
            Object.assign(data, this.$refs.activeCustomFormSurvey.getFormData())

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/employees/${this.employee.id}/document-tasks/${this.docTask.id}`, data).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo('Form completed successfully.')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    },
}
</script>