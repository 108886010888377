<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered my-4">
                        <div class="row">
                            <div class="col-12 col-md-10 offset-md-1">
                                <router-link class="btn btn-link mb-2" :to="{name: 'employer-home', params: {hashedClientId: $hasher.encode(client.id)}}">&laquo; Back to Dashboard</router-link>

                                <h3>Allocate Tips: <span class="text-thin">Pay Transactions Labor Audit Export</span></h3>

                                <form @submit.prevent="startReportImport" novalidate class="mt-4">
                                    <form-errors :errors="formErrors" :errorsHtml="formErrorsHtml" />

                                    <div class="row mb-1">
                                        <div class="col-12">
                                            <form-input label="Company" v-model="formData.company" :errors="errors.company" type="select" :options="companyOptions"/>
                                        </div>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="col-12">
                                            <form-input type="file" v-model="formData.report_file" :errors="errors.report_file" label-html="Upload the <b>Pay Transactions Labor Audit Export</b>:" helper-text="File extension must be .xlsx" accept=".xlsx" :multiple="true"  @upload="onUpload($event)" />
                                        </div>
                                    </div>

                                    <p>
                                        Download your <b>Pay Transactions Labor Audit Export</b> and then upload it here.
                                    </p>

                                    <ol class="px-2 my-2 mb-4">
                                        <li> After entering hours on the <b>Labor Entry Grid</b>, go to <b>PROCESS PAYROLL</b> and preview the payroll</li>
                                        <li> Select the report <b>Pay Transaction Labor Audit Export</b> in the drop down report menu and press <b>GO</b></li>
                                        <li> Save the report in a location that you will be able to upload them into <b>GetMyPayStub</b> from</li>
                                    </ol>

                                    <div class="row mb-2 d-flex justify-content-between">
                                        <div class="col">
                                            <button type="button" class="btn btn-block btn-outline-primary" @click="goBack()">Cancel</button>
                                        </div>
                                        <div class="col">
                                            <button type="submit" class="btn btn-block btn-primary">Continue</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'

import moment from 'moment'

export default {
    props: ['client'],
    mixins: [FormMixin, ],
    data() {
        return {
            statusDetail: "Uploading report",
            formRules: {
            },
            formLabels: {
            },
            formErrorsHtml: [],
            formData: this.makeFormData(),
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tips Allocator')
    },
    computed: {
        companies() {
            return this.client.companies || []
        },
        companyOptions() {
            const options = []
            for (let company of this.companies) {
                options.push({text: company.name, value: company.id})
            }
            return options
        },
    },
    methods: {
        makeFormData() {
            return {
                'report_file': null,
                'company': null,
            }
        },
        onUpload(f) {
            // NB: ALL FILES, not just f[0]
            this.formData.report_file = f
        },
        startReportImport() {
            const url = `/clients/${this.client.id}/tips-allocator/start`

            this.$store.dispatch('START_LOADING')
            // uploadMultiple
            // let files = {}
            // console.log(this.formData.report_file)
            // return

            return this.$api.uploadMultiple(url, this.formData.report_file, {'formData': {
                    'company': this.formData.company,
                    'report_file': this.formData.report_file,
                }, 'fieldName': 'report_file'}).then(resp => {
                let taskId = resp['task']['id']
                this.$router.push({name: 'tips-allocator-store-totals', params: {hashedClientId: this.$route.params.hashedClientId, taskId: taskId}});
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrorsHtml = []
            this.formData = this.makeFormData()
        },
        goBack: function() {
            this.$router.push({name: 'employer-home', params: {hashedClientId: this.$route.params.hashedClientId}});
        }
    },
}
</script>
