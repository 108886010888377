<template>
    <div>
        <nav-secondary :navOptions="navOptions"></nav-secondary>
        <router-view class="flex-page-content flex-container view" :company="company"/>
    </div>
</template>

<script>
import NavSecondary from '@/components/NavSecondary'

export default {
    components: { NavSecondary, },
    props: ['company', ],
    data() {
        const navOptions = [
            {'text': 'Personal Information', 'value': 'employee-profile-personal-info', 'params': '{hashedCompanyId: $route.params.hashedCompanyId}}'},
            {'text': 'Direct Deposit', 'value': 'employee-profile-direct-deposit', 'params': '{hashedCompanyId: $route.params.hashedCompanyId}}'},
            {'text': 'Tax Forms', 'value': 'employee-profile-tax-withholdings', 'params': '{hashedCompanyId: $route.params.hashedCompanyId}}'},
        ]

        return {
            navOptions: navOptions
        }
    },
    mounted() {
        if (!this.company.has_enhanced_ess) {
            this.$router.replace({name: 'employee-company-home', params: {hashedCompanyId: this.$route.params.hashedCompanyId}})
            return
        }
        const pageTitle = this.company ? `${this.company.name} / Profile` : "Profile"
        this.$store.dispatch('SET_PAGE_TITLE', pageTitle)
        const routes = this.navOptions.map(x => x.value)
        if (routes.indexOf(this.$route.name) < 0) {
            this.$router.replace({name: routes[0], params: {hashedCompanyId: this.$route.params.hashedCompanyId}})
        }
    },
}
</script>
