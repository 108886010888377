<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">
                      
                        <h3>Terms and Conditions</h3>
                        
                        <div class="d-flex mb-2 mb-rs-4">
                            <p class="mb-0">
                                Please accept the following terms and conditions before proceeding.
                            </p>
                            <div class="flex-0">
                                <button class="btn btn-outline-primary btn-collapse-xl ml-1" @click.prevent="$refs.terms.printToPDF()">
                                    <i class="far fa-fw fa-file-pdf"></i>
                                    <span class="sr-only">Print</span>
                                </button>
                            </div>
                        </div>
                        
                        <div class="card card-body bg-100 p-0 mb-4">
                            <div class="scrollable-content-terms">
                                <terms ref="terms" />
                            </div>
                        </div>
                        
                        <form @submit.prevent="onSubmit" class="mt-2">
                            <div class="form-row mb-2">
                                <div class="col">
                                    <div class="form-check">
                                        <input id="consent-checkbox" type="checkbox" class="form-check-input" v-model="formData.consent">
                                        <label for="consent-checkbox">
                                            I consent to the above terms and the <a href @click.prevent="showPrivacy()" class="d-inline-block">Privacy Policy</a>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <form-errors :errors="formErrors"/>
        
                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col">
                                    <router-link :to="{name: 'signup'}" class="btn btn-link">Back</router-link>
                                </div>
                                <div class="col text-right">
                                    <button class="btn btn-primary">Continue</button>
                                </div>
                            </div>
                        </form>
                        
                    </div>
                    
                </div>
            </div>
        </div>

        <privacy-modal ref="privacyModal" />
    </div>
</template>


<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import api from '@/api'
import Terms from '@/components/Policies/Terms'
import PrivacyPolicy from '@/components/Policies/PrivacyPolicy'
import PrivacyModal from '@/components/Policies/PrivacyModal'

export default {
    mixins: [FormMixin],
    components: {
        FormInput,
        FormErrors,
        Terms,
        PrivacyPolicy,
        PrivacyModal,
    },
    data() {
        return {
            formRules: {
                consent: {
                    presence: true,
                },
            },
            formData: {
                consent: false,
                action: 'sign-digital-consent',
            },
        }
    },
    methods: {
        validate() {
            const basicFormValidation = FormMixin.methods.validate.call(this)

            if (!this.formData.consent) {
                this.formErrors.push("Check the box indicating your agreement to continue.")
                return false
            }

            return basicFormValidation
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/signup/sign-digital-consent`, this.formData).then(() => {
                this.$router.push({name: 'signup-set-password'})
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrors = []
            this.formData.consent = false
        },
        showPrivacy() {
            this.$refs.privacyModal.open()
        },
    },
}
</script>
