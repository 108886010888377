<template>
    <modal @close="$emit('close')" ref="modal" :is-closeable="true" size="xl" :autoshow="true">
        <template #title>
            <b>Action Required:</b> Scan and Upload
        </template>
        <slot>
            <div v-if="['scan-and-upload'].includes(mode)">
                <p>
                    Company: {{ company.name }}
                </p>

                <div v-if="eVerifyCase" class="bg-light p-2 mb-2">
                    <b>Case #:</b> {{ eVerifyCase.case_number }}
                    <br>
                    <b>Status:</b> {{ eVerifyCase.case_status_pretty }}
                    <br>
                    <b>Employee:</b> {{ eVerifyCase.emp_full_name_sortable }}
                    <br>
                    <b>Start Date:</b> {{ $filters.date(eVerifyCase.emp_start_date) }}
                </div>

                <div v-if="employerDocumentsWithPhotos.length || employeeDocumentsWithPhotos.length">
                    You can use a document previously uploaded in the I-9 process, or scan & upload a new copy.
                    <br><br>

                    <div v-if="employeeDocumentsWithPhotos.length" class="mr-2">
                        <div class="py-hf mb-2" v-for="doc in employeeDocumentsWithPhotos">
                            <div v-if="doc.photos.length">
                                <label>
                                    <input type="radio" name="selectedDoc" :value="[doc.photos[0].id, doc.photos[1].id, ]" v-model="selectedDoc">
                                    {{ doc.document_title }} (Uploaded by Employee)
                                </label>
                                <div class="docphoto" :class="{'zoom-active': zoom > 100}">
                                    <img :src="`${$api.API_BASE}/clients/${client.id}/onboarding-applications/${onboardingApplicationId}/forms/${latestI9Form.id}/photos/${doc.photos[0].id}/preview`" alt="Document Photo" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                                    <img :src="`${$api.API_BASE}/clients/${client.id}/onboarding-applications/${onboardingApplicationId}/forms/${latestI9Form.id}/photos/${doc.photos[1].id}/preview`" alt="Document Photo" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="employerDocumentsWithPhotos.length">
                        <div class="py-hf mb-2" v-for="doc in employerDocumentsWithPhotos">
                            <div v-if="doc.photos.length">
                                <label>
                                    <input type="radio" name="selectedDoc" :value="[doc.photos[0].id, doc.photos[1].id, ]" v-model="selectedDoc">
                                    {{ doc.document_title }} (Uploaded by Manager)
                                </label>
                                <div class="docphoto" :class="{'zoom-active': zoom > 100}">
                                    <img :src="`${$api.API_BASE}/clients/${client.id}/onboarding-applications/${onboardingApplicationId}/forms/${latestI9Form.id}/photos/${doc.photos[0].id}/preview`" alt="Document Photo" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                                    <img :src="`${$api.API_BASE}/clients/${client.id}/onboarding-applications/${onboardingApplicationId}/forms/${latestI9Form.id}/photos/${doc.photos[1].id}/preview`" alt="Document Photo" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-2">
                        <label>
                            <input type="radio" name="selectedDoc" :value="'upload'" v-model="selectedDoc">
                            Upload new document
                        </label>
                    </div>
                </div>

                <div v-if="!(employerDocumentsWithPhotos.length || employeeDocumentsWithPhotos.length)">
                    Upload the photo document provided by the employee.
                </div>

                <div class="my-2" v-show="mustUpload || (selectedDoc == 'upload')">
                    <p>
                        Separate files for front and back, each file must be a .jpg, .pdf, or .png file no greater than 5 MB.
                    </p>

                    <div class="row">
                        <div class="col-6">
                            <div class="mb-2">
                                <b>Document Front</b>
                            </div>

                            <div v-if="!uploads['front']">
                                <button class="btn btn-outline-primary btn-boxy" @click.prevent="$refs.fileInputFront.click()">
                                    <i class="far fa-camera mr-hf"></i>
                                    Add Photo or Upload (Front)
                                </button>

                                <input class="d-none" type="file" @change="onUpload($event, 'front')" accept=".pdf,.jpg,.jpeg,.png,.heic,.heif;capture=camera" ref="fileInputFront" />
                            </div>

                            <div v-if="uploads['front']">
                                <div class="docphoto">
                                    <img :src="previewURLs['front']" alt="Document Photo">
                                </div>

                                <div class="text-small pt-1">
                                    Not quite right? <button class="btn btn-link-inline" @click.prevent="uploads['front'] = null">Add a different photo instead</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="mb-2">
                                <b>Document Back</b>
                            </div>

                            <div v-if="!uploads['back']">
                                <button class="btn btn-outline-primary btn-boxy" @click.prevent="$refs.fileInputBack.click()">
                                    <i class="far fa-camera mr-hf"></i>
                                    Add Photo or Upload (Back)
                                </button>

                                <input class="d-none" type="file" @change="onUpload($event, 'back')" accept=".pdf,.jpg,.jpeg,.png,.heic,.heif;capture=camera" ref="fileInputBack" />
                            </div>

                            <div v-if="uploads['back']">
                                <div class="docphoto">
                                    <img :src="previewURLs['back']" alt="Document Photo">
                                </div>

                                <div class="text-small pt-1">
                                    Not quite right? <button class="btn btn-link-inline" @click.prevent="uploads['back'] = null">Add a different photo instead</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </slot>

        <template #footer>
            <div class="modal-footer">
                <div v-if="mode == 'scan-and-upload'">
                    <hr v-if="formErrors.length" />
                    <form-errors :errors="formErrors"/>

                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmit('scan-and-upload')">
                            Submit to E-Verify
                        </button>
                    </button-row>
                </div>

                <div v-if="mode == 'view-case'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Close
                        </button>
                    </button-row>
                </div>
            </div>
        </template>
    </modal>

<!-- /Modal: Select Documents -->
</template>

<style scoped lang="scss">
    .docphoto {
        text-align: left;
        background: none;
        display: inline-block;
        margin-left: 1em;
    }
    .docphoto img {
        max-height: 200px;
        max-width: auto;
        margin-right: 1em;
    }
    .docphoto.zoom-active img {
        max-height: none;
    }
    .docphoto.zoom-active {
        max-width: 100%;
        overflow: auto;
        max-height: 200px;
    }
</style>


<script>
import $ from 'jquery'
import moment from 'moment'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import i9Documents from '@/utils/i9'
import ImageConverter from '@/utils/image-converter'
import DownloadableMixin from '@/mixins/Downloadable'

export default {
    mixins: [FormMixin, ModalMixin, DownloadableMixin],
    props: ['client', 'company', 'employee', 'eVerifyCase', 'latestI9Form'],
    mounted() {
        this.reset()
    },
    computed: {
        employeeObjectUrlPath() {
            return this.employee.is_employee ? 'employee' : 'onboarding-application'
        },
        onboardingApplicationId() {
            return this.latestI9Form ? this.latestI9Form.onboarding_application_id : null
        },
        previewURLs() {
            return {
                front: this.uploads.front ? `data:${this.uploads.front.file_type};base64,${this.uploads.front.data}` : '',
                back: this.uploads.back ? `data:${this.uploads.back.file_type};base64,${this.uploads.back.data}` : '',
            }
        },
        employeeDocumentsWithPhotos() {
            const result = []
            if (! this.latestI9Form) {
                return result
            }

            this.latestI9Form.photos.filter(p => !p.is_manager_uploaded).forEach(p => {
                const doc = result.find(x => x.document_slug == p.document_slug)
                if (!doc) {
                    result.push({
                        document_title: p.document_title,
                        document_slug: p.document_slug,
                        document_list: p.document_list,
                        is_manager_uploaded: false,
                        photos: [p],
                        docDetails: doc,
                    })
                } else {
                    doc.photos.push(p)
                    doc.photos.sort(a => {
                        return (a.side == 'front') ? -1 : 1
                    })
                }
            })
            return result
        },
        employerDocumentsWithPhotos() {
            const result = []
            if (! this.latestI9Form) {
                return result
            }

            const addDoc = (doc, list) => {
                const x = {
                    document_title: doc.shortName ? doc.shortName : doc.name,
                    document_slug: doc.slug,
                    document_list: list,
                    requiresBackPhoto: doc.requiresBackPhoto,
                    is_manager_uploaded: true,
                    photos: [],
                    docDetails: doc,
                }

                const front = this.latestI9Form.photos.find(p => p.document_slug == doc.slug && p.is_manager_uploaded && p.side == 'front')
                const back = this.latestI9Form.photos.find(p => p.document_slug == doc.slug && p.is_manager_uploaded && p.side == 'back')
                if (front) {
                    x.photos.push(front)
                    if (back) {
                        x.photos.push(back)
                    }
                }

                // only count if it has photos
                if (x.photos.length) {
                    result.push(x)
                }
            }

            if (this.latestI9Form.employer_form_data.doc_a_slug) {
                const doc = this.findI9Document(this.latestI9Form.employer_form_data.doc_a_slug)
                addDoc(doc, 'list-a')

                if (this.latestI9Form.employer_form_data.doc_a_secondary_slug) {
                    addDoc(this.findI9Document(doc.slug, this.latestI9Form.employer_form_data.doc_a_secondary_slug), 'list-a-1')
                }
                if (this.latestI9Form.employer_form_data.doc_a_tertiary_slug) {
                    addDoc(this.findI9Document(doc.slug, '', this.latestI9Form.employer_form_data.doc_a_tertiary_slug), 'list-a-2')
                }
            }

            if (this.latestI9Form.employer_form_data.doc_b_slug) {
                addDoc(this.findI9Document(this.latestI9Form.employer_form_data.doc_b_slug), 'list-b')
            }

            if (this.latestI9Form.employer_form_data.doc_c_slug) {
                addDoc(this.findI9Document(this.latestI9Form.employer_form_data.doc_c_slug), 'list-c')
            }

            return result
        },
        mustUpload() {
            return !(this.employerDocumentsWithPhotos.length || this.employeeDocumentsWithPhotos.length)
        },
    },
    data() {
        return {
            zoom: 100,
            formData: this.makeFormData(),
            formRules: {},
            errors: [],
            formErrors: [],
            mode: 'scan-and-upload',
            side: 'front',
            selectedDoc: null,
            uploads: {
                front: null,
                back: null,
            },
        }
    },
    methods: {
        makeFormData() {
            return {
            }
        },
        getInitialData() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            this.$api.get(url).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onSubmit(action) {
            const formData = {
                action: action,
                form_data: {
                    selected_doc: this.selectedDoc
                },
                uploads: this.uploads,
            }

            if (this.mustUpload) {
                formData['form_data']['selected_doc'] = 'upload'
            }

            // validation
            this.formErrors = []
            if (this.selectedDoc == 'upload') {
                if (! this.uploads['front']) {
                    this.formErrors.push('Front photo required.')
                }
                if (! this.uploads['back']) {
                    this.formErrors.push('Back photo required.')
                }
            }
            if (this.formErrors.length > 0) {
                return
            }

            if (!this.validate()) {
                return
            }

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            this.$store.dispatch('START_LOADING')
            this.$api.post(url, formData).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
                this.$nextTick(() => {
                    this.close(true)
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },

        reset() {
            FormMixin.methods.reset.call(this)
            this.formData = this.makeFormData()
            this.getInitialData()

            this.mode = 'scan-and-upload'

            this.isPristine = true
            this.$store.dispatch('SET_CLOSEABLE', true)
        },

        findI9Document(slug, secondarySlug, tertiarySlug) {
            let doc = i9Documents.listA.find(d => d.slug == slug)
            if (!doc) {
                doc = i9Documents.listB.find(d => d.slug == slug)
            }
            if (!doc) {
                doc = i9Documents.listC.find(d => d.slug == slug)
            }
            if (!doc) {return null}

            if (secondarySlug) {
                return doc.secondary_documents.find(d => d.slug == secondarySlug)
            }

            if (tertiarySlug) {
                return doc.tertiary_documents.find(d => d.slug == tertiarySlug)
            }

            return doc
        },
        onUpload(evt, side) {
            this.formErrors = []
            const files = evt.target.files || evt.dataTransfer.files
            if (!files.length) {
                return
            }

            const reader = new FileReader()
            reader.onload = () => {
                const photo = {
                    filename: files[0].name,
                    size: files[0].size,
                    file_type: files[0].type,
                    data: btoa(reader.result),

                    side: side,
                }

                this.$store.dispatch('START_LOADING')
                ImageConverter.convert(files[0]).then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    photo.data = resp.data  // Base64 encoded
                    photo.file_type = resp.mime
                    this.uploads[side] = photo
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    Object.keys(errors).forEach(field => {
                        if (field.substring(0, 2) == '__' && field != '__all__') {return}
                        this.formErrors = this.formErrors.concat(errors[field])
                    })

                    this.$bus.showError(this.formErrors)
                })
            }
            reader.readAsBinaryString(files[0])
        },
    }
}
</script>
