<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template v-slot:title>
            <span v-if="earningType">Update Earning Type: </span><span v-if="earningType" class="text-thin text-nowrap">{{ earningType.name }}</span>
            <span v-if="!earningType">Add New Earning Type</span>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name"  :readonly="earningType" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.type" :errors="errors.type" type="select" label="Type" :options="typeOptions"  />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col">
                        <form-input v-model="formData.has_tip_credit_claimed" :errors="errors.has_tip_credit_claimed" type="checkbox" label="Has Tip Credit Claimed"  />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col">
                        <form-input v-model="formData.is_tips" :errors="errors.is_tips" type="checkbox" label="Is Tips"  />
                    </div>
                </div>

                <div class="form-row mb-2 ml-2" v-if="formData.is_tips">
                    <div class="col">
                        <form-input v-model="formData.is_employer_paid_tips" :errors="errors.is_employer_paid_tips" type="checkbox" label="Is Employer Paid Tips"  />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col">
                        <form-input v-model="formData.is_work_suffered" :errors="errors.is_work_suffered" type="checkbox" label="Is Work Suffered"  />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col">
                        <form-input v-model="formData.is_virtual" :errors="errors.is_virtual" type="checkbox" label="Is Virtual (e.g. PUCC)"  />
                    </div>
                </div>

            </form>
        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['earningType', ],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        isPast() {
            return this.earningType && this.earningType.is_past
        },
        typeOptions() {
            return [
                {text: 'Hourly', value: 'hourly'},
                {text: 'Per-unit', value: 'per-unit'},
                {text: 'Amount', value: 'amount'},
            ]
        },
    },
    data() {
        return {
            states: [],
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.earningType) {
                return {
                    name: this.earningType.name,
                    type: this.earningType.current_version.type,
                    has_tip_credit_claimed: this.earningType.current_version.has_tip_credit_claimed,
                    is_tips: this.earningType.current_version.is_tips,
                    is_employer_paid_tips: this.earningType.current_version.is_employer_paid_tips,
                    is_work_suffered: this.earningType.current_version.is_work_suffered,
                    is_virtual: this.earningType.current_version.is_virtual,
                    starts_on: this.earningType.current_version.starts_on,
                    inactive_on: this.earningType.current_version.inactive_on,
                }
            } else {
                return {
                    name: '',
                    type: 'hourly',
                    has_tip_credit_claimed: false,
                    is_tips: false,
                    is_employer_paid_tips: false,
                    is_work_suffered: false,
                    is_virtual: false,
                    starts_on: '',
                    inactive_on: '',
                }
            }
        },
        getFormData() {
            return this.formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise
            if (this.earningType) {
                promise = this.$api.put(`/admin/tax-engine/earning-types/${this.earningType.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/admin/tax-engine/earning-types`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
