<template>
    <modal size="xl" @close="$emit('close')" ref="modal">
        <template v-slot:title>Earnings Statement</template>

        <template v-slot:headerbuttons>
            <button class="btn btn-outline-primary btn-collapse-xl ml-1 pull-right" @click.prevent="$refs.paycheckComponent.printToPDF()">
                <i class="far fa-fw fa-file-pdf"></i>
                <span class="sr-only">Print</span>
            </button>
        </template>

        <slot>
            <paycheck :paycheck="check" v-if="check" ref="paycheckComponent" />
        </slot>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import Paycheck from '@/components/Paycheck'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin],
    props: ['paycheck', 'client', ],
    components: {Paycheck, },
    data() {
        return {
            check: null,
        }
    },
    watch: {
        paycheck: {
            handler() {
                this.getPaycheck()
            },
            immediate: true,
        }
    },
    methods: {
        getPaycheck() {
            this.$store.dispatch('START_LOADING')
            const url = this.client ? `/clients/${this.client.id}/paychecks/${this.paycheck.id}` : `/admin/paychecks/${this.paycheck.id}`
            api.get(url).then(resp => {
                this.check = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
    },
}
</script>
