<template>
    <div>
        <div class="flex-body pb-2">
            <br><br><br><br>
            <div class="text-center">
                <div class="mx-auto d-flex flex-column justify-content-center">
                    <div class="spinner mx-auto position-relative mb-2">
                        <div class="lds-grid">
                            <div></div><div></div><div></div>
                            <div></div><div></div><div></div>
                            <div></div><div></div><div></div>
                        </div>
                    </div>
                    <div class="position-relative mx-auto">
                        <h4>Getting Data</h4>
                        <ul class="list-unstyled process-spinner-messages">
                            <li class="d-block">
                                {{ statusDetail }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'

import moment from 'moment'

export default {
    props: ['client', 'taskDetail'],
    data() {
        return {
            statusDetail: 'Syncing data from POS',
            pollInterval: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Point-of-Sale Integration')
        this.pollInterval = setInterval(() => {this.pollTask(this.taskDetail.id)}, 2000)
    },
    methods: {
        pollTask(taskId) {
            let url = `/clients/${this.client.id}/pos-integration/${taskId}/poll`
            api.get(url).then((resp) => {
                if (resp['task']['status_display'].toUpperCase() == 'COMPLETE') {
                    clearInterval(this.pollInterval)
                    if (resp['task']['step'] == 3) {
                        this.$router.push({name: 'pos-integration-complete', params: {hashedClientId: this.$route.params.hashedClientId, taskId: taskId}});
                    } else {
                        this.$router.push({name: 'pos-integration-review', params: {hashedClientId: this.$route.params.hashedClientId, taskId: taskId}});
                    }
                } else if (resp['task']['status_display'].toUpperCase() == 'ERROR') {
                    clearInterval(this.pollInterval)
                    this.$router.push({name: 'pos-integration-error', params: {hashedClientId: this.$route.params.hashedClientId, taskId: taskId}});
                } else {
                    // do nothing, keep polling
                    this.statusDetail = resp['task']['status_update']
                }
            })
        },
    },
}
</script>
