<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Case Results</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <h5>Employment Authorized</h5>
                                <p>
                                    An employment authorized result means the employee's information matched records available to DHS and/or SSA.
                                </p>

                                <h5>E-Verify Needs More Time</h5>
                                <p>
                                    An E-Verify needs more time result means the case is referred to DHS for further verification.
                                </p>

                                <h5>Tentative Nonconfirmation (mismatch)</h5>
                                <p>
                                    An DHS or SSA mismatch result means information did not match records available to DHS and/or SSA. Additional action is 
                                    required.
                                </p>

                                <h5>Case in Continuance</h5>
                                <p>
                                    A DHS or SSA Case in Continuance result means the employee has contacted DHS or SSA, but more time is needed to determine a final case result.
                                </p>

                                <h5>Close Case and Resubmit</h5>
                                <p>
                                    A Close Case and Resubmit means DHS or SSA requires that you close the case and create a new case for this employee. This result may be issued when the employee's U.S. passport, passport card, or driver's license information is incorrect.
                                </p>

                                <h5>Final Nonconfirmation</h5>
                                <p>
                                    A Final Nonconfirmation means E-Verify cannot confirm the employee's employment eligibility after the employee contacted DHS or visited SSA.
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page2b'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page3a'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
