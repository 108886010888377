<template>
    <div>
        <div class="flex-body pb-2">
            <br><br><br><br>
            <div class="text-center">
                <div class="mx-auto d-flex flex-column justify-content-center">
                    <div class="position-relative mx-auto text-left">
                        <div v-if="this.taskDetail.error_code == 'HOURS_IMPORT_ERROR_DATA_NOT_AVAILABLE'">
                            <div class="alert alert-warning">
                                <h4>
                                    <i class="fas fa-fw fa-exclamation-triangle"></i> Error
                                </h4>

                                <br>
                                No data is available to import for the selected pay period.
                            </div>
                        </div>

                        <div v-else-if="this.taskDetail.error_code == 'HOURS_IMPORT_ERROR_POS_DATA_ERROR' && this.taskDetail.error_detail">
                            <div class="alert alert-warning">
                                <h4>
                                    <i class="fas fa-fw fa-exclamation-triangle"></i> POS Data Error(s)
                                </h4>

                                <pre><strong>{{ this.taskDetail.error_detail }}</strong></pre>
                            </div>
                        </div>

                        <div v-else-if="this.taskDetail.error_code == 'HOURS_IMPORT_ERROR_POS_LOGIN_ERROR'">
                            <div class="alert alert-warning">
                                <h4>
                                    <i class="fas fa-fw fa-exclamation-triangle"></i> POS Login Error
                                </h4>

                                <pre><strong>Error logging in to POS. Contact Paymasters Support.</strong></pre>
                            </div>
                        </div>

                        <div v-else>
                            <div class="alert alert-danger">
                                <h4>
                                    <i class="fas fa-fw fa-exclamation-triangle"></i> Error
                                </h4>

                                <br>
                                The system has encountered a fatal error.
                                <br><br>
                                Support has been notified and is investigating.
                            </div>
                        </div>

                        <router-link class="btn btn-primary" :to="{name: 'pos-integration', query: {'initial': this.taskDetail.id}}">Back to Start</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'

export default {
    props: ['client', 'taskDetail'],
    data() {
        return {
            statusDetail: 'Syncing data from POS',
            pollInterval: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Point-of-Sale Integration')
    },
    methods: {
        pollTask(taskId) {
        },
    },
}
</script>
