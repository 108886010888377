<template>
<div v-show="dataLoaded">
<sidebar-widget
    @add="modalOpen = true"
    @delete="onDelete($event)"
    @edit="currentRowId = $event; modalOpen = true"
    @versions="currentRowId = $event; versionsModalIsOpen = true"
    :rows="rows"
    :headers="['Name', 'Type']"
>
    <template v-slot:title>Intertax Rules</template>

    <template v-slot:row-details scope="props">
        <intertax-rule-version :version="props.row.current_version"/>

        <p><a href @click.prevent="previewIntertaxRule = props.row">Preview tax selection</a></p>
    </template>
</sidebar-widget>

<edit-modal
    v-if="modalOpen"
    :tax="tax"
    :intertax-rule-id="currentRow ? currentRow.id : null"
    :taxes="taxes"
    :jurisdictions="jurisdictions"
    @updated="onUpdate"
    @close="modalOpen = false; currentRowId = null"
/>

<versions-modal
    v-if="versionsModalIsOpen"
    :url="`/admin/tax-engine/taxes/${tax.id}/intertax-rules/${currentRowId}`"
    @close="currentRowId = null; versionsModalIsOpen = false"
>
    <template v-slot:version scope="props">
        <intertax-rule-version :version="props.version"/>
    </template>
</versions-modal>

<intertax-rule-preview-modal
    :intertax-rule="previewIntertaxRule"
    v-if="previewIntertaxRule"
    @close="previewIntertaxRule = null"
/>

</div>
</template>

<script>
import EditModal from './IntertaxRuleModal'
import SidebarWidget from './SidebarWidget'
import IntertaxRuleVersion from './IntertaxRuleVersion'
import VersionsModal from './VersionsModal'
import IntertaxRulePreviewModal from './IntertaxRulePreviewModal'

export default {
    props: ['tax', 'taxes', 'jurisdictions'],
    components: {EditModal, SidebarWidget, IntertaxRuleVersion, VersionsModal, IntertaxRulePreviewModal, },
    computed: {
        currentRow() {
            return this.tax.intertax_rules.find(x => x.id == this.currentRowId)
        },
        rows() {
            const result = []
            this.intertaxRules.forEach(row => {
                row = JSON.parse(JSON.stringify(row))
                row.cells = [
                    row.name,
                    row.type_pretty,
                ]
                result.push(row)
            })
            return result
        },
    },
    data() {
        return {
            modalOpen: false,
            versionsModalIsOpen: false,
            currentRowId: null,
            dataLoaded: false,
            intertaxRules: [],
            previewIntertaxRule: null,
        }
    },
    mounted() {
        this.getData()
    },
    watch: {
        'tax.id'() {
            this.getData()
        },
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/taxes/${this.tax.id}/intertax-rules`).then(resp => {
                this.intertaxRules = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })

        },

        getURL(rowId) {
            if (!rowId) {return}
            return `/admin/tax-engine/taxes/${this.tax.id}/intertax-rules/${rowId}`
        },
        onDelete(rowId) {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(this.getURL(rowId)).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.intertaxRules = this.intertaxRules.filter(x => x.id != rowId)
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate() {
            this.getData()
            this.$emit('updated')
        },
    },
}
</script>



