<template>
<div v-if="uniformDataLoaded">
    <form class="mui-form">
        <h3 class="py-2 mb-0">Add Uniform Form{{ addVersionMode ? ' Version' : '' }}: <span class="text-thin text-nowrap">{{ stepAsText }}</span></h3>
        <div v-if="step != 'uniform-companies'" class="mb-2">
            <div class="p-1 unpad-x bg-150 rounded">
                <div><span class="text-muted">Company: </span>{{ companyAsText }}</div>
                <div v-if="step != 'uniform-name' && formData.name"><span class="text-muted">Document Name: </span>{{ formData.name }}</div>
                <div v-if="addVersionMode"><span class="text-muted">Version: </span>{{ formData.version_number }}</div>
            </div>
        </div>

        <form-errors class="unpad-x" :errors="formErrors"/>

        <div v-if="step == 'uniform-companies'">
            <p>Select the company or companies this form will be available for.</p>
            <company-selector :client="client" :companies="companiesWithFeature" v-model="formData.companies"/>
        </div>

        <div v-if="step == 'uniform-name'">
            <form-input v-model="formData.name" :errors="errors.name" label="Form Name" helperText="Tip: Name your form after the positions that will wear this uniform (“Crew” or “Managers”)" type="text" />
        </div>

        <div v-if="step == 'uniform-instructions'">
            <p>You will build your uniform form in 3 steps:</p>

            <p><strong>1) Add Clothing Items</strong></p>
            <p>Create a list of the base items employees should select sizes for.</p>

            <p><strong>2) Set Up Item Options</strong></p>
            <p>Set up size categories (mens, womens, etc.) and colors for items.</p>

            <p><strong>3) Preview Form</strong></p>
            <p>Double-check your work in the form preview.</p>
        </div>

        <div v-if="step == 'uniform-add-items'">
            <p>Select all clothing items (hat, shoes, shirt, etc.) available for employees.</p>
            <div v-for="itemName in defaultItems" :key="itemName">
                <form-input class="mb-1 pb-hf" :label="itemName" type="checkbox" :model-value="isDefaultItemEnabled(itemName)" @update:model-value="toggleDefaultItemEnabled(itemName)" />
            </div>
            <template v-for="(item, idx) in formData.uniform_items" :key="'customItem-' + idx">
                <div class="form-row" v-if="item.is_custom">
                    <div class="col-auto">
                        <input type="checkbox" class="mt-1" disabled checked>
                    </div>
                    <div class="col-8">
                        <form-input type="text" :deletable="true" :nocaps="true" v-model="item.name" placeholder="Custom Item Name" @delete="removeItemAtIndex(idx)"/>
                    </div>
                </div>
            </template>
            <button class="btn btn-link" @click.prevent="addCustomItem"><i class="far fa-fw fa-plus mr-hf"></i>Add a Custom Item</button>
        </div>

        <div v-if="step == 'uniform-setup-items'">
            <p>Edit the size categories, colors, and sizes available for each clothing item.</p>
            <ul class="list-unstyled">
                <li v-for="item in formData.uniform_items" :key="item.name" class="px-1 py-2 border mb-1">
                    <div class="todo-item" style="cursor: pointer" :class="{'todo-item-completed': isItemCompleted(item)}" href @click.prevent="editItem(item)">
                        <span class="todo-item-content">
                            <h6>{{ item.name }}</h6>
                            <span v-if="itemOptionsAsText(item)">{{ itemOptionsAsText(item) }}</span>
                            <span v-else><em>Click here to set up sizes and colors</em></span>
                        </span><br/>
                        <span class="btn btn-icon todo-item-arrow">
                            <i aria-hidden="true" class="fas fa-chevron-right"></i>
                        </span>
                    </div>
                </li>
            </ul>
        </div>

        <div v-if="step == 'uniform-item-categories'">
            <p><strong>{{ editingItem.name }}</strong></p>
            <hr>
            <p>Select all size categories for {{ editingItem.name }}. You must select at least one.</p>
            <div v-for="categoryName in defaultCategories" :key="categoryName">
                <form-input class="mb-1 pb-hf" :label="categoryName" type="checkbox" :model-value="isCategoryEnabled(editingItem, categoryName)" @update:model-value="toggleCategoryEnabled(editingItem, categoryName)" />
            </div>
            <template v-for="(category, idx) in editingItem.categories" :key="'customCategory-' + idx">
            <div class="form-row" v-if="category.is_custom">
                <div class="col-auto">
                    <input type="checkbox" class="mt-1" disabled checked>
                </div>
                <div class="col-8">
                    <form-input type="text" :deletable="true" :nocaps="true" v-model="category.name" placeholder="Custom Category Name" @delete="removeCategoryAtIndex(editingItem, idx)"/>
                </div>
            </div>
            </template>
            <button class="btn btn-link" @click.prevent="addCustomCategory(editingItem)"><i class="far fa-fw fa-plus mr-hf"></i>Add a Custom Category</button>
        </div>

        <div v-if="step == 'uniform-item-sizes-colors'">
            <button class="btn btn-link mb-2 mt-0" @click.prevent="onPrevStep()"><i class="far fa-fw fa-chevron-left mr-hf"></i>Back to Size Categories</button>
            <p><strong>{{ editingItem.name }}</strong></p>
            <hr>
            <p>Set up sizes and colors available for each sizing category</p>
            <info-widget v-for="category in editingItem.categories" :key="category.name">
                <template v-slot:title>{{ category.name }}</template>
                <div class="card-body py-1 mt-1">
                    <h5>SIZES</h5>
                    <p>Input the sizes available for {{ editingItem.name }} - {{ category.name }} (example: S, M, L or 8, 10, 12). You can have as many sizes as you require.</p>
                    <template v-for="(size, idx) in category.sizes" :key="'size-' + idx">
                    <div class="form-row">
                        <div class="col-8">
                            <form-input type="text" :deletable="true" :nocaps="true" v-model="size.name" placeholder="Size Name" @delete="removeSizeAtIndex(category, idx)"/>
                        </div>
                    </div>
                    </template>
                    <button class="btn btn-link mb-3" @click.prevent="addSize(category)"><i class="far fa-fw fa-plus mr-hf"></i>Add Another Size</button>

                    <h5>COLORS</h5>
                    <p>Input the colors available for {{ editingItem.name }} - {{ category.name }} (if any).</p>
                    <!-- <form-input type="radio" v-model="category.has_colors" :options="colorOptions" :checked="category.has_colors"/> -->
                    <div class="form-check">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input" :name="category.name + 'HasColors'" :checked="!category.has_colors" @click="category.has_colors = false"/>
                            <span>No color options</span>
                        </label>
                    </div>
                    <div class="form-check py-hf mb-1">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input" :name="category.name + 'HasColors'" :checked="category.has_colors" @click="category.has_colors = true; if (!category.colors.length) { category.colors = makeColorsFormData() }"/>
                            <span>Add color options</span>
                        </label>
                    </div>
                    <div v-if="category.has_colors">
                        <template v-for="(color, idx) in category.colors" :key="'color-' + idx">
                        <div class="form-row">
                            <div class="col-8">
                                <form-input type="text" :deletable="true" :nocaps="true" v-model="color.name" placeholder="Color Name" @delete="removeColorAtIndex(category, idx)"/>
                            </div>
                        </div>
                        </template>
                        <button class="btn btn-link mb-2" @click.prevent="addColor(category)"><i class="far fa-fw fa-plus mr-hf"></i>Add Another Color</button>
                    </div>
                </div>
            </info-widget>
        </div>

        <div v-if="step == 'uniform-preview'">
            <p class="py-1"><strong>This is the form that will be presented to employees.</strong> Review it below and ensure that all questions and options are correct.</p>
            <info-widget>
                <template v-slot:title>Uniform Selections</template>
                <div class="card-body py-1 mt-1">
                    <div v-for="item in formData.uniform_items" :key="item.name + '-size'">
                        <h5>{{ item.name }}</h5>
                        <form-input type="select" :label="item.name + ' Size'" v-model="item.previewing_category_size" @update:model-value="onItemPreviewSelect(item, $event)" :opt-groups="makeItemPreviewSizeOptGroups(item)"/>
                        <form-input v-if="item.previewing_category && itemPreviewColorOptions[item.name][item.previewing_category].length" type="select" :label="item.name + ' Color'"  v-model="item.previewing_color" :options="itemPreviewColorOptions[item.name][item.previewing_category]" :readonly="false"/>
                    </div>
                </div>
            </info-widget>
        </div>

        <div v-if="step == 'uniform-settings'">
            <info-widget>
                <template v-slot:title>Signature Settings</template>
                <div class="card-body pt-2 pb-1">
                    <p class="text-semibold">Is a manager signature required to approve this document?</p>
                    <div class="form-check">
                        <label>
                            <input name="manager_signature_required" type="radio" @click="formData.manager_signature_required = true" :checked="formData.manager_signature_required == true" class="form-check-input">
                            <strong>Yes</strong>, manager signature is required.
                        </label>
                        <p class="mt-0 small">
                            A manager must review and sign this document after submission to approve.
                        </p>
                    </div>
                    <div class="form-check">
                        <label>
                            <input name="manager_signature_required" type="radio" @click="formData.manager_signature_required = false" :checked="formData.manager_signature_required == false" class="form-check-input">
                            <strong>No</strong>, manager signature is not required.
                        </label>
                    </div>
                </div>
            </info-widget>
        </div>

        <div v-if="step == 'uniform-assignment'">
            <p>How will you require this uniform form for employees?</p>
            <div class="form-check mb-hf">
                <div class="form-row">
                    <div class="col">
                        <label>
                            <input name="uniformAssignment" type="radio" @click="formData.assign_to = 'positions'" :checked="formData.assign_to == 'positions'" class="form-check-input">
                            <strong>By Position</strong>
                        </label>
                        <p class="mt-0">
                            You will be asked to select positions. All employees in the positions you select will be required to have this document completed.
                        </p>
                    </div>
                </div>
            </div>
            <div class="form-check mb-hf">
                <div class="form-row">
                    <div class="col">
                        <label>
                            <input name="uniformAssignment" type="radio" @click="formData.assign_to = 'individuals'; formData.positions = []; formData.include_in_onboarding = false" :checked="formData.assign_to == 'individuals'" class="form-check-input">
                            <strong>By Individual Employee</strong>
                        </label>
                        <div class="mt-0">
                            After you create this document, you may manually require individual employees to have this document completed via their employee record.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="step == 'uniform-positions'">
            <p>Select the positions that are required to complete this uniform form.</p>

            <position-selector :all-positions="allCompanyPositions" :companies="formData.companies" v-model="formData.positions" />
        </div>

        <div v-if="step == 'version-settings'">
            <p class="text-semibold">Is this new version required for active employees who already have a previous version of this document completed?</p>
            <div class="form-check pt-1 pb-1">
                <label>
                    <input name="is_major_version" type="radio" @click="formData.is_major_version = true" :checked="formData.is_major_version == true" class="form-check-input">
                    <p class="mb-1"><strong>Yes, this is a required version</strong>.</p>
                    <span v-if="formData.collection_method == 'employee'">All active employees who are required to have {{ formData.name }} completed must now complete this new version, <em>even if they have already completed a previous version of this document</em>.</span>
                    <span v-else>All active employees who are required to have {{ formData.name }} completed must now have this new version completed, <em>even if they already have a previous version of this document completed</em>.</span>
                </label>
                <p class="small mt-0">
                    <em>(example: you are making a significant revision to this document so employees must complete it again)</em>
                </p>
            </div>
            <div class="form-check">
                <label>
                    <input name="is_major_version" type="radio" @click="formData.is_major_version = false" :checked="formData.is_major_version == false" class="form-check-input">
                    <strong>No</strong>, active employees who already have the previous required version of this document completed (version {{ uniform.major_version_number }}{{ (uniform.major_version_number < uniform.version_number) ? ' or higher' : '' }}) are <strong>not required</strong> to also have this new version completed.<br/>
                </label>
                <p class="small mt-0">
                    <em>(example: you are fixing a typo or making a change that does not require employees to complete this document again)</em>
                </p>
            </div>
        </div>

        <div v-if="step == 'uniform-collection-settings'">
            <p>
                <span class="text-semibold">How should GetMyPaystub collect this document for required employees?</span>
                <small v-if="formData.positions.length">You may collect the document differently for new employees and for current active employees.</small>
            </p>
            <info-widget v-if="formData.positions.length">
                <template v-slot:title>New Employees</template>
                <div class="card-body pt-2 pb-1">
                    <div class="form-check">
                        <div class="form-row">
                            <div class="col">
                                <label>
                                    <input name="include_in_onboarding" type="radio" @click="formData.include_in_onboarding = true" :checked="formData.include_in_onboarding == true" class="form-check-input">
                                    <strong>Employees</strong> will be prompted to complete this uniform form online when they onboard or first log in.
                                </label>
                                <p class="mt-0 small">
                                    New employees will be blocked from accessing paystubs, W-2s, etc. until they have completed this uniform form.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form-check">
                        <div class="form-row">
                            <div class="col">
                                <label>
                                    <input name="include_in_onboarding" type="radio" @click="formData.include_in_onboarding = false" :checked="formData.include_in_onboarding == false" class="form-check-input">
                                    <strong>Managers</strong> will complete this uniform form on behalf of employees.
                                </label>
                                <p class="mt-0 small">
                                    New employees will be allowed to access paystubs, W-2s, etc. before this uniform form is completed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </info-widget>
            <info-widget>
                <template v-slot:title>{{ activeEmployeeLabel }} Employees</template>
                <div class="card-body pt-2 pb-1">
                    <p class="text-semibold">How do you want to collect this document for {{ activeEmployeeLabel.toLowerCase() }} employees?</p>
                    <div class="form-check pb-1">
                        <label>
                            <input name="collection_method" type="radio" @click="formData.collection_method = 'employee'" :checked="formData.collection_method == 'employee'" class="form-check-input">
                            <strong>Employees</strong> will receive an email and will be prompted to complete this document online.
                        </label>
                        <div class="pl-2 pr-1 pt-2 pb-1 mb-1 bg-100 rounded" v-show="formData.collection_method == 'employee'">
                            <div class="form-check">
                                <label>
                                    <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = true" :checked="formData.block_ee_until_completion == true" class="form-check-input">
                                    Block access to paystubs, W-2s, etc. until employee has completed this document
                                </label>
                            </div>
                            <div class="form-check">
                                <label>
                                    <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = false" :checked="formData.block_ee_until_completion == false" class="form-check-input">
                                    Allow employee to access paystubs, W-2s, etc. before completing this document
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-check">
                        <label>
                            <input name="collection_method" type="radio" @click="formData.collection_method = 'manager'; formData.block_ee_until_completion = false" :checked="formData.collection_method == 'manager'" class="form-check-input">
                            <strong>Managers</strong> will be given a task to complete or upload this document for each required employee.
                        </label>
                        <p class="mt-0 small">
                            Employees will be allowed to access paystubs, W-2s, etc. before this document is completed.
                        </p>
                    </div>
                    <div class="form-check pb-1">
                        <label>
                            <input name="collection_method" type="radio" @click="formData.collection_method = 'do-not-collect'" :checked="formData.collection_method == 'do-not-collect'" class="form-check-input">
                            <strong>Do not collect</strong> this document for active employees.
                        </label>
                    </div>
                    <alert-box type="info">
                        In any case, managers can always upload or complete a document on an employee’s behalf.
                    </alert-box>
                </div>
            </info-widget>
        </div>

        <div v-if="step == 'uniform-summary'">
            <p v-if="addDocMode">Review the settings below before saving this uniform form.</p>
            <p v-if="addVersionMode">
                <span class="text-semibold">Review the settings below before saving and making this the active version of {{ uniform.name }}</span>.
                Once you make this the active version, future responses will use these updated settings.
            </p>

            <info-widget>
                <template v-slot:title>Signature Settings</template>
                <template v-slot:headerbutton>
                    <button class="btn btn-outline-primary" type="button" @click.prevent="goToStepFromSummary('uniform-settings')">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>
                <div class="card-body">
                    <template v-if="formData.manager_signature_required">
                        <p class="mb-1"><strong>Manager signature is required to approve this uniform form.</strong></p>
                        <p class="mb-0">A manager must review and sign this uniform form after submission to approve.</p>
                    </template>
                    <template v-else>
                        <strong>Manager signature is not required.</strong>
                    </template>
                </div>
            </info-widget>

            <info-widget>
                <template v-slot:title>Required Employees</template>
                <template v-slot:headerbutton v-if="addDocMode">
                    <button class="btn btn-outline-primary" type="button" @click.prevent="goToStepFromSummary('uniform-assignment')">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>
                <div class="card-body">
                    <template v-if="formData.positions.length">
                        <p class="mb-hf">This uniform form is required for employees in <strong>{{ selectedPositions.length }} position{{ selectedPositions.length > 1 ? 's' : '' }}.</strong></p>
                        <ul class="mb-2">
                            <li v-for="p in selectedPositions" :key="p.terminal_value">
                                {{ p.terminal_value }}
                            </li>
                        </ul>
                    </template>
                    <template v-if="uniform && uniform.individual_document_requirements && uniform.individual_document_requirements.length">
                        <p class="mb-hf">This uniform form is required for <strong>{{ this.uniform.individual_document_requirements.length }} individual employee{{ formData.positions.length > 1 ? 's' : '' }}.</strong></p>
                        <ul>
                            <li v-for="r in this.uniform.individual_document_requirements" :key="r.id">
                                {{ r.employee.name }}
                            </li>
                        </ul>
                    </template>
                    <template v-if="!formData.positions.length && !(uniform && uniform.individual_document_requirements)">
                        <strong>This uniform form is not required for any employees.</strong><br/>
                        You can add required positions and individual employees after creating this uniform form.
                    </template>
                </div>
            </info-widget>

            <info-widget v-if="addVersionMode">
                <template v-slot:title>Version Settings</template>
                <template v-slot:headerbutton>
                    <button class="btn btn-outline-primary" type="button" @click.prevent="goToStepFromSummary('version-settings')">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>
                <div class="card-body">
                    <template v-if="formData.is_major_version">
                        <p class="mb-1"><strong>This is a required version.</strong></p>
                        <p class="mb-0" v-if="formData.collection_method == 'employee'">
                            All required active employees must now complete this new version, <em>even if they have already completed a previous version of this uniform form.</em>.
                        </p>
                        <p class="mb-0" v-if="formData.collection_method == 'manager'">
                            All required active employees must now have this new version completed, <em>even if they already have a previous version of this uniform form completed</em>.
                        </p>
                    </template>
                    <template v-else>
                        Active employees who already have the previous required version of this uniform form completed (version {{ uniform.major_version_number }}{{ (uniform.major_version_number < uniform.version_number) ? ' or higher' : '' }}) are <strong>not required</strong> to also have this new version completed.
                    </template>
                </div>
            </info-widget>

            <info-widget v-if="addDocMode || formData.is_major_version">
                <template v-slot:title>Collection Settings</template>
                <template v-slot:headerbutton>
                    <button class="btn btn-outline-primary" type="button" @click.prevent="goToStepFromSummary('uniform-collection-settings')">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>
                <div class="card-body">
                    <p>GetMyPayStub will collect this uniform form from required employees using these methods:</p>
                    <template v-if="formData.positions.length">
                        <h5>New Employees</h5>
                        <p v-if="formData.include_in_onboarding">
                            <strong>New employees</strong> will be prompted to complete this uniform form online when they onboard or first log in.<br/>
                            <small>New employees will be blocked from accessing paystubs, W-2s, etc. until they have completed this uniform form.</small>
                        </p>
                        <p v-else>
                            <strong>Managers</strong> will be given a task to complete this uniform form for each new employee.<br/>
                            <small>New employees will be allowed to access paystubs, W-2s, etc. before this uniform form is completed.</small>
                        </p>
                    </template>
                    <h5 class="pt-1">{{ activeEmployeeLabel }} Employees</h5>
                    <template v-if="formData.collection_method == 'employee'">
                        <p>
                            <strong>{{ activeEmployeeLabel }} employees</strong> will receive an email and will be prompted to complete this uniform form online.<br/>
                            <small v-if="formData.block_ee_until_completion">{{ activeEmployeeLabel }} employees will be blocked from accessing paystubs, W-2s, etc. until they have completed this document.</small>
                            <small v-else>{{ activeEmployeeLabel }} employees will be allowed to access paystubs, W-2s, etc. before this uniform form is completed.</small>
                        </p>
                    </template>
                    <p v-if="formData.collection_method == 'manager'">
                        <strong>Managers</strong> will be given a task to complete this uniform form for each {{ activeEmployeeLabel.toLowerCase() }} employee.<br/>
                        <small>{{ activeEmployeeLabel }} employees will be allowed to access paystubs, W-2s, etc. before this uniform form is completed.</small>
                    </p>
                    <p v-else-if="formData.collection_method == 'do-not-collect'">
                        This document will not be collected for active employees.
                    </p>
                </div>
            </info-widget>

            <info-widget v-if="addDocMode">
                <template v-slot:title>Termination Settings</template>
                <div class="card-body">
                    <span>Uniform form is <strong>canceled</strong> and no longer required for an employee when they are terminated.</span>
                </div>
            </info-widget>
        </div>

    </form>

    <button-row stacked>
        <button type="button" class="btn btn-outline-primary" @click.prevent="onPrevStep">Back</button>
        <button v-if="showSaveAsDraft" type="button" class="btn btn-outline-primary" @click.prevent="saveAsDraft">Save as Draft</button>
        <button v-if="step == 'uniform-summary'" type="button" class="btn btn-primary" @click.prevent="saveAndPublish">{{ saveAndPublishText }}</button>
        <button v-else type="button" class="btn btn-primary" @click.prevent="onNextStep">{{ nextButtonLabel }}</button>
    </button-row>
</div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import CompanySelector from './CompanySelector'
import PositionSelector from './PositionSelector'
import InfoWidget from '@/components/InfoWidget'
import ButtonRow from '@/components/ButtonRow.vue'

// Steps used when adding a new uniform (i.e. 'add' and 'edit-draft' modes)
const STEPS = [
    'uniform-companies',
    'uniform-name',
    // 'uniform-instructions',
    'uniform-add-items',
    'uniform-setup-items',
    'uniform-item-categories',
    'uniform-item-sizes-colors',
    'uniform-preview',
    'uniform-settings',
    'uniform-assignment',
    'uniform-positions',
    'uniform-collection-settings',
    'uniform-summary'
]

// Steps used when adding or editing a version (i.e. 'add-version' and 'edit-draft-version' modes)
const VERSION_STEPS = [
    'uniform-add-items',
    'uniform-setup-items',
    'uniform-item-categories',
    'uniform-item-sizes-colors',
    'uniform-preview',
    'uniform-settings',
    'uniform-summary'
]

const STEP_METADATA = {
    'uniform-companies': {'showSaveAsDraft': false, 'saveOnNext': false},
    'uniform-name': {'showSaveAsDraft': false, 'saveOnNext': true},
    'uniform-instructions': {'showSaveAsDraft': false, 'saveOnNext': false},
    'uniform-add-items': {'showSaveAsDraft': true, 'saveOnNext': true},
    'uniform-setup-items': {'showSaveAsDraft': true, 'saveOnNext': true, 'nextStep': 'uniform-preview'},
    'uniform-item-categories': {'showSaveAsDraft': true, 'saveOnNext': true, 'nextButtonLabel': 'Continue'},
    'uniform-item-sizes-colors': {'showSaveAsDraft': true, 'saveOnNext': true, 'nextButtonLabel': 'Save Sizes', 'nextStep': 'uniform-setup-items'},
    'uniform-preview': {'showSaveAsDraft': true, 'saveOnNext': false, 'prevStep': 'uniform-setup-items'},
    'uniform-settings': { 'showSaveAsDraft': true, 'saveOnNext': true},
    'uniform-assignment': {'showSaveAsDraft': true, 'saveOnNext': false},
    'uniform-positions': {'showSaveAsDraft': true, 'saveOnNext': true},
    'version-settings': {'showSaveAsDraft': true, 'saveOnNext': true},
    'uniform-collection-settings': {'showSaveAsDraft': true, 'saveOnNext': true},
    'uniform-summary': {'showSaveAsDraft': true, 'saveOnNext': true},
}

const FORM_OPTIONS = {
    'is_major_version': {
        true: {
            'label': "Major Revision",
            'description': ""
        },
        false: {
            'label': "Minor Revision",
            'description': ""
        }
    },
    'include_in_onboarding': {
        true: {
            'label': "Employees will be prompted to complete this uniform form online when they onboard or first log in",
            'description': "Block access to paystubs, W-2s,  etc. until employee has completed this uniform form."
        },
        false: {
            'label': "Managers will complete this uniform form on behalf of employees.",
            'description': "Allow employee to access paystubs, W-2s,  etc. before completing this uniform form."
        }
    },
    'collection_method': {
        'employee': {
            'label': "Employees will be prompted to complete this uniform form online",
            'description': ""
        },
        'manager': {
            'label': "Managers will complete this uniform form on behalf of employees",
            'description': ""
        }
    },
    'block_ee_until_completion': {
        true: {
            'label': "Block access to paystubs, W-2s,  etc. until employee has completed this uniform form.",
            'description': ""
        },
        false: {
            'label': "Allow employee to access paystubs, W-2s,  etc. before completing this uniform form.",
            'description': ""
        }
    },
}

export default {
    components: {CompanySelector, PositionSelector, InfoWidget, ButtonRow},
    props: ['client', 'uniformId', 'mode'], // mode can be add, edit-draft, add-version, edit-draft-version
    mixins: [FormMixin, ],
    data() {
        let data = {
            step: this.getStartingStep(),
            formData: this.makeFormData(),
            defaultItems: ['Hat', 'Shirt', 'Pants', 'Shoes'],
            defaultCategories: ['Unisex', "Men's", "Women's", 'Youth', 'Adult'],
            editingItem: {},
            allPositions: [],
            uniform: {},
            uniformDataLoaded: false,
            navigatedFromSummary: false
        }
        return data
    },
    computed: {
        companyAsText() {
            let companyText = ''
            if (this.formData.companies && this.formData.companies.length > 0) {
                let company = (this.client.companies.find(company => company.id == this.formData.companies[0]))
                if (company) {
                    companyText = company.name
                }
                if (this.formData.companies && this.formData.companies.length > 1) {
                    companyText += ` + ${this.formData.companies.length - 1} More`
                }
            }
            return companyText
        },
        stepAsText() {
            return {
                'uniform-companies': 'Company Selection',
                'uniform-name': 'Form Name',
                'uniform-instructions': 'Form Instructions',
                'uniform-add-items': 'Add Clothing Items',
                'uniform-setup-items': 'Set Up Clothing Items',
                'uniform-item-categories': `${this.editingItem ? this.editingItem.name: ''} Size Categories`,
                'uniform-item-sizes-colors': `${this.editingItem ? this.editingItem.name: ''} Sizes`,
                'uniform-preview': 'Form Preview',
                'uniform-settings': 'Signature Settings',
                'uniform-positions': 'Requirement',
                'uniform-collection-settings': 'Collection',
                'uniform-summary': 'Summary'
            }[this.step]
        },
        showSaveAsDraft() {
            return STEP_METADATA[this.step]['showSaveAsDraft']
        },
        nextButtonLabel() {
            return STEP_METADATA[this.step]['nextButtonLabel'] || 'Continue'
        },
        itemPreviewColorOptions() {
            let colorsByItem = {}
            for (let item of this.formData.uniform_items) {
                colorsByItem[item.name] = {
                    'hasCategoryWithColors': false
                }
                for (let category of item.categories) {
                    colorsByItem[item.name][category.name] = []
                    for (let color of category.colors) {
                        colorsByItem[item.name]['hasCategoryWithColors'] = true
                        colorsByItem[item.name][category.name].push({text: color.name, value: color.name})
                    }
                }
            }
            return colorsByItem
        },
        allCompanyPositions() {
            return this.allPositions.filter(p => this.formData.companies && this.formData.companies.includes(p.company_id))
        },
        selectedPositions() {
            return this.allCompanyPositions.filter(p => this.formData.positions && this.formData.positions.includes(p.id))
        },
        companiesWithFeature() {
            return this.client.companies.filter(c => c.has_uniform_module)
        },
        addDocMode() {
            return this.mode == 'add' || this.mode == 'edit-draft' || this.mode == 'duplicate'
        },
        addVersionMode() {
           return this.mode == 'add-version' || this.mode == 'edit-draft-version'
        },
        willSendEmails() {
            return this.formData.is_major_version && this.formData.collection_method == 'employee' && this.formData.positions.length
        },
        saveAndPublishText() {
            if (this.willSendEmails) {
                return 'Save & Send'
            } else {
                return this.addVersionMode ? 'Save & Make Active' : 'Save Uniform'
            }
        },
        activeEmployeeLabel() {
            return this.formData.positions.length ? 'Active' : 'Required'
        },
    },
    created() {
        if (this.uniformId) {
            this.getUniform()
        } else {
            this.uniformDataLoaded = true
        }
        this.getPositions()
    },
    methods: {
        getUniform() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/uniforms/${this.uniformId}`).then(resp => {
                this.uniform = resp
                this.uniformDataLoaded = true
                this.formData = this.makeFormData()
                this.step = this.getStartingStep()
                // this.editingItem = this.getStartingEditingItem()
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getPositions() {
            this.$api.get(`/clients/${this.client.id}/positions`).then(resp => {
                this.allPositions = resp
                this.allPositions.sort((a, b) => {return a.terminal_value.toLowerCase() > b.terminal_value.toLowerCase() ? 1 : -1})
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getStartingStep() {
            if (this.mode == 'add') {
                return STEPS[0]
            } else if (this.mode == 'add-version') {
                return VERSION_STEPS[0]
            } else if (this.mode == 'edit-draft') {
                if  (this.uniform && this.uniform.draft_version) {
                    let step = this.uniform.draft_version.draft_data.step
                    if (step == 'uniform-item-categories' || step == 'uniform-item-sizes-colors') {
                        // best UX is probably to bring them to the "Set Up Clothing Items" step if they last exited while editing an item
                        return 'uniform-setup-items'
                    } else {
                        return step
                    }
                } else {
                    return STEPS[0]
                }
            } else if (this.mode == 'edit-draft-version') {
                if (this.uniform && this.uniform.draft_version) {
                    let step = this.uniform.draft_version.draft_data.step
                    if (step == 'uniform-item-categories' || step == 'uniform-item-sizes-colors') {
                         // best UX is probably to bring them to the "Set Up Clothing Items" step if they last exited while editing an item
                        return 'uniform-setup-items'
                    } else {
                        return step
                    }
                } else {
                    return VERSION_STEPS[0]
                }
            }
        },
        getStartingEditingItem() {
            if (this.mode == 'edit-draft' || this.mode == 'edit-draft-version') {
                if (this.uniform && this.uniform.draft_data.editingItemName) {
                    return this.formData.uniform_items.find(i => i['name'] == this.uniform.draft_data.editingItemName)
                }
            }
            return {}
        },
        makeFormData() {
            let data = {}
            if (this.mode == 'add') {
                data = {
                    version_number: 1,
                    companies: [],
                    name: '',
                    uniform_items: [],
                    manager_signature_required: undefined,
                    assign_to: '',
                    positions: [],
                    is_major_version: true,
                    collection_method: 'employee', // TODO KB ideally these are undefined but that doesn't play nicely with saveOnNext
                    block_ee_until_completion: false,
                    include_in_onboarding: true,
                    is_draft: true,
                    batch_key: '',
                    publish_version: false,
                    draft_data: {}
                }
            } else if (this.uniform && this.mode == 'add-version') {
                data = this.makeFormDataFromUniformVersion(this.uniform.current_version)
                data.version_number = data.version_number + 1
                data.is_major_version = undefined
            } else if (this.uniform && this.mode == 'edit-draft') {
                data = this.makeFormDataFromUniformVersion(this.uniform.draft_version)
                data.batch_key = this.uniform.batch_key
                data.companies = this.uniform.batch_company_ids
            } else if (this.uniform && this.mode == 'edit-draft-version') {
                data = this.makeFormDataFromUniformVersion(this.uniform.draft_version)
            }
            return data
        },
        makeFormDataFromUniformVersion(uniformVersion) {
            const uv = JSON.parse(JSON.stringify(uniformVersion))
            let formData = {
                version_number: uv.version_number,
                companies: [this.uniform.company_id],
                name: this.uniform.name,
                uniform_items: [],
                manager_signature_required: uv.manager_signature_required,
                assign_to: uv.draft_data['assign_to'],
                positions: uv.draft_data['positions'] || [], // TODO KB upon reapplying this, filter to make sure we still have access to all these positions
                is_major_version: uv.is_major_version,
                collection_method: this.uniform.collection_method,
                block_ee_until_completion: this.uniform.block_ee_until_completion,
                include_in_onboarding: this.uniform.include_in_onboarding,
                is_draft: true,
                batch_key: '',
                publish_version: false,
                draft_data: uv.draft_data
            }
            uv.item_data.forEach(i => {
                let itemData = {
                    name: i.name,
                    categories: [],
                    is_custom: !this.defaultItems.includes(i.name),
                    is_enabled: true,
                }
                i.categories.forEach(c => {
                    let categoryData = {
                        name: c.name,
                        sizes: [],
                        has_colors: false,
                        colors: [],
                        is_custom: !this.defaultCategories.includes(c.name),
                        is_enabled: true
                    }
                    c.sizes.forEach(s => {
                        categoryData.sizes.push({
                            name: s.name,
                        })
                    })
                    c.colors.forEach(color => {
                        categoryData.colors.push({
                            name: color.name,
                        })
                        categoryData.has_colors = true
                    })
                    itemData.categories.push(categoryData)
                })
                formData.uniform_items.push(itemData)
            })
            return formData
        },
        makeItemFormData(isCustom=false) {
            let data = {
                name: '',
                categories: [],
                is_custom: isCustom,
                is_enabled: true
            }
            return data
        },
        makeCategoryFormData(isCustom=false) {
            let data = {
                name: '',
                sizes: [],
                has_colors: false,
                colors: [],
                is_custom: isCustom,
                is_enabled: true,
            }
            return data
        },
        makeSizeFormData() {
            let data = {
                name: '',
            }
            return data
        },
        makeSizesFormData() {
            let data = []
            for (let i = 0; i < 3; i++) {
                data.push(this.makeSizeFormData());
            }
            return data
        },
        makeColorFormData() {
            let data = {
                name: '',
            }
            return data
        },
        makeColorsFormData() {
            let data = []
            for (let i = 0; i < 3; i++) {
                data.push(this.makeColorFormData());
            }
            return data
        },
        makeDraftData() {
            // Save info about the draft for resumption that would not otherwise be saved on the model on a draft save
            let draftData = {
                step: this.step,
            }
            if (this.addDocMode) {
                draftData.assign_to = this.formData.assign_to,
                draftData.positions = this.formData.positions
            }
            return draftData
        },
        getNextStep(step) {
            if (this.navigatedFromSummary && step != 'uniform-assignment') {
                this.navigatedFromSummary = false
                return 'uniform-summary'
            }
            let steps = this.addVersionMode ? VERSION_STEPS : STEPS
            if (STEP_METADATA[step]['nextStep']) {
                return STEP_METADATA[step]['nextStep']
            } else {
                let stepIdx = steps.indexOf(step)
                if (stepIdx == -1 || stepIdx + 1 >= steps.length) {
                    return null
                } else {
                    let nextStep = steps[stepIdx + 1]
                    if (nextStep == 'uniform-positions' && this.formData.assign_to == 'individuals') {
                        nextStep = this.getNextStep(nextStep)
                    }
                    return nextStep
                }
            }
        },
        getPrevStep(step) {
            if (this.navigatedFromSummary && step != 'uniform-assignment') {
                this.navigatedFromSummary = false
                return 'uniform-summary'
            }
            let steps = this.addVersionMode ? VERSION_STEPS : STEPS
            if (STEP_METADATA[step]['prevStep']) {
                return STEP_METADATA[step]['prevStep']
            } else {
                let stepIdx = steps.indexOf(step)
                if (stepIdx <= 0) {
                    return null
                } else {
                    let prevStep = steps[stepIdx - 1]
                    if (prevStep == 'uniform-positions' && this.formData.assign_to == 'individuals') {
                        prevStep = this.getPrevStep(prevStep)
                    }
                    return prevStep
                }
            }
        },
        goToStep(step) {
            this.step = step
        },
        goToNextStep() {
            this.step = this.getNextStep(this.step)
        },
        goToPrevStep() {
            this.step = this.getPrevStep(this.step)
        },
        goToStepFromSummary(step) {
            this.navigatedFromSummary = true
            this.goToStep(step)
        },
        onNextStep() {
            this.cleanFormDataBeforeStep()
            this.validateFormData().then(validated => {
                if (!validated) {
                    return false
                } else {
                    if (STEP_METADATA[this.step]['saveOnNext']) {
                        this.formData.publish_version = false
                        this.formData.draft_data = this.makeDraftData()
                        this.formData.draft_data['step'] = this.step // TODO KB this should ideally be nextStep instead of this.step, but we have a problem with doing getNextStep more than once + navigatedFromSummary logic
                        // if (this.step == 'uniform-item-categories') {
                        //     this.formData.draft_data['editingItemName'] = this.editingItem['name']
                        // }
                        this.saveUniform({callback: (() => {this.goToNextStep(); this.prepFormDataAfterStep()})})
                    } else {
                        this.goToNextStep()
                        this.prepFormDataAfterStep()
                    }
                }
            })
        },
        onPrevStep() {
            // first check if we should exit out of this component
            if (this.step == STEPS[0]) {
                this.$emit('prev') // return to doc type selector in parent component
                return
            }
            if (this.step == VERSION_STEPS[0] && this.addVersionMode) {
                this.exitToDocuments() // return to Settings / HR Documents table
                return
            }
            // we're just going back to a previous step within this component; first call method to clean up form data
            this.cleanFormDataBeforeStep()
            // actually go to the prev step
            this.goToPrevStep()
            this.prepFormDataAfterStep()
        },
        exitToDocuments() {
            this.$router.push({name: 'settings-hr-documents'})
        },
        cleanFormDataBeforeStep() {
            // do some operations on formData to clean up data *before* moving from a step (called on prev and next)
            if (this.step == 'uniform-add-items') {
                // delete any items that were unchecked (but not actually deleted from formData until step change)
                this.formData.uniform_items = this.formData.uniform_items.filter(i => !i.name == '' && i.is_enabled)
                // Order remaining items
                this.formData.uniform_items.sort((a, b) => {
                    let idxA = this.defaultItems.indexOf(a.name)
                    let idxB = this.defaultItems.indexOf(b.name)
                    if (idxA == idxB == -1) {
                        return 0
                    } else if (idxA == -1) {
                        return 1
                    } else if (idxB == -1) {
                        return -1
                    } else {
                        return idxA - idxB
                    }
                })
            }
            if (this.step == 'uniform-item-categories') {
                // delete any categories that were unchecked (but not actually deleted from formData until step change)
                this.editingItem.categories = this.editingItem.categories.filter(c => !c.name == '' && c.is_enabled)
            }
            // delete any blank sizes or categories
            if (this.step == 'uniform-item-sizes-colors') {
                for (let category of this.editingItem.categories) {
                    category.sizes = category.sizes.filter(s => !s.name == '')
                    category.colors = category.colors.filter(c => !c.name == '')
                }
            }
        },
        prepFormDataAfterStep() {
            // prep some formData *after* moving to a step (called on prev and next)
            if (this.step == 'uniform-item-sizes-colors') {
                for (let category of this.editingItem.categories) {
                    // populate 3 blank categories in the UI for an item without any categories yet
                    if (category.sizes.length == 0) {
                        category.sizes = this.makeSizesFormData()
                    }
                }
            }
            // clear editingItem if we're no longer at a step where it's applicable
            if (!(this.step == 'uniform-item-categories' || this.step == 'uniform-item-sizes-colors')) {
                this.editingItem = {}
            }
        },
        async validateFormData() {
            // validate form data on each next step
            this.formErrors = []
            this.errors = {}
            if (this.step == 'uniform-companies') {
                if (!this.formData.companies.length) {
                    this.formErrors = ['At least one company is required.']
                    return false
                }
            }
            if (this.step == 'uniform-name') {
                if (!this.formData.name) {
                    this.errors['name'] = ['This field is required.']
                    return false
                }
                try {
                    let resp = await this.$api.post(`/clients/${this.client.id}/uniforms?action=validate-name`, this.getFormData())
                    return true
                } catch(errors) {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    return false
                }
            }
            if (this.step == 'uniform-add-items') {
                if (!this.formData.uniform_items.length) {
                    this.formErrors = ['At least one clothing item is required.']
                    return false
                }
                // Make sure we don't have duplicate item names
                let seen = new Set();
                let hasDuplicates = this.formData.uniform_items.some((i) => {
                    return seen.size === seen.add(i.name).size;
                });
                if (hasDuplicates) {
                    this.formErrors = ['Cannot have duplicate clothing items.']
                    return false
                }
            }
            if (this.step == 'uniform-setup-items') {
                if (!this.areAllItemsCompleted()) {
                    this.formErrors = ['Set up at least one size for each item before continuing.']
                    return false
                }
            }
            if (this.step == 'uniform-item-categories') {
                if (!this.editingItem.categories.length) {
                    this.formErrors = ['At least one size category is required.']
                    return false
                }
            }
            if (this.step == 'uniform-settings') {
                if (this.formData.manager_signature_required === undefined) {
                    this.formErrors = ['Manager signature settings are required to continue.']
                    return false
                }
            }
            if (this.step == 'uniform-collection-settings') {
                if (this.formData.include_in_onboarding === undefined && this.formData.positions.length) {
                    this.formErrors = ['Select collection settings for new employees to continue.']
                    return false
                }
                if (this.formData.collection_method == '') {
                    this.formErrors = ['Select collection settings for active employees to continue.']
                    return false
                }
                if (this.formData.collection_method == 'employee' && this.formData.block_ee_until_completion === undefined) {
                    this.formErrors = ['Select collection settings for active employees to continue.']
                    return false
                }
            }
            return true
        },
        getFormData() {
            this.cleanFormDataBeforeStep()
            return this.formData
        },
        getFormLabel(key, val) {
            try {
                return FORM_OPTIONS[key][val]['label']
            } catch(e) {
                return ''
            }
        },
        getFormDescription(key, val) {
            try {
                return FORM_OPTIONS[key][val]['description']
            } catch(e) {
                return ''
            }
        },
        saveAsDraft() {
            this.formData.publish_version = false
            // if (this.areAllItemsComplete()) {
                // we *may* need to do the following:
                // if all uniforms items are in a valid complete state, then we can
                // actually save the model
                // if not, we just send invalid partial data to be stored in a json blob
            // }
            this.formData.draft_data = this.makeDraftData()
            this.saveUniform({callback: this.exitToDocuments})
        },
        saveAndPublish() {
            this.formData.publish_version = true;
            this.saveUniform({callback: this.exitToDocuments})
        },
        saveUniform(options) {
            this.validateFormData().then(validated => {
                if (!validated) {
                    return false
                } else {
                    this.$store.dispatch('START_LOADING')
                    if (this.addVersionMode) {
                        this.$api.post(`/clients/${this.client.id}/uniforms/${this.uniformId}/versions`, this.getFormData()).then(resp => {
                            this.$store.dispatch('STOP_LOADING')
                            if (options['callback']) {
                                options['callback']()
                            }
                        }).catch(errors => {
                            this.$store.dispatch('STOP_LOADING')
                            this.errors = errors
                            this.formErrors = errors.__all__
                            this.onError()
                        })
                    } else if (this.addDocMode) {
                        this.$api.post(`/clients/${this.client.id}/uniforms`, this.getFormData()).then(resp => {
                            this.formData.batch_key = resp['batch_key']
                            this.$store.dispatch('STOP_LOADING')
                            if (options['callback']) {
                                options['callback']()
                            }
                        }).catch(errors => {
                            this.$store.dispatch('STOP_LOADING')
                            this.errors = errors
                            this.formErrors = errors.__all__
                            this.onError()
                        })
                    }
                }
            })
        },
        toggleDefaultItemEnabled(itemName) {
            // NB: rather than remove form data for default items
            // (because it would be odd to uncheck a item and have that delete all previously inputted form data for the item without yet clicking continue)
            // instead set is_enabled to false and only clear the form data in cleanFormDataBeforeStep when going to the next/prev step
            let itemToToggle = this.formData.uniform_items.find(i => i.is_custom == false && i.name == itemName)
            if (itemToToggle) {
                itemToToggle.is_enabled = !itemToToggle.is_enabled
            } else {
                let itemFormData = this.makeItemFormData()
                itemFormData['name'] = itemName
                this.formData.uniform_items.push(itemFormData)
            }
        },
        isDefaultItemEnabled(itemName) {
            return this.formData.uniform_items.find(i => i.is_custom == false && i.name == itemName && i.is_enabled)
        },
        isItemCompleted(item) {
            let complete = true
            if (item.categories.length == 0) {
                complete = false
            }
            item.categories.forEach(c => { if (c.sizes.length == 0) { complete = false }})
            return complete
        },
        areAllItemsCompleted() {
            let foundIncompleteItem = false
            this.formData.uniform_items.forEach(i => {
                if (!this.isItemCompleted(i)) {
                    foundIncompleteItem = true
                }
            })
            return !foundIncompleteItem
        },
        itemOptionsAsText(item) {
            let itemOptionsAsTextArr = []
            for (let category of item.categories) {
                let colorNames = []
                for (let c of category.colors) {
                    colorNames.push(c.name)
                }
                let sizeNames = []
                for (let s of category.sizes) {
                    sizeNames.push(s.name)
                }
                let sizesAsText = ""
                if (sizeNames.length > 1) {
                    sizesAsText = sizeNames[0] + "-" + sizeNames[sizeNames.length-1]
                } else if (sizeNames.length == 1) {
                    sizesAsText = sizeNames[0]
                } else {
                    sizesAsText = "No sizes defined"
                }
                itemOptionsAsTextArr.push(`${category.name}: ${colorNames.join('/')} ${sizesAsText}`)
            }
            return itemOptionsAsTextArr.join(', ')
        },
        addCustomItem() {
            this.formData.uniform_items.push(this.makeItemFormData(true))
        },
        removeItemAtIndex(idx) {
            this.formData.uniform_items.splice(idx, 1)
        },
        editItem(item) {
            this.formErrors = []
            this.step = 'uniform-item-categories'
            this.editingItem = item
        },
        toggleCategoryEnabled(item, categoryName) {
            // NB: rather than remove form data for default categories
            // (because it would be odd to uncheck a category and have that delete all previously inputted form data for the category without yet clicking continue)
            // instead set is_enabled to false and only clear the form data in cleanFormDataBeforeStep when going to the next/prev step
            let categoryToToggle = item.categories.find(c => c.name == categoryName)
            if (categoryToToggle) {
                categoryToToggle.is_enabled = !categoryToToggle.is_enabled
            } else {
                let categoryFormData = this.makeCategoryFormData()
                categoryFormData['name'] = categoryName
                item.categories.push(categoryFormData)
            }
        },
        isCategoryEnabled(item, categoryName) {
            return item.categories.find(c => c.name == categoryName && c.is_enabled)
        },
        addCustomCategory(item) {
            item.categories.push(this.makeCategoryFormData(true))
        },
        removeCategoryAtIndex(item, idx) {
            item.categories.splice(idx, 1)
        },
        addSize(category) {
            category.sizes.push(this.makeSizeFormData())
        },
        removeSizeAtIndex(category, idx) {
            category.sizes.splice(idx, 1)
        },
        addColor(category) {
            category.colors.push(this.makeColorFormData())
        },
        removeColorAtIndex(category, idx) {
            category.colors.splice(idx, 1)
        },
        makeItemPreviewSizeOptGroups(item) {
            let optGroups = []
            for (let category of item.categories) {
                let optGroup = {
                    label: category.name,
                    options: []
                }
                for (let size of category.sizes) {
                    optGroup.options.push({text: `${category.name} ${size.name}`, value: `${category.name}-${size.name}`})
                }
                optGroups.push(optGroup)
            }
            return optGroups
        },
        onItemPreviewSelect(item, event) {
            if (event) {
                let [category, size] = event.split('-')
                item.previewing_category = category
                item.previewing_size = size
            } else {
                item.previewing_category = null
                item.previewing_size = null
                item.previewing_color = null
            }
        }
    }
}
</script>
