<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body">
                <div class="container-fluid">
                    <div class="flex-body pb-2">
                        <transition name="fade" mode="out-in">
                            <router-view class="view" ref="stepComponent" :client="client" />
                        </transition>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'

import moment from 'moment'

export default {
    props: ['client'],
    data() {
        return {
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tips Allocator')
    },
    computed: {
        taskId() {
            return this.$route.params.taskId
        },
    },
    methods: {
    },
}
</script>
