<template>
    <div class="page-actions-filter" v-if="possibleFilters.length">
        <div class="page-actions-filterbuttons">

            <button type="button" v-if="hasQuickFilter" href class="btn btn-icon btn-icon-outline btn-toggle btn-toggle--warnings" :class="{'btn-toggle-active': quickFilterOn}" aria-label="Quick Filter: Warnings" @click.prevent="toggleQuickFilter()">
                <i aria-hidden="true" class="fas fa-exclamation-triangle" title="Quick Filter: Warnings"></i>
            </button>

            <button type="button" class="btn btn-icon btn-icon-outline btn-toggle btn-toggle--filters" :class="{'btn-toggle-active': activeFilters.length && !onlyQuickFilterIsActive}" title="Open Filters" aria-label="Open Filters" @click.prevent="openFilterModal()">
                <span title="Filters">
                    <svg aria-hidden="true" class="custom-icon" width="20" height="20" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <defs></defs>
                        <g id="icon-filters" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group" transform="translate(2.000000, 4.000000)" fill="#000000" class="svg-fill">
                        <rect id="icon-filters-bar1" x="0" y="0" width="16" height="2" rx="1"></rect>
                        <rect id="icon-filters-bar2" x="3" y="5" width="10" height="2" rx="1"></rect>
                        <rect id="icon-filters-bar3" x="5" y="10" width="6" height="2" rx="1"></rect>
                        </g>
                        </g>
                    </svg>
                </span>
            </button>
        </div>

        <div class="page-actions-activefilters">
            <active-filters-list
                :in-modal="false"
                :possible-filters="possibleFilters"
                :filter-values="activeFilterValues"
                :filter-value-counts="filterValueCounts"
                :quick-filter-on="quickFilterOn"
                @remove="removeFilter"
                @clear="clearFilters"
            />

        </div>

        <filter-modal
            :possible-filters="possibleFilters"
            :filter-values="activeFilterValues"
            :quick-filter-on="quickFilterOn"

            :filteredData="filteredData"
            :data="data"
            :headers="headers"
            :sections="sections"
            :managers="managers"

            v-if="!useOverlayFilterEditor && editorOpen"
            @close="editorOpen = false"
            @filter="onModalFilters"
        />

        <portal to="modal-child" v-if="useOverlayFilterEditor && editorOpen">
            <div class="modal-header">
                <div class="d-flex w-100 flex-wrap">
                    <div class="modal-header-titlebar py-hf">
                        <h3 class="modal-title btnheight-min">
                            Filters
                        </h3>
                    </div>
                    <button type="button" class="close" aria-label="Close" @click.prevent="closeFilterModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-header-buttonbar py-hf">
                    </div>
                </div>
            </div>
            <div class="modal-body d-flex flex-column flex-grow-1">
                <div class="flex-grow-1 modal-scroll-area">
                    <filter-editor
                        :possible-filters="possibleFilters"
                        :filter-values="activeFilterValues"
                        :quick-filter-on="quickFilterOn"

                        :filteredData="filteredData"
                        :data="data"
                        :headers="headers"
                        :sections="sections"
                        :managers="managers"

                        @close="closeFilterModal"
                        @filter="onModalFilters"
                    />
                </div>
            </div>
        </portal>

    </div>
</template>

<script>
import ActiveFiltersList from './ActiveFiltersList'
import FilterModal from './FilterModal'
import FilterEditor from './FilterEditor'
import {rowMatchesFilters, EDITORS, objectToQueryString} from './filter-utils'

export default {
    props: ['sections', 'headers', 'data', 'filteredData', 'managers', 'useOverlayFilterEditor', 'encodeFiltersToUrl'],
    components: {ActiveFiltersList, FilterModal, FilterEditor, },
    computed: {
        possibleFilters() {
            const filters = []

            if (this.managers) {
                const filter = {
                    name: 'Managers',
                    filterValues: [],
                    idx: -1, // This filter is not pulled from any column so don't worry about it.
                    id: '_managers',
                    type: 'managers',
                    filterValueEditor: null,
                }

                filters.push(filter)
            }

            this.headers.forEach((h, idx) => {
                if (!h.isFilterable) {return}
                const filter = {
                    name: h.label,
                    filterValues: [],
                    idx: idx,
                    id: h.id,
                    type: h.type,
                    filterValueEditor: h.filterValueEditor ? h.filterValueEditor : null,
                }

                filters.push(filter)
            })

            this.data.forEach(section => {
                section.forEach(row => {
                    filters.forEach(filter => {
                        if (filter.type == 'managers') {
                            const managerIds = row.object.company_security_profile_ids || []

                            // For each managerId that this row has, see if we want to add that option
                            managerIds.forEach(cspId => {
                                const csp = this.managers[cspId] ? this.managers[cspId] : null  // NB: These are not full on CSPs. They are trimmed objects created by /api/client/[clientId]
                                if (csp) {
                                    if (!filter.filterValues.find(fv => fv.value == csp.security_profile_id)) {
                                        filter.filterValues.push({
                                            value: csp.security_profile_id,
                                            label: csp.full_name,
                                        })
                                    }
                                }
                            })
                        }
                        else if (filter.type == 'warning') {
                            const warnings = (row.object.warnings || [])
                            if (warnings.length > 0) {
                                warnings.forEach(w => {
                                    if (!filter.filterValues.find(v => v.value == w.warning_type)) {
                                        filter.filterValues.push({
                                            value: w.warning_type,
                                            label: w.warning_type_pretty,
                                        })
                                    }
                                })
                            }
                        }
                        else if (filter.type == 'checkmark' && !filter.filterValues.length) {
                            filter.filterValues.push({
                                value: true,
                                label: 'Yes',
                            })

                            filter.filterValues.push({
                                value: false,
                                label: 'No',
                            })
                        }
                        else {
                            let val = (row.cells[filter.idx] && row.cells[filter.idx].value) ? row.cells[filter.idx].value : row.cells[filter.idx]
                            if (filter.filterValueEditor) {
                                const editor = EDITORS[filter.filterValueEditor]
                                val = editor(val)
                            }
                            const label = val

                            if (val && !filter.filterValues.find(fv => fv.value == val)) {
                                filter.filterValues.push({
                                    value: val,
                                    label: label,
                                })
                            }

                            if (row.subrows) {
                                row.subrows.forEach(srow => {
                                    const val = (srow[filter.idx] && srow[filter.idx].value) ? srow[filter.idx].value : srow[filter.idx]
                                    const label = val

                                    if (val && !filter.filterValues.find(fv => fv.value == val)) {
                                        filter.filterValues.push({
                                            value: val,
                                            label: label,
                                        })
                                    }
                                })
                            }
                        }
                    })
                })
            })

            // In case we have any active filters but the row matching that filter disappeared, we should hold onto those values
            Object.keys(this.activeFilterValues).forEach(filterId => {
                const filter = filters.find(f => f.id == filterId)
                const activeValues = this.activeFilterValues[filterId]

                if (!activeValues.length) {return}

                activeValues.forEach(fv => {
                    if (!filter.filterValues.find(x => x.value == fv.value)) {
                        filter.filterValues.push({
                            value: fv.value,
                            label: fv.label,
                        })
                    }
                })
            })

            filters.forEach(f => {
                f.filterValues.sort((a, b) => {
                    if (a.label === b.label) {
                        return 0
                    }

                    return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
                })
            })

            return filters
        },
        activeFilters() {
            // This method returns an Array of active filters (as opposed to our internally stored mapping-by-ID) that is consumable by our external users (such as index.vue)
            const activeFilters = []
            Object.keys(this.activeFilterValues).forEach(filterId => {
                if (!this.activeFilterValues[filterId].length) {return}
                const filter = this.possibleFilters.find(f => f.id == filterId)
                activeFilters.push({
                    name: filter.name,
                    filterValues: this.activeFilterValues[filterId],
                    idx: filter.idx,
                    id: filter.id,
                    type: filter.type,
                    filterValueEditor: filter.filterValueEditor ? filter.filterValueEditor : null,
                })
            })

            return activeFilters
        },
        onlyQuickFilterIsActive() {
            if (this.activeFilters.length != 1) {return false}
            return (this.activeFilters[0].type == 'warning')
        },
        hasQuickFilter() {
            return this.possibleFilters.filter(f => f.type == 'warning').length ? true : false
        },
        filterValueCounts() {
            const filters = []
            const counts = {}
            Object.keys(this.activeFilterValues).forEach(filterId => {
                if (!this.activeFilterValues[filterId].length) {return}
                const filter = this.possibleFilters.find(f => f.id == filterId)

                counts[filter.id] = {}

                this.activeFilterValues[filterId].forEach(filterValue => {
                    filters.push({
                        name: filter.name,
                        filterValues: [filterValue],
                        idx: filter.idx,
                        id: filter.id,
                        type: filter.type,
                        filterValueEditor: filter.filterValueEditor ? filter.filterValueEditor : null,
                    })

                    counts[filter.id][filterValue.value] = 0
                })
            })

            this.filteredData.forEach(section => {
                section.forEach(row => {
                    filters.forEach(filter => {
                        if (rowMatchesFilters(row, [filter], this.managers)) {
                            counts[filter.id][filter.filterValues[0].value] += 1
                        }
                    })
                })
            })

            return counts
        },
    },
    data() {
        const filterValues = {}
        if (this.managers) {
            filterValues['_managers'] = []
        }

        this.headers.forEach(h => {
            if (!h.isFilterable) {return}
            filterValues[h.id] = []
        })

        return {
            activeFilterValues: filterValues,
            editorOpen: false,
            quickFilterOn: false,
        }
    },
    methods: {
        toggleQuickFilter() {
            const filter = this.possibleFilters.find(f => f.type == 'warning')
            if (!this.activeFilterValues.hasOwnProperty(filter.id)) {
                this.activeFilterValues[filter.id] = []
            }

            if (this.quickFilterOn) {
                this.activeFilterValues[filter.id] = []
                this.quickFilterOn = false
            } else {
                this.activeFilterValues[filter.id] = []
                if (filter.filterValues.length) {
                    filter.filterValues.forEach(fv => {
                        this.activeFilterValues[filter.id].push(fv)
                    })
                }
                else {
                    // In this case no rows have warnings, so we simply add a bogus filter value that no row will match
                    this.activeFilterValues[filter.id].push({
                        value: 'fake-filter-value-that-will-remove-all-rows',
                        label: ''
                    })
                }
                this.quickFilterOn = true
            }

            this.onFilter(this.activeFilterValues)
        },
        removeFilter({filterId, filterValue}) {
            this.activeFilterValues[filterId] = this.activeFilterValues[filterId].filter(fv => fv.value != filterValue)
            this.onFilter(this.activeFilterValues)
        },
        clearFilters() {
            this.possibleFilters.forEach(f => {
                this.activeFilterValues[f.id] = []
            })
            this.quickFilterOn = false

            this.onFilter(this.activeFilterValues)
        },
        onModalFilters({filterValues, quickFilterOn}) {
            this.quickFilterOn = quickFilterOn
            this.onFilter(filterValues)
        },
        onFilter(filterValues) {
            this.activeFilterValues = filterValues
            if (this.encodeFiltersToUrl) {
                const numFilters = Object.values(filterValues).reduce((sum, arr) => sum + arr.length, 0)
                if (numFilters) {
                    let qs1 = objectToQueryString({'numFilters': numFilters, 'filters': this.activeFilterValues})
                    history.replaceState(
                        {},
                        null,
                        `${this.$route.path}?${qs1}`
                    )
                } else {
                    history.replaceState(
                        {},
                        null,
                        this.$route.path
                    )
                }
            }
            this.$emit('filter', this.activeFilters)
        },
        openFilterModal() {
            this.editorOpen = true
            this.$emit('filter-edit-open')
        },
        closeFilterModal() {
            this.editorOpen = false
            this.$emit('filter-edit-close')
        },
        addFilters(filterValues) {
            this.activeFilterValues = Object.assign({}, this.activeFilterValues, filterValues)
            this.$emit('filter', this.activeFilters)
        }
    },

}

</script>
