<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container my-4">
                        <div class="row">
                            <div class="col-6">
                                <h3>Adoption/Engagement Metrics by Client</h3>

                                <form @submit.prevent class="mt-4">
                                    <form-errors :errors="formErrors" :errorsHtml="formErrorsHtml" />

                                    <div class="form-row">
                                        <div class="col-6">
                                            <form-input label="Client Number" v-model="formData.client_code" :errors="errors.client_code" type="text" />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col">
                                            <button class="btn btn-outline-primary" @click="queryData">Query</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="debug-output" v-text="debugOutput" v-if="debugOutput"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .debug-output {
        background-color: #EEEEEE;
        color: black;
        white-space: pre;
        padding: 1em;
        font-family: monospace;
        font-size: 0.8em;
    }
</style>

<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'


export default {
    mixins: [FormMixin, ],
    data() {
        return {
            formRules: {
                client_code: {presence: {allowEmpty: false},},
            },
            formErrorsHtml: [],
            formData: this.makeFormData(),
            debugOutput: '',
        }
    },
    methods: {
        makeFormData() {
            return {
                client_code: '',
            }
        },
        queryData() {
            if (!this.validate()) {
                return
            }
            this.$store.dispatch('START_LOADING')
            api.post(`/admin/analytics/client-adoption`, this.getFormData()).then((resp) => {
                this.debugOutput = resp.data
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrorsHtml = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>
