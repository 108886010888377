<template>
    <div class="row">
        <div class="col-12">
            <form @submit.prevent="onSubmit">
                <form-errors :errors="formErrors" />

                <p>Please confirm that your residential address is correct.</p>

                <div class="mui-textfield">
                    <span class="textfield">
                        {{ formData.address_1 }}
                        <br />
                        {{ formData.address_2 }}
                        <br v-if="formData.address_2" />
                        {{ formData.city }}, {{ formData.state }} {{ formData.postal_code }}
                    </span>
                    <label>Resident Address</label>
                </div>

                <router-link :to="{name: 'employee-profile-personal-info', params: {hashedCompanyId: $route.params.hashedCompanyId}, query: {editAddress: 1}}">
                    Change residential address
                </router-link>

                <portal to="tax-withholding-edit-modal-footer">
                    <button-row stacked>
                        <button type="button" @click.prevent="onPrev()" class="btn btn-outline-primary">Cancel</button>
                        <button type="button" @click.prevent="onSubmit()" class="btn btn-primary">Continue</button>
                    </button-row>
                </portal>
            </form>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ButtonRow from '@/components/ButtonRow'
import api from '@/api'

export default {
    mixins: [FormMixin],
    components: {ButtonRow, },
    props: ['employee', 'employeeUpdate', 'taxWithholding', 'prev'],
    watch: {
        employee() {
            this.reset()
        },
        employeeUpdate() {
            this.reset()
        },
        taxWithholding() {
            this.reset()
        }
    },
    computed: {},
    data() {
        const data = {
            formData: this.makeFormData(),
            formRules: {
                address_1: {presence: {allowEmpty: false},},
                city: {presence: {allowEmpty: false},},
                state: {presence: {allowEmpty: false},},
                postal_code: {presence: {allowEmpty: false},},
            },
            formLabels: {
                postal_code: 'Zip Code',
            },
        }
        return data
    },
    methods: {
        makeFormData() {
            let source = this.employee
            let formData = {
                action: 'confirm-address',
                address_1: source.address_1,
                address_2: source.address_2,
                city: source.city,
                state: source.state,
                postal_code: source.postal_code,
            }
            return formData
        },
        onSubmit() {
            let validateFields = ['address_1', 'city', 'state', 'postal_code', 'phone']
            if (!this.validate(validateFields)) {
                return
            }

            this.$store.dispatch('START_LOADING')
            if (this.employeeUpdate) {
                this.patchEmployeeUpdate(this.employeeUpdate.id)
            } else {
                // If employeeUpdate wasn't passed in, that means we need to create one first before we patch it with form data
                api.post(`/me/ess/companies/${this.employee.company_id}/updates`, {}).then((resp) => {
                    this.patchEmployeeUpdate(resp['id'])
                }).catch((errors) => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                    this.$store.dispatch('STOP_LOADING')
                })
            }
        },
        onPrev() {
            this.$emit('prev')
        },
        patchEmployeeUpdate(employeeUpdateId) {
            this.$store.dispatch('START_LOADING')
            api.patch(`/me/ess/companies/${this.employee.company_id}/updates/${employeeUpdateId}/tax-withholdings/${this.taxWithholding.id}`, this.getFormData()).then((resp) => {
                this.$emit('patch', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    }
}
</script>