<template>
<info-widget :collapsible="true" :is-expanded="isExpanded" @toggle_expand="isExpanded = !isExpanded" >
    <template v-slot:title>Version {{ version.version }}</template>

    <div class="card-body">
        <slot></slot>
    </div>
</info-widget>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'

export default {
    components: {InfoWidget, },
    props: ['version', ],
    data() {
        return {
            isExpanded: false,
        }
    },
}
</script>

