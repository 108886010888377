<template>
    <modal :autoshow="true" size="xl" :height-max="true" ref="modal" @close="$emit('close')" :is-closeable="isCloseable" :has-child-overlay="true">
        <template v-slot:title>
            Add Security User:
            <span class="text-thin text-nowrap" v-if="mode == 'user-info'">User Information</span>
            <span class="text-thin text-nowrap" v-if="mode == 'role'">Security Role</span>
            <span class="text-thin text-nowrap" v-if="mode == 'companies'">Companies</span>
            <span class="text-thin text-nowrap" v-if="mode == 'positions'">Access</span>
            <span class="text-thin text-nowrap" v-if="mode == 'locations'">Access</span>
            <span class="text-thin text-nowrap" v-if="mode == 'summary'">Summary</span>
        </template>

        <template v-slot:subheading>
            <div v-if="mode != 'user-info'">
                <div>
                    <div class="p-1 unpad-x bg-150 rounded">
                        <div><span class="text-muted">User:</span> {{ formData.first_name }} {{ formData.last_name }}</div>
                    </div>
                </div>
            </div>
        </template>

        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()" v-if="mode == 'user-info'">
                <div class="form-row">
                    <div class="col-12">
                        <form-input v-model="formData.first_name" :errors="errors.first_name" placeholder="First Name" type="text" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-12">
                        <form-input v-model="formData.last_name" :errors="errors.last_name" placeholder="Last Name" type="text" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-12">
                        <form-input v-model="formData.email" :errors="errors.email" placeholder="Email" type="email" />
                    </div>
                </div>
            </form>

            <div v-if="mode == 'role'">
                <role-selector
                    :role-options="roleOptions"
                    :my-role-options="myRoleOptions"
                    v-model="formData.role"
                    :errors="errors.role"
                />
            </div>

            <div v-if="mode == 'companies'">
                <p>Select the companies where this user will be a <strong>{{ currentRolePretty }}:</strong></p>

                <company-selector
                    :client="client"
                    :companies="client.companies"
                    v-model="formData.companies"
                    @filter-edit-open="$refs.modal.openModalChild"
                    @filter-edit-close="$refs.modal.closeModalChild"
                />
            </div>

            <div v-if="mode == 'positions'">
                <p>Select the positions that this user should have access to as a <strong>{{ currentRolePretty }}</strong>.</p>

                <position-selector
                    :client="client"
                    :client-positions="clientPositions"
                    v-model="formData.positions"
                    @filter-edit-open="$refs.modal.openModalChild"
                    @filter-edit-close="$refs.modal.closeModalChild"
                />
            </div>

            <div v-if="mode == 'locations'">
                <p>Select the locations that this user should have access to as a <strong>{{ currentRolePretty }}</strong>.</p>

                <location-selector
                    :client="client"
                    :client-work-locations="clientWorkLocations"
                    v-model="formData.work_locations"
                    @filter-edit-open="$refs.modal.openModalChild"
                    @filter-edit-close="$refs.modal.closeModalChild"
                />
            </div>

            <div v-if="mode == 'summary'" class="mb-1">
                <security-user-modal-summary :company-roles="summaryCompanyRoles" :show-edit-buttons="false" />
            </div>

        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="back()">
                    {{ mode == 'user-info' ? 'Cancel' : 'Back' }}
                </button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">
                    {{ mode == 'summary' ? 'Save Security User' : 'Continue' }}
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import SecurityUserModalMixin from './SecurityUserModalMixin'
import FormMixin from '@/mixins/Form'

export default {
    props: ['client', ],
    mixins: [SecurityUserModalMixin],
    data() {
        return {
            mode: 'user-info',

            formData: this.makeFormData(),
            formRules: {
                first_name: {presence: {allowEmpty: false}, },
                last_name: {presence: {allowEmpty: false}, },
                email: {presence: {allowEmpty: false}, email: true},
                role: {presence: {allowEmpty: false}},
            },
            clientPositions: [],
        }
    },
    methods: {
        makeFormData() {
            const data = {
                first_name: '',
                last_name: '',
                email: '',
                role: '',
                companies: [],
                positions: [],
                work_locations: [],
            }

            return data
        },
        getFormData() {
            const data = {
                email: this.formData.email,
                first_name: this.formData.first_name,
                last_name: this.formData.last_name,
                roles: {},
                positions: {},
                work_locations: {},
            }

            if (this.$store.state.security_roles.POSITION_LEVEL_ROLES.includes(this.formData.role)) {
                this.formData.positions.forEach(pId => {
                    const pos = this.clientPositions.find(p => p.id == pId)
                    data.roles[pos.company_id] = this.formData.role

                    if (!data.positions.hasOwnProperty(pos.company_id)) {
                        data.positions[pos.company_id] = []
                    }
                    data.positions[pos.company_id].push(pos.id)
                })
            }
            else if (this.$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(this.formData.role)) {
                this.formData.work_locations.forEach(wId => {
                    const w = this.clientWorkLocations.find(w => w.id == wId)
                    data.roles[w.company_id] = this.formData.role

                    if (!data.work_locations.hasOwnProperty(w.company_id)) {
                        data.work_locations[w.company_id] = []
                    }
                    data.work_locations[w.company_id].push(w.id)
                })
            }
            else if (this.$store.state.security_roles.COMPANY_LEVEL_ROLES.includes(this.formData.role)) {
                this.formData.companies.forEach(companyId => {
                    data.roles[companyId] = this.formData.role
                })
            }

            return data
        },
        validate() {
            if (this.mode == 'user-info') {
                return FormMixin.methods.validate.call(this, ['first_name', 'last_name', 'email'])
            }

            if (this.mode == 'role' && !this.formData.role) {
                return FormMixin.methods.validate.call(this, ['role'])
            }

            if (this.mode == 'companies' && this.formData.companies.length < 1) {
                this.formErrors.push('At least one company is required.')
                return false
            }

            if (this.mode == 'positions' && this.formData.positions.length < 1) {
                this.formErrors.push('At least one position is required.')
                return false
            }

            if (this.mode == 'locations' && this.formData.work_locations.length < 1) {
                this.formErrors.push('At least one location is required.')
                return false
            }

            return true
        },
        onSubmit() {
            this.errors = []
            this.formErrors = []

            if (!this.validate()) {
                return
            }

            if (this.mode == 'user-info') {
                this.$store.dispatch('START_LOADING')
                this.$api.post(`/clients/${this.client.id}/security-profiles/validate-email`, {'email': this.formData.email}).then(() => {
                    this.$store.dispatch('STOP_LOADING')
                    this.mode = 'role'
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })

                return
            }

            if (this.mode == 'role') {
                if (this.$store.state.security_roles.COMPANY_LEVEL_ROLES.includes(this.formData.role)) {
                    this.mode = 'companies'
                    this.formData.positions = []
                    this.formData.work_locations = []
                }
                else if (this.$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(this.formData.role)) {
                    this.mode = 'locations'
                    this.formData.positions = []
                    this.formData.companies = []
                }
                else if (this.$store.state.security_roles.POSITION_LEVEL_ROLES.includes(this.formData.role)) {
                    this.mode = 'positions'
                    this.formData.work_locations = []
                    this.formData.companies = []
                }
                return
            }

            if (this.mode == 'positions' || this.mode == 'locations' || this.mode == 'companies') {
                this.mode = 'summary'
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/security-profiles`, this.getFormData()).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        back() {
            this.errors = []
            this.formErrors = []
            if (this.mode == 'user-info') {
                this.close()
            }
            else if (this.mode == 'role') {
                this.mode = 'user-info'
            }
            else if (this.mode == 'companies') {
                this.mode = 'role'
            }
            else if (this.mode == 'positions') {
                this.mode = 'role'
            }
            else if (this.mode == 'locations') {
                this.mode = 'role'
            }
            else if (this.mode == 'summary') {
                if (this.$store.state.security_roles.POSITION_LEVEL_ROLES.includes(this.formData.role)) {
                    this.mode = 'positions'
                }
                else if (this.$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(this.formData.role)) {
                    this.mode = 'locations'
                }
                if (this.$store.state.security_roles.COMPANY_LEVEL_ROLES.includes(this.formData.role)) {
                    this.mode = 'companies'
                }
                else {
                    this.mode = 'role'
                }
            }
            else {
                this.close()
            }
        },
    },
}
</script>

