<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ $filters.date(version.starts_on, "MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ $filters.date(version.ends_on, "MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <div>
        <span class="text-semibold">Formula: </span>
        <span>{{ version.formula_id }}</span>
    </div>

    <div>
        <span class="text-semibold">Values: </span>
        <pre v-for="key in Object.keys(version.formula_constants)">{{ key }} =&gt; {{ version.formula_constants[key] }}</pre>
    </div>

</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', ],
    data() {
        return {
            isExpanded: false,
        }
    },
}
</script>
