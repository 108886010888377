<template>
    <modal @close="$emit('close')" ref="modal" :autoshow="true">
        <template v-slot:title>Enhanced ESS Access</template>

        <slot>
            <alert-box type="secondary">
                <p class="mb-0">
                    {{ companiesWithEESS.length }} of {{ client.companies.length }} companies have Enhanced ESS enabled.
                    Employees of companies without access will not be able to edit their own payroll and HR information.
                </p>
                <router-link target="_blank" class="btn-link" :to="{name: 'client-features-enhanced-ess', params: {client: client.id} }" @click="trackLearnMore">
                    <i class="far mr-hf fa-book-open"></i>Learn more about Enhanced ESS
                </router-link>
            </alert-box>

            <h5>Enhanced ESS Enabled</h5>
            <ul class="unindent">
                <li v-for="company in companiesWithEESS" :key="company.id">{{ company.name }}</li>
            </ul>

            <h5>No Enhanced ESS</h5>
            <ul class="unindent">
                <li v-for="company in companiesMissingEESS" :key="company.id">{{ company.name }}</li>
            </ul>

            <button-row>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Close</button>
            </button-row>
        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin, ],
    props: ['client'],
    data() {
        return {}
    },
    computed: {
        companiesWithEESS() {
            return this.client.companies.filter(c => c.has_enhanced_ess)
        },
        companiesMissingEESS() {
            return this.client.companies.filter(c => !c.has_enhanced_ess)
        },
    },
    methods: {
        trackLearnMore() {
            this.$bus.trackEvent('Enhanced ESS: Self-Service Access Learn More', {
                'client_id': this.client.id,
                'client_business_type_rollup_primary': this.client.business_type_rollup_primary,
                'client_business_type_rollup_secondary': this.client.business_type_rollup_secondary,
                'client_business_type_rollup_brand': this.client.business_type_rollup_brand,
            })
        },
    }
}
</script>
