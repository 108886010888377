<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-s py-4">

                        <h1 class="text-center mb-4">Create a Password</h1>

                        <form @submit.prevent="onSubmit" novalidate class="mui-form">
                            <form-errors :errors="formErrors"/>

                            <div class="row mb-2 d-flex align-items-center" v-if="needsRestart">
                                <div class="col text-center">
                                    <router-link :to="{name: 'signup'}" class="btn btn-primary">Restart Registration</router-link>
                                </div>
                            </div>

                            <div v-if="!needsRestart">
                                <p>Create a secure password for your account.</p>

                                <div class="form-row">
                                    <div class="col">
                                        <div class="mui-textfield--postfix">
                                            <form-input placeholder="Enter a password" v-model="formData.password" :errors="errors.password" type="password" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <router-link :to="{name: 'signup-digital-consent'}" class="btn btn-link">Back</router-link>
                                    </div>
                                    <div class="col text-right">
                                        <button type="submit" class="btn btn-primary">Continue</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'

export default {
    mixins: [FormMixin],
    components: {
        FormInput,
        FormErrors,
    },
    data() {
        return {
            formRules: {
                password: {
                    presence: {allowEmpty: false},
                    length: {
                        minimum: 8,
                        maximum: 128,
                    }
                },
            },
            passwordField: {
                type: 'password',
            },
            formData: this.makeFormData(),
            needsRestart: false,
        }
    },
    methods: {
        makeFormData() {
            return {
                password: '',
            }
        },
        togglePassword: function () {
            // toggle password field's visibility
            this.passwordField.type = (this.passwordField.type === 'password') ? 'text':'password'
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/signup/set-password`, this.formData).then((resp) => {
                this.$store.dispatch('SET_SESSION', resp)
                this.$store.dispatch('GET_USER').then(() => {
                    this.$router.push({name: 'index'})
                    this.$store.dispatch('STOP_LOADING')
                }).catch((errors) => {
                    this.$bus.showError(errors.__all__)
                    this.$store.dispatch('STOP_LOADING')
                })
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()

                if (errors.__reason__ == 'session-problem') {
                    this.needsRestart = true
                }
                this.$store.dispatch('STOP_LOADING')
            })

        },
    },
}
</script>
