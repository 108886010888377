<template>
    <sidebar @close="$emit('close')">
        <template v-slot:title>
            {{ fullName }}
        </template>

        <template v-slot:subtitle>
            <span class="counter counter-warnings" v-if="warningCount">
                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                <span class="counter-label">{{ warningCount }}</span>
            </span>
        </template>

        <template v-slot:header>
            <div class="py-qt">
                <!-- Status -->
                <span class="text-muted" v-if="user.num_active_companies > 0">
                    Employee&nbsp;&middot; Active ({{ user.num_active_companies }} <span v-if="user.num_active_companies == 1">Company</span><span v-else="">Companies</span>)
                </span>
                <span class="text-muted" v-else-if="user.onboarding_applications.length > 0">
                    Onboarding&nbsp;&middot; Active ({{ user.onboarding_applications.length }} <span v-if="user.onboarding_applications.length == 1">Company</span><span v-else="">Companies</span>)
                </span>
                <span class="text-danger-dark text-bold text-italic" v-else>
                    Inactive
                </span>
            </div>

            <unlock-widget v-if="user.id && isLocked" :client="client" :user="user" :is-locked="isLocked" @unlocked="onUpdate" />

            <!-- Tabs -->
            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href id="tabLinkMain" role="tab" aria-controls="tabContentMain" :aria-selected="activeTab == 'main'" @click.prevent="activeTab = 'main'">Main</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'warnings'}" href id="tabLinkWarnings" role="tab" aria-controls="tabContentWarnings" :aria-selected="activeTab == 'warnings'" @click.prevent="activeTab = 'warnings'">Warnings</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'access'}" href id="tabLinkUserAccess" role="tab" aria-controls="tabContentUserAccess" :aria-selected="activeTab == 'access'" @click.prevent="activeTab = 'access'">User Access</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href id="tabLinkHistory" role="tab" aria-controls="tabContentHistory" aria-selected="activeTab == 'changelogs'" @click.prevent="activeTab = 'changelogs'">History</a>
                        </li>

                        <li class="nav-item" v-if="isAdmin">
                            <a class="nav-link" :class="{active: activeTab == 'admin'}" href id="tabLinkAdmin" role="tab" aria-controls="tabContentAdmin" aria-selected="activeTab == 'admin'" @click.prevent="activeTab = 'admin'">Admin</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot>
            <div class="tab-content">
                <!-- Tab Pane 1 -->
                <div class="tab-pane" :class="{'show active': activeTab == 'main'}" id="tabContentMain" role="tabpanel" aria-labelledby="tabLinkMain">

                    <div class="card mb-2">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Employee Information</h4>
                            </span>
                        </div>

                        <div class="card-body">
                            <alert-box type="caution" v-if="false && hasBouncedEmail">
                                <strong>Bounced Email:</strong> Onboarding cannot proceed because email cannot be delivered.
                                <a href @click.prevent="editEmail">Update this user's email address to re-try.</a>
                            </alert-box>

                            <div class="mui-textfield">
                                <span class="textfield">{{ fullName }}</span>
                                <label>Employee Name</label>
                            </div>

                            <div class="mui-textfield">
                                <span class="textfield">
                                    <a v-if="user.email" :href="'mailto:' + encodeURI(user.email)">{{ user.email }}</a>
                                    <span v-if="!user.email">---</span>
                                </span>
                                <label>Email Address</label>
                                <div class="field-helper-text mb-0 mt-1"><a href @click.prevent="openEditEmailModal">Change email address</a></div>

                            </div>
                        </div>
                    </div>

                    <!-- Companies widget -->
                    <div class="card mb-2">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Companies</h4>
                            </span>
                            <span v-if="!user.has_user" class="badge badge-lg badge-danger">
                                Not Registered
                            </span>
                        </div>

                        <div class="card-body py-1">
                            <ul class="list-unstyled m-0">
                                <li class="py-1 boxed-li-bottom" v-for="x in companies">
                                    <div class="form-row align-items-baseline pb-1 py-sm-hf">
                                        <div class="col">
                                            <strong>{{ x.company.name }}</strong>
                                            <span v-if="x.is_locked" class="text-danger pl-hf">(LOCKED)</span>
                                        </div>
                                        <div class="col-auto text-right">
                                            <span v-if="user.has_user && !x.is_linked" class="badge badge-lg badge-danger" style="width: 5.5rem;">
                                                Not Linked
                                            </span>
                                            <span v-if="user.has_user && x.is_linked" class="badge badge-lg bg-200 text-muted" style="width: 5.5rem;">
                                                <i class="far fa-check mr-qt"></i> Linked
                                            </span>
                                            <span v-if="!user.has_user">
                                                <span class="badge badge-lg bg-white invisible">
                                                    -
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="mt-nhf mb-1 d-flex justify-content-between">
                                        <span>{{ x.relationship }}</span>
                                        <span v-if="x.employee_number">Emp ID {{ x.employee_number }}</span>
                                    </div>
                                    <div v-if="x.full_name != fullName">
                                        {{ x.full_name }}
                                    </div>
                                    <ul class="list-unstyled text-muted text-small pb-1 m-0">
                                        <li class="pb-qt" v-for="job in x.jobs">
                                            {{ job.position.terminal_value }}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Explanations of statuses -->
                    <div class="card my-2" v-if="hasUnlinkedCompanies || !user.has_user">
                        <div class="card-body py-2" v-if="!user.has_user">
                            <b>Not Registered</b>
                            <br>
                            This person has not yet created their account with GetMyPayStub.
                        </div>

                        <div class="card-body py-2" v-else-if="hasUnlinkedCompanies">
                            <b>Not Linked</b>
                            <br>
                            This person has a GetMyPayStub account, but they have not yet linked it to your organization.
                        </div>
                    </div>
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'warnings'}" id="tabContentWarnings" role="tabpanel" aria-labelledby="tabLinkWarnings">
                    <section class="table-section">
                        <warnings-header category="Dismissed" :expanded="!dismissedWarningsExpanded" :warning-count="dismissedWarnings.length" @click="dismissedWarningsExpanded = !dismissedWarningsExpanded" />
                        <warnings :warnings="dismissedWarnings" :active-warning-id="activeWarningId" v-if="user" v-show="dismissedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                    <section class="table-section">
                        <warnings-header category="Unresolved" :expanded="!unresolvedWarningsExpanded" :warning-count="unresolvedWarnings.length" @click="unresolvedWarningsExpanded = !unresolvedWarningsExpanded" />
                        <warnings :warnings="unresolvedWarnings" :active-warning-id="activeWarningId" v-if="user" v-show="unresolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                    <section class="table-section">
                        <warnings-header category="Resolved" :expanded="!resolvedWarningsExpanded" :warning-count="resolvedWarnings.length" @click="resolvedWarningsExpanded = !resolvedWarningsExpanded" />
                        <warnings :warnings="resolvedWarnings" :active-warning-id="activeWarningId" v-if="user" v-show="resolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>
                </div>

                <!-- Tab Pane - User Access -->
                <div class="tab-pane" :class="{'show active': activeTab == 'access'}" id="tabContentUserAccess" role="tabpanel" aria-labelledby="tabLinkUserAccess">
                    <user-access :client="client" :user="user" :email="user.email" :login-sessions="loginSessions" :is-locked="isLocked" :allow-security-profile-pin-reset="false" :pin-reset-disabled="user.id && user.has_security_profile" @pin_reset="resetPIN()" @send_invite_email="sendESSInvitation(user.email)">
                        <template v-slot:no-account-message>
                            <span v-if="user.email">This person has not yet created their account. Use the button below to send an invitation to their email address on file (<em>{{ user.email }}</em>).</span>
                            <span v-if="!user.email">To invite them to register a Self-Service account, update their email address in payroll first.</span>
                        </template>

                        <template v-slot:pin-reset-disabled-message>
                            <p class="mb-0 pt-1">
                                This user account has Security User access. To reset their PIN, go to <strong>Settings &gt; Security Users</strong>.
                            </p>
                        </template>
                    </user-access>
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'changelogs'}" id="tabContentHistory" role="tabpanel" aria-labelledby="tabLinkHistory">
                    <change-history :client="client" :changelogs="user.changelogs" :active-changelog-id="activeChangelogId" />
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'admin'}" id="tabContentAdmin" role="tabpanel" aria-labelledby="tabLinkAdmin">
                    <div class="form-row">
                        <div class="col-6">
                            <button v-if="employeeId" class="btn btn-outline-primary btn-block mb-2" @click.prevent="getTaxData()">Get Tax Info</button>

                            <button v-if="user.employees.length" class="btn btn-outline-primary btn-block mb-2" @click.prevent="posSyncStatusModalOpened = true">POS Sync Status</button>
                        </div>
                    </div>

                    <div class="my-2" v-if="taxData">
                        <pre><code>{{ taxData }}</code></pre>
                    </div>

                </div>
            </div>

            <pin-reset-modal :user="user" :client="client" v-if="user.id" ref="pinResetModal" />

            <edit-email-modal
                :email="user.email"
                :employee-id="user.employees.length == 1 && user.onboarding_applications.length == 0 ? user.employees[0].id : null"
                :full-name="fullName"
                :client="client"
                @updated="onEmailChange"
                v-if="editEmailModalOpened"
                @close="editEmailModalOpened = false"
            />

            <pos-sync-status-modal
                :employees="user.employees"
                :client="client"
                :full-name="fullName"
                v-if="posSyncStatusModalOpened"
                @close="posSyncStatusModalOpened = false"
            />

        </slot>
    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import PINResetModal from '@/components/PINResetModal'
import UnlockWidget from '@/components/UnlockWidget'
import UserAccess from '@/components/UserAccess'
import Warnings from '@/components/WarningsWidget'
import WarningsHeader from '@/components/WarningsHeaderWidget'
import ChangeHistory from '@/components/ChangeHistory'
import EditEmailModal from '@/components/EditEmployeeEmailModal'
import POSSyncStatusModal from '@/components/POSSyncStatusModal.vue'

export default {
    components: {Sidebar, PinResetModal: PINResetModal, UnlockWidget, UserAccess, Warnings, WarningsHeader, ChangeHistory, EditEmailModal, PosSyncStatusModal: POSSyncStatusModal, },
    props: ['user', 'client', 'tab', 'section', 'itemId', ],
    computed: {
        isAdmin() {
            return this.$store.state.user.is_superuser
        },
        fullName() {
            let fullName = this.user.full_name
            if (!fullName) {
                if (this.user.employees.length) {
                    fullName = this.user.employees[0].full_name ? this.user.employees[0].full_name : ''
                }
                if (this.user.onboarding_applications.length) {
                    fullName = this.user.onboarding_applications[0].full_name ? this.user.onboarding_applications[0].full_name : fullName
                }
            }

            return fullName
        },
        employeeId() {
            if (this.user.employees.length) {
                return this.user.employees[0].id
            }
            return ''
        },
        warningCount() {
            return this.user.warnings.length
        },
        unresolvedWarnings() {
            if (!this.user) {return []}
            return this.user.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && !w.is_resolved)
            })
        },
        resolvedWarnings() {
            if (!this.user) {return []}
            return this.user.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && w.is_resolved)
            })
        },
        dismissedWarnings() {
            if (!this.user) {return []}
            return this.user.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return isDismissed
            })
        },
        companyMap() {
            const companyMap = {};
            (this.client.companies || []).forEach(c => {
                companyMap[c.id] = c
            })
            return companyMap
        },
        companies() {
            const result = []
            const seenEmp = new Set()

            this.user.employees.forEach(e => {
                if (!seenEmp.has(e.company_id)) {
                    result.push({
                        'company': this.companyMap[e.company_id] || {},
                        'relationship': `${e.employment_status} Employee`,
                        'employee_number': e.employee_number,
                        'full_name': e.full_name,
                        'is_locked': e.is_locked,
                        'jobs': e.jobs,
                        'is_linked': (!!e.user_id),
                    })
                    seenEmp.add(e.company_id)
                }
            })

            const seenOA = new Set()
            this.user.onboarding_applications.forEach(o => {
                if (!seenOA.has(o.company_id)) {
                    result.push({
                        'company': this.companyMap[o.company_id],
                        'relationship': 'Onboarding',
                        'full_name': o.full_name,
                        'is_locked': o.is_locked,
                        'jobs': o.jobs,
                        'is_linked': (!!o.user_id),
                    })
                    seenOA.add(o.company_id)
                }
            })

            return result
        },
        hasUnlinkedCompanies() {
            let numUnlinked = 0
            this.user.employees.forEach(e => {
                if (!e.user_id) {
                    numUnlinked += 1
                }
            })
            return numUnlinked > 0
        },
        isLocked() {
            let numLocked = 0
            this.user.employees.forEach(e => {
                numLocked += e.is_locked ? 1 : 0
            })

            this.user.onboarding_applications.forEach(o => {
                numLocked += o.is_locked ? 1 : 0
            })

            return numLocked ? true : false
        },
    },
    data() {
        return {
            openCompanyPositions: {},
            loginSessions: [],
            showAll: false,
            taxData: null,

            activeTab: this.tab,

            activeWarningId: (this.tab == 'warnings') ? this.itemId : null,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,

            unresolvedWarningsExpanded: true,
            resolvedWarningsExpanded: true,
            dismissedWarningsExpanded: true,

            editEmailModalOpened: false,
            posSyncStatusModalOpened: false,
        }
    },
    mounted() {
        this.getLoginSessions()
    },
    watch: {
        user() {
            this.getLoginSessions()
            this.taxData = null
        }
    },
    methods: {
        resetPIN() {
            this.$refs.pinResetModal.open()
        },
        unlock() {
            this.$refs.unlockModal.open()
        },
        sendESSInvitation(email) {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/send-ess-invitation`, {'email': email}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo("Invitation email sent.")
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        getTaxData() {
            if (!this.employeeId) {
                this.$bus.showError("Employee not found")
                return
            }
            const url = `/admin/employees/${this.employeeId}/aos-tax-data`
            this.$store.dispatch('START_LOADING')
            this.$api.get(url).then(resp => {
                this.taxData = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getLoginSessions() {
            if ((this.user.employees.filter(e => e.user_id).length + this.user.onboarding_applications.filter(o => o.user_id).length) < 1) {return}

            this.loginSessions = null
            if (this.user.id) {
                this.$store.dispatch('START_LOADING')
                this.$api.get(`/clients/${this.client.id}/users/${this.user.id}/login-history`).then(resp => {
                    this.loginSessions = resp
                    this.$store.dispatch('STOP_LOADING')
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.__all__[0])
                })
            }
        },
        onUpdate() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/non-sec-users`).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                const user = resp.find(u => u.email == this.user.email)
                this.$emit('updated', user)

            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onEmailChange(email) {
            if (this.editEmailModalOpened) {
                this.editEmailModalOpened = false
            }

            const user = Object.assign({}, this.user)
            user.email = email
            user.key = email
            this.$emit('updated', user)
        },
        openCompanyPosition(cpId) {
            this.openCompanyPositions[cpId] = !this.openCompanyPositions[cpId]
        },
        onWarningsUpdate() {
            this.onUpdate()
        },
        openEditEmailModal() {
            this.editEmailModalOpened = true
        },
    },
}
</script>
