<template>
<div v-if="accountStatus">
    <div class="card mb-2">
        <div class="card-header pr-1 d-flex align-items-center">
            <span class="btnlike mr-auto">
                <h4 class="mb-0">Login Information</h4>
            </span>
        </div>

        <div class="card-body">
            <div v-if="accountStatus.has_user && !accountStatus.has_linked_user" class="d-flex mb-2">
                <div class="flex-grow-0 flex-shrink-0 mr-1">
                    <i aria-hidden="true" class="alert-icon fas fa-fw fa-exclamation-circle text-danger"></i>
                    <span class="sr-only">Notice:</span>
                </div>
                <div class="text-danger-dark text-semibold">
                    User has a registered account, but they have not yet linked it to your organization.
                </div>
            </div>

            <div v-if="!accountStatus.has_user" class="d-flex mb-2">
                <div class="flex-grow-0 flex-shrink-0 mr-1">
                    <i aria-hidden="true" class="alert-icon fas fa-fw fa-exclamation-circle text-danger"></i>
                    <span class="sr-only">Notice:</span>
                </div>
                <div class="text-danger-dark text-semibold">
                    User has not registered an account.
                </div>
            </div>

            <!-- Textfield with icon buttons to the right of it -->
            <div v-if="accountStatus.has_user" class="d-flex align-items-start">
                <div class="flex-fill overflow-auto">
                    <div class="mui-textfield">
                        <span class="textfield">{{ email }}</span>
                        <label><span v-if="accountStatus.has_user">Login</span> Email Address</label>
                    </div>
                </div>
                <div class="flex-0">
                    <a class="btn btn-outline-primary btn-collapse-xl" :href="`mailto:${email}`">
                        <i class="far fa-envelope" aria-hidden="true" title="Send Email"></i> 
                        <span class="btn-text">Send Email</span>
                    </a>
                </div>
            </div>

            <div>
                <div v-if="accountStatus.withdrew_consent">
                    <div class="text-danger-dark text-semibold mb-1 pb-1">
                        Employee Self Service account is disabled because
                        the user withdrew their consent to the Terms of Service.
                    </div>
                </div>

                <div v-if="!accountStatus.has_user" class="mb-1 pb-1">
                    <slot name="no-account-message"></slot>
                </div>

                <div v-if="!accountStatus.has_user && (user && user.email)">
                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="sendInviteEmail">Send Registration Invite</button>
                </div>

                <div v-if="pinResetEnabled">
                    <button class="btn btn-outline-primary btn-block mb-1" @click.prevent="resetPIN">Reset User's PIN</button>
                </div>

                <div v-if="accountStatus.has_user && accountStatus.has_linked_user && accountStatus.has_security_profile && !allowSecurityProfilePinReset">
                    This user account has Security User access. To reset their PIN, go to <strong>Settings &gt; Security Users.</strong>
                </div>

            </div>
        </div>
    </div>

    <login-history :login-sessions="loginSessions" v-if="accountStatus.has_user && accountStatus.has_linked_user" />
</div>
</template>

<script>
import LoginHistory from '@/components/LoginHistory'

export default {
    props: ['user', 'email', 'client', 'loginSessions', 'allowSecurityProfilePinReset', ],
    components: {LoginHistory, },
    computed: {
        pinResetEnabled() {
            if (!this.accountStatus.has_user) {
                return false // If there is no user, there is no PIN to reset
            }

            if (!this.accountStatus.can_have_pin_reset) {
                return false // The user exists but can't have its PIN reset (no linked accounts managed by the viewer)
            }

            // if they're a regular EE without a Security Profile, we no longer do PINs, so nothing to reset
            if (! this.accountStatus.has_security_profile) {
                return false
            }

            if (this.accountStatus.has_security_profile && !this.allowSecurityProfilePinReset) {
                return false // This UI doesn't allow you to reset their PIN (aka, got to settings -> security users
            }

            // if we're here, that means:
            // - there's an user
            // - ...which has either an EE, OA, or SP linked
            // - ...that the viewer can manage
            // - ...and if there's a SP, we're in the right view (settings -> security users)
            return true
        }
    },
    watch: {
        user: {
            handler() {
                this.getAccountStatus()
            },
            deep: true,
        },
    },
    data() {
        return {
            accountStatus: null,
        }
    },
    mounted() {
        this.getAccountStatus()
    },
    methods: {
        resetPIN() {
            this.$emit('pin_reset')
        },
        sendInviteEmail() {
            this.$emit('send_invite_email')
        },
        getAccountStatus() {
            if (!this.email) {
                this.accountStatus = {
                    has_user: false,
                    has_security_profile: false,
                    withdrew_consent: false,
                }

                return
            }

            this.$api.get(`/clients/${this.client.id}/account-access?email=${encodeURIComponent(this.email)}`).then(resp => {
                this.accountStatus = resp
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>
