<template>
    <table-page
        :client="client"
        :warnings="warnings"
        :changelogs="changelogs"
        @changelog_date_filter_change="onChangelogDateRangeChange"
        @warnings_date_filter_change="onWarningsDateRangeChange"
        @warnings_updated="getWarnings"
        @updated="refresh"
    >
        <fast-table
            :recordLabel="recordLabel"
            :sections="sections"
            :headers="headers"
            :defaultSortStack="defaultSortStack"
            :activeRowId="currentPaycheck ? currentPaycheck.id : null"
            :data="tableData"
            :managers="client.company_managers"
            @click="currentPaycheck = $event"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        />

        <employee-sidebar
            :client="client"
            :employee-id="currentPaycheck.employee.id"
            v-if="currentPaycheck"
            @close="currentPaycheck = null"
            tab="misc" section="checks"
            @updated="refresh"
            :key="currentPaycheck.id"/>
    </table-page>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import TablePageMixin from '@/mixins/TablePageMixin'

export default {
    props: ['client'],
    components: {FastTable, EmployeeSidebar,  },
    mixins: [TablePageMixin, ],
    computed: {
        tableData() {
            const tableData = []

            this.paychecks.forEach((paycheck, idx) => {
                let warnings = ''

                const row = {
                    id: paycheck.id,
                    object: paycheck,
                    cells: [
                        paycheck.employee.full_name_sortable,
                        paycheck.employee.employee_number,
                        warnings,

                        paycheck.check_date,
                        paycheck.check_number,
                        paycheck.net_pay,

                        paycheck.company.name,

                        paycheck.check_type_display,
                        paycheck.is_paper_check,
                        paycheck.payroll_run_number,
                        paycheck.employee.employment_status == 'Active',

                        paycheck.employee.email,
                        paycheck.employee.ssn_last_4 ? `XXX-XX-${paycheck.employee.ssn_last_4}` : '---',
                        paycheck.employee.last_pay_date,
                    ],
                }

                tableData.push(row)
            })

            return [ tableData ]
        },
    },
    data() {
        return {
            paychecks: [],
            dataLoaded: false,
            refreshing: false,
            currentPaycheck: null,
            dateRanges: {},

            recordLabel: "pay stubs",
            headers: [
                {label: 'Employee', classes: '', isSearchable: true, id: 'employee-name'},
                {label: 'Employee Num', classes: 'cw-7h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'employee-num'},
                {label: 'Warnings', classes: 'cell-warning', isSearchable: false, type: 'warning', isFilterable: true},

                {label: 'Check Date', classes: 'cw-date', isSearchable: true, type: 'date', type: 'date', id: 'check-date'},
                {label: 'Check Num', classes: 'cw-checknum', isSearchable: true, id: 'check-num'},
                {label: 'Net Pay', classes: 'cw-pay cell-right', type: 'money', isSearchable: true, sortDataType: 'numeric'},

                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true, id: 'company'},

                {label: 'Check Type', classes: 'cell-narrow', isSearchable: true, id: 'check-type'},
                {label: 'Paper Check', classes: 'cell-narrow', type: "checkmark", isSearchable: true, id: 'paper-check'},
                {label: 'Payroll Run Num', classes: 'cell-narrow', isSearchable: true},
                {label: 'Active Employee', classes: 'cw-7', type: "checkmark", isSearchable: true, isFilterable: true},

                {label: 'Email', classes: 'cw-email', isSearchable: true},
                {label: 'SSN', classes: 'cw-ssn', isSearchable: true},
                {label: 'Last Pay Date', classes: 'cw-date', isSearchable: true, type: 'date'},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 'paychecks'},
            ],
            defaultSortStack: [
                {'id': 'check-date', 'isDesc': true},
                {'id': 'company', 'isDesc': false},
                {'id': 'employee-name', 'isDesc': false},
                {'id': 'check-num', 'isDesc': false},
            ],
            warnings: null,
            changelogs: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Pay Stubs')
        this.getData()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        filterRelevantWarnings(w) {
            // N/A for this tile
            return false
        },
        filterRelevantChangelog(c) {
            // N/A for this tile
            return false
        },
        getData(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.refreshing = true

            let url = `/clients/${this.client.id}/paychecks`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                this.paychecks = resp
                this.dataLoaded = true
                this.refreshing = false
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.refreshing = false
                this.$bus.showError(errors.__all__)
            })
        },
        refresh() {
            if (this.refreshing) {return}
            this.getData(true)
            this.getWarnings()
            this.getChangelogs()
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getData()
        },
    },
}

</script>
