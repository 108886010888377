<template>
    <page>
        <fast-table
            v-if="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="onClick"
        />

        <sidebar :state="currentState" :all-states="states" v-if="currentState" ref="sidebar" @updated="onUpdate()" @close="currentState = null" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import Sidebar from '@/pages/admin/tax-engine/components/StateSidebar'

export default {
    components: {FastTable, Page, Sidebar, },
    computed: {
        tableData() {
            const tableData = []

            this.rules.forEach((r, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentState && r[0] == this.currentState.name,
                    object: r,
                    cells: r,
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            dataLoaded: false,
            currentState: null,
            states: [],
            rules: [],

            headers: [
                {label: 'Work State', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Resident State', classes: 'cell-medium', isSearchable: true},
                {label: 'Question', classes: 'cell-wide', isSearchable: true},
                {label: 'Answer', classes: 'cell-wide', isSearchable: true},
                {label: 'Form', classes: 'cell-wide', isSearchable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'State Rules Review')
        this.getRules()
        this.getStates()
    },
    methods: {
        getRules() {
            this.$api.get(`/admin/pdf-forms/auto-tester/rules`).then(resp => {
                this.rules = resp
                this.dataLoaded = true
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getStates() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/states`).then(resp => {
                resp.forEach(s => {
                    if (this.states.find(x => x.id == s.id)) {
                        Object.assign(this.states.find(x => x.id == s.id), s)
                    }
                    else {
                        this.states.push(s)
                    }
                })

                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onClick(rule) {
            this.currentState = this.states.find(s => s.name == rule[0])
        },
        onUpdate() {
            this.getRules()
            this.getStates()
        },
    },
}

</script>
