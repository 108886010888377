<template>
<div>
<sidebar-widget
    @add="modalOpen = true"
    @delete="onDelete($event)"
    @edit="currentRowId = $event; modalOpen = true"
    @versions="currentRowId = $event; versionsModalIsOpen = true"
    :rows="rows"
    :headers="['Name', 'Rate(s)']"
>
    <template v-slot:title>Unemployment Ins. Program Rates</template>

    <template v-slot:row-details scope="props">
        <unemployment-insurance-program-rate-version :version="props.row.current_version" />
    </template>
</sidebar-widget>

<unemployment-insurance-program-rate-modal
    v-if="modalOpen"
    :jurisdiction="jurisdiction"
    :unemployment-insurance-program-rate="currentRow"
    @updated="$emit('updated')"
    @close="modalOpen = false; currentRowId = null"

/>

<versions-modal
    v-if="versionsModalIsOpen"
    :url="getURL(currentRowId)"
    @close="currentRowId = null; versionsModalIsOpen = false"
>
    <template v-slot:version scope="props">
        <unemployment-insurance-program-rate-version :version="props.version" />
    </template>
</versions-modal>

</div>
</template>

<script>
import UnemploymentInsuranceProgramRateModal from './UnemploymentInsuranceProgramRateModal'
import SidebarWidget from './SidebarWidget'
import UnemploymentInsuranceProgramRateVersion from './UnemploymentInsuranceProgramRateVersion'
import VersionsModal from './VersionsModal'

export default {
    props: ['jurisdiction', ],
    components: {UnemploymentInsuranceProgramRateModal, SidebarWidget, UnemploymentInsuranceProgramRateVersion, VersionsModal},
    computed: {
        currentRow() {
            return this.allUnemploymentInsuranceProgramRates.find(x => x.id == this.currentRowId)
        },
        rows() {
            const result = []
            this.allUnemploymentInsuranceProgramRates.forEach(row => {
                let rates = ''
                if (row.current_version.rate_type == 'constant') {
                    rates = row.current_version.rate + '%'
                }
                else if (row.current_version.rate_type == 'min-max') {
                    rates = `${row.current_version.min_rate}% - ${row.current_version.max_rate}%`
                }
                else if (row.current_version.rate_type == 'list-selection') {
                    rates = row.current_version.rate_choices.map(r => r + '%').join(', ')
                }

                row = JSON.parse(JSON.stringify(row))
                row.cells = [
                    row.name,
                    rates,
                ]
                result.push(row)
            })
            return result
        },
        allUnemploymentInsuranceProgramRates() {
            return this.jurisdiction.unemployment_insurance_program_rates.sort((a, b) => a.current_version.starts_on > b.current_version.starts_on ? -1 : 1)
        },
    },
    data() {
        return {
            modalOpen: false,
            versionsModalIsOpen: false,
            currentRowId: null,
        }
    },
    methods: {
        getURL(rowId) {
            if (!rowId) {return}
            return `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/unemployment-insurance-program-rates/${rowId}`
        },
        onDelete(rowId) {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(this.getURL(rowId)).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>

