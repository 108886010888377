<template>
    <div class="form-moreinfo-trigger">
        <span ref="trigger" @click.prevent="onClick" @focus.prevent="onClick" @blur="onBlur" href class="btn btn-helpinfo help-info" :class="[hasLabel ? 'pl-1 pr-1' : 'btn-icon']" tabindex="0">
            <span :class="{ 'moreinfo-label': hasLabel }">
                <slot name="label">
                    <span class="sr-only">More Info</span>
                </slot>
            </span>
            <i class="far fa-fw fa-info-circle"></i>
        </span>
        <div style="display: none;" ref="info"><slot></slot></div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    props: ['title', 'placement'],
    methods: {
        onClick($event) {
            $event.stopPropagation()
            $(this.$refs.trigger).popover({
                boundary: 'viewport', // Otherwise defaults to scrollParent which sometimes doesn't work.
                content: $(this.$refs.info).html(),
                html: true,
                placement: this.placement || 'right',
                title: this.title || '',
                trigger: 'manual'
            }).popover('show')
        },
        onBlur($event) {
            $event.stopPropagation()
            $(this.$refs.trigger).popover('dispose')
        },
    },
    computed: {
        hasLabel() {
            return !!this.$slots.label
        }
    },
}
</script>
