let API_BASE = '/api'
if (import.meta.env.VITE_API_URL) {
    API_BASE = import.meta.env.VITE_API_URL
}
else if (import.meta.env.DEV) {
    API_BASE = 'http://localhost:5001/api'
}

import store from '@/store'
import bus from '@/bus'
import 'whatwg-fetch'

const handleError = (err) => {
    if (!Object.keys(err).length) {
        err.__all__ = ['There was a problem communicating with the server.']
    }
    throw err
}

const handleResponse = (response) => {
    // if we got an app version, save it
    let appVersion = response.headers.get('X-GetMyPayStub-App-Version')
    if (appVersion) {
        store.dispatch('SET_APP_VERSION', appVersion)
    }

    if (response.status == 401) {
        store.dispatch('LOGOUT', true)
        return bus.loggedOut()
    }

    // if we got an expiration time, save it
    let session_expiry = response.headers.get('X-GetMyPayStub-Session-Expiry')
    if (session_expiry) {
        store.dispatch('SET_SESSION_EXPIRY', session_expiry)
    }

    if (response.status > 399) {
        return response.json().catch(() => {
            switch (response.status) {
                case 404:
                    throw {'__all__': ['A programming error has occurred.'], '__status__': 404}
                default:
                    throw {'__all__': ['An error has occurred.'], '__status__': response.status}
            }
        }).then(err => {
            err.__status__ = response.status
            throw err
        })
    }

    if (response.headers.get('content-type') == 'application/json') {
        return response.json()
    }
    if (response.headers.get('content-type') == 'application/pdf') {
        return response.arrayBuffer()
    }
    if (response.headers.get('content-type') == 'application/zip') {
        return response.arrayBuffer()
    }
    return response.text()
}

const getHeaders = () => {
    return new Headers({
        'Content-Type': 'application/json',
        'X-CSRFToken': store.state.session ? store.state.session.csrf_token : '',
    })
}

const put = (url, data) => {
    data['csrfmiddlewaretoken'] = store.state.session ? store.state.session.csrf_token : ''
    return fetch(`${API_BASE}${url}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: getHeaders(),
        credentials: 'include',
    }).then(handleResponse).catch(handleError)
}

const patch = (url, data) => {
    data['csrfmiddlewaretoken'] = store.state.session ? store.state.session.csrf_token : ''
    return fetch(`${API_BASE}${url}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: getHeaders(),
        credentials: 'include',
    }).then(handleResponse).catch(handleError)
}

const post = (url, data) => {
    data['csrfmiddlewaretoken'] = store.state.session ? store.state.session.csrf_token : ''
    return fetch(`${API_BASE}${url}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: getHeaders(),
        credentials: 'include',
    }).then(handleResponse).catch(handleError)
}

const get = (url, ignore_for_session_expiry = false, prefix_api_base = true) => {
    // for gets caused by async updates, we send a header
    // to let the server know *not* to extend the session
    // (GET requests only)
    const headers = getHeaders()
    if (ignore_for_session_expiry) {
        headers.append('X-GetMyPayStub-Session-Ignore', '1')
    }
    if (prefix_api_base) {
        url = `${API_BASE}${url}`
    }
    return fetch(url, {
        method: 'GET',
        headers: headers,
        credentials: 'include',
    }).then(handleResponse).catch(handleError)
}

const del = (url) => {
    return fetch(`${API_BASE}${url}`, {
        method: 'DELETE',
        headers: getHeaders(),
        credentials: 'include',
    }).then(handleResponse).catch(handleError)
}

const upload = (url, file, {formData, fieldName, noCors}) => {
    const headers = new Headers({
        'X-CSRFToken': store.state.session.csrf_token,
    })

    const data = new FormData()

    // make sure this exists as a dict since it's optional
    formData = formData || {}

    Object.keys(formData).forEach(field => {
        data.append(field, formData[field])
    })
    data.append(fieldName || 'file', file)

    if (url.indexOf('https://') == -1 && url.indexOf('http://') == -1) {
        url = `${API_BASE}${url}`
    }

    let upload_args = {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: data,
    }

    // no-CORS mode only for S3 uploads
    if (noCors) {
        upload_args['mode'] = 'no-cors'
    }

    return fetch(url, upload_args).then(handleResponse).catch(handleError)
}

const uploadMultiple = (url, fileData, {formData}) => {
    const headers = new Headers({
        'X-CSRFToken': store.state.session.csrf_token,
    })

    const data = new FormData()

    fileData = fileData || {}
    Object.keys(fileData).forEach(field => {
        data.append(field, fileData[field])
    })

    formData = formData || {}
    Object.keys(formData).forEach(field => {
        data.append(field, formData[field])
    })

    return fetch(`${API_BASE}${url}`, {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: data,
    }).then(handleResponse).catch(handleError)
}

const redirect = (url) => {
    window.location.href = `${API_BASE}${url}`
}

export default {
    get,
    post,
    put,
    patch,
    del,
    upload,
    uploadMultiple,
    redirect,
    API_BASE,
}

