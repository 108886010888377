<template>
<modal size="xl" @close="$emit('close')" :autoshow="true" ref="modal" :is-closeable="isCloseable">
    <template v-slot:title>
        Update Tax Withholding Config
    </template>

    <slot>
        <form class="mui-form container" @submit.prevent="onSubmit()" style="min-height: 20em;">
            <form-errors :errors="formErrors"/>

            <h4 class="mt-2 mb-2">Tax Withholding Fields</h4>
            <div class="form-row" v-if="formData.field_configs">
                <div class="col-12 col-md-6 mb-2" v-for="f in formData.field_configs" :key="f.tax_field">
                    <form-input v-model="f.is_enabled" type="checkbox" :label="f.default_display_name" />
                    <form-input v-model="f.override_display_name" type="text" nocaps="true" label="Override Name" />
                </div>
            </div>

        </form>
    </slot>

    <template v-slot:footer>
        <div class="form-row">
            <div class="col">
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
            </div>
            <div class="col">
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </div>
        </div>
    </template>
</modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['city', 'state', 'fed', 'jurisdiction', ],
    mixins: [FormMixin, ModalMixin],
    computed: {
        juris() {
            const jurisdictions = {
                'fed': this.fed,
                'state': this.state,
                'city': this.city,
            }
            return jurisdictions[this.jurisdiction]
        },
        url() {
            if (this.jurisdiction == 'fed') {
                return `/admin/tax-engine/fed/tax-withholding-config`
            }
            else if (this.jurisdiction == 'state') {
                return `/admin/tax-engine/states/${this.state.id}/tax-withholding-config`
            }
            else if (this.jurisdiction == 'city') {
                return `/admin/tax-engine/cities/${this.city.id}/tax-withholding-config`
            }
            return ''
        },
    },
    data() {
        return {
            formLabels: {
                default_result: 'This field',
                result: 'This field',
            },
            formRules: {},
            formData: this.makeFormData(),
        }
    },
    mounted() {
        this.getFieldConfigs()
    },
    methods: {
        makeFormData() {
            const data = {
                field_configs: []
            }
            return data
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            return this.$api.put(this.url, this.getFormData()).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
        getFieldConfigs() {
            this.$api.get(`/admin/tax-engine/default-tax-withholding-field-configs`).then(resp => {
                let field_configs = resp
                if (this.juris.tax_withholding_configs.length) {
                    this.juris.tax_withholding_configs[0].field_configs.forEach(fc => {
                        let idx = field_configs.findIndex(default_config => default_config.tax_field == fc.tax_field)
                        if (idx > -1) {
                            field_configs[idx] = JSON.parse(JSON.stringify(fc))
                        }
                    })
                }
                this.formData.field_configs = field_configs.sort((a, b) => (
                    (a.tax_field < b.tax_field ? -1 : a.tax_field > b.tax_field ? 1 : 0)
                ))
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                return {}
            })
        }
    }
}
</script>
