<template>
    <page :client="client">
        <fast-table
            :recordLabel="recordLabel"
            v-if="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="onEmployeeSelected"
            @click_cell="onEmployeeSelected($event.object, $event.columnIdx)"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
            @updated="onUpdate"
        >

        </fast-table>

        <security-profile-sidebar
            :security-profile-id="currentProfile.id"
            :key="currentProfile.id"
            :tab="tab"
            :section="section"
            :item-id="sidebarItemId"
            v-if="currentProfile && currentProfileEditable"
            ref="sidebar"
            :client="client"
            @updated="onUpdate"
            @deleted="onDelete"
            @close="currentProfileId = null"
        />

    </page>
</template>

<script>
import Page from '@/components/Page'
import FastTable from '@/components/FastTable'
import SecurityProfileSidebar from '@/components/SecurityProfileSidebar'
import TablePageMixin from '@/mixins/TablePageMixin'

export default {
    props: ['client', ],
    components: {Page, FastTable, SecurityProfileSidebar, },
    mixins: [TablePageMixin, ],
    computed: {
        activeUsers() {
            return this.profiles.filter((p) => {
                if (!p.user) {  // These must have users + at least one active CSP
                    return false
                }

                let c = 0
                p.company_security_profiles.forEach(csp => {
                    if (csp.role && csp.is_active) {
                        c += 1
                    }
                })

                return c > 0
            })
        },
        inactiveUsers() {
            return this.profiles.filter((p) => {
                if (!p.user) {  // These must have users + no active CSPs
                    return false
                }

                let c = 0
                p.company_security_profiles.forEach(csp => {
                    if (csp.role && csp.is_active) {
                        c += 1
                    }
                })

                return c < 1
            })
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.activeUsers,
                this.inactiveUsers,
            ]

            subsets.forEach(profiles => {
                const sectionData = []

                profiles.forEach(p => {
                    let everify_access = ''

                    if (p.everify_access == 'enabled') {
                        everify_access = 'OK'
                    } else if (p.everify_access == 'blocked') {
                        everify_access = 'Training Required'
                    } else {
                        return
                    }

                    const roleCompanyCounts = {}
                    p.company_security_profiles.forEach(csp => {
                        if (!csp.is_active) {return}
                        if (!roleCompanyCounts.hasOwnProperty(csp.role)) {
                            roleCompanyCounts[csp.role] = 0
                        }
                        roleCompanyCounts[csp.role]++
                    })

                    const ROLE_PRIORITY = {}
                    this.$store.state.security_roles.NUMERICALLY_SORTED_ROLES.forEach((roleId, idx) => {
                        ROLE_PRIORITY[roleId] = idx
                    })

                    const roleCompanyCountsArray = Array.from(Object.entries(roleCompanyCounts))
                    roleCompanyCountsArray.sort((a, b) => {
                        if (a[1] == b[1]) {
                            if (a[0] == b[0]) {return 0}
                            return ROLE_PRIORITY[a[0]] > ROLE_PRIORITY[b[0]] ? -1 : 1
                        }

                        return a[1] > b[1] ? -1 : 1
                    })

                    const firstRole = roleCompanyCountsArray[0] || ['', 0]
                    const additionalRoles = roleCompanyCountsArray.slice(1)

                    const ROLES = this.$store.state.security_roles.ROLE_LABELS
                    let cells = [
                        p.full_name_sortable,
                        everify_access,
                        ROLES[firstRole[0]],
                        firstRole[1],
                        p.email,
                    ]

                    const row = {
                        id: p.id,
                        isActive: this.currentProfileId && p.id == this.currentProfileId,
                        object: p,
                        cells: cells,
                        subrows: [],
                    }

                    additionalRoles.forEach(r => {
                        const subrow = ['', '', ROLES[r[0]], r[1], '']
                        row.subrows.push(subrow)
                    })

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })
            return tableData
        },
        currentProfile() {
            return this.profiles.find(p => p.id == this.currentProfileId)
        },
        currentProfileEditable() {
            if (!this.currentProfile) {
                return false
            }

            if (this.$store.state.user.is_superuser) {
                return true
            }

            const userSP = this.$permissions.getSecurityProfile(this.client.id)
            if (userSP.id == this.currentProfile.id) {
                return false // Can't edit yourself
            }

            // We are going to use these two sets to track in which companies can we assign roles.
            // This is necessary because we need to know if there is a company where the SP we are editing does not have an active role.
            const officerCompanyIds = new Set(this.$permissions.getCompanyIdsByRoles(this.client.id, this.$store.state.security_roles.OFFICER_ROLES))
            const payrollAdminCompanyIds = new Set(this.$permissions.getCompanyIdsByRoles(this.client.id, [this.$store.state.security_roles.ROLE_PAYROLL_ADMIN]))

            this.currentProfile.company_security_profiles.forEach(csp => {
                if (this.$store.state.security_roles.OFFICER_ROLES.includes(csp.role)) {
                    // The SP we are looking at is an officer in this company. We can't touch this role
                    officerCompanyIds.delete(csp.company.id)
                    payrollAdminCompanyIds.delete(csp.company.id)
                }
                else if ([this.$store.state.security_roles.ROLE_PAYROLL_ADMIN].includes(csp.role)) {
                    // The SP we are looking at is a payroll-admin in this company. We can only touch this role if we are an officer, but not a payroll admin.
                    payrollAdminCompanyIds.delete(csp.company.id)
                }
            })

            // Are there any companies in which we can assign or edit roles?
            return (Array.from(officerCompanyIds).length + Array.from(payrollAdminCompanyIds).length) > 0
        }
    },
    data() {
        // E-Verify column is conditional on the client having E-Verify

        let headers = [
            {label: 'User', classes: '', isSearchable: true, defaultSort: true},
            {label: 'E-Verify Training', classes: 'cw-4 cell-center', isSearchable: false, type: "checkmark-or-blank", isFilterable: true},
            {label: 'Role', classes: 'cw-8', isSearchable: true, isFilterable: true},
            {label: 'Companies', classes: 'cw-4 cell-center', isSearchable: false, isFilterable: false},
            {label: 'Email', classes: 'cw-8', isSearchable: true},
        ]

        return {
            profiles: [],
            dataLoaded: false,
            currentProfileId: null,

            recordLabel: "security users",
            headers: headers,
            sections: [
                {title: 'Active Users', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Users', defaultIsClosed: true, id: 'inactive', hasDateFilter: true},
            ],

            tab: 'main',
            section: '',
            sidebarItemId: '',
            addModalOpen: false,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Settings / E-Verify Users')
        this.getProfiles(false)

        this.$bus.$on('client_data_updated', updates => {
            if (updates.indexOf('security_profiles') >= 0) {
                this.getProfiles(true)
            }
        })
    },
    methods: {
        filterRelevantWarnings(w) {
            return w.company_security_profile_id
        },
        filterRelevantChangelog(c) {
            // NB: changelogs may have a security_profile_id, warnings will not
            return c.company_security_profile_id || c.security_profile_id
        },
        getProfiles(silent) {
            if (!silent) {
                this.$store.dispatch('START_LOADING')
            }

            let url = `/clients/${this.client.id}/security-profiles`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            let ignore_for_session_expiry = silent
            this.$api.get(url, ignore_for_session_expiry).then((resp) => {
                this.profiles = resp
                this.dataLoaded = true
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }

                if (this.currentProfile) {
                    this.$refs.sidebar.updateSecurityProfile(this.currentProfile)
                }
            }).catch((errors) => {
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(secProfile) {
            const existingProfile = this.profiles.find((p) => {return secProfile.id == p.id})
            if (existingProfile) {
                Object.assign(existingProfile, secProfile)
            }
            else {
                this.profiles.push(secProfile)
            }
        },
        onDelete() {
            this.profiles = this.profiles.filter(p => p.id != this.currentProfileId)
            this.currentProfileId = null
            this.getProfiles(true)
        },
        onEmployeeSelected(sp, columnIdx) {
            this.tab = 'main'
            this.section = ''
            this.sidebarItemId = ''

            this.currentProfileId = sp.id
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getProfiles()
        },
    },
}
</script>
