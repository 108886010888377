<script>
const ONBOARDING_ORDER = [
    'onboarding-wotc',
    'onboarding-hr-info',
    'onboarding-address',
    'onboarding-address-correction',
    'onboarding-address-details',
    'onboarding-address-school-district',
    'onboarding-emergency-contact',
    'onboarding-eeo-info',
    'onboarding-form-selection',
    'onboarding-forms',
    'onboarding-direct-deposit',
    'onboarding-complete',
]

export default {
    computed: {
        routePrefix() {
            return this.$route.name.split('-')[0]
        },
        strippedRouteName() {
            return this.$route.name.replace(new RegExp(`^${this.routePrefix}-`), '')
        },
        previousPage() {
            let prev = ONBOARDING_ORDER[ONBOARDING_ORDER.indexOf(this.strippedRouteName) - 1]
            if (!prev) {
                return {name: 'employee-company-home', params: {hashedCompanyId: this.$hasher.encode(this.onboardingApplication.company.id)}}

            }
            if ((prev == 'onboarding-wotc') && (! this.onboardingApplication.has_wotc_step)) {
                return {name: 'employee-company-home', params: {hashedCompanyId: this.$hasher.encode(this.onboardingApplication.company.id)}}

            }
            if ((prev == 'onboarding-address-school-district') && (! this.onboardingApplication.has_school_district_disambiguation_step)) {
                prev = 'onboarding-address-details'
            }
            if ((prev == 'onboarding-address-details') && (! this.onboardingApplication.has_geocode_disambiguation_step)) {
                prev = 'onboarding-address-correction'
            }
            if ((prev == 'onboarding-address-correction') && (! this.onboardingApplication.has_address_correction_step)) {
                prev = 'onboarding-address'
            }
            return {name: `${this.routePrefix}-${prev}`, params: {appId: this.onboardingApplication.id}, query: {prev: 1}}
        },
        nextPage() {
            let next = ONBOARDING_ORDER[ONBOARDING_ORDER.indexOf(this.strippedRouteName) + 1]
            if (!next) {
                let completionRoute = {name: 'employee-company-home', params: {hashedCompanyId: this.$hasher.encode(this.onboardingApplication.company.id)}}
                if (this.routePrefix == 'admin') {
                    completionRoute = {name: 'onboarding', params: {hashedClientId: this.$hasher.encode(this.onboardingApplication.company.client_id)}}
                }
                return completionRoute
            }

            if ((next == 'onboarding-address-correction') && !this.onboardingApplication.has_address_correction_step) {
                next = 'onboarding-address-details'
            }
            if ((next == 'onboarding-address-details') && !this.onboardingApplication.has_geocode_disambiguation_step) {
                next = 'onboarding-address-school-district'
            }
            if ((next == 'onboarding-address-school-district') && !this.onboardingApplication.has_school_district_disambiguation_step) {
                next = 'onboarding-emergency-contact'
            }

            return {name: `${this.routePrefix}-${next}`, params: {appId: this.onboardingApplication.id}}
        },
    },
}
</script>
