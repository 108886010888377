
import pako from 'pako'

export default {
    compressText(string) {
        const byteArray = new TextEncoder().encode(string)
        const output = pako.gzip(byteArray)
        return Buffer.from(output, 'binary').toString('base64')
    },
    decompressText(b64) {
        const bytes = Buffer.from(b64, 'base64')
        const plain = pako.ungzip(bytes, {to: 'string'})
        return plain
    },
}
