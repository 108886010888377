<template>
    <div>
        <alert-box type="error" v-for="error in errors" :key="error">
            {{ error }}
        </alert-box>
        <alert-box type="error" v-for="error in errorsHtml" :key="error">
            <span v-html="error"></span>
        </alert-box>
    </div>
</template>

<script>

// Only specific errors that we know to be "safe", and need HTML rendering,
// should be output with errorsHtml - and *nothing* that'll echo user input,
// since that can be an XSS vector

export default {
    props: ['errors', 'errorsHtml'],
}
</script>
