<template>
    <sidebar @close="$emit('close')">
        <template v-slot:title>
            Federal
        </template>
        <slot>
            <involuntary-withholding-law-sidebar-widget
                jurisdiction="fed"
                :fed="fed"
                :earning-types="earningTypes"
                :filing-statuses="filingStatuses"
                :deduction-types="deductionTypes"
                :involuntary-withholding-types="involuntaryWithholdingTypes"
                @updated="$emit('updated')"
            />

            <minimum-wage-sidebar-widget
                jurisdiction="fed"
                :fed="fed"
                @updated="$emit('updated')"
            />

            <filing-status-sidebar-widget
                jurisdiction="fed"
                :fed="fed"
                @updated="$emit('updated')"
            />

            <tax-withholding-config-sidebar-widget
                jurisdiction="fed"
                :fed="fed"
                @updated="$emit('updated')"
            />
        </slot>
    </sidebar>
</template>

<script>
import InvoluntaryWithholdingLawSidebarWidget from './InvoluntaryWithholdingLawSidebarWidget'
import MinimumWageSidebarWidget from './MinimumWageSidebarWidget'
import Sidebar from '@/components/Sidebar'
import FilingStatusSidebarWidget from './FilingStatusSidebarWidget'
import InfoWidget from '@/components/InfoWidget'
import TaxWithholdingConfigSidebarWidget from './TaxWithholdingConfigSidebarWidget'

export default {
    components: {Sidebar, InfoWidget, InvoluntaryWithholdingLawSidebarWidget, MinimumWageSidebarWidget, FilingStatusSidebarWidget, TaxWithholdingConfigSidebarWidget, },
    props: ['fed', 'involuntaryWithholdingTypes', 'deductionTypes', 'earningTypes', ],
    computed: {
        filingStatuses() {
            return this.fed.filing_statuses
        },
    },
}
</script>


