<template>
<div>
    <div v-if="w2StatusData.status">
        <!-- Account Alerts (W-2 Availability etc) -->
        <div class="scoreboard-group">
            <h4 class="mb-2">
                Account Alerts <span class="text-regular">(1)</span> <!-- Needs to be dynamic number -->
            </h4>

            <!-- Tax documents now available -->
            <div class="scoreboard scoreboard--accountalerts--info box-shadow-tile" v-if="w2StatusData.status == 'available'">
                <div class="scoreboard-item">
                    <div class="scoreboard-item-main">
                        <span class="scoreboard-item-icon">
                            <i class="fal fa-fw fa-file-alt"></i>
                        </span>
                        <span class="scoreboard-item-type">
                            You have new tax documents available to view and print. You can find them in the Tax Documents section of your Employee Dashboard.
                        </span>
                    </div>
                </div>
            </div>

            <!-- Tax documents will be available -->
            <div class="scoreboard scoreboard--accountalerts--info box-shadow-tile" v-if="w2StatusData.status == 'upcoming'">
                <div class="scoreboard-item">
                    <div class="scoreboard-item-main">
                        <span class="scoreboard-item-icon">
                            <i class="fal fa-fw fa-file-alt"></i>
                        </span>
                        <span class="scoreboard-item-type">
                            You have incoming tax documents that will be available to view and print by <strong>{{ w2StatusData.upcoming_date }}.</strong> You'll be able to find them in the Tax Documents section of your Employee Dashboard.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="onboardingApplications.length || pendingLinkCompanies.length || unreportedCovidVaxStatus || numTasks">
        <!-- Next Actions -->
        <div class="scoreboard-group">
            <h4 class="mb-2">
                Next Actions <span class="text-regular">({{ onboardingApplications.length + pendingLinkCompanies.length + (unreportedCovidVaxStatus ? 1 : 0) + numTasks}})</span>
            </h4>
            <div class="scoreboard scoreboard--nextactions box-shadow-tile">
                <!-- Onboarding items -->
                <div class="scoreboard-item" v-for="app in onboardingApplications" :key="app.id">
                    <router-link :to="{name: 'employee-onboarding', params: {appId: app.id}}" class="scoreboard-item-main">
                        <span class="scoreboard-item-icon">
                            <i class="fas fa-fw fa-paper-plane"></i>
                        </span>
                        <span class="scoreboard-item-type">
                            <span v-if="!app.digital_consent_given_on">Start Onboarding</span>
                            <span v-if="app.digital_consent_given_on">Continue Onboarding</span>
                        </span>
                        <span class="scoreboard-item-count">
                            <i class="fas fa-fw fa-arrow-right"></i>
                        </span>
                    </router-link>
                </div>

                <!-- Linking items -->
                <div class="scoreboard-item" v-for="company in pendingLinkCompanies" :key="company.id">
                    <router-link :to="{name: 'employee-link-record'}" class="scoreboard-item-main">
                        <span class="scoreboard-item-icon">
                            <i class="fas fa-fw fa-link"></i>
                        </span>
                        <span class="scoreboard-item-type">
                            Link Employee Record
                        </span>
                        <span class="scoreboard-item-count">
                            <i class="fas fa-fw fa-arrow-right"></i>
                        </span>
                    </router-link>
                </div>

                <!-- COVID status -->
                <div class="scoreboard-item" v-if="unreportedCovidVaxStatus">
                    <router-link :to="{name: 'employee-covid', params: {hashedCompanyId: $route.params.hashedCompanyId}}" class="scoreboard-item-main">
                        <span class="scoreboard-item-icon">
                            <i class="fas fa-fw fa-virus"></i>
                        </span>
                        <span class="scoreboard-item-type">Report COVID Vaccination Status </span>
                        <span class="scoreboard-item-count">
                            <i class="fas fa-fw fa-arrow-right"></i>
                        </span>
                    </router-link>
                </div>

                <!-- Document tasks -->
                <div class="scoreboard-item" v-if="numTasks > 0">
                    <router-link :to="{name: 'employee-tasks', params: {hashedCompanyId: $route.params.hashedCompanyId}}" class="scoreboard-item-main">
                        <span class="scoreboard-item-icon">
                            <i class="fas fa-fw fa-file"></i>
                        </span>
                        <span class="scoreboard-item-type">You have {{ numTasks }} document{{ numTasks > 1 ? 's' : ''}} to complete.</span>
                        <span class="scoreboard-item-count">
                            <i class="fas fa-fw fa-arrow-right"></i>
                        </span>
                    </router-link>
                </div>

            </div>
        </div>

        <!-- Onboarding reminder modal -->
        <onboarding-reminder-modal v-if="modalIsOpen" :onboarding-applications="onboardingApplications" />
    </div>
</div>
</template>

<script>
import ButtonRow from '@/components/ButtonRow'
import OnboardingReminderModal from '@/components/OnboardingReminderModal'

export default {
    components: {ButtonRow, OnboardingReminderModal, },
    props: ['company', 'showOnboardingModal'],
    computed: {
        user() {return this.$store.state.user},
        onboardingApplications() {
            const apps = this.getApps()

            apps.sort((a, b) => {
                if (a.progress == b.progress) {
                    return a.id > b.id ? 1 : 1
                }
                return a.progress > b.progress ? 1 : -1
            })

            return apps
        },
        pendingLinkCompanies() {
            let pending = this.user ? this.user.employee_pending_link_companies : []
            if (pending && this.company) {
                pending = pending.filter(c => {
                    return c.id == this.company.id
                })
            }
            return pending
        },
        unreportedCovidVaxStatus() {
            if (this.user && this.company && this.company.feature_covid_vaccination_status_reporting) {
                return this.user.employee_unreported_covid_status_company_ids.includes(this.company.id)
            } else {
                return false
            }
        },
        w2StatusData() {
            return this.user ? this.user.employee_w2_status : {}
        },
        numTasks() {
            if (this.user && this.company && this.user.employee_num_tasks_by_company_id && this.company.id in this.user.employee_num_tasks_by_company_id) {
                return this.user.employee_num_tasks_by_company_id[this.company.id]['num_tasks']
            }
            return 0
        }
    },
    data() {
        const apps = this.getApps()
        return {
            modalIsOpen: this.showOnboardingModal && apps.length,
        }
    },
    methods: {
        getApps() {
            let apps = this.$store.state.user ? this.$store.state.user.pending_onboarding_applications.filter(onApp => {
                return ['started', 'logged-in'].includes(onApp.status)
            }) : []
            if (apps && this.company) {
                apps = apps.filter(onApp => {
                    return onApp.company.id == this.company.id
                })
            }
            return apps
        }
    }
}

</script>
