<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container mx-auto">
                    <div class="py-4">
                        <div class="row">
                            <div class="col-12">
                                <a href="/privacy" target="_blank" class="btn btn-link"><i class="far fa-book-open mr-hf"></i>target="_blank"</a><br>
                                <a href="/privacy" target="_system" class="btn btn-link"><i class="far fa-book-open mr-hf"></i>target="_system"</a><br>
                                <a href="/privacy" :target="$store.state.isInApp ? '_system' : '_blank'" class="btn btn-link"><i class="far fa-book-open mr-hf"></i>target="_system" on App / target="_blank" on Web</a><br>
                                <a href onclick="window.location.reload()" class="btn btn-link"><i class="far fa-redo mr-hf"></i>window.location.reload()</a><br>
                            </div>
                        </div>
                    </div>

                    <div class="py-4">
                        <div class="row">
                            <div class="col-6 col-lg-3">
                                <div>
                                    <button class="btn btn-outline-primary btn-boxy" @click.prevent="$refs.fileInput1.click()">
                                        <i class="far fa-camera mr-hf"></i>
                                        Add Photo
                                    </button>

                                    <input class="d-none" type="file" @change="onUpload($event)" accept=".pdf,.jpg,.jpeg,.png,.heic,.heif;capture=camera" ref="fileInput1" />
                                </div>
                            </div>

                            <div class="col-6 col-lg-3">
                                <div>
                                    <button class="btn btn-outline-primary btn-boxy" @click.prevent="$refs.fileInput2.click()">
                                        <i class="far fa-camera mr-hf"></i>
                                        Add Photo
                                    </button>

                                    <input class="d-none" type="file" @change="onUpload($event)" accept=".pdf,.jpg,.jpeg,.png,.heic,.heif" capture="camera" ref="fileInput2" />
                                </div>
                            </div>

                            <div class="col-6 col-lg-3">
                                <div>
                                    <button class="btn btn-outline-primary btn-boxy" @click.prevent="$refs.fileInput3.click()">
                                        <i class="far fa-camera mr-hf"></i>
                                        Add Photo
                                    </button>

                                    <input class="d-none" type="file" @change="onUpload($event)" accept="image/*;capture=camera" ref="fileInput3" />
                                </div>
                            </div>

                            <div class="col-6 col-lg-3">
                                <div>
                                    <button class="btn btn-outline-primary btn-boxy" @click.prevent="$refs.fileInput4.click()">
                                        <i class="far fa-camera mr-hf"></i>
                                        Add Photo
                                    </button>

                                    <input class="d-none" type="file" @change="onUpload($event)" accept="image/*" capture="camera" ref="fileInput4" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Test')
    },
    methods: {
        onUpload(evt) {
            console.log(evt)
            alert('Got file')
        }
    }
}
</script>
