<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Employee Civil Rights and Civil Liberties</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    It is the employer's and employer agent's responsibility to treat employees equally and fairly when using E-Verify.
                                </p>

                                <h5>Employers and employer agents participating in E-Verify MUST:</h5>

                                <ul>
                                    <li> Create a case in E-Verify only AFTER the applicant accepted an offer of employment and Form I-9, Employment Eligibility Verification is complete.</li>
                                    <li> Create an E-Verify case for all newly hired employees, regardless of citizenship.</li>
                                    <li> Notify each job applicant of E-Verify participation and employee rights.</li>
                                    <li> Use E-Verify for an existing employee only when he or she is assigned to a federal contract or if your company chose to verify its existing workforce.</li>
                                    <li> Verify all new employees and existing employees assigned to a federal contract regardless of national origin, citizenship, or immigration status.</li>
                                    <li> Provide each employee with notice of and the opportunity to take action on a Tentative Nonconfirmation (Mismatch) or a Dual Tentative Noncomfirmation (Mismatch).</li>
                                    <li> Ensure no change in the working conditions (pay, hours, etc.) of employees who takes action to resolve a mismatch.</li>
                                </ul>

                                <h5>Employers and employer agents participating in E-Verify MUST NOT:</h5>

                                <ul>
                                    <li> Use E-Verify to pre-screen an applicant for employment.</li>
                                    <li> Specify or request which Form I-9 documentation an employee must use, except to specify that any Form I-9 List B document the employee chooses to present must contain a photo.</li>
                                    <li> Use E-Verify to discriminate against any job applicant or employee on the basis of his or her national origin, citizenship, or immigration status.</li>
                                    <li> Create an E-Verify case for an employee who was hired before the employer signed the E-Verify MOU, except in certain instances such as employees of federal contractors with the FAR E-Verify clause.</li>
                                    <li> Take adverse action against or terminate an employee because he or she received a mismatch result, unless E Verify issues a case result of Final Nonconfirmation.</li>
                                    <li> Share any user ID and/or password.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page1'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page1b'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
