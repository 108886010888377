<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-s py-4" v-show="dataLoaded">

                        <h1 class="mb-4 text-center">Contact your Account Administrator</h1>

                        <div v-if="managers && managers.length">
                            <p>Call one of the following administrators to reset your PIN. They will ask you to confirm your secret recovery word.</p>

                            <ul>
                                <li v-for="m in managers">{{ m.full_name }}: {{ m.phone_formatted }}</li>
                            </ul>
                        </div>

                        <p v-if="managers && !managers.length">Please call your account administrator to help reset your PIN.</p>

                        <p>
                            <router-link class="btn btn-primary btn-block" :to="{name: 'login'}">Back to Sign In</router-link>
                        </p>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            managers: [],
            dataLoaded: false,
        }
    },
    mounted() {
        this.getManagers()
    },
    methods: {
        getManagers() {
            this.$store.dispatch('START_LOADING')
            return this.$api.get(`/me/managers/authd`).then((resp) => {
                this.managers = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.dataLoaded = true
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>
