<template>
    <modal size="m" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template v-slot:title>
            <h3 class="modal-title">Edit Expiration Settings</h3>
        </template>

        <slot>
            <div class="mb-2">
                <div class="p-1 unpad-x bg-150 rounded">
                    <div><span class="text-muted">Company: </span>{{ document.company.name }}</div>
                    <div><span class="text-muted">Document Name: </span>{{ document.name }}</div>
                </div>
            </div>

            <form-errors :errors="formErrors"/>

            <alert-box type="info" v-if="documentHasDraft" class="mt-1 mb-2">
                Any changes to expiration settings will apply to both your active and draft versions of this document.
            </alert-box>

            <p>Begin reminders <span class="expiration-inline-input mui-textfield"><input type="number" placeholder="30" v-model="formData.remind_days_before_expiration"/></span> days before expiration.</p>

        </slot>

        <template v-slot:footer>
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">
                    Save
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import InfoWidget from '@/components/InfoWidget'

export default {
    props: ['client', 'document', 'documentType', 'documentHasDraft'],
    mixins: [FormMixin, ModalMixin],
    components: {InfoWidget, },
    data() {
        return {
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            const data = {
                remind_days_before_expiration: this.document.remind_days_before_expiration,
            }

            return data
        },
        onSubmit() {
            this.$store.dispatch('START_LOADING')

            return this.$api.patch(`/clients/${this.client.id}/hr-custom-forms/${this.document.id}/expiration-settings`, this.getFormData()).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    }
}
</script>

<style>
    .expiration-inline-input.mui-textfield {
        display: inline;
    }
    .expiration-inline-input.mui-textfield > input {
        display: inline;
        width: 60px;
        padding-left: 10px;
        text-align: center;
    }
</style>
