<template>
    <div class="training-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <h2 class="mb-2">Privacy Breaches and Rights violations</h2>

                        <div class="row">
                            <div class="col-12 px-2 mb-2">
                                <p>
                                    Report all suspected or confirmed privacy breaches by calling 888-464-4218 or emailing <a href="mailto:E-Verify@uscis.dhs.gov">E-Verify@uscis.dhs.gov</a>. Please use ‘Privacy Incident – Password’ in the subject line of the email when sending a breach report to E-Verify.
                                </p>

                                <p>
                                    Questions regarding how to avoid unfair and discriminatory verification practices when using E-Verify should be directed to U.S. Department of Justice, Civil Rights Division, Immigrant and Employee Rights Section (IER) <a href="mailto:ier@usdoj.gov">ier@usdoj.gov</a>, 800-255-8155 or 800-237-2515 (TTY).
                                </p>
                            </div>
                        </div>

                        <div class="row justify-content-center mb-4">
                            <button-row inline>
                                <router-link class="btn btn-width-medium btn-outline-primary mr-4" :to="{name: 'training-e-verify-page1b'}">Back</router-link>
                                <router-link class="btn btn-width-medium btn-primary ml-4" :to="{name: 'training-e-verify-page2'}">Continue</router-link>
                            </button-row>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import ButtonRow from '@/components/ButtonRow.vue'

export default {
    props: ['client', ],
    components: {ButtonRow, },
    data() {
        return {
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'E-Verify Training')
    },
    computed: {
    },
    methods: {

    }
}

</script>
