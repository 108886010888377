<template>
    <modal size="xl" @close="$emit('close')" ref="modal">
        <template v-slot:title>Validation Results</template>

        <slot>
            <div class="form-row pb-2">
                <div class="col">

                    <h4>Summary</h4>

                    <table class="table table-sm table-bordered table-striped table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Active</th>
                                <th>Inactive</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Employees</td>
                                <td>{{ results.counts.employees_active }}</td>
                                <td>{{ results.counts.employees_inactive }}</td>
                                <td>{{ results.counts.employees_total }}</td>
                            </tr>
                            <tr>
                                <td>Mapping Problems</td>
                                <td>{{ results.counts.mapping_problems_active }}</td>
                                <td>{{ results.counts.mapping_problems_inactive }}</td>
                                <td>{{ results.counts.mapping_problems_total }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="results.counts.mapping_problems_total">
                        <br>
                        <h4>Details</h4>

                        <table class="table table-sm table-bordered table-striped table-hover">
                            <thead>
                                <tr>
                                    <th># EEs</th>
                                    <th>Company</th>
                                    <th>Work Location</th>
                                    <th>Paygroup</th>
                                    <th>Orgs</th>
                                    <th>Sample EE #s</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="problem in results.mapping_problems">
                                    <td>{{ problem.num_problems }}</td>
                                    <td>{{ problem.company_code }}</td>
                                    <td>{{ problem.work_location }}</td>
                                    <td>{{ problem.paygroup }}</td>
                                    <td>
                                        <span v-for="(val, index) in problem.org_values">
                                            {{ val }}<span v-if="index != problem.org_values.length - 1"> / </span>
                                        </span>
                                    </td>
                                    <td>
                                        <span v-for="(num, index) in problem.example_employee_numbers">
                                            {{ num }}<span v-if="index != problem.example_employee_numbers.length - 1">, </span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Close</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin],
    props: ['client', 'results'],
    data() {
        return {

        }
    },
    computed: {
    },
    methods: {

    }
}
</script>
