<template>
<div>

    <fast-table
        v-if="dataLoaded"
        :sections="sections"
        :headers="headers"
        :data="tableData"
        @click="sidebarOpen = true"
    />

    <sidebar
        v-if="sidebarOpen"
        :fed="fed"
        :earning-types="earningTypes"
        :deduction-types="deductionTypes"
        :involuntary-withholding-types="involuntaryWithholdingTypes"
        @updated="onUpdate($event)"
        @close="sidebarOpen = false"
    />
</div>
</template>

<script>
import FastTable from '@/components/FastTable'
import Sidebar from './components/FedSidebar'

export default {
    components: {FastTable, Sidebar, },
    computed: {
        tableData() {
            const tableData = []

            const minWage = this.fed.minimum_wages.find(w => w.is_active)
            const row = {
                id: 'fed',
                isActive: this.sidebarOpen,
                object: this.fed,
                cells: [
                    'Federal',
                    minWage ? '$' + minWage.minimum_wage : '',
                    minWage ? '$' + minWage.max_tip_credit_claimed : '',
                    this.fed.updated_on,
                ],
            }

            tableData.push(row)

            return [tableData]
        },
    },
    data() {
        return {
            fed: {},
            dataLoaded: false,
            sidebarOpen: false,

            involuntaryWithholdingTypes: [],
            earningTypes: [],
            deductionTypes: [],

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Minimum Wage', classes: 'cw-3', isSearchable: false},
                {label: 'Max Tip Credit', classes: 'cw-3', isSearchable: false},
                {label: 'Last Update', classes: 'cell-date', type: 'date', isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / Federal')
        this.getFed()
        this.getInvoluntaryWithholdingTypes()
        this.getEarningTypes()
        this.getDeductionTypes()
    },
    methods: {
        getFed() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/fed`).then(resp => {
                this.fed = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        getInvoluntaryWithholdingTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/involuntary-withholding-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.involuntaryWithholdingTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getEarningTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/earning-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.earningTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getDeductionTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/deduction-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.deductionTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(city) {
            return this.getFed()
        },
    },
}

</script>
