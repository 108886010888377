<template>
    <modal :autoshow="true" size="xl" ref="modal" @close="$emit('close')" :is-closeable="isCloseable">

        <template v-slot:title>
            {{ stepTitle }}
        </template>

        <component
            :is="step"
            :prev="prev"
            ref="stepComponent"
            :employee="employee"
            :employeeUpdate="employeeUpdate"
            @patch="onPatch"
            @next="onNextStep"
            @prev="onPrevStep"
            @updated="onUpdated"
        />

        <template v-slot:footer>
            <portal-target name="identity-edit-modal-footer"></portal-target>
        </template>
    </modal>
</template>

<script>
// Note that there's only one step here, but this component is written
// to support multiple steps if requirements change for identity editing
const STEP_ORDER = [
    'edit-identity',
]
const STEP_TITLES = {
    'default': "Edit Personal Info",
}

import ModalMixin from '@/mixins/ModalMixin'
import EditIdentity from './EmployeeIdentityEditStep.vue'

export default {
    mixins: [ModalMixin, ],
    components: {EditIdentity, },
    props: ['employee'],
    watch: {
        employee() {
            this.reset()
        },
    },
    computed: {
        stepTitle() {
            if (this.step in STEP_TITLES) {
                return STEP_TITLES[this.step]
            } else {
                return STEP_TITLES['default']
            }
        },
    },
    data() {
        return {
            step: STEP_ORDER[0],
            employeeUpdate: null,
            prev: false,
        }
    },
    methods: {
        getNextStep(step) {
            return STEP_ORDER[STEP_ORDER.indexOf(step) + 1]
        },
        getPrevStep(step) {
            return STEP_ORDER[STEP_ORDER.indexOf(step) - 1]
        },
        onPrevStep() {
            this.prev = true
            let step = this.getPrevStep(this.step)
            if (!step) {
                this.$emit('close')
            }
            this.step = step
        },
        onNextStep() {
            this.prev = false
            let step = this.getNextStep(this.step)
            if (!step) {
                this.$emit('close')
            }
            this.step = step
        },
        onPatch(update) {
            this.employeeUpdate = update
        },
        onUpdated() {
            this.$emit('updated')
            this.$emit('close')
        },
        reset() {
            this.employeeUpdate = null
            this.step = STEP_ORDER[0]
        }
    },
}
</script>
