<template>
    <div>
    <nav-secondary :navOptions="navOptions"></nav-secondary>
    <!-- <router-view class="flex-page-content flex-container view" :client="client"/> -->
    <div class="flex-page-content flex-container view">
    <table-page
        :client="client"
        :warnings="warnings"
        :changelogs="changelogs"
        @changelog_date_filter_change="onChangelogDateRangeChange"
        @warnings_date_filter_change="onWarningsDateRangeChange"
        @warnings_updated="getWarnings"
        @updated="refresh"
    >
        <fast-table
            :recordLabel="recordLabel"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :activeRowId="activeRowId"
            :managers="client.company_managers"
            @click="currentDocument = $event"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
            :useFiltersFromUrl="dataLoaded"
        />

        <employee-sidebar
            :client="client"
            :employee-id="currentDocument.employee.id"
            v-if="currentDocument"
            @close="currentDocument = null"
            tab="hr-docs" section="incomplete-docs" :item-id="currentDocument.id"
            @updated="refresh"
            @doc-task-canceled="onDocTaskCanceled"
            :key="currentDocument.id"/>
    </table-page>
    </div>
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import TablePageMixin from '@/mixins/TablePageMixin'
import NavSecondary from '@/components/NavSecondary'

export default {
    props: ['client'],
    components: {FastTable, EmployeeSidebar, NavSecondary, },
    mixins: [TablePageMixin, ],
    computed: {
        pendingEmployeeCompletionDocs() {
            return this.documents.filter(d => d.status == 'needs-employee-response')
        },
        pendingManagerCompletionDocs() {
            return this.documents.filter(d => d.status == 'needs-manager-response')
        },
        readyForApprovalDocs() {
            return this.documents.filter(d => d.status == 'needs-manager-approval')
        },
        activeRowId() {
            return this.currentDocument ? this.currentDocument.id : null
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.readyForApprovalDocs,
                this.pendingEmployeeCompletionDocs,
                this.pendingManagerCompletionDocs,
            ]

            subsets.forEach(docs => {
                const sectionData = []

                docs.forEach((d, idx) => {
                    const row = {
                        id: d.id,
                        object: d,
                        cells: [
                            d.employee.full_name_sortable,
                            d.employee.employee_number,
                            d.employee.warning_count,
                            d.employee.company.name,
                            d.document_name,
                            // d.most_recent ? 'Active' : 'Inactive',
                            // d.source_pretty,
                            null, // d.responded_on
                            d.status == 'needs-manager-response' ? null : (d.last_reminded_on || d.assigned_on), // don't show last reminded on for manager docs
                            // d.signature_data.manager_signature ? d.signature_data.manager_signature.full_name : 'N/A',
                            d.employee.email,
                            d.employee.ssn_last_4 ? `XXX-XX-${d.employee.ssn_last_4}` : '---',
                            d.employee.last_pay_date,
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            documents: [],
            dataLoaded: false,
            refreshing: false,
            currentDocument: null,
            dateRanges: {},
            openedRoutedDocSidebar: false,

            recordLabel: "HR documents",
            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Employee Num', classes: 'cw-7h cell-right', isSearchable: true, sortDataType: 'numeric'},
                {label: 'Warnings', classes: 'cw-warning', isSearchable: false, type: 'warning', isFilterable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                // {label: 'Position', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Document', classes: 'cell-medium', isSearchable: true, isFilterable: true},
                // {label: 'Status', classes: 'cw-5h cell-center', isFilterable: true},
                // {label: 'Source', classes: 'cw-6h', isSearchable: false, isFilterable: true},
                {label: 'Due On', classes: 'cw-date', isSearchable: false, type: 'date'},
                // TODO KB: Sent On? To match with dashboard?
                {label: 'Last Reminded On', classes: 'cell-medium', isSearchable: false, type: 'date', defaultSort: true, defaultSortIsDesc: true},
                // {label: 'Signed/Approved By', classes: 'cw-10', isSearchable: false},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'SSN', classes: 'cw-ssn', isSearchable: true},
                {label: 'Last Paid', classes: 'cw-date', isSearchable: false, type: 'date'},
            ],
            sections: [
                {title: 'Ready for Approval', defaultIsClosed: false, id: 'readyForApprovalDocs', hasDateFilter: false},
                {title: 'Pending Employee Completion', defaultIsClosed: false, id: 'pendingEmployeeCompletionDocs', hasDateFilter: false},
                {title: 'Pending Manager Completion', defaultIsClosed: false, id: 'pendingManagerCompletionDocs', hasDateFilter: false},
            ],
            navOptions: [
                {'text': 'Completed Documents', 'value': 'hr-documents', },
                {'text': 'Incomplete Documents', 'value': 'hr-documents-incomplete',},
                {'text': 'Canceled Documents', 'value': 'hr-documents-canceled', },
            ]
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'HR Documents')
        this.getData()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        filterRelevantWarnings(w) {
            if (w.employee) {
                if (['i9-completed-late', 'i9-incomplete'].includes(w.warning_type)) {
                    return true
                }
            }
            return false
        },
        getData(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.refreshing = true

            let url = `/clients/${this.client.id}/document-tasks/incomplete`

            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                resp.forEach(d => {
                    d.company_security_profile_ids = d.employee.company_security_profile_ids
                })
                this.documents = resp
                this.dataLoaded = true
                this.refreshing = false
                if (this.$route.query.doc && !this.openedRoutedDocSidebar) {
                    this.openRoutedDocSidebar()
                }
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.refreshing = false
                this.$bus.showError(errors.__all__)
            })
        },
        refresh() {
            if (this.refreshing) {return}
            this.getData(true)
            this.getWarnings()
            this.getChangelogs()
        },
        filterRelevantChangelog(c) {
            return c.employee_id && c.tracked_object_slug == 'document-task'
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getData()
        },
        onDocTaskCanceled(docTaskId) {
            this.documents = this.documents.filter(d => d.id != docTaskId)
        },
        openRoutedDocSidebar() {
            if (this.$route.query.doc) {
                let docTaskId = this.$route.query.doc
                let currentDocument = this.documents.find(d => d.id == docTaskId)
                if (currentDocument) {
                    this.currentDocument = currentDocument
                    this.openedRoutedDocSidebar = true
                }
            }
        },
    },
}

</script>
