<template>
    <modal size="xl" ref="modal" @close="$emit('close')" :is-closeable="isCloseable" :autoshow="true">
        <template v-slot:title>
            <span v-if="table">Update Tax Recapture Table</span>
            <span v-if="!table">Add Tax Recapture Table</span>
        </template>
        <slot>

            <form class="mui-form" @submit.prevent="onSubmit">
                <div class="form-row mb-4">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.filing_status" :errors="errors.filing_status" type="select" label="Filing Status" :readonly="!!table" :options="filingStatusOptions" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col">
                        <form-input
                            @upload="onFileUpload($event)"
                            :errors="errors.file"
                            label="Upload Excel or CSV/TSV file"
                            type="file"
                            accept=".xls,.tsv,.csv,.xlsx"
                        />
                    </div>
                </div>

            </form>
        </slot>

        <template v-slot:footer>
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import moment from 'moment'

export default {
    props: ['tax', 'table', ],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        filingStatusOptions() {
            const result = []
            this.tax.jurisdiction.filing_statuses.forEach(s => {
                result.push({text: s.name, value: s.id})
            })

            if (!result.length) {
                result.push({text: 'NO FILING STATUS', value: '-'})
            }
            return result
        },
        isPast() {
            return this.table && this.table.is_past
        },
        isReadonly() {
            return this.table && !this.table.is_future
        },
    },
    data() {
        return {
            formRules: {
                starts_on: {presence: {allowEmpty: false}, date: true},
                file: {presence: {allowEmpty: false}, },
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.table) {
                return {
                    starts_on: moment(this.table.current_version.starts_on),
                    inactive_on: moment(this.table.current_version.inactive_on),
                    filing_status: this.table.filing_status_id,
                    file: '',
                }
            } else {
                return {
                    starts_on: '',
                    inactive_on: '',
                    filing_status: '',
                    file: '',
                }
            }
        },
        onFileUpload(f) {
            const reader = new FileReader()
            reader.onload = () => {
                this.formData.file = btoa(reader.result)
            }
            reader.readAsBinaryString(f[0])
        },
        getFormData() {
            const data = JSON.parse(JSON.stringify(this.formData))
            data.filing_status = data.filing_status == '-' ? null : data.filing_status
            return data
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise, url;
            if (this.table) {
                url = `/admin/tax-engine/taxes/${this.tax.id}/tax-recapture-tables/${this.table.id}`
                promise = this.$api.put(url, this.getFormData())
            }
            else {
                url = `/admin/tax-engine/taxes/${this.tax.id}/tax-recapture-tables`
                promise = this.$api.post(url, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>


