<template>
  <div class="flex-page-content flex-container">
        <div class="flex-body" :class="flexBodyClasses" ref="pageBody">
            <div class="container-fluid">
                <slot></slot>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props: {
        // 'fullScroll':
        //    True if the entire body area (under the top bar) should scroll.
        //    Examples:
        //    - fullScroll = 'true' for a content page (e.g. an Employee Onboarding step),
        //      because it has no other inner scrollbars.
        //    - fullScroll = 'false' for a FastTable page (e.g. Self Service Access),
        //      because the FastTable area has its own inner scrollbars.
        //
        //    NOTE: On a fullScroll page, <page> must live inside a child <div>, like so:
        //      <template><div><page> stuff </page></div></template>
        fullScroll: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        flexBodyClasses() {
            let flexBodyClasses = ''
            if (this.fullScroll) {
                flexBodyClasses += ' body-scroll'
            }
            return flexBodyClasses
        },
    },
    methods: {
        scrollTop() {
            this.$nextTick(() => {
                this.$refs.pageBody.scrollTop = 0
            })
        }
    },
    provide: function () {
        return {
            scrollTop: this.scrollTop
        }
    }
}
</script>