<template>
    <table-page
        :client="client"
        :warnings="warnings"
        :changelogs="changelogs"
        @warnings_date_filter_change="onWarningsDateRangeChange"
        @warnings_updated="getWarnings"
        @updated="refresh"
    >
        <fast-table
            :recordLabel="recordLabel"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :managers="client.company_managers"
            @click="currentEmployeeId = $event.id"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
        />

        <employee-sidebar
            :client="client"
            :employee-id="currentEmployeeId"
            v-if="currentEmployeeId"
            @close="currentEmployeeId = null"
            tab="misc" section="covid-vaccination-statuses" :item-id="currentEmployeeId" expanded-items-subsection="active"
            @updated="refresh"
            :key="currentEmployeeId"/>
    </table-page>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import TablePageMixin from '@/mixins/TablePageMixin'

export default {
    props: ['client'],
    components: {FastTable, EmployeeSidebar, },
    mixins: [TablePageMixin, ],
    computed: {
        pending() {
            return this.employees.filter(e => {
                const statuses = e.employee_covid_vaccination_statuses
                if (!statuses.length) {return false}

                const status = e.employee_covid_vaccination_statuses[0]
                return status.status == 'pending'
            })
        },
        accepted() {
            return this.employees.filter(e => {
                const statuses = e.employee_covid_vaccination_statuses
                if (!statuses.length) {return false}

                const status = e.employee_covid_vaccination_statuses[0]
                return status.status == 'accepted'
            })
        },
        unreported() {
            return this.employees.filter(e => {
                const statuses = e.employee_covid_vaccination_statuses
                if (!statuses.length) {return true}

                const status = e.employee_covid_vaccination_statuses[0]
                return status.status == 'rejected'
            })
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.pending,
                this.unreported,
                this.accepted,
            ]

            subsets.forEach(docs => {
                const sectionData = []

                docs.forEach((e, idx) => {
                    let status_text = 'Unreported'
                    if (e.employee_covid_vaccination_statuses.length) {
                        // rejected == unreported for display purposes
                        if (e.employee_covid_vaccination_statuses[0].status != 'rejected') {
                            status_text = e.employee_covid_vaccination_statuses[0].vaccination_status_pretty
                        }
                    }
                    const row = {
                        id: idx,
                        isActive: this.currentEmployeeId && e.id == this.currentEmployeeId,
                        object: e,
                        cells: [
                            e.full_name_sortable,
                            e.employee_number,
                            status_text,
                            e.user_id ? 'Registered' : 'Not registered',
                            e.company.name,
                            e.email,
                            e.ssn_last_4 ? `XXX-XX-${e.ssn_last_4}` : '---',
                            e.last_pay_date,
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            employees: [],
            dataLoaded: false,
            refreshing: false,
            currentEmployeeId: null,
            dateRanges: {},

            recordLabel: "COVID Vaccination Status for Employees",
            headers: [
                {label: 'Employee', classes: '', isSearchable: true},
                {label: 'Employee Num', classes: 'cw-7h cell-right', isSearchable: true, sortDataType: 'numeric'},

                {label: 'Status', classes: 'cw-8', isSearchable: false, isFilterable: true},
                {label: 'Account Status', classes: 'cw-10', isSearchable: false, isFilterable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: false, isFilterable: true},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'SSN', classes: 'cw-ssn', isSearchable: true},
                {label: 'Last Paid', classes: 'cw-date', isSearchable: false},
            ],
            sections: [
                {title: 'Ready for Review', defaultIsClosed: false, id: 'pending', hasDateFilter: false},
                {title: 'Unreported', defaultIsClosed: false, id: 'unreported', hasDateFilter: false},
                {title: 'Accepted', defaultIsClosed: true, id: 'accepted', hasDateFilter: false},
                //{title: 'Rejected', defaultIsClosed: true, id: 'rejected', hasDateFilter: false},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'COVID / Vaccination Statuses')
        this.getData()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        filterRelevantWarnings(w) {
            if (w.employee) {
                if (['covid-vaccination-status'].includes(w.warning_type)) {
                    return true
                }
            }
            return false
        },
        getData(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.refreshing = true

            let url = `/clients/${this.client.id}/covid-vaccination-statuses`
            this.$api.get(url).then(resp => {
                this.employees = resp
                this.employees.forEach(e => {
                    e.employee_covid_vaccination_statuses.sort((a, b) => a.reported_on > b.reported_on ? -1 : 1)
                })
                this.dataLoaded = true
                this.refreshing = false
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.refreshing = false
                this.$bus.showError(errors.__all__)
            })
        },
        refresh() {
            if (this.refreshing) {return}
            this.getData(true)
            this.getWarnings()
            this.getChangelogs()
        },
        filterRelevantChangelog(c) {
            return c.employee_id && c.tracked_object_slug == 'covid-vaccination-status'
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getData()
        },
    },
}

</script>
