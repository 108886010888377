<template>
    <div>
        <page :fullScroll="true">

                    <div class="container-centered-s py-4">
                        <router-link class="btn btn-link" :to="{name: 'settings-hr-documents'}"><i class="far fa-fw fa-chevron-left mr-hf"></i>Back to HR Document Library</router-link>

                        <div v-if="!docTypeConfirmed && !autoselectCompanyDoc">
                            <h3 class="py-2 mb-0">Add HR Document: <span class="text-thin text-nowrap">Document Type</span></h3>
                            <p>What type of document would you like to add?</p>

                            <!-- Company Doc -->
                            <div class="form-row ml-4">
                                <div class="col">
                                    <label>
                                        <input :disabled="!companyDocsModuleEnabled" name="docType" type="radio" @click="docType = 'hr-doc'" :checked="docType == 'hr-doc'" class="form-check-input">
                                        <span class="text-semibold" :class="{'text-muted': !companyDocsModuleEnabled}">
                                            <i class="far fa-file-signature"></i>
                                            Company Document
                                        </span>
                                    </label>
                                    <div class="mt-0 field-helper-text">
                                        <small>Upload a PDF for employees to sign.</small>
                                        <a v-if="!companyDocsModuleEnabled" href="#"><i class="mt-1 fas mr-hf fa-star"></i>Upgrade</a> <!-- TODO KB add link to marketing page when we have it -->
                                    </div>
                                </div>
                            </div>

                            <!-- Uniform -->
                            <div v-if="hrDocsSuiteReleased">
                                <div class="form-row ml-4">
                                    <div class="col">
                                        <label>
                                            <input :disabled="!client.has_uniform_module" name="docType" type="radio" @click="docType = 'uniform'" :checked="docType == 'uniform'" class="form-check-input">
                                            <span class="text-semibold" :class="{'text-muted': !client.has_uniform_module}">
                                                <i class="far fa-file-user mr-hf"></i>
                                                Uniform Form
                                            </span>
                                        </label>
                                        <div class="mt-0 field-helper-text">
                                            <small>Create a form to collect uniform sizes, colors, etc. from employees.</small><br/>
                                            <a v-if="!client.has_uniform_module" href="#"><i class="mt-1 fas mr-hf fa-star"></i>Upgrade</a> <!-- TODO KB add link to marketing page when we have it -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Custom Form -->
                            <div v-if="hrDocsSuiteReleased">
                                <div class="form-row ml-4">
                                    <div class="col">
                                        <label>
                                            <input :disabled="!client.has_custom_forms_module" name="docType" type="radio" @click="docType = 'custom-form'" :checked="docType == 'custom-form'" class="form-check-input">
                                            <span class="text-semibold" :class="{'text-muted': !client.has_custom_forms_module}">
                                                <i class="far fa-file-alt mr-hf"></i>
                                                Custom Form
                                            </span>
                                        </label>
                                        <div class="mt-0 field-helper-text">
                                            <small>Create a custom form for employees or managers to complete. Select from various question types to build your form.</small><br/>
                                            <a v-if="!client.has_custom_forms_module" href="#"><i class="mt-1 fas mr-hf fa-star"></i>Upgrade</a> <!-- TODO KB add link to marketing page when we have it -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Fillable Form AKA Legacy Custom Form -->
                            <div v-if="client.has_fillable_forms" class="form-row ml-4">
                                <div class="col">
                                    <label>
                                        <input name="docType" type="radio" @click="docType = 'fillable-form'" :checked="docType == 'fillable-form'" class="form-check-input">
                                        <span class="text-semibold">
                                            <i class="far fa-file-contract mr-hf"></i>
                                            Fillable Form
                                        </span>
                                    </label>
                                    <div class="mt-0 field-helper-text">
                                        <small>Choose a fillable form for employees to complete.</small><br/>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4 mb-2 d-flex align-items-center">
                                <div class="col">
                                    <button class="btn btn-outline-primary" @click.prevent="exitToDocuments">Cancel</button>
                                </div>

                                <div class="col text-right">
                                    <button class="btn btn-primary" @click.prevent="onNext">Continue</button>
                                </div>
                            </div>
                        </div>

                        <uniform-wizard
                            v-if="docType=='uniform' && docTypeConfirmed"
                            :client="client"
                            mode="add"
                            @prev="onPrev"
                        />

                        <company-document-wizard
                            v-if="(docType=='hr-doc' && docTypeConfirmed) || autoselectCompanyDoc"
                            :client="client"
                            mode="add"
                            @prev="onPrev"
                        />

                        <custom-form-wizard
                            v-if="docType=='custom-form' && docTypeConfirmed"
                            :client="client"
                            mode="add"
                            @prev="onPrev"
                        />

                        <fillable-form-wizard
                            v-if="docType=='fillable-form' && docTypeConfirmed"
                            :client="client"
                            @prev="onPrev"
                        />
                    </div>

        </page>
    </div>
</template>

<script>
import UniformWizard from './components/UniformWizard.vue'
import CompanyDocumentWizard from './components/CompanyDocumentWizard.vue'
import CustomFormWizard from './components/CustomFormWizard.vue'
import FillableFormWizard from './components/FillableFormWizard.vue'
import Page from '@/components/Page'

export default {
    components: {Page, UniformWizard, CompanyDocumentWizard, CustomFormWizard, FillableFormWizard},
    props: ['client', ],
    data() {
        let data = {
            docType: '',
            docTypeConfirmed: false,
            formData: {}
        }
        return data
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Settings / HR Document Library')
    },
    computed: {
        hrDocsSuiteReleased() {
            return (this.$store.state.system_flags && this.$store.state.system_flags['hr_docs_suite_released']) || this.client.preview_hr_docs_suite
        },
        companyDocsModuleEnabled() {
            // user can use limited version of company docs module if they only have the onboarding module
            return this.client.has_company_docs_module || this.client.has_paperless_onboarding
        },
        autoselectCompanyDoc() {
            return this.client.has_paperless_onboarding && !this.client.has_fillable_forms && !this.hrDocsSuiteReleased
        }
    },
    methods: {
        exitToDocuments() {
            this.$router.push({name: 'settings-hr-documents'})
        },
        onNext() {
            this.docTypeConfirmed = true
        },
        onPrev() {
            this.docTypeConfirmed = false
        },
    }
}

</script>
