<template>
    <div class="alert" :class="alertBox.alertClass" :role="alertBox.alertRole">
        <div class="d-flex">
            <div class="flex-grow-0 flex-shrink-0 mr-1 unpad-l">
                <i v-if="alertBox.alertIconClass" aria-hidden="true" class="alert-icon fas fa-fw" :class="alertBox.alertIconClass"></i>
                <span class="sr-only" v-html="alertBox.alertPrefix"></span>
            </div>
            <div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            default: 'info',
        },
    },
    data() {
        return {
            alertBoxDefinitions: {
                success: {
                    alertClass:     'alert-success',
                    alertRole:      'alert',
                    alertIconClass: 'fa-check-circle',
                    alertPrefix:    'Success: ',
                },
                caution: {
                    alertClass:     'alert-warning',
                    alertRole:      'alert',
                    alertIconClass: 'fa-exclamation-triangle',
                    alertPrefix:    'Caution: ',
                },
                error: {
                    alertClass:     'alert-danger',
                    alertRole:      'alert',
                    alertIconClass: 'fa-exclamation-circle text-danger',
                    alertPrefix:    'Error: ',
                },
                info: {
                    alertClass:     'alert-info',
                    alertRole:      '',
                    alertIconClass: 'fa-info-circle',
                    alertPrefix:    'Note: ',
                },
                secondary: {
                    alertClass:     'alert-secondary',
                    alertRole:      '',
                    alertIconClass: '',
                    alertPrefix:    'Note: ',
                },
                lockedWarning: {
                    alertClass:     'alert-locked alert-locked-warning',
                    alertRole:      '',
                    alertIconClass: 'fa-exclamation-triangle',
                    alertPrefix:    'Note: ',
                },
                _default: {
                    alertClass:     'alert-info',
                    alertRole:      '',
                    alertIconClass: 'fa-info-circle',
                    alertPrefix:    'Note: ',
                },
            }
        }
    },
    computed: {
        alertBox() {
            if (this.alertBoxDefinitions[this.type]) {
                return this.alertBoxDefinitions[this.type]
            }
            return this.alertBoxDefinitions._default
        }
    },
}
</script>