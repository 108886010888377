<template>
    <modal @close="$emit('close')" ref="modal">
        <template v-slot:title>POS Connection Options</template>

        <slot>
            <form-errors :errors="formErrors"/>

            <div class="form-row" v-if="['lc', 'sicom', 'macromatix', 'synergy', 'crunchtime', 'R365'].includes(integration.pos)">
                <div class="col">
                    <form-input label="POS URL" v-model="formData.pos_url" :errors="errors.pos_url" :nocaps="true" type="text" />
                </div>
            </div>

            <div class="form-row" v-if="['lc', 'lc_alt', 'sicom', 'macromatix', 'nbo', 'synergy', 'crunchtime', 'R365', 'micros_sonic'].includes(integration.pos)">
                <div class="col">
                    <form-input label="POS Username" v-model="formData.pos_username" :errors="errors.pos_username" :nocaps="true" type="text" />
                </div>
            </div>

            <div class="form-row" v-if="['lc', 'lc_alt', 'sicom', 'macromatix', 'nbo', 'synergy', 'crunchtime', 'R365', 'micros_sonic'].includes(integration.pos)">
                <div class="col">
                    <form-input label="POS Password" v-model="formData.pos_password" :errors="errors.pos_password" :nocaps="true" type="text" />
                </div>
            </div>

            <div class="form-row" v-if="['sicom'].includes(integration.pos)">
                <div class="col">
                    <form-input label="SICOM Custom Report ID" v-model="formData.sicom_custom_report_id" :errors="errors.sicom_custom_report_id" :nocaps="true" type="text" />
                </div>
            </div>

            <div class="form-row" v-if="['qsr'].includes(integration.pos)">
                <div class="col">
                    <form-input label="QSR Client Number" v-model="formData.qsr_client_number" :errors="errors.qsr_client_number" :nocaps="true" type="text" />
                </div>
            </div>

            <div class="form-row" v-if="['qsr'].includes(integration.pos)">
                <div class="col">
                    <form-input label="QSR Mode" v-model="formData.qsr_mode" :errors="errors.qsr_mode" type="select" :options="QSRModeOptions" :nocaps="true" />
                </div>
            </div>

            <div class="form-row" v-if="['lc_alt_api'].includes(integration.pos)">
                <div class="col">
                    <form-input label="API Environment" v-model="formData.alt_api_env" :errors="errors.alt_api_env" type="select" :options="AltAPIEnvOptions" :nocaps="true" />
                </div>
            </div>

            <div class="form-row" v-if="['lc_alt_api'].includes(integration.pos)">
                <div class="col">
                    <form-input label="POS Client ID" v-model="formData.pos_client_id" :errors="errors.pos_client_id" :nocaps="true" type="text" />
                </div>
            </div>

            <div class="mt-2 mb-2" v-if="['lc', 'lc_alt', 'nbo', 'synergy', 'crunchtime', 'R365', 'micros_sonic'].includes(integration.pos)">
                <h5>Additional Credentials</h5>

                <div v-for="(id, $index) in Object.keys(credentialForms)" :key="id">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="mb-1">#{{ $index + 1 }}</h6>
                        </div>
                        <div class="col text-right">

                            <div class="dropdown d-inline-block">
                                <a href="" class="btn btn-icon dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i><span class="sr-only">More Actions</span>
                                </a>
                                <div class="dropdown-menu has-icons dropdown-menu-right">
                                    <a class="dropdown-item" href @click.prevent="removeCredential(id, $index)"><i class="fas fa-fw fa-times-circle mr-1"></i>Remove</a>
                                </div>
                            </div>

                        </div>
                    </div>

                    <pos-credential-form
                        :client="client"
                        :integration="integration"
                        :instance="formData.credentials[$index]"
                        ref="credentialForms"
                        @update="onCredentialUpdate($index, $event)"
                    />
                </div>

                <p class="mb-2">
                    <a href class="btn btn-link" @click.prevent="addCredential()"><i class="far fa-plus mr-hf"></i>Add another</a>
                </p>
            </div>

        </slot>

        <template v-slot:footer>
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import POSCredentialForm from './POSCredentialForm'

export default {
    mixins: [FormMixin, ModalMixin],
    components: {PosCredentialForm: POSCredentialForm, },
    props: ['client', 'integration', ],
    data() {
        const QSRModeOptions = [
            {text: 'Default', value: 'default'},
            {text: 'Test', value: 'test'},
        ]

        const AltAPIEnvOptions = [
            {text: 'Production', value: 'prod'},
            {text: 'sandbox', value: 'sandbox'},
        ]

        const data = {
            dataLoaded: false,
            credentialForms: {},
            QSRModeOptions: QSRModeOptions,
            AltAPIEnvOptions: AltAPIEnvOptions,
            formData: this.makeFormData(),
        }

        if (data.formData['credentials']) {
            for (let i = 0; i < data.formData['credentials'].length; i++) {
                data.credentialForms[i] = i
            }
        }

        return data
    },
    mounted() {

    },
    computed: {
        formRules() {
            let rules = {}

            if (['lc', 'sicom', 'macromatix', 'crunchtime'].includes(this.integration.pos)) {
                rules['pos_url'] = {presence: {allowEmpty: false}, }
            }

            if (['lc', 'lc_alt', 'sicom', 'macromatix', 'crunchtime', 'micros_sonic'].includes(this.integration.pos)) {
                rules['pos_username'] = {presence: {allowEmpty: false}, }
                rules['pos_password'] = {presence: {allowEmpty: false}, }
            }

            if (['sicom'].includes(this.integration.pos)) {
                rules['sicom_custom_report_id'] = {presence: {allowEmpty: false}, }
            }

            if (['qsr'].includes(this.integration.pos)) {
                rules['qsr_client_number'] = {presence: {allowEmpty: false}, }
                rules['qsr_mode'] = {presence: {allowEmpty: false}, }
            }

            if (['lc_alt_api'].includes(this.integration.pos)) {
                rules['pos_client_id'] = {presence: {allowEmpty: false}, }
                rules['alt_api_env'] = {presence: {allowEmpty: false}, }
            }

            return rules
        },
    },
    methods: {
        addCredential() {
            const id = Math.max(1, ...Object.keys(this.credentialForms)) + 1
            this.credentialForms[id] = Object.keys(this.credentialForms.length - 1)
            this.$emit('scrollbottom')
        },
        removeCredential(id, idx) {
            const credentials = []
            this.formData.credentials = [];
            (this.$refs.credentialForms || []).forEach((form, i) => {
                if (i != idx) {
                    credentials.push(form.getFormData())
                }
            })

            this.formData.credentials = credentials
            delete this.credentialForms[id]
        },
        onCredentialUpdate(idx, data) {
            this.formData.credentials[idx] = data
        },

        makeFormData() {
            let fields = [
                'pos_url', 'pos_username', 'pos_password', 'sicom_custom_report_id', 'qsr_client_number', 'qsr_mode', 'alt_api_env', 'pos_client_id'
            ]
            let credential_fields = [
                'id', 'pos_url', 'pos_username', 'pos_password', 'ref_code', 'sync_only'
            ]
            let formData = {}

            fields.forEach(f => {
                formData[f] = this.integration[f]
            })

            formData['credentials'] = []
            this.integration['pos_credentials'].forEach((cred, idx) => {
                const credData = {}
                credential_fields.forEach(f => {
                    credData[f] = cred[f]
                })
                formData['credentials'][idx] = credData
            })

            return formData
        },
        onSubmit() {
            let errorCount = 0;
            (this.$refs.credentialForms || []).forEach((form) => {
                if (!form.validate()) {
                    errorCount += 1
                }
            })

            if (!this.validate() || errorCount) {
                return
            }

            this.$store.dispatch('START_LOADING')
            let url = `/admin/clients/${this.client.id}/pos-integrations/${this.integration.id}/connection`
            api.put(url, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    }
}
</script>
