<template>
    <div class="feature-marketing-page">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">

                <div class="brand-banner">
                    <div class="container-fluid">
                        <div class="container-centered-l py-4">
                            <h1>Empower Your Employees With Enhanced ESS</h1>
                            <p class="mt-2 mb-0">The Enhanced ESS feature empowers your employees to directly update some of their own company information.</p>
                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="container-centered-l py-4">

                        <template v-if="hasEnhancedESS">
                            <h2>About Enhanced ESS</h2>
                            <p>
                                Your organization has enabled <strong>Enhanced ESS</strong>, a feature that enables employees to directly update some of their own company information.
                                These editing features can be found in the <strong>My Profile</strong> area of employees&rsquo; Self-Service dashboard.
                            </p>
                            <alert-box type="info" class="mb-2">
                                The <strong>Enhanced ESS</strong> feature is currently enabled at {{ enhancedESSCompanies.length }} of your {{ client.companies.length }} companies:
                                <ul class="mb-0">
                                    <li v-for="c in shownEnhancedESSCompanies" :key="c.id">{{ c.name }}</li>
                                </ul>
                                <a v-if="enhancedESSCompanies.length > 3" href @click.prevent="seeMoreCompanies = !seeMoreCompanies" class="btn btn-link">{{ seeMoreCompanies ? "See Less" : "See More" }}</a>
                            </alert-box>
                        </template>

                        <template v-if="!hasEnhancedESS">
                            <h2 class="mb-2">About Enhanced ESS</h2>
                            <div class="row">
                                <div class="col-md-4 col-sm-12 px-1 mb-1">
                                    <div class="feature-tile px-1 py-1 text-center justify-content-center">
                                        <i class="fas fa-clock py-4 px-4"></i>
                                        <h4 class="py-1">Save your managers time and headaches</h4>
                                        <p class="py-1">Remove the need for managers to update employee records manually.</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12 px-1 mb-1">
                                    <div class="feature-tile px-1 py-1 text-center justify-content-center">
                                        <i class="fas fa-shield-alt py-4 px-4"></i>
                                        <h4 class="py-1">Reduce the risk of direct deposit and withholding errors</h4>
                                        <p class="py-1">Put control in employees&rsquo; hands.</p>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12 px-1 mb-1">
                                    <div class="feature-tile px-1 py-1 text-center justify-content-center">
                                        <i class="fas fa-check-circle py-4 px-4"></i>
                                        <h4 class="py-1">Rest assured that data is accurate</h4>
                                        <p class="py-1">GetMyPaystub automatically verifies any changes your employees make.</p>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="row ml-1 mt-4 pt-2">
                            <div class="col-md-6 col-sm-12 mb-2 pr-4">
                                <div class="row flex-nowrap">
                                    <div class="my-1 mr-1">
                                        <div class="icon-circle">
                                            <i class="far fa-fw fa-pen"></i>
                                        </div>
                                    </div>
                                    <div class="my-1">
                                        <h5>Empower</h5>
                                        <h4>What can employees update for themselves?</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    Enhanced ESS allows employees to edit the following 3 profile sections:
                                    <ul>
                                        <li>Basic HR Information (name, address, emergency contacts)</li>
                                        <li>Direct Deposit payment details</li>
                                        <li>Withholding exemptions</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 mb-2 pr-4">
                                <div class="row flex-nowrap">
                                    <div class="my-1 mr-1">
                                        <div class="icon-circle">
                                            <i class="far fa-fw fa-clipboard-check"></i>
                                        </div>
                                    </div>
                                    <div class="my-1">
                                        <h5>Automate | Safeguard</h5>
                                        <h4>How does GetMyPayStub verify data?</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <ul>
                                        <li>Verifies name changes via the Social Security Administration</li>
                                        <li>Verifies address changes via a postal address validation service.</li>
                                        <li>Requires employees to fill out new tax forms after an address change as needed. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div v-if="hasEnhancedESS" class="mt-4">
                            <h2>How can employees access this feature?</h2>
                            <p>When Enhanced ESS was enabled at your organization, a notification email was sent to all employees at the companies where the feature was turned on. This email provided the following instructions:</p>
                            <ol>
                                <li>Log into GetMyPayStub, so that you are viewing your Employee Dashboard.</li>
                                <li>Click on the <strong>My Profile tile.</strong></li>
                                <li>Within My Profile, you will see a navigation menu listing the available sections of profile information. Select the section you want to edit.</li>
                                <li>View the information as currently listed in your employee record. Use the <strong>Edit button</strong> (showing a pencil icon) to make changes to this information.</li>
                            </ol>
                        </div>

                        <div v-if="!hasEnhancedESS" class="mt-4">
                            <h2>How does it work?</h2>
                            <p>Employees can make changes to their information within the <strong>My Profile</strong> area of their Self-Service dashboard. Changes are verified, then saved to their employee record.</p>
                        </div>

                        <div v-if="!hasEnhancedESS" class="row justify-content-center mt-4 mb-4">
                            <a href="mailto:sales@paymastersinc.com" @click="trackContact" class="btn btn-primary"><i class="far fa-fw fa-envelope mr-1"></i>Contact your Payroll Specialist to Learn More</a>
                        </div>
                    </div>
                </div>

                <div class="brand-banner">
                    <div class="container-fluid">
                        <div class="container-centered-l py-4 text-center">
                            <h5>Automate. Safeguard. Empower.</h5>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['client', ],
    components: {},
    data() {
        return {
            seeMoreCompanies: false,
        }
    },
    created() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Enhanced ESS')
    },
    computed: {
        enhancedESSCompanies() {
            if (this.client && this.client.has_enhanced_ess) {
                return this.client.companies.filter(c => c.has_enhanced_ess)
            } else {
                return []
            }
        },
        shownEnhancedESSCompanies() {
            if (this.seeMoreCompanies) {
                return this.enhancedESSCompanies
            } else {
                return this.enhancedESSCompanies.slice(0, 3)
            }
        },
        hasEnhancedESS() {
            return this.enhancedESSCompanies.length > 0
        }
    },
    methods: {
        trackContact() {
            this.$bus.trackEvent('Enhanced ESS Contact', {'client_id': this.client ? this.client.id : null})
        }
    }
}

</script>

<style lang="scss">
    .feature-marketing-page {
        h5 {
            color: #2A7EFF;
        }
        .container-centered-l {
            max-width: 64rem;
        }
        .brand-banner {
            background-color: #FE4E00;
            background-image: linear-gradient(135deg, #DD3B00 0%, #FE4E00 30%, #FD8753 100%);
            color: white;
            h1, h2, h3, h4, h5 {
                color: white;
            }
            h5 {
                letter-spacing: 4px;
            }
        }
        .feature-tile {
            border: 1px solid #CCCCCC;
            background-color: #F8F8F8;
            height: 290px;
            i {
                color: #D44100;
                font-size: 64px;
            }
        }
        .icon-circle {
            background-color: #2A7EFF;
            height: 44px;
            width: 44px;
            border-radius: 50%;
            position: relative;
            i {
                color: white;
                font-size: 22px;
                position: absolute;
                width: 22px;
                top: 11px;
                left: 11px;
            }
        }
    }
</style>