<template>
    <modal size="lg" @close="$emit('close')" ref="modal" :autoshow="true">
        <template v-slot:title>{{ docName }}</template>

        <slot>
            <pdf-inline v-if="previewPDF" :url="previewPDF" :hide-controls="true"/>
            <custom-form-survey
                v-else
                :custom-form="previewCustomForm"
                :is-preview="true"
                ref="previewCustomFormSurvey"
            />
        </slot>

        <template v-slot:footer>
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="$emit('close')">Close</button>
                <button v-if="!previewPDF && !hidePreviewSubmit" type="button" class="btn btn-primary" @click.prevent="savePreview">Preview Saved Document</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
// import pdf from 'vue-pdf'
import ModalMixin from '@/mixins/ModalMixin'
import ButtonRow from '@/components/ButtonRow'
import CustomFormSurvey from '@/components/CustomFormSurvey'
import PdfInline from '@/components/PdfInline'

export default {
    mixins: [ModalMixin],
    components: {ButtonRow, CustomFormSurvey, PdfInline},
    props: ['docName', 'companyName', 'questions', 'client', 'hidePreviewSubmit'],
    data() {
        return {
            previewPDF: null,
        }
    },
    computed: {
        previewCustomForm() {
            return {
                'hr_custom_form_version': {
                    'questions': this.questions
                },
                'response_data': {}
            }
        },
    },
    methods: {
        loadPreviewPDF(resp) {
            let blob = new Blob([resp], { type: 'application/pdf' })
            let reader = new FileReader()
            reader.onloadend = () => this.previewPDF = reader.result
            reader.readAsDataURL(blob)
        },
        savePreview() {
            if (!this.$refs.previewCustomFormSurvey.validate()) {
                return
            }
            let data = {
                'name': this.name,
                'company_name': this.companyName
            }
            Object.assign(data, this.$refs.previewCustomFormSurvey.getFormData())
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/hr-custom-forms/preview-pdf`, data).then(resp => {
                this.loadPreviewPDF(resp)
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        }
    },
}
</script>
