<template>
    <div>
        <h1 class="mb-4 text-center">Contact your Employer</h1>
        <p>
            Your employer will need to update your email address in Payroll.
        </p>

        <div class="card card-body mb-4" v-if="mode == 'auth'">

            <div v-if="mode == 'auth'">
                <div v-if="managers && managers.length">
                    <p>
                        Contact one of the following administrators:
                    </p>
                    <ul>
                        <li v-for="m in managers">{{ m.full_name }}: {{ m.phone_formatted }}</li>
                    </ul>
                </div>
                <div v-if="!(managers && managers.length)">
                    <p class="mb-0">
                        No administrators have been specified. Please contact 
                        the person in charge of your company's payroll.
                    </p>
                </div>
            </div>
            
        </div>
        <p class="text-center">
            <router-link class="btn btn-link" :to="{name: 'login'}">Back to Sign In</router-link>
        </p>

    </div>
</template>


<script>
export default {
    props: ['email', 'isVerified'],
    data() {
        return {
            formData: {
                pin: '',
            },
            mode: 'auth',
            managers: [],
            dataLoaded: false,
        }
    },
    mounted() {
        if (this.isVerified) {
            this.mode = 'auth'
            this.getManagers()
        } else {
            this.mode = 'anon'
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/me/managers/pin`, {
                 email: this.email,
                 pin: this.formData.pin,
            }).then((resp) => {
                this.managers = resp
                this.dataLoaded = true
                this.mode = 'auth'
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getManagers() {
            this.$store.dispatch('START_LOADING')
            return this.$api.get(`/me/managers/authd`).then((resp) => {
                this.managers = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.dataLoaded = true
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}

</script>
