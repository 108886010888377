<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ $filters.date(version.starts_on, "MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ $filters.date(version.ends_on, "MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <div>
        <span class="text-semibold">Type: </span>
        <span>{{ version.rate_type_pretty }}</span>
    </div>

    <div>
        <span class="text-semibold">Is Employer Paid: </span>
        <span>{{ version.is_employer_paid ? 'Yes' : 'No' }}</span>
    </div>

    <div>
        <span class="text-semibold">Taxable Wage Base: </span>
        <span>${{ $filters.formatCurrency(version.taxable_amount) }}</span>
    </div>

    <div v-if="version.rate_type == 'constant'">
        <span class="text-semibold">Rate: </span>
        <span>{{ version.rate }}%</span>
    </div>

    <div v-if="version.rate_type == 'min-max'">
        <span class="text-semibold">Min. Rate: </span>
        <span>{{ version.min_rate }}%</span>
    </div>

    <div v-if="version.rate_type == 'min-max'">
        <span class="text-semibold">Max. Rate: </span>
        <span>{{ version.max_rate }}%</span>
    </div>

    <div v-if="version.rate_type == 'list-selection'">
        <span class="text-semibold">Rates: </span>
        <span>{{ version.rate_choices.map(r => r + '%').join(', ') }}</span>
    </div>

</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', ],
    data() {
        return {
            isExpanded: false,
        }
    },
}
</script>
