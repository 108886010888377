
// TODO: make validateForm below use these strings instead
/*
export const VALIDATION_MESSAGES = {
    'url': 'This field must be a valid URL.',
    'numeric': 'This field must be a number.',
    'integer': 'This field must be an integer.',
    'required': 'This field is required.',
    'alpha': 'This field may only contain letters.',
    'email': 'This field must be a valid email address.',
    'dateISO': 'Please enter a valid date with a ISO format.',
    'alphaNum': 'This field may only contain letters and numbers.',
    'blank': 'This field is required and does not allow blank spaces.',
}
*/

import validate from 'validate.js'
import moment from 'moment'

// Custom validators
validate.extend(validate.validators, {
    currency(value, options) {
        if (!validate.isDefined(value)) {return}
        const re = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
        if (value.match(re)) {
            return
        }
        else {
            return options.message || this.notValid || "is not a valid amount."
        }
    },
    integer(value, options) {
        if (!validate.isDefined(value)) {return}

        if (!validate.isInteger(parseInt(value, 10))) {
            return options.message || this.notValid || "is not a valid number."
        }

        if (options.min && (value < options.min)) {
            return ` must be greater than or equal to ${options.min}.`
        }

        if (options.max && (value > options.max)) {
            return ` must be less than or equal to ${options.max}.`
        }
    },
    decimal(value, options) {
        if (!validate.isDefined(value)) {return}
        const re = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
        if (value.match(re)) {
            if (options.min && (value < options.min)) {
                return ` must be greater than or equal to ${options.min}.`
            }

            if (options.max && (value > options.max)) {
                return ` must be less than or equal to ${options.max}.`
            }
        }
        else {
            return options.message || this.notValid || "is not a valid amount."
        }
    },
    ssn(value, options) {
        if (!validate.isDefined(value)) {return}

        if (options.specialAllowedValues && options.specialAllowedValues.indexOf(value) >= 0) {
            return
        }

        if (value.replace(/[^0-9]/g, '').length !== 9) {
            return options.message || this.notValid || "is not a valid SSN."
        }
    },
    aba_routing_number(value, options) {
        if (!validate.isDefined(value)) {return}
        const re = /^\d{9,9}$/
        if (value.match(re)) {
            return
        }
        else {
            return options.message || this.notValid || "is not a valid routing number."
        }
    },
    aba_account_number(value, options) {
        if (!validate.isDefined(value)) {return}
        const re = /^\w{1,17}$/
        if (value.match(re)) {
            return
        }
        else {
            return options.message || this.notValid || "is not a valid account number."
        }
    },
    // optionalEmail to work around https://github.com/ansman/validate.js/issues/182
    optionalEmail(value) {
        if (value === '') {
            return null
        }
        return validate.single(value, { email: true })
    },
    date(value, options) {
        if ((typeof value) == 'string' && !value.trim()) {
            // Empty values are fine
            return
        }

        if (options.specialAllowedValues && options.specialAllowedValues.indexOf(value) >= 0) {
            return
        }

        if (value === null || (value && value.match && !value.match(/^\d{2}\/\d{2}\/\d{4}$/))) {
            // This indicates that we have a partial value
            const err = options.notValid || options.message || this.notValid || "must be a valid date in the MM/DD/YYYY format."
            return validate.format(err, {value: arguments[0]})
        }

        options = Object.assign({}, this.options, options)

        let errors = []
        let earliest = options.earliest ? this.parse(options.earliest, options) : NaN
        let latest = options.latest ? this.parse(options.latest, options) : NaN

        value = this.parse(value, options)

        // 86400000 is the number of milliseconds in a day, this is used to remove
        // the time from the date
        if (isNaN(value) || value % 86400000 !== 0) {
            const err = options.notValid || options.message || this.notValid || "must be a valid date in the MM/DD/YYYY format."
            return validate.format(err, {value: arguments[0]})
        }

        if (!isNaN(earliest) && value < earliest) {
            let err = options.tooEarly || options.message || this.tooEarly || "must be no earlier than %{date}"
            err = validate.format(err, {
                value: this.format(value, options),
                date: this.format(earliest, options)
            })
            errors.push(err)
        }

        if (!isNaN(latest) && value > latest) {
            let err = options.tooLate || options.message || this.tooLate || "must be no later than %{date}"
            err = validate.format(err, {
                date: this.format(latest, options),
                value: this.format(value, options)
            })
            errors.push(err)
        }

        if (errors.length) {
            return validate.unique(errors)
        }
    },
    ten_digit_phone(value, options) {
        if (!validate.isDefined(value) || value == '') {return}
        const re = /^[0-9]{10}$/
        if (re.test(value)) {
            return // empty return means valid
        } else {
            return options.message || this.notValid || "is not valid."
        }
    },
})

// Custom validation rules for datetime
validate.extend(validate.validators.datetime, {
    parse: function(value, options) {
        let format
        if (value && value.match && value.match(/^\d{4}-\d{2}-\d{2}/)) {
            format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM DD hh:mm:ss"
        }
        else {
            format = options.dateOnly ? "MM/DD/YYYY" : "MM/DD/YYYY hh:mm:ss"
        }
        return moment.utc(value, format)
    },
    format: function(value, options) {
        const format = options.dateOnly ? "MM/DD/YYYY" : "MM/DD/YYYY hh:mm:ss"
        return moment.utc(value).format(format)
    }
})


validate.extend(validate.validators.date, {
    parse: function(value) {
        return moment.utc(value, ['MM/DD/YYYY', 'YYYY-MM-DD'], true).valueOf()
    },
    format: function(value) {
        return moment.utc(value).format("MM/DD/YYYY")
    }
})


export const validateForm = (formData, rules, labels = {}) => {
    const errors = validate(formData || {}, rules || {}, {fullMessages: false})

    if (errors) {
        labels = labels || {}
        for (const fieldName of Object.keys(errors)) {
            const label = labels[fieldName] || validate.capitalize(validate.prettify(fieldName))
            for (const [i, msg] of errors[fieldName].entries()) {
                errors[fieldName][i] = label + ' ' + msg
            }
        }
    }

    return errors
}
