<template>
    <modal size="xl" height-max @close="$emit('close')" ref="modal" autoshow>
        <template v-slot:title>{{ title }}</template>

        <template v-slot:headerbuttons v-if="!hideControls">
            <button class="btn btn-outline-primary btn-collapse-xl" :disabled="zoom >= 400" @click.prevent="zoom = zoom * 1.25">
                <i class="far fa-fw fa-search-plus"></i>
                <span class="sr-only">Zoom In</span>
            </button>
            <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="zoom <= 50" @click.prevent="zoom = zoom / 1.25">
                <i class="far fa-fw fa-search-minus"></i>
                <span class="sr-only">Zoom Out</span>
            </button>
            <button class="btn btn-outline-primary btn-collapse-rs ml-1" @click.prevent="print()">
                <i class="far fa-fw fa-file-pdf"></i>
                <span class="btn-text ml-hf">PDF</span>
            </button>
        </template>

        <template v-slot:subheading>
        </template>

        <slot>
            <div class="pdf-viewer-wrapper">
                <VuePDF v-for="p in src.pages" :pdf="src.pdf" :page="p" :scale="scale" @loaded="onLoaded" :style="{ '--zoom': zoomPct }"/>
            </div>
            <div class="loader loader-modal" v-show="!isLoaded">
                <div class="spinner">
                    <div class="lds-grid">
                        <div></div><div></div><div></div>
                        <div></div><div></div><div></div>
                        <div></div><div></div><div></div>
                    </div>
                </div>
            </div>
        </slot>

        <template v-slot:footer>
            <slot name="footer">
            </slot>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import { VuePDF, usePDF } from '@tato30/vue-pdf'

export default {
    mixins: [ModalMixin],
    components: {
        VuePDF,
    },
    props: ['url', 'title', 'hideControls'],
    // url can be a URL string (including a data URL), or an object in the form of pdf.js DocumentInitParameters: https://github.com/mozilla/pdf.js/blob/38287d943532eee939ceffbe6861163f93805ca7/src/display/api.js#L145
    data() {
        return {
            isLoaded: false,
            src: this.loadPDF(),
            zoom: 100,
            scale: 2
        }
    },
    computed: {
        zoomPct() {
            return `${this.zoom}%`
        }
    },
    watch: {
        url() {
            this.src = this.loadPDF()
        },
    },
    methods: {
        loadPDF() {
            if (!this.url) {
                return null
            }
            this.isLoaded = false
            const { pdf, pages } = usePDF(this.url)
            const src = {
                pdf: pdf,
                pages: pages
            }
            return src
        },
        onLoaded() {
            this.isLoaded = true
            this.$emit('loaded')
        },
        print() {
            if (!this.url) {
                return null
            }
            let pdf_url = this.url.url ? this.url.url : this.url

            if (! this.$store.state.isInApp) {
                window.open(pdf_url) // NB: Chrome does not allow navigating to a data URL
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.get(pdf_url, false, false).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                // use this rather than ArrayBufferToString because ArrayBufferToString
                // sometimes results in: 'RangeError: Maximum call stack size exceeded'
                function _arrayBufferToBase64( buffer ) {
                    var binary = '';
                    var bytes = new Uint8Array( buffer );
                    var len = bytes.byteLength;
                    for (var i = 0; i < len; i++) {
                        binary += String.fromCharCode( bytes[ i ] );
                    }
                    return window.btoa( binary );
                }

                let message = JSON.stringify({
                    command: 'print',
                    content: btoa(_arrayBufferToBase64(resp))
                })

                if (typeof global !== 'undefined' && global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
                    global.webkit.messageHandlers.cordova_iab.postMessage(message)
                } else {
                    console.error("In app, but global.webkit.messageHandlers not found");
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.pdf-viewer-wrapper {
    margin-left: auto;
    margin-right: auto;
    :deep(canvas) {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        margin-left: auto;
        margin-right: auto;
        margin-top: 1em;
        margin-bottom: 1em;
        width: var(--zoom) !important;
        height: auto !important;
    }
}
</style>
