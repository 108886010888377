<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ $filters.date(version.starts_on, "MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ $filters.date(version.ends_on, "MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <div>
        <span class="text-semibold">Version: </span>
        <span>{{ version.version }}</span>
    </div>

    <div>
        <span class="text-semibold">Algorithm: </span>
        <span>{{ version.algorithm_id }}</span>
    </div>

    <div>
        <span class="text-semibold">Additional Data: </span>
        <span>{{ version.additional_data }}</span>
    </div>

    <div>
        <span class="text-semibold">Affected By Taxes: </span>
        <ul v-if="version.affected_by_taxes && version.affected_by_taxes.length">
            <li v-for="tax in version.affected_by_taxes">{{ tax.name }}</li>
        </ul>
        <span v-else>None</span>
    </div>

    <div>
        <span class="text-semibold">Tax Exemptions Group: </span>
        <span v-if="version.tax_exemptions_group">{{ version.tax_exemptions_group.name }}</span>
        <span v-else><em>None</em></span>
    </div>

    <div>
        <span class="text-semibold">Mastertax Tax Codes: </span>
        <ul v-if="version.mastertax_tax_codes.length">
            <li v-for="code in version.mastertax_tax_codes">{{ code }}</li>
        </ul>
        <p v-else class="ml-2"><em>None</em></p>
    </div>

    <div>
        <span class="text-semibold">Tags: </span>
        <ul v-if="version.tags.length">
            <li v-for="tag in version.tags">{{ tag.name }}</li>
        </ul>
        <p v-else class="ml-2"><em>None</em></p>
    </div>

</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', ],
    data() {
        return {
            isExpanded: false,
        }
    },
}
</script>
