<script>

export default {
    data() {
        const sortStack = []

        const headers = this.makeHeadersWithIds()
        if (headers.length == 0) {
            return {
                sortStack: []
            }
        }

        const defaultSortStack = this.getDefaultSortStack()
        if (defaultSortStack && defaultSortStack.length > 0) {
            return {
                sortStack: defaultSortStack
            }
        }

        headers.forEach(h => {
            if (h.defaultSort) {
                sortStack.push({
                    id: h.id,
                    isDesc: !!h.defaultSortIsDesc,
                })
            }
        })

        if (!sortStack.length) {
            sortStack.push({
                id: headers[0].id,
                isDesc: false,
            })
        }

        return {
            sortStack: sortStack,
        }
    },
    computed: {
        sortColumnId() {
            if (this.sortStack.length) {
                return this.sortStack[0].id
            }

            return null
        },
        sortIsAsc() {
            if (this.sortStack.length) {
                return !this.sortStack[0].isDesc
            }

            return true
        },
    },
    methods: {
        sortRows(rows) {
            const headers = this.makeHeadersWithIds()

            // identify any numeric columns
            const numericCols = []
            headers.forEach(h => {
                if (h.sortDataType && h.sortDataType == 'numeric') {
                    numericCols.push(h.id)
                }
            })

            rows.sort((a, b) => {
                for (let sortColumn of this.sortStack) {
                    const idx = this.headerOrderIds.indexOf(sortColumn.id)
                    const orderMultiplier = sortColumn.isDesc ? -1 : 1
                    let valA = ((a.cells[idx] && a.cells[idx].value) ? a.cells[idx].value : a.cells[idx]) || ''
                    let valB = ((b.cells[idx] && b.cells[idx].value) ? b.cells[idx].value : b.cells[idx]) || ''

                    if (numericCols.includes(sortColumn.id)) {
                        valA = valA ? parseFloat(valA) : 0
                        valB = valB ? parseFloat(valB) : 0
                    }

                    if (valA != valB) {
                        return valA > valB ? orderMultiplier : -1 * orderMultiplier
                    }
                }

                return 0
            })

            return rows
        },
        onSort(orderColumnId) {
            if (this.sortStack.length && this.sortStack[0].id == orderColumnId) {
                // The user clicked on the order column that's already at the top of the sort stack. Just reverse it.
                this.sortStack[0].isDesc = !this.sortStack[0].isDesc
                return
            }

            // Move the new column to the top of the stack
            const tmpStack = this.sortStack.filter(c => (c.id != orderColumnId))
            this.sortStack.splice(0, this.sortStack.length)  // empty the stack in a way that Vue would be able to observe
            this.sortStack.push({id: orderColumnId, isDesc: false})
            tmpStack.forEach(s => this.sortStack.push(s))
        },
    },
}
</script>
