<template>
    <div>
        <date-filter v-if="hasDateFilter" class="d-inline-block" :default-range="dateFilterDefaultRange" @change="$emit('date_range_changed', $event)" />

        <div class="dropdown d-inline-block">
            <a href="" class="btn btn-icon dropdown-toggle" role="button" id="menuPageMore" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i><span class="sr-only">More Actions</span>
            </a>
            <div class="dropdown-menu has-icons dropdown-menu-right" aria-labelledby="menuPageMore">
                <!-- For now, everything is grayed out with a 'Coming Soon' message -->
                <span class="dropdown-item disabled text-muted" disabled>
                    <i class="fas fa-fw fa-info-circle mr-1"></i>
                    <em>Coming soon:</em>
                </span>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item disabled text-muted" disabled>
                    <i class="far fa-fw fa-check-square mr-1"></i>
                    Select Multiple
                </a>
                <a class="dropdown-item disabled text-muted" disabled>
                    <i class="far fa-fw fa-file-pdf mr-1"></i>
                    Print
                </a>
                <a class="dropdown-item disabled text-muted" disabled>
                    <i class="far fa-fw fa-file-excel mr-1"></i>
                    Export to Excel
                </a>
                <a class="dropdown-item disabled text-muted" disabled>
                    <i class="far fa-fw fa-undo mr-1"></i>
                    Reset to Default View
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import DateFilter from '@/components/DateFilter'

export default {
    components: {DateFilter, },
    props: {
        dateFilterDefaultRange: {
            default: 30,
        },
        hasDateFilter: {
            default: false,
        },
    }
}
</script>
