<template>
<div v-if="dataLoaded" class="flex-page-content flex-container">
    <div class="flex-body body-scroll">
        <div class="container-fluid">

            <div class="container-centered-s py-4">

                <div v-if="covidPolicy && !isCovidPolicySigned">
                    <div class="card mb-2">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto"><h4 class="mb-0">Vaccination Status</h4></span>
                        </div>

                        <div class="card-body">

                            <p>Before you can report your vaccination status we need you to review and sign the COVID-9 Policy.</p>

                            <button v-if="!isCovidPolicySigned" class="btn btn-primary" @click.prevent="showCovidPolicy()">
                                <i class="far fa-fw fa-external-link mr-1"></i>Sign Document
                            </button>

                            <button v-else class="btn btn-outline-success" @click.prevent="showCovidPolicy()">
                                <i class="far fa-fw fa-check mr-1"></i>View Signed Document
                            </button>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <vaccination-status
                        :status="status"
                        @click="updateModalOpen = true"
                    />
                </div>
            </div>
        </div>
    </div>

    <vaccination-status-modal
        v-if="updateModalOpen"
        :company="company"
        :status="status"
        @close="updateModalOpen = false"
        @updated="onUpdate"
    />

    <pdf-modal
        v-if="currentPDF"
        :url="currentPDF.url"
        :title="currentPDF.title"
        @close="currentPDF = null"
        @updated="$emit('updated')"
    >
        <template v-slot:footer>
            <button-row stacked center>
                <button class="btn btn-outline-primary" @click.prevent="currentPDF = null">Close</button>
                <button class="btn btn-primary" @click.prevent="signCovidPolicy">Sign Document</button>
            </button-row>
        </template>
    </pdf-modal>
</div>
</template>


<script>
import VaccinationStatus from './VaccinationStatus'
import VaccinationStatusModal from './VaccinationStatusModal'
import PdfModal from '@/components/PdfModal'
import ButtonRow from '@/components/ButtonRow'
import moment from 'moment'

export default {
    props: ['company'],
    components: {VaccinationStatus, VaccinationStatusModal, PdfModal, ButtonRow, },
    computed: {
        isCovidPolicySigned() {
            return this.signedCovidPolicy ? true : false
        },
    },
    data() {
        return {
            status: null,
            dataLoaded: false,
            updateModalOpen: false,
            currentPDF: null,
            signedCovidPolicy: null,
            covidPolicy: null,
        }
    },
    mounted() {
        if (!this.company.feature_covid_vaccination_status_reporting) {
            this.$router.replace({name: 'employee-company-home', params: {hashedCompanyId: this.$route.params.hashedCompanyId}})
            return
        }
        this.$store.dispatch('SET_PAGE_TITLE', 'COVID')
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            const promises = []
            promises.push(this.$api.get(`/employee/companies/${this.company.id}/covid-policy`).then(resp => {
                this.covidPolicy = resp.hr_doc
                this.signedCovidPolicy = resp.employee_doc
            }))

            promises.push(this.$api.get(`/employee/companies/${this.company.id}/covid-vaccination-statuses`).then(resp => {
                resp.sort((a, b) => {
                    return (a.created_on < b.created_on ? 1 : -1)
                })
                this.status = resp.length ? resp[0] : null
            }))

            Promise.all(promises).then(() => {
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate() {
            this.updateModalOpen = false
            this.getData()
        },
        showCovidPolicy() {
            this.currentPDF = {
                url: {
                    url: `${this.$api.API_BASE}/hr-documents/${this.covidPolicy.id}/pdf-file`,
                    withCredentials: true,
                },
                title: 'COVID-19 Policy',
            }
        },
        signCovidPolicy() {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/employee/companies/${this.company.id}/covid-policy`, {'date': moment().format('MM/DD/YYYY')}).then(resp => {
                this.covidPolicy = resp.hr_doc
                this.signedCovidPolicy = resp.employee_doc
                this.currentPDF = null
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}

</script>
