<template>
<sidebar @close="$emit('close')">
    <template v-slot:title>
        Payroll Run #{{ payroll.run_number }}
    </template>

    <slot v-if="dataLoaded">
        <p v-if="!paychecks.length">This payroll had no paychecks.</p>

        <ul class="list-unstyled m-0" v-if="paychecks.length">
            <li>
                <div class="form-row align-items-baseline py-1">
                    <div class="col"><h5 class="m-0">Check Number</h5></div>
                    <div class="col"><h5 class="m-0">Net Pay</h5></div>
                    <div class="col"><h5 class="m-0">Employee</h5></div>
                </div>
            </li>

            <li v-for="(paycheck, i) in paychecks" :key="paycheck.id" :class="{'border-bottom': i < paychecks.length - 1}">
                <div class="form-row align-items-baseline py-3q">
                    <div class="col">
                        <a href @click.prevent="onCheckClicked(paycheck)" class="paycheck">{{ paycheck.check_number || '[Unknown]' }}</a>
                    </div>

                    <div class="col">
                        <span>${{ paycheck.net_pay }}</span>
                    </div>

                    <div class="col cap">
                        <span>{{ paycheck.employee.full_name }}</span>
                    </div>
                </div>
            </li>
        </ul>
    </slot>

    <check-modal :paycheck="currentCheck" :client="client" ref="checkModal" v-if="currentCheck" />
</sidebar>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import Sidebar from '@/components/Sidebar'
import CheckModal from '@/components/PaycheckModal'

export default {
    components: {Sidebar, CheckModal, },
    props: ['client', 'payroll', ],
    data() {
        return {
            dataLoaded: false,
            paychecks: [],
            currentCheck: null,
        }
    },
    mounted() {
        this.getPaychecks()
    },
    watch: {
        payroll() {
            this.getPaychecks()
        }
    },
    methods: {
        getPaychecks() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            api.get(`/admin/payrolls/${this.payroll.id}/paychecks`).then(resp => {
                this.paychecks = resp
                this.paychecks.sort((a, b) => {
                    return a.check_number > b.check_number ? 1 : -1
                })
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
        onCheckClicked(check) {
            this.currentCheck = check
            this.$nextTick(() => {
                this.$refs.checkModal.open()
            })
        },
    },
}
</script>
