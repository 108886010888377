<template>
    <div>
        <div class="flex-body pb-2">
            <div>
                <div class="container-centered my-4" v-if="dataLoaded">
                    <div class="row">
                        <div class="col-12 col-md-10 offset-md-1">
                            <router-link class="btn btn-link mb-2" :to="{name: 'employer-home', params: {hashedClientId: $hasher.encode(client.id)}}">&laquo; Back to Dashboard</router-link>

                            <div v-if="taskDetail.errors.length">
                                <h3>Allocate Tips: <span class="text-thin">Error</span></h3>

                                <div class="alert alert-danger" v-for="err in taskDetail.errors">
                                    {{ err }}
                                </div>
                            </div>
                            <div v-else>
                                <h3>Allocate Tips: <span class="text-thin">Imported</span></h3>

                                <div class="d-flex mt-2">
                                    <div class="display-4 text-success mr-2 mt-0">
                                        <i class="fas fa-check-circle"></i>
                                    </div>

                                    <div class="mt-1">
                                        <p>
                                            <b>Success!</b> Your <b>${{ $filters.formatCurrency(taskDetail.total_tips_imported) }}</b> in tips has been allocated and imported into payroll.
                                            <br><br>
                                            Return to Paymasters to finish running your payroll.
                                        </p>
                                    </div>
                                </div>

                                <div v-if="taskDetail.warnings.length > 0" class="mt-2">
                                    <hr>
                                    <b>Warnings Summary</b>
                                    <br><br>

                                    <div class="alert alert-warning" v-for="w in taskDetail.warnings">
                                        {{ w }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'

import moment from 'moment'

export default {
    props: ['client', ],
    computed: {
        taskId() {
            return this.$route.params.taskId
        },
    },
    data() {
        return {
            dataLoaded: false,
            taskDetail: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tips Allocator')
        this.getInitialData()
    },
    methods: {
        getInitialData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            const url = `/clients/${this.client.id}/tips-allocator/${this.taskId}/detail`

            this.$api.get(url).then(resp => {
                this.taskDetail = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
    },
}
</script>
