<template>
    <modal ref="modal" :fade="false" :v-center="true" modal-additional-classes="modal-pageerror modal-noclose" :options="modalOptions" :modal-container="'specialModal'" :hide-x-button="true">
        <template v-slot:title>
            <div>Time Mismatch</div>
        </template>

        <slot>
            <div>
                <p>
                  Your computer clock or timezone is not correct, which will cause problems logging in
                  to GetMyPayStub.
                </p>

                <p>
                    Check that the time and timezone are correct and consider
                    setting it automatically (offered on most operating systems).
                </p>

                <p>
                    Once you've made the adjustment, refresh this page.
                </p>
            </div>
        </slot>

        <template v-slot:footer>
            <button-row center>
                <a href @click.prevent="onClick" class="btn btn-primary">Refresh</a>
            </button-row>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin, ],
    data() {
        return {
            modalOptions: {
                backdrop: 'static',
                keyboard: false,
            },
        }
    },
    methods: {
        onClick() {
            window.location.reload()
        },
        close(forceClose = false) {
            if (forceClose) {
                ModalMixin.methods.close.call(this)
            }
        },
    },
}
</script>
