<template>
    <page>
        <warnings-table
            v-if="dataLoaded"
            :client="client"
            :warnings="warnings"
            @updated="onUpdate"
            :show-employee-columns="true"
            @date_filter_change="onDateRangeChange"
        />
    </page>
</template>

<script>
import WarningsTable from '@/components/WarningsTable'
import Page from '@/components/Page'

export default {
    components: {WarningsTable, Page, },
    props: ['client'],
    data() {
        return {
            warnings: [],
            dataLoaded: false,
            currentWarning: null,

            dateRangeStart: null,
            dateRangeEnd: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Warnings')
        this.getWarnings()
    },
    methods: {
        getWarnings() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/warnings`
            if (this.dateRangeStart && this.dateRangeEnd) {
                url = `${url}?date-filter.resolved.start=${encodeURIComponent(this.dateRangeStart)}&date-filter.resolved.end=${encodeURIComponent(this.dateRangeEnd)}`
            }
            this.$api.get(url).then(resp => {
                this.warnings = resp.warnings
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate() {
            this.getWarnings()
        },
        onDateRangeChange(range) {
            this.dateRangeStart = range.start
            this.dateRangeEnd = range.end
            this.getWarnings()
        },
    },
}
</script>

