<template>
<div>
<sidebar-widget
    @add="modalOpen = true"
    @delete="onDelete($event)"
    @edit="currentRowId = $event; modalOpen = true"
    @versions="currentRowId = $event; versionsModalIsOpen = true"
    :rows="rows"
    :headers="['Deduction Type', 'Monthly Lmt', 'Annual Lmt']"
>
    <template v-slot:title>Exempt Deduction Types</template>

    <template v-slot:row-details scope="props">
        <exempt-deduction-type-version :version="props.row.current_version"/>
    </template>
</sidebar-widget>

<edit-modal
    v-if="modalOpen"
    :tax-exemptions-group="taxExemptionsGroup"
    :exempt-deduction-type="currentRow"
    :deduction-types="deductionTypes"
    @updated="$emit('updated')"
    @close="modalOpen = false; currentRowId = null"

/>

<versions-modal
    v-if="versionsModalIsOpen"
    :url="`/admin/tax-engine/tax-exemptions-groups/${taxExemptionsGroup.id}/exempt-deduction-types/${currentRowId}`"
    @close="currentRowId = null; versionsModalIsOpen = false"
>
    <template v-slot:version scope="props">
        <exempt-deduction-type-version :version="props.version"/>
    </template>
</versions-modal>

</div>
</template>

<script>
import EditModal from './ExemptDeductionTypeModal'
import SidebarWidget from './SidebarWidget'
import ExemptDeductionTypeVersion from './ExemptDeductionTypeVersion'
import VersionsModal from './VersionsModal'

export default {
    props: ['taxExemptionsGroup', 'deductionTypes', ],
    components: {EditModal, SidebarWidget, ExemptDeductionTypeVersion, VersionsModal},
    computed: {
        currentRow() {
            return this.taxExemptionsGroup.exempt_deduction_types.find(x => x.id == this.currentRowId)
        },
        rows() {
            const result = []
            this.taxExemptionsGroup.exempt_deduction_types.forEach(row => {
                row = JSON.parse(JSON.stringify(row))
                row.cells = [
                    row.deduction_type.name,
                    row.current_version.monthly_exemption_limit ?
                        `$${this.$filters.formatCurrency(row.current_version.monthly_exemption_limit)}`
                        : '-',
                    row.current_version.exemption_limit ?
                        `$${this.$filters.formatCurrency(row.current_version.exemption_limit)}`
                        : '-',
                ]
                result.push(row)
            })
            return result
        },
    },
    data() {
        return {
            modalOpen: false,
            versionsModalIsOpen: false,
            currentRowId: null,
        }
    },
    methods: {
        getURL(rowId) {
            if (!rowId) {return}
            return `/admin/tax-engine/tax-exemptions-groups/${this.taxExemptionsGroup.id}/exempt-deduction-types/${rowId}`
        },
        onDelete(rowId) {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(this.getURL(rowId)).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>


