<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ $filters.date(version.starts_on, "MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ $filters.date(version.ends_on, "MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <div v-if="involuntaryWithholdingType.has_rate">
        <span class="text-semibold" v-if="involuntaryWithholdingType.has_min_max_rate">Min. Rate: </span>
        <span class="text-semibold" v-else>Rate: </span>
        <span>{{ version.min_rate }}%</span>
    </div>
    <div v-if="involuntaryWithholdingType.has_min_max_rate">
        <span class="text-semibold">Max. Rate: </span>
        <span>{{ version.max_rate }}%</span>
    </div>
    <div v-if="involuntaryWithholdingType.has_arrears_rate">
        <span class="text-semibold">Arrears Rate: </span>
        <span>{{ version.arrears_rate }}%</span>
    </div>
    <div v-if="involuntaryWithholdingType.has_non_credit_rate">
        <span class="text-semibold">Non-Credit Rate: </span>
        <span>{{ version.min_non_credit_rate }}%</span>
    </div>

    <h6 class="text-semibold">Earnings Excluded from Disposable</h6>
    <ul v-if="version.earning_types_excluded_from_disposable.length">
        <li v-for="earning_type in version.earning_types_excluded_from_disposable">{{ earning_type.name }}</li>
    </ul>
    <p v-else><em>None</em></p>

    <h6 class="text-semibold">Deductions That Reduce Disposable</h6>
    <ul v-if="version.deductions_that_reduce_disposable.length">
        <li v-for="deduction_type in version.deductions_that_reduce_disposable">{{ deduction_type.name }}</li>
    </ul>
    <p v-else><em>None</em></p>

    <div v-if="involuntaryWithholdingType.has_exempt_amount">
        <h6 class="text-semibold">Exempt Amounts</h6>
        <div v-for="filingStatus in filingStatuses" class="ml-1 mb-1">
            <div class="text-semibold">{{ filingStatus.name }}</div>
            <div>
                <span class="text-semibold">Base Amount: </span>
                <span>${{ version.exempt_amounts.find(x => x.filing_status_id == filingStatus.id) ? version.exempt_amounts.find(x => x.filing_status_id == filingStatus.id).base_amount : ''}}</span>
            </div>

            <div>
                <span class="text-semibold">Per Dependent Amount: </span>
                <span>${{ version.exempt_amounts.find(x => x.filing_status_id == filingStatus.id) ? version.exempt_amounts.find(x => x.filing_status_id == filingStatus.id).per_dependent_amount : ''}}</span>
            </div>

            <div>
                <span class="text-semibold">Additional Exempt Amount: </span>
                <span>${{ version.exempt_amounts.find(x => x.filing_status_id == filingStatus.id) ? version.exempt_amounts.find(x => x.filing_status_id == filingStatus.id).additional_exempt_amount : ''}}</span>
            </div>

        </div>
    </div>

</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', 'involuntaryWithholdingType', 'filingStatuses', ],
    data() {
        return {
            isExpanded: false,
        }
    },
}
</script>

